import type { Path } from "hw/common/types";
import type { PdfMapping } from "hw/portal/modules/common/draft";
import type { Result } from "hw/ui/document-mapper/types";
import { Actions as EditorActions, changeTypes } from "../../state";

/**
 * Provide an `UpdatedType` value that matches the type you are updating
 * when callign this function to get correct types.
 *
 * TODO: type this out more explicitly
 *
 * @example
 * // Updating the entire pdf map
 * updatePdfMap<OutputField[]>({
 *   path,
 *   updater: (pdfMap) => pdfMap
 * })
 *
 * // Update a single output field
 * updatePdfMap<OutputField>({
 *   path: [...pdfMap, idx],
 *   updater: (field) => field
 * })
 */
export function updatePdfMap<UpdatedType>(payload: {
  path: Path;
  updater: (payload: UpdatedType) => UpdatedType;
}) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.SAVE_PDF_MAP,
  });
}

export function resizeOutputField(payload: {
  path: Path;
  // $FlowFixMe: migration
  resizedFields: Result;
}) {
  return EditorActions.ResizeOutputField({
    ...payload,
    changeType: changeTypes.RESIZE_OUTPUT_FIELD,
  });
}

export function changeFontSize<UpdatedType>(payload: {
  path: Path;
  updater: (payload: UpdatedType) => UpdatedType;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  mapping: PdfMapping;
}) {
  return EditorActions.ChangeFontSize({
    ...payload,
    changeType: changeTypes.CHANGE_FONT_SIZE,
  });
}
