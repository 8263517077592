import { getIn, updateIn } from "timm";
import { createLogger } from "hw/common/utils/logger";
import { invariant } from "hw/common/utils/assert";
import type {
  ParsedSchema,
  MergeField,
  Field,
} from "hw/portal/modules/common/draft";
import * as constants from "./constants";
import type {
  SchemaState as SchemaStateType,
  Tab,
  DraftResponse,
} from "./types";
import * as SchemaState from "./schema-state";
import type { History } from "./undoable";
import {
  undoable,
  UNDO,
  REDO,
  COMMIT,
  lastUndone,
  lastCommitted,
} from "./undoable";
import { Schema } from "../common/draft/schema-ops";
import {
  defaultWorkflowName,
  removeMergeFieldMapping,
  getReferringFields,
  getReferringFormLabels,
  getReferringCalculationLabels,
} from "./utils";

const logger = createLogger("draft-editor-state");
const UNDO_LIMIT = 100;
export const initialState = {
  app: {
    status: "default",
    mergeFieldsModalOpen: false,
    roleModalOpen: false,
    prePublishModalOpen: false,
    lastSetRoles: {
      workflow: undefined,
      form: {},
    },
    saving: false,
    sendOnPublish: true,
    reassignmentConfirmationModal: {
      open: false,
    },
  },
};
export type DraftState = {
  name: DraftResponse["name"];
  schema: SchemaStateType;
};
export type State = {
  app: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    status: any;
    tab: Tab;
    mergeFieldsModalOpen: boolean;
    roleModalOpen: boolean;
    prePublishModalOpen: boolean;
    lastSetRoles: {
      workflow: string | null | undefined;
      form: Record<string, string | null | undefined>;
    };
    saving: boolean;
    sendOnPublish: boolean;
    reassignmentConfirmationModal:
      | {
          open: false;
        }
      | {
          open: true;
          modalProps: {
            formId: string;
            field: Field;
            roleId: string;
          };
        };
  };
  draft: History<DraftState>;
};

/**
 * Action Types
 */
export const ActionTypes = {
  CHANGE_TAB: "CHANGE_TAB",
  CLOSE_MERGE_FIELDS_MODAL: "CLOSE_MERGE_FIELDS_MODAL",
  CLOSE_ROLE_MODAL: "CLOSE_ROLE_MODAL",
  CLOSE_PRE_PUBLISH_MODAL: "CLOSE_PRE_PUBLISH_MODAL",
  INIT: "INIT",
  LAUNCH: "LAUNCH",
  LAUNCH_ERROR: "LAUNCH_ERROR",
  LAUNCH_ERROR_RATE_LIMITED: "LAUNCH_ERROR_RATE_LIMITED",
  LAUNCH_SUCCESS: "LAUNCH_SUCCESS",
  OPEN_MERGE_FIELDS_MODAL: "OPEN_MERGE_FIELDS_MODAL",
  OPEN_PRE_PUBLISH_MODAL: "OPEN_PRE_PUBLISH_MODAL",
  OPEN_ROLE_MODAL: "OPEN_ROLE_MODAL",
  PUBLISH: "PUBLISH",
  PUBLISH_ERROR: "PUBLISH_ERROR",
  SAVE: "SAVE",
  SAVE_ERROR: "SAVE_ERROR",
  SAVE_SUCCESS: "SAVE_SUCCESS",
  SAVE_AS_TEMPLATE: "SAVE_AS_TEMPLATE",
  SAVE_AS_TEMPLATE_ERROR: "SAVE_AS_TEMPLATE_ERROR",
  SAVE_AS_TEMPLATE_SUCCESS: "SAVE_AS_TEMPLATE_SUCCESS",
  SET_NAME: "SET_NAME",
  SET_RAW_SCHEMA: "SET_RAW_SCHEMA",
  UPDATE_PARSED_SCHEMA: "UPDATE_PARSED_SCHEMA",
  SET_DEFAULT_WORKFLOW_NAME: "SET_DEFAULT_WORKFLOW_NAME",
  SEND_ON_PUBLISH: "SEND_ON_PUBLISH",
  UNDO,
  REDO,
  COMMIT,
  FIELD_REASSIGNMENT_REQUESTED: "FIELD_REASSIGNMENT_REQUESTED",
  FIELD_REASSIGNMENT_CANCELED: "FIELD_REASSIGNMENT_CANCELED",
  FIELD_REASSIGNED: "FIELD_REASSIGNED",
};
const AT = ActionTypes;

/**
 * Action Creators
 */
type ChangePayload = {
  changeType: string;
  description?: string;
  undoable?: boolean;
};
export type UpdateParsedPayload = ChangePayload & {
  path: Array<string | number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updater: (...args: Array<any>) => any;
};
type ActionType = keyof typeof AT;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Action = any;
export const Actions = {
  Init() {
    return {
      type: AT.INIT,
    };
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SetName(payload: any) {
    return UndoableChangeEvent({
      type: AT.SET_NAME,
      payload: { ...payload, changeType: AT.SET_NAME },
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SetRawSchema(payload: any) {
    return {
      type: AT.SET_RAW_SCHEMA,
      payload,
    };
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SetDefaultWfName(payload: any) {
    return UndoableChangeEvent({
      type: AT.SET_DEFAULT_WORKFLOW_NAME,
      payload: {
        // Flow wants this?
        undoable: true,
        ...payload,
      },
    });
  },

  /**
   * This is a generic action creator used to make updates to the parsed draft
   * schema.  99% percent of updates will go through this action.  It returns
   * a `UndoableChangeEvent` action type, which expects that the action payloads have
   * certain properties in their payload that describes the change being made.
   * This will be used to determine the undo/redo behavior.
   *
   * Many times you'll have an additional action creator in front of this one that
   * prefills with some specific information.
   *
   * @example
   * const Actions = {
   *   SetFormName(payload) {
   *     return EditorActions.UpdateParsedSchema({
   *       ...payload,
   *       changeType: "SET_FORM_NAME"
   *     })
   *   }
   * }
   *
   * function MyComponent(props) {
   *   const { editorDispatch } = props;
   *
   *   editorDispatch(Actions.SetFormName({
   *     description: "Change form name",
   *     path: [...props.path, 'name'],
   *     updater: () => 'some value'
   *   }))
   * }
   */
  UpdateParsedSchema(payload: UpdateParsedPayload) {
    return UndoableChangeEvent({
      type: AT.UPDATE_PARSED_SCHEMA,
      payload,
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReassignField(payload: any) {
    return UndoableChangeEvent({
      type: AT.FIELD_REASSIGNED,
      payload: {
        changeType: changeTypes.SET_FIELD_ROLE,
        ...payload,
      },
    });
  },
};
export const changeTypes = {
  CHANGE_FIELD_SETTING: "CHANGE_FIELD_SETTING",
  CREATE_FIELD: "CREATE_FIELD",
  CREATE_FORM: "CREATE_FORM",
  CREATE_FORM_FROM_TEMPLATE: "CREATE_FORM_FROM_TEMPLATE",
  DELETE_FIELD: "DELETE_FIELD",
  DELETE_FORM: "DELETE_FORM",
  DELETE_MAPPING: "DELETE_MAPPING",
  DELETE_PDF_TEMPLATE: "DELETE_PDF_TEMPLATE",
  DUPLICATE_FIELD: "DUPLICATE_FIELD",
  MOVE_FIELD: "MOVE_FIELD",
  SAVE_MERGE_FIELDS: "SAVE_MERGE_FIELDS",
  SAVE_ROLES: "SAVE_ROLES",
  SAVE_PDF_MAP: "SAVE_PDF_MAP",
  SET_FIELD_ROLE: "SET_FIELD_ROLE",
  SET_MAPPING: "SET_MAPPING",
  SET_FORM_NAME: "SET_FORM_NAME",
  SET_NAME: "SET_NAME",
  UPLOAD_PDF_TEMPLATE: "UPLOAD_PDF_TEMPLATE",
};

/**
 * These are the messages that get displayed to the user when describing an undo
 * or redo action.  They will be rendered as:
 *
 *   "Undo/Redo ${message.toLowerCase()}"
 */
const changeMessages = {
  [changeTypes.CHANGE_FIELD_SETTING]: "Change component setting",
  [changeTypes.CREATE_FIELD]: "Create component",
  [changeTypes.CREATE_FORM]: "Create form",
  [changeTypes.CREATE_FORM_FROM_TEMPLATE]: "Create form from template",
  [changeTypes.DELETE_FIELD]: "Delete component",
  [changeTypes.DELETE_FORM]: "Delete form",
  [changeTypes.DELETE_MAPPING]: "Delete pre-fill",
  [changeTypes.DELETE_PDF_TEMPLATE]: "Delete PDF",
  [changeTypes.DUPLICATE_FIELD]: "Duplicate component",
  [changeTypes.MOVE_FIELD]: "Move component",
  [changeTypes.SAVE_MERGE_FIELDS]: "Change merge fields",
  [changeTypes.SAVE_PDF_MAP]: "Change PDF map",
  [changeTypes.SAVE_ROLES]: "Change roles",
  [changeTypes.SET_FIELD_ROLE]: "Assign component",
  [changeTypes.SET_FORM_NAME]: "Change form name",
  [changeTypes.SET_NAME]: "Change name",
  [changeTypes.SET_MAPPING]: "Pre-fill component",
  [changeTypes.UPLOAD_PDF_TEMPLATE]: "Upload PDF",
};

/**
 * Takes an action and marks it as a "UndoableChangeEvent" which will be used for
 * undo/redo behavior.
 *
 * NOTE: This will assume the action is undoable unless explicitly marked with
 * `undoable=false`.  If marked as such, the history will be cleared when
 * that action is dispatched.
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function UndoableChangeEvent(action) {
  const { changeType, undoable = true } = action.payload;
  invariant(
    changeType,
    "Change events should have a 'changeType' property in their payload"
  );
  // $FlowIgnore
  const description = action.payload.description || changeMessages[changeType];
  invariant(
    description,
    "Change events should have a 'description' property in their payload"
  );
  // $FlowIgnore
  const meta = action.meta || {};
  return {
    ...action,
    meta: {
      ...meta,
      change: {
        type: changeType,
        description,
        undoable,
      },
    },
  };
}

/**
 * As a way to reduce boilerplate, we can allow actions to be dispatched with
 * only a type string and assume that coerce that into an action object with a
 * type
 *
 * @example
 * dispatch('Launch');
 * //=> Same as...
 * //=> dispatch({ type: "Launch" })
 */
export function ensureActionFormat(action: ActionType | Action): Action {
  if (typeof action === "string") {
    return {
      type: action,
    };
  } else {
    return action;
  }
}

/**
 * Handles state for everything _except_ the draft
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function appReducer(state, action) {
  switch (action.type) {
    case AT.PUBLISH:
    case AT.PUBLISH_ERROR:
    case AT.SAVE:
    case AT.SAVE_ERROR:
    case AT.SAVE_SUCCESS:
    case AT.SAVE_AS_TEMPLATE:
    case AT.SAVE_AS_TEMPLATE_ERROR:
    case AT.SAVE_AS_TEMPLATE_SUCCESS:
    case AT.LAUNCH:
    case AT.LAUNCH_SUCCESS:
    case AT.LAUNCH_ERROR:
    case AT.LAUNCH_ERROR_RATE_LIMITED: {
      return transitionStatus(state, action);
    }

    case AT.OPEN_ROLE_MODAL:
      return { ...state, roleModalOpen: true };

    case AT.CLOSE_ROLE_MODAL:
      return { ...state, roleModalOpen: false };

    case AT.OPEN_MERGE_FIELDS_MODAL:
      return { ...state, mergeFieldsModalOpen: true };

    case AT.CLOSE_MERGE_FIELDS_MODAL:
      return { ...state, mergeFieldsModalOpen: false };

    case AT.FIELD_REASSIGNMENT_CANCELED:
      return {
        ...state,
        reassignmentConfirmationModal: {
          open: false,
        },
      };

    case AT.FIELD_REASSIGNED: {
      const { formId, roleId } = action.payload;
      return {
        ...state,
        reassignmentConfirmationModal: {
          open: false,
        },
        lastSetRoles: {
          ...state.lastSetRoles,
          workflow: roleId,
          [formId]: roleId,
        },
      };
    }

    case AT.CHANGE_TAB: {
      const nextTab = action.payload;
      return { ...state, tab: nextTab };
    }

    case AT.CLOSE_PRE_PUBLISH_MODAL: {
      return { ...state, prePublishModalOpen: false };
    }

    case AT.OPEN_PRE_PUBLISH_MODAL: {
      return { ...state, prePublishModalOpen: true };
    }

    case AT.SEND_ON_PUBLISH: {
      return { ...state, sendOnPublish: action.payload };
    }

    default:
      return state;
  }
}

/**
 * Handles all state related to drafts
 */
const draftReducer = undoable(
  function draftReducer(draft, action) {
    switch (action.type) {
      case AT.CHANGE_TAB: {
        const nextTab = action.payload;
        invariant(nextTab, "'CHANGE_TAB' action should have a payload");
        const nextSchemaState = SchemaState.convertTo(
          nextTab.schemaStateType,
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          draft.schema
        );
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        if (nextSchemaState === draft.schema) return draft;
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        return { ...draft, schema: nextSchemaState };
      }

      case AT.SET_NAME: {
        invariant(
          action.payload,
          "'SET_NAME' actions should have a payload with a 'value' property"
        );
        const { value } = action.payload;
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        return { ...draft, name: value };
      }

      case AT.SET_DEFAULT_WORKFLOW_NAME: {
        invariant(
          action.payload,
          "'SET_DEFAULT_WORKFLOW_NAME' actions must define a payload"
        );
        const { updater, path } = action.payload;
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        const updatedDraft = updateIn(draft, ["schema"], (schemaState) =>
          SchemaState.updateParsedOrThrow(
            schemaState,
            ["value", "value", ...path],
            updater
          )
        );
        const workflowName = defaultWorkflowName(
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          getIn(updatedDraft, ["schema", "value", "value", "forms"])
        );
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        return { ...updatedDraft, name: workflowName };
      }

      case AT.UPDATE_PARSED_SCHEMA: {
        invariant(
          action.payload,
          "'UPDATE_PARSED_SCHEMA' actions must define a payload"
        );
        const { updater, path } = action.payload;
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        return updateIn(draft, ["schema"], (schemaState) =>
          SchemaState.updateParsedOrThrow(
            schemaState,
            ["value", "value", ...path],
            updater
          )
        );
      }

      case AT.SET_RAW_SCHEMA: {
        invariant(
          action.payload !== undefined,
          "'SET_RAW_SCHEMA' actions should have a payload"
        );
        const newSchema = action.payload;
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...draft,
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          schema: SchemaState.setRawOrThrow(newSchema, draft.schema),
        };
      }

      case AT.FIELD_REASSIGNED: {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        return updateIn(draft, ["schema"], (schemaState) =>
          SchemaState.updateParsedOrThrow(
            schemaState,
            ["value", "value"],
            (schema) => {
              const { field, formId, roleId } = action.payload;
              return Schema.reassignComponent(schema, field, formId, roleId);
            }
          )
        );
      }

      default:
        return draft;
    }
  },
  {
    limit: UNDO_LIMIT,
  }
);

// @ts-expect-error ts-migrate(7023) FIXME: 'requestFieldReassignmentReducer' implicitly has r... Remove this comment to see the full error message
function requestFieldReassignmentReducer(state, action) {
  const { path, roleId, field } = action.payload;
  const currentDraft = Selectors.draft(state);
  const schema = SchemaState.getParsedOrThrow(currentDraft.schema);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  const fieldsIndex = path.findIndex((val) => val === "fields");
  const formPath = path.slice(0, fieldsIndex);
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  const form = getIn(schema, formPath);
  const { id: formId, fields } = form;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'mapping' does not exist on type 'unknown... Remove this comment to see the full error message
  const { mapping: mappings, forms } = schema;
  const hasConditionalLogicReferences =
    getReferringFields(fields, field).length !== 0;
  const hasMappingReferences =
    getReferringFormLabels(field, formId, forms, mappings).length !== 0;
  const hasCalculationReferences =
    getReferringCalculationLabels(fields, field).length !== 0;
  const hasFieldReferences =
    hasConditionalLogicReferences ||
    hasMappingReferences ||
    hasCalculationReferences;
  const assignmentProps = {
    field,
    formId,
    roleId,
  };

  if (hasFieldReferences) {
    // If there are any references to this field, update the `app` state to show
    // the confirmation modal.
    return {
      ...state,
      app: {
        ...state.app,
        reassignmentConfirmationModal: {
          open: true,
          modalProps: assignmentProps,
        },
      },
    };
  } else {
    // Otherwise, kick off the action to reassign the field
    // This needs to go through the `app` reducer as well to update the
    // last set roles
    return reducer(state, Actions.ReassignField(assignmentProps));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function init(state: any) {
  return {
    app: appReducer(state.app, Actions.Init()),
    draft: draftReducer(state.draft, Actions.Init()),
  };
}

/**
 * Reducer
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'reducer' implicitly has return type 'any' because... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reducer(state: any, action: Action) {
  logger.debug(action);

  switch (action.type) {
    /**
     * This is a specialized reducer to handle field reassignment. It's handled
     * at the top because the resulting state is computed based on the entire
     * current state (both `app` and `draft`). Example:
     *
     *   - If the field being reassigned has references, we update the `app`
     *     state to show the confirmation modal and leave the draft state as
     *     is.
     *   - If the field beign reassigned _does not_ have references, we leave
     *     the `app` state as is and update the draft state to reassign the
     *     field.
     *
     * We could handle this logic separately in each slice reducer, but we'd
     * need opposing, duplicated logic in each
     */
    case AT.FIELD_REASSIGNMENT_REQUESTED:
      return requestFieldReassignmentReducer(state, action);

    default:
      return {
        app: appReducer(state.app, action),
        draft: draftReducer(state.draft, action),
      };
  }
}
export const Selectors = {
  draft: (state: State) => state.draft.present,
  lastCommitted: (state: State) => lastCommitted(state.draft),
  lastUndone: (state: State) => lastUndone(state.draft),
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function transitionStatus(state, action) {
  const currentStatus = state.status;
  const nextStatus = getIn(constants.STATUS_MACHINE, [
    currentStatus,
    action.type,
  ]);

  if (nextStatus) {
    return { ...state, status: nextStatus };
  } else {
    logger.error(
      `Invalid status in the workflow editor trying to ${action.type} from ${currentStatus}`
    );
    return state;
  }
}

/**
 * Updater for saving new merge fields. Handles deleting any merge field references
 * that might exist for merge fields that have been deleted
 */
export function saveMergeFields(
  schema: ParsedSchema,
  savedMergedFields: Array<MergeField>,
  deletedMergeFields: Array<MergeField>
) {
  const dataRefsToDelete = deletedMergeFields.map((mf) => mf.dataRef);
  let nextMappings = schema.mapping;

  for (const dataRef of dataRefsToDelete) {
    nextMappings = removeMergeFieldMapping(nextMappings, dataRef);
  }

  return { ...schema, mapping: nextMappings, mergeFields: savedMergedFields };
}
