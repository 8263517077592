/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import { Text } from "hw/ui/text";
import Modal, { ACTION_TYPE } from "hw/ui/modal";
import { Stack } from "hw/ui/layout";
import type { FreeTrialStatus } from "hw/portal/modules/common/graphql/schema";
import FreeTrialStatusQuery from "hw/portal/modules/common/graphql/team/free-trial-status.graphql";
import FreeTrialStart from "hw/portal/modules/common/graphql/team/free-trial-start.graphql";

export const FREE_TRIAL_AVAILABLE = "FREE_TRIAL_AVAILABLE";
export const FREE_TRIAL_STARTED = "FREE_TRIAL_STARTED";
export const FREE_TRIAL_IN_PROGRESS = "FREE_TRIAL_IN_PROGRESS";

export type FreeTrialStatusQueryType = {
  data: { freeTrialInfo: FreeTrialStatus };
  loading?: boolean;
  refetch?: (...args: Array<any>) => any;
};

export function isOnFreeTrial(freeTrialInfo: FreeTrialStatus): boolean {
  return (
    freeTrialInfo.status === FREE_TRIAL_IN_PROGRESS && freeTrialInfo.length > 0
  );
}

export function isOnFreeTrialPackage(
  freeTrialInfo: FreeTrialStatus,
  currentPackage: string
): boolean {
  return (
    freeTrialInfo.status === FREE_TRIAL_IN_PROGRESS &&
    freeTrialInfo.length > 0 &&
    freeTrialInfo.packageName === currentPackage
  );
}

export function isFreeTrialAvailable(freeTrialInfo: {
  status: string;
}): boolean {
  return freeTrialInfo.status === FREE_TRIAL_AVAILABLE;
}

type Props = {
  isOpen: boolean;
  parentAction?: (hasAccepted?: boolean, status?: string) => void;
};

const messages = defineMessages({
  primaryAction: {
    id: "freetrialmodal.primaryaction.button.text",
    description:
      "modal, free trial modal, action, button text, asks user to click on the button to start the free trial",
    defaultMessage: "Start free trial",
  },
  secondaryAction: {
    id: "freetrialmodal.secondaryaction.button.text",
    description:
      "modal, free trial modal, action, button text, gives user chance to close modal",
    defaultMessage: "No thanks",
  },
  modalHeading: {
    id: "freetrialmodal.heading.text",
    description:
      "modal, free trial modal, header, informs user what this modal is about",
    defaultMessage: "Start 30-day trial",
  },
  unknownError: {
    id: "freetrialmodal.freetrialstart.error.message",
    description:
      "modal, error, informs user that something went wrong and provides link to contact sales team",
    defaultMessage:
      "Something went wrong. Please try again or contact <sales>sales team</sales>",
  },
});

// @ts-expect-error refactor
function FreeTrialModal({ isOpen, parentAction = null }: Props) {
  const intl = useIntl();
  const [isOpenLocal, setIsOpen] = React.useState(isOpen);
  const [errorText, setErrorText] = React.useState(null);
  const salesText = (...chunks: any[]) => (
    <a
      href="https://www.hellosign.com/form/helloworks-lets-talk"
      rel="noopener noreferrer"
    >
      {chunks}
    </a>
  );

  // @ts-expect-error refactor
  const {
    // @ts-expect-error refactor
    data: { freeTrialInfo } = {},
    loading: isFreeTrialLoading,
  }: FreeTrialStatusQueryType = useQuery(FreeTrialStatusQuery);

  const [startFreeTrial] = useMutation(FreeTrialStart);

  React.useEffect(() => setIsOpen(isOpen), [isOpen]);

  const onClose = React.useCallback(() => {
    if (parentAction) {
      parentAction();
    } else {
      setIsOpen(false);
    }
  }, [parentAction]);

  const onAccept = React.useCallback(async () => {
    const {
      data: { startFreeTrial: trialInfo },
      // @ts-expect-error refactor
      isLoading,
    } = await startFreeTrial();
    if (!isLoading && trialInfo.status === FREE_TRIAL_STARTED) {
      if (parentAction) {
        parentAction(true, trialInfo.status);
      } else {
        onClose();
      }
    } else {
      setErrorText(
        // @ts-expect-error refactor
        intl.formatMessage(messages.unknownError, { sales: salesText })
      );
    }
  }, [startFreeTrial, intl, parentAction, onClose]);

  if (
    !isOpenLocal ||
    (!isFreeTrialLoading &&
      (freeTrialInfo.length > 0 ||
        freeTrialInfo.status !== FREE_TRIAL_AVAILABLE))
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        heading={intl.formatMessage(messages.modalHeading)}
        primaryAction={{
          text: intl.formatMessage(messages.primaryAction),
          onClick: onAccept,
          type: ACTION_TYPE.CONFIRM,
          dataTrackId: "free_trial_modal-start_trial",
        }}
        secondaryAction={{
          text: intl.formatMessage(messages.secondaryAction),
          onClick: onClose,
          dataTrackId: "free_trial_modal-cancel",
        }}
        onClose={onClose}
        portalExtend={{ zIndex: 1000 }}
      >
        <div>
          <Stack space="md">
            <div>
              <Text>
                <FormattedMessage
                  id="freetrialmodal.primary.marketing.text.continue"
                  description="modal, free trial modal, marketing, promotes free trial to a user for 30 days"
                  defaultMessage="Try our Standard plan with a free 30-day trial. No credit card required."
                />
              </Text>
            </div>
            <div>
              <Text variant="body1">{errorText}</Text>
            </div>
          </Stack>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default FreeTrialModal;
