import Task from "hw/common/utils/task";
// This is the amount of time we want to wait before cleaning up if the original
// scroll callback is never called
const FALLBACK_TIMEOUT_MS = 3000;

type Timer = ReturnType<typeof setTimeout>;

/**
 * This is a specialized `scrollIntoView` function that returns a `Promise`
 * that resolves when the scrolling has completed. Strangely, there's no
 * built-in way to get this information, so we have to hack around it. This
 * is useful when you need to scroll to something and then trigger another
 * event after the scroll has stopped. If you don't need that callback, you
 * are likely better off using the native `scrollIntoView`
 *
 * Note that this is more of an aproximation and not an exact callback. We wait for a
 * scroll event to stop and assume that once it stops we've reached the element.
 * It doesn't do anything with deteremining if the element is actually in
 * view.
 *
 * @example
 * const task = scrollIntoView(container, element);
 *
 * task.fork(function onReject() {
 *   // handle failure
 * }, function onSuccess() {
 *   // Run your callback
 * })
 */
export function scrollIntoView(
  scrollContainer: HTMLElement,
  targetEl: Element,
  scrollIntoViewOptions?: ScrollIntoViewOptions
) {
  return Task.from<unknown, void>((reject, resolve) => {
    const inView = isInView(scrollContainer, targetEl);

    if (inView) {
      // If already in view, resolve immediately
      resolve();
      return () => {};
    }

    // Otherwise, setup a one-time scroll listener that resolves when the
    // container scroll events have stopped. There's no exact event for this, so
    // we have to use timeouts to infer it.

    let timeout: Timer;
    let fallbackTimeout: Timer;

    function cancel() {
      clearTimeout(timeout);
      clearTimeout(fallbackTimeout);
      scrollContainer.removeEventListener("scroll", onScroll);
    }

    // Setup a timeout to clean up if we never get the `onScroll` end. This
    // avoids leaving dangling event listeners.
    fallbackTimeout = setTimeout(() => {
      // If the timeout hasn't been cleared in this amount of time, assume
      // something went wrong and clear it
      cancel();
      reject();
    }, FALLBACK_TIMEOUT_MS);

    function onScroll() {
      clearTimeout(timeout);

      timeout = setTimeout(
        () => {
          cancel();
          resolve();
        },
        // bit of a magic number, but based on a frame rate of 15 frames per second
        66
      );
    }

    scrollContainer.addEventListener("scroll", onScroll);

    targetEl.scrollIntoView({
      block: "center",
      ...scrollIntoViewOptions,
    });

    // NOTE: Cancel does not actually cancel the scroll operation, but just
    // cleans up the listeners and cancels the callbacks
    return cancel;
  });
}

function isInView(container: HTMLElement, elem: Element) {
  const containerRect = getRect(container);
  const elemRect = getRect(elem);

  return (
    elemRect.top >= containerRect.top && elemRect.bottom <= containerRect.bottom
  );
}

function getRect(el: Element | Window) {
  if (el === window) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    return {
      top: 0,
      left: 0,
      x: 0,
      y: 0,
      right: width,
      bottom: height,
      height,
      width,
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return el.getBoundingClientRect();
  }
}
