import * as React from "react";
import { IntlProvider } from "react-intl";
import StyleProvider from "hw/ui/style";
import defaultTheme from "hw/ui/theme";

type Props = {
  children: React.ReactNode;
};

export default function Providers(props: Props) {
  const { children } = props;

  return (
    <IntlProvider locale="en" messages={{}}>
      <StyleProvider theme={defaultTheme}>{children}</StyleProvider>
    </IntlProvider>
  );
}
