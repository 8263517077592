import { createComponent } from "hw/ui/style";
import { TRASH_ICON_WIDTH } from "../constants";

const LIST_ORDER_SIZE = "32px";

/**
 * Displays an indicator describing where an element will be dropped within the
 * list
 */
export const PositionIndicator = createComponent(
  function OrderableListPositionIndicator({ theme, position, active }) {
    return {
      transition: "opacity 150ms",
      position: "absolute",
      [position]: 0,
      opacity: active ? 1 : 0,
      // This offset needs to be half the value of the `marginBottom` of the
      // container
      transform: `translateY(${position === "top" ? "-" : ""}5px)`,
      left: "-2px",
      width: "calc(100% + 2px)",
      height: "2px",
      backgroundColor: theme.color.blue300,
      borderRadius: theme.corner.round,
      ":before": {
        content: "''",
        width: "4px",
        height: "4px",
        display: "block",
        marginTop: "-1px",
        backgroundColor: theme.color.blue300,
        borderRadius: theme.corner.round,
      },
    };
  }
);

/**
 * Wraps the inner content of the component
 */
export const InnerContainer = createComponent(
  function OrderableListInnerContainer({ isOver }) {
    return {
      opacity: isOver && 0.5,
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    };
  },
  "div",
  ["onMouseEnter", "onMouseLeave"]
);

/**
 * Wraps the input field and sequence of the component
 */
export const InputContainer = createComponent(
  function OrderableListInputContainer() {
    return {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ":hover": {},
    };
  }
);

/**
 * Wraps the content of the item
 */
export const ContentWrapper = createComponent(
  function OrderableListContentWrapper({ isModal = false }) {
    return {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: isModal ? "344px" : "0", // TODO: short-term magic number. Refactor as part of HWD-3179 for better alignment with Add button
    };
  }
);

/**
 * Wraps the item
 */
export const ItemWrapper = createComponent(function ItemWrapper({ compacted }) {
  return {
    width: compacted ? `calc(100% - ${TRASH_ICON_WIDTH + 8}px)` : "100%",
  };
});

/**
 * Wraps the entire contents of the component
 */
export const Container = createComponent(
  function OrderableListContainer({ theme }) {
    return {
      // This value links to the position indicator offset
      marginBottom: theme.space.sm,
      position: "relative",
      width: "100%",
    };
  },
  "div",
  ["data-automation-id"]
);

/**
 * Displays the order description of the item
 */
export const Order = createComponent(function OrderableListOrder({
  theme,
  isDragging,
}) {
  return {
    borderRadius: theme.corner.sm,
    borderColor: theme.color.dividerDim,
    borderStyle: "solid",
    borderWidth: "1px",
    color: theme.color.gray500,
    fontSize: theme.fontSize.sm,
    fontWeight: theme.fontWeight.semibold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: LIST_ORDER_SIZE,
    minHeight: "20px",
    height: "100%",
    cursor: isDragging ? "grabbing" : "grab",
    backgroundColor: theme.color.gray025,
    marginRight: theme.space.sm,
  };
});
export const MetaWrapper = createComponent(function MetaWrapper({ theme }) {
  return {
    marginTop: theme.space.xs,
    marginLeft: `calc(${LIST_ORDER_SIZE} + ${theme.space.sm})`,
    marginBottom: theme.space.sm,
  };
});

/**
 * Optional space allocated to the right side of input field for additional
 * content, such as participant group icons
 */
export const MetaSideContentWrapper = createComponent(
  function MetaSideContentWrapper({ theme }) {
    return {
      marginLeft: theme.space.xs,
    };
  }
);
