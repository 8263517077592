/**
 * This is a module for interacting with a group of rects as a single unit.
 * Generally, a group of rects can be treated as just one big rect by
 * drawing the rect around the group and passing that to existing functions
 * that expect a `Field` type rect.
 */
import { invariant } from "hw/common/utils/assert";
import {
  sortFieldsBy,
  expandRect,
  assign,
  ensureInPage,
  getCollisions,
} from "./utils";
import type { Field } from "./types";

export class Group {
  rects: Field[];

  rect: Field;

  static of(rects: Field[]) {
    return new Group(rects);
  }

  constructor(rects: Field[]) {
    this.rects = rects;
    this.rect = combineRects(rects);
  }

  /**
   * Ensures that the group of rects fits within the page that's determined to
   * encapsulate the group. Note that this does not move the group between
   * pages.
   */
  ensureInPage(pages: Array<{ width: number; height: number }>) {
    const page = pages[this.rect.page - 1];

    invariant(page);

    const adjustedRect = ensureInPage(this.rect, page);

    return this.applyDiff(adjustedRect);
  }

  /**
   * Take a new `rect` like object and reflect the difference onto the
   * current rect.
   */
  applyDiff(rect: Field) {
    const diff = diffRects(this.rect, rect);
    const newRects = [];

    for (const rect of this.rects) {
      newRects.push(
        assign(rect, {
          x: rect.x - diff.x,
          y: rect.y - diff.y,
          page: rect.page - diff.page,
        })
      );
    }

    this.rects = newRects;
    this.rect = combineRects(newRects);

    return this;
  }

  ungroup() {
    return this.rects;
  }

  /**
   * Tries to place the given `rect` into the group next to the `nextTo` rect.
   * For simplicity, this method _only_ cares about collisions within the group
   * and does not care if the placement is outside of the boundaries of the
   * page. It will attempt to place the `rect` to the right of the `nextTo`
   * rect, and if collisions are found, the next item in the group becomes
   * the `nextTo`. This means that the placed rect may end up off the page, but
   * given that we are always placing to the right, the fields should always be
   * reachable for users to drag to a new position.
   */
  insert(rect: Field, { nextTo }: { nextTo: Field }) {
    const gap = 4;

    const placeWithoutCollisions = (rect: Field, nextTo: Field) => {
      const placed = assign(rect, {
        x: nextTo.right + gap,
        y: nextTo.y,
      });

      const hasCollisions = getCollisions([...this.rects, placed]).length > 0;

      if (!hasCollisions) return placed;
    };

    const initialPlacement = placeWithoutCollisions(rect, nextTo);

    if (initialPlacement) {
      this.rects.push(initialPlacement);
      return this;
    }

    // The first try didn't work, so loop through each rect in the group and
    // try to place it next to that item. This will always fall back to the
    // rect being placed after the very last item in the group, but it may be off
    // the page
    const startingIndex = this.rects.findIndex((r) => r.id === nextTo.id);
    for (const existingRect of this.rects.slice(startingIndex + 1)) {
      const placed = placeWithoutCollisions(rect, existingRect);
      if (placed) {
        this.rects.push(placed);
        break;
      }
    }

    return this;
  }
}

function diffRects(a: Field, b: Field) {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
    page: a.page - b.page,
  };
}

/**
 * Tries to take a group of rects and draw the surrounding rectangle. The
 * `page` value is computed based on the most common `page` property in the
 * group
 *
 *    *---*  *---*
 *    |   |  |   |
 *    *---*  *---*
 *              *---*
 *              |   |
 *              *---*
 *
 *    ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓
 *
 *    *-------------*
 *    |             |
 *    |             |
 *    |             |
 *    |             |
 *    *-------------*
 */

function combineRects(rects: Field[]): Field {
  const topMost = sortFieldsBy(rects, "top");
  const leftMost = sortFieldsBy(rects, "left");
  const rightMost = sortFieldsBy(rects, "right").reverse();
  const bottomMost = sortFieldsBy(rects, "bottom").reverse();

  invariant(topMost[0]);
  invariant(leftMost[0]);
  invariant(rightMost[0]);
  invariant(bottomMost[0]);

  const mostCommonPage = extractPage(rects);
  const x = leftMost[0].x;
  const y = topMost[0].y;
  const width = rightMost[0].right - x;
  const height = bottomMost[0].bottom - y;

  return expandRect({
    id: `${topMost[0].id}-expanded`,
    page: mostCommonPage,
    x,
    y,
    width,
    height,
    lockAspectRatio: false,
  });
}

function extractPage(rects: Field[]) {
  const counts: { [page: string]: number } = {};

  invariant(rects[0]);

  let maxPage = rects[0].page;
  let maxCount = 1;

  for (const rect of rects) {
    if (!counts[rect.page]) {
      counts[rect.page] = 1;
    } else {
      counts[rect.page] += 1;
    }

    const pageCount = counts[rect.page];

    invariant(pageCount);

    if (pageCount > maxCount) {
      maxCount = pageCount;
      maxPage = rect.page;
    }
  }

  return maxPage;
}
