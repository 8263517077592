import camelCase from "lodash/camelCase";
import { combineRules, createComponent } from "hw/ui/style";
import Theme from "hw/ui/theme";
import { TransitionStatus } from "react-transition-group";
import type { Props } from "../hover-card";
import { Position } from "../types";

type StyleProps = {
  theme: typeof Theme;
  transitionState: "entering" | "entered" | "exiting" | "exited";
};

export const TRANSITION_DURATION = 150;
export const TRANSFORM_AMT = 4;
export const ARROW_HEIGHT = 6;

const leftOrRight = (pos: Position) => pos === "left" || pos === "right";
const topOrBottom = (pos: Position) => pos === "top" || pos === "bottom";
const oppositePosition = (pos: Position) =>
  ({
    top: "bottom",
    bottom: "top",
    left: "right",
    right: "left",
  }[pos]);

const transformForPosition = (pos: Position) =>
  ({
    right: `translate3d(-${TRANSFORM_AMT}px, 0, 0)`,
    left: `translate3d(${TRANSFORM_AMT}px, 0, 0)`,
    top: `translate3d(0, ${TRANSFORM_AMT}px, 0)`,
    bottom: `translate3d(0, -${TRANSFORM_AMT}px, 0)`,
  }[pos]);

const transitionStyle = (position: Position, state: TransitionStatus) => ({
  opacity: state === "entered" ? 1 : 0,
  transform:
    state === "entered"
      ? "translate3d(0, 0, 0)"
      : transformForPosition(position),
});

/**
 * Base Styles - applied to all variations
 */
const BaseStyle = (props: StyleProps & Props) => {
  const { transitionState, position, shouldAnimate } = props;

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    opacity: 0,
    pointerEvents: !props.active && "none",
    transitionProperty: shouldAnimate ? "transform, opacity" : "none",
    transitionDuration: `${TRANSITION_DURATION}ms`,
    borderRadius: "2px",
    position: "absolute",
    zIndex: props.theme.layer.hoverCard,
    ...transitionStyle(position, transitionState),
  };
};

const dropdownShadow =
  "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)";

/**
 * Dropdown styles
 */
const Dropdown = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  base: combineRules(BaseStyle, ({ theme }: StyleProps & Props) => ({
    background: theme.color.white,
    boxShadow: dropdownShadow,
    borderRadius: theme.corner.sm,
    minWidth: "120px",
  })),
};

const Select = {
  base: combineRules(Dropdown.base, (_props: StyleProps & Props) => ({
    minWidth: undefined,
    maxHeight: "300px",
    overflowY: "auto",
  })),
};

/**
 * Tooltip styles
 */
const Tooltip = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  base: combineRules(BaseStyle, (props: StyleProps & Props) => ({
    backgroundColor: props.theme.color.gray900,
    lineHeight: props.theme.fontSize.md,
    color: props.theme.color.white,
    fontSize: props.theme.fontSize.sm,
    fontWeight: props.theme.fontWeight.semibold,
    paddingLeft: props.theme.space.sm,
    paddingRight: props.theme.space.sm,
    paddingTop: props.theme.space.xs,
    paddingBottom: props.theme.space.xs,
    width: "auto",
    ":after": {
      content: '" "',
      borderStyle: "solid",
      borderColor: "transparent",
      borderWidth: `${ARROW_HEIGHT - 2}px`,
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
    },
    ":before": {
      borderStyle: "solid",
      borderColor: "transparent",
      borderWidth: `${ARROW_HEIGHT}px`,
      content: '" "',
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
    },
  })),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  position: combineRules((props: StyleProps & Props) => {
    /* $FlowFixMe[incompatible-use] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    const { position = "bottom", theme } = props;
    const opposingPosition = oppositePosition(position);
    const margin = camelCase(`margin-${opposingPosition}`);
    const borderProp = camelCase(`border-${position}-color`);

    return {
      [margin]: `${ARROW_HEIGHT}px`,
      ":before": {
        [position]: "100%",
        [borderProp]: theme.color.black,
        marginLeft: topOrBottom(position) ? `-${ARROW_HEIGHT}px` : null,
      },
      ":after": {
        [position]: "100%",
        [borderProp]: theme.color.black,
        marginLeft: topOrBottom(position) ? `-${ARROW_HEIGHT - 2}px` : null,
      },
    };
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  justify: combineRules((props: StyleProps & Props) => {
    const { position = "bottom", justify = "left" } = props;

    const styles = {
      left: {
        ":before": {
          left: "20%",
        },
        ":after": {
          left: "20%",
        },
      },
      right: {
        ":before": {
          left: "80%",
        },
        ":after": {
          left: "80%",
        },
      },
      center: {
        ":before": {
          left: "50%",
        },
        ":after": {
          left: "50%",
        },
      },
    };

    return topOrBottom(position) ? styles[justify] : {};
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  align: combineRules((props: StyleProps & Props) => {
    const { position = "bottom", align = "top" } = props;
    const styles = {
      top: {
        ":before": {
          top: 0,
          marginTop: `${ARROW_HEIGHT - 2}px`,
        },
        ":after": {
          top: 0,
          marginTop: `${ARROW_HEIGHT}px`,
        },
      },
      center: {
        ":before": {
          top: "50%",
          marginTop: `-${ARROW_HEIGHT}px`,
        },
        ":after": {
          top: "50%",
          marginTop: `-${ARROW_HEIGHT - 2}px`,
        },
      },
      bottom: {
        ":before": {
          bottom: "5%",
          marginTop: `-${ARROW_HEIGHT - 2}px`,
        },
        ":after": {
          bottom: "5%",
          marginTop: `-${ARROW_HEIGHT}px`,
        },
      },
    };

    return leftOrRight(position) ? styles[align] : {};
  }),
};

const Types = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dropdown: combineRules(Dropdown.base),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  select: combineRules(Select.base),
  tooltip: combineRules(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Tooltip.base,
    Tooltip.position,
    Tooltip.justify,
    Tooltip.align
  ),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HoverCardContentStyle = (props: Props, ...rest: Array<any>) => {
  const rule = Types[props.type || "dropdown"];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return rule(props, ...rest);
};

export const HoverCardContent = createComponent(HoverCardContentStyle, "div", [
  "data-testid",
  "data-hwui-hovercard",
]);
export const HoverCardWrapper = createComponent(function HoverCardWrapper(
  props
) {
  return {
    position: "relative",

    // TODO: Determine why this needs to be `inline-block`
    display: "inline-block",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    width: props.fillContainer && "100%",
  };
});
