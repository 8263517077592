import * as React from "react";

export default function OrderedList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="list-ordered-icon-24-a"
          d="M9 6h10a1 1 0 0 1 0 2H9a1 1 0 1 1 0-2zm0 5h10a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2zm0 5h10a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2zM4.746 9h-.5a.25.25 0 0 1-.25-.25V6.257a.25.25 0 0 0-.25-.25h-.543a.207.207 0 0 1-.207-.207v-.064a.25.25 0 0 1 .122-.215l.819-.486A.25.25 0 0 1 4.065 5h.681a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25zm1 5h-2.5a.25.25 0 0 1-.25-.25v-.436a.5.5 0 0 1 .278-.448c1.154-.571 1.731-1.062 1.731-1.473 0-.164.006-.507-.509-.507-.279 0-.441.135-.486.405a.25.25 0 0 1-.247.209H3.26a.25.25 0 0 1-.249-.278c.09-.815.586-1.222 1.485-1.222 1.494 0 1.5 1.286 1.5 1.393-.004.591-.443 1.127-1.319 1.607h1.069a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25zm-1.858 2.345l.93-.409a.1.1 0 0 0-.04-.191H3.25a.25.25 0 0 1-.25-.25v-.245a.25.25 0 0 1 .25-.25h2.5a.25.25 0 0 1 .25.25v.602a.25.25 0 0 1-.14.225L5 16.5c.667.12 1 .534 1 1.244C6 18.317 5.949 19 4.5 19c-.899 0-1.394-.362-1.486-1.087a.15.15 0 0 1 .149-.169h.57a.25.25 0 0 1 .25.222c.024.218.196.328.517.328.623 0 .623-.295.623-.55 0-.399-.207-.647-.623-.744h-.512a.25.25 0 0 1-.25-.25v-.176c0-.1.06-.19.15-.23z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#list-ordered-icon-24-a" />
      </g>
    </svg>
  );
}
