import * as React from "react";
import { A } from "hw/ui/text";
import theme from "hw/ui/theme";
import * as heap from "hw/portal/modules/analytics/heap";
import Constants from "./constants";

const { CSV_COMMENTS } = Constants;
const sampleUsers = [
  { name: "Jane Smith", email: "jsmith@domain" },
  { name: "John Smith", email: "jsmith2@domain" },
  { name: "Marry Doe", email: "mdoe@domain" },
];

function downloadClicked() {
  heap.track(heap.EVENTS.launchPage.bulkSendDownloadClicked);
}

type Props = {
  guid: string;
  name: string;
  numberOfRoles: number;
  headers: Array<string>;
};

export default function SampleCsv(props: Props) {
  const { guid, name, numberOfRoles, headers } = props;

  // By filling the sample row to the expected length the csv will contain a blank entry for the cell, this lets us
  // assume that the sample data will always have a length of headers.length OR that the user used a comma in their data
  // which we can catch
  const getSampleData = () => {
    const sampleData = new Array(headers.length).fill("");

    for (let i = 0; i < numberOfRoles; i++) {
      // @ts-expect-error refactor
      sampleData[i * 2] = sampleUsers[i % sampleUsers.length].name;
      // @ts-expect-error refactor
      sampleData[i * 2 + 1] = sampleUsers[i % sampleUsers.length].email;
    }

    return sampleData.join(",");
  };

  const encodedUri = encodeURI(
    `data:text/csv;charset=utf-8,${[
      [guid, name].join(","),
      CSV_COMMENTS,
      headers.join(","),
      getSampleData(),
    ].join("\n")}`
  );

  return (
    <A
      href={encodedUri}
      download={`${name} Bulk Send Template`}
      extend={{ fontSize: theme.fontSize.md }}
      data-track-id="bulk-send-download-csv-template"
      onClick={downloadClicked}
    >
      Download template
    </A>
  );
}
