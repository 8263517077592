// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { NodeSelection } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import { getIn } from "timm";

/**
 * If a data node is encountered when backspacing, instead of immediately
 * deleting the entire node, select it first so it's visually distinct and
 * obvious that the node will be deleted on the next backspace.
 */
function backspaceKeymap(state, dispatch) {
  const { doc, selection } = state;
  const previousNode = getIn(state, ["selection", "$from", "nodeBefore"]);

  if (previousNode && previousNode.type === state.schema.nodes.data) {
    const newSelection = NodeSelection.create(doc, selection.from - 1);
    const tr = state.tr.setSelection(newSelection);

    dispatch(tr);
    return true;
  }

  return false;
}

export function createPlugin() {
  return [
    keymap({
      Backspace: backspaceKeymap,
    }),
  ];
}
