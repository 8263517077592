import * as Sentry from "@sentry/browser";
import type { BrowserTracing } from "@sentry/tracing";
import type { Event, EventHint, Scope, Integration } from "@sentry/types";
import * as Tags from "./tags";

export { Tags, Sentry };

const isProd = process.env.NODE_ENV === "production";

type SentryUser = {
  id: string;
  team?: string;
  internal?: boolean;
};

/**
 * We need to dynamically determine which environment we're in based on the URL
 * because we deploy the same asset bundle to all environments
 *
 * Assumes we're running in a subdomain for each environment
 */
export function getEnvironment(hostname: string) {
  const envs = [
    { re: /\.helloworks\.com/, value: "prod" },
    { re: /\.staging-helloworks\.com/, value: "staging" },
    { re: /\.qa-helloworks\.com/, value: "qa" },
    { re: /\.dev-helloworks\.com/, value: "dev" },
    { re: /localhost/, value: "dev" },
  ];
  const env = envs.find((config) => config.re.test(hostname));

  return env?.value ?? "omni";
}

interface SentryInitOptions {
  userId?: string;
  teamGuid?: string;
  isInternal?: boolean;
  tracingIntegration?: BrowserTracing;
}

export default function init(options: SentryInitOptions = {}) {
  const { userId, teamGuid, isInternal, tracingIntegration } = options;
  const SENTRY_DSN = process.env.SENTRY_DSN;
  const RELEASE_NUMBER = process.env.SENTRY_RELEASE || "";
  const COMMIT_HASH = process.env.COMMIT_HASH || "";

  Sentry.init({
    dsn: SENTRY_DSN,

    // Set this to `true` if you want to try out Sentry locally
    enabled: isProd,

    // The 'release' value in Sentry. This is used to tie our bundled assets to
    // the source maps we send Sentry at build time
    release: RELEASE_NUMBER,
    environment: getEnvironment(window.location.hostname),
    integrations: (defaultIntegrations) => {
      const integrations: Integration[] = [
        ...defaultIntegrations.filter(
          (integration) => integration.name !== "Breadcrumbs"
        ),

        // The `Breadcrumbs` integration captures breadcrumbs before sending
        // events to Sentry. Capturing console logs by default is probably not a
        // great idea for PII reasons, but also means all of our console logs
        // will be wrapped in Sentrys instrumentation which makes things harder
        // to debug.
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
      ];

      if (tracingIntegration) {
        integrations.push(tracingIntegration);
      }

      return integrations;
    },

    initialScope: {
      tags: {
        commit_hash: COMMIT_HASH,
      },
    },
    sampleRate: 0.75,

    // Configure client-side sampling for our transactions based on environment.
    // Note, we can also further sample on Server-Side:
    // see: https://docs.sentry.io/product/data-management-settings/server-side-sampling/
    tracesSampleRate: 0.1,

    denyUrls: [/maps\.googleapis\.com/],
    ignoreErrors: [
      // This is not an actionable error. It means the browser couldn't
      // complete work in a single frame, so it's more of a warning
      "ResizeObserver loop limit exceeded",

      // This is apparently an issue with Google translate, which may be part
      // of an extension, or built into Chrome directly. I have not been able to
      // reproduce directly and given that there is currently no action to
      // take here, it's only contributing noise.
      // See: https://github.com/airbnb/lottie-web/issues/1134#issuecomment-565808141
      "a[b].target.className.indexOf is not a function",

      // This seems to be an error thrown from an Office 365 "Safe Link"
      // feature. As far as I can tell this is not actionable.
      //
      // See: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      "Non-Error promise rejection captured",
    ],

    beforeBreadcrumb(breadcrumb, hint) {
      // Try to add the operation name to the `fetch` breadcrumb
      if (breadcrumb.category === "fetch") {
        try {
          const isGraphQL = hint?.input[0].includes("graphql");
          if (isGraphQL) {
            const operationName = JSON.parse(hint?.input[1].body).operationName;

            if (operationName) {
              // @ts-expect-error refactor
              breadcrumb.data.operationName = operationName;
            }
          }
        } catch (_err) {
          //
        }
      }
      return breadcrumb;
    },

    beforeSend(sentryEvent, hint) {
      return capEvents(sentryEvent, hint);
    },
  });

  if (typeof userId === "string") {
    const user: SentryUser = {
      id: userId,
    };

    if (typeof teamGuid === "string") {
      user.team = teamGuid;
    }

    if (typeof isInternal === "boolean") {
      user.internal = isInternal;
    }

    Sentry.setUser(user);
  }
}

/**
 * Caps individual exceptions per session. In most cases if a message is
 * being sent more than a few times per session it probably was not intended.
 * This prevents our Sentry limits from being reach unintentionally and avoids
 * excessive noise.
 */
const limit = 10;
const messageCounts: { [msg: string]: number } = {};

function capEvents(sentryEvent: Event, hint?: EventHint) {
  const message =
    typeof hint?.originalException === "string"
      ? hint?.originalException
      : hint?.originalException?.message;

  if (message) {
    messageCounts[message] = (messageCounts[message] ?? 0) + 1;

    // @ts-expect-error refactor
    if (messageCounts[message] > limit) {
      return null;
    }
  }

  return sentryEvent;
}

export function reportUnexpectedResponse(setScope: (scope: Scope) => void) {
  Sentry.withScope((scope) => {
    scope.setLevel("error");
    setScope(scope);
    Sentry.captureMessage("Unexpected response received from backend");
  });
}
