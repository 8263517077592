import * as React from "react";
import { withStyle } from "hw/ui/style";
import Theme from "hw/ui/theme";

type Props = {
  value: string;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  placeholder: string;
  maxLength?: number;
};
type FelaProps = {
  styles: {
    text: string;
    counter: string;
  };
  theme: typeof Theme;
};
type InternalProps = Props & FelaProps;

function TextArea(props: InternalProps) {
  const { value, onChange, placeholder, maxLength = 300, styles } = props;
  return (
    <div>
      <textarea
        className={styles.text}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: SyntheticEvent<HTMLInputElement, Event>)... Remove this comment to see the full error message
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
        maxLength={maxLength.toString()}
      />
      <span className={styles.counter}>
        {value ? maxLength - value.length : maxLength} characters left
      </span>
    </div>
  );
}

const textStyle = ({ theme }: FelaProps) => ({
  fontSize: theme.fontSize.ms,
  width: "100%",
  borderColor: theme.color.gray300,
  borderWidth: theme.corner.xs,
  borderRadius: theme.corner.sm,
  padding: theme.space.ms,
  resize: "none",
});

const counterStyle = ({ theme }: FelaProps) => ({
  float: "right",
  fontSize: theme.fontSize.sm,
  color: theme.color.gray300,
});

const Component: React.ComponentType<InternalProps> = withStyle({
  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ theme }: FelaProps) => { fontSize: ".875r... Remove this comment to see the full error message
  text: textStyle,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ theme }: FelaProps) => { float: string; f... Remove this comment to see the full error message
  counter: counterStyle,
})(TextArea);
export default Component;
