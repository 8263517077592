/**
 * NOTE: This file is meant to run on both Node and Browser environments
 */

/*
 * Converts a hex color to an rgba string
 */
exports.rgba = function rgba(hex, alpha) {
  const longFormHex = hex.replace(
    // Converts short form hex, ie "#FAB" to lng form ie "#FFAABB"
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (match, red, green, blue) => red + red + green + green + blue + blue
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(longFormHex);

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  throw new Error("Invalid color input" + hex);
};
