/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIn } from "timm";
import { isLegacy } from "hw/common/components/smartforms/address-group";

export function isNew(value: string) {
  return value === "";
}

/**
 * This function will attempt to extract a single data ref. Notes:
 *
 *   - It may not find one, so make sure to handle that result
 *   - It will only attempt if there is a single data ref in the value. It will
 *     not attempt if multiple data refs are found
 */
export function extractDataRef(value: string) {
  const re = /@([a-z][0-9a-zA-Z_]*)/g;
  const captures = allCaptures(value, re);

  if (captures.length > 1) return;

  return captures[0];
}

export const AddressGroup = {
  /**
   * Translates into the value property in the pdf mapper, which then translates into elixer code.
   * It takes street, address2, city and state and filters out any nil values.
   * Then it joins them all together using a comma and space.
   * Zip doesn't want the comma though so we have to repeat the process using the string street, addr2, city, state
   * string and zip. We filter again to remove zip if it is null, then join the two strings using a space.
   *
   * @type {string}
   */
  SingleLine(field: any) {
    const { children } = field;
    const isLeg = isLegacy(children);
    const getRefValue = (index: number) => {
      if (isLeg && index === 1) {
        return "nil";
      }

      const refIndex = index === 0 || !isLeg ? index : index - 1;
      const ref = getIn(children, [refIndex, "dataRef"]);
      return ref ? `@${ref}` : "nil";
    };

    // Weird formatting caused by prettier
    return `value filter([filter([${getRefValue(0)}, ${getRefValue(
      1
    )}, ${getRefValue(2)}, ${getRefValue(
      3
    )}], fn el -> el != nil end) |> join(", "), ${getRefValue(
      4
    )}], fn el -> el != nil end) |> join(" ")`;
  },

  // 123 Test Street, Apt. 1
  FullStreet(field: any) {
    const street1 = field.children[0];
    const street2 = field.children[1];
    return `value filter([@${street1.dataRef}, @${street2.dataRef}], fn el -> el != nil end) |> join(", ")`;
  },

  CityStateZip(field: any) {
    const city = field.children[2];
    const state = field.children[3];
    const zip = field.children[4];

    return `value filter([filter([@${city.dataRef}, @${state.dataRef}], fn el -> el != nil end) |> join(", "), @${zip.dataRef} ], fn el -> el != nil end) |> join(" ")`;
  },
  StreetAddress(field: any) {
    return `value @${field.children[0].dataRef}`;
  },
  StreetLine2(field: any) {
    return `value @${field.children[1].dataRef}`;
  },
  City(field: any) {
    return `value @${field.children[2].dataRef}`;
  },
  State(field: any) {
    return `value @${field.children[3].dataRef}`;
  },
  Zip(field: any) {
    return `value @${field.children[4].dataRef}`;
  },
};

export const MultipleChoice = {
  /**
   * Extracts a value from the format:
   *
   * value "#{filter(@dataRef, &(&1 == 'option'))} == 'option'
   */
  extractOptionFromList(value: string) {
    const re =
      /value "#{filter\(@(?:[a-z][0-9a-zA-Z_]*), &\(&1 == "(.*)"\)\)}" == "(.*)"/g;
    const captures = allCaptures(value, re);

    if (captures.length === 2 && captures[0] === captures[1])
      return captures[0];
  },

  /**
   * Extracts a value from the format:
   *
   * value @dataRef == 'option'
   */
  extractOption(value: string) {
    const re = /value @(?:[a-z][0-9a-zA-Z_]*) == "(.*)"/g;
    const captures = allCaptures(value, re);

    return captures[0] || MultipleChoice.extractOptionFromList(value);
  },
};

function allCaptures(str: string, re: RegExp) {
  const captures = [];
  let match = re.exec(str);

  while (match !== null) {
    captures.push(...Array.from(match.slice(1)));
    match = re.exec(str);
  }

  return captures;
}

/**
 * It escapes backslash and double quotes.
 */
export const escapeStr = (string: string) =>
  string.replace(/\\/g, "\\\\").replace(/"/g, '\\"');

export const Formats = {
  AddressGroup,
  MultipleChoice: {
    Checkbox(field: any, option: string) {
      const { dataRef, allowMultiple } = field;
      if (allowMultiple) {
        return `value "#{filter(@${dataRef}, &(&1 == "${escapeStr(
          option
        )}"))}" == "${escapeStr(option)}"`;
      } else {
        return `value @${dataRef} == "${escapeStr(option)}"`;
      }
    },
    Text(field: any) {
      const { dataRef, allowMultiple } = field;

      if (allowMultiple) {
        return `value "#{format(:list, @${dataRef}, ", ")}"`;
      } else {
        return Formats.single(dataRef);
      }
    },
  },
  single: (dataRef: string) => `value @${dataRef}`,
  date: (dataRef: string) =>
    `value "#{format(:date, @${dataRef}, "{0M}/{0D}/{YYYY}")}"`,
};
