import React from "react";
import IconWrapper, { DeleteIcon } from "hw/ui/icons";
import { VisuallyHidden } from "hw/ui/text";
import { useStyle } from "hw/ui/style";

type Props = {
  onClick: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFocus: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur: (...args: Array<any>) => any;
  visible: boolean;
};
export default function RemoveButton(props: Props) {
  const { onClick, onFocus, onBlur, visible } = props;
  const cn = useStyle(
    "remove-btn",
    visible
      ? {}
      : {
          position: "absolute",
          right: 0,
          opacity: 0,
          pointerEvents: "none",
        }
  );
  return (
    <div onFocus={onFocus} onBlur={onBlur} className={cn}>
      <IconWrapper onClick={onClick}>
        <VisuallyHidden>Remove</VisuallyHidden>
        <DeleteIcon />
      </IconWrapper>
    </div>
  );
}
