import zipWith from "lodash/zipWith";
import { updateIn, addLast, getIn } from "timm";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import type { FieldOption, Rule } from "./types";

/**
 * Gathers all warnings and errors into a structure that corresponds to the
 * `rule` structure: A list of error string lists with the error strings populated
 * based on which rule they refer to
 *
 * [
 *   [], // No errors for rule 1
 *   [{
 *     message: "'Classification Group' needs to appear in the workflow before this condition",
 *     pos: 1  // The position in the rule where the error occurs
 *   }]
 * ]
 *
 * This is a very specific and short-term solution for being able to display
 * error states for the expression builder.  Errors are provided by the backend
 * based on their original `path`, but rules are structured in a flat last.  We
 * need to determine which error corresponds to which rule and we do that here by
 * trying to extract the subject data ref from the error string.  Until we have
 * a better structure for errors, this seems like the best we can do.
 */
export function gatherFeedback(
  feedbacks: Array<DraftFeedback> = [],
  rules: Array<Rule>,
  subjectsByDataRef: Record<string, FieldOption>
) {
  const messages = feedbacks.map((fb) => fb.message);
  const serverFeedback = gatherServerFeedback(
    messages,
    rules,
    subjectsByDataRef
  );
  const browserFeedback = gatherBrowserFeedback(
    messages,
    rules,
    subjectsByDataRef
  );
  // $FlowFixMe
  // @ts-expect-error ts-migrate(2548) FIXME: Type 'unknown' is not an array type or does not ha... Remove this comment to see the full error message
  return zipWith(serverFeedback, browserFeedback, (a, b) => [...a, ...b]);
}

/**
 * Gathers the feedback from the server and attempts to determine which rule the
 * message applies to
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'messages' implicitly has an 'any' type.
function gatherServerFeedback(messages, rules, subjectsByDataRef) {
  // If we aren't able to associate an error with an individual rule, we just
  // append it to the last rule
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'list' implicitly has an 'any' type.
  const addToLastRule = (list, msg) =>
    updateIn(list, [rules.length - 1], (errs) => addLast(errs || [], msg));

  const init: Array<Array<string>> = new Array(rules.length).fill([]);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'list' implicitly has an 'any' type.
  return messages.reduce((list, str) => {
    const error = extractError(str, subjectsByDataRef);

    if (error.dataRef) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rule' implicitly has an 'any' type.
      const ruleIndex = rules.findIndex((rule) => rule[0] === error.dataRef);

      if (ruleIndex !== -1) {
        return updateIn(list, [ruleIndex], (errs) =>
          addLast(errs || [], error)
        );
      } else {
        return addToLastRule(list, error);
      }
    } else if (error.message) {
      return addToLastRule(list, error);
    }

    return list;
  }, init);
}

/**
 * This is feedback that's _only_ done on the client.  It will not prevent
 * publishing
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'messages' implicitly has an 'any' type.
function gatherBrowserFeedback(messages, rules, subjectsByDataRef) {
  const init: Array<Array<string>> = new Array(rules.length).fill([]);
  return init.map((ruleErrorSet, idx) => {
    const rule = rules[idx];
    return [...ruleErrorSet, validateOption(rule, subjectsByDataRef)].filter(
      Boolean
    );
  });
}

/**
 * Validates that a rule contains a value reference to a field option
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rule' implicitly has an 'any' type.
function validateOption(rule, subjectsByDataRef) {
  const [dataRef, , value] = rule;

  if (value) {
    const subject = subjectsByDataRef[dataRef];

    if (
      subject &&
      Array.isArray(subject.options) &&
      !subject.options.includes(value)
    ) {
      return {
        pos: 2,
        message: `'${value}' is no longer an option for '${
          subject.label || subject.dataRef
        }'`,
      };
    }
  }
}

/**
 * Attempts to extract a `dataRef` out of each individual error message string.
 * This is very brittle and depends on a specific string from the backend.
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
function extractError(str, subjects) {
  const attempts = [
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
    (str) => extractIncorrectOrderError(str, subjects),
    extractInvalidDataRefError,
  ];

  // @ts-expect-error ts-migrate(7023) FIXME: 'extract' implicitly has return type 'any' because... Remove this comment to see the full error message
  function extract(str, attemptNumber) {
    const attempt = attempts[attemptNumber];

    if (attempt) {
      const found = attempt(str);

      if (found) {
        return found;
      }

      return extract(str, attemptNumber + 1);
    }
  }

  const found = extract(str, 0);

  if (found) {
    return found;
  }

  return {
    message: str,
  };
}

/**
 * Extracts a data ref for a message regarding references to fields that come
 * after the target field
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
function extractIncorrectOrderError(str, subjects) {
  const re = /^'(.*)' needs to appear/;
  const match = str.match(re);
  // This could be either the field label or the data ref if the label does not exist
  const foundLabelOrDataRef = getIn(match, [1]);

  if (foundLabelOrDataRef) {
    const subject = findSubjectByLabelOrDataRef(foundLabelOrDataRef, subjects);

    if (subject) {
      return {
        dataRef: subject.dataRef,
        message: str,
        pos: 0,
      };
    }
  }
}

/**
 * Extract a data ref for a message regarding a data ref which no longer exists
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
function extractInvalidDataRefError(str) {
  const re = /This item references "(.*)" which no longer exists/;
  const match = str.match(re);
  const foundDataRef = getIn(match, [1]);

  if (foundDataRef) {
    return {
      dataRef: foundDataRef,
      message: str,
    };
  } else if (foundDataRef === "") {
    return {
      dataRef: foundDataRef,
      message: "The condition is incomplete",
    };
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'labelOrDataRef' implicitly has an 'any'... Remove this comment to see the full error message
function findSubjectByLabelOrDataRef(labelOrDataRef, subjectsByDataRef) {
  if (subjectsByDataRef[labelOrDataRef]) {
    return subjectsByDataRef[labelOrDataRef];
  }

  const dataRef = Object.keys(subjectsByDataRef).find(
    (dataRef) => subjectsByDataRef[dataRef].label === labelOrDataRef
  );
  return dataRef && subjectsByDataRef[dataRef];
}
