import React from "react";
import { Box } from "hw/ui/blocks";

const style = {
  borderColor: "dividerDim",
  borderBottomStyle: "solid",
  borderWidth: 1,
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export function TableRow(props) {
  const { className, onClick, dataAutomationId } = props;

  return (
    <Box
      {...style}
      as="tr"
      className={className}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; as: "tr"; className: any; o... Remove this comment to see the full error message
      onClick={onClick}
      data-automation-id={dataAutomationId}
    >
      {props.children}
    </Box>
  );
}

export default TableRow;
