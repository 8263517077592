import * as React from "react";

export default function PrintIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4H6V5V8H5C3.89543 8 3 8.89543 3 10V14C3 15.6569 4.34314 17 6 17L6 19V20H7H17H18V19V17C19.6569 17 21 15.6569 21 14V10C21 8.89543 20.1046 8 19 8H18V5V4H17H7ZM16 8V6H8V8H16ZM6 10H5V14C5 14.5523 5.44772 15 6 15L6 14V13H7H17H18V14V15C18.5523 15 19 14.5523 19 14V10H18H17H7H6ZM8 15V18H16V15H8Z"
        fill="#727A83"
      />
    </svg>
  );
}
