/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Box } from "hw/ui/blocks";
import focusScope from "a11y-focus-scope";
import focusStore from "a11y-focus-store";
import Button from "hw/ui/button";
import IconWrapper, { CloseIcon } from "hw/ui/icons";
import { Tooltip } from "hw/ui/tooltip";
import theme from "hw/ui/theme";
import Message from "hw/sf/components/common/message";
import {
  Body,
  Wrapper,
  Header,
  ActionsFooter,
  Footer,
  ModalPortal as Portal,
} from "./components/styled";
import { Heading3, VisuallyHidden } from "../text";
import Shade from "../shade";
import type { Action, ActionType } from "./types";
import { ACTION_TYPE } from "./constants";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

type Props = {
  /**
   * The content of the modal
   */
  children?: React.ReactNode;

  /**
   * A function that should return the element where the rest of the React
   * app is rendered.  This is used to to apply accessibility labels when
   * the modal is shown and hidden.
   */
  getApplicationElement: () => HTMLElement | null | undefined;

  /**
   * Extend the style of the main modal wrapper
   */
  extend?: any;

  /**
   * Extend the style of the outer portal container
   */
  portalExtend?: any;

  /**
   * Extend the style of the modal body
   */
  bodyExtend?: any;

  /**
   * Extend the style of the header
   */
  headerExtend?: any;

  /**
   * The heading of the modal
   */
  heading: React.ReactNode;

  /**
   * Called when the modal should be closed
   */
  onClose?: (...args: Array<any>) => any;

  /**
   * The primary action for the modal.  This will be rendered as a primary button
   */
  primaryAction?: Action;

  /**
   * The secondary action for the modal.  This will rendered as a "subtle"
   * button
   */
  secondaryAction?: Action;

  /**
   * Actions that go to the left side of the footer
   */
  extraActions?: React.ReactNode;

  /**
   * Custom width for the modal
   */
  width?: string;

  layer?: keyof typeof theme["layer"];

  /**
   * A convenience prop for storybook so the component doesn't continually
   * steal focus
   */
  handleFocus?: boolean;

  initialFocusRef?: React.RefObject<HTMLElement>;

  shade?: "off" | "on";
};

// Hides the application element and focuses the modal element
export function setFocusOn(
  applicationElement: HTMLElement | null | undefined,
  element: HTMLElement | null | undefined
) {
  focusStore.storeFocus();

  if (applicationElement) {
    applicationElement.setAttribute("aria-hidden", "true");
  }

  focusScope.scopeFocus(element);
}

// Unhides the application element and restores focus to its previous state
export function resetFocus(applicationElement: HTMLElement | null | undefined) {
  focusScope.unscopeFocus();

  if (applicationElement) {
    applicationElement.removeAttribute("aria-hidden");
  }

  focusStore.restoreFocus();
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'onClose' implicitly has an 'any' type.
function canClose(onClose): boolean {
  return typeof onClose === "function";
}

function buttonTypeFromAction(actionType?: ActionType) {
  switch (actionType) {
    case ACTION_TYPE.CONFIRM:
      return "primary";
    case ACTION_TYPE.DANGER:
      return "danger";
    default:
      return "primary";
  }
}

let count = 0;

function makeId() {
  return `modal:${count++}`;
}

/**
 * Displays content in a layer that sits on top of the rest of the page content.
 * Users must interact with the modal before interacting with anything else on the
 * page.
 *
 * Modals can have a primary and/or secondary action that are rendered as buttons
 * to the user. If an action is given, then the modal footer will be rendered
 * otherwise only the modal header and body will be rendered.
 *
 * Basic example:
 *
 * ```jsx
 * class MyCompoennt extends React.Component {
 *   state = {
 *     modalOpen: false,
 *   };
 *
 *   openModal = () => this.setState({ modalOpen: true });
 *   closeModal = () => this.setState({ modalOpen: false });
 *
 *   render() {
 *     const { modalOpen } = this.state;
 *
 *     return (
 *       <div>
 *         <button onClick={this.openModal}>Open Modal</button>
 *         {modalOpen && (
 *           <Modal onClose={this.closeModal} heading="The Modal Heading">
 *             Thte Modal Body
 *           </Modal>
 *         )}
 *       </div>
 *     );
 *   }
 * }
 * ```
 *
 * With Actions:
 *
 * ```jsx
 * class MyCompoennt extends React.Component {
 *   state = {
 *     modalOpen: false,
 *   };
 *
 *   openModal = () => this.setState({ modalOpen: true });
 *   closeModal = () => this.setState({ modalOpen: false });
 *
 *   render() {
 *     const { modalOpen } = this.state;
 *
 *     return (
 *       <div>
 *         <button onClick={this.openModal}>Open Modal</button>
 *         {modalOpen && (
 *           <Modal
 *             onClose={this.closeModal}
 *             heading="The Modal Heading"
 *             primaryAction={{
 *               text: "Decline",
 *               onClick: action("Primary clicked"),
 *             }}
 *             secondaryAction={{
 *               text: "Cancel",
 *               onClick: action("Secondary clicked"),
 *             }}
 *           >
 *             Thte Modal Body
 *           </Modal>
 *         )}
 *       </div>
 *     );
 *   }
 * }
 * ```
 *
 * If you want to restrict the modal from being able to close via the close button,
 * escape, or background click you can omit the `onClose` prop. This can be useful
 * if you want to ensure that the user does not accidentally close the modal. In
 * this case, you should make sure to provide other means to close the modal, like
 * a "Cancel" action:
 *
 * ```jsx
 * class MyCompoennt extends React.Component {
 *   state = {
 *     modalOpen: false,
 *   };
 *
 *   openModal = () => this.setState({ modalOpen: true });
 *   closeModal = () => this.setState({ modalOpen: false });
 *
 *   render() {
 *     const { modalOpen } = this.state;
 *
 *     return (
 *       <div>
 *         <button onClick={this.openModal}>Open Modal</button>
 *         {modalOpen && (
 *           <Modal
 *             heading="The Modal Heading"
 *             primaryAction={{
 *               text: "Decline",
 *               onClick: action("Primary clicked"),
 *             }}
 *             secondaryAction={{
 *               text: "Cancel",
 *               onClick: this.closeModal,
 *             }}
 *           >
 *             Thte Modal Body
 *           </Modal>
 *         )}
 *       </div>
 *     );
 *   }
 * }
```
 */
class Modal extends React.Component<Props> {
  id: string = makeId();

  static defaultProps = {
    getApplicationElement: () => document.getElementById("root"),
    handleFocus: true,
    layer: "modal",
  };

  focusNode = (node: HTMLElement) => {
    if (this.props.handleFocus) {
      setFocusOn(this.props.getApplicationElement(), node);

      const initialFocusElement = this.props.initialFocusRef?.current ?? null;
      if (initialFocusElement) {
        initialFocusElement.focus();
      }
      document.addEventListener("keydown", this.handleDocumentKeydown);
    }
  };

  componentWillUnmount() {
    resetFocus(this.props.getApplicationElement());
    document.removeEventListener("keydown", this.handleDocumentKeydown);
  }

  handleDocumentKeydown = (event: KeyboardEvent) => {
    // Escape
    if (event.keyCode === 27 && canClose(this.props.onClose)) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onClose();
    }
  };

  render() {
    const {
      children,
      heading,
      onClose,
      primaryAction,
      secondaryAction,
      width,
      extend,
      portalExtend,
      bodyExtend,
      headerExtend,
      layer,
      extraActions,
      shade = "on",
    } = this.props;
    const hasActions = Boolean(primaryAction || secondaryAction);

    return (
      <Portal layer={layer} extend={portalExtend}>
        {shade === "on" ? (
          <Shade onClick={canClose(onClose) ? onClose : noop} />
        ) : null}
        <Wrapper
          width={width}
          onMount={this.focusNode}
          role="dialog"
          aria-modal="true"
          id={this.id}
          aria-labelledby={this.id + "-header"}
          aria-describedby={this.id + "-body"}
          layer={layer}
          extend={extend}
        >
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: (... Remove this comment to see the full error message */}
          <Header id={this.id + "-header"} extend={headerExtend}>
            <Box flex={1} mr="ms">
              {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: a... Remove this comment to see the full error message */}
              <Heading3>{heading}</Heading3>
            </Box>
            {canClose(onClose) && (
              <Tooltip tip={<Message stringId="ui.modal.closeButton" />}>
                <IconWrapper onClick={onClose} aria-label="Close">
                  <VisuallyHidden>
                    <Message stringId="ui.modal.closeButton" />
                  </VisuallyHidden>
                  <CloseIcon />
                </IconWrapper>
              </Tooltip>
            )}
          </Header>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; id: string; extend: any; }'... Remove this comment to see the full error message */}
          <Body id={this.id + "-body"} extend={bodyExtend}>
            {children}
          </Body>
          {hasActions && (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: a... Remove this comment to see the full error message
            <Footer>
              {extraActions}
              <ActionsFooter>
                {secondaryAction && (
                  <Box mr="sm">
                    <Button
                      onClick={secondaryAction.onClick}
                      presentation="subtle"
                      data-automation-id="secondary-action"
                      data-track-id={secondaryAction.dataTrackId}
                    >
                      {secondaryAction.text}
                    </Button>
                  </Box>
                )}
                {primaryAction && (
                  /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment
                   * suppresses an error found when upgrading Flow to v0.132.0.
                   * To view the error, delete this comment and run Flow. */
                  <Button
                    onClick={primaryAction.onClick}
                    disabled={primaryAction.disabled}
                    presentation={buttonTypeFromAction(primaryAction.type)}
                    data-track-id={primaryAction.dataTrackId}
                    {...primaryAction.attrs}
                    data-automation-id="primary-action"
                  >
                    {primaryAction.text}
                  </Button>
                )}
              </ActionsFooter>
            </Footer>
          )}
        </Wrapper>
      </Portal>
    );
  }
}

export default Modal;
