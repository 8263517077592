export const IS_FILLED = "is filled";
export const IS_BLANK = "is blank";
export const IS = "is";
export const IS_NOT = "is not";
export const BASE_CONDITIONS = [IS_FILLED, IS_BLANK];
export const MULTIPLE_CHOICE_CONDITIONS = [IS, IS_NOT];
export const REQUIRED_CONDITIONS = [IS_FILLED];
export const EMPTY_RULE = [""];
export const GLOBAL_SELECTOR_ALL = "All";
export const GLOBAL_SELECTOR_ANY = "Any";
export const DEFAULT_GLOBAL_SELECTOR = GLOBAL_SELECTOR_ALL;
// methods
export const METHOD_ALL = "all";
export const METHOD_ANY = "any";
