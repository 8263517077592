import logger from "hw/common/utils/logger";
import theme from "./__generated__/theme";
import utils from "./__src__/shared-utils";

/**
 * A function for create a variant on a prop value for a specific property or
 * set of CSS properties
 *
 * @example
 * const fontSize = variantOn("appearance", {
 *   default: theme.fontSizes[2],
 *   compact: theme.fontSizes[1],
 * });
 *
 * function Component(props) {
 *   const className = useStyle({
 *     fontSize: fontSize(props),
 *   });
 * }
 * <Component appearance="compact" />;
 *
 * You can also use it with a group of styles:
 *
 * const groupOfStyles = variantOn("mode", {
 *   light: {
 *     color: "black",
 *     backgroundColor: "white",
 *   },
 *   dark: {
 *     color: "white",
 *     backgroundColor: "black",
 *   },
 * });
 *
 * function Label(props) {
 *   const cn = useStyle({
 *     fontSize: fontSize(props),
 *     ...groupOfStyles(props),
 *   });
 *
 *   return <label className={cn} />;
 * }
 *
 * NOTE: If you have multiple groups of styles, make sure there are not
 * conflicting properties in each group otherwise you may get unexpected
 * results when merging the object together.
 */
export function variantOn<PropName extends string, Variants>(
  propName: PropName,
  variants: Record<string, Variants>
) {
  return function variantOnProps(props: Record<string, unknown>) {
    const variant = props[propName];

    return typeof variant === "string" ? variants[variant] : undefined;
  };
}

export function hexToRGBA(hex = "", alpha = 1) {
  try {
    return utils.rgba(hex, alpha);
  } catch (err) {
    if (process.env.NODE_ENV !== "test") {
      logger.error("Invalid hex passed to RGBA, returning undefined");
    }
    return undefined;
  }
}

export default theme;
