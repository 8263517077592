import * as React from "react";
import { useStyle } from "hw/ui/style";

/**
 * When a flex item is meant to have scrolled overflow, the parent of that
 * flex item needs to have a `min-height: 0` set in order to properly calculate
 * the height.  This is part of an updated flexbox spec that is not present in
 * every browser.
 *
 * See:
 *   - https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox/28639686#28639686
 *   - https://bugzilla.mozilla.org/show_bug.cgi?id=1043520
 *   - https://drafts.csswg.org/css-flexbox/#min-size-auto
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function FlexOverflowParent(props: any) {
  const { extend, ...rest } = props;
  const cn = useStyle(
    "flex-overflow-parent",
    {
      minHeight: "0px",
    },
    extend
  );

  return <div {...rest} className={cn} />;
}
