/* eslint-disable @typescript-eslint/ban-types */
import { getIn } from "timm";
import isPlainObject from "lodash/isPlainObject";
import nanoid from "nanoid/non-secure";
import type { Form, ParsedSchema } from "hw/portal/modules/common/draft";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import * as utils from "./utils";

/**
 * Returns `true` if there are errors that can _only_ be addressed in the Code
 * view.  For now, that is every error.
 */
export function hasCodeErrors(errors: Array<DraftFeedback>) {
  return hasAnyErrors(errors);
}
export function hasAnyErrors(errors: Array<DraftFeedback>) {
  return Boolean(errors.length);
}

/**
 * This function is to guard against server validation errors that might make
 * the parsed schema views unusable.
 */
export function canRenderParsedView(errors: Array<DraftFeedback>) {
  // We use id properties for important rendering things like React `key` values
  // and other find-by-id lookups
  const hasIdErrors = errors.find((err) => err.type === "ids");
  return !hasIdErrors;
}
export function isFormValid(
  form: Form,
  index: number,
  errors: Array<DraftFeedback>
) {
  const anyErrors = errors.filter((err) => {
    return err.path.slice(0, 2).join(".") === ["forms", index].join(".");
  });
  return anyErrors.length === 0;
}

/**
 * Returns a list of role names that are currently unused in the draft schema
 *
 * TODO: I don't think we need both this and `getUnusedRoles`. Leaving for
 * now and we can retire when we deprecate the v1 editor.
 */
export function getUnusedRoleNames(
  warnings: Array<DraftFeedback>,
  parsedSchema: ParsedSchema
) {
  const unusedRoles = getUnusedRoles(warnings, parsedSchema);
  // @ts-expect-error ts-migrate(2555) FIXME: Expected at least 2 arguments, but got 1.
  const roleDescById = utils.roleDescriptionById(parsedSchema.roles || []);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
  return unusedRoles.map<string>((role) => roleDescById[role.id].title);
}

/**
 * Returns the list of unused roles in the draft
 */
export function getUnusedRoles(
  warnings: Array<DraftFeedback>,
  parsedSchema: ParsedSchema
) {
  const roleWarnings = warnings.filter(
    (warning) => warning.type === "roles" && warning.code === "unused"
  );
  const unusedRoles = roleWarnings.reduce((list, warning) => {
    // TODO: We should have the server return a more explicit unused roles
    // warning rather than matching on the warning message
    const hasUnusedWarning = warning.message.includes("is an unused role");

    if (hasUnusedWarning) {
      const role = getIn(parsedSchema, warning.path);
      // there is a brief moment immediately after removing unused roles from modal
      // where parsedSchema roles are updated, but warning.path needs time to
      // catch up until after the save happens and component re-renders,
      // e.g. remove bar from [foo, bar], roles becomes [foo]
      // but warning.path still references bar index
      // @ts-expect-error refactor
      if (role) list.push(role);
    }

    return list;
  }, []);
  return unusedRoles;
}

/**
 * This functions takes errors in the old, nested-object format and returns the
 * upcoming new format, which is flat list of objects.  This is not an ideal or
 * optimized function and is only temporary until we move the draft editor to
 * GraphQL which is already returning errors in this format.  This function is
 * meant to get the rest of the draft editor working with this new format before
 * moving to GraphQL
 */
export function shim(errorsInOldFormat: {} = {}) {
  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'output' implicitly has type 'any[]' in s... Remove this comment to see the full error message
  const output = [];

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
  function step(obj, prevPath = [], depth = 1, errorType = "unknown") {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const nextErrorType = depth === 1 ? key : errorType;
      const nextPath = depth === 1 ? prevPath : [...prevPath, key];

      if (isPlainObject(value)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        return step(value, nextPath, depth + 1, nextErrorType);
      } else if (Array.isArray(value)) {
        value.forEach((val) => {
          output.push({
            type: nextErrorType,
            message: val,
            id: nanoid(),
            code: "to-do",
            path: nextPath,
          });
        });
      } else {
        output.push({
          type: nextErrorType,
          message: value,
          id: nanoid(),
          code: "to-do",
          path: nextPath,
        });
      }
    });
  }

  step(errorsInOldFormat, [], 1);
  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'output' implicitly has an 'any[]' type.
  return output;
}
