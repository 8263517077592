import React from "react";
import { createComponent } from "hw/ui/style";
import AceEditor from "react-ace";

/* eslint-disable import/no-extraneous-dependencies */
import "brace/theme/tomorrow";
import "brace/mode/elixir";

/* eslint-enable import/no-extraneous-dependencies */
type Props = {
  height?: number;
  name: string;
  onChange: (newValue: string) => void;
  value: string;
  id: string;
};
const Wrapper = createComponent(function CodeEditorWrapper({ theme }) {
  return {
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: "8px",
    borderColor: theme.color.gray100,
    // We need this to make sure we render with a monospace font, otherwise
    // the cursor position gets messed up
    "& *": {
      fontFamily: theme.fontFamily.monospace,
    },
  };
});
/**
 * This is a mini code editor for use in the `value` and `coords` inputs.  There's
 * not much room, so the gutter and line numbers are gone.
 */

export default function CodeEditor(props: Props) {
  const { height = 100, name, value, onChange } = props;
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
    <Wrapper>
      <AceEditor
        editorProps={{
          $blockScrolling: true,
        }}
        fontSize={12}
        height={`${height}px`}
        highlightActiveLine={false}
        mode="elixir"
        name={name}
        onChange={onChange}
        showGutter={false}
        tabSize={2}
        theme="tomorrow"
        value={value}
        width="100%"
      />
    </Wrapper>
  );
}
