import React from "react";
import styles from "./table.module.css";

type Props = {
  header?: boolean;
  dimmed?: boolean;
  className?: string;
} & React.TdHTMLAttributes<HTMLTableCellElement>;

function TableCell(props: Props) {
  const { header, dimmed, className, ...rest } = props;
  const classes = [
    styles.td,
    className,
    header ? styles.header : "",
    dimmed ? styles.dimmed : "",
  ].join(" ");

  return <td className={classes} {...rest} />;
}

export default TableCell;
