/**
 * Arrangements are ways to arrange a group of rects in a various ways.
 *
 * NOTE:
 *   - This does not deal with the DOM at all and assumes all coordinate values
 *     are relative to the page that they are on
 *   - This does not handle ensuring that an arrangment fits within a particular
 *     page, so that must be handled separately.
 *   - The `rects` must be in the correct `Field` format, so conversion may be
 *     necessary
 *
 * @example
 * const arrangment = Arrangement({
 *   type: 'columns',
 * })
 * arrange(arrangment, rects)
 */
import { invariant } from "hw/common/utils/assert";
import { sortFields, sortFieldsBy, assign } from "./utils";
import type { Field } from "./types";

type Column = { width: number };

export type ArrangementType =
  | {
      type: "flex";
      rows: Array<Column[]>;
      minWidth?: number;
    }
  | {
      type: "columns";
      columns?: number;
    }
  | {
      type: "packed";
      maxWidth: number;
    };

export function Arrangement(type: ArrangementType): ArrangementType {
  return type;
}

type Options = {
  gap?: number;
  anchorPoint?: {
    x: number;
    y: number;
  };
  minWidth?: number;
};

export function arrange(
  arrangement: ArrangementType,
  rects: Field[],
  options: Options = {}
) {
  const gap = options.gap ?? 4;

  // Do a general-purpose sort on the fields so that they are in order of
  // x, y, and page
  const sorted = sortFields(rects, (rect) => rect);

  // The row height will be based on the tallest rect in the group
  const tallest = sortFieldsBy(rects, "height").reverse()[0];
  invariant(tallest);
  const rowHeight = Math.floor(tallest.height);

  invariant(sorted[0]);

  // Set the page for the arrangement to the first page in the sorted group
  const page = sorted[0].page;

  // The anchor point is the x,y coordinates for where the arrangement starts
  // By default, this is the first element in the sorted group, but a custom
  // vlaue can be specified
  const anchorPoint = options.anchorPoint ?? sorted[0];

  // Begin tracking the top coordinate for the arrangment
  let currentTop = anchorPoint.y;

  // This arrangment tries to arrange the elements in rows and columns with
  // each column having a specified width.
  //
  // *--------*     *--------*
  // |        |     |        |
  // *--------*     *--------*
  // *-----*  *-----*  *-----*
  // |     |  |     |  |     |
  // *-----*  *-----*  *-----*
  //
  // Used by `AddressGroup`
  if (arrangement.type === "flex") {
    const arranged = [];

    // The widest rect in the group will determine the maximum width for the
    // whole arrangement
    const widest = sortFieldsBy(rects, "width").reverse()[0];
    invariant(widest);
    const { rows } = arrangement;
    const baseWidth = options.minWidth ?? arrangement.minWidth ?? widest.width;

    // Loop through the specified rows and try to fit each rect into that row
    // at the specified width with a small gap between
    for (const row of rows) {
      // The 'effective' row width is the number that we use as the basis for
      // the percentage calculations. We subtract the gaps from the base width
      // so that the arrange is flush on the edges
      const gapCount = row.length - 1;
      const effectiveRowWidth = baseWidth - gapCount * gap;

      // No gap on this row if there's only one element
      const rowGap = row.length === 1 ? 0 : gap;

      let currentLeft = anchorPoint.x;

      for (const col of row) {
        const rect = sorted.shift();
        if (!rect) {
          // This would happen if more rects were provided than rows
          // configured
          break;
        }
        const width = Math.floor(effectiveRowWidth * col.width);

        const nextRect = assign(rect, {
          y: Math.floor(currentTop),
          x: Math.floor(currentLeft),
          width,
          height: rowHeight,
          page,
        });
        currentLeft += nextRect.width + rowGap;
        arranged.push(nextRect);
      }
      currentTop += rowHeight + gap;
    }

    return arranged;
  }

  // This arrangment will place each element in a specified amount of columns
  // Used by `MultipleChoice` checkboxes
  else if (arrangement.type === "columns") {
    const arranged = [];
    const { columns = Infinity } = arrangement;
    let rowLeft = anchorPoint.x;
    let columnCount = 0;

    for (const rect of sorted) {
      // Reach the column limit, so start over on the next row
      if (columnCount >= columns) {
        columnCount = 0;
        currentTop += tallest.height + gap;
        rowLeft = anchorPoint.x;
      }

      const nextRect = assign(rect, {
        y: Math.floor(currentTop),
        x: Math.floor(rowLeft),
        page,
      });
      rowLeft += nextRect.width + gap;
      columnCount += 1;
      arranged.push(nextRect);
    }
    return arranged;
  }

  // This arrangement tries to arrange as many elements in rows as possible
  // until the `maxWidth` is reached, at which point it starts over on a new
  // row.
  //                            * max width
  // *--------*  *-------*      |
  // |        |  |       |      |
  // *--------*  *-------*      |
  // *-----*  *-----*  *-----*  |
  // |     |  |     |  |     |  |
  // *-----*  *-----*  *-----*  |
  // *-------------*  *-------* |
  // |             |  |       | |
  // *------------ *  *-------* |
  //                            |
  else if (arrangement.type === "packed") {
    const arranged = [];
    const { maxWidth } = arrangement;
    let currentLeft = anchorPoint.x;

    for (const rect of sorted) {
      // Compute what the next width would be with the this rect added at the
      // current left
      const nextWidth = currentLeft + rect.width + gap;

      // If the next width greater than the given `maxWidth`, reset the
      // left value and move to the next row
      if (nextWidth > maxWidth) {
        currentTop += tallest.height + gap;
        currentLeft = anchorPoint.x;
      }

      const nextRect = assign(rect, {
        x: currentLeft,
        y: currentTop,
      });

      currentLeft += nextRect.width + gap;
      arranged.push(nextRect);
    }

    return arranged;
  }

  throw new Error(`Unknown arrangment type`);
}
