import * as React from "react";
import { useCss } from "hw/ui/style";
import theme from "hw/ui/theme";
import { VisuallyHidden } from "hw/ui/text";
import { Tooltip } from ".";

export type Props = {
  /**
   * Text to be displayed in the tip
   */
  text?: string;
  /**
   * The position of the tip around the anchor
   */
  position?: "top" | "bottom" | "left" | "right";

  /**
   * The label to use as the label for this text tooltip.  This should be a
   * short label of what your text-tooltip is describing.
   */
  ariaLabel?: string;
};

/**
 * A styled Tooltip with a specific anchor and only accepting text as content
 */
function TextTooltip(props: Props) {
  const { text, position, ariaLabel = "Help" } = props;
  const css = useCss();
  const triggerCn = css("ui-text-tooltip-root", triggerStyle);
  const tipCn = css("ui-text-tooltip-content", tipStyle);
  const id = React.useRef(makeId());

  return (
    <Tooltip tip={<div className={tipCn}>{text}</div>} position={position}>
      <div tabIndex={0} aria-labelledby={id.current}>
        <VisuallyHidden id={id.current}>{ariaLabel}</VisuallyHidden>
        <div className={triggerCn} aria-hidden="true">
          ?
        </div>
      </div>
    </Tooltip>
  );
}

let id = 0;

function makeId() {
  return `text-tooltip:${id++}`;
}

TextTooltip.defaultProps = {
  text: "",
  position: "top",
};

const triggerStyle = {
  width: "16px",
  height: "16px",
  lineHeight: "16px",
  borderRadius: theme.corner.round,
  textAlign: "center",
  fontSize: "15px",
  transition: "background-color 50ms",
  cursor: "pointer",
  backgroundColor: theme.color.gray400,
  fontWeight: theme.fontWeight.semibold,
  color: theme.color.white,
  ":hover": {
    backgroundColor: theme.color.gray500,
  },
};

const tipStyle = {
  textAlign: "center",
  maxWidth: "300px",
  whiteSpace: "pre-wrap",
};

export default TextTooltip;
