import * as React from "react";
import { Box, Text } from "hw/ui/blocks";
import type { Field } from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import JumpToDefLink from "../jump-to-def-link";

type Props = {
  field: Field;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpToDef: (...args: Array<any>) => any;
  path: Array<string | number>;
  jumpPath?: Path;
  children?: React.ReactNode;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
const defaultMessage = (field) =>
  `${field.type} components are not currently configurable in the form builder.`;

export default function FallbackComponentPreview(props: Props) {
  const {
    field,
    jumpToDef,
    path,
    children,
    jumpPath = [...path, "type"],
  } = props;
  const message = children || defaultMessage(field);
  const onJump = React.useCallback(
    () =>
      jumpToDef({
        path: jumpPath,
      }),
    [jumpPath, jumpToDef]
  );
  return (
    <Box p="ms" bg="gray025" className="fallback-preview">
      <Text fontSize="ms" mb="ms">
        {message}
      </Text>
      <JumpToDefLink onClick={onJump} />
    </Box>
  );
}
