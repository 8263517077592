import * as React from "react";
import { createComponent } from "hw/ui/style";
import { TextTooltip } from "hw/ui/tooltip";
import type { TextTooltipProps } from "hw/ui/tooltip";
import { InputLabel } from "hw/ui/input";
import type { InputLabelProps } from "hw/ui/input";
import theme from "hw/ui/theme";

const Wrapper = createComponent(function WrapperLabel() {
  return {
    display: "flex",
    alignItems: "baseline",
  };
});

/**
 * These are smartforms-specific style overrides for our label component to
 * keep parity with existing styles.
 */
const SmartformsLabelStyles = (props: {
  appearance?: InputLabelProps["appearance"];
}) => {
  const { appearance } = props;

  return {
    fontSize: appearance === "compact" ? theme.fontSize.sm : theme.fontSize.md,
    marginBottom: appearance === "compact" ? theme.space.xs : theme.space.ms,

    // To make room for help text
    marginRight: theme.space.xs,
    wordBreak: "break-word",
  };
};

type LabelProps = {
  helpText?: TextTooltipProps["text"];
  labelClassName?: string;
} & InputLabelProps;

export function SmartformsLabel(props: LabelProps) {
  const { children, helpText, required, appearance, labelClassName, ...rest } =
    props;

  return (
    <Wrapper>
      <InputLabel
        required={required}
        appearance={appearance}
        extend={SmartformsLabelStyles(props)}
        className={labelClassName}
        {...rest}
      >
        {children}
      </InputLabel>
      {helpText && <TextTooltip text={helpText} />}
    </Wrapper>
  );
}

export default SmartformsLabel;
