/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  createContext,
} from "react";
import HoverCard, { Align, Justify, Position } from "hw/ui/hover-card";
import { Item, Group, List, Hint } from "hw/ui/collection";
import { useOutsideClick } from "./use-outside-click";
import { TextTrigger, IconTrigger } from "./triggers";

const ItemAttr = "data-hwui-menu-item";
const EnabledItemsQuery = `[${ItemAttr}]:not([disabled])`;

type Ref<T> = React.MutableRefObject<T>;
type Fn = () => void;

type Placement =
  | "bottom-start"
  | "bottom"
  | "bottom-end"
  | "top"
  | "top-start"
  | "top-end"
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end";

type MenuContext = {
  buttonRef: Ref<HTMLButtonElement | null>;
  listRef: Ref<HTMLElement | null>;
  isOpen: boolean;
  toggleOpen: Fn;
  open: Fn;
  close: Fn;
  focusNext: (element: HTMLElement) => void;
  focusPrev: (element: HTMLElement) => void;
  tabAway: ({ reverse }: { reverse?: boolean }) => void;
  itemKeydown: (key: string) => void;
  onOpened: Fn;
  pointerState: {
    isDown: boolean;
  };
};

const Context = createContext<MenuContext>({
  buttonRef: {
    current: null,
  },
  listRef: {
    current: null,
  },
  isOpen: false,
  open: () => {},
  close: () => {},
  toggleOpen: () => {},
  focusNext: (_) => {},
  focusPrev: (_) => {},
  tabAway: (_) => {},
  itemKeydown: (_) => {},
  onOpened: () => {},
  pointerState: {
    isDown: false,
  },
});

type MenuProps = {
  /**
   * Determines whether the menu is initially open or not
   */
  defaultOpen?: boolean;

  children: React.ReactNode;
};

export function Menu(props: MenuProps) {
  const { defaultOpen, ...rest } = props;
  const isDefaultOpen = useRef(defaultOpen);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const nextFocus = useRef<
    "trigger" | "prev-element" | "next-element" | null
  >();

  /**
   * To achieve the drag-to-select functionality, we need to manually keep
   * track of the pointer state and coordinate this state across several events
   *
   *   - Button mousedown
   *     - menu closed?   -> pointerDown = true
   *   - Button click
   *     - pointer down?  -> pointerDown = false
   *   - Button click     -> pointerDown = false
   *   - Outside click    -> pointerDown = false
   *   - Menu List        -> pointerDown = false
   */
  const pointerStateRef = useRef({
    isDown: false,
  });
  const pointerState = pointerStateRef.current;

  const onOpened = useCallback(() => {
    const listElement = listRef.current;
    if (!listElement) return;

    const enabledItems = queryEnabledItems(listElement);
    const firstEnabled = enabledItems[0];

    if (firstEnabled) {
      firstEnabled.focus();
    }
  }, []);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => {
    setIsOpen(false);

    nextFocus.current = "trigger";
  }, []);

  const toggleOpen = isOpen ? close : open;

  const tabAway = useCallback(({ reverse }) => {
    setIsOpen(false);
    nextFocus.current = reverse ? "prev-element" : "next-element";
  }, []);

  const focusNext = useCallback((currentElement) => {
    const listElement = listRef.current;
    if (!listElement) return;
    const enabledItems = queryEnabledItems(listElement);
    const currentIdx = enabledItems.indexOf(currentElement);
    const nextIdx = (currentIdx + 1) % enabledItems.length;
    const nextItem = enabledItems[nextIdx];

    if (nextItem) {
      nextItem.focus();
    }
  }, []);

  const focusPrev = useCallback((currentElement) => {
    const listElement = listRef.current;
    if (!listElement) return;
    const enabledItems = queryEnabledItems(listElement);
    const currentIdx = enabledItems.indexOf(currentElement);
    const nextIdx =
      (currentIdx - 1 + enabledItems.length) % enabledItems.length;
    const nextItem = enabledItems[nextIdx];

    if (nextItem) {
      nextItem.focus();
    }
  }, []);

  const itemKeydown = useCallback(
    (key) => setInputValue((currentInputValue) => `${currentInputValue}${key}`),
    []
  );

  const context = useMemo(
    () => ({
      buttonRef,
      listRef,
      isOpen,
      toggleOpen,
      open,
      close,
      focusNext,
      focusPrev,
      onOpened,
      tabAway,
      itemKeydown,
      pointerState,
    }),
    [
      buttonRef,
      listRef,
      isOpen,
      toggleOpen,
      open,
      close,
      focusNext,
      focusPrev,
      onOpened,
      tabAway,
      itemKeydown,
      pointerState,
    ]
  );

  useOutsideClick({
    ref: listRef,
    onOutsideClick: (evt) => {
      if (!isOpen) return;
      const { target } = evt;
      if (!(target instanceof Node)) return;
      if (!target) return;
      if (buttonRef.current?.contains(target)) return;
      close();

      // Make sure the pointer state is reset on any outside click
      pointerState.isDown = false;
    },
  });

  useEffect(() => {
    if (isDefaultOpen.current) {
      open();
    }
  }, [open]);

  useEffect(() => {
    if (!isOpen) return;
    if (!inputValue) return;
    const listElement = listRef.current;
    if (!listElement) return;

    // Clear the search input after some amount of time for another search
    const timeout = setTimeout(() => setInputValue(""), 300);

    const enabledItems = queryEnabledItems(listElement);
    const toFocus = findItemByTypeahead(inputValue, enabledItems);
    if (toFocus) {
      toFocus.focus();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [inputValue, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setInputValue("");

      if (nextFocus.current === "trigger" && buttonRef.current) {
        buttonRef.current.focus();
      } else if (nextFocus.current === "next-element" && buttonRef.current) {
        focusElementAfter(buttonRef.current);
      } else if (nextFocus.current === "prev-element" && buttonRef.current) {
        focusElementBefore(buttonRef.current);
      }

      nextFocus.current = null;
    }
  }, [isOpen]);

  return <Context.Provider value={context} {...rest} />;
}

Menu.Button = MenuButton;
Menu.IconButton = IconButton;
Menu.List = MenuList;
Menu.Item = MenuItem;
Menu.Group = MenuGroup;
Menu.Hint = MenuHint;

/**
 * Menu Button
 * -----------------
 */

type MenuButtonContext = {
  buttonProps: {
    ref: MenuContext["buttonRef"];
    "aria-expanded": boolean;
    "aria-haspopup": true;
    onKeyDown: (evt: KeyboardEvent) => void;
    onMouseDown: (evt: MouseEvent) => void;
    onClick: (evt: MouseEvent) => void;
  };
  isOpen: boolean;
};

/**
 * This hook encapsulates all the functionality need for the menu button.
 * It returns a `buttonProps` value that can be spread on an element:
 *
 * ```
 * function MyTrigger() {
 *   const { buttonProps } = useMenuButton();
 *   return <button {...buttonProps}>Open</button>
 * }
 * ```
 */
export function useMenuButton(): MenuButtonContext {
  const { buttonRef, isOpen, open, toggleOpen, pointerState } =
    useContext(Context);

  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      switch (evt.key) {
        case "ArrowDown": {
          evt.preventDefault();
          open();
          break;
        }
        default:
          break;
      }
    },
    [open]
  );

  const onMouseDown = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      if (!isOpen) {
        // If the menu is not open, then we will open the menu on mousedown
        // and keep track of the pointer state so we don't _also_ try to open
        // on the subsequent `click` event
        pointerState.isDown = true;
        // Open the menu to allow users to drag the cursor to a selection
        open();
      }
    },
    [open, pointerState, isOpen]
  );

  const onClick = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      // Only open if the pointer is not down already. This will be the case
      // for keyboard click events
      if (!pointerState.isDown) {
        toggleOpen();
      } else {
        // Otherwise reset the pointer state
        pointerState.isDown = false;
      }
    },
    [pointerState, toggleOpen]
  );

  return {
    isOpen,
    buttonProps: {
      ref: buttonRef,
      "aria-expanded": isOpen,
      "aria-haspopup": true,
      onKeyDown,
      onMouseDown,
      onClick,
    },
  };
}

/**
 * This is the default 'text' trigger implementation. The `children` can be
 * an element or a function for quickly creating custom triggers.
 *
 * ```
 * <Menu>
 *   <Menu.Button>Open Me</Menu.Button>
 * </Menu>
 *
 * // OR
 * <Menu>
 *   <Menu.Button>
 *     {({buttonProps}) => <button {...buttonProps}>Open Me</button>
 *   </Menu.Button>}
 * </Menu>
 * ```
 *
 * If you need more control, check out the `useMenuButton` hook.
 */

type MenuButtonChildFn = (menuButtonContext: MenuButtonContext) => JSX.Element;

type MenuButtonProps = {
  children: React.ReactNode | MenuButtonChildFn;
};

export function MenuButton(props: MenuButtonProps) {
  const { children, ...rest } = props;

  if (isChildFn(children)) {
    return <CustomMenuButton {...rest}>{children}</CustomMenuButton>;
  } else {
    return <TextButton {...rest}>{children}</TextButton>;
  }
}

function isChildFn(
  children: MenuButtonProps["children"]
): children is MenuButtonChildFn {
  return typeof children === "function";
}

/**
 * Default icon trigger implementation. By default, the icon is the
 * `MoreVerticalIcon`, but a custom icon may be provided
 *
 * ```
 * <Menu>
 *   <Menu.IconButton aria-label="Open" />
 * </Menu>
 *
 * // OR
 * <Menu>
 *   <Menu.IconButton aria-label="Open">
 *     <CaretDownIcon />
 *   </Menu.IconButton>
 * </Menu>
 * ```
 *
 * NOTE: Icon-only triggers must have an `aria-label` property for assistive
 * devices
 */
function IconButton(
  props: Omit<React.ComponentProps<typeof IconTrigger>, "isOpen"> & {
    isOpen?: boolean;
  }
) {
  const { buttonProps, isOpen } = useMenuButton();

  return <IconTrigger {...props} {...buttonProps} isOpen={isOpen} />;
}

type CustomMenuButtonProps = {
  children: MenuButtonChildFn;
};

function CustomMenuButton(props: CustomMenuButtonProps) {
  const { buttonProps, isOpen } = useMenuButton();

  return props.children({ buttonProps, isOpen });
}

function TextButton(
  props: React.ComponentProps<typeof TextTrigger> & { isOpen?: boolean }
) {
  const { buttonProps, isOpen } = useMenuButton();

  return <TextTrigger {...props} {...buttonProps} isOpen={isOpen} />;
}

/**
 * Menu List
 * ------------
 */

function useMenuList() {
  const { listRef, pointerState } = useContext(Context);

  const onMouseUp = useCallback(() => {
    pointerState.isDown = false;
  }, [pointerState]);

  return {
    listProps: {
      ref: listRef,
      role: "menu",
      onMouseUp,
    },
  };
}

type MenuListProps = {
  placement?: Placement;
  children: React.ReactNode;
};

export function MenuList(
  props: MenuListProps & React.ComponentProps<typeof List>
) {
  const { placement = "bottom-start", ...rest } = props;
  const { isOpen, buttonRef, onOpened } = useContext(Context);
  const { listProps } = useMenuList();
  const hoverCardPosition = hoverCardFromPlacement(placement);
  const buttonElement = buttonRef.current;

  if (!buttonElement) return null;

  return (
    <HoverCard
      {...hoverCardPosition}
      anchor={buttonElement}
      active={isOpen}
      renderDest="portal"
      onEnter={onOpened}
    >
      <List {...rest} {...listProps} />
    </HoverCard>
  );
}

function hoverCardFromPlacement(placement: Placement): {
  position: Position | undefined;
  align: Align;
  justify: Justify;
} {
  let align;
  let justify;
  let position;

  if (placement.startsWith("top")) {
    position = "top" as const;
  } else if (placement.startsWith("bottom")) {
    position = "bottom" as const;
  } else if (placement.startsWith("left")) {
    position = "left" as const;
  } else if (placement.startsWith("right")) {
    position = "right" as const;
  }

  if (placement.endsWith("end")) {
    justify = "right" as const;
    align = "bottom" as const;
  } else if (placement.endsWith("start")) {
    justify = "left" as const;
    align = "top" as const;
  } else {
    justify = "center" as const;
    align = "center" as const;
  }

  return { align, justify, position };
}

/**
 * Menu Group
 * -------------
 */
export function MenuGroup(props: React.ComponentProps<typeof Group>) {
  return <Group {...props} />;
}

/**
 * Menu Hint
 * ----------
 */
export function MenuHint(props: React.ComponentProps<typeof Hint>) {
  return <Hint {...props} />;
}

/**
 * Menu Item
 * ----------
 */
type MenuItemProps = {
  onSelect: Fn;
};

export function useMenuItem(props: MenuItemProps) {
  const { onSelect } = props;
  const { focusNext, focusPrev, tabAway, close, itemKeydown, pointerState } =
    useContext(Context);
  const ref = useRef<HTMLElement>(null);

  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      const element = ref.current;
      if (!element) return;

      switch (evt.key) {
        case "ArrowDown": {
          evt.preventDefault();
          return focusNext(element);
        }
        case "ArrowUp": {
          evt.preventDefault();
          return focusPrev(element);
        }
        case "Tab": {
          evt.preventDefault();
          return tabAway({ reverse: evt.shiftKey });
        }
        case "Escape": {
          evt.preventDefault();
          return close();
        }
        default: {
          if (evt.key !== "Enter" && evt.key !== " ") {
            evt.stopPropagation();
            evt.preventDefault();
            itemKeydown(evt.key);
          }
          break;
        }
      }
    },
    [focusPrev, focusNext, tabAway, itemKeydown, close]
  );

  const selectAndClose = useCallback(() => {
    if (typeof onSelect === "function") {
      onSelect();
    }
    close();
  }, [onSelect, close]);

  const onClick = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      selectAndClose();
    },
    [selectAndClose]
  );

  const onMouseUp = useCallback(() => {
    if (pointerState.isDown && ref.current) {
      // If we're here, that means we were holding the mouse down from the
      // menu button. Trigger a `click` on the item to select it
      ref.current.click();
    }
  }, [pointerState]);

  const onMouseOver = useCallback(() => ref.current?.focus(), []);

  return {
    itemProps: {
      ref,
      role: "menuitem",
      [ItemAttr]: "",
      tabIndex: "-1",
      onKeyDown,
      onClick,
      onMouseOver,
      onMouseUp,
    },
  };
}

/**
 * hi?
 */
export function MenuItem(
  props: MenuItemProps & React.ComponentProps<typeof Item>
) {
  const { itemProps } = useMenuItem(props);

  return <Item {...props} {...itemProps} />;
}

function getAllFocusableElements(): HTMLElement[] {
  return Array.from(
    document.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  );
}

/**
 * Tries to focus the next 'focusable' item in the DOM after the given element.
 * This may not be perfect, but it should be better than the default behavior.
 * Menu lists render within a portal, so the default tab order won't work. We
 * want to tab to the elements before/after the menu button
 */
function focusElementAfter(after: HTMLElement) {
  const allFocusable = getAllFocusableElements();
  const idx = allFocusable.indexOf(after);
  if (idx > -1) {
    const el = allFocusable[idx + 1];
    if (el) {
      el.focus();
    }
  }
}

/**
 * Tries to focus the previous 'focusable' item in the DOM before the given element.
 * See above: `focusElementAfter`
 */
function focusElementBefore(before: HTMLElement) {
  const allFocusable = getAllFocusableElements();
  const idx = allFocusable.indexOf(before);
  if (idx > -1) {
    const el = allFocusable[idx - 1];
    if (el) {
      el.focus();
    }
  }
}

function findItemByTypeahead(inputSoFar: string, elements: HTMLElement[]) {
  const keysSoFar = inputSoFar.toLowerCase();

  return elements.find((element) =>
    element.textContent?.toLowerCase().startsWith(keysSoFar)
  );
}

/**
 * DOM stuff
 */
function queryEnabledItems(menu: HTMLElement): HTMLElement[] {
  return Array.from(menu.querySelectorAll(EnabledItemsQuery));
}
