import * as React from "react";
import { Input } from "hw/ui/input";
import { TextToggle } from "./basics";
import type { SettingsProps } from "../../../types";

type Props = SettingsProps & {
  /**
   * Help text to be displayed in the tip
   */
  helpText?: string;
};

/**
 * Settings module. It adds suppot of a help text for the field.
 */
export function HelpText(props: Props) {
  /* $FlowFixMe[incompatible-use] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  const { helpText, ...rest } = props;
  return (
    <TextToggle
      schemaPropName="helpText"
      text={helpText}
      name="Help text"
      InputComponent={Input}
      {...rest}
    />
  );
}
export default HelpText;
