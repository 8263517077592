import React from "react";
import Multiline from "hw/common/components/smartforms/multi-line";
import type { MultilineField } from "hw/portal/modules/common/draft";

type Props = {
  field: MultilineField;
};
export default function MultiLinePreview(props: Props) {
  const [value, setValue] = React.useState("");
  const { field } = props;
  const { label, required, helpText, placeholder, maxLength } = field;
  const isRequired = Boolean(required);
  return (
    <Multiline
      onChange={setValue}
      value={value}
      helpText={helpText}
      isValid={true}
      label={label}
      required={isRequired}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  );
}
