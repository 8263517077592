import React from "react";
import theme from "hw/ui/theme";
import { SubtleLink } from "hw/ui/text";
import { useUser } from "hw/portal/modules/user";

const MsgForCodeElement = ({ errorCode }: { errorCode: string }) => {
  const user = useUser();

  const pricingLinkAttributes = {
    target: "_blank",
    rel: "noreferrer noopener",
    href: "https://www.hellosign.com/info/hs-pricing",
  };
  const planLinkAttributes = {
    href: "/plan",
  };
  const isAdmin = user.role === "admin";

  switch (errorCode) {
    case "429":
      return (
        <p>
          You have exceeded the maximum number of forms you can send in an hour
          on your plan.{" "}
          <SubtleLink
            {...(isAdmin ? planLinkAttributes : pricingLinkAttributes)}
            extend={{
              color: theme.color.white,
              // @ts-expect-error refactor
              ":hover": {
                color: theme.color.gray100,
              },
              ":active": {
                color: theme.color.gray100,
              },
            }}
          >
            View plans and upgrade
          </SubtleLink>{" "}
          to remove this limit.
        </p>
      );
    case "402":
      return (
        <p>
          You have exceeded the maximum number of forms you can send this month.{" "}
          <SubtleLink
            {...(isAdmin ? planLinkAttributes : pricingLinkAttributes)}
            extend={{
              color: theme.color.white,
              // @ts-expect-error refactor
              ":hover": {
                color: theme.color.gray100,
              },
              ":active": {
                color: theme.color.gray100,
              },
            }}
          >
            View plans and upgrade
          </SubtleLink>{" "}
          to remove this limit.
        </p>
      );
    case "403":
      return (
        <p>
          Your account has been blocked. Your transaction has NOT been launched.{" "}
          <SubtleLink
            {...(isAdmin ? planLinkAttributes : pricingLinkAttributes)}
            extend={{
              color: theme.color.white,
              // @ts-expect-error refactor
              ":hover": {
                color: theme.color.gray100,
              },
              ":active": {
                color: theme.color.gray100,
              },
            }}
          >
            Contact support
          </SubtleLink>{" "}
          to launch this workflow.
        </p>
      );
    default:
      return null;
  }
};

export function msgForCode(code: string) {
  switch (code) {
    case "429":
    case "402":
    case "403":
      return <MsgForCodeElement errorCode={code} />;
    default:
      return null;
  }
}

const MsgForCodeShareableLinkElement = ({
  errorCode,
}: {
  errorCode: string;
}) => {
  const user = useUser();

  const pricingLinkAttributes = {
    target: "_blank",
    rel: "noreferrer noopener",
    href: "https://www.hellosign.com/info/hs-pricing",
  };
  const planLinkAttributes = {
    href: "/plan",
  };
  const isAdmin = user.role === "admin";
  switch (errorCode) {
    case "402":
      return (
        <p>
          You have exceeded the maximum number of forms you can send this month.{" "}
          <SubtleLink
            {...(isAdmin ? planLinkAttributes : pricingLinkAttributes)}
            extend={{
              color: theme.color.white,
              // @ts-expect-error refactor
              ":hover": {
                color: theme.color.gray100,
              },
              ":active": {
                color: theme.color.gray100,
              },
            }}
          >
            View plans and upgrade
          </SubtleLink>{" "}
          to activate shareable link.
        </p>
      );
    case "429":
      return (
        <p>
          You have exceeded the maximum number of forms you can send in an hour
          on your plan.{" "}
          <SubtleLink
            {...(isAdmin ? planLinkAttributes : pricingLinkAttributes)}
            extend={{
              color: theme.color.white,
              // @ts-expect-error refactor
              ":hover": {
                color: theme.color.gray100,
              },
              ":active": {
                color: theme.color.gray100,
              },
            }}
          >
            View plans and upgrade
          </SubtleLink>{" "}
          to activate shareable link.
        </p>
      );
    case "403":
      return (
        <p>
          Your account has been blocked. Your transaction has NOT been launched.{" "}
          <SubtleLink
            {...(isAdmin ? planLinkAttributes : pricingLinkAttributes)}
            extend={{
              color: theme.color.white,
              // @ts-expect-error refactor
              ":hover": {
                color: theme.color.gray100,
              },
              ":active": {
                color: theme.color.gray100,
              },
            }}
          >
            Contact support
          </SubtleLink>{" "}
          to activate shareable link.
        </p>
      );
    default:
      return null;
  }
};

export const msgForCodeShareableLink = {
  "402": <MsgForCodeShareableLinkElement errorCode="402" />,
  "429": <MsgForCodeShareableLinkElement errorCode="429" />,
  "403": <MsgForCodeShareableLinkElement errorCode="403" />,
};

/**
 * Translates the given GraphQL error into a single error code used to display
 * a message to the user. This could potentially be expanded into a more
 * general utitlity. Leaving it here for now until we can better determine the
 * use cases.
 */
export function retrieveErrorCode(apolloError: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphQLErrors?: Array<any>;
  networkError?: { statusCode: string };
}) {
  const { graphQLErrors, networkError } = apolloError;

  // If there is a network error, use the direct status code
  if (networkError) {
    return networkError?.statusCode;
  }

  // In this specific context we're assuming there's only ever one GraphQL
  // error given. If we extend this to more a general context, this may not
  // apply
  const message = graphQLErrors?.[0]?.message;

  switch (message) {
    case "rate_limit":
      return "429";
    case "exceeded_transaction_limit":
    case "Exceeded transaction limit":
      return "402";
    case "transactions_blocked":
    case "Transactions blocked":
      return "403";
    default:
      break;
  }
}
