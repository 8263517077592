import * as React from "react";
import debounce from "lodash/debounce";

/**
 * Runs an effect but only after the initial mount. Useful if you want to run
 * an effect on a debounced value but only after it's been updated and not the
 * initial value.
 */
export function useUpdateEffect(effect: () => void, deps: Array<unknown>) {
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

/**
 * Returns a debounced function using lodash's `debounce`. Make sure to
 * properly memoize the function you pass via `useCallback`, otherwise this
 * will continually cancel itself!
 *
 * NOTE: The `delay` argument is (questionably) overloaded so that if the
 * string 'none' is passed, then the function is returned as is, essentially
 * resulting in a normal, non-debounced behavior. This can be helpful in
 * tests if you want to avoid dealing with timeouts.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFn = (...args: any) => any;

export function useDebouncedFn<Fn extends AnyFn>(fn: Fn, delay: "none"): Fn;

export function useDebouncedFn(
  fn: AnyFn,
  delay: number
): ReturnType<typeof debounce>;

export function useDebouncedFn<Fn extends AnyFn>(
  fn: Fn,
  delay: number | "none"
) {
  const debouncedFn = React.useMemo(() => {
    if (delay === "none") return fn;
    return debounce(fn, delay);
  }, [fn, delay]);

  React.useEffect(() => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof debouncedFn.cancel === "function") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        debouncedFn.cancel();
      }
    };
  }, [debouncedFn]);

  return debouncedFn;
}

/**
 * Returns a ref for the latest version of a value. Useful for callbacks that
 * may change in a render, but you only want the latest value
 *
 * @example
 * function MyComponent() {
 *   const onMove = useLatest(props.onMove);
 *
 *   function onClick() {
 *    onMove.current('A value')
 *   }
 *
 *   return <button onClick={onClick}>Click</button>
 * }
 */
export function useLatest<T>(value: T): { current: T } {
  const ref = React.useRef<T>(value);

  React.useLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
}
