import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "hw/ui/blocks";
import { Input, InputLabel } from "hw/ui/input";
import Modal, { ACTION_TYPE } from "hw/ui/modal";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { TextBody1 } from "hw/ui/text";

type Data = {
  name: string;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (name: string) => Promise<any>;
  submitting: boolean;
};

type State = {
  data: Data;
};

/**
 * Captures any necessary information required for users to complete their
 * signup process
 */
export default class CompleteSignupModal extends React.Component<Props, State> {
  form: { current: HTMLFormElement | null } = React.createRef();

  state = {
    data: {
      // NOTE: This is purposefully empty to start because a user's team
      // might not technically be empty but instead contain the default team
      // string value.  For now, this modal is only shown in that specific
      // case
      name: "",
    },
  };

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      data: { name: evt.target.value },
    });
  };

  handleSubmit = (evt: React.ChangeEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (this.form.current) {
      const form = this.form.current;

      if (form.checkValidity()) {
        this.props.onSubmit(this.state.data.name);
      } else {
        form.reportValidity();
      }
    }
  };

  render() {
    const { submitting } = this.props;

    return (
      <Modal
        heading={
          <FormattedMessage
            id="completeSignup.heading"
            defaultMessage="Name your team before sending out a workflow"
          />
        }
        primaryAction={{
          text: (
            <FormattedMessage
              id="completeSignup.submit"
              defaultMessage="Continue"
            />
          ),
          onClick: this.handleSubmit,
          type: ACTION_TYPE.CONFIRM,
          disabled: submitting,
          attrs: getTestAttributes("complete-signup-modal-submit-btn"),
        }}
      >
        <TextBody1>
          <FormattedMessage
            id="completeSignup.copy"
            defaultMessage="The team name you set will be visible in emails to participants. You can always update this later in 'Team Settings'."
          />
        </TextBody1>
        <form ref={this.form} onSubmit={this.handleSubmit}>
          <Box mb="xs" mt="lg">
            <InputLabel htmlFor="team-name" required={true}>
              <FormattedMessage
                id="completeSignup.teamNameLabel"
                defaultMessage="Team Name"
              />
            </InputLabel>
          </Box>
          <Box mb="sm">
            <Input
              id="team-name"
              type="text"
              name="data[name]"
              value={this.state.data.name}
              onChange={this.handleChange}
              required
              autoFocus={true}
            />
          </Box>
        </form>
      </Modal>
    );
  }
}
