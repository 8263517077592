import { useContext } from "react";
import { combineRules } from "fela";
import type { IRenderer, TRuleProps, IStyle } from "fela";
import { RendererContext } from "react-fela";
import { warning } from "hw/common/utils/assert";

type FnExtend = (props: TRuleProps) => IStyle;
type ObjExtend = Record<string, unknown>;

export type StyleRule = FnExtend | ObjExtend | IStyle;

/**
 * A hook for creating a single class name
 *
 * @example
 * function MyComponent(props) {
 *   const className = useStyle({ color: 'red' });
 *
 *   return <div className={className} {...props} />
 * }
 *
 * This hook accepts any number of style objects, rule functions, or arrays of either.
 * NOTE: For now, function rules will not receive any arguments.  If you need
 * access to the global `theme`, import it as a normal import and reference
 * that way.  In the future, we may pass whitelabeling values as an argument to
 * rule functions.
 *
 * Additionally, you may pass a string label as the first argument that will
 * get appened to the generated class name in development.
 *
 * @example
 * function MyComponent(props) {
 *   const className = useStyle(
 *     'debug-class',
 *     { color: 'red' },
 *     () => ({ backgroundColor: 'blue' })
 *   );
 *
 *   // Class name will be "debug-class_12312"
 *   return <div className={className} {...props} />
 * }
 */
export function useStyle(
  ruleOrLabel: StyleRule | string,
  ...rules: Array<StyleRule | void>
) {
  const renderer = useContext(RendererContext);

  return renderRules(
    renderer,
    {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ruleOrLabel,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...rules
  );
}

/**
 * A hook for creating multiple class names
 *
 * This hook is very similar to the `useStyle` hook, but instead of returning a
 * single class name, it returns a function which can be used to create class
 * names.
 *
 * Additionally, you can provide the `props` to the hook and those props will
 * be provide to function rules.  This is useful if you don't want to create a
 * separate styled component for each element.
 *
 * @example
 * function MyComponent(props) {
 *   const css = useCss(props);
 *   const cn1 = css({ color: props.color });
 *   const cn2 = css({ backgroundColor: 'blue' });
 *
 *   return <div className={cn1}>
 *     <div className={cn2}>Hello, world</div>
 *   </div>
 * }
 *
 * <MyComponent color='red' />
 *
 * It's also helpful if you need to optimize the CSS calls
 *
 * const css = useCss(props);
 * const cn = useMemo(() => css({ color: props.color }), [props.color]);
 */
export function useCss(props?: TRuleProps) {
  const renderer = useContext(RendererContext);

  function css(ruleOrLabel: string | StyleRule, ...rules: Array<StyleRule>) {
    return renderRules(renderer, props || {}, ruleOrLabel, ...rules);
  }

  return css;
}

/**
 * Returns a tuple of a label and an array of rules.
 *
 * NOTE: There's some intended mutation here for optimization purposes
 */
function handleLabeledRules(
  ruleOrLabel: StyleRule | string,
  ...rules: Array<StyleRule>
): [string, StyleRule[]] {
  if (typeof ruleOrLabel === "string") {
    // If the first rule is a string, assume it's a label.

    warning(
      !/\s/.test(ruleOrLabel),
      `The label '${ruleOrLabel}' includes a space.  Labels should be valid CSS selectors`
    );
    return [ruleOrLabel, rules];
  } else {
    // Other wise assume it's a style rule and prepend to the beginning of the
    // list of rules
    rules.unshift(ruleOrLabel);

    return ["", rules];
  }
}

/**
 * Renders a rule with props
 */
function renderRules(
  renderer: IRenderer,
  props: TRuleProps,
  ruleOrLabel: string | StyleRule,
  ...rules: StyleRule[]
) {
  const [label, adjustedRules] = handleLabeledRules(ruleOrLabel, ...rules);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rule = combineRules(...adjustedRules);

  // The `ruleName` is a special property that `fela` looks for and will
  // prepend to the generated class name
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  rule.ruleName = label;

  return renderer.renderRule(rule, props);
}
