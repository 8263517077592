import flatMap from "lodash/flatMap";
import { combineRules } from "hw/ui/style";
import { space, flexbox, system } from "styled-system";

const num = (val: unknown): val is number =>
  typeof val === "number" && !Number.isNaN(val);

// If a number is passed, return the value as pixels
const px = (val: string | number) => (num(val) ? `${val}px` : val);

// If the value is less than 1, then a percentage is assumed.
// Otherwise return the value directly
//   e.g. `width={1/2}`  // => '50%'
//        `width='100px' // => '100px'
const getWidth = (n: string | number) =>
  !num(n) || n > 1 ? px(n) : n * 100 + "%";

/**
 * Style system
 *
 * This is the custom HelloWorks styled system.  Each key represents a style
 * prop that is transformed into a CSS property.
 */
const hwSystem = system({
  borderWidth: {
    property: "borderWidth",
    transform: px,
  },
  borderBottomStyle: {
    property: "borderBottomStyle",
  },
  borderStyle: {
    property: "borderStyle",
  },
  borderColor: {
    property: "borderColor",
    scale: "color",
  },
  bg: {
    property: "backgroundColor",
    scale: "color",
  },
  color: {
    property: "color",
    scale: "color",
  },
  display: {
    property: "display",
  },
  width: {
    property: "width",
    transform: getWidth,
  },
  textAlign: {
    property: "textAlign",
  },
  textTransform: {
    property: "textTransform",
  },
  fontWeight: {
    property: "fontWeight",
    scale: "fontWeight",
  },
  fontSize: {
    property: "fontSize",
    scale: "fontSize",
  },
});

export const transforms = [space, flexbox, hwSystem];

export const stylePropNames = flatMap(transforms, (t) => t.propNames ?? []);

export default combineRules(...transforms);
