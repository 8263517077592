// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { getIn } from "timm";
import { TextSelection, EditorState } from "prosemirror-state";
import type { EditorView } from "prosemirror-view";
import { toggleMark } from "prosemirror-commands";
import type { ActiveLink } from "./types";
import type { Dispatch, Command } from "../../types";
import schema from "../../schema";
import { markExtend } from "./utils";

/**
 * Inserts or removes a link mark around the currently selected text
 */
export function toggleLink(state: typeof EditorState, dispatch?: Dispatch) {
  // For now, we'll only allow a link to be created if there is an active text
  // selection.  There's some UX decisions to be made about how to configure a
  // link that doesn't yet have any text
  if (!canLink(state)) {
    return false;
  }

  toggleMark(state.schema.marks.link, {
    href: "",
  })(state, dispatch);

  return true;
}

/**
 * Returns true if a link can be inserted based on the current state of the
 * editor
 */
export function canLink(state: typeof EditorState) {
  return getIn(state, ["selection", "empty"]) === false;
}

/**
 * Removes the link mark around the given active link
 *
 * NOTE: This is slightly different from the 'toggleLink' in the toolbar. This
 * one will remove the entire link from the document as opposed to just
 * unlink a selection of text.
 */
export function unlink(_currentLink: ActiveLink): Command {
  return function _unlink(state, dispatch, view) {
    const { from, to } = markExtend(state.selection.$from, schema.marks.link);

    if (dispatch) {
      dispatch(state.tr.removeMark(from, to, schema.marks.link));
    }

    if (view) {
      view.focus();
    }
    return true;
  };
}

/**
 * Updates the `href` attribute for the given active link
 */
export function updateLinkHref(
  currentLink: ActiveLink,
  newHref: string
): Command {
  return function _updateLinkHref(state, dispatch) {
    const { schema } = state;
    const { from, to } = markExtend(state.selection.$from, schema.marks.link);
    const newMark = schema.mark("link", {
      href: newHref,
    });

    const tr = state.tr
      .removeMark(from, to, schema.marks.link)
      .addMark(from, to, newMark);

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
}

/**
 * Moves the focus back to the editor but does not close the edit window
 */
export function exitWindow(
  /* $FlowFixMe[value-as-type] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  state: EditorState,
  dispatch: Dispatch,
  /* $FlowFixMe[value-as-type] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  view: EditorView
) {
  const { selection } = state;
  const newSelection = TextSelection.near(selection.$to);
  const tr = state.tr.setSelection(newSelection).scrollIntoView();
  dispatch(tr);
  view.focus();
  return true;
}
