/**
 * This module is meant to be a collection of functions that perform operations
 * on the schema and return the update schema.
 *
 * NOTE: Some operations may only make sense in one version of the editor, but
 * all operations are kept here for colocation benefits.
 */
import pipe from "lodash/flow";
import reduce from "lodash/reduce";
import { invariant } from "hw/common/utils/assert";
import { omit } from "timm";

// TODO: Re-implement these modules and move them here.
// These modules have non-trival logic and are tightly coupled with the
// editor compoennts. We should write tests and re-implement them here so that
// they are less coupled.
import { removeConditionalLogicReferences as removeExternalConditionalLogicReferences } from "hw/portal/modules/draft-editor/form-builder/form-builder";
import { removeCalculationReferences } from "hw/portal/modules/draft-editor/utils";
import {
  isLinkedTo,
  AddressFormats,
} from "hw/portal/modules/draft-editor-v2/build/mapping";
import { maxCharsInArea } from "./utils";

import type {
  ParsedSchema,
  Mapping,
  Component as ComponentType,
  OutputField as OutputFieldType,
  ComponentOutputInstance,
  Form as FormType,
} from "./types";

export const Schema = {
  /**
   * Changes the role assign on an individual component at the given path and
   * removes any active references.
   */
  reassignComponent(
    schema: ParsedSchema,
    component: ComponentType,
    formId: string,
    roleId: string
  ) {
    return pipe(
      (schema) =>
        Schema.updateComponent(schema, formId, component.id, (component) =>
          Component.setRole(component, roleId)
        ),
      // $FlowFixMe: This explodes because our Field types are not in good shape
      (schema) => Schema.removeAllComponentReferences(schema, component, formId)
    )(schema);
  },

  /**
   * Updates a component within a form
   */
  updateComponent(
    schema: ParsedSchema,
    formId: string,
    componentId: string,
    updater: (component: ComponentType) => ComponentType
  ) {
    return {
      ...schema,
      forms: updateAtId(schema.forms, formId, (form) =>
        Form.updateComponent(form, componentId, updater)
      ),
    };
  },

  /**
   * Removes all component-to-component references for a component within a schema.
   *
   * NOTE: That this will remove both _incoming_ and _outgoing_  references
   * between _components_ within the schema. This will not remove references
   * for other reference types, like output field references.
   */
  removeAllComponentReferences(
    schema: ParsedSchema,
    component: ComponentType,
    formId: string
  ) {
    return {
      ...schema,
      // Remove any mappings that this component may be references
      mapping: component.dataRef
        ? Mappings.omitAllMatching(schema.mapping, component.dataRef)
        : schema.mapping,

      // Remove any conditional logic references
      forms: updateAtId(schema.forms, formId, (form) => {
        return {
          ...form,
          fields: pipe(
            // This function only removes _external_ references. Meaning,
            // references of other conditional logic components that might be
            // referencing the given component.
            (components) =>
              removeExternalConditionalLogicReferences(components, component),

            // This function removes calculation references
            (components) => removeCalculationReferences(components, component),

            // If the component we're removing references is a `Group` component,
            // then we also need to remove any outgoing references of other
            // components. For now, this is a forceful rmeove of the entire
            // `visible` property since it's the only likely place a reference
            // to another component would exist
            //
            // TODO: Have a better reference tracking system!
            (components) =>
              // @ts-expect-error refactor
              updateAtId(components, component.id, (component) => {
                if (component.type === "Group") {
                  return {
                    ...component,
                    visible: {},
                  };
                } else if (component.type === "Calculation") {
                  return omit(component, "value");
                } else {
                  return component;
                }
              })
          )(form.fields),
        };
      }),
    };
  },
};

export const Form = {
  updateComponent(
    form: FormType,
    componentId: string,
    updater: (comp: ComponentType) => ComponentType
  ) {
    return {
      ...form,
      fields: updateWhere(
        form.fields,
        (component) => Component.contains(component, componentId),
        (component) => Component.updateAtId(component, componentId, updater)
      ),
    };
  },

  findComponent(
    form: FormType,
    cb: (c: ComponentType) => boolean
  ): ComponentType | undefined | null {
    function traverse(
      components: ComponentType[],
      cb: (c: ComponentType) => boolean
    ): ComponentType | undefined {
      for (const component of components) {
        if (cb(component)) return component;
        if (Array.isArray(component.children))
          return traverse(component.children, cb);
      }
    }

    return traverse(form.fields, cb);
  },

  /**
   * Traverses the components within a form including `children` and calls the
   * callback. Use this when you need to iterate each component in a form to
   * avoid forggeting to iterate `children`.
   *
   * NOTE: There is no particular order adhered to here, so it's not stable to
   * rely on the order that the callback is invoked
   */
  traverseComponents(form: FormType, cb: (c: ComponentType) => void) {
    function traverse(
      components: ComponentType[],
      cb: (c: ComponentType) => void
    ) {
      for (const component of components) {
        cb(component);

        if (Array.isArray(component.children)) {
          traverse(component.children, cb);
        }
      }
    }

    traverse(form.fields, cb);
  },

  /**
   * This function updates multiple components at once
   *
   * NOTE: this function is pretty expensive overall and it's mean to be used
   * when you have arrays with a few elements
   */
  updateComponents(
    form: FormType,
    updatersByComponent: {
      [componentId: string]: (c: ComponentType) => ComponentType;
    }
  ) {
    return reduce(
      updatersByComponent,
      (updatedForm, updater, componentId) =>
        Form.updateComponent(updatedForm, componentId, updater),
      form
    );
  },
};

const Mappings = {
  /**
   * Remove all mappings (merge field and form-to-form) from the schema that
   * reference the component.
   */
  omitAllMatching(mappings: Mapping, dataRef: string) {
    let nextMappings = mappings;

    for (const [target, sourceExpression] of Object.entries(mappings)) {
      if (target.includes(dataRef)) {
        nextMappings = omit(nextMappings, target);
      } else if ((JSON.stringify(sourceExpression) || "").includes(dataRef)) {
        nextMappings = omit(nextMappings, target);
      }
    }

    return nextMappings;
  },
};

export const Component = {
  /**
   * Returns `true` if this component contains the given `id`
   */
  contains(component: ComponentType, id: string) {
    if (component.id === id) return true;

    return (
      Array.isArray(component.children) &&
      component.children.some((child) => child.id === id)
    );
  },

  /**
   * Updates a component at the given `id`.
   */
  updateAtId(
    component: ComponentType,
    id: string,
    updater: (c: ComponentType) => ComponentType
  ) {
    if (component.id === id) return updater(component);
    if (Array.isArray(component.children)) {
      return {
        ...component,
        children: updateAtId(component.children, id, updater),
      };
    }

    throw new Error(`Couldn't update component at id '${id}'`);
  },

  /**
   * Traverses the component and its children and updates each node with the
   * updater
   */
  map(component: ComponentType, updater: (c: ComponentType) => ComponentType) {
    const updatedField = updater(component);

    if (Array.isArray(component.children)) {
      updatedField.children = component.children.map((childField) =>
        Component.map(childField, updater)
      );
    }

    return updatedField;
  },

  setRole(component: ComponentType, roleId: string) {
    return Component.map(component, (component) => ({
      ...component,
      roles: [roleId],
    }));
  },

  updateMaxLength(
    component: ComponentType,
    dimensions: { width: number; height: number; fontSize?: number }
  ) {
    if (component.type !== "Multiline") return component;
    const maxChars = maxCharsInArea(dimensions);
    const maxLength = maxChars > 500 ? 500 : maxChars;

    return {
      ...component,
      maxLength,
    };
  },
};

/**
 * Output Fields
 * --------------
 *
 * NOTE: There's some overlap with the `OutputField` ops below and the
 * operations listed in `./build/mapping/`. This file is meant to be closer to
 * our current model of PDF mapping. In general, if you're defining new
 * operations, do it here but ensure there isn't any duplicate logic between
 * the two locations.
 */
export const OutputFields = {
  /**
   * Partitions the list of output fields a list of `ComponentOutputInstances`.
   * The component output instance may be a single output field or it may be a
   * set of fields that all have the same set `id`.
   */
  toComponentOutputInstances(
    outputFields: Array<OutputFieldType>
  ): Array<ComponentOutputInstance> {
    function makeInstance(id: string, fields = []) {
      return {
        id,
        fields,
      };
    }
    const sets = {};
    const singleFields = [];

    for (const field of outputFields) {
      const setId = OutputField.getSetId(field);
      if (setId) {
        // If this output field has an explicit `id` set, group all other
        // fields with that set `id` together.
        // @ts-expect-error refactor
        if (!sets[setId]) {
          // @ts-expect-error refactor
          sets[setId] = makeInstance(setId);
        }

        // @ts-expect-error refactor
        const set = sets[setId];

        set.fields.push(field);
      } else {
        // Otherwise, each output field is considered a separate output
        // instance
        // @ts-expect-error refactor
        singleFields.push(makeInstance(field.id, [field]));
      }
    }

    // @ts-expect-error refactor
    return [...Object.values(sets), ...singleFields].filter(Boolean);
  },
};

export const OutputField = {
  componentDisplayTypeLabels: {
    AddressGroup: {
      [AddressFormats.SingleLine]: "Single field",
      [AddressFormats.TwoLines]: "Two fields",
      [AddressFormats.ThreeLines]: "Three fields",
      [AddressFormats.FourLines]: "Four fields",
      [AddressFormats.SeparateFields]: "Separate fields",
    },
  },

  isLinkedTo(outputField: OutputFieldType, component: ComponentType) {
    return isLinkedTo(outputField, component);
  },

  /**
   * This function will return `true` if the given output field refrences the
   * given component. This is a _crude_ check and is not meant for crucial
   * operations, so it's marked as `unsafe` for now
   */
  unsafe_hasReferenceTo(
    outputField: OutputFieldType,
    component: ComponentType
  ) {
    const { dataRef } = component;
    const { value } = outputField;

    // Sanity check
    if (typeof value !== "string") return false;

    // @ts-expect-error refactor
    return value.includes(dataRef);
  },

  inSameSet(a: OutputFieldType, b: OutputFieldType) {
    const isPartOfSet = a.meta?.group !== undefined;
    if (isPartOfSet) return a.meta?.group === b.meta?.group;

    return false;
  },

  /**
   * Returns the id of the set for this output field if it exists
   *
   * NOTE: This is currently named as 'group' in the schema but should be
   * referred to as a set for naming consistency.
   */
  getSetId(outputField: OutputFieldType) {
    return outputField?.meta?.group;
  },

  /**
   * Returns the display type configuration for the given component and output
   * field. Different component types will have different display types.
   */
  getDisplayType(component: ComponentType, outputField: OutputFieldType) {
    switch (component.type) {
      case "AddressGroup":
        return outputField?.options?.format;
      default:
        return undefined;
    }
  },

  /**
   * Returns a string label for a specific component output instance. This
   * value depends on _both_ output field type (`Type`, `Checkmark`, `Signature`, `Multiline`)
   * _and_ the component type.
   */
  describeComponentOutputInstance(
    component: ComponentType,
    instance: ComponentOutputInstance
  ) {
    const { fields } = instance;
    const firstField = fields[0];

    invariant(
      firstField,
      "A component output instance should have at least one output field"
    );

    switch (firstField.type) {
      case "Text":
        switch (component.type) {
          case "AddressGroup": {
            const displayType = OutputField.getDisplayType(
              component,
              firstField
            );

            invariant(
              displayType,
              `Unknown display type for component ${component.type}`
            );

            const label =
              OutputField.componentDisplayTypeLabels.AddressGroup[displayType];

            return `Address - ${label}`;
          }
          default:
            return `Text field`;
        }
      case "Checkmark":
        return "Checkboxes";
      case "Signature":
        return "Signature";
      case "Multiline":
        return "Multiline";
      default:
        throw new Error(`Unknown output field type ${firstField.type}`);
    }
  },
};

/**
 * Utilities
 */

/**
 * Update an item in an array by a predicate:
 */
function updateWhere<Item = unknown>(
  arr: Array<Item>,
  predicate: (item: Item) => boolean,
  updater: (item: Item) => Item
) {
  return arr.map((item) => {
    if (predicate(item)) {
      return updater(item);
    } else {
      return item;
    }
  });
}

/**
 * Update an item in an array by its `id` property
 */
function updateAtId<Item extends { id: string }>(
  arr: Array<Item>,
  id: string,
  updater: (item: Item) => Item
) {
  return updateWhere(arr, (item) => item.id === id, updater);
}
