import * as React from "react";
import ReactDOM from "react-dom";
import { setRef } from "hw/ui/utils";

const container = document.body;

type Props = {
  children: React.ReactNode;
  className?: string;
  type?: string;
};

/**
 * A component for rendering elements within a React portal
 */
export const Portal = React.forwardRef<HTMLDivElement, Props>(function Portal(
  props: Props,
  ref
) {
  const { children, className, type = "" } = props;
  const [mountNode, setMountNode] = React.useState(null);

  React.useLayoutEffect(() => {
    if (!container) return;

    const el = document.createElement("div");
    el.setAttribute("data-hw-portal", type);

    if (className) {
      el.className = className;
    }

    container.appendChild(el);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLDivElement' is not assignabl... Remove this comment to see the full error message
    setMountNode(el);

    return () => {
      container.removeChild(el);
    };
  }, [className, type]);

  React.useLayoutEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setRef(ref, mountNode);

    return () => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setRef(ref, null);
    };
  }, [ref, mountNode]);

  if (!container) return null;

  return mountNode ? ReactDOM.createPortal(children, mountNode) : null;
});

export default Portal;
