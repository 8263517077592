import * as React from "react";
import theme from "hw/ui/theme";
import Button from "hw/ui/button";

type Props = {
  active: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  extend?: {};
};
export default function SettingsButton(props: Props) {
  const { active, extend, ...rest } = props;

  /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  const fullExtend = {
    color: theme.color.textStandard,
    backgroundColor: active ? theme.color.blue100 : theme.color.gray100,
    fontSize: theme.fontSize.sm,
    ...extend,
  };
  return <Button {...rest} presentation="primaryOutline" extend={fullExtend} />;
}
