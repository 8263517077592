/**
 * JSON-parses a data attribute on a DOM node.  Can be used for retrieving
 * initial client-side state rendered out by the server.
 *
 * @example
 * const data = parseDomNodeDataset('root', 'data');
 */
export default function parseDomNodeDataset(id: string, attr: string) {
  const el = document.getElementById(id);

  if (el) {
    const dataset = el.dataset[attr] ?? "";

    return JSON.parse(dataset);
  } else {
    throw new Error(`Could not parse contents of node with ID ${id}.`);
  }
}
