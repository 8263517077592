/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { createComponent } from "hw/ui/style";
import OriginalIconWrapper from "hw/ui/icons";

const MIN_HEIGHT = 100;

export const ToolbarSection = createComponent(function IconSection({
  theme,
  last,
}: any) {
  return {
    display: "flex",
    borderRightStyle: "solid",
    borderRightWidth: last ? "0px" : "1px",
    borderRightColor: theme.color.gray200,
    paddingLeft: theme.space.xs,
    paddingRight: theme.space.xs,
  };
});

export const ToolbarContainer = createComponent(function Container({
  theme,
  isScrolled,
}: any) {
  return {
    backgroundColor: theme.color.gray025,
    borderRadius: theme.corner.xs,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: theme.space.sm,
    paddingBottom: theme.space.sm,
    width: "100%",
    transitionProperty: "box-shadow",
    transitionDuration: theme.transitionSpeed.fast,
    boxShadow: isScrolled && theme.shadow[2],
  };
});

function IconWrapperExtend({ theme, selected }: any) {
  return {
    marginLeft: theme.space.xs,
    backgroundColor: selected ? theme.color.gray200 : "transparent",
    color: selected ? theme.color.gray900 : theme.color.gray500,
    ":hover": {
      backgroundColor: theme.color.gray200,
    },
    ":active": {
      backgroundColor: theme.color.blue100,
    },
  };
}

export const ToolbarIconWrapper = (props: any) => (
  <OriginalIconWrapper {...props} extend={IconWrapperExtend} />
);

export const ScrollContainer = createComponent(
  function ScrollContainer({ isActive, maxHeight, theme }: any) {
    return {
      overflowY: isActive ? "auto" : "hidden",
      maxHeight: typeof maxHeight === "string" ? maxHeight : `${maxHeight}px`,
      minHeight: `${MIN_HEIGHT}px`,
      position: "relative",
      transitionProperty: "max-height",
      transitionDuration: theme.transitionSpeed.fast,
      ":after": !isActive && {
        content: '""',
        width: "100%",
        height: "3em",
        position: "absolute",
        pointerEvents: "none",
        left: 0,
        bottom: 0,
        backgroundImage:
          "linear-gradient(to bottom,rgba(255,255,255, 0),rgba(255,255,255, 1) 90%)",
      },
    };
  },
  "div",
  ["onScroll"]
);

export const EditorContainer = createComponent(function EditorContainer({
  isActive,
}: any) {
  return {
    pointerEvents: isActive ? "all" : "none",
    // Needed to ensure the scroll container overflow gets triggered
    height: "100%",
  };
});
