import * as React from "react";
import FileAttachment from "hw/common/components/smartforms/file-attachment";
import { PremiumPreview as Premium } from "hw/portal/modules/common/components/premium";
import type { FileAttachmentField } from "hw/portal/modules/common/draft";

type Props = {
  field: FileAttachmentField;
};

class FileAttachmentPreview extends React.Component<Props> {
  render() {
    const { field } = this.props;
    const { label, required, instructions } = field;
    // The `required` field could be a macro in which case the form builder won't
    // know if the field is required or not because macros are not being
    // evaluated.  For V1, we'll coerce string values to `true` and treat them
    // as required.
    const isRequired = Boolean(required);
    return (
      <>
        <Premium feature="FileAttachment" />
        <FileAttachment
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disableUpload: boolean; isValid: true; lab... Remove this comment to see the full error message
          disableUpload={true}
          isValid={true}
          label={label}
          readOnly={false}
          required={isRequired}
          instructions={instructions}
        />
      </>
    );
  }
}

export default FileAttachmentPreview;
