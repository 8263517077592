import * as React from "react";
import { TextToggle, TextArea } from "./basics";
import type { SettingsProps } from "../../../types";

type Props = SettingsProps & {
  instructionText?: string;
};

const Instructions = (props: Props) => {
  /* $FlowFixMe[incompatible-use] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  const { instructionText, ...restProps } = props;
  const textToggleProps = {
    schemaPropName: "instructions",
    name: "Instructions",
    text: instructionText,
    placeHolder: "Instructions to complete this field",
    InputComponent: TextArea,
    ...restProps,
  };
  return <TextToggle {...textToggleProps} />;
};

export default Instructions;
