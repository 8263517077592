/**
 * WARNING: This is a generated file
 *
 * Please do not make any changes to this file directly.  This file is
 * generated from the source theme file.  Any changes needed
 * here should be done within that file and the theme should be regenerated
 * after following instructions in the readme.
 */

/**
 * Usage:
 *   To use these variables, import from the `hw/ui/theme` directory and use as
 *   needed.
 *
 *   import theme from 'hw/ui/theme';
 *
 *   const className = useStyle({
 *     color: theme.color.gray100
 *   })
 */

const theme = {
  color: {
    coreAccent: "#0061fe",
    __coreAccent: "var(--hw-color-core-accent)",
    white: "#ffffff",
    black: "#000000",
    transparent: "transparent",
    gray025: "#F8F9FA",
    gray050: "#F2F5F7",
    gray100: "#E9ECEF",
    gray200: "#DEE3E9",
    gray300: "#CED4DA",
    gray400: "#ADB5BD",
    gray500: "#959CA4",
    gray600: "#727A83",
    gray700: "#555D65",
    gray800: "#343A40",
    gray900: "#121519",
    gray90002: "rgba(18, 21, 25, 0.2)",
    red025: "#FFF7F7",
    red050: "#FFEEEE",
    red100: "#FFD6D6",
    red200: "#FF9191",
    red300: "#FF6464",
    red400: "#F63838",
    red500: "#DD1D1D",
    red600: "#B81414",
    red700: "#8F0F0F",
    red800: "#760505",
    red900: "#550000",
    yellow025: "#FFFDF2",
    yellow050: "#FFFCE8",
    yellow100: "#FFF6C7",
    yellow200: "#FFE175",
    yellow300: "#FFC733",
    yellow400: "#FCB212",
    yellow500: "#F19D00",
    yellow600: "#E67E00",
    yellow700: "#C75D00",
    yellow800: "#A14000",
    yellow900: "#672600",
    green025: "#F5FFF5",
    green050: "#F0FFF0",
    green100: "#D2F7D4",
    green200: "#95ECA0",
    green300: "#51D474",
    green400: "#24B34A",
    green500: "#189841",
    green600: "#0C7A35",
    green700: "#00631C",
    green800: "#004E24",
    green900: "#003A1C",
    blue025: "#F7FEFF",
    blue050: "#EDFDFF",
    blue100: "#D7F9FF",
    blue200: "#8AE2F4",
    blue300: "#3DC9EA",
    blue400: "#10B3E4",
    blue500: "#019BCE",
    blue600: "#0078A0",
    blue700: "#006384",
    blue800: "#00506B",
    blue900: "#00374D",
    purple025: "#FDFBFF",
    purple050: "#FBF5FF",
    purple100: "#EFDAFC",
    purple200: "#E5BEFC",
    purple300: "#C478EF",
    purple400: "#AA4CE2",
    purple500: "#9844CD",
    purple600: "#7B33A8",
    purple700: "#631D8E",
    purple800: "#4C0E73",
    purple900: "#370457",
    textLighter: "#959CA4",
    textLight: "#727A83",
    textMid: "#555D65",
    textDark: "#343A40",
    textDarker: "#121519",
    textStandard: "#121519",
    textDim: "#555D65",
    textFaint: "#959CA4",
    textInverse: "#ffffff",
    textPlaceholder: "#727A83",
    textLabelDisabled: "#727A83",
    textFieldDisabled: "#959CA4",
    textSelectPrompt: "#555D65",
    textAction: "#0078A0",
    textActionHover: "#019BCE",
    textActionPressed: "#006384",
    textError: "#760505",
    iconDefault: "#727A83",
    iconDim: "#ADB5BD",
    iconHover: "#343A40",
    iconPressed: "#343A40",
    iconDisabled: "#ADB5BD",
    surfaceLight0: "#ffffff",
    surfaceLight1: "#F8F9FA",
    surfaceLight2: "#F2F5F7",
    uiActionBase: "#0078A0",
    uiActionHover: "#019BCE",
    uiActionPressed: "#D7F9FF",
    uiActionDisabled: "rgba(0, 55, 77, 0.1)",
    uiDropdownTriggerActive: "rgba(0, 55, 77, 0.12)",
    buttonBgStandard: "rgba(0, 55, 77, 0.09)",
    buttonBgStandardHover: "rgba(0, 55, 77, 0.12)",
    buttonBgSubtleHover: "rgba(0, 55, 77, 0.09)",
    buttonBgSubtlePressed: "#D7F9FF",
    buttonBgDisabled: "rgba(0, 55, 77, 0.05)",
    buttonPrimaryBg: "#0061fe",
    buttonPrimaryBgHover: "#0057e5",
    buttonPrimaryBgPressed: "#0050d0",
    buttonPrimaryBgDisabled: "#c0bbb4",
    buttonPrimaryBgDisabledColor: "#ffffff",
    buttonPrimaryColor: "#ffffff",
    __buttonPrimaryBg: "var(--hw-color-button-primary-bg)",
    __buttonPrimaryBgHover: "var(--hw-color-button-primary-bg-hover)",
    __buttonPrimaryBgPressed: "var(--hw-color-button-primary-bg-pressed)",
    __buttonPrimaryBgDisabled: "var(--hw-color-button-primary-bg-disabled)",
    __buttonPrimaryBgDisabledColor:
      "var(--hw-color-button-primary-disabled-color)",
    __buttonPrimaryColor: "var(--hw-color-button-primary-color)",
    buttonPrimaryOutlineBg: "#0061fe",
    buttonPrimaryOutlineBgHover: "#0057e5",
    buttonPrimaryOutlineBgPressed: "#0050d0",
    buttonPrimaryOutlineBgDisabled: "transparent",
    buttonPrimaryOutlineBgDisabledColor: "#ffffff",
    buttonPrimaryOutlineBorderColor: "none",
    buttonPrimaryOutlineBorderColorHover: "none",
    buttonPrimaryOutlineBorderColorPressed: "none",
    buttonPrimaryOutlineColor: "#ffffff",
    buttonPrimaryOutlineColorPressed: "#ffffff",
    __buttonPrimaryOutlineBg: "var(--hw-color-button-primary-outline-bg)",
    __buttonPrimaryOutlineBgHover:
      "var(--hw-color-button-primary-outline-bg-hover)",
    __buttonPrimaryOutlineBgPressed:
      "var(--hw-color-button-primary-outline-bg-pressed)",
    __buttonPrimaryOutlineBgDisabled:
      "var(--hw-color-button-primary-outline-bg-disabled)",
    __buttonPrimaryOutlineBgDisabledColor:
      "var(--hw-color-button-primary-outline-bg-disabled-color)",
    __buttonPrimaryOutlineBorderColor:
      "var(--hw-color-button-primary-outline-border-color)",
    __buttonPrimaryOutlineBorderColorHover:
      "var(--hw-color-button-primary-outline-border-color-hover)",
    __buttonPrimaryOutlineBorderColorPressed:
      "var(--hw-color-button-primary-outline-border-color-pressed)",
    __buttonPrimaryOutlineColor: "var(--hw-color-button-primary-outline-color)",
    __buttonPrimaryOutlineColorPressed:
      "var(--hw-color-button-primary-outline-color-pressed)",
    uiFocus: "rgba(1, 155, 206, 0.7)",
    inputBgDisabled: "rgba(0, 55, 77, 0.05)",
    inputBorderDisabled: "rgba(18, 21, 25, 0.2)",
    borderSelectedColor: "#0078A0",
    dividerDefault: "#DEE3E9",
    dividerDim: "#E9ECEF",
  },
  space: {
    none: 0,
    xs: "4px",
    sm: "8px",
    ms: "12px",
    md: "16px",
    lg: "24px",
    xl: "32px",
    xxl: "64px",
  },
  fontSize: {
    none: 0,
    xs: ".6875rem",
    sm: ".75rem",
    ms: ".875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xxl: "1.875rem",
  },
  shadow: {
    "1": "0 1px 2px rgba(0, 0, 0, .1)",
    "2": "0 2px 4px rgba(0, 0, 0, .1)",
    "3": "0 4px 5px rgba(0, 0, 0, .1)",
  },
  shadowReverse: {
    "1": "0 -1px 2px rgba(0, 0, 0, .1)",
    "2": "0 -2px 4px rgba(0, 0, 0, .1)",
    "3": "0 -4px 5px rgba(0, 0, 0, .1)",
  },
  corner: {
    none: 0,
    xs: "1px",
    sm: "2px",
    md: "4px",
    round: "100rem",
    buttonPrimary: "0px",
    __buttonPrimary: "var(--hw-corner-button-primary)",
  },
  fontFamily: { brand: "proxima-nova, sans-serif", monospace: "monospace" },
  fontWeight: { normal: 400, semibold: 600, bold: 700 },
  layer: { focus: 400, shade: 500, modal: 510, hoverCard: 800 },
  transitionSpeed: { fast: ".2s" },
} as const;

export default theme;
