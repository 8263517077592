/* eslint-disable @typescript-eslint/no-explicit-any */
import throttle from "lodash/throttle";
import * as React from "react";
import { DropTarget } from "react-dnd";
import * as Styled from "../styled";
import { DRAG_TYPE_FIELD } from "../../constants";
// The amount of pixels before the edge to start scrolling
// This can be adjusted to taste
const DEFAULT_BUFFER = 175;
// These values adjust how fast the scrolling happens
const verticalStrength = createVerticalStrength(DEFAULT_BUFFER);
const strengthMultiplier = 40;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef: (...args: Array<any>) => any;
  isAnyFieldDragging: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dragDropManager: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...args: Array<any>) => any;
};
/**
 * This component monitors if fields are currently being dragged and scrolls the
 * window if the item being dragged is within a certain threshold of the scroll
 * container.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ScrollContainer extends React.Component<any> {
  container: HTMLElement | null | undefined;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'scaleY' has no initializer and is not de... Remove this comment to see the full error message
  scaleY: number;

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'AnimationFrameID'.
  frame: AnimationFrameID | null | undefined;

  getDragDropManager() {
    return this.props.dragDropManager;
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'node' implicitly has an 'any' type.
  handleRef = (node) => {
    this.container = node;
    this.props.innerRef(node);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (!prevProps.isAnyFieldDragging && this.props.isAnyFieldDragging) {
      // If we're dragging a field, attach an additional `dragover` listener
      // to update scrolling as needed
      this.attach();
    } else if (prevProps.isAnyFieldDragging && !this.props.isAnyFieldDragging) {
      // Once we're done dragging a field, remove the additional listener
      this.stopScrolling();
      this.detach();
    }
  }

  componentWillUnmount() {
    this.detach();
    this.updateScrolling.cancel();
    this.stopScrolling();
  }

  attach() {
    window.document.body.addEventListener("dragover", this.updateScrolling, {
      capture: true,
      passive: true,
    });
  }

  detach() {
    window.document.body.removeEventListener("dragover", this.updateScrolling, {
      capture: true,
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      passive: true,
    });
  }

  updateScrolling = throttle(
    (evt) => {
      if (!this.container) {
        return;
      }

      const {
        left: x,
        top: y,
        width: w,
        height: h,
      } = this.container.getBoundingClientRect();
      const box = {
        x,
        y,
        w,
        h,
      };
      const coords = getCoords(evt);
      // calculate strength
      this.scaleY = verticalStrength(box, coords);

      // start scrolling if we need to
      if (!this.frame && this.scaleY) {
        this.startScrolling();
      }
    },
    100,
    {
      trailing: false,
    }
  );

  startScrolling() {
    let i = 0;

    const tick = () => {
      const { scaleY, container } = this;

      if (!container) {
        return;
      }

      // stop scrolling if there's nothing to do
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      if (strengthMultiplier === 0 || scaleY === 0) {
        this.stopScrolling();
        return;
      }

      // there's a bug in safari where it seems like we can't get
      // mousemove events from a container that also emits a scroll
      // event that same frame. So we double the strengthMultiplier and only adjust
      // the scroll position at 30fps
      if (i++ % 2) {
        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scaleY) {
          container.scrollTop = intBetween(
            0,
            scrollHeight - clientHeight,
            scrollTop + scaleY * strengthMultiplier
          );
        }
      }

      this.frame = requestAnimationFrame(tick);
    };

    tick();
  }

  stopScrolling() {
    this.scaleY = 0;

    if (this.frame) {
      cancelAnimationFrame(this.frame);
      this.frame = null;
    }
  }

  render() {
    const { onClick } = this.props;
    return (
      <Styled.ScrollContainer
        innerRef={this.handleRef}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        extend={ExtendScrollContainer}
        onClick={onClick}
        data-testid="form-builder-scroll-container"
      >
        {this.props.children}
      </Styled.ScrollContainer>
    );
  }
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
function ExtendScrollContainer({ theme }) {
  return {
    backgroundColor: theme.color.gray025,
    padding: theme.space.ms,
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'min' implicitly has an 'any' type.
function intBetween(min, max, val) {
  return Math.floor(Math.min(max, Math.max(min, val)));
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
function getCoords(evt) {
  return {
    x: evt.clientX,
    y: evt.clientY,
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter '_buffer' implicitly has an 'any' type.
function createVerticalStrength(_buffer) {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'y' implicitly has an 'any' type.
  return function defaultVerticalStrength({ y, h }, point) {
    const buffer = Math.min(h / 2, _buffer);

    if (point.y < y + buffer) {
      return (point.y - y - buffer) / buffer;
    } else if (point.y > y + h - buffer) {
      return -(y + h - point.y - buffer) / buffer;
    }

    return 0;
  };
}

const dropSpec = {
  drop() {},
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter '_connect' implicitly has an 'any' type.
function dropCollect(_connect, monitor) {
  return {
    isAnyFieldDragging: monitor.getItemType() === DRAG_TYPE_FIELD,
  };
}

const Component: React.ComponentType<any> = DropTarget(
  DRAG_TYPE_FIELD,
  dropSpec,
  dropCollect
)(ScrollContainer);
export default Component;
