import React from "react";
import { Box, Text } from "hw/ui/blocks";
import JumpToDefLink from "../components/jump-to-def-link";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpToDef: (...args: Array<any>) => any;
};
export default function Fallback(props: Props) {
  const { jumpToDef } = props;
  return (
    <Box px="sm">
      <Text fontSize="ms" mb="ms">
        This condition has been edited in the code view and can no longer be
        edited here.
      </Text>
      {typeof jumpToDef === "function" && <JumpToDefLink onClick={jumpToDef} />}
    </Box>
  );
}
