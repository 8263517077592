import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useIsAuthorizedTo, permissions } from "hw/portal/modules/authn";
import { WarningIcon } from "hw/ui/icons";
import { A } from "hw/ui/text";
import * as heap from "hw/portal/modules/analytics/heap";
import styles from "./upgrade-banner.module.css";

type Props = {
  className?: string;
};

function handleUpgradeLinkClick() {
  heap.track("Upgrade link clicked", {
    Source: "Bulk send",
  });
}

export default function UpgradeBanner(props: Props) {
  const { className = "" } = props;
  const classes = [styles.upgradeBanner, className].join(" ");

  const onClick = React.useCallback(() => {
    handleUpgradeLinkClick();
  }, []);

  const isAuthorizedToReadBilling = useIsAuthorizedTo(
    permissions.ReadTeamBilling
  );

  const linkText = (...chunks: string[]) => {
    const attributes = {
      href: "/plan",
      "data-track-id": "bulk-send-view_plans_self-serve",
      onClick,
    };
    if (!isAuthorizedToReadBilling) {
      return null;
    }
    return <A {...attributes}>{chunks}</A>;
  };

  return (
    <div className={classes}>
      <span className={styles.warningIcon}>
        <WarningIcon />
      </span>
      <span className={styles.upgradeText}>
        <span>
          <FormattedMessage
            id="bulk.send.upgrade.link.text"
            description="bulk send, upgrade link text, informs user that bulk send is a paid feature and user needs to upgrade to continue"
            defaultMessage="Bulk send is a premium feature. <link>View plans to upgrade</link>"
            values={{ link: linkText }}
          />
        </span>
      </span>
    </div>
  );
}
