import React from "react";
import cx from "classnames";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import Tooltip from "hw/ui/tooltip";
import { Sparkle16 } from "hw/ui/icons/svg-icons";
import { PremiumWrapper as Premium } from "hw/portal/modules/common/components/premium";
import * as options from "./options";
import styles from "./grid-menu.module.css";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (...args: Array<any>) => any;
  highlightedIndex: number;
  isNested?: boolean;
};
export default function ComponentGrid(props: Props) {
  const { isNested, getItemProps } = props;
  const buildOption = React.useCallback(
    (hasAccess, component, index) => {
      return (
        <Option
          key={component.label}
          component={component}
          hasAccess={hasAccess}
          index={index}
          getItemProps={getItemProps}
        />
      );
    },
    [getItemProps]
  );
  const components = [
    options.TextInput,
    options.MultipleChoice,
    options.Signature,
    options.Multiline,
    options.Address,
    options.DateInput,
    options.PhoneNumber,
    options.Email,
    options.SSN,
    options.FileAttachment,
    ...(isNested ? [] : [options.Group]),
    ...(isNested ? [] : [options.Paragraph]),
    options.Calculation,
  ];
  return (
    <>
      <p className={styles.title}>Form Components</p>
      <div className={cx(styles.grid)}>
        {components.map((component, index) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'premium' does not exist on type '{ "data... Remove this comment to see the full error message
          const isPremiumComponent = component.premium;
          return isPremiumComponent ? (
            <Premium
              key={component.label}
              feature={component.value}
              access={buildOption(true, component, index)}
              noAccess={
                <Tooltip position="top" tip={"Available with paid plans"}>
                  <span>{buildOption(false, component, index)}</span>
                </Tooltip>
              }
              /*
          The <Premium> component will show nothing until entitlements load.
          Unfortunately, that may cause an abrupt shift when the premium components pop into the menu.
          So, we can show the "access" view while loading (i.e. loadingView). Should be fine since
          we don't really prevent unpaid users from using the component anyway.
          They'll just see the premium "sparkle" marker a brief moment later to indicate they
          don't have full access.
          */
              loadingView={buildOption(true, component, index)}
            />
          ) : (
            buildOption(true, component, index)
          );
        })}
      </div>
    </>
  );
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'component' implicitly has an 'any... Remove this comment to see the full error message
const Option = ({ component, hasAccess, index, getItemProps }) => {
  return (
    <button
      className={styles.cell}
      data-test-ref={`new-field-item-${component.value}`}
      data-testid="new-field-item"
      {...getItemProps({
        item: component,
        index,
      })}
      {...getTestAttributes(component["data-guide-id"])}
    >
      {!hasAccess ? (
        <span className={styles.iconWrapper}>
          <span className={styles.sparkle}>
            <Sparkle16 />
          </span>
          <component.Icon />
        </span>
      ) : (
        <component.Icon />
      )}
      <p className={styles.label}>{component.label}</p>
    </button>
  );
};

ComponentGrid.defaultProps = {
  getItemProps: () => {},
};
