/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import cx from "classnames";
import type {
  PdfMapping,
  Role,
  PageMetadata,
  OutputField,
} from "hw/portal/modules/common/draft";
import { OutputFields } from "hw/portal/modules/common/draft/schema-ops";
import type { Path } from "hw/common/types";
import type { Api } from "hw/portal/modules/draft-editor/types";
import {
  DocumentMapperProvider,
  DocumentMapper,
  useDocumentMapper,
  Page,
  Field,
  sortFields,
  queryField,
} from "hw/ui/document-mapper";
import { useDebouncedFn } from "hw/common/hooks";
import { hexToRGBA } from "hw/ui/theme";
import {
  PdfLoading,
  PdfError,
  PdfDocument,
  PdfPage,
} from "../../components/pdf";
import * as Actions from "./actions";
import PdfMappingSettings from "./settings";
import { OutputFieldSettingsPopover } from "./settings-popover";
import { toFieldRect } from "./utils";
import { withRawValue, getLinkedField } from "../mapping";
import { getRoleTheme } from "../participants";
import styles from "./pdf-mapper.module.css";
import { PdfOverlay } from "./pdf-overlay";
import { useBuilderContext } from "../context";
import Label from "./label";

export {
  useDocumentMapper as usePdfMapper,
  queryField as getFieldElement,
} from "hw/ui/document-mapper";

type Props = {
  // $FlowIgnore
  api: Api;

  /**
   * List of all mappings in this form
   */
  mappings: Array<PdfMapping>;

  /**
   * Dispatcher for the editor
   */
  dispatch: (action: any) => void;

  /**
   * The currently selected mapping
   */
  selectedMapping: PdfMapping | null | void;

  /**
   * Deselect all selected output fields
   */
  onDeselectOutputField: Function;

  /**
   * Selects a mapping
   */
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  onSelectOutputField: (field: OutputField) => void;

  /**
   * Path to update this pdf map
   */
  path: Path;

  /**
   * Draft roles
   */
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  roles: Array<Role>;

  /**
   * Opens the role modal
   */
  openRoleModal: Function;

  /**
   * The list of ID's currently selected in the field list
   */
  selectedFieldIds: Array<string>;

  /**
   * The list of fields being hinted at (hovered over) from the field list
   */
  hintedFieldIds: Array<string>;

  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  onMappingHover: (mapping: PdfMapping) => void;

  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  onMappingUnhover: (mapping: PdfMapping) => void;

  templateId: string;

  deleteField: (fieldPath: Path) => void;

  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  pageMetadata: PageMetadata;

  scrollContainerClassName: string;

  "data-testid": string;
};

export function withPdfMapperContext<Config>(
  Component: React.ComponentType<Config>
): React.ComponentType<Config> {
  return function PdfMapperContextProvider(props) {
    const component = <Component {...props} />;

    return <DocumentMapperProvider>{component}</DocumentMapperProvider>;
  };
}

export default function PdfMapper(props: Props) {
  const {
    mappings,
    dispatch,
    selectedMapping,
    onSelectOutputField,
    path,
    roles,
    openRoleModal,
    selectedFieldIds,
    hintedFieldIds,
    onMappingHover,
    onMappingUnhover,
    templateId,
    api,
    deleteField,
    pageMetadata,
    onDeselectOutputField,
  } = props;

  const { mappableFieldsById, fieldPathsById } = useBuilderContext();
  const state = useFetchTemplate(templateId, api);

  const getComponentOutputInstances = React.useCallback(
    (componentId) => {
      const outputFieldsWithSameSource = mappings.filter(
        (field) => field.source === componentId
      );
      return OutputFields.toComponentOutputInstances(
        outputFieldsWithSameSource
      );
    },
    [mappings]
  );

  /**
   * When removing an output field we following this logic:
   *
   *   - Remove the given output field _and_ any output fields that are
   *     also part of the same set. E.g. Removing an `AddressGroup` displaying
   *     as `Two fields` will delete all of those associated fields.
   *   - If the output field is part of the only output set remaining,
   *     also delete the component.
   */
  const onRemoveOutputField = React.useCallback(
    (outputFieldToRemove, fieldPath) => {
      // Turn those into the list of instances for this component
      const componentOutputInstances = getComponentOutputInstances(
        outputFieldToRemove.source
      );

      // If there is only one instance, then we just delete the component.
      // Deleting the component will automatically delete the associated
      // output fields
      const isLastInstance = componentOutputInstances.length === 1;

      if (isLastInstance && fieldPath) {
        deleteField(fieldPath);
      } else {
        // There is more than one output instance left.
        // We need to delete all output fields in the same set as the given field

        // Find the associated instance for this output field
        const instance = componentOutputInstances.find((instance) =>
          instance.fields.some((field) => field.id === outputFieldToRemove.id)
        );
        let idsToRemove = new Set();

        if (instance) {
          idsToRemove = new Set(instance.fields.map((field) => field.id));
        } else {
          // This shouldn't happen because all fields should be part of an
          // instance set, but leaving this here as a fallback
          idsToRemove = new Set([outputFieldToRemove.id]);
        }
        dispatch(
          Actions.updatePdfMap({
            path,
            updater: (mappings) =>
              // @ts-expect-error refactor
              mappings.filter((m) => !idsToRemove.has(m.id)),
          })
        );
      }
    },
    [dispatch, deleteField, path, getComponentOutputInstances]
  );

  const onMove = React.useCallback(
    (result) => {
      dispatch(
        Actions.updatePdfMap({
          path,
          updater(pdfMap) {
            // @ts-expect-error refactor
            return pdfMap.map((outputField) => {
              if (result[outputField.id]) {
                const moved = result[outputField.id];
                return withRawValue({
                  ...outputField,
                  coords: {
                    ...outputField.coords,
                    x: moved.x,
                    y: moved.y,
                    page: moved.page,
                  },
                });
              } else {
                return outputField;
              }
            });
          },
        })
      );
    },
    [dispatch, path]
  );

  const onResize = React.useCallback(
    (result) => {
      const basePath = path.slice(0, -1);

      dispatch(
        Actions.resizeOutputField({ path: basePath, resizedFields: result })
      );
    },
    [dispatch, path]
  );

  const onDelete = React.useCallback(
    (id) => {
      const field = mappings.find((field) => field.id === id);

      if (field) {
        const linkedComponent = getLinkedField(field, mappableFieldsById);
        const componentPath = linkedComponent
          ? fieldPathsById.get(linkedComponent.id)
          : undefined;

        onRemoveOutputField(field, componentPath);
      }
    },
    [fieldPathsById, mappings, mappableFieldsById, onRemoveOutputField]
  );

  switch (state.value) {
    case "loading":
      return <PdfLoading />;
    case "error":
      return <PdfError />;
    case "loaded":
      return (
        <>
          <DocumentMapper
            autoScroll={true}
            autoScrollClassName={styles.scrollContainer}
            onMove={onMove}
            onResize={onResize}
            onDelete={onDelete}
            data-testid="pdf-mapper"
          >
            {pageMetadata.pageSizes.map((page, idx) => {
              const { height, width } = page;
              const pageNumber = idx + 1;
              const pageFields = mappings.filter(
                (field) => field.coords.page === pageNumber
              );
              return (
                <Page
                  width={width}
                  height={height}
                  number={pageNumber}
                  key={pageNumber}
                  content={
                    <PageContent
                      {...page}
                      number={pageNumber}
                      // @ts-expect-error refactor
                      file={state.data}
                      renderOverlay={mappings.length === 0}
                    />
                  }
                  className={styles.page}
                >
                  {sortFields(pageFields, toFieldRect).map((field) => {
                    const extendedState = getExtendedFieldState(
                      selectedFieldIds,
                      hintedFieldIds,
                      field.source
                    );
                    return (
                      <OutputFieldComponent
                        key={field.id}
                        field={field}
                        roles={roles}
                        extendedState={extendedState}
                        onHover={onMappingHover}
                        onUnhover={onMappingUnhover}
                      />
                    );
                  })}
                </Page>
              );
            })}
          </DocumentMapper>
          <OutputFieldSettingsPopover
            // @ts-expect-error refactor
            field={selectedMapping}
            onClickOutside={() => onDeselectOutputField()}
            // @ts-expect-error refactor
            getElement={queryField}
          >
            {(field) => {
              return (
                <PdfMappingSettings
                  mapping={field}
                  mappings={mappings}
                  updateMap={(updater) => {
                    dispatch(
                      Actions.updatePdfMap({
                        path,
                        updater,
                      })
                    );
                  }}
                  dispatch={dispatch}
                  path={[...path, mappings.findIndex((f) => f.id === field.id)]}
                  onSelect={onSelectOutputField}
                  roles={roles}
                  openRoleModal={openRoleModal}
                  onRemove={onRemoveOutputField}
                  getComponentOutputInstances={getComponentOutputInstances}
                  pageMetadata={pageMetadata}
                />
              );
            }}
          </OutputFieldSettingsPopover>
        </>
      );
    default:
      return null;
  }
}

// @ts-expect-error refactor
function useFetchTemplate(templateId, api) {
  const [state, setState] = React.useState({ value: "idle" });

  // TODO: Figure out a better to let API callers cancel their own requests
  // Our provided `api` props have their requests automatically canceled
  // when the editor is unmounted, but this prevents individual requests from
  // being canceled within the editor.
  React.useEffect(() => {
    let mounted = true;
    setState({ value: "loading" });
    api.fetchTemplate(templateId).then(
      // @ts-expect-error refactor
      (result) => mounted && setState({ value: "loaded", data: result }),
      // @ts-expect-error refactor
      (error) => {
        if (error.name && error.name === "AbortError") {
          return;
        }

        if (mounted) {
          // @ts-expect-error refactor
          setState({ value: "error", error });
        }
      }
    );

    return () => {
      mounted = false;
    };
  }, [templateId, api]);

  return state;
}

/**
 * Handles 'hover' and 'onHover' events for an element in a debounced way to
 * avoid over-calling the events when they are likely not needed. This is
 * helpful for dragging and resizing where mouse events could be called many
 * times without much practical benefit
 */
const debounceTimeout = process.env.NODE_ENV === "test" ? 0 : 100;

// @ts-expect-error refactor
function useDebouncedHoverEvts(onHover, onUnhover) {
  // Use a ref to keep track of the hover state
  const state = React.useRef("idle");

  const debouncedOnHover = useDebouncedFn(
    React.useCallback(() => {
      onHover();
      // If we've gotten here, consider the state 'hovered'
      state.current = "hovered";
    }, [onHover]),
    debounceTimeout
  );

  const onMouseEnter = React.useCallback(() => {
    state.current = "pending";
    debouncedOnHover();
  }, [debouncedOnHover]);

  const onMouseLeave = React.useCallback(() => {
    if (state.current === "pending") {
      // If the hover is still pending cancel it without calling the cb
      debouncedOnHover.cancel();
    } else if (state.current === "hovered") {
      // If the hover callback has been called, call the onHover callback
      onUnhover();
    }

    // In any case reset the hover state
    state.current = "idle";
  }, [onUnhover, debouncedOnHover]);

  React.useEffect(() => {
    return () => {
      debouncedOnHover.cancel();
    };
  }, [debouncedOnHover]);

  return { onMouseEnter, onMouseLeave };
}

/**
 * Returns the mapping state based on the following priority:
 *   - `hinted` if being hinted
 *   - `highlighted` if being highlighted and there are no other fields being
 *   hinted at. This is so we don't have both highlighted and hinted states
 *   at the same time.
 *
 * Assumes that a mapping cannot be both hinted and highlighted at the
 * same time
 */
// @ts-expect-error refactor
function getExtendedFieldState(selectedFieldIds, hintedFieldIds, source) {
  const isHighlighted = selectedFieldIds.includes(source);
  const isHinted = hintedFieldIds.includes(source);

  if (isHinted) return "hinted";
  if (isHighlighted && hintedFieldIds.length === 0) return "highlighted";
}

// @ts-expect-error refactor
function PageContent({ number, file, renderOverlay }) {
  const [{ scale }] = useDocumentMapper();

  return (
    <div data-testid="pdf-page">
      {renderOverlay && <PdfOverlay />}
      <PdfDocument file={file}>
        <PdfPage scale={scale} pageNumber={number} />
      </PdfDocument>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OutputFieldComponent = React.memo<any>(function OutputFieldComponent(
  props: any
) {
  const { field, roles, extendedState, onHover, onUnhover } = props;

  const { id, coords, dimensions, splitBy } = field;
  const { mappableFieldsById } = useBuilderContext();
  const linkedField = getLinkedField(field, mappableFieldsById);
  const roleIndex = linkedField
    ? // @ts-expect-error refactor
      roles.findIndex((role) => role.id === linkedField.roles[0])
    : -1;
  const roleTheme = getRoleTheme(roleIndex);

  const mouseEventBindings = useDebouncedHoverEvts(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useCallback(() => onHover(field), [field, onHover]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useCallback(() => onUnhover(field), [field, onUnhover])
  );

  return (
    <Field
      id={id}
      x={coords.x}
      y={coords.y}
      page={coords.page}
      width={dimensions.width}
      height={dimensions.height}
      resizableOn={getResizeForType(field.type)}
      lockAspectRatio={field.type === "Checkmark"}
      className={cx(styles.field, styles[`field--${field.type}`])}
      handleClass={styles["resize-handle"]}
      style={{
        fontSize: field.fontSize,
        // @ts-expect-error refactor
        "--base": roleTheme.base,
        // @ts-expect-error refactor
        "--light": splitBy ? "#FCB212" : roleTheme.light,
        // @ts-expect-error refactor
        "--gradient": makeGradient(splitBy ? "#FCB212" : roleTheme.light),
      }}
      // There are a few states that aren't handled by the document mapper
      // automatically. For those states, mark them as 'extended' to not
      // conflict with the automatic `data-state` that gets applied
      data-extended-state={extendedState}
      {...mouseEventBindings}
      data-testid="mapping"
      // If a field has advanced positioning (splitBy value filled)
      // we can't resize it
      resizable={!splitBy}
    >
      <div className={cx(styles.label, styles[`label--${field.type}`])}>
        {/* @ts-expect-error refactor */}
        <Label mapping={field} roleTheme={roleTheme} />
      </div>
    </Field>
  );
});

// @ts-expect-error refactor
function makeGradient(hex) {
  const rgba = hexToRGBA(hex, 0.3);

  if (rgba) {
    return `linear-gradient(0deg, ${rgba}, ${rgba}), #ffffff`;
  }
}

// @ts-expect-error refactor
function getResizeForType(type) {
  switch (type) {
    case "Signature":
    case "Multiline":
      return "all";
    case "Checkmark":
      return "xy";
    case "Text":
      return "x";
    default:
      throw new Error(`Unknown output field type '${type}'`);
  }
}
