import React from "react";
import cx from "classnames";
import { MenuList, MenuItem, MenuItemText, MenuItemGroup } from "hw/ui/menu";
import { Tooltip } from "hw/ui/tooltip";
import theme from "hw/ui/theme";
import IconWrapper, { EditIcon } from "hw/ui/icons";
import type {
  FormMapping,
  MappedField,
  MergeField,
} from "hw/portal/modules/common/draft";
import { filterOptions } from "hw/ui/select/utils";
import styles from "./prefill-menu.module.css";

const NO_MATCH_COPY = "No eligible components match your search";
type Props = {
  formMappings: Array<FormMapping>;
  mergeFields?: Array<MergeField>;
  selectedItem?: MappedField | MergeField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (...args: Array<any>) => any;
  highlightedIndex?: number;
  inputValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal?: (...args: Array<any>) => any;
};

function PrefillMenu(props: Props) {
  const {
    formMappings,
    mergeFields,
    inputValue,
    selectedItem,
    openMergeFieldsModal,
  } = props;
  const isSelected = selectedItem && selectedItem.label === inputValue;
  const isMergeField = React.useMemo(
    () => (mergeFields || []).some((mf) => mf.label === inputValue),
    [mergeFields, inputValue]
  );
  const filteredMappings = isSelected
    ? formMappings
    : formMappings.map((formMapping) => ({
        ...formMapping,
        options: filterOptions(formMapping.options, inputValue),
      }));
  const filteredMergeFields = isSelected
    ? mergeFields
    : mergeFields && filterOptions(mergeFields, inputValue);
  const mappingMatch = filteredMappings.some(
    (mapping) => mapping.options.length > 0
  );
  const displayNoMatch =
    !mappingMatch &&
    !mergeFields &&
    (!filteredMergeFields || filteredMergeFields.length === 0);
  let absoluteIndex = -1;

  function getIndex() {
    absoluteIndex += 1;
    return absoluteIndex;
  }

  return (
    <MenuList
      extend={{
        width: "250px",
        maxHeight: "150px",
        overflow: "auto",
      }}
    >
      {filteredMergeFields && (
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ getIndex: () => number; mergeFields: any[]... Remove this comment to see the full error message
        <MergeFieldMenu
          {...props}
          getIndex={getIndex}
          mergeFields={filteredMergeFields}
          last={!mappingMatch}
          isSelected={isSelected}
          openMergeFieldsModal={openMergeFieldsModal}
          isMergeField={isMergeField}
        />
      )}
      {filteredMappings &&
        filteredMappings.map((formMapping) => (
          /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment
           * suppresses an error found when upgrading Flow to v0.132.0. To view
           * the error, delete this comment and run Flow. */
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ getIndex: () => number; formMapping: any; ... Remove this comment to see the full error message
          <FormMappingMenu
            key={`prefill-menu-group-${formMapping.id}`}
            {...props}
            getIndex={getIndex}
            formMapping={formMapping}
          />
        ))}
      {displayNoMatch && <EmptySearch />}
    </MenuList>
  );
}

PrefillMenu.defaultProps = {
  getItemProps: () => {},
};

function MergeFieldMenu({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'mergeFields' implicitly has an 'a... Remove this comment to see the full error message
  mergeFields,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'inputValue' implicitly has an 'an... Remove this comment to see the full error message
  inputValue,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'last' implicitly has an 'any' typ... Remove this comment to see the full error message
  last,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'getIndex' implicitly has an 'any'... Remove this comment to see the full error message
  getIndex,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'getItemProps' implicitly has an '... Remove this comment to see the full error message
  getItemProps,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'highlightedIndex' implicitly has ... Remove this comment to see the full error message
  highlightedIndex,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'selectedItem' implicitly has an '... Remove this comment to see the full error message
  selectedItem,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'isSelected' implicitly has an 'an... Remove this comment to see the full error message
  isSelected,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'openMergeFieldsModal' implicitly ... Remove this comment to see the full error message
  openMergeFieldsModal,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'isMergeField' implicitly has an '... Remove this comment to see the full error message
  isMergeField,
}) {
  const newMergeFieldOption = {
    label: inputValue,
    dataRef: "new",
  };
  const displayTypePrompt = !inputValue || inputValue === selectedItem?.label;
  const displayCreatePrompt = inputValue && !isSelected && !isMergeField;
  return (
    <MenuItemGroup last={last}>
      {displayTypePrompt && (
        <MenuItem disabled extend={menuItemStyle}>
          <MenuItemText
            extend={{
              color: theme.color.textSelectPrompt,
            }}
          >
            Type to create new merge field...
          </MenuItemText>
        </MenuItem>
      )}
      {/* This is pretty dumb, but I need to specify "&& inputValue" otherwise flow doesn't recognize that
      displayCreatePrompt already checks for it */}
      {displayCreatePrompt && inputValue && (
        <MenuItem
          active={getIndex() === highlightedIndex}
          passthroughProps={getItemProps({
            item: newMergeFieldOption,
          })}
          extend={menuItemStyle}
        >
          <MenuItemText
            extend={{
              color: theme.color.textSelectPrompt,
            }}
          >
            {`Create merge field for "${inputValue}"`}
          </MenuItemText>
        </MenuItem>
      )}
      {mergeFields.length > 0 && (
        <>
          <Label divider={displayTypePrompt || displayCreatePrompt}>
            <span>Merge Fields</span>
            <Tooltip tip="Edit merge fields">
              <IconWrapper
                onClick={openMergeFieldsModal}
                aria-label="Edit merge fields"
              >
                <EditIcon />
              </IconWrapper>
            </Tooltip>
          </Label>
          {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mf' implicitly has an 'any' type. */}
          {mergeFields.map((mf) => (
            <MenuItem
              key={`prefill-menu-item-launch.${mf.dataRef}`}
              active={
                getIndex() === highlightedIndex ||
                (selectedItem === mf && highlightedIndex === null)
              }
              passthroughProps={getItemProps({
                item: mf,
              })}
              extend={menuItemStyle}
            >
              <MenuItemText extend={menuItemTextStyle}>{mf.label}</MenuItemText>
            </MenuItem>
          ))}
        </>
      )}
    </MenuItemGroup>
  );
}

function FormMappingMenu({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'formMapping' implicitly has an 'a... Remove this comment to see the full error message
  formMapping,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'getIndex' implicitly has an 'any'... Remove this comment to see the full error message
  getIndex,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'getItemProps' implicitly has an '... Remove this comment to see the full error message
  getItemProps,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'highlightedIndex' implicitly has ... Remove this comment to see the full error message
  highlightedIndex,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'selectedItem' implicitly has an '... Remove this comment to see the full error message
  selectedItem,
}) {
  if (formMapping.options.length === 0) return null;
  return (
    <>
      <Label>
        <span className={styles.labelText}>{formMapping.label}</span>
      </Label>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type. */}
      {formMapping.options.map((option) => (
        <MenuItem
          key={`prefill-menu-item-${formMapping.id}.${option.dataRef}`}
          active={
            getIndex() === highlightedIndex ||
            (selectedItem === option && highlightedIndex === null)
          }
          passthroughProps={getItemProps({
            item: option,
          })}
          extend={menuItemStyle}
        >
          <MenuItemText extend={menuItemTextStyle}>{option.label}</MenuItemText>
        </MenuItem>
      ))}
    </>
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function Label(props) {
  const { children, divider = false } = props;
  return (
    <div
      className={cx(styles.label, {
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [styles.divider]: divider,
      })}
    >
      {children}
    </div>
  );
}

function EmptySearch() {
  return <div className={styles.emptySearch}>{NO_MATCH_COPY}</div>;
}

// Styles
const menuItemStyle = {
  paddingLeft: theme.space.ms,
};
const menuItemTextStyle = {
  paddingLeft: theme.space.none,
};
export default PrefillMenu;
