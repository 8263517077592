import * as React from "react";
import DOMPurify from "dompurify";

type LinkProps = {
  children: React.ReactNode;
  href: string;
  title?: string;
};

function Link(props: LinkProps) {
  const { href, title } = props;
  const filteredHref = DOMPurify.isValidAttribute("a", "href", href)
    ? href
    : null;

  return (
    <a
      // @ts-expect-error refactor
      href={filteredHref}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
}

export default {
  em: "em",
  strong: "strong",
  link: Link,
  underline: "u",
  strike: "s",

  // These marks are really only useful in the editing phase, but in some cases
  // they can end up in the document so we support rendering them here
  mergeFieldQuery: function MergeFieldQuery({
    children,
  }: {
    children: React.ReactNode;
  }) {
    return <span>{children}</span>;
  },
};
