import type { Field, Form, Role } from "hw/portal/modules/common/draft";
import { FieldConstants } from "hw/common/core/constants";
import { fieldsIncludeRoleAssignment, isExpression } from "../utils";

/**
 * Returns `true` if the field should be displayed within the form builder
 */
export function isFieldVisible(field: Field) {
  return field.type !== FieldConstants.Hidden;
}
export function formRoleOrder(roles: Array<Role>, form: Form) {
  return roles.reduce((ord, role) => {
    if (fieldsIncludeRoleAssignment(form.fields, role)) {
      // @ts-expect-error refactor
      ord.push(role);
    }

    return ord;
  }, []);
}
// These are keys that can contain expression ASTs _and_ may be rendered within
// the builder somewhere.  Currently we don't properly support rendering this
// values when they are dynamic, so we render the fallback as a short-term
// way to keep the builder otherwise usable.
const expressionCompatibleKeys = ["label", "helpText"];

/**
 * Finds the key in a field that contains a value that cannot be rendered
 * if it exists.  We use this to determine if the preview and/or settings panel
 * for a field can be display or if the fallback should be displayed.
 */
export function findUnrenderableProperty(field: Field) {
  // @ts-expect-error refactor
  return expressionCompatibleKeys.find((key) => isExpression(field[key]));
}

/**
 * Returns true if the field can be edited within the settings panel.
 */
export function isFieldEditable(field: Field) {
  return !findUnrenderableProperty(field);
}
