import * as React from "react";
import { FieldSet } from "hw/portal/modules/draft-editor-v2/build/field-settings";

type Props = {
  children: React.ReactNode;
  label: React.ReactNode;
  id: string;
};

/**
 * Outer Wrapper for each settings module
 *
 * @example
 * function MySettingsModule() {
 *   return <SettingsWrapper>
 *     <Container />
 *   </SettingsWrapper>
 * }
 */
const SettingsWrapper = (props: Props) => {
  const { label } = props;
  const dataAutomationId =
    typeof label === "string"
      ? `settings-wrapper-${label.replace(/\s/g, "")}`
      : "";
  return (
    <FieldSet
      {...props}
      // @ts-expect-error refactor
      borderBottomStyle="solid"
      borderColor="dividerDim"
      borderWidth={1}
      data-automation-id={dataAutomationId}
    />
  );
};

export default SettingsWrapper;
