import { combineRules } from "hw/ui/style";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'disabled' implicitly has an 'any'... Remove this comment to see the full error message
const baseRule = ({ disabled, theme }) => ({
  padding: "12px 20px",
  backgroundColor: theme.color.white,
  borderColor: theme.color.gray500,
  borderRadius: theme.corner.sm,
  borderStyle: "solid",
  borderWidth: "2px",
  color: theme.color.textStandard,
  cursor: disabled ? "not-allowed" : "pointer",
  fontFamily: "inherit",
  fontSize: theme.fontSize.ms,
  fontWeight: theme.fontWeight.semibold,
  minHeight: "42px",
  letterSpacing: "0.05em",
  userSelect: "none",
  width: "100%",
  height: "100%",
  overflowWrap: "break-word",
  ":focus": {
    outline: "none",
    boxShadow: "0 0 0 2px " + theme.color.uiFocus,
  },
  lineHeight: 1,
});

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
const activeRule = ({ theme, active, disabled }) => {
  // The "primaryColor" in the runtime SF application is the white label
  // "primaryColor" and has historically been set at runtime on our global
  // UI theme. This method of adjusting the theme is not used anywhere else
  // and is not recommended. For legacy purposes, this is left as is until
  // we can design a better way to consume this value in UI components.
  const primaryColor = theme.color.unsafe_UiToggle || theme.color.uiActionBase;

  return (
    active && {
      borderColor: disabled ? theme.color.gray500 : primaryColor,
      position: "relative",
      // The background color needs to be supplied color at 0.1 alpha, so in
      // order to handle any color type and not just hex values, we use a
      // pseudo element to apply the styles instead of `backgroundColor`.
      ":after": {
        content: "''",
        position: "absolute",
        opacity: disabled ? 1 : 0.1,
        backgroundColor: disabled ? theme.color.uiActionDisabled : primaryColor,
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
      },
    }
  );
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
const disabledRule = ({ theme, disabled }) =>
  disabled && {
    backgroundColor: theme.color.uiActionDisabled,
    color: theme.color.textLighter,
    borderColor: theme.color.gray200,
  };

export default {
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  root: combineRules(baseRule, disabledRule, activeRule),
};
