import React from "react";
import cx from "classnames";
import "./text-input.css";
import Label from "./common/label";
import ErrorMessage from "./common/error-message";

type Props = {
  classNames?: string;
  errorMessage?: string;
  onChange?: (newValue: string) => void;
  handleVisited?: (e: React.SyntheticEvent) => void;
  hidden?: boolean;
  id?: string;
  isValid?: boolean;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  theme?: {
    classNames?: {
      inputBorderFocus?: string;
    };
  };
  value?: string;
  defaultValue?: string; // Used by Draft 2 in place of value
  helpText?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  labelProps?: {};
  htmlType?: string;
};

export type { Props as TextInputProps };

export default function TextInput(props: Props) {
  const {
    classNames,
    label,
    required,
    theme,
    id,
    defaultValue,
    value,
    placeholder,
    onChange,
    handleVisited,
    readOnly,
    errorMessage,
    hidden,
    helpText,
    isValid = true,
    labelProps,
    htmlType = "text",
  } = props;

  const classNameInput = cx(
    "m-sf-text-input",
    theme?.classNames?.inputBorderFocus
  );

  const textInputProps = {
    type: htmlType,
    id,
    className: classNameInput,
    autoComplete: "off",
    defaultValue,
    value,
    placeholder: readOnly ? undefined : placeholder,
    onChange: onChange
      ? (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)
      : null,
    onBlur: handleVisited,
    disabled: readOnly,
    "data-qa-ref": "text-input",
  };

  const error = !isValid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

  const classes = {
    "m-sf-text-input-field": true,
    invalid: !isValid,
    readonly: readOnly,
  };

  const labelComp = label && (
    <Label required={required} helpText={helpText} htmlFor={id} {...labelProps}>
      {label}
    </Label>
  );

  return (
    <div
      className={cx(classes, classNames)}
      hidden={hidden}
      role="presentation"
    >
      {labelComp}
      {/* @ts-expect-error refactor */}
      <input {...textInputProps} />
      {error}
    </div>
  );
}
