import * as React from "react";
import { Flex, Text, Box } from "hw/ui/blocks";
import Toggle from "hw/ui/toggle";
import Wrapper from "./wrapper";
import { genId } from "../../utils";

type Props = {
  /**
   * The name of the setting
   */
  name: string;

  /**
   * The `checked` state of the toggle
   */
  checked: boolean;

  /**
   * Additional optional copy to display below the label
   */
  copy?: string;

  /**
   * The `onChange` handler for the toggle
   */
  onChange: (newCheckedValue: boolean) => void;

  /**
   * The `disabled` state of the toggle
   */
  disabled: boolean;

  /**
   * If true, margin bottom will be applied
   */
  spacing?: boolean;
};

/**
 * Helper component for display a settting that is toggled on and off.
 */
export function ToggledSetting(props: Props) {
  const { name, checked, onChange, disabled, copy, spacing = false } = props;
  const { current: id } = React.useRef(genId());
  return (
    <Box mb={spacing ? "ms" : "none"}>
      <Wrapper label={name} id={id}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; justifySelf: string; }'... Remove this comment to see the full error message */}
        <Flex justifySelf="end">
          <Toggle
            on={checked}
            onClick={onChange}
            disabled={disabled}
            id={id}
            label={name}
          />
        </Flex>
      </Wrapper>
      {copy && (
        <Text fontSize="xs" mt="xs">
          {copy}
        </Text>
      )}
    </Box>
  );
}
export default React.memo(ToggledSetting);
