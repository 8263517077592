import * as React from "react";
import { withStyle } from "hw/ui/style";
import style from "./style";

type Props = {
  /**
   * Sets the `disabled` state of the button
   */
  disabled?: boolean;

  /**
   * The contents of the button
   */
  children: React.ReactNode;

  /**
   * Called when the button is clicked
   */
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
};
type StyleProps = {
  styles: {
    root: string;
  };
};

/**
 * @one-off Button
 *
 * NOTE: This is a potentially temporary UI component that's a recreation of the
 * smartforms `Toggle` component. It's separated out as a distinct UI component
 * because the existing `Toggle` was difficult to extract as a standalone
 * component. It's possible that this component will eventually get categorized
 * as part of a generic "button" class of components.
 */
export function ToggleButton(props: StyleProps & Props) {
  const { disabled, children, styles, onClick } = props;

  return (
    <button disabled={disabled} onClick={onClick} className={styles.root}>
      {children}
    </button>
  );
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ root: TRule<{ disabled: any; t... Remove this comment to see the full error message
export default withStyle(style)(ToggleButton);
