/**
 * This describes the case where a user overlaps one field with another, but
 * in a way that we think is unintentional. Meaning, they probably don't
 * want things to overlap so we can attempt to 'nudge' into place.
 * The `minThreshold` and `maxThreshold` describe the range (inclusive)
 * where this nudging kicks in.
 */
export const AccidentalCollision = {
  minThreshold: 0,
  maxThreshold: 7,
};

/**
 * The number of pixels to check before initiating a drag or a resize
 * Helps avoid 'accidental' drags where a `mousemove` then `mouseup` occurs
 * very quickly
 */
export const SloppyClickThreshold = 2;
