/* eslint-disable @typescript-eslint/no-explicit-any */
import { setIn } from "timm";
import generate from "nanoid/generate";
import type { PdfMapping } from "hw/portal/modules/common/draft";
import { MultipleChoice } from "./factories";

/**
 * Ensures that the `rawValue` is applied based on the current coordinates
 */
export function withRawValue(mapping: PdfMapping): PdfMapping {
  const { coords } = mapping;
  const { x, y, page } = coords;
  const rawValue = `coords %{x: ${x}, y: ${y}, page: ${page}}`;

  // @ts-expect-error refactor
  return setIn(mapping, ["coords", "rawValue"], rawValue);
}

export const id = () =>
  generate("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 6);

/**
 * Duplicates a mapping by creating a new mapping and copying over any
 * additional provided props
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function duplicate(mapping: PdfMapping, attrs: Partial<PdfMapping>) {
  return {
    ...mapping,
    ...attrs,
    id: id(),

    // Labels have to be unique for the backend
    label: `Mapping_${id()}`,
  };
}

/**
 * Returns `true` if the mapping is linked to the provided field. In every
 * case, the mapping must have a `source` property pointing to the field
 * id. Certain field types may have specific addiional requirements.
 */
export function isLinkedTo(mapping: PdfMapping, field: any) {
  switch (field.type) {
    case "MultipleChoice":
      return MultipleChoice.isLinkedTo(mapping, field);
    default:
      return isSourcedBy(mapping, field);
  }
}

/**
 * Returns `true` if the mapping has the correct `source` property for the
 * field
 */
export function isSourcedBy(mapping: PdfMapping, field: any) {
  return mapping.source === field.id;
}

/**
 * Updates a mapping within the list of mappings only if it passes the
 * predicate
 */
export function updateWhere(
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  mappings: Array<PdfMapping> = [],
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  predicate: (mapping: PdfMapping) => boolean,
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  updater: (mapping: PdfMapping) => PdfMapping
) {
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  return mappings.map<PdfMapping>((mapping) => {
    if (predicate(mapping)) {
      return updater(mapping);
    } else {
      return mapping;
    }
  });
}
