import * as React from "react";
import { Flex } from "hw/ui/blocks";
import { Wrapper } from "./basics";

type Props = {
  children: Array<React.ReactNode>;
  label: React.ReactNode;
  stacked?: boolean;
};
export default function ButtonGroup(props: Props) {
  const { children, label, stacked } = props;
  const modifiedChildren = React.Children.map(children, (child, index) => {
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    const { extend } = child.props;
    const newExtend = { ...extend };

    // Adjust the borders so the buttons look like they belong to one pill.
    // This will override any individual button extend border radius value.
    if (index !== 0) {
      newExtend.borderTopLeftRadius = 0;
      newExtend.borderBottomLeftRadius = 0;
    }

    if (index !== children.length - 1 && children.length > 1) {
      newExtend.borderTopRightRadius = 0;
      newExtend.borderBottomRightRadius = 0;
    }

    newExtend.width =
      (extend && extend.width) || `${(1 / children.length) * 100}%`;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return React.cloneElement(child, {
      extend: newExtend,
    });
  });
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; label: ReactNode; id: s... Remove this comment to see the full error message
    <Wrapper label={label} id="field-button-group" stacked={stacked}>
      <Flex>{modifiedChildren}</Flex>
    </Wrapper>
  );
}
