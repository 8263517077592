import * as React from "react";
import { Flex } from "hw/ui/blocks";
import type { EinField } from "hw/portal/modules/common/draft";
import { HelpText, Label, Required } from "./common";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: EinField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
};
export function EinSettings(props: Props) {
  const { field, path, editorDispatch, defaultSettings, referenceSetting } =
    props;
  const { label, required, helpText, type } = field;
  return (
    <Flex flexDirection="column">
      {defaultSettings}
      <Label
        path={path}
        editorDispatch={editorDispatch}
        label={label}
        fieldType={type}
        placeholder="EIN"
      />
      <Required
        path={path}
        editorDispatch={editorDispatch}
        required={required}
        fieldType={type}
      />
      <HelpText
        path={path}
        editorDispatch={editorDispatch}
        helpText={helpText}
      />
      {referenceSetting}
    </Flex>
  );
}
export default EinSettings;
