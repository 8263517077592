/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "hw/ui/blocks";
import { Heading3 } from "hw/ui/text";
import { GraphQLWrapper as ConnectedShareableLink } from "hw/portal/modules/common/shareable-links";

type Props = {
  url?: string;
  isDisabled?: boolean;
  workflow: {
    mergeFields: Array<any>;
    guid: string;
    name: string;
    roles: Array<{ id: string; label: string }>;
  };
  addSuccess: Function;
  addDanger: Function;
};

export default function ShareableLink(props: Props) {
  const { workflow, addSuccess, addDanger } = props;

  return (
    <>
      <Box mb="md">
        {/* @ts-expect-error refactor */}
        <Heading3>
          <FormattedMessage
            id="launchWorkflow.shareableSubHeader"
            defaultMessage="Provide access with a shareable link"
          />
        </Heading3>
      </Box>
      <ConnectedShareableLink
        // @ts-expect-error refactor
        workflow={workflow}
        saving={false}
        addSuccess={addSuccess}
        addDanger={addDanger}
      />
    </>
  );
}
