import type { ApolloError } from "@apollo/client";
import {
  msgForCode,
  retrieveErrorCode,
} from "hw/portal/modules/common/components/errors";

export function launchError(workflowName: string, error?: ApolloError) {
  const defaultError = `Unexpected error trying to launch ${workflowName}`;
  // @ts-expect-error refactor
  const errorCode = error ? retrieveErrorCode(error) : null;

  if (!errorCode) return defaultError;

  return msgForCode(errorCode) ?? defaultError;
}

export function launchSuccess(workflowName: string) {
  return `${workflowName} was successfully launched`;
}

/**
 * Returns a flash message based on the creation result from the server
 */
export function launchResult(
  workflowName: string,
  createSucceeded: boolean,
  errors?: ApolloError
) {
  switch (createSucceeded) {
    case true:
      return launchSuccess(workflowName);
    default:
      return launchError(workflowName, errors);
  }
}
