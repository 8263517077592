import * as React from "react";
import AddIcon from "hw/ui/icons/svg-icons/add-icon-16";
import FileUpload from "hw/portal/modules/common/components/file-upload";
import styles from "./upload.module.css";

type Props = {
  onChange: (file: File) => void;
};

export default function Upload(props: Props) {
  const { onChange } = props;

  return (
    <FileUpload
      processFile={onChange}
      className={styles.dropZone}
      accept=".csv"
      dataTrackId="bulk-send-upload-participant-list"
    >
      <span className={styles.addIcon}>
        <AddIcon />
      </span>
      <span className={styles.addText}>Upload participant list</span>
    </FileUpload>
  );
}
