/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { SuccessIcon, WarningIcon, DangerIcon } from "hw/ui/icons";
import Message from "./message";
import styles from "./toast.module.css";

export type ContextProps = {
  messages: Array<{
    message: string;
    id: number;
    dismissible: boolean;
    timeOut: number;
  }>;
  addMessage: (
    msg: React.ReactNode,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
  removeMessage: (...args: Array<any>) => any;
  addSuccess: (
    msg: string | React.ReactElement<any>,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
  addWarning: (
    msg: string | React.ReactElement<any>,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
  addDanger: (
    msg: string | React.ReactElement<any>,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
};

export const Context = React.createContext<ContextProps>({
  messages: [],
  addMessage: () => {},
  removeMessage: () => {},
  addSuccess: () => {},
  addWarning: () => {},
  addDanger: () => {},
});

export function Provider(props: any) {
  const [messages, setMessages] = React.useState([]);

  const removeMessage = React.useCallback(
    (id) =>
      setMessages((state) => {
        // @ts-expect-error ts-migrate(2550) FIXME: Property 'findIndex' does not exist on type 'never... Remove this comment to see the full error message
        const index = state.findIndex((messageObj) => messageObj.id === id);
        return index > -1
          ? [...state.slice(0, index), ...state.slice(index + 1)]
          : state;
      }),
    []
  );

  const addMessage = React.useCallback((message, dismissible, timeOut) => {
    const id = Date.now();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(state: never[]) => { message: a... Remove this comment to see the full error message
    setMessages((state) => [...state, { message, id, dismissible, timeOut }]);
    return id;
  }, []);

  const addSuccess = React.useCallback(
    (message, dismissible, timeOut) =>
      addMessage(
        <div className={styles.iconWrapper}>
          <SuccessIcon />
          {message}
        </div>,
        dismissible,
        timeOut
      ),
    [addMessage]
  );
  const addWarning = React.useCallback(
    (message, dismissible, timeOut) =>
      addMessage(
        <div className={styles.iconWrapper}>
          <WarningIcon />
          {message}
        </div>,
        dismissible,
        timeOut
      ),
    [addMessage]
  );
  const addDanger = React.useCallback(
    (message, dismissible, timeOut) =>
      addMessage(
        <div className={styles.iconWrapper}>
          <DangerIcon />
          {message}
        </div>,
        dismissible,
        timeOut
      ),
    [addMessage]
  );

  const contextValue = {
    messages,
    addMessage,
    removeMessage,
    addSuccess,
    addWarning,
    addDanger,
  };

  return <Context.Provider value={contextValue} {...props} />;
}

export const Consumer = Context.Consumer;

export default function Toast() {
  const { messages, removeMessage } = React.useContext(Context);

  return (
    <div className={styles.container}>
      {messages.map((messageObj) => {
        const removeMyMessage = () => removeMessage(messageObj.id);

        return (
          <Message
            key={messageObj.id}
            message={messageObj.message}
            removeMessage={removeMyMessage}
            dismissible={messageObj.dismissible}
            timeOut={messageObj.timeOut}
          />
        );
      })}
    </div>
  );
}
