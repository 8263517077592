import * as React from "react";

import sanitize from "./sanitize";
import interpolateString from "./interpolate-string";
import type { SanitizeOptions } from "./sanitize";

type Props = {
  html: string;
  component: React.ElementType;
  data?: Record<string, string>;
  sanitizeOptions: SanitizeOptions;
};

/**
 * Renders a string of HTML
 *
 * The given html string is sanitized before rendered to the dom.  Currently the
 * only way to render an HTML string with React is to wrap it in a containing
 * component, so you the `component` property can be passed to customized the
 * wrapping element type.
 *
 * NOTE: This component should only be used in very controlled scenarios
 * because it renders raw HTML and is vulnerable to XSS.
 *
 * @example
 * <HtmlRenderer html="<div>some html</div>" />
 */
function HtmlRenderer(props: Props) {
  const { html, component = "div", data, sanitizeOptions, ...rest } = props;
  const interpolatedString = interpolateString(html, data);
  const sanitizedHtml = sanitize(interpolatedString, sanitizeOptions);

  /* $FlowFixMe[incompatible-call] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  return React.createElement(component, {
    ...rest,
    dangerouslySetInnerHTML: {
      __html: sanitizedHtml,
    },
  });
}

export default React.memo(HtmlRenderer);
