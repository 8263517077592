import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ACTION_TYPE } from "hw/ui/modal";
import type { Field, ParsedSchema } from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import { ConfirmFieldReferenceRemovalModal } from "./modal-confirm-field-reference-removal";

type Props = {
  schema: ParsedSchema;

  /**
   * Called when the user confirms that they want to delete a field
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (...args: Array<any>) => any;

  /**
   * Closes the modal
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel: (...args: Array<any>) => any;

  /**
   * The field to delete
   */
  field: Field;

  /**
   * ID of the form containing the field
   */
  formId: string;
  path?: Path;
};
export default function DeleteFieldModal(props: Props) {
  const { field, onCancel, onConfirm, schema, formId } = props;
  return (
    <ConfirmFieldReferenceRemovalModal
      layer="hoverCard"
      field={field}
      formId={formId}
      schema={schema}
      heading={<Heading />}
      primaryAction={{
        text: <PrimaryActionText />,
        onClick: onConfirm,
        type: ACTION_TYPE.DANGER,
      }}
      onCancel={onCancel}
    >
      Deleting it will remove those existing references.
    </ConfirmFieldReferenceRemovalModal>
  );
}

function PrimaryActionText() {
  return (
    <FormattedMessage
      defaultMessage="Delete"
      id="deleteFieldModal.primaryAction"
    />
  );
}

function Heading() {
  // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'formattedMessage' implicitly has a... Remove this comment to see the full error message
  const headerTestEl = (...formattedMessage) => {
    return React.createElement(
      "span",
      {
        "data-testid": "header",
      },
      formattedMessage
    );
  };

  return (
    <FormattedMessage
      id="deleteFieldModal.header"
      defaultMessage="Delete component?"
    >
      {headerTestEl}
    </FormattedMessage>
  );
}
