/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This is context that should be useful to both the form-builder and the pdf-mapper
 * at very nested levels
 */
import * as React from "react";
import type { Path } from "hw/common/types";
import { JobStatus } from "./constants";

type Job = {
  id: string;
  status: typeof JobStatus[keyof typeof JobStatus];
};

type ContextType = {
  mappableFieldsById: Map<string, unknown>;
  fieldPathsById: Map<string, Path>;
  job?: Job;
  setJob: React.Dispatch<React.SetStateAction<undefined>>;
};

const Context = React.createContext<ContextType>({
  mappableFieldsById: new Map(),
  fieldPathsById: new Map(),
  job: undefined,
  setJob: () => {},
});

export function Provider(props: any) {
  const { fields, children, formPath } = props;
  const [job, setJob] = React.useState(undefined);

  const value = React.useMemo(() => {
    return {
      mappableFieldsById: getMappableFieldsById(fields),
      fieldPathsById: getFieldPathsById(fields, formPath),
      job,
      setJob,
    };
  }, [fields, formPath, job]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useBuilderContext() {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(`useBuilderContext must be wrapped in the Provider`);
  }

  return context;
}

// For lack of a better term, these are all of the fields that are "mappable"
// which means they are fields that have data and are treated as a distinct
// group in the pdf mapper. For example:
//
//   - `Group` components themselves are skipped because they have no data, but
//     their children are included
//   - `AddressGroup` components are included as a single field. Their children
//     are not included separately because the pdf mapper handles the child
//     field mappings
function getMappableFieldsById(fields: any, map = new Map()) {
  return fields.reduce((map: any, field: any) => {
    if (field.type === "Group") {
      getMappableFieldsById(field.children, map);
    } else if (field.type !== "Paragraph") {
      map.set(field.id, field);
    }

    return map;
  }, map);
}

function getFieldPathsById(
  fields: any,
  formPath: string[],
  map: Map<any, any> = new Map(),
  fieldIdx?: number
) {
  return fields.reduce((map: Map<any, any>, field: any, idx: number) => {
    const isChild = typeof fieldIdx === "number";
    const fieldPath = ["fields", fieldIdx ?? idx];
    const childPath = isChild ? ["children", idx] : [];
    const fullPath = [...formPath, ...fieldPath, ...childPath];

    if (field.type === "Group") {
      getFieldPathsById(field.children, [...formPath], map, idx);
    } else if (field.type !== "Paragraph") {
      map.set(field.id, fullPath);
    }

    return map;
  }, map);
}
