/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Stack } from "hw/ui/layout";
import { FormattedMessage } from "react-intl";
import { Text } from "hw/ui/blocks";
import { Heading2, TextBody1 } from "hw/ui/text";
import Button from "hw/ui/button";
import { Divider } from "hw/portal/modules/launch-workflow";

type Props = {
  multipleParticipants: boolean;
  onResend: (...args: Array<any>) => any;
  resending: boolean;
};

export default function Submitted(props: Props) {
  const { multipleParticipants } = props;

  return multipleParticipants ? (
    <MultiParticipant />
  ) : (
    <SingleParticipant {...props} />
  );
}

function MultiParticipant() {
  return (
    <Stack space="sm">
      <Heading2>Email sent</Heading2>
      <TextBody1>
        A link has been sent to the first participant to begin this workflow.
      </TextBody1>
    </Stack>
  );
}

function SingleParticipant(props: Props) {
  const { onResend, resending } = props;

  return (
    <Stack space="lg">
      <Stack space="sm">
        <Heading2>Check your inbox</Heading2>
        <TextBody1>
          A unique link has been sent to you. Check your email for the link to
          fill out this form.
        </TextBody1>
      </Stack>

      <Stack space="md">
        <Divider />
        <Text color="textDim" fontSize="ms">
          <FormattedMessage
            id="shareable-links.submitted-help"
            defaultMessage="Don't see it in your inbox? Check your email filters or "
          />
          <Button
            presentation="link"
            onClick={onResend}
            extend={ExtendBtn}
            disabled={resending}
          >
            {resending ? (
              <FormattedMessage
                id="shareable-links.submitted-resend-sending"
                defaultMessage="sending..."
              />
            ) : (
              <FormattedMessage
                id="shareable-links.submitted-resend"
                defaultMessage="click here to resend"
              />
            )}
          </Button>
          .
        </Text>
      </Stack>
    </Stack>
  );
}

// TODO: Should all link buttons have this style?
function ExtendBtn({ theme }: any) {
  return {
    padding: 0,
    ":disabled": {
      backgroundColor: "inherit",
    },
    ":active": {
      color: theme.color.blue500,
      ":focus": {
        boxShadow: "none",
      },
    },
    ":hover": {
      color: theme.color.blue500,
      textDecoration: "underline",
    },
  };
}
