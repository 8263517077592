import * as React from "react";
import { getIn, removeAt, update } from "timm";
import { Flex } from "hw/ui/blocks";
import OrderableList, { reorder } from "hw/ui/orderable-list";
import ButtonGroup from "hw/portal/modules/common/components/button-group";
import type { MultipleChoiceField } from "hw/portal/modules/common/draft";
import { Input } from "hw/ui/input";
import { filterNonUTF83BCharacters } from "hw/portal/modules/draft-editor/utils";
import { Label, Required, HelpText } from "./common";
import { Wrapper, Toggled } from "./common/basics";
import { Actions } from "../../state";
import { useUndoContext } from "../../../undo-context";

const PRESENTATION_OPTIONS = ["buttons", "dropdown", "list"];
const Options = React.memo(function Options(props) {
  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type '{ child... Remove this comment to see the full error message
    options,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type '{ chil... Remove this comment to see the full error message
    onChange,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onAdd' does not exist on type '{ childre... Remove this comment to see the full error message
    onAdd,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onMove' does not exist on type '{ childr... Remove this comment to see the full error message
    onMove,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onRemove' does not exist on type '{ chil... Remove this comment to see the full error message
    onRemove,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastAdded' does not exist on type '{ chi... Remove this comment to see the full error message
    lastAdded,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'minimumOptionCount' does not exist on ty... Remove this comment to see the full error message
    minimumOptionCount,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'field' does not exist on type '{ childre... Remove this comment to see the full error message
    field,
  } = props;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'localIndex' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleChange(localIndex, evt) {
    const { value } = evt.target;
    const filteredValue = filterNonUTF83BCharacters(value);
    onChange(localIndex, filteredValue);
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'opt' implicitly has an 'any' type.
  const wrappedValues = options.map((opt) => ({
    ...opt,
    value: {
      value: opt.value,
      disabled: options.length <= minimumOptionCount,
      disabledMessage: `At least ${minimumOptionCount} option${
        minimumOptionCount > 1 ? "s" : ""
      } ${minimumOptionCount > 1 ? "are" : "is"} required`,
    },
  }));
  return (
    <Wrapper
      data-testid="multiple-choice-options"
      label="Options"
      id={`${field.id}-options`}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; "data-testid": string; ... Remove this comment to see the full error message
      extend={{
        "> label": {
          alignSelf: "baseline",
        },
      }}
    >
      <OrderableList
        addText="Add Option"
        items={wrappedValues}
        getItemKey={(option) => option.key}
        onAdd={onAdd}
        onMove={onMove}
        onRemove={onRemove}
        renderItem={(option, localIndex) => (
          <Input
            type="text"
            value={option.value.value}
            onChange={(evt) => handleChange(localIndex, evt)}
            placeholder="Add option value"
            data-testid="option-input"
            autoFocus={localIndex === lastAdded}
            onFocus={(e) => e.currentTarget.select()}
          />
        )}
      />
    </Wrapper>
  );
});
const AllowMultiple = React.memo(function AllowMultiple(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'presentation' does not exist on type '{ ... Remove this comment to see the full error message
  const { presentation, onChange, allowMultiple } = props;
  return (
    <Toggled
      name="Allow Multiple Responses"
      checked={presentation !== "dropdown" && allowMultiple}
      disabled={presentation === "dropdown"}
      onChange={onChange}
    />
  );
});
const Presentation = React.memo(function Presentation(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'presentation' does not exist on type '{ ... Remove this comment to see the full error message
  const { presentation, onChange } = props;
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type '{ children: Elem... Remove this comment to see the full error message
    <Wrapper label="Presentation">
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; justifySelf: string; }'... Remove this comment to see the full error message */}
      <Flex justifySelf="end">
        <ButtonGroup
          value={presentation}
          options={PRESENTATION_OPTIONS}
          /* $FlowFixMe[incompatible-type] $FlowFixMe This comment suppresses
           * an error found when upgrading Flow to v0.132.0. To view the error,
           * delete this comment and run Flow. */
          onChange={onChange}
        />
      </Flex>
    </Wrapper>
  );
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class MultipleChoiceSettings extends React.Component<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unsubscribe: (...args: Array<any>) => any = () => {};

  optionKeyCount = 0;

  /**
   * Prepares and option with a stable `key`
   *
   * @param option - The option string value
   * @return - An object with a `key`/`value` pair
   */
  prepareOptionWithKey = (option: string) => {
    return {
      key: this.optionKeyCount++,
      value: option,
    };
  };

  componentDidMount() {
    this.unsubscribe = this.props.onUndo(() => {
      this.setState({
        options: this.initOptions(this.props.field),
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initOptions = (field: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    return this.prepareOptionsWithKeys(getIn(field, ["options"]));
  };

  /**
   * The option values are freely moved around and added to/removed from so
   * it's important that they have a stable `key` value.  Unfortunately our
   * schema value for options is just a list of strings, so the only natural
   * key value would be the `index`, which will not work in our case.  The
   * only option I can think of is to keep the options in state and add a
   * custom `key` value to them.  We make edits to the local state and then
   * "sync" the string values back to the parent.
   */
  prepareOptionsWithKeys = (
    options: Array<string> = []
  ): Array<{
    key: number;
    value: string;
  }> => {
    return options.map((option) => this.prepareOptionWithKey(option));
  };

  state = {
    options: this.initOptions(this.props.field),
    lastAdded: -1,
  };

  /**
   * Add a new option to the list of options
   */
  handleAddOption = () => {
    this.setState(
      {
        options: [
          ...this.state.options,
          this.prepareOptionWithKey(`Option ${this.optionKeyCount + 1}`),
        ],
        lastAdded: this.state.options.length,
      },
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(cb: (...args: any[]) => any, ..... Remove this comment to see the full error message
      this.syncOptions
    );
  };

  handleAllowMultipleChange = (value: boolean) => {
    const { path, editorDispatch, field } = this.props;
    const updatedField = update(field, "allowMultiple", () => value);
    editorDispatch(
      Actions.ChangeFieldSetting({
        path,
        updater: () => updatedField,
      })
    );

    if (typeof this.props.onChangeAllowMultiple === "function") {
      this.props.onChangeAllowMultiple(updatedField);
    }
  };

  /**
   * Finds the option at the given `localIndex` and updates the value
   *
   * @param localIndex - The local index of the option in the current array.
   */
  handleChangeOption = (localIndex: number, value: string) => {
    const { options } = this.state;
    this.setState(
      {
        options: update(options, localIndex, (option) => ({
          ...option,
          value,
        })),
      },
      () => this.syncOptions(this.props.onChangeOption, localIndex)
    );
  };

  /**
   * Reorders the options given the `fromIndex` and `toIndex` values
   *
   * @param options - The move options provided by `OrderableList`
   * @param options.fromIndex - The original local index of the item before it
   * was move
   * @param options.toIndex - The new local index of the item when after it
   * was dropped
   */
  handleMoveOption = ({
    fromIndex,
    toIndex,
  }: {
    fromIndex: number;
    toIndex: number;
  }) => {
    const newOptions = reorder(this.state.options, fromIndex, toIndex);
    // Use the previous keys and the reordered keys to determine what the
    // new index order is for the options. We need this for the new edittor
    const oldKeys = this.state.options.map((opt) => opt.key);
    const newKeys = newOptions.map((opt) => opt.key);
    const newIdxOrder = oldKeys.map((oldKey) => newKeys.indexOf(oldKey));
    this.setState(
      {
        options: newOptions,
      },
      () => this.syncOptions(this.props.onReorderOptions, newIdxOrder)
    );
  };

  handlePresentationChange = (value: MultipleChoiceField["presentation"]) => {
    const { path, editorDispatch } = this.props;
    return editorDispatch(
      Actions.ChangeFieldSetting({
        path,
        updater: (prevConfig) => ({
          ...prevConfig,
          presentation: value,
          allowMultiple:
            value === "dropdown" ? false : prevConfig.allowMultiple,
        }),
      })
    );
  };

  /**
   * Removes an option at the specfied `localIndex`
   *
   * @param localIndex - The local index of the option in the current array
   */
  handleRemoveOption = (localIndex: number) => {
    const { options } = this.state;
    this.setState(
      {
        options: removeAt(options, localIndex),
      },
      () => this.syncOptions(this.props.onRemoveOption, localIndex)
    );
  };

  /**
   * Syncs the options in the state with their string values to the parent
   * component
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncOptions(cb: (...args: Array<any>) => any, ...args: any) {
    const { editorDispatch, field } = this.props;
    const optionVals = this.state.options.map((opt) => opt.value);
    const updatedField = update(field, "options", () => optionVals);
    editorDispatch(
      Actions.ChangeFieldSetting({
        path: this.props.path,
        updater: () => updatedField,
      })
    );

    /**
     * If there's a callback provided for this specific action, call it
     * with the updated field and any specific arguments provided. We need
     * this for the new editor
     */
    if (typeof cb === "function") {
      cb(updatedField, ...args);
    }
  }

  render() {
    const {
      defaultSettings,
      field,
      path,
      referenceSetting,
      editorDispatch,
      // The minimum amount of options required.
      minimumOptionCount = 0,
    } = this.props;
    const { allowMultiple, label, required, presentation, helpText, type } =
      field;
    return (
      <Flex flexDirection="column">
        {defaultSettings}
        <Label
          label={label}
          editorDispatch={editorDispatch}
          path={path}
          fieldType={type}
          placeholder="Choose one option, Select all that apply, ..."
        />
        <Options
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ options: { key: number; value: string; }[]... Remove this comment to see the full error message
          options={this.state.options}
          onChange={this.handleChangeOption}
          onMove={this.handleMoveOption}
          onAdd={this.handleAddOption}
          onRemove={this.handleRemoveOption}
          lastAdded={this.state.lastAdded}
          minimumOptionCount={minimumOptionCount}
          field={field}
        />
        <Required
          required={required}
          editorDispatch={editorDispatch}
          path={path}
          fieldType={type}
        />
        <HelpText
          helpText={helpText}
          editorDispatch={editorDispatch}
          path={path}
        />
        <AllowMultiple
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; presentation: any; onChange:... Remove this comment to see the full error message
          name="Allow Multiple"
          presentation={presentation}
          onChange={this.handleAllowMultipleChange}
          allowMultiple={allowMultiple}
        />
        <Presentation
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ presentation: any; onChange: (value: any) ... Remove this comment to see the full error message
          presentation={presentation}
          onChange={this.handlePresentationChange}
        />
        {referenceSetting}
      </Flex>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function MultipleChoiceSettingsWithContext(props: any) {
  const onUndo = useUndoContext();
  return <MultipleChoiceSettings {...props} onUndo={onUndo} />;
}
