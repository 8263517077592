import * as React from "react";
import { withStyle, combineRules } from "hw/ui/style";
import { CaretDownIcon } from "hw/ui/icons";
import sharedStyle from "./style";

type Props = {
  /**
   * Disables the select control
   */
  disabled?: boolean;

  /**
   * The placeholder to display when nothing is selected.  In this implementation
   *  it's render as an `option` element with no value
   */
  placeholder: string;

  /**
   * An array of `option` elements
   */
  children: React.ReactNode;

  /**
   * Called with the native select change event
   */
  onChange: (e: React.SyntheticEvent<HTMLSelectElement>) => void;

  /**
   * The current value of the select element
   */
  selectedItem?: string;
  id?: string;
  "data-qa-ref"?: string;
};

type StyleProps = {
  styles: {
    select: string;
    root: string;
    content: string;
    text: string;
    icon: string;
  };
};

const ensureScalar = (value: string | number | undefined) =>
  typeof value === "string" || typeof value === "number" ? value : undefined;

export function SelectNative(props: StyleProps & Props) {
  const {
    disabled,
    children,
    onChange,
    placeholder,
    styles,
    selectedItem,
    id,
    "data-qa-ref": qaRef,
  } = props;
  // React will complain if we provide an array as the `value` prop here
  // Since we're not yet supporting multiple values for this presentation
  // mode, we clear it out if it's not a string or a number.
  const value = ensureScalar(selectedItem);

  return (
    <div className={styles.root} data-qa-ref={qaRef} role="presentation">
      <select
        disabled={disabled}
        onChange={onChange}
        className={styles.select}
        value={value}
        id={id}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </select>
      <i className={styles.icon} aria-hidden="true">
        <CaretDownIcon />
      </i>
    </div>
  );
}
const SelectNativeStyle = {
  root: sharedStyle.root,
  icon: sharedStyle.icon,
  // @ts-expect-error refactor
  select: combineRules(
    sharedStyle.content,
    sharedStyle.text,
    ({ theme, disabled }) => ({
      margin: 0,
      width: "100%",
      // Setting to 1rem to avoid zooming issues on iOS
      // At some point we could parametize this
      fontSize: "1rem",
      backgroundImage: "none",
      height: "100%",
      appearance: "none",
      cursor: disabled ? "not-allowed" : "pointer",
      ":active": {
        outline: "none",
        borderColor: theme.color.transparent,
        boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
      },
      ":focus": {
        outline: "none",
        borderColor: theme.color.transparent,
        boxShadow: `0 0 0 2px ${theme.color.borderSelectedColor}`,
      },
      ":disabled": {
        backgroundColor: theme.color.gray100,
        color: theme.color.gray600,
        cursor: "not-allowed",
        opacity: 0.6,
        boxShadow: "none",
      },
    })
  ),
};

// @ts-expect-error needs a refactor
export default withStyle(SelectNativeStyle)(SelectNative);
