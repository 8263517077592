/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import map from "lodash/map";
import compose from "lodash/flowRight";
import Button from "hw/ui/button";
import { getIn, setIn } from "timm";
import { Box } from "hw/ui/blocks";
import { TextBody1, Heading3 } from "hw/ui/text";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import * as heap from "hw/portal/modules/analytics/heap";
import { isAuthorizedTo, permissions } from "hw/portal/modules/authn";
import { Stack } from "hw/ui/layout";
import { trackEvent } from "../analytics/gtm";
import styles from "./form.module.css";
import * as Form from "./components/form";
import BulkSend from "./bulk-send/index";
import * as flashMessages from "./flash-messages";
import { CONTACT_TYPE } from "./constants";
import { bulkSendMutation } from "../common/graphql/workflow-list/container";
import { withSession } from "../session";
import type { Session } from "../session";
import type {
  FormValues,
  Workflow,
  CreateWorkflowInstanceInput,
  BulkSendOperation,
} from "./types";
import { useCreateWorkflowInstance } from "./queries";

type Props = {
  /**
   * The workflow to be launched
   */
  workflow: Workflow;

  /**
   * Copy to render at the top of the form
   */
  headerCopy: React.ReactNode;

  /**
   * Launches the workflow
   */
  launchWorkflow: (input: CreateWorkflowInstanceInput) => Promise<unknown>;

  /**
   * Bulk launches workflows
   */
  bulkSend: BulkSendOperation;

  /**
   * Function that adds an error toast message
   */
  addDanger: Function;

  /**
   * Function that adds a success toast message
   */
  addSuccess: Function;

  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  session: Session;

  /**
   * Specifies either attachment or unique link for delivery
   */
  documentDeliveryType: string;

  /**
   * Specifies the allowable document delivery types
   */
  allowedDocumentDeliveryTypes: ["link"] | ["link", "attachment"];

  /**
   * Specifies whether or not selected team members get a copy of delivery docs
   */
  notifyWhenComplete: boolean;
};

type State = {
  submitting: boolean;
  values: FormValues;
};

/**
 * Launch Workflow Form
 *
 * Handles the form data and API submission for launching a given workflow.
 */
class LaunchWorkflowForm extends React.Component<Props, State> {
  // @ts-expect-error refactor
  _isMounted: boolean;

  state = formatInitialState(this.props.workflow);

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.launchWorkflow();
  };

  launchWorkflow = async () => {
    const {
      launchWorkflow,
      workflow,
      session,
      documentDeliveryType,
      notifyWhenComplete,
      addDanger,
      addSuccess,
    } = this.props;
    const { user } = session;
    const input = prepareCreateInstanceInput(
      user.role,
      workflow.guid,
      this.state,
      documentDeliveryType,
      notifyWhenComplete
    );

    this.setState({
      submitting: true,
    });

    // @ts-expect-error refactor
    const { error, response } = await launchWorkflow(input);

    if (!this._isMounted) {
      return;
    }

    if (error) {
      this.setState({
        submitting: false,
      });

      addDanger(
        flashMessages.launchError(workflow.name, error),
        // dismissable
        true,
        // Longer timeout. In some cases the error messages are a bit wordy
        1000 * 15
      );

      return;
    }

    const createResult = getIn(response, ["data", "createWorkflowInstance"]);
    const createErrors = response.errors;
    // @ts-expect-error refactor
    const createSucceeded = createResult.status === true;

    // If it's a success, we reset the participant information:
    const nextFormState = createSucceeded
      ? formatInitialState(this.props.workflow)
      : this.state;

    const status = flashMessages.launchResult(
      workflow.name,
      createSucceeded,
      createErrors
    );

    this.setState(nextFormState);

    if (createSucceeded) {
      addSuccess(status);
      trackEvent({
        category: "engagement",
        action: "launch workflow - hw",
        label: user.isPaid ? "paid" : "free",
      });
    } else {
      addDanger(status);
    }
  };

  handleMergeFieldChange = (key: string, value: string) => {
    this.setState((state) =>
      setIn(state, ["values", "mergeFields", key], value)
    );
  };

  /**
   * When a participants data changes update the state in this component.
   *
   * @param role - The role the participant is tied to.
   * @param property - The property of the participant to change, IE name or email.
   * @param value
   */
  handleParticipantChange = (
    role: string,
    property: "fullName" | "value",
    value: string
  ) => {
    this.setState(
      // @ts-expect-error refactor
      setIn(this.state, ["values", "participants", role, property], value) // setIn returns a new object instead of mutating.
    );
  };

  trackWorkflowLaunch = () => {
    const { guid } = this.props.workflow;
    heap.track(heap.EVENTS.launchPage.launchBtnClicked, {
      "Workflow ID": guid,
    });
  };

  render() {
    const {
      workflow,
      headerCopy,
      bulkSend,
      session,
      documentDeliveryType,
      notifyWhenComplete,
    } = this.props;
    const { roles } = workflow;
    const { user } = session;
    const { values, submitting } = this.state;
    const { mergeFields, participants } = values;

    const showBulk =
      roles.length < 4 &&
      (!workflow.mergeFields || workflow.mergeFields.length < 21);

    const hasMergeFields = Boolean(workflow.mergeFields.length);
    const hasMultipleParticipants = roles.length > 1;

    return (
      <Stack space="lg" as="form" onSubmit={this.handleSubmit}>
        <Stack space="md">
          <div className={styles.participantHeader}>
            {/* @ts-expect-error refactor */}
            <Heading3>{headerCopy}</Heading3>
            {showBulk && (
              <BulkSend
                workflow={workflow}
                bulkSend={bulkSend}
                user={user}
                documentDeliveryType={documentDeliveryType}
                notifyWhenComplete={notifyWhenComplete}
              />
            )}
          </div>

          <Form.ParticipantFieldSets
            roles={roles}
            data={participants}
            onChange={this.handleParticipantChange}
          />
        </Stack>
        {hasMergeFields && (
          <Stack space="md">
            <Form.Divider />
            <Stack space="xs">
              {/* @ts-expect-error refactor */}
              <Heading3>Merge fields</Heading3>
              <TextBody1>
                The following information is used in this{" "}
                {hasMultipleParticipants ? "workflow" : "form"}.
              </TextBody1>
            </Stack>
            <Box extend={{ maxWidth: "240px" }}>
              <Stack space="md">
                {workflow.mergeFields.map((mergeField) => (
                  <Form.MergeFieldInput
                    key={mergeField.dataRef}
                    mergeField={mergeField}
                    // @ts-expect-error refactor
                    value={mergeFields[String(mergeField.dataRef)] || ""}
                    onChange={this.handleMergeFieldChange}
                  />
                ))}
              </Stack>
            </Box>
          </Stack>
        )}
        <Button
          presentation="primary"
          disabled={submitting}
          type="submit"
          onClick={this.trackWorkflowLaunch}
          {...getTestAttributes("launch-create-workflow-instance-btn")}
        >
          Send
        </Button>
      </Stack>
    );
  }
}

function withLazyLaunchWorkflowMutation(Comp: React.ComponentType<Props>) {
  return function WithLazyLaunchWorkflowMutation(props: Props) {
    const { launchWorkflow } = useCreateWorkflowInstance();

    return <Comp {...props} launchWorkflow={launchWorkflow} />;
  };
}

export default compose(
  withSession,
  withLazyLaunchWorkflowMutation,
  bulkSendMutation
)(LaunchWorkflowForm);

/**
 * Takes the state and prepares the input object for the `createWorkflowInstance`
 * mutation
 */
function prepareCreateInstanceInput(
  userRole: string,
  guid: string,
  state: State,
  documentDeliveryType: string,
  notifyWhenComplete: boolean
) {
  const { participants, mergeFields } = state.values;

  // Flatten { roleId: { stuff } } into [{ roleId: roleId, stuff }
  const participantsArray = map(participants, (value, key) => ({
    roleId: key,
    ...value,
  }));

  // Flatten { dataRef: { value } } into [{ dataRef: dataRef }]
  const mergeFieldsArray = map(mergeFields, (value, key) => ({
    dataRefId: key,
    dataRefValue: value,
  }));

  const input = {
    guid,
    participants: participantsArray,
    isTest: false,
    mergeFields: mergeFieldsArray,
  };

  if (isAuthorizedTo(permissions.UpdateWorkflow, userRole)) {
    // @ts-expect-error refactor
    input.documentDeliveryType = documentDeliveryType;

    // @ts-expect-error refactor
    input.notifyWhenComplete = notifyWhenComplete;
  }

  return input;
}

function formatInitialState(workflow: Workflow) {
  const participants = workflow.roles.reduce((participantAcc, role) => {
    // @ts-expect-error refactor
    participantAcc[role.id] = {
      fullName: "",
      value: "",
      type: CONTACT_TYPE.EMAIL,
    };

    return participantAcc;
  }, {});

  return {
    submitting: false,
    values: {
      participants,
      mergeFields: {},
    },
  };
}
