/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { lastUndone } from "./undoable";

type ContextValue = {
  lastUndone: any;
};
export const Context = React.createContext<ContextValue>({
  lastUndone: undefined,
});
export function Provider(props: any) {
  const { history } = props;
  const value = React.useMemo(
    () => ({
      lastUndone: lastUndone(history),
    }), // If you touch this, please fix the lint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.past]
  );
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}

/**
 * This is a little unfortunate, but some components fork their own internal
 * state as opposed to always reading state from the top.  In those cases,
 * we have to know when an undo action occurs so that it can be re-initialied
 * with the new state.  This should be used sparingly and avoided if possible.
 */
export function useUndoContext() {
  const state = React.useContext(Context);

  if (!state) {
    throw new Error(
      "useUndoContext must be used within the undo `Provider` component"
    );
  }

  // When the last undone item has changed, notify the subscribes
  useUpdateEffect(notify, [state.lastUndone]);
  return subscribe;
}
// @ts-expect-error ts-migrate(7034) FIXME: Variable 'subscriptions' implicitly has type 'any[... Remove this comment to see the full error message
const subscriptions = [];

function subscribe(fn: (...args: Array<any>) => any) {
  subscriptions.push(fn);
  return () => {
    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'subscriptions' implicitly has an 'any[]'... Remove this comment to see the full error message
    subscriptions.splice(subscriptions.indexOf(fn), 1);
  };
}

function notify() {
  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'subscriptions' implicitly has an 'any[]'... Remove this comment to see the full error message
  subscriptions.forEach((fn) => fn());
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'effect' implicitly has an 'any' type.
function useUpdateEffect(effect, deps) {
  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (mounted.current) {
      return effect();
    } else {
      mounted.current = true;
      return undefined;
    } // If you touch this, please fix the lint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
