import * as React from "react";
import debounce from "lodash/debounce";
import { ParagraphEditor } from "hw/common/rich-text/editor";
import * as resizeListener from "hw/common/utils/dom/resize-listener";
import type {
  MergeField,
  MappedField,
  ParagraphField,
} from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import Fallback from "./fallback";
import { Actions } from "../../state";
import { useFormBuilderState } from "../../form-builder-context";
import { useUndoContext } from "../../../undo-context";

const DEBOUNCE_TIMEOUT = process.env.NODE_ENV === "test" ? 0 : 350;
type Props = {
  setMapping: (
    mappedField: MappedField | MergeField,
    field: ParagraphField
  ) => void;
  field: ParagraphField;
  isAnyFieldDragging: boolean;
  isSelected: boolean;
  path: Path;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpToDef: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: any;
  onCreateMergeField: (mergeField: MergeField) => void;
};
type ContextProps = Props & {
  mergeFields: Array<MergeField>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal: (...args: Array<any>) => any;
  previewPaneRef: (HTMLElement | null | undefined) | void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUndoRedo: (...args: Array<any>) => any;
};
type State = {
  visibleHeight: number | null | undefined;
  hasError: boolean;
};
/**
 * Paragraph ("Content Block") Preview
 *
 * This preview component is a little different than the other fields because:
 *
 * - It renders different depending on if it's selected or not
 * - Users can edit the schema directly from the preview as opposed to
 *   only being able to edit from within the settings panel.
 *
 * The Paragraph component needs the parent scroll container's DOM reference in
 * order to be able to calculate the max height.  Since this is something
 * specific to this component, it's passed down via context.  We wait until we
 * actually have a `scrollContainerRef` before rendering so we can immediately
 * compute the height
 */

class ParagraphPreview extends React.PureComponent<ContextProps, State> {
  container: HTMLElement | null | undefined;

  state = {
    visibleHeight: undefined,
    hasError: false,
  };

  handleRef = (node: HTMLElement | null | undefined) => {
    this.container = node;
  };

  componentDidMount() {
    this.track();
    this.measure();
  }

  componentWillUnmount() {
    this.untrack();

    if (typeof this.handleChange.cancel === "function") {
      this.handleChange.cancel();
    }
  }

  componentDidUpdate(prevProps: ContextProps) {
    if (!prevProps.previewPaneRef && this.props.previewPaneRef) {
      this.measure();
    }
  }

  /**
   * When existing workflows are loaded, their `value` properties will be
   * strings, which is not compatible with the rich text editor.  Trying to load
   * that in the editor will crash.  Ideally, the entire form builder should only
   * ever render if the form is valid, but there are currently some issues with
   * the `isValid` getting out of sync with the actual validity for the form.
   * Sometimes a form will be marked as valid when it isn't and vice versa and
   * in those cases, the entire workflow editor might crash.  The workaround
   * for now is to catch any errors and render the generic fallback in the builder.
   * This will allow the builder to mostly work without crashing the editor.
   */
  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  measure = () => {
    const { previewPaneRef } = this.props;

    if (!previewPaneRef) {
      return;
    }

    const visibleHeight = computeVisibleHeight(
      previewPaneRef.getBoundingClientRect()
    );
    this.setState({
      visibleHeight,
    });
  };

  track = () => resizeListener.add(this.measure);

  untrack = () => resizeListener.remove(this.measure);

  // eslint-disable-next-line @typescript-eslint/ban-types
  handleChange = debounce((value: {}) => {
    const { editorDispatch, path } = this.props;
    editorDispatch(
      Actions.ChangeFieldSetting({
        path: [...path, "value"],
        updater: () => value,
      })
    );
  }, DEBOUNCE_TIMEOUT);

  handleInsertMergeField = (mergeField: MergeField) => {
    const { setMapping, field } = this.props;
    setMapping(mergeField, field);
  };

  render() {
    const {
      field,
      isSelected,
      mergeFields,
      openMergeFieldsModal,
      isAnyFieldDragging,
      onUndoRedo,
      onCreateMergeField,
    } = this.props;
    const { visibleHeight, hasError } = this.state;
    const { value } = field;

    if (hasError) {
      return <Fallback {...this.props} />;
    }

    return (
      <div ref={this.handleRef}>
        {/* $FlowFixMe */}
        <ParagraphEditor
          isActive={isSelected && !isAnyFieldDragging}
          defaultValue={value}
          onChange={this.handleChange}
          maxHeight={visibleHeight}
          mergeFields={mergeFields}
          onInsertMergeField={this.handleInsertMergeField}
          onCreateMergeField={onCreateMergeField}
          onEditMergeFields={openMergeFieldsModal}
          onResync={onUndoRedo}
        />
      </div>
    );
  }
}
/*
 * Computes the maxHeight for the editor.
 *
 * The component's height is meant to be slightly smaller than the available
 * visible space within the from preview pane.  The entire component should be able to
 * fit into the visible space if the preview pane is scrolled to the correct
 * position.
 */

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rect' implicitly has an 'any' type.
function computeVisibleHeight(rect) {
  const windowHeight = window.innerHeight;
  const visibleHeight = windowHeight - rect.top;
  return visibleHeight * 0.8;
}

function ParagraphPreviewWithContext(props: Props) {
  const state = useFormBuilderState();
  const onUndoRedo = useUndoContext();
  return (
    <ParagraphPreview
      {...props}
      previewPaneRef={state.previewPaneRef}
      mergeFields={state.mergeFields}
      openMergeFieldsModal={state.openMergeFieldsModal}
      onUndoRedo={onUndoRedo}
    />
  );
}

export default ParagraphPreviewWithContext;
