import * as React from "react";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  size?: number;
  active?: boolean;
};
export default function AddIcon16(props: Props) {
  const { size = 16 } = props;
  const cn = useStyle({
    transitionProperty: "transform",
    transitionDuration: "250ms",
    transitionTimingFunction: "ease-in-out",
    transform: props.active && `rotate(45deg)`,
    borderRadius: theme.corner.round,
  });
  return (
    <svg
      className={cn}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 8H3h5V3v5zm0 0h5-5v5-5z"
      />
    </svg>
  );
}
