/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Label from "../common/label";
import ErrorMessage from "../common/error-message";
import TextArea from "./textarea";

import styles from "./multi-line.module.css";

type Props = {
  /**
   * Help bubble next to the label
   */
  helpText?: string;
  /**
   * Label attached to the field
   */
  label?: string;
  /**
   * Function triggered when the value changes
   */
  onChange?: (...args: Array<any>) => any;
  /**
   * Determines if it should be readonly.
   * Used to display information from previous participants
   */
  readOnly?: boolean;
  /**
   * Determines if the value is enforced
   */
  required: boolean;
  /**
   * Value assigned to the field
   */
  value?: string;
  /**
   * Maximum number of characters
   */
  maxLength?: number;
  /**
   * Placeholder value from the schema
   */
  placeholder?: string;
  /**
   * Determines if a value is valid after validation
   */
  isValid?: boolean;
  /**
   * Error message to display based on validation errors
   */
  errorMessage?: string;
  /**
   * Function used to track the field for validation
   */
  handleVisited?: (...args: Array<any>) => any;
  id?: string;
};

export default function Multiline(props: Props) {
  const {
    label,
    helpText,
    required,
    maxLength = 500,
    onChange,
    placeholder,
    value,
    readOnly,
    isValid,
    errorMessage,
    handleVisited,
    id,
  } = props;

  const error = !isValid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

  return (
    <div
      className={styles.wrapper}
      role="presentation"
      aria-live="polite"
      aria-atomic="true"
    >
      {label && (
        <Label required={required} helpText={helpText} htmlFor={id}>
          {label}
        </Label>
      )}
      <TextArea
        id={id}
        // @ts-expect-error refactor
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        readOnly={readOnly}
        aria-multiline="true"
        aria-required={required}
        onBlur={handleVisited}
        data-custom-focus
      />
      {!readOnly && (
        <>
          <span
            className={styles.counter}
            role="timer"
            aria-label="Character Limit"
          >
            {value ? maxLength - value.length : maxLength} character
            {value && maxLength - value.length === 1 ? "" : "s"} left
          </span>
          {error}
        </>
      )}
    </div>
  );
}
