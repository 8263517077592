/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useQuery } from "@apollo/client";
import { Sparkle, Sparkle16 } from "hw/ui/icons/svg-icons";
import * as heap from "hw/portal/modules/analytics/heap";
import { createLogger } from "hw/common/utils/logger";
import Tooltip from "hw/ui/tooltip";
import { Box } from "hw/ui/blocks";
import { Form } from "hw/portal/modules/common/draft/schema-ops";
import entitlementsQuery from "hw/portal/modules/common/graphql/team/entitlements.graphql";
import { useUser } from "hw/portal/modules/user";
import styles from "./premium.module.css";

type Props = {
  text?: React.ReactNode;
  feature: string;
};

const logger = createLogger("premium");

// D1 and D2 use different names so to only have one source of truth we pull this out of the config.
const fileAttachmentFeature = {
  label: "File attachment",
  isComponent: true,
  tracking: "Component - Attachment",
  automationId: "attachment-upgrade_now",
  entitlementName: "attachment",
};

// NOTE: We use this config in both V2 and V3 editors.
export const paidFeatures = {
  BulkSend: {
    label: "Bulk send",
    isComponent: false,
    tracking: "Bulk send",
    entitlementName: "bulk_send",
  },
  Calculation: {
    label: "Calculation",
    isComponent: true,
    tracking: "Component - Calculation",
    automationId: "calculation-upgrade_now",
    entitlementName: "calculation",
  },
  Group: {
    label: "Conditional rule",
    isComponent: true,
    tracking: "Component - Conditional rule",
    automationId: "conditional-upgrade_now",
    entitlementName: "conditional_rule",
  },
  CSVDownload: {
    label: "CSV download",
    isComponent: false,
    entitlementName: "transaction_csv_download",
  },
  AuditLog: {
    label: "Audit log",
    isComponent: false,
    entitlementName: "audit_log_csv_download",
  },
  FileAttachment: fileAttachmentFeature,
  AttachmentInput: fileAttachmentFeature,
  MultipleForms: {
    label: "Multiple forms",
    isComponent: false,
    entitlementName: "multi_form",
  },
  TeamRole: {
    label: "Team role",
    isComponent: false,
    entitlementName: "team_role",
  },
  SignatureOptions: {
    label: "Signature options",
    isComponent: false,
    entitlementName: "signature_options",
  },
  WhiteLabeling: {
    label: "White labeling",
    isComponent: false,
    entitlementName: "white_label",
  },
  AutoBuild: {
    label: "Auto build",
    isComponent: false,
    entitlementName: "auto_build",
  },
  MoreTransactions: {
    label: "Monthly transactions",
    isComponent: false,
    entitlementName: "number_of_instances_per_month",
  },
};

function handleUpgradeLinkClick(value: any) {
  heap.track(heap.EVENTS.upgradeLinkClicked, {
    Source: value,
  });
}

// for components, "feature" shares same name as component.type or component.value
// for non-components, "feature" is a standalone feature and doesn't share a name with anything else
function hasFeatureAccess(entitlements: any, feature: any) {
  // @ts-expect-error refactor
  if (!paidFeatures[feature]) return true;

  // @ts-expect-error refactor
  const { entitlementName } = paidFeatures[feature];
  // @ts-expect-error refactor
  const entitlement = entitlements.find((e) => e.name === entitlementName);

  if (entitlement) {
    const { type, name, value } = entitlement;

    if (type === "boolean") {
      return value;
    }

    if (name === "multi_form") {
      // As of initial launch of paygate, we are only concerned with single-form vs. multi-form.
      // It's conceivable that at some time in the future we may want to support more than two tiers,
      // in which case we can refactor this and update messaging at that time.
      // -1 indicates unlimited
      return value === -1 || value > 1;
    }

    if (name === "number_of_instances_per_month") {
      // This logic is a bit of a hack. We no longer really have a concept of paid vs. unpaid user.
      // Now users just have what they are entitled to.
      // At this time, we really only have two tiers, unlimited ("paid"), and 50 transactions ("free")
      // So this is just a way to infer a paid user in the meantime
      return value === -1;
    }
  }

  return false;
}

// Data container for team entitlements
function AccessContainer(props: {
  children: any;
  loadingView?: React.ReactNode;
}) {
  const { children, loadingView } = props;
  const { error, data } = useQuery(entitlementsQuery);

  const entitlements = data?.user?.entitlements;

  if (error) {
    logger.error("Error retrieving entitlementsQuery entitlements");
  }

  if (entitlements)
    return children({
      entitlements,
    });

  return loadingView || null;
}

// Generic wrapper. Wraps around an element, return the "access" element if it has premium access.
// returns the "noAccess" if not and exists.
export function PremiumWrapper(props: {
  access: React.ReactNode;
  noAccess: React.ReactNode;
  feature: string;
  loadingView?: React.ReactNode;
}) {
  const { feature, access = null, noAccess = null, loadingView } = props;

  return (
    <AccessContainer loadingView={loadingView}>
      {({ entitlements }: { entitlements: any }): React.ReactNode => {
        const hasAccess = hasFeatureAccess(entitlements, feature);

        return hasAccess ? access : noAccess;
      }}
    </AccessContainer>
  );
}

function defaultGetUsedFieldTypes(forms: any) {
  const components = new Set();

  forms.forEach((f: any) => {
    Form.traverseComponents(f, (component: any) => {
      components.add(component.type);
    });
  });

  return components;
}

// Specific publish wrapper. Wraps around publish buttons, activates if it has premium access
// returns the noAccess otherwise
export function PremiumPublish(props: {
  forms: Array<any>;
  access: React.ReactNode;
  noAccess: React.ReactNode;
  getUsedFieldTypes?: (...args: Array<any>) => any;
}) {
  const {
    forms,
    access,
    noAccess,
    getUsedFieldTypes = defaultGetUsedFieldTypes,
  } = props;
  const [currentComponents, setCurrentComponents] = React.useState([]);

  // store all the different component types being used in the entire workflow
  React.useEffect(() => {
    const components = getUsedFieldTypes(forms);

    // @ts-expect-error refactor
    setCurrentComponents([...components]);
  }, [forms, getUsedFieldTypes]);

  // check the component store for any which are gated
  const checkForUnpaidComponents = React.useCallback(
    (entitlements) =>
      currentComponents.some((cType) => !hasFeatureAccess(entitlements, cType)),
    [currentComponents]
  );

  const { role } = useUser();

  return (
    <React.Fragment>
      <AccessContainer>
        {({ entitlements }: { entitlements: any }): React.ReactNode => {
          let message;
          const entitlement = entitlements.find(
            (e: any) => e.name === "multi_form"
          );

          if (!entitlement) {
            logger.error("Missing entitlement: multi_form");
          }

          const availableForms = entitlement?.value ?? 1; // -1 has unlimited forms
          const hasUnpaidComponents = checkForUnpaidComponents(entitlements);
          const hasUnpaidForms =
            availableForms === -1 ? false : forms.length > availableForms;

          if (hasUnpaidForms) {
            message = hasUnpaidComponents
              ? "This workflow has components and additional forms not included in your plan."
              : "This workflow has additional forms not included in your plan.";
          } else {
            message = hasUnpaidComponents
              ? "This form has components not included in your plan."
              : ""; // no warning, OK
          }

          if (message)
            return (
              <>
                <PremiumPublishWarning
                  {...props}
                  message={message}
                  userRole={role}
                />
                {noAccess}
              </>
            );

          return access;
        }}
      </AccessContainer>
    </React.Fragment>
  );
}

function PremiumPublishWarning(props: { message: string; userRole?: string }) {
  const { message, userRole } = props;
  const handleUpgradeLink = React.useCallback(() => {
    handleUpgradeLinkClick("Publish area");
  }, []);

  const shouldDisplayLink = userRole && userRole === "admin";
  return (
    <Box my="md">
      <div className={styles.settingsContainer}>
        <span className={styles.iconWrapper}>
          <Sparkle16 />
        </span>
        <span>
          <span>
            {message} Remove them to publish{shouldDisplayLink ? " or " : ""}
            {shouldDisplayLink && (
              <a
                href="/plan"
                className={styles.link}
                onClick={handleUpgradeLink}
                data-automation-id="publish-upgrade_now_plan_page"
              >
                upgrade now
              </a>
            )}
            .
          </span>
        </span>
      </div>
    </Box>
  );
}

export function PremiumBadge(props: Props) {
  const { feature } = props;

  return (
    <AccessContainer>
      {({ entitlements }: { entitlements: any }): React.ReactNode => {
        const hasAccess = hasFeatureAccess(entitlements, feature);

        return hasAccess ? null : (
          <div className={styles.badgeContainer}>
            <Sparkle />
            <div className={styles.badgeLabel}>Premium</div>
          </div>
        );
      }}
    </AccessContainer>
  );
}

export function PremiumPreview(props: Props) {
  const { feature } = props;

  return (
    <AccessContainer>
      {({ entitlements }: { entitlements: any }): React.ReactNode => {
        const hasAccess = hasFeatureAccess(entitlements, feature);

        return hasAccess ? null : (
          <div className={styles.previewContainer}>
            <Tooltip
              tip={"Paid feature available for preview only"}
              position="top"
            >
              <div className={styles.tooltipArea}>
                <Sparkle16 />
              </div>
            </Tooltip>
          </div>
        );
      }}
    </AccessContainer>
  );
}

export function PremiumBox1(props: Props) {
  const { text, feature } = props;
  const {
    label = "This",
    tracking,
    automationId,
    // @ts-expect-error refactor
  } = paidFeatures[feature] || {};

  const { role } = useUser();

  const handleUpgradeLink = React.useCallback(async () => {
    handleUpgradeLinkClick(tracking);
  }, [tracking]);

  return (
    <AccessContainer>
      {({ entitlements }: { entitlements: any }): React.ReactNode => {
        const hasAccess = hasFeatureAccess(entitlements, feature);

        return hasAccess ? null : (
          <React.Fragment>
            <div className={styles.settingsContainer}>
              <div className={styles.iconWrapper}>
                <Sparkle16 />
              </div>
              {text ? (
                <div>{text}</div>
              ) : (
                <div>
                  {label} is a paid feature and can only be used for preview.{" "}
                  {role === "admin" && (
                    <a
                      href="/plan"
                      className={styles.link}
                      data-automation-id={automationId}
                      onClick={handleUpgradeLink}
                    >
                      Upgrade now
                    </a>
                  )}
                </div>
              )}
            </div>
          </React.Fragment>
        );
      }}
    </AccessContainer>
  );
}

export function PremiumInlineButton(props: Props) {
  const { feature } = props;

  return (
    <AccessContainer>
      {({ entitlements }: { entitlements: any }): React.ReactNode => {
        const hasAccess = hasFeatureAccess(entitlements, feature);

        return hasAccess ? null : (
          <div>
            <Tooltip
              tip={
                "You can add additional forms to preview workflow functionality but not publish."
              }
              position="top"
            >
              <div className={styles.tooltipArea}>
                <Sparkle16 />
              </div>
            </Tooltip>
          </div>
        );
      }}
    </AccessContainer>
  );
}

/**
 * This converts a Draft V2 field type to a known "paid feature" as listed above.
 */
export function convertDraftV2FieldTypeToPaidFeatureName(
  d2FieldType: string
): string {
  switch (d2FieldType) {
    case "CalculatedNumericValue":
      return "Calculation";
    case "Condition":
      return "Group";
    default:
      return d2FieldType;
  }
}
