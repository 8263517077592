import * as React from "react";
import { Text } from "hw/ui/blocks";

/**
 * TODO: Should this be in hello-ui?  It has some hard-coded styles that I'm not
 * quite sure how to fit into the theme yet.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function SmallHeader(props: {}) {
  return (
    <Text
      fontSize="xs"
      style={{ lineHeight: "16px", letterSpacing: "0.085em" }}
      textTransform="uppercase"
      // @ts-expect-error refactor
      fontWeight={600}
      color="textDim"
      {...props}
    />
  );
}

export default SmallHeader;
