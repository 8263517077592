import * as React from "react";

export default function ExternalLink() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <defs>
        <path
          id="external-link-icon-24-a"
          d="M13.586 9H11a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0v-2.586l-5.293 5.293a1 1 0 1 1-1.414-1.414L13.586 9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use
          fill="currentColor"
          fillRule="nonzero"
          xlinkHref="#external-link-icon-24-a"
        />
      </g>
    </svg>
  );
}
