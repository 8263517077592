import { Expression } from "hw/portal/modules/common/draft/factories";
import type { Expression as ExpressionT } from "hw/portal/modules/common/draft/types";
import type { Rule } from "./types";
import * as constants from "./constants";
/**
 * Takes the expression state and composes and new expression
 */

export default function composeExpression({
  rules,
  globalSelector,
}: {
  rules: Array<Rule>;
  globalSelector: "All" | "Any";
}): ExpressionT {
  const expressions = rules // If a rule is completely empty, assume that it was created but not touched
    // and filter it out
    .filter((rule) => !isEmptyRule(rule))
    .map((rule) => ruleToExpression(rule));
  const method =
    globalSelector === constants.GLOBAL_SELECTOR_ANY
      ? constants.METHOD_ANY
      : constants.METHOD_ALL;
  return Expression(method, expressions);
} // We take a list of strings and transform it in options

function ruleToExpression(rule: Rule): ExpressionT {
  const [subject, condition, value] = rule;
  invariant(
    rule.length > 0,
    `Rules must have at least one element.  Received ${rule.length}`
  );

  if (rule.length === 1) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return getValExpression(subject);
  } else if (rule.length === 2) {
    if (condition === constants.IS_BLANK) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return getIsBlankExpression(subject);
    } else if (condition === constants.IS_FILLED) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return getIsFilledExpression(subject);
    } else if (condition === constants.IS) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return getIsExpression(subject);
    } else if (condition === constants.IS_NOT) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return getIsNotExpression(subject);
    } else {
      throw new Error(`You can't have a condition with the name ${condition}`);
    }
  } else if (rule.length === 3) {
    if (condition === constants.IS) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return getIsExpression(subject, value);
    } else if (condition === constants.IS_NOT) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return getIsNotExpression(subject, value);
    } else {
      throw new Error(
        `You can't have a condition with 3 rules that include ${condition}`
      );
    }
  } else {
    throw new Error(
      `Rules can't have more than 3 elements.  Received ${rule.length}`
    );
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rule' implicitly has an 'any' type.
function isEmptyRule(rule) {
  return Array.isArray(rule) && rule.length === 1 && rule[0] === "";
}

function getValExpression(subject: string) {
  return Expression("val", [subject]);
}

function getIsEmptyExpression(subject: string | ExpressionT) {
  return Expression("isEmpty", [subject]);
}

function getIsOrIncludesExpression(
  subject: string | ExpressionT,
  value?: string
) {
  // @ts-expect-error refactor
  return Expression("isOrIncludes", [subject, value]);
}

function getIsBlankExpression(subject: string) {
  const valExpression = getValExpression(subject);
  const isEmptyExpression = getIsEmptyExpression(valExpression);
  // @ts-expect-error refactor
  return Expression("if", [isEmptyExpression, true, false]);
}

function getIsFilledExpression(subject: string) {
  const valExpression = getValExpression(subject);
  const isEmptyExpression = getIsEmptyExpression(valExpression);
  // @ts-expect-error refactor
  return Expression("if", [isEmptyExpression, false, true]);
}

function getIsExpression(subject: string, value?: string) {
  const valExpression = getValExpression(subject);
  const isOrIncludesExpression = getIsOrIncludesExpression(
    valExpression,
    value
  );
  // @ts-expect-error refactor
  return Expression("if", [isOrIncludesExpression, true, false]);
}

function getIsNotExpression(subject: string, value?: string) {
  const valExpression = getValExpression(subject);
  const isOrIncludesExpression = getIsOrIncludesExpression(
    valExpression,
    value
  );
  // @ts-expect-error refactor
  return Expression("if", [isOrIncludesExpression, false, true]);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'condition' implicitly has an 'any' type... Remove this comment to see the full error message
function invariant(condition, message) {
  if (!condition) {
    const error = new Error(message);
    error.name = "Rule to Expression Invariant";
    throw error;
  }
}
