import * as React from "react";
import TextInput from "hw/common/components/smartforms/text-input";
import type { TextInputField } from "hw/portal/modules/common/draft";

type Props = {
  prefillMessage: string | null | undefined;
  field: TextInputField;
};
type State = {
  value: string;
};
export class TextInputPreview extends React.Component<Props, State> {
  state = {
    value: this.props.prefillMessage || "",
  };

  onChange = (newValue: string) =>
    this.setState({
      value: newValue,
    });

  componentDidUpdate(prevProps: Props) {
    if (prevProps.prefillMessage !== this.props.prefillMessage) {
      this.setState({
        value: this.props.prefillMessage || "",
      });
    }
  }

  render() {
    const { value } = this.state;
    const { field } = this.props;
    const { label, placeholder, required, helpText, id } = field;
    // The `required` field could be a macro in which case the form builder won't
    // know if the field is required or not because macros are not being
    // evaluated.  For V1, we'll coerce string values to `true` and treat them
    // as required.
    const isRequired = Boolean(required);
    return (
      <TextInput
        id={id}
        label={label}
        placeholder={placeholder}
        theme={{
          classNames: {
            inputBorderFocus: "",
          },
        }}
        required={isRequired}
        helpText={helpText || ""}
        onChange={this.onChange}
        value={value}
      />
    );
  }
}
export default TextInputPreview;
