/* eslint-disable @typescript-eslint/no-explicit-any */
import { matchSorter, rankings } from "match-sorter";

type Ranking = typeof rankings[keyof typeof rankings];

/**
 * Function that calls matchSorter to sort and filter a list
 *
 * It receives:
 * - a list of options (with a label),
 * - a filter to filter the options
 * - optional list of params, e.g. keys used to match. It can be a function or a string (key)
 * and the list will be sorted depending on the order of the keys.
 * Or threshold - i.e. criteria used to rank results, essentially another filter,
 * e.g. "so" would match "oslo" and "solo" by default,
 * but setting a CONTAINS threshold will return only "solo"
 */
export function filterOptions(
  options: Array<Record<string, any>>,
  filter?: string,
  keys: Array<((...args: Array<any>) => any) | string> = ["label"],
  threshold?: Ranking
) {
  return filter
    ? matchSorter(options, filter, {
        keys,
        threshold,
      })
    : options;
}
