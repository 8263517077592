import * as React from "react";
import cond from "lodash/cond";
import matchesProperty from "lodash/matchesProperty";
import matches from "lodash/matches";
import constant from "lodash/constant";
import { withStyle } from "hw/ui/style";

// Helper Functions
const disabled = matchesProperty("disabled", true);
const invalid = matchesProperty("invalid", true);
const active = matchesProperty("active", true);
const focused = matchesProperty("focused", true);
const checked = matchesProperty("checked", true);
const hovered = matchesProperty("hovered", true);
const disabledAndChecked = matches({ checked: true, disabled: true });
const fallback = constant(true);

const themeColor =
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.


    (color) =>
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
    ({ theme }) =>
      theme.color[color];

/**
 * Styling logic
 *
 * The `cond` function will return the first value that matches the predicate
 * outline in the first tuple, so the order matters.
 */
const outerCircleStroke = cond([
  [disabled, themeColor("transparent")],
  [invalid, themeColor("red")],
  [checked, themeColor("borderSelectedColor")],
  [active, themeColor("borderSelectedColor")],
  [focused, themeColor("borderSelectedColor")],
  [fallback, themeColor("gray500")],
]);

const outerCircleFill = cond([
  [disabledAndChecked, themeColor("inputBgDisabled")],
  [disabled, themeColor("inputBgDisabled")],
  [invalid, themeColor("white")],
  [checked, themeColor("borderSelectedColor")],
  [active, themeColor("blue100")],
  [hovered, themeColor("gray100")],
  [fallback, themeColor("white")],
]);

const outerCircleStrokeWidth = cond([
  [focused, constant("2px")],
  [active, constant("2px")],
  [invalid, constant("2px")],
  [fallback, constant("1px")],
]);

const outerCircleStrokeOpacity = cond([[focused, constant(0.7)]]);

const innerCircleFill = cond([
  [disabledAndChecked, themeColor("gray500")],
  [invalid, themeColor("white")],
  [checked, themeColor("white")],
]);

const style = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  outerCircle: (props) => ({
    stroke: outerCircleStroke(props),
    fill: outerCircleFill(props),
    strokeWidth: outerCircleStrokeWidth(props),
    strokeOpacity: outerCircleStrokeOpacity(props),
  }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  innerCircle: (props) => ({
    fill: innerCircleFill(props),
  }),
};

type Props = {
  styles: {
    outerCircle: string;
    innerCircle: string;
  };
};

export function RadioIcon(props: Props) {
  const { styles } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      role="presentation"
    >
      <circle className={styles.outerCircle} cx="9.5" cy="9.5" r="8" />
      <circle
        className={styles.innerCircle}
        cx="9.5"
        cy="9.5"
        r="3"
        fill="transparent"
      />
    </svg>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default withStyle(style)(RadioIcon) as React.ComponentType<{}>;
