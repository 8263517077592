/**
 * NOTE: If you're using this file, ensure you have the worker file * placed within the `priv/static/js` directory of the application.
 */
import * as ReactPDF from "react-pdf";

export type { PDFDocumentProxy } from "react-pdf";

// @ts-expect-error refactor
ReactPDF.pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.entry.js";

export default ReactPDF;
