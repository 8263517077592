import omit from "lodash/omit";
import * as React from "react";
import { Extend, useCss } from "hw/ui/style";
import theme from "hw/ui/theme";
import style, { stylePropNames } from "./style";

type Theme = typeof theme;
type ThemeColors = Theme["color"];

type MaybeArray<T> = T | T[] | null;

type Space = "none" | "xs" | "sm" | "ms" | "md" | "lg" | "xl" | "xxl";
type FontSize = "none" | "xs" | "sm" | "ms" | "md" | "lg" | "xl" | "xxl";
type FontWeight = "normal" | "semibold" | "bold";

type Props = {
  as?: React.ElementType;
  alignItems?: MaybeArray<string>;
  borderBottomStyle?: MaybeArray<string>;
  bg?: MaybeArray<keyof ThemeColors>;
  borderColor?: MaybeArray<string>;
  borderStyle?: MaybeArray<string>;
  borderWidth?: MaybeArray<number>;
  className?: string;
  color?: MaybeArray<string>;
  display?: MaybeArray<string>;
  extend?: Extend;
  flex?: MaybeArray<string | number>;
  flexDirection?: MaybeArray<string>;
  fontSize?: MaybeArray<FontSize>;
  fontWeight?: MaybeArray<FontWeight>;
  justifyContent?: MaybeArray<string>;
  m?: MaybeArray<Space | "auto">;
  mb?: MaybeArray<Space | "auto">;
  ml?: MaybeArray<Space | "auto">;
  mr?: MaybeArray<Space | "auto">;
  mt?: MaybeArray<Space | "auto">;
  mx?: MaybeArray<Space | "auto">;
  my?: MaybeArray<Space | "auto">;
  p?: MaybeArray<Space>;
  pb?: MaybeArray<Space>;
  pl?: MaybeArray<Space>;
  pr?: MaybeArray<Space>;
  pt?: MaybeArray<Space>;
  px?: MaybeArray<Space>;
  py?: MaybeArray<Space>;
  textAlign?: MaybeArray<string>;
  textTransform?: MaybeArray<string>;
  width?: MaybeArray<number | string | null>;
  children?: React.ReactNode;
  style?: Record<string, unknown>;
  tabIndex?: number;
};

/**
 * Box component.  This component is a low-level component that has convenience
 * properties for setting certain often-used style settings and operating with
 * the `theme` that's been defined for an app.  It's built on `styled-system` so
 * much of the documentation can be found there.
 *
 * @example
 * // Will set a margin and padding based on the elements at those points on the
 * // defined `space` scale
 * <Box m="sm" p="ms">
 *      <MyComponent {...}  />
 * </Box>
 *
 * // Will set the background color the `primary` color defined on the theme and
 * // the text color will be the `secondary` color defined on the theme
 * <Box bg='primary' color='secondary'>
 *      <MyComponent {...}  />
 * </Box>
 *
 * See:  https://github.com/jxnblk/styled-system
 */
export default function Box(props: Props) {
  const { as = "div", extend, className = "", ...rest } = props;
  const inputs = getInputs(props);
  const css = useCss({ ...props, theme });

  // If you touch this, please fix the lint warning
  /* eslint-disable react-hooks/exhaustive-deps */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const cn = React.useMemo(() => css("box", style, extend), inputs);
  /* eslint-enable react-hooks/exhaustive-deps */
  const finalClassName = [cn, className].join(" ").trim();
  const filteredProps = omit(rest, stylePropNames);

  return React.createElement(as, {
    ...filteredProps,
    className: finalClassName,
  });
}

function getInputs(props: Record<string, unknown>) {
  const styleProps = stylePropNames.map((name) => props[name]);

  return [...styleProps, props.extend];
}
