/**
 * This is a _very_ basic task interface that's mean to be a simplified version
 * of something like Fluture: https://github.com/fluture-js/Fluture.  The only
 * real functionality included here is:
 *
 *   - Laziness: Tasks are only actually run after calling the `.fork()` method
 *   - Cancellation: The `.fork()` method returns a function that cancels the asyn operation
 *
 * If there is more advanced functionlity needed from this interface, we should
 * consider incorporating a library.
 *
 * @example
 *
 * function fetchFromApi(id) {
 *   return new Task(function(reject, resolve) {
 *     const req = superagent.get('/api');
 *
 *     req.then(resp => resolve(resp), err => reject(err))
 *
 *     return () => req.abort()
 *   });
 * }
 *
 * // Nothing has happened yet, we just set up the task and mapped over the
 * // results
 * const task = fetchFromApi(1).map(resp => resp.body)
 *
 * // Calling `fork` initiates the API call
 * const cancel = task.fork(
 *   err => {
 *     // handle error
 *   },
 *   val => {
 *     // do something with value
 *   }
 * );
 *
 * // The task can be canceled if needed...
 * cancel()
 */

type Computation<Err, Value> = (
  reject: (err?: Err) => void,
  resolve: (val: Value) => void
) => () => void;

export default class Task<Err, Value> {
  fork: Computation<Err, Value>;

  constructor(computation: Computation<Err, Value>) {
    this.fork = computation;
  }

  /**
   * Map over the successful result of a task.  See example above.
   */
  map<O>(fn: (val?: Value) => O): Task<Err, O> {
    return Task.from((reject, resolve) =>
      this.fork(reject, (b) => resolve(fn(b)))
    );
  }

  /**
   * Returns a new `Task` instance
   *
   * @example
   * const task = Task.from((rej, res) => {
   *   // ...
   * })
   */
  static from<Err, Value>(computation: Computation<Err, Value>) {
    return new Task(computation);
  }

  /**
   * Creates a new task that always rejects
   *
   * @example
   * const task = Task.reject('Error!');
   *
   * task.fork()
   * //=> 'Error!'
   */
  static reject<Err>(err?: Err) {
    return new Task((l) => {
      l(err);
      return () => {
        //
      };
    });
  }

  /**
   * Creates a new task that always resolved
   *
   * @example
   * const task = Task.resolve();
   *
   * task.fork()
   */
  static resolve<Value>(data: Value) {
    return new Task((_, r) => {
      r(data);
      return () => {
        //
      };
    });
  }
}
