import * as React from "react";
import { createComponent } from "hw/ui/style";

type Props = {
  align: string;
  width: string;
};

/**
 * TODO: See about removing this, or the heading settings in table cell. We don't need both.
 */
export const Th = createComponent(
  function Th({ theme, dimmed }) {
    return {
      padding: theme.space.ms,
      fontSize: theme.fontSize.ms,
      fontWeight: theme.fontWeight.semibold,
      color: dimmed ? theme.color.textDim : theme.color.textStandard,
    };
  },
  "th",
  ["align", "width"]
);
// @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'ComponentClass<{ theme: any; d... Remove this comment to see the full error message
export default Th as React.ComponentType<Props>;
