import * as React from "react";
import throttle from "lodash/throttle";
import { useForkRef } from "hw/ui/utils";

// The amount of pixels before the edge to start scrolling
// This can be adjusted to taste
const initialBuffer = 150;

// Affects how quickly the auto-scrolling will increase. A higher amount means
// a faster auto-scroll.
const strengthMultiplier = 40;

type Props = {
  enabled: boolean;
  style?: Record<string, unknown>;
};

export const AutoScroller = React.forwardRef<HTMLElement, Props>(
  function AutoScroller(props, providedRef) {
    const { enabled, ...rest } = props;
    const autoScrollerRef = useAutoScroller(enabled);
    const ref = useForkRef(autoScrollerRef, providedRef);
    return <div {...rest} ref={ref} />;
  }
);

function useAutoScroller(enabled: boolean, eventType = "mousemove") {
  const ref = React.useRef<HTMLElement>(null);
  const scaleY = React.useRef(0);
  const scaleX = React.useRef(0);
  const frame = React.useRef<number | null>();
  const initialScrollBehavior = React.useRef<string | null>(null);

  React.useEffect(() => {
    function stopScrolling() {
      scaleY.current = 0;
      scaleX.current = 0;

      if (frame.current) {
        cancelAnimationFrame(frame.current);
        frame.current = null;
      }

      if (ref.current) {
        if (initialScrollBehavior.current) {
          ref.current.style.scrollBehavior = initialScrollBehavior.current;
          initialScrollBehavior.current = null;
        } else {
          ref.current.style.removeProperty("scroll-behavior");
        }
      }
    }

    function startScrolling() {
      let i = 0;
      const tick = () => {
        const container = ref.current;

        if (!container) {
          return;
        }

        // stop scrolling if there's nothing to do
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          strengthMultiplier === 0 ||
          (scaleY.current === 0 && scaleX.current === 0)
        ) {
          stopScrolling();
          return;
        }

        // there's a bug in safari where it seems like we can't get
        // mousemove events from a container that also emits a scroll
        // event that same frame. So we double the strengthMultiplier and only adjust
        // the scroll position at 30fps
        if (i++ % 2) {
          const {
            scrollTop,
            scrollHeight,
            clientHeight,
            scrollLeft,
            scrollWidth,
            clientWidth,
          } = container;

          if (scaleY) {
            container.scrollTop = intBetween(
              0,
              scrollHeight - clientHeight,
              scrollTop + scaleY.current * strengthMultiplier
            );
          }

          if (scaleX) {
            container.scrollLeft = intBetween(
              0,
              scrollWidth - clientWidth,
              scrollLeft + scaleX.current * strengthMultiplier
            );
          }
        }
        frame.current = requestAnimationFrame(tick);
      };

      tick();
    }

    const onScroll = throttle(
      (evt) => {
        if (!ref.current) return;
        const {
          left: x,
          top: y,
          width: w,
          height: h,
        } = ref.current.getBoundingClientRect();

        if (initialScrollBehavior.current === null) {
          const scrollBehavior = ref.current.style.scrollBehavior;
          initialScrollBehavior.current = scrollBehavior;
        }

        const box = { x, y, w, h };
        const coords = getCoords(evt);

        // calculate strength
        scaleY.current = verticalStrength(box, coords, "y");
        scaleX.current = verticalStrength(box, coords, "x");

        // Smooth scroll behavior seems to mess with the auto scroll
        // calculations
        ref.current.style.scrollBehavior = "auto";

        // start scrolling if we need to
        if (!frame.current && (scaleY.current || scaleX.current)) {
          startScrolling();
        }
      },
      100,
      { trailing: false }
    );

    const detach = () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener(eventType, onScroll, {
        capture: true,
        passive: true,
      });

    const attach = () =>
      window.addEventListener(eventType, onScroll, {
        capture: true,
        passive: true,
      });

    if (enabled) {
      attach();
    } else {
      stopScrolling();
      detach();
    }
    return () => {
      detach();
      stopScrolling();
      onScroll.cancel();
    };
  }, [enabled, eventType]);

  return ref;
}

function verticalStrength(
  { x, y, w, h }: { x: number; y: number; w: number; h: number },
  point: { x: number; y: number },
  axis: "x" | "y"
) {
  if (axis === "y") {
    const buffer = Math.min(h / 2, initialBuffer);

    if (point.y < y + buffer) {
      return (point.y - y - buffer) / buffer;
    } else if (point.y > y + h - buffer) {
      return -(y + h - point.y - buffer) / buffer;
    }

    return 0;
  } else if (axis === "x") {
    const buffer = Math.min(w / 2, initialBuffer);

    if (point.x < x + buffer) {
      return (point.x - x - buffer) / buffer;
    } else if (point.x > x + w - buffer) {
      return -(x + w - point.x - buffer) / buffer;
    }

    return 0;
  } else {
    return 0;
  }
}

function intBetween(min: number, max: number, val: number) {
  return Math.floor(Math.min(max, Math.max(min, val)));
}

function getCoords(evt: MouseEvent) {
  return { x: evt.clientX, y: evt.clientY };
}
