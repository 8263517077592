/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ErrorMessage from "hw/common/components/smartforms/common/error-message";
import MaskedInput from "./masked-input/masked-input";
import Label from "./common/label";

type Props = {
  errorMessage?: string; // Any validation errors
  handleVisited?: (...args: Array<any>) => any; // Used to track the field for validation
  helpText?: string; // The help bubble next to the label, if null there is no bubble
  hidden: boolean;
  id?: string; // Passed through to the masked input
  isValid?: boolean;
  label?: string; // Label attached to the input
  onChange?: (...args: Array<any>) => any;
  readOnly: boolean;
  required: boolean; // Is the input required
  value?: string;
};

const notANumber = /\D/;

/**
 * A component for displaying a phone number and it's relative metadata.
 */
export default class SIN extends React.Component<Props> {
  static defaultProps = {
    hidden: false,
    onChange: () => {},
    readOnly: false,
    required: false,
  };

  render() {
    const {
      errorMessage,
      handleVisited,
      helpText,
      hidden,
      id,
      isValid,
      label,
      onChange,
      readOnly,
      required,
      value,
    } = this.props;

    const error = !isValid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

    const token = " ";
    const mask = `${token}${token}${token}-${token}${token}${token}-${token}${token}${token}`;
    const maxLength = 9; // The number of tokens in mask

    const maskedInputProps = {
      attrs: {
        disabled: readOnly,
        id,
        pattern: "[0-9]*", // iphone number pad
        inputMode: "numeric", // android number pad
        "x-inputmode": "numeric", // firefox
      },
      invalidValueExp: notANumber,
      isBlockFormat: true,
      isInsertMode: true,
      isValid,
      mask,
      maxLength,
      onBlur: handleVisited,
      onChange,
      readOnly,
      token,
      value,
    };

    const labelComp = label && (
      <Label required={required} helpText={helpText}>
        {label}
      </Label>
    );

    return (
      !readOnly && (
        <div hidden={hidden}>
          {labelComp}
          <MaskedInput {...maskedInputProps} />
          {error}
        </div>
      )
    );
  }
}
