import * as React from "react";

export function SmallFile({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#prefix__filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8 4.5A1.5 1.5 0 016.3 3h11.4a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5H6.3a1.5 1.5 0 01-1.5-1.5v-15z"
          fill="#F7F9FA"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8 9.5a.5.5 0 01.5-.5h7.4a.5.5 0 01.5.5v.2a.5.5 0 01-.5.5H8.3a.5.5 0 01-.5-.5v-.2zm0 2.4a.5.5 0 01.5-.5h7.4a.5.5 0 01.5.5v.2a.5.5 0 01-.5.5H8.3a.5.5 0 01-.5-.5v-.2zm.5 1.9a.5.5 0 00-.5.5v.2a.5.5 0 00.5.5h3.2a.5.5 0 00.5-.5v-.2a.5.5 0 00-.5-.5H8.3z"
        fill="#637282"
      />
      <defs>
        <filter
          id="prefix__filter0_d"
          x={4.8}
          y={3}
          width={14.4}
          height={19}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0.858859 0 0 0 0 0.871766 0 0 0 0 0.884673 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
