/**
 * Form Builder Context
 *
 * There are certain fields in the form builder that need access to more global
 * properties that other fields do not need.  Examples:
 *
 *   - Paragraph components need the `ref` of the scroll container to know how
 *     tall to render
 *   - Group components need to know all of the fields and errors so that they
 *     can display info about conditional logic
 *
 * Because we render all fields recursively through the same handler, that means
 * we'd need to pass down those special props to all fields so that they can
 * ultimately make it to those special fields.
 *
 * Instead, we can put those global properties within context and have those
 * special fields read from the context as needed.
 */
import * as React from "react";
import globalLogger from "hw/common/utils/logger";
import type { Path } from "hw/common/types";
import type { Form, MergeField } from "hw/portal/modules/common/draft";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";

const logger = globalLogger.create("form-builder-context");
type StateContextValue = {
  previewPaneRef: HTMLElement | null | undefined;
  selectedFieldPath: Path | null | undefined;
  form: Form;
  errors: Array<DraftFeedback>;
  warnings: Array<DraftFeedback>;
  mergeFields: Array<MergeField>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal: (...args: Array<any>) => any;
} | null;
const initialState = {
  previewPaneRef: null,
  selectedFieldPath: null,
  errors: [],
  warnings: [],
  mergeFields: [],
  openMergeFieldsModal: () => {},
};
const StateContext = React.createContext<StateContextValue>(null);
// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
const DispatchContext = React.createContext();

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function reducer(state, action) {
  logger.debug(action);

  switch (action.type) {
    case "set-preview-pane-ref":
      return { ...state, previewPaneRef: action.payload };

    case "select-field":
      return { ...state, selectedFieldPath: action.payload };

    default:
      throw new Error(`Unknown action type '${action.type}'`);
  }
}

/**
 * A hook for dispatching an action to update the context state
 */
export function useFormBuilderDispatch() {
  const dispatch = React.useContext(DispatchContext);

  if (!dispatch) {
    throw new Error(
      "useFormBuilderDispatch must be used within the `Provider` component"
    );
  }

  return dispatch;
}

/**
 * A hook for reading the form builder state
 */
export function useFormBuilderState() {
  const state = React.useContext(StateContext);

  if (!state) {
    throw new Error(
      "useFormBuilderState must be used within the `Provider` component"
    );
  }

  return state;
}

/**
 * Custom Provider for the form builder context
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FormBuilderProvider(props: any) {
  const { form, errors, warnings, openMergeFieldsModal, mergeFields } = props;

  /* $FlowFixMe[speculation-ambiguous] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, dispatch] = React.useReducer<StateContextValue, any>(reducer, {
    ...initialState,
    form,
    errors,
    warnings,
  });
  const value = React.useMemo(
    () => ({
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...state,
      form,
      errors,
      warnings,
      openMergeFieldsModal,
      mergeFields,
    }),
    [state, form, errors, warnings, openMergeFieldsModal, mergeFields]
  );
  return (
    <StateContext.Provider value={value}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}
