import * as React from "react";
import cx from "classnames";
import styles from "./styled.module.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Row({ last, ...rest }: any) {
  const cn = cx(styles.row, last && styles["row--last"]);
  return <div {...rest} className={cn} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Line({ rulePosition, ...rest }: any) {
  const cn = cx(
    styles.line,
    rulePosition === "first" && styles["line--first"],
    rulePosition === "last" && styles["line--last"]
  );
  return <div {...rest} className={cn} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function BulletWrapper(props: any) {
  return <div {...props} className={styles.bulletWrapper} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function BulletIcon(props: any) {
  return <div {...props} className={styles.bulletIcon} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ErrorWrapper(props: any) {
  return <div {...props} className={styles.errorWrapper} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Error(props: any) {
  return <div {...props} className={styles.error} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function HighlightedText({ disabled, withDropdown, ...rest }: any) {
  const cn = cx(
    styles.highlightedText,
    disabled && styles["highlightedText--disabled"],
    withDropdown && styles["highlightedText--withDropdown"]
  );
  return <div {...rest} className={cn} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SelectWrapper(props: any) {
  return <div {...props} className={styles.selectWrapper} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Grid(props: any) {
  return <div {...props} className={styles.grid} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SelectorCell(props: any) {
  return <div {...props} className={styles.selectorCell} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ControlRuleContainer({ visible, ...rest }: any) {
  const cn = cx(
    styles.controlRuleContainer,
    visible && styles["controlRuleContainer--visible"]
  );
  return <div {...rest} className={cn} />;
}
