import * as React from "react";
import { createComponent, useStyle } from "hw/ui/style";
import Portal from "hw/ui/portal";
import theme from "hw/ui/theme";
import { DEFAULT_WIDTH, PORTAL_TYPE } from "../constants";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export function ModalPortal(props) {
  const { layer = "modal", extend, ...rest } = props;
  const cn = useStyle(
    "modal-portal",
    {
      position: "fixed",
      right: 0,
      left: 0,
      top: 0,
      width: "100vw",
      height: "100%",
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      zIndex: theme.layer[layer],
      paddingTop: "128px",
      paddingLeft: theme.space.md,
      paddingRight: theme.space.md,
    },
    extend
  );

  return <Portal {...rest} type={PORTAL_TYPE} className={cn} />;
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'width' implicitly has an 'any' ty... Remove this comment to see the full error message
export function Wrapper({ width, extend, onMount, layer = "modal", ...rest }) {
  const ref = React.useRef();
  const cn = useStyle(
    "modal-wrapper",
    {
      maxHeight: "80%",
      width: "100%",
      overflow: "hidden",
      maxWidth: width || DEFAULT_WIDTH,
      backgroundColor: theme.color.surfaceLight0,
      borderRadius: theme.corner.sm,
      boxShadow: theme.shadow[3],
      marginLeft: "auto",
      marginRight: "auto",
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      zIndex: theme.layer[layer],
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    extend
  );

  React.useEffect(() => {
    if (typeof onMount === "function") {
      onMount(ref.current);
    }
    // eslint-disable-next-line
  }, []);

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
  return <div {...rest} className={cn} ref={ref} />;
}

export const Body = React.forwardRef(function ModalBody(props, ref) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'extend' does not exist on type '{ childr... Remove this comment to see the full error message
  const { extend, ...rest } = props;

  const cn = useStyle(
    "modal-body",
    {
      paddingTop: theme.space.sm,
      paddingLeft: "24px",
      paddingRight: "24px",
      paddingBottom: "24px",
      overflow: "auto",
      flexGrow: 1,
    },
    extend
  );

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <div {...rest} className={cn} ref={ref} />;
});

export const Header = createComponent(function ModalHeader({ theme }) {
  return {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.space.md,
    paddingTop: theme.space.md,
    paddingRight: theme.space.ms,
    paddingLeft: "24px",
  };
});

export const ActionsFooter = createComponent(function ModalFooter() {
  return {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  };
});

export const Footer = createComponent(function ModalParentFooter({ theme }) {
  return {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.space.ms,
    backgroundColor: theme.color.gray025,
  };
});
