/* eslint-disable @typescript-eslint/ban-types */
/**
 * Handles the `HoverCard` state for the output field settings. This has some
 * extra complexity because it's handling the transition states. Specifically,
 * the case where there is no `OutputField` given but we want the menu to
 * transition to its closed state. In that scenario, we temporarily use a
 * cached version of the field while it's closing.
 *
 * Note that this doesn't explicitly handle the case where we want to swap one
 * field for another within an open hover card. Because there is only one
 * `HoverCard` for all fields, if we want to truly transition between two fields
 * we have to introduce some extra state and complexity. In practice it didn't
 * seem to make much of a UX difference, so it's not explicitly handled.
 */
import * as React from "react";
import onClickOutside from "react-onclickoutside";
import { HoverCard, useTargetObserver } from "hw/ui/hover-card";
import theme from "hw/ui/theme";
import type { OutputField } from "hw/portal/modules/common/draft";
import { isAnyModalOpen } from "hw/ui/modal";

type Props = {
  onClickOutside: Function;
  field: OutputField | null | undefined;
  children: (field: OutputField) => React.ReactNode;
  getElement: (fieldId: string) => HTMLElement | null | undefined;
};

export function OutputFieldSettingsPopover(props: Props) {
  const { field: nextField, children, onClickOutside, getElement } = props;
  const ref = React.useRef();

  // The state and effect setup are solely here for the purpose of being
  // able to do the exit transition on the `HoverCard`. We don't want the
  // `HoverCard` to immediately exit when the field is unselect, so we cache
  // it quickly while the transition is happening.
  const [state, setState] = React.useState(deriveState(null, null));
  const [target, setTarget] = React.useState(null);

  React.useLayoutEffect(() => {
    setState((state) => deriveState(state.field, nextField));
  }, [nextField]);

  const { field, isOpen } = state;

  React.useEffect(() => {
    if (field) {
      // @ts-expect-error refactor
      setTarget(getElement(field.id));
    }
  }, [field, getElement]);

  // @ts-expect-error refactor
  useTargetObserver(ref.current);

  if (!(field && target)) return null;

  return (
    <HoverCard
      key={field.id}
      anchor={target}
      active={isOpen}
      extendContentStyle={{ borderColor: theme.color.dividerDefault }}
      renderDest="portal"
      // @ts-expect-error refactor
      ref={ref}
      // After exiting, reset the state to its default idle state
      onExited={() => setState(deriveState(null, null))}
    >
      {/* @ts-expect-error refactor */}
      <ClickOutsideHandler
        handleClickOutside={() => {
          // Assume that if a modal is open and this output field is selected,
          // we don't want to unselect it immediately when clicking within
          // the modal
          return !isAnyModalOpen(document) && onClickOutside();
        }}
      >
        {children(field)}
      </ClickOutsideHandler>
    </HoverCard>
  );
}

const ClickOutsideHandler = onClickOutside(function ClickOutsideHandler(props) {
  // @ts-expect-error refactor
  return props.children;
});

// @ts-expect-error refactor
function deriveState(currentField, nextField) {
  if (nextField) {
    // If there is a next field provided, always use it. This will ensure the
    // `HoverCard` is never opened with the wrong field
    return {
      isOpen: true,
      field: nextField,
    };
  } else if (!nextField && currentField) {
    // If there is no next field but there is a current field, we're
    // closing the `HoverCard`. Temprorarily use the current field as the target
    // so that the exit transition can occur.
    return {
      isOpen: false,
      field: currentField,
    };
  } else {
    return {
      isOpen: false,
      field: null,
    };
  }
}
