import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { createComponent } from "hw/ui/style";
import { CaretDownIcon } from "hw/ui/icons";
import Theme from "hw/ui/theme";
import type { TriggerProps } from "./types";

type Props = TriggerProps & {
  children: React.ReactNode;
};

type FelaProps = {
  styles: {
    button: string;
  };
  theme: typeof Theme;
};

type InternalProps = FelaProps & Props;

/**
 * @one-off Button special button to style a button in a dropdown.
 * This could be one variation of the presentation prop in the button
 */
const Button = createComponent(
  function Button({ disabled, active, theme }) {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: theme.fontSize.ms,
      fontWeight: theme.fontWeight.normal,
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.xs,
      cursor: disabled ? "not-allowed" : "pointer",
      borderRadius: theme.corner.sm,
      color: active ? theme.color.white : theme.color.textStandard,
      backgroundColor: theme.color.gray100,
      border: "none",
      transition: "background-color 50ms, color 50ms",
      ":active": {
        outline: "none",
      },
      ":focus": {
        outline: active && "none",
      },
      ":hover": {
        backgroundColor: theme.color.gray300,
      },
      "> i": {
        display: "flex",
        marginLeft: theme.space.sm,
        fill: active ? theme.color.white : theme.color.textStandard,
      },
    };
  },
  "button",
  // Need to pass through all props here due to our strange trigger/dropdown
  // relationship.
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  (props) => Object.keys(props)
);

/**
 * This is similar to the `TextTrigger` in that it has text with a downward
 * facing caret, but it has a persistent background color instead of a
 * transparent background
 */
function ButtonTrigger(props: InternalProps) {
  const { children, triggerPassthroughProps, disabled } = props;
  const { getButtonProps } = triggerPassthroughProps;

  /**
   * @one-off Button The button style here should be one of our standard UI button
   variations.  We're inlining it here but eventually we should delegate
   to that component
   * @provisional
   */
  return (
    <Button
      {...getButtonProps()}
      disabled={disabled}
      data-test-ref="ui-text-trigger"
      {...getTestAttributes(props["data-guide-id"])}
    >
      {children}
      <i>
        <CaretDownIcon />
      </i>
    </Button>
  );
}

export default ButtonTrigger;
