/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { EditorView } from "prosemirror-view";
import type { Schema } from "prosemirror-model";
import { Node } from "prosemirror-model";
import { EditorState, Transaction } from "prosemirror-state";
import flatten from "lodash/flatten";
import throttle from "lodash/throttle";
import type { Plugin } from "prosemirror-state";
import type { MergeField } from "hw/portal/modules/common/draft";
import Toolbar from "./toolbar";

import schema from "../schema";
import * as plugins from "../plugins";
import RichTextContainer from "../../../common/container";
import * as MergeFieldPlugin from "../plugins/merge-fields";
import * as DataPlugin from "../plugins/data";
import * as LinkPlugin from "../plugins/link";
import * as Styled from "./styled";
import "./editor.css";

type Props = {
  /**
   * This is injected from the merge field plugin.  It's used to instantiate
   * the plugin and returns the plugins to be provided to ProseMirror
   */
  createMergeFieldPlugin: (schema: Schema) => Array<Plugin>;

  /**
   * The default value for the editor.  This should be a JSON structure conforming
   * to the defined schema.
   */
  defaultValue?: Node;

  /**
   * Indicates whether the editor is currently "active" or not.  When active,
   * the menu toolbar will display
   */
  isActive?: boolean;

  /**
   * The max height for the editor.
   */
  maxHeight: number;

  /**
   * A list of merge fields to be provided to the merge field plugin
   */
  mergeFields: Array<MergeField>;

  /**
   * Called with the new value whenever changes are made.  For performance reasons, this
   * is automatically debounced
   */
  onChange: (newDoc: Node) => void;

  /**
   * Called when the "Edit merge fields" option is selected from the
   * merge field plugin
   */
  onEditMergeFields: (...args: Array<any>) => any;

  /**
   * Called when a merge field is selected
   */
  onInsertMergeField: (mergeField: MergeField) => void;

  /**
   * Called when the view is created with the view instance.
   */
  onCreated: (view: EditorView) => void;

  /**
   * Since the paragraph keeps its own state, it can be necessary
   * to sometimes 're-sync' the state so that it's in sync with the parent
   * value.  For example, if a change is undone we need to reset the paragraph
   * to a new state.
   */
  onResync: (...args: Array<any>) => any;

  /**
   * The new editor has some _slight_ adjustments to how the toolbar is rendered
   * and rather than introduce another abstraction, these props are used to
   * augment the toolbar for the v2 context. Once we've consolidated the
   * editors, we can bake these into the editor component
   * TODO: Remove this as options
   */
  v2Options?: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    extendToolbar?: {};
    shouldAnimateToolbar?: boolean;
    extraToolbarContent?: React.ReactNode;
  };
};

type State = {
  view?: EditorView;
  editor?: EditorState;
  isScrolled: boolean;
};

const keymapsByName = plugins.keymapsByName(schema);
const inputRulesByName = plugins.inputRulesByName(schema);

/**
 * React component that handles the ProseMirror editor for `Paragraph`
 * components
 */
class ParagraphEditor extends React.Component<Props, State> {
  view: EditorView | null | undefined;

  scrollContainer: HTMLElement | null | undefined;

  // @ts-expect-error refactor
  unsubscribe: (...args: Array<any>) => any;

  static defaultProps = {
    onChange: () => {},
  };

  state = {
    editor: undefined,
    isScrolled: false,
  };

  // Order matters!
  plugins = flatten([
    plugins.bindInputRules(inputRulesByName),
    DataPlugin.create(),
    this.props.createMergeFieldPlugin(schema),
    LinkPlugin.create(schema),
    plugins.bindKeymaps(keymapsByName),
    plugins.dropCursor(),
    plugins.gapCursor(),
    plugins.history(),
  ]);

  /**
   * Creates the ProseMirror editor view & state
   */
  createEditor(node: HTMLElement) {
    const { defaultValue } = this.props;

    const state = EditorState.create({
      schema,
      doc: deserializeState(defaultValue),
      plugins: this.plugins,
    });

    this.view = new EditorView(node, {
      state,

      /* $FlowFixMe[value-as-type] $FlowFixMe This comment suppresses an error
       * found when upgrading Flow to v0.132.0. To view the error, delete this
       * comment and run Flow. */
      dispatchTransaction: (transaction: Transaction) => {
        if (this.view) {
          this.onTransaction(this.view, transaction);
        }
      },
    });

    if (typeof this.props.onCreated === "function") {
      this.props.onCreated(this.view);
    }

    this.setState({ editor: state });
  }

  resyncDoc = () => {
    if (!this.view) return;
    const { state, updateState } = this.view;

    // @ts-expect-error refactor
    if (!(state && updateState && schema)) return;

    const newState = EditorState.create({
      schema: this.view.state.schema,
      doc: deserializeState(this.props.defaultValue),
      plugins: this.view.state.plugins,
    });

    this.view.updateState(newState);

    this.setState({ editor: newState });
  };

  /**
   * Handles the editor `ref` by either creating a new ProseMirror view or
   * destroying it if it already exists
   */
  handleEditorRef = (node: HTMLElement | null | undefined) => {
    const { view } = this;

    if (node && !view) {
      this.createEditor(node);
    } else if (view && !node) {
      view.destroy();
      this.view = undefined;
    }
  };

  /**
   * Called with each transaction dispatched on the ProseMirror view.  This is
   * where the ProseMirror state is synced to the React state
   */
  onTransaction(view: EditorView, transaction: Transaction) {
    const newState = view.state.apply(transaction);
    view.updateState(newState);

    if (transaction.docChanged) {
      // @ts-expect-error refactor
      this.props.onChange(serializeState(view.state));
    }

    this.setState({ editor: newState });
  }

  componentDidMount() {
    if (this.props.isActive && this.view) {
      this.view.focus();
    }

    this.unsubscribe = this.props.onResync(this.resyncDoc);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  // @ts-expect-error refactor
  componentDidUpdate(prevProps) {
    // Move from active to inactive, scroll the container to the top so users
    // always see the top of their document
    if (!this.props.isActive && prevProps.isActive && this.scrollContainer) {
      this.scrollContainer.scrollTop = 0;
    }

    if (!prevProps.isActive && this.props.isActive && this.view) {
      this.view.focus();
    }
  }

  // @ts-expect-error refactor
  handleScrollContainerRef = (node) => {
    this.scrollContainer = node;
  };

  handleScroll = throttle(
    () => {
      const el = this.scrollContainer;
      if (!el) return;
      const isScrolled = el.scrollTop > 0;

      if (isScrolled !== this.state.isScrolled) {
        this.setState({ isScrolled });
      }
    },
    100,
    { leading: true }
  );

  render() {
    const { maxHeight, mergeFields, isActive, v2Options = {} } = this.props;

    return (
      <Styled.EditorContainer isActive={isActive}>
        {this.view && (
          // @ts-expect-error refactor
          <Toolbar
            isActive={isActive}
            view={this.view}
            onInsertMergeField={MergeFieldPlugin.insertQuery}
            onCreateLink={LinkPlugin.toggleLink}
            canLink={LinkPlugin.canLink}
            keymaps={keymapsByName}
            inputRules={inputRulesByName}
            v2Options={v2Options}
            isScrolled={this.state.isScrolled}
          />
        )}
        <Styled.ScrollContainer
          isActive={isActive}
          innerRef={this.handleScrollContainerRef}
          maxHeight={maxHeight}
          onScroll={this.handleScroll}
        >
          <RichTextContainer>
            <div key="editor" ref={this.handleEditorRef} data-testid="editor" />
          </RichTextContainer>
        </Styled.ScrollContainer>
        {this.view && (
          <React.Fragment>
            <MergeFieldPlugin.Suggestions
              view={this.view}
              mergeFields={mergeFields}
              editorActive={isActive}
            />
            <LinkPlugin.EditWindow view={this.view} editorActive={isActive} />
          </React.Fragment>
        )}
      </Styled.EditorContainer>
    );
  }
}

/**
 * Deserializes a store JSON value into a ProseMirror document
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function deserializeState(value?: {}) {
  if (!value) {
    return undefined;
  }

  const doc = schema.nodeFromJSON(value);

  // Validate that the document confirms to the schema, otherwise throw
  doc.check();

  return doc;
}

/**
 * Serializes the ProseMirror state
 */
function serializeState(state: EditorState) {
  return state.doc.toJSON();
}

export default MergeFieldPlugin.withSuggestionsState(ParagraphEditor);
