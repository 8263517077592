/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { PluginKey, Plugin } from "prosemirror-state";
import { inputRules, InputRule } from "prosemirror-inputrules";
import { merge } from "timm";
import { keymap } from "prosemirror-keymap";
import type { Schema } from "prosemirror-model";

import * as utils from "../../utils";

type Props = {
  keymap: {};
  schema: Schema;
};

export const key = new PluginKey("mergeFieldPlugin");

const leafNodeReplacementCharacter = "\ufffc";

const triggerCharacter = "{";

const queryInputRule = (schema) => {
  const regex = new RegExp(
    // eslint-disable-next-line security-node/non-literal-reg-expr
    `(^|[\\s(${leafNodeReplacementCharacter}])${triggerCharacter}$`
  );
  const rule = new InputRule(regex, (state, _match, _start, _end) => {
    const mark = schema.mark("mergeFieldQuery");
    const { tr } = state;
    const mergeFieldLabel = schema.text("{", [mark]);

    return tr.replaceSelectionWith(mergeFieldLabel, false);
  });

  return rule;
};

export function createPlugin(props: Props) {
  return [
    inputRules({
      rules: [queryInputRule(props.schema)],
    }),
    keymap(props.keymap),
    new Plugin({
      key,
      state: {
        init(_config, _state) {
          return {
            active: false,
            query: "",
          };
        },
        apply(tr, prevPluginState, prevState, state) {
          const { mergeFieldQuery } = state.schema.marks;
          const { doc, selection } = state;
          const { from, to } = selection;

          function hasActiveQueryNode(node) {
            const mark = mergeFieldQuery.isInSet(node.marks);
            return mark && mark.attrs.active;
          }

          if (
            utils.rangeHasNodeMatchingQuery(
              doc,
              from - 1,
              to,
              hasActiveQueryNode
            )
          ) {
            const { nodeBefore } = selection.$from;
            const query = ((nodeBefore && nodeBefore.textContent) || "").substr(
              1
            );

            return merge(prevPluginState, {
              active: true,
              query,
            });
          } else {
            return merge(prevPluginState, {
              active: false,
              query: "",
            });
          }
        },
      },
    }),
  ];
}
