/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import "./address-group.css";
import React, { Children, cloneElement } from "react";
import { getIn } from "timm";
import cx from "classnames";
import Label from "./common/label";

type Props = {
  children?: [];
  // Children as a config, used in builder.
  helpText?: string;
  isLegacy: boolean; // This is for a legacy address configuration that lacks the address 2 input.
  onMount?: (...args: Array<any>) => any; // Function to run when the component mounts
  readOnly?: boolean;
  required?: boolean;
  label?: string;
  field?: {
    // Used in smart forms.
    children?: [];
  };
  updateDataRef?: (...args: Array<any>) => any;
  value?: string;
  onChange?: (newValue: string) => void;
};

export default class AddressGroup extends React.Component<Props> {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  render() {
    const {
      children,
      helpText,
      isLegacy,
      readOnly = false,
      required = false,
      label,
      value,
      onChange,
    } = this.props;

    const addressTextInputs = Children.map(children, (child, index) => {
      // We pass the default value of an address group to the first
      // child
      const valueProp =
        index === 0 && value && onChange ? { value, onChange } : {};

      const newProps = {
        childIndex: index,
        readOnly,
        classNames: cx(
          getChildClass(index + (isLegacy ? 1 : 0)),
          child.props.classNames
        ),
        ...valueProp,

        /**
         * Address group child components will be specially formatted to be
         * compact, without help text and without the required indicator.
         */
        labelProps: {
          appearance: "compact",
          helpText: null,
          required: false,
        },
      };

      const childProps = Object.assign({}, child.props, newProps);
      return cloneElement(child, childProps);
    });

    const labelComp = label && (
      <Label required={required} helpText={helpText}>
        {label}
      </Label>
    );

    return (
      <div
        className="m-sf--address-group"
        role="form"
        aria-atomic="true"
        aria-label={label}
      >
        {labelComp}
        {addressTextInputs}
      </div>
    );
  }
}

function getChildClass(index) {
  switch (index) {
    case 0:
      return "m-sf--address-group--line-1";
    case 1:
      return "m-sf--address-group--line-2";
    case 2:
      return "m-sf--address-group--city";
    case 3:
      return "m-sf--address-group--state";
    case 4:
      return "m-sf--address-group--zip";
    default:
      return "m-sf--address-group--extra";
  }
}

/**
 * The legacy version of address does not have the address 2 input. So if we have the select state drop down at
 * index 2 then we are using a legacy address.
 */
export function isLegacy(children: Array<any> | null | undefined) {
  return getIn(children, [2, "type"]) === "SelectList";
}
