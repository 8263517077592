import * as React from "react";

export default function Bold() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="bold-icon-24-a"
          d="M15.6 11.79c.965-.675 1.65-1.765 1.65-2.79 0-2.255-1.745-4-4-4H8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6.04c2.095 0 3.71-1.7 3.71-3.79 0-1.52-.865-2.815-2.15-3.42zM10 7.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#bold-icon-24-a" />
      </g>
    </svg>
  );
}
