import * as React from "react";

export default function Strikethrough() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="strikethrough-icon-24-a"
          d="M11 20h2a1 1 0 0 0 1-1v-2h-4v2a1 1 0 0 0 1 1zM5 6v1a1 1 0 0 0 1 1h4v3h4V8h4a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1zm-1 9h16a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#strikethrough-icon-24-a" />
      </g>
    </svg>
  );
}
