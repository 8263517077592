// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import get from "lodash/get";
import Checkbox from "hw/ui/checkbox";
import Radio from "hw/ui/radio";
import { withTheme } from "hw/ui/style";
import styles from "../multiple-choice.module.css";
import type { PresentationProps } from "../types";

type Props = PresentationProps;

export class MultipleChoiceList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: React.SyntheticEvent<HTMLInputElement>): void {
    const value = get(e, ["currentTarget", "value"]);

    if (value) {
      this.props.onSelect(value);
    }
  }

  render() {
    const {
      allowMultiple,
      isSelected,
      options,
      onSelect,
      disabled,
      qaRef,
      id,
    } = this.props;

    return (
      <div data-qa-ref={qaRef} className={styles.container} id={id} role="menu">
        {options.map((option, index) =>
          allowMultiple ? (
            <Checkbox
              key={`${option}-${index}`}
              label={option}
              checked={isSelected(option)}
              disabled={disabled}
              onChange={(e) => onSelect(e.currentTarget.value)}
              name={option}
              value={option}
            />
          ) : (
            <Radio
              key={`${option}-${index}`}
              label={option}
              checked={isSelected(option)}
              disabled={disabled}
              onChange={(e) => onSelect(e.currentTarget.value)}
              name={option}
              value={option}
            />
          )
        )}
      </div>
    );
  }
}

export default withTheme(MultipleChoiceList);
