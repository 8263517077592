import React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import Select from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import styles from "./document-delivery-selector.module.css";

type Props = {
  onChange: (selectedType: "link" | "attachment") => void;
  selectedOption: string;
  allowedDocumentDeliveryTypes: ["link"] | ["attachment", "link"];
};

function DocumentDeliverySelector(props: Props) {
  const { onChange, selectedOption, allowedDocumentDeliveryTypes } = props;

  const options = [
    { label: "file attachments", value: "attachment" },
    { label: "unique link", value: "link" },
  ].filter((option) => {
    // @ts-expect-error refactor
    return allowedDocumentDeliveryTypes.includes(option.value);
  });

  const selectedItem =
    options.find((element) => element.value === selectedOption) || options[0];

  return (
    <div data-testid="document-delivery-selector" className={styles.container}>
      <label className={styles.label}>
        Email completed documents to participants via
      </label>
      <div className={styles.selectorContainer}>
        <Select
          fillContainer
          // @ts-expect-error refactor
          onChange={(item) => {
            if (item) onChange(item.value);
          }}
          triggerProps={{ compacted: true }}
          placeholder="Select document delivery option..."
          {...getTestAttributes("document-delivery-selector")}
          itemToString={(option) => (option ? option.label : null)}
          // @ts-expect-error refactor
          selectedItem={selectedItem || null}
          render={({ getItemProps, highlightedIndex }) => (
            <MenuList>
              {options.map((option, index) => (
                <MenuItem
                  key={option.value}
                  active={index === highlightedIndex}
                  passthroughProps={getItemProps({
                    item: option,
                  })}
                >
                  <MenuItemText extend={{ fontSize: "14px" }}>
                    {option.label}
                  </MenuItemText>
                </MenuItem>
              ))}
            </MenuList>
          )}
        />
      </div>
    </div>
  );
}

export default DocumentDeliverySelector;
