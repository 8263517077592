import * as React from "react";
import { forwardRef } from "react";
import { invariant } from "hw/common/utils/assert";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import Button from "hw/ui/button";
import IconButtonBase, { MenuCaret, MoreVerticalIcon } from "hw/ui/icons";

/**
 * Text Trigger
 * ------------
 */

type TextTriggerProps = {
  disabled?: boolean;
  children: React.ReactNode;
  isOpen?: boolean;
};

function TextTrigger(
  props: TextTriggerProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { disabled, children, isOpen, ...rest } = props;

  const iconCn = useStyle("text-menu-btn-icon", {
    display: "flex",
    marginLeft: theme.space.sm,
    transitionProperty: "all",
    transitionDuration: theme.transitionSpeed.fast,
    // eslint-disable-next-line no-nested-ternary
    color: disabled
      ? theme.color.iconDisabled
      : isOpen
      ? theme.color.iconPressed
      : theme.color.iconDefault,
  });

  return (
    <Button
      {...rest}
      ref={ref}
      disabled={disabled}
      presentation="subtle"
      extend={{
        color: theme.color.textStandard,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        justifyContent: "space-between",
        backgroundColor: isOpen
          ? theme.color.uiDropdownTriggerActive
          : "transparent",
        ":hover": {
          color: theme.color.textStandard,
          "> span": {
            color: theme.color.iconHover,
          },
        },
      }}
    >
      {children}
      <span aria-hidden="true" className={iconCn}>
        <MenuCaret />
      </span>
    </Button>
  );
}

const _TextTrigger = forwardRef<HTMLButtonElement, TextTriggerProps>(
  TextTrigger
);

export { _TextTrigger as TextTrigger };

/**
 * Icon Trigger
 * ------------
 */
type IconTriggerProps = {
  children?: React.ReactNode;
  "aria-label": string;
  isOpen: boolean;
};

function IconTrigger(
  props: IconTriggerProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { isOpen, ...rest } = props;

  // TODO: This should be in all of our icon-only buttons
  invariant(
    props["aria-label"],
    `You must provide an aria-label prop for icon only buttons`
  );

  return (
    <IconButtonBase
      {...rest}
      ref={ref}
      extend={{
        color: isOpen ? theme.color.textStandard : theme.color.iconDefault,
        backgroundColor: isOpen
          ? theme.color.uiDropdownTriggerActive
          : "transparent",
      }}
    >
      {props.children ?? <MoreVerticalIcon />}
    </IconButtonBase>
  );
}

const _IconTrigger = forwardRef<HTMLButtonElement, IconTriggerProps>(
  IconTrigger
);

export { _IconTrigger as IconTrigger };
