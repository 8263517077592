import React from "react";
import theme from "hw/ui/theme";

type Props = {
  color?: keyof typeof theme.color;
};

export function Arrow(props: Props) {
  const { color = theme.color.iconDefault } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 8L13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L13 8ZM3 7C2.44772 7 2 7.44771 2 8C2 8.55228 2.44771 9 3 9L3 7ZM9.70711 3.29289C9.31658 2.90237 8.68342 2.90237 8.29289 3.29289C7.90237 3.68342 7.90237 4.31658 8.29289 4.70711L9.70711 3.29289ZM8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L8.29289 11.2929ZM13 7L3 7L3 9L13 9L13 7ZM8.29289 4.70711L12.2929 8.70711L13.7071 7.29289L9.70711 3.29289L8.29289 4.70711ZM12.2929 7.29289L8.29289 11.2929L9.70711 12.7071L13.7071 8.70711L12.2929 7.29289Z"
        fill={color}
      />
    </svg>
  );
}
