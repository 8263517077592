import * as React from "react";
import { Box } from "hw/ui/blocks";
import { createComponent } from "hw/ui/style";
import IconWrapper, { ArrowRight, EditIcon } from "hw/ui/icons";
import { Tooltip } from "hw/ui/tooltip";
import type { Form, Role } from "hw/portal/modules/common/draft";
import RoleBadge from "./role-badge";
import * as utils from "../../utils";

const Container = createComponent(function FormParticipantsContainer({
  theme,
}) {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.space.md,
    marginBottom: theme.space.md,
  };
});
const Title = createComponent(function Title({ theme }) {
  return {
    fontSize: theme.fontSize.sm,
    fontWeight: theme.fontWeight.semibold,
    letterSpacing: "1px",
    color: theme.color.gray700,
    textTransform: "uppercase",
    marginRight: theme.space.sm,
  };
});
type Props = {
  form: Form;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeOrder: (...args: Array<any>) => any;
  roleDescriptions: Record<
    Role["id"],
    {
      title: string;
      badge: string;
    }
  >;
  roles: Array<Role>;
};

function FormParticipants(props: Props) {
  const { roles, form, onChangeOrder, roleDescriptions } = props;
  const roleOrder = utils.formRoleOrder(roles, form).map((role, idx, arr) => {
    // @ts-expect-error refactor
    const desc = roleDescriptions[role.id];
    const badge = (
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      <RoleBadge key={role.id} title={desc.title} hasBorder>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {desc.badge}
      </RoleBadge>
    );
    const isLast = idx === arr.length - 1;
    // TODO: Text truncation!
    return (
      // @ts-expect-error refactor
      <React.Fragment key={role.id}>
        {badge}
        {isLast ? null : (
          <Box ml="xs" mr="xs">
            <ArrowRight />
          </Box>
        )}
      </React.Fragment>
    );
  });
  const displayEditAction = roleOrder.length > 1;
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: a... Remove this comment to see the full error message
    <Container>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: s... Remove this comment to see the full error message */}
      <Title>Form Participants</Title>
      {roleOrder}
      {displayEditAction ? (
        <Tooltip tip="Change order" position="top">
          <Box ml="sm">
            <IconWrapper onClick={onChangeOrder}>
              <EditIcon />
            </IconWrapper>
          </Box>
        </Tooltip>
      ) : null}
    </Container>
  );
}

export default FormParticipants;
