import * as React from "react";
import cx from "classnames";
import Label from "./common/label";
import styles from "./calculation.module.css";

type Props = {
  value?: string | number | React.ReactNode;
  label?: React.ReactNode;
  helpText?: string;
  hidden?: boolean;
};

export function Calculation(props: Props) {
  const { value, label, helpText, hidden } = props;

  const pendingValue = React.useMemo(
    () =>
      value === undefined ||
      value === null ||
      (typeof value === "number" && Number.isNaN(value)),
    [value]
  );

  return (
    <div hidden={hidden} aria-hidden={hidden} className={styles.wrapper}>
      {label && <Label helpText={helpText}>{label}</Label>}
      <div className={cx(styles.result, pendingValue && styles.pending)}>
        {pendingValue ? "..." : value}
      </div>
    </div>
  );
}
