import React from "react";

export default function SuccessIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="24" />
        <rect width="16" height="16" x="4" y="4" fill="#24B34A" rx="8" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M1.7229411,5.21432814 C1.34137946,4.81505458 0.708385691,4.80069455 0.309109955,5.18225412 C-0.0901657805,5.56381369 -0.104525889,6.19680402 0.277035756,6.59607758 L3.23451089,9.6908374 C3.68417395,10.1613736 4.45637619,10.0837757 4.80342535,9.53317858 L9.84584473,1.53332752 C10.1403333,1.06611826 10.0003135,0.448641437 9.53310174,0.154154428 C9.06588995,-0.140332581 8.44840977,-0.00031353459 8.15392116,0.466895721 L3.79492351,7.38249105 L1.7229411,5.21432814 Z"
          transform="translate(7 7)"
        />
      </g>
    </svg>
  );
}
