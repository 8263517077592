import * as React from "react";
import { Box } from "hw/ui/blocks";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function SmallHeading(props: any) {
  const cn = useStyle("small-heading", {
    fontWeight: theme.fontWeight.semibold,
    fontSize: theme.fontSize.sm,
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: theme.color.textDim,
    lineHeight: theme.fontSize.md,
  });

  return <Box as="h2" {...props} className={cn} />;
}
