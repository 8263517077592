import * as React from "react";
import memoize from "lodash/memoize";
import { Box } from "hw/ui/blocks";
import { getIn } from "timm";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import type {
  Form,
  Role,
  Mapping,
  MergeField,
} from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import * as Styled from "../styled";
import Group from "./group";
import { Large as NewComponentDropdown } from "./component-menu/dropdown";
import FormParticipants from "./form-participants";
import ScrollContainer from "./scroll-container";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formBuilderDispatch: any;
  form: Form;
  roles: Array<Role>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCreate: (path: Path, type: string, attrs?: {}) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openRoleModal: (...args: Array<any>) => any;
  roleDescriptions: Record<
    Role["id"],
    {
      title: string;
      badge: string;
    }
  >;
  path: Path;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unselectField: (...args: Array<any>) => any;
  forms: Array<Form>;
  mapping: Mapping;
  mergeFields: Array<MergeField>;
  selectedFieldPath: Path | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: any;
  onCreateMergeField: (mergeField: MergeField) => void;
  isPaid: boolean;
};
export default class FormPreview extends React.PureComponent<Props> {
  /**
   * This is memoized so that we don't needlessly break reference equality in the
   * array value when re-rendering
   */

  /* $FlowFixMe[missing-annot] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  getPath = memoize(
    (path: Path) => path,
    (path) => path.join(".")
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlePreviewPaneRef = (node: any) => {
    this.props.formBuilderDispatch({
      type: "set-preview-pane-ref",
      payload: node,
    });
  };

  render() {
    const {
      form,
      onCreate,
      openRoleModal,
      roles,
      roleDescriptions,
      unselectField,
      selectedFieldPath,
      isPaid,
      ...rest
    } = this.props;
    const path = this.getPath([...this.props.path, "fields"]);
    const fields = getIn(form, ["fields"]) || [];
    // TODO: The roles for this specific form need to be more than 1
    const shouldDisplayParticipants = roles.length > 1;
    return (
      <ScrollContainer
        innerRef={this.handlePreviewPaneRef}
        onClick={unselectField}
      >
        <Styled.Canvas>
          <Styled.PreviewContainer
            className="m-p-preview"
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; "data-guide-id": stri... Remove this comment to see the full error message
            p="md"
            flex={1}
            mx="auto"
            {...getTestAttributes("editor-build-preview-container")}
          >
            <Box mb="xl">
              <Group
                {...rest}
                formId={form.id}
                // @ts-expect-error refactor
                fields={fields}
                path={path}
                onCreate={onCreate}
                roles={roles}
                roleDescriptions={roleDescriptions}
                order=""
                selectedFieldPath={selectedFieldPath}
                showRoleBadge={shouldDisplayParticipants}
                isPaid={isPaid}
              />
            </Box>
            <NewComponentDropdown
              // @ts-expect-error refactor
              path={this.getPath([...path, fields.length])}
              onSelect={onCreate}
              // @ts-expect-error refactor
              initialIsOpen={fields.length === 0}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: Path; onSelect: (path: Path, type: s... Remove this comment to see the full error message
              isPaid={isPaid}
            />
          </Styled.PreviewContainer>
          {shouldDisplayParticipants ? (
            <FormParticipants
              roles={roles}
              form={form}
              roleDescriptions={roleDescriptions}
              onChangeOrder={openRoleModal}
            />
          ) : null}
        </Styled.Canvas>
      </ScrollContainer>
    );
  }
}
