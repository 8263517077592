import * as React from "react";

export default function LinkBreak() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12.723 8.655a1 1 0 1 1-1.446-1.383l1.014-1.06a3.926 3.926 0 0 1 5.485-.183 3.766 3.766 0 0 1 .239 5.32l-.086.092-2.208 2.258a1 1 0 1 1-1.43-1.398l2.207-2.258.04-.043a1.766 1.766 0 0 0-.111-2.495 1.926 1.926 0 0 0-2.69.09l-1.014 1.06zM4.5 9a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zM8 4.5a.5.5 0 0 1 1 0v1a.5.5 0 0 1-1 0v-1zm-3.854.354a.5.5 0 1 1 .708-.708l2 2a.5.5 0 1 1-.708.708l-2-2zM19.5 15a.5.5 0 1 1 0 1h-1a.5.5 0 1 1 0-1h1zM16 19.5a.5.5 0 1 1-1 0v-1a.5.5 0 1 1 1 0v1zm3.854-.354a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 .708-.708l2 2zm-8.573-3.791a1 1 0 1 1 1.44 1.387l-1.012 1.052a3.934 3.934 0 0 1-5.48.184 3.751 3.751 0 0 1-.254-5.3l.091-.097 2.223-2.278a1 1 0 0 1 1.432 1.396l-2.223 2.279-.043.045a1.751 1.751 0 0 0 .119 2.474 1.934 1.934 0 0 0 2.694-.09l1.013-1.052z"
      />
    </svg>
  );
}
