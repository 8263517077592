/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Banner from "hw/ui/banner";
import ReactPDF from "hw/portal/modules/common/react-pdf";
import Loader from "hw/ui/loader";
import { Text, Flex } from "hw/ui/blocks";
import { useLazyTimeout } from "hw/common/utils/lazy";
import theme from "hw/ui/theme";
import { useStyle } from "hw/ui/style";

/**
 * Pre-configured ReactPDF.Page with loading and error states
 */
export const PdfPage = React.memo(function PdfPage(props: any) {
  const pdfCn = useStyle("pdf-page", {
    "> canvas": {
      boxShadow: theme.shadow[2],
    },
  });
  const [shouldRenderTextLayer, onLoadSuccess] = useConditionalTextLayer(
    props.onLoadSuccess
  );

  return (
    <ReactPDF.Page
      className={pdfCn}
      loading={<PdfLoading />}
      error={<PdfError />}
      renderAnnotationsLayer={false}
      {...props}
      onLoadSuccess={onLoadSuccess}
      renderTextLayer={shouldRenderTextLayer}
    />
  );
});

export function PdfDocument(props: any) {
  return <ReactPDF.Document loading={<PdfLoading />} {...props} />;
}

export function PdfLoading() {
  const isPastDelay = useLazyTimeout();

  if (!isPastDelay) return null;

  return (
    <Flex p="ms" justifyContent="center" flexDirection="column" width={1}>
      <Loader />
      <Text color="textDim" textAlign="center">
        Loading PDF...
      </Text>
    </Flex>
  );
}

export function PdfError() {
  return (
    <Banner type="danger">
      There was an error loading your PDF document. This has been reported to
      our engineering team.
    </Banner>
  );
}

/**
 * The text layer for `react-pdf` has the potential to be very performance
 * intensive. For each item in the text layer, an HTML element needs to be
 * rendered into the DOM. In some PDFs, there can be over 10,000 DOM
 * elements to render which brings the browser to crawl. For that reason,
 * we will render the text layer asynchronously and _only_ if there are fewer
 * than a certain number of text layer items.
 */
const TEXT_LAYER_UPPER_THRESHOLD = 300;

function useConditionalTextLayer(cb: any) {
  const [renderTextLayer, setRenderTextLayer] = React.useState(false);
  const [page, setPage] = React.useState(null);
  const onLoadSuccess = React.useCallback(
    (page) => {
      setPage(page);
      if (typeof cb === "function") {
        cb(page);
      }
    },
    [cb]
  );

  React.useEffect(() => {
    if (!page) return;

    let didCancel = false;
    let frame: number;

    async function getTextItems() {
      try {
        // @ts-expect-error refactor
        const textContent = await page.getTextContent();
        const itemCount = textContent.items.length;

        if (itemCount < TEXT_LAYER_UPPER_THRESHOLD && !didCancel) {
          // Reqeust animation frame for an extra rendering boost on the
          // rest of the page. This is a low-priority render.
          frame = requestAnimationFrame(() => setRenderTextLayer(true));
        }

        return textContent;
      } catch (err) {
        // Considering the text layer a secondary feature, so if it fails for
        // some reason just continue without it.
      }
    }

    getTextItems();

    return () => {
      didCancel = true;
      cancelAnimationFrame(frame);
    };
  }, [page]);

  return [renderTextLayer, onLoadSuccess];
}
