/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { useStyle } from "hw/ui/style";
import { Box, Flex, Text } from "hw/ui/blocks";
import { getIn } from "timm";
import Button from "hw/ui/button";
import theme from "hw/ui/theme";
import UISelect from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import { VisuallyHidden } from "hw/ui/text";
import Toggle from "hw/ui/toggle";
import type {
  PdfMapping as PdfMappingType,
  Role,
  ComponentOutputInstance,
  PageMetadata,
  MultipleChoiceField,
  AddressGroupField,
} from "hw/portal/modules/common/draft";
import { useFlag } from "hw/portal/modules/session";
import { OutputField } from "hw/portal/modules/common/draft/schema-ops";
import type { Path } from "hw/common/types";
import { useUpdateEffect } from "hw/common/hooks";
import { Input as UIInput } from "hw/ui/input";
import CodeEditor from "hw/portal/modules/draft-editor/pdf-mapper/components/code-editor";
import * as componentMenuIcons from "hw/portal/modules/draft-editor/form-builder/components/preview/component-menu/icons";
import Tooltip from "hw/ui/tooltip";
import IconWrapper, { DeleteIcon, LocateIcon, WarningIcon } from "hw/ui/icons";
import { invariant } from "hw/common/utils/assert";
import { Arrangement, arrange, Group } from "hw/ui/document-mapper";
import type { ArrangementType } from "hw/ui/document-mapper";
import { getReferringCalculations } from "hw/portal/modules/draft-editor/utils";
import { useBuilderContext } from "../context";
import * as Actions from "./actions";
import SmallHeading from "../../components/small-heading";
import * as Mapping from "../mapping";
import { AssignTo, FieldSet, InputType } from "../field-settings";
import { canReassign, canChangeInputType } from "../../display-rules";
import { toFieldRect, fromFieldRect } from "./utils";

const { AddressFormats, Types: MappingTypes, ValueExpr } = Mapping;

type Props = {
  mapping: PdfMappingType;
  path: Path;
  dispatch: (action: any) => void;
  openRoleModal: Function;
  onRemove: (mapping: PdfMappingType, fieldPath?: Path) => void;
  onSelect: (mapping: PdfMappingType) => void;
  roles: Array<Role>;

  getComponentOutputInstances: (
    componentId: string
  ) => Array<ComponentOutputInstance>;

  mappings: Array<PdfMappingType>;
  updateMap: (updater: (fields: PdfMappingType[]) => PdfMappingType[]) => void;

  pageMetadata: PageMetadata;
};

export default function PdfMappingSettings(props: Props) {
  const { mapping, onRemove, getComponentOutputInstances } = props;
  const codeViewEnabled = useFlag("can_use_code_view");

  const { mappableFieldsById, fieldPathsById } = useBuilderContext();
  // If this mapping has a `source` that can be found in the form fields, this
  // mapping is considered "linked" and will render a special settings panel.
  // If not linked, then a fallback settings panel will be used. A mapping
  // should only be "unlinked" if it was made outside of the UI, like in the
  // code editor
  const linkedComponent = Mapping.getLinkedField(mapping, mappableFieldsById);
  const fieldPath = linkedComponent
    ? fieldPathsById.get(linkedComponent.id)
    : undefined;

  const instances = linkedComponent
    ? getComponentOutputInstances(linkedComponent.id)
    : null;

  const isLastInstance = instances ? instances.length === 1 : false;

  const deleteTip = isLastInstance
    ? "Delete field and linked component"
    : "Delete field";

  const cn = useStyle("pdf-mapping-settings", {
    paddingLeft: theme.space.xs,
    paddingRight: theme.space.xs,
    backgroundColor: theme.color.gray025,
    minWidth: "200px",
    maxHeight: "500px",
    overflowY: "auto",
  });

  const onRemoveMapping = React.useCallback(() => {
    onRemove(mapping, fieldPath);
  }, [onRemove, mapping, fieldPath]);

  return (
    <div
      className={`${cn} ignore-react-onclickoutside`}
      data-testid="pdf-mapping-settings"
      id={`pdf-mapping-settings-${mapping.id}`}
    >
      <Flex mb="xs" justifyContent="space-between" alignItems="center" p="xs">
        <SmallHeading pl="sm">Output Field Settings</SmallHeading>
        <Tooltip tip={deleteTip} position="bottom">
          <IconWrapper
            onClick={onRemoveMapping}
            data-testid="delete-mapping-btn"
          >
            <DeleteIcon />
          </IconWrapper>
        </Tooltip>
      </Flex>
      <Box>
        {linkedComponent ? (
          <LinkedSettings
            {...props}
            field={linkedComponent}
            fieldPath={fieldPath}
          />
        ) : (
          <UnlinkedSettings {...props} linkedField={linkedComponent} />
        )}
      </Box>
      {codeViewEnabled && linkedComponent && <AdvancedOptions {...props} />}
      {__DEV__ && <Debug {...props} />}
    </div>
  );
}

/**
 * Advanced options that allow users to convert linked output fields into custom ones
 */
function AdvancedOptions(props: any) {
  const { mapping, dispatch, path } = props;
  const unlinkField = React.useCallback(() => {
    dispatch(
      Actions.updatePdfMap({
        path,
        updater: (of) => {
          // @ts-expect-error refactor
          const { source, ...outputField } = of;
          return outputField;
        },
      })
    );
  }, [dispatch, path]);

  return (
    <FieldSet label="Advanced" id={genId(mapping, "advanced")}>
      <LinkButton
        onClick={unlinkField}
        extend={{ fontSize: theme.fontSize.sm, justifySelf: "end" }}
      >
        Convert to custom field
      </LinkButton>
    </FieldSet>
  );
}

/**
 * A Dev-only component for viewing a mapping value. Helpful for debugging
 * mapping issues
 */
// @ts-expect-error refactor
function Debug(props) {
  const [open, setOpen] = React.useState(false);
  const { mapping } = props;

  const json = JSON.stringify(mapping, null, 2);
  const toggle = () => setOpen((isOpen) => !isOpen);

  return (
    <Box p="ms" display="flex" flexDirection="column" alignItems="flex-end">
      <Button
        size="small"
        presentation="subtle"
        onClick={toggle}
        extend={{ fontSize: theme.fontSize.sm }}
      >
        Debug
      </Button>
      {open && (
        <Box
          p="sm"
          as="pre"
          bg="white"
          mt="sm"
          fontSize="sm"
          extend={{ maxWidth: "350px", overflow: "auto" }}
        >
          <code>{json}</code>
        </Box>
      )}
    </Box>
  );
}

// @ts-expect-error refactor
function UnlinkedSettings(props) {
  const { mapping } = props;
  const isNew = ValueExpr.isNew(mapping.value);

  // If this is not a brand new mapping, then this is not something we know how
  // to render settings for. Show the fallback settings panel.
  return (
    <>
      <LinkToSettings {...props} />
      {!isNew && <FallbackSettings {...props} />}
    </>
  );
}

// @ts-expect-error refactor
function LinkedSettings(props) {
  const { field, dispatch, roles, fieldPath, openRoleModal } = props;
  const { mappableFieldsById } = useBuilderContext();

  // To know if the field has a calculation source, we check
  // on the fields from the context. We need to make it an array
  // since mappableFieldsById is a map
  const isCalculationSource = React.useMemo(() => {
    const fields = Array.from(mappableFieldsById.entries());
    // @ts-expect-error refactor
    return getReferringCalculations(fields, field).length !== 0;
  }, [field, mappableFieldsById]);

  const SettingsComponent =
    // @ts-expect-error refactor
    SettingsForFieldType[field.type] || SettingsForFieldType.Default;

  return (
    <div>
      <SettingsComponent {...props} />
      {canChangeInputType(field.type) && !isCalculationSource && (
        /* $FlowFixMe[incompatible-type] $FlowFixMe This comment suppresses an
         * error found when upgrading Flow to v0.132.0. To view the error,
         * delete this comment and run Flow. */
        <InputType field={field} editorDispatch={dispatch} path={fieldPath} />
      )}
      {
        /* $FlowFixMe[incompatible-call] $FlowFixMe This comment suppresses an
         * error found when upgrading Flow to v0.132.0. To view the error,
         * delete this comment and run Flow. */
        canReassign(roles, field.type, fieldPath) && (
          <AssignTo
            editorDispatch={dispatch}
            field={field}
            roles={roles}
            /* $FlowFixMe[incompatible-type] $FlowFixMe This comment suppresses
             * an error found when upgrading Flow to v0.132.0. To view the error,
             * delete this comment and run Flow. */
            path={fieldPath}
            openRoleModal={openRoleModal}
            extend={{
              borderTopColor: theme.color.gray200,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
            }}
          />
        )
      }
    </div>
  );
}

const SettingsForFieldType = {
  MultipleChoice: MultipleChoiceSettings,
  AddressGroup: AddressGroupSettings,
  Default: DefaultSettings,
};

// @ts-expect-error refactor
function DefaultSettings(props) {
  const { mapping } = props;

  if (
    mapping.type === MappingTypes.Text ||
    mapping.type === MappingTypes.Multiline
  ) {
    return <FontSizeSettings {...props} />;
  }

  return null;
}

// @ts-expect-error refactor
function AddressGroupSettings(props) {
  const { mapping, field } = props;
  const format = getIn(mapping, ["options", "format"]);

  const {
    mappingGroup,
    displayOptions,
    onChangeDisplay,
    onAdd,
    onLocate,
    segments,
  } = useDisplayGroup(getAddressDisplayFormats(), format, props);

  if (!format) return <FallbackSettings {...props} />;

  return (
    <>
      <FieldSet label="Display as" id={genId(mapping, "display-type")}>
        <Select
          options={displayOptions}
          value={displayOptions.find((opt) => opt.id === format)}
          // @ts-expect-error refactor
          onChange={(opt) => opt && onChangeDisplay(opt.id)}
          id={genId(mapping, "display-type")}
        />
      </FieldSet>
      <FontSizeSettings {...props} />
      {displayFormatOptions(format) && isGrouped(mapping) && (
        <DisplayGroup
          segments={segments}
          mappingGroup={mappingGroup}
          mapping={mapping}
          onAdd={onAdd}
          onLocate={onLocate}
          field={field}
        />
      )}
    </>
  );
}

// @ts-expect-error refactor
function displayFormatOptions(format) {
  return (
    Object.values(AddressFormats).includes(format) &&
    format !== AddressFormats.SingleLine
  );
}

// @ts-expect-error refactor
function DisplayGroup(props) {
  const { segments, mappingGroup, mapping, onAdd, onLocate, field } = props;

  // @ts-expect-error refactor
  const opts = segments.map((segment, idx) => (
    <DisplayGroupSegment
      key={idx}
      segment={segment}
      onConnectedClick={onLocate}
      onConnect={onAdd}
      // The output field we are looking at is the 'active' segment if it has
      // the same id as the segment in this iteration of the loop
      isActive={getSegmentId(mapping, field) === segment.id}
      // This particular segment is 'connected' if we can find an output field
      // in the current set that has the same `id`
      isConnected={mappingGroup.some(
        // @ts-expect-error refactor
        (mapping) => getSegmentId(mapping, field) === segment.id
      )}
    />
  ));

  return (
    <Box pb="ms">
      <Box px="sm" my="ms">
        <SmallHeading
          mb="ms"
          extend={{
            display: "flex",
            alignItems: "center",
            ":after": {
              content: "''",
              height: "1px",
              marginLeft: theme.space.xs,
              flex: 1,
              backgroundColor: theme.color.gray200,
            },
          }}
        >
          Linked Fields
        </SmallHeading>
        <Text fontWeight="semibold" fontSize="sm" color="textStandard">
          {field.label}
        </Text>
      </Box>
      <Box as="ul" p="none" m="none" aria-label="Linked Fields">
        {opts}
      </Box>
    </Box>
  );
}

// @ts-expect-error refactor
function DisplayGroupSegment(props) {
  const { segment, onConnectedClick, onConnect, isActive, isConnected } = props;

  return (
    <Flex
      as="li"
      data-testid={`display-group-option-${segment.label}`}
      justifyContent="space-between"
      fontSize="sm"
      fontWeight="semibold"
      alignItems="center"
      mx="xs"
      pl="xs" // Right padding comes from inner element on the right
      extend={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: theme.corner.sm,
        backgroundColor: isActive && theme.color.blue050,
        borderColor: isActive ? theme.color.uiActionBase : "transparent",
      }}
    >
      <Box py="sm">
        <Text
          as="span"
          extend={{ opacity: "0.5" }}
          color="textStandard"
          mr="xs"
        >
          -
        </Text>
        <Text as="span" color="textStandard">
          {segment.label}
        </Text>
      </Box>

      {/**
       * Options that are connected buton not active show a button that
       * will jump to that option on the document
       */}
      {isConnected && !isActive && (
        <Tooltip tip="Locate field">
          <IconWrapper onClick={() => onConnectedClick(segment)}>
            <LocateIcon />
            <VisuallyHidden>Locate field</VisuallyHidden>
          </IconWrapper>
        </Tooltip>
      )}

      {/**
       * Unconnected options show a button that adds this option to the
       * document
       */}
      {!isConnected && (
        <DisplayGroupOptionBtn onClick={() => onConnect(segment)}>
          Add
        </DisplayGroupOptionBtn>
      )}
    </Flex>
  );
}

const MultipleChoiceDisplayFormats = getMultipleChoiceDisplayFormats();

// @ts-expect-error refactor
function MultipleChoiceSettings(props) {
  const { mapping, field } = props;
  const { type } = mapping;

  const {
    displayOptions,
    onChangeDisplay,
    onAdd,
    onLocate,
    segments,
    mappingGroup,
  } = useDisplayGroup(MultipleChoiceDisplayFormats, type, props);

  return (
    <>
      <FieldSet
        label="Display as"
        id={genId(mapping, "display-type")}
        extend={{ marginBottom: theme.space.ms }}
      >
        <Select
          options={displayOptions}
          value={displayOptions.find((opt) => opt.id === type)}
          // @ts-expect-error refactor
          onChange={(opt) => opt && onChangeDisplay(opt.id)}
          id={genId(mapping, "display-type")}
        />
      </FieldSet>
      {type === MappingTypes.Text && <FontSizeSettings {...props} />}
      {type === MappingTypes.Checkmark && isGrouped(mapping) && (
        <DisplayGroup
          segments={segments}
          mapping={mapping}
          mappingGroup={mappingGroup}
          onAdd={onAdd}
          onLocate={onLocate}
          field={field}
        />
      )}
    </>
  );
}

/**
 * Hook that digs into the builder context for all of the mappings to find
 * only the mappings with a specific group id
 */
// @ts-expect-error refactor
function useMappingGroup(id, props) {
  const { mappings, updateMap } = props;
  const mappingGroup = mappings.filter(
    // @ts-expect-error refactor
    (mapping) => isGrouped(mapping) && getGroupId(mapping) === id
  );

  // Replaces every mapping in the group with a new array of mappings
  // @ts-expect-error refactor
  const replace = (updater) =>
    // @ts-expect-error refactor
    updateMap((mappings) => {
      const withoutGroup = mappings.filter(
        // @ts-expect-error refactor
        (mapping) => getGroupId(mapping) !== id
      );
      const mappingGroup = mappings.filter(
        // @ts-expect-error refactor
        (mapping) => getGroupId(mapping) === id
      );
      const updatedGroup = updater(mappingGroup);

      return [...withoutGroup, ...updatedGroup];
    });

  return { mappingGroup, replace };
}

// Had to make a separate function for flow...
function keys<T extends string>(obj: {}): Array<T> {
  // @ts-expect-error refactor
  return Object.keys(obj);
}

/**
 * This is a helper hook to manage output field sets that have display
 * configuration – changing display configurations (e.g. 'Three fields' to
 * 'Four fields'), adding new segments, or locating segments
 */
// @ts-expect-error refactor
function useDisplayGroup(config, currentFormat, props) {
  const { field, mapping, pageMetadata, onSelect } = props;
  const { mappingGroup, replace } = useMappingGroup(getGroupId(mapping), props);

  const displayOptions = keys(config).map((id) => ({
    id,
    label: config[id].label,
  }));

  const replaceSegments = React.useCallback(
    (segments, arrangement) => {
      // Use the currently selected output field as the anchor point
      const anchorPoint = mapping.coords;

      const converter = makeRectConverter();

      const arranged = arrange(arrangement, segments.map(converter.to), {
        anchorPoint,
      });

      const group = Group.of(arranged)
        .ensureInPage(pageMetadata.pageSizes)
        .ungroup()
        .map(converter.from);

      // @ts-expect-error refactor
      replace((_existingSegments) => group);
    },
    [pageMetadata, replace, mapping]
  );

  /**
   * Handles changing a display configuration from one type to another. To do
   * this, we find the new configuration based on the option selected, locate
   * or spawn new segments based on the configuration, and replace the entire
   * set with the new segments
   */
  const onChangeDisplay = React.useCallback(
    (newFormat) => {
      const formatConfig = config[newFormat];
      if (!formatConfig) {
        throw new Error(
          `Could not find configuration for format '${newFormat}'`
        );
      }

      const newSegments = formatConfig
        // Get the list of segment configurations for this format
        .createSegmentConfigs(field, mapping)

        // If there is an existing output field that matches segment configuration,
        // use it. Otherwise, use the pre-generated output field in the segment
        // config.
        // @ts-expect-error refactor
        .map((segment) => {
          const existingSegment = mappingGroup.find(
            // @ts-expect-error refactor
            (mapping) => getSegmentId(mapping, field) === segment.id
          );

          return existingSegment ?? segment.outputField;
        });

      replaceSegments(newSegments, formatConfig.arrangement);
    },
    [config, field, mapping, mappingGroup, replaceSegments]
  );

  /**
   * Adds a new segment to the existing set
   */
  const onAdd = React.useCallback(
    (segment) => {
      const formatConfig = config[currentFormat];
      if (!formatConfig) {
        throw new Error(
          `Could not find configuration for format '${currentFormat}'`
        );
      }

      const converter = makeRectConverter();

      /**
       * The resulting new segments are created by creating a `Group` of the
       * existing segments and then inserting the new segment next to the
       * currently selected segment.
       *
       * The `Group` class operates on "field rects" which are flat structures,
       * so we need to do some conversions to this format when supplying arguments
       * and convert back when 'ungrouping' to save back in the original format.
       */

      const existingSegments = mappingGroup.map(converter.to);
      const newSegment = converter.to(segment.outputField);
      const currentlySelectedSegment = converter.to(mapping);

      const newSegments = Group.of(existingSegments)
        .insert(newSegment, { nextTo: currentlySelectedSegment })
        .ungroup()
        .map(converter.from);

      // @ts-expect-error refactor
      replace((_group) => newSegments);
    },
    [config, currentFormat, mappingGroup, replace, mapping]
  );

  /**
   * Locates a particular segment in the set
   */
  const onLocate = React.useCallback(
    (segment) => {
      const placedSegment = mappingGroup.find(
        // @ts-expect-error refactor
        (mapping) => getSegmentId(mapping, field) === segment.id
      );
      onSelect(placedSegment);
    },
    [onSelect, mappingGroup, field]
  );

  const formatConfig = config[currentFormat];
  if (!formatConfig) {
    throw new Error(
      `Could not find configuration for format '${currentFormat}'`
    );
  }

  const segments = formatConfig.createSegmentConfigs(field, mapping);

  return {
    mappingGroup,
    displayOptions,
    onChangeDisplay,
    onAdd,
    onLocate,
    segments,
  };
}

/**
 * This is the settings panel rendered when a mapping doesn't match one of our
 * expected use cases (e.g. A mapping has been edited in code view)
 */
// @ts-expect-error refactor
function FallbackSettings(props) {
  const { mapping, dispatch, path } = props;
  const [splitBy, setSplitBy] = React.useState(mapping.splitBy);

  useUpdateEffect(() => {
    if (splitBy) {
      dispatch(
        Actions.updatePdfMap({
          path: [...path, "splitBy"],
          updater: () => splitBy,
        })
      );
    } else {
      dispatch(
        Actions.updatePdfMap({
          path,
          updater: (of) => {
            // @ts-expect-error refactor
            const { splitBy, ...outputField } = of;
            return outputField;
          },
        })
      );
    }
  }, [splitBy]);

  const onLabelChange = React.useCallback(
    (evt) => {
      const { value } = evt.target;
      dispatch(
        Actions.updatePdfMap({
          path: [...path, "label"],
          updater: () => value,
        })
      );
    },
    [path, dispatch]
  );
  const onValueChange = React.useCallback(
    (value) => {
      dispatch(
        Actions.updatePdfMap({
          path: [...path, "value"],
          updater: () => value,
        })
      );
    },
    [path, dispatch]
  );

  const onFieldTypeChange = React.useCallback(
    (value) => {
      // We change both the type and size to match the defaults
      dispatch(
        Actions.updatePdfMap({
          path: [...path],
          updater: () => Mapping.setOutputFieldType(mapping, value),
        })
      );
    },
    [dispatch, path, mapping]
  );

  const onCoordsChange = React.useCallback(
    (value) => {
      dispatch(
        Actions.updatePdfMap({
          path: [...path, "coords", "rawValue"],
          updater: () => value,
        })
      );
    },
    [dispatch, path]
  );

  const fieldTypeOptions = [
    { id: "Text", label: "Text field" },
    { id: "Checkmark", label: "Checkbox" },
  ];

  const splitByOptions = [
    {
      id: "char",
      label: "Split by Character",
    },
    {
      id: "page",
      label: "Split by Page",
    },
    {
      id: "entry",
      label: "Split by Entry",
    },
  ];

  const genId = (str: string) => `${mapping.id}-${str}`;

  return (
    <>
      <FieldSet
        label="Label"
        id={genId("label-input")}
        extend={{ gridTemplateColumns: "1fr 3fr" }}
      >
        <Input
          value={mapping.label}
          onChange={onLabelChange}
          id={genId("label-input")}
          type="text"
        />
      </FieldSet>
      {(mapping.type === MappingTypes.Text ||
        mapping.type === MappingTypes.Checkmark) && (
        <FieldSet label="Field Type" id={genId("field-type")}>
          <Select
            options={fieldTypeOptions}
            value={fieldTypeOptions.find((opt) => opt.id === mapping.type)}
            // @ts-expect-error refactor
            onChange={(opt) => opt && onFieldTypeChange(opt.id)}
            id={genId("field-type")}
          />
        </FieldSet>
      )}
      {(mapping.type === MappingTypes.Text ||
        mapping.type === MappingTypes.Multiline) && (
        <FontSizeSettings {...props} />
      )}
      <FieldSet
        label="Value (Advanced)"
        id={genId("value")}
        extend={{ minWidth: "250px" }}
        stacked
      >
        <Box width={1}>
          <CodeEditor
            value={mapping.value}
            onChange={onValueChange}
            name="value"
            id={genId("value")}
          />
        </Box>
      </FieldSet>
      {mapping.type === MappingTypes.Text && (
        <FieldSet
          label="Advanced Positioning"
          id={genId("advance-positioning")}
          extend={{ gridTemplateColumns: "3fr 1fr" }}
        >
          <Flex justifyContent="flex-end">
            <Toggle
              on={Boolean(mapping.splitBy)}
              onClick={() => setSplitBy(!splitBy && "char")}
              id={genId("advanced-positioning")}
              label={"advanced-positioning"}
            />
          </Flex>
        </FieldSet>
      )}
      {Boolean(mapping.splitBy) && (
        <>
          <Flex alignItems="center" mx="sm">
            <WarningIcon />
            <Text ml="xs" fontSize="sm" color="textStandard">
              Advanced positioning fields are not draggable
            </Text>
          </Flex>
          <FieldSet label="Type" id={genId("split-by")}>
            <Select
              options={splitByOptions}
              value={splitByOptions.find((opt) => opt.id === mapping.splitBy)}
              // @ts-expect-error refactor
              onChange={(opt) => opt && setSplitBy(opt.id)}
              id={genId("split-by")}
            />
          </FieldSet>
          <FieldSet
            label="Coords"
            id={genId("coords")}
            extend={{ minWidth: "250px" }}
            stacked
          >
            <Box width={1}>
              <CodeEditor
                value={mapping.coords.rawValue}
                onChange={onCoordsChange}
                name="coords"
                id={genId("coords-editor")}
              />
            </Box>
          </FieldSet>
        </>
      )}
    </>
  );
}

// @ts-expect-error refactor
function FontSizeSettings(props) {
  const { mapping, dispatch, path } = props;
  const { fontSize } = mapping;
  const [input, setInput] = React.useState(
    String(fontSize || Mapping.FontSize)
  );
  const id = genId(mapping, "font-size");
  const [error, setError] = React.useState(null);

  // @ts-expect-error refactor
  const onFontSizeChange = (evt) => {
    const { value } = evt.target;

    setInput(value);
    setError(null);

    const fontSize = parseInt(value, 10) || Mapping.FontSize;
    if (
      (fontSize < 9 || fontSize > 99) &&
      mapping.type === MappingTypes.Multiline
    ) {
      // @ts-expect-error refactor
      setError("The font size needs to be a number between 9 and 99.");
      return;
    } else if (fontSize < 1 || fontSize > 99) {
      // @ts-expect-error refactor
      setError("The font size needs to be a number between 1 and 99.");
      return;
    }

    const nextMapping = Mapping.setFontSize(mapping, fontSize);

    dispatch(
      Actions.changeFontSize({
        path,
        updater: () => nextMapping,
        mapping: nextMapping,
      })
    );
  };

  return (
    <FieldSet label="Font Size" id={id}>
      <Input
        value={input}
        onChange={onFontSizeChange}
        id={id}
        type="number"
        min="1"
        max="99"
        error={error}
        autoComplete="off"
        placeholder={`${Mapping.FontSize}`}
      />
      {error && (
        <FieldError extend={{ gridColumnStart: 2 }}>{error}</FieldError>
      )}
    </FieldSet>
  );
}

const iconsForType = {
  ...componentMenuIcons,
  AddressGroup: componentMenuIcons.Address,
  FileAttachment: componentMenuIcons.Attachment,
  DateInput: componentMenuIcons.DateComponent,
};

// @ts-expect-error refactor
function LinkToSettings(props) {
  const { mapping, dispatch, path, linkedField } = props;
  // @ts-expect-error refactor
  const genId = (str) => `${mapping.id}-${str}`;
  const { mappableFieldsById } = useBuilderContext();
  const options = [...mappableFieldsById].map(([, field]) => {
    // @ts-expect-error refactor
    const Icon = iconsForType[field.type];

    return {
      // @ts-expect-error refactor
      id: field.id,
      // @ts-expect-error refactor
      label: field.label || field.type, // Some fields aren't created with labels?
      Icon,
    };
  });
  const [isConnecting, setIsConnecting] = React.useState(false);
  const { replace } = useMappingGroup(getGroupId(mapping), props);

  // @ts-expect-error refactor
  const onSelect = (option) => {
    const field = mappableFieldsById.get(option.id);
    if (!field) return;
    const nextMapping = Mapping.linkTo(mapping, field);

    // If this mapping is within a group, we have to make sure we remove all
    // other mappings within the group
    if (isGrouped(mapping)) {
      // @ts-expect-error refactor
      replace((_group) => [nextMapping]);
    } else {
      dispatch(
        Actions.updatePdfMap({
          path,
          updater: () => nextMapping,
        })
      );
    }
  };

  if (!linkedField && !isConnecting) {
    return (
      <FieldSet
        label="Link to component"
        id={genId("unlinked")}
        extend={{
          gridTemplateColumns: "3fr 1fr",
          borderBottomColor: theme.color.dividerDim,
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
        }}
      >
        <LinkButton
          onClick={() => setIsConnecting(true)}
          extend={{ fontSize: theme.fontSize.sm, justifySelf: "end" }}
        >
          Connect
        </LinkButton>
      </FieldSet>
    );
  }
  const selectedOption = linkedField
    ? options.find((opt) => opt.id === linkedField.id)
    : null;

  return (
    <FieldSet label="Linked to" id={genId("link")}>
      <Box flex={1}>
        <Select
          value={selectedOption}
          options={options}
          onChange={onSelect}
          id={genId("link")}
          // @ts-expect-error refactor
          renderSelected={(opt) => {
            if (typeof opt === "string") return <Box ml="xs">{opt}</Box>;

            return (
              <Flex alignItems="center">
                {opt.Icon && (
                  <Flex mr="xs">
                    <opt.Icon size={24} />
                  </Flex>
                )}
                {opt.label}
              </Flex>
            );
          }}
          // @ts-expect-error refactor
          renderItem={(opt) => {
            return (
              <>
                {opt.Icon && (
                  <Flex>
                    <opt.Icon size={24} />
                  </Flex>
                )}
                <MenuItemText>{opt.label}</MenuItemText>
              </>
            );
          }}
        />
      </Box>
    </FieldSet>
  );
}

// @ts-expect-error refactor
function Select(props) {
  const { options, onChange, value, id, renderItem, renderSelected } = props;

  return (
    /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    <UISelect
      // @ts-expect-error refactor
      selectedItem={value || "Select component..."}
      onChange={onChange}
      placeholder="..."
      fillContainer
      triggerProps={{
        small: true,
        compacted: true,
        id,
        renderSelected,
      }}
      render={({ getItemProps, highlightedIndex }) => (
        <MenuList>
          {/* @ts-expect-error refactor */}
          {options.map((option, index) => (
            <MenuItem
              small={true}
              key={option.id}
              active={index === highlightedIndex}
              passthroughProps={getItemProps({ item: option })}
              extend={{ fontSize: theme.fontSize.ms }}
            >
              {renderItem(option)}
            </MenuItem>
          ))}
        </MenuList>
      )}
    />
  );
}

Select.defaultProps = {
  // eslint-disable-next-line react/display-name
  // @ts-expect-error refactor
  renderItem: (opt) => <MenuItemText>{opt.label}</MenuItemText>,
  // @ts-expect-error refactor
  renderSelected: (opt) => opt.label,
};

// @ts-expect-error refactor
function LinkButton(props) {
  return (
    <Button
      presentation="link"
      size="small"
      {...props}
      extend={{ fontSize: theme.fontSize.sm, justifySelf: "end" }}
    />
  );
}

// @ts-expect-error refactor
function DisplayGroupOptionBtn(props) {
  const { color, ...rest } = props;

  return (
    <Button
      presentation="link"
      size="small"
      {...rest}
      extend={{
        fontSize: theme.fontSize.sm,
        height: "auto",
        // @ts-expect-error refactor
        color: color && theme.color[color],
      }}
    />
  );
}

// Flow wants this...
DisplayGroupOptionBtn.defaultProps = {
  color: "blue500",
};

// @ts-expect-error refactor
function Input(props) {
  return (
    /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    <UIInput
      {...props}
      extend={{
        fontSize: theme.fontSize.ms,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.xs,
        paddingTop: theme.space.xs,
        paddingBottom: theme.space.xs,
        lineHeight: "20px",
      }}
    />
  );
}

// @ts-expect-error refactor
function FieldError(props) {
  const { extend, ...rest } = props;
  return (
    <div
      className={useStyle(
        "field-error",
        {
          color: theme.color.red500,
          marginTop: theme.space.xs,
          fontSize: theme.fontSize.sm,
        },
        extend
      )}
      {...rest}
    />
  );
}

// @ts-expect-error refactor
function genId(mapping, label) {
  return `${mapping.id}-${label}`;
}

// @ts-expect-error refactor
function getGroupId(mapping) {
  return getIn(mapping, ["meta", "group"]);
}

// @ts-expect-error refactor
function isGrouped(mapping) {
  return Boolean(getGroupId(mapping));
}

/**
 * Segment Display Formats
 * -----------------------
 *
 * Segments are output fields that are part of a set, e.g. each output field
 * that points to an individual option of a `MultipleChoice` component is a
 * segment. Different components can have different segment configurations that
 * are available for the user to choose.
 */

/**
 * A segment config defines an `id` that uniquely identifies this segment within
 * its group. We use this property to know identify which segment each output
 * field in the set currently points to
 */
type SegmentConfig = {
  label: string;
  id: string;

  /**
   * This is a bit confusing, but we need to be able to identity which segment
   * an output field currently represents within its group. For example,
   * identifying that an output field is representing a checkbox for the
   * third option in a `MultipleChoice` component. The only way to do that
   * right now is to create a _new_ output field for that component and then
   * compare the `value` of the new field to the `value` of the existing fields
   * in the set. For convenience, we pre-generate this output field and store
   * it in the configuration as we may need it later if users decide to add
   * new segments to the set that don't already exist.
   */
  outputField: PdfMappingType;
};

type DisplayConfig<ComponentType> = {
  label: string;
  // $FlowFixMe: migration
  arrangement: ArrangementType;
  createSegmentConfigs: (
    component: ComponentType,
    /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish
     * the TS migration. */
    sibling: PdfMappingType
  ) => SegmentConfig[];
};

type DisplayConfigMap<ComponentType> = {
  [id: string]: DisplayConfig<ComponentType>;
};

// TODO: There is some weird Webpack thing happening with circular dependencies
function getAddressDisplayFormats(): DisplayConfigMap<AddressGroupField> {
  // @ts-expect-error refactor
  return {
    [AddressFormats.SingleLine]: {
      label:
        OutputField.componentDisplayTypeLabels.AddressGroup[
          AddressFormats.SingleLine
        ],
      arrangement: Arrangement({
        minWidth: 303,
        type: "flex",
        rows: [[{ width: 1 }]],
      }),
      createSegmentConfigs(component, sibling) {
        invariant(
          component.dataRef,
          `No dataRef found on component ${component.id}`
        );

        const outputField = Mapping.duplicate(sibling, {
          value: ValueExpr.Formats.single(component.dataRef),
          options: {
            format: AddressFormats.SingleLine,
          },
        });
        return [
          {
            label: "Address",
            id: getSegmentId(outputField, component),
            outputField,
          },
        ];
      },
    },
    [AddressFormats.TwoLines]: {
      label:
        OutputField.componentDisplayTypeLabels.AddressGroup[
          AddressFormats.TwoLines
        ],
      arrangement: Arrangement({
        type: "flex",
        rows: [[{ width: 1 }], [{ width: 1 }]],
        minWidth: 303,
      }),
      createSegmentConfigs(component, sibling) {
        const line1 = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.FullStreet(component),
          options: {
            format: AddressFormats.TwoLines,
          },
        });

        const line2 = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.CityStateZip(component),
          options: {
            format: AddressFormats.TwoLines,
          },
        });
        return [
          {
            label: "Street Address - Line 2",
            id: getSegmentId(line1, component),
            outputField: line1,
          },
          {
            label: "City, State, Zip",
            id: getSegmentId(line2, component),
            outputField: line2,
          },
        ];
      },
    },
    [AddressFormats.ThreeLines]: {
      label:
        OutputField.componentDisplayTypeLabels.AddressGroup[
          AddressFormats.ThreeLines
        ],
      arrangement: Arrangement({
        type: "flex",
        rows: [[{ width: 1 }], [{ width: 1 }], [{ width: 1 }]],
        minWidth: 303,
      }),
      createSegmentConfigs(component, sibling) {
        const line1 = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.StreetAddress(component),
          options: {
            format: AddressFormats.ThreeLines,
          },
        });
        const line2 = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.StreetLine2(component),
          options: {
            format: AddressFormats.ThreeLines,
          },
        });
        const cityStateZip = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.CityStateZip(component),
          options: {
            format: AddressFormats.ThreeLines,
          },
        });
        return [
          {
            label: "Street Address",
            id: getSegmentId(line1, component),
            outputField: line1,
          },
          {
            label: "Street Address - Line 2",
            id: getSegmentId(line2, component),
            outputField: line2,
          },
          {
            label: "City, State, Zip",
            id: getSegmentId(cityStateZip, component),
            outputField: cityStateZip,
          },
        ];
      },
    },
    [AddressFormats.FourLines]: {
      label:
        OutputField.componentDisplayTypeLabels.AddressGroup[
          AddressFormats.FourLines
        ],
      arrangement: Arrangement({
        type: "flex",
        minWidth: 303,
        rows: [
          [{ width: 1 }],
          [{ width: 0.5 }, { width: 0.2 }, { width: 0.3 }],
        ],
      }),
      createSegmentConfigs(component, sibling) {
        const line1 = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.FullStreet(component),
          options: {
            format: AddressFormats.FourLines,
          },
        });
        const city = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.City(component),
          options: {
            format: AddressFormats.FourLines,
          },
        });
        const state = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.State(component),
          options: {
            format: AddressFormats.FourLines,
          },
        });
        const zip = Mapping.duplicate(sibling, {
          value: ValueExpr.AddressGroup.Zip(component),
          options: {
            format: AddressFormats.FourLines,
          },
        });
        return [
          {
            label: "Street Address - Line 2",
            id: getSegmentId(line1, component),
            outputField: line1,
          },
          {
            label: "City",
            id: getSegmentId(city, component),
            outputField: city,
          },
          {
            label: "State",
            id: getSegmentId(state, component),
            outputField: state,
          },
          {
            label: "Zip Code",
            id: getSegmentId(zip, component),
            outputField: zip,
          },
        ];
      },
    },
    [AddressFormats.SeparateFields]: {
      label:
        OutputField.componentDisplayTypeLabels.AddressGroup[
          AddressFormats.SeparateFields
        ],
      arrangement: Arrangement({
        type: "flex",
        minWidth: 303,
        rows: [
          [{ width: 1 }],
          [{ width: 1 }],
          [{ width: 0.5 }, { width: 0.2 }, { width: 0.3 }],
        ],
      }),
      createSegmentConfigs(component, sibling) {
        return component.children.map((childField) => {
          invariant(
            childField.dataRef,
            `No dataRef found on component ${component.id}`
          );

          const outputField = Mapping.duplicate(sibling, {
            value: ValueExpr.Formats.single(childField.dataRef),
            options: {
              format: AddressFormats.SeparateFields,
            },
          });

          invariant(
            childField.label,
            `No label found on component ${component.id}`
          );

          return {
            label: childField.label,
            id: getSegmentId(outputField, component),
            outputField,
          };
        });
      },
    },
  };
}

/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
function getMultipleChoiceDisplayFormats(): DisplayConfigMap<MultipleChoiceField> {
  return {
    [MappingTypes.Text]: {
      label: "Text",
      arrangement: Arrangement({
        type: "flex",
        rows: [[{ width: 1 }]],
        minWidth: 125,
      }),
      createSegmentConfigs(component, sibling) {
        const outputField = Mapping.MultipleChoice.createText(
          component,
          sibling
        );

        return [
          {
            label: "Text",
            id: getSegmentId(outputField, component),
            outputField,
          },
        ];
      },
    },
    [MappingTypes.Checkmark]: {
      label: "Checkboxes",
      arrangement: Arrangement({
        type: "columns",
      }),
      createSegmentConfigs(component, sibling) {
        return component.options.map((opt, idx) => {
          const outputField = Mapping.MultipleChoice.createCheckbox(
            component,
            sibling,
            idx
          );
          return {
            label: opt,
            id: getSegmentId(outputField, component),

            outputField,
          };
        });
      },
    },
  };
}

/**
 * The segment identifier currently cannot be determined statically, we must
 * check which component the output field refers to and, in some cases, the
 * `type` of the output field..
 */
// @ts-expect-error refactor
function getSegmentId(outputField, component) {
  switch (component.type) {
    case "MultipleChoice": {
      if (outputField.type === MappingTypes.Checkmark) {
        invariant(
          outputField.meta?.optionIndex !== undefined,
          `MultipleChoice output fields should always have an optionIndex if they are of type ${MappingTypes.Checkmark}`
        );

        return `${outputField.value}.${outputField.meta.optionIndex}`;
      } else {
        return outputField.value;
      }
    }

    /**
     * Address Group output fields can sometimes contain the same `value` but
     * they are configured for different display formats. We need to consider
     * them different output field segments.
     */
    case "AddressGroup": {
      invariant(
        outputField.options?.format,
        `AddressGroup output fields should always have a 'format' option`
      );

      return `${outputField.value}.${outputField.options?.format}`;
    }
    default:
      return outputField.value;
  }
}

function makeRectConverter() {
  // Build a map to find the original output field by ID
  const byId = new Map();

  // @ts-expect-error refactor
  function toRect(outputField) {
    byId.set(outputField.id, outputField);
    return toFieldRect(outputField);
  }

  // @ts-expect-error refactor
  function fromRect(rect) {
    const originalOutputField = byId.get(rect.id);

    if (!originalOutputField) {
      throw new Error("Could not find convert field rect back to output field");
    }
    return fromFieldRect(rect, originalOutputField);
  }

  return {
    to: toRect,
    from: fromRect,
  };
}
