import * as React from "react";
import { invariant } from "hw/common/utils/assert";
import { FormattedMessage } from "react-intl";
import type { Action } from "hw/ui/modal";
import Modal from "hw/ui/modal";
import { TextBody1 } from "hw/ui/text";
import type { Field, ParsedSchema } from "hw/portal/modules/common/draft";
import {
  sentenceJoin,
  getReferringFieldLabels,
  getReferringFormLabels,
  getReferringCalculationLabels,
} from "../utils";

type Props = {
  /**
   * Additional content for the body
   */
  children: React.ReactNode;

  /**
   * Closes the modal
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel: (...args: Array<any>) => any;

  /**
   * The field to delete
   */
  field: Field;

  /**
   * Heading for the modal
   */
  heading: React.ReactNode;

  /**
   * Full schema for the draft
   */
  schema: ParsedSchema;

  /**
   * Primary action for the modal
   */
  // $FlowIgnore
  primaryAction: Action;

  /**
   * ID of the form containing the field
   */
  formId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layer?: any;
};

function getFieldReference(field: Field): string {
  if (field.type === "Group") {
    return "Components in this Condition are";
  } else {
    return `'${field.label || field.type}' is`;
  }
}

export function ConfirmFieldReferenceRemovalModal(props: Props) {
  const {
    field,
    primaryAction,
    heading,
    onCancel,
    schema,
    children,
    formId,
    ...rest
  } = props;
  const { forms, mapping } = schema;
  const form = forms.find((form) => form.id === formId);
  invariant(
    form,
    `Could not find the containing form for field with id '${field.id}'`
  );
  const { fields } = form;
  const referringFields = getReferringFieldLabels(fields, field);
  const referringForms = getReferringFormLabels(field, formId, forms, mapping);
  const referringCalculations = getReferringCalculationLabels(fields, field);
  return (
    <Modal
      {...rest}
      heading={heading}
      primaryAction={primaryAction}
      secondaryAction={{
        text: (
          <FormattedMessage
            defaultMessage="Cancel"
            id="removeFieldReferenceModal.secondaryAction"
          />
        ),
        onClick: onCancel,
      }}
    >
      <TextBody1>
        <Body
          field={field}
          referringFields={referringFields}
          referringForms={referringForms}
          referringCalculations={referringCalculations}
        >
          {children}
        </Body>
      </TextBody1>
    </Modal>
  );
}

/**
 * Uses `react-intl` to create the copy string
 */
function Body({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'field' implicitly has an 'any' ty... Remove this comment to see the full error message
  field,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'referringFields' implicitly has a... Remove this comment to see the full error message
  referringFields,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'referringForms' implicitly has an... Remove this comment to see the full error message
  referringForms,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'referringCalculations' implicitly... Remove this comment to see the full error message
  referringCalculations,
}) {
  // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'formattedMessage' implicitly has a... Remove this comment to see the full error message
  const bodyTestEl = (...formattedMessage) => {
    return React.createElement(
      "span",
      {
        "data-testid": "body",
      },
      formattedMessage
    );
  };

  const fieldReference = getFieldReference(field);
  const fieldCopy =
    referringFields.length > 0
      ? "in " + sentenceJoin(referringFields) + " for conditional logic"
      : "";
  const formCopy =
    referringForms.length > 0
      ? "in " + sentenceJoin(referringForms) + " to pre-fill other form fields"
      : "";
  const calculationCopy =
    referringCalculations.length > 0
      ? "in the calculation " + sentenceJoin(referringCalculations)
      : "";
  const referringText = sentenceJoin(
    [formCopy, fieldCopy, calculationCopy],
    ""
  );
  const bodyMsg = "{fieldReference} being used {referringText}. ";
  return (
    <>
      <FormattedMessage
        id="deleteFieldModal.body"
        defaultMessage={bodyMsg}
        values={{
          fieldReference,
          referringText,
        }}
      >
        {bodyTestEl}
      </FormattedMessage>
      {children}
    </>
  );
}
