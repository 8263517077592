import * as React from "react";
import * as heap from "hw/portal/modules/analytics/heap";
import { TableCell, TableRow, TableHead, TableBody, Table } from "hw/ui/table";
import Loader from "hw/ui/loader";
import { DangerIcon16 } from "hw/ui/icons";
import Constants from "./constants";
import { validateName, validateEmail } from "./validations";
import styles from "./table.module.css";

const { STATUS } = Constants;

export type DataRowValues = {
  csvIndex: number;
  status: string;
  participants: Array<{
    nameValue: string;
    emailValue: string;
    id: string;
  }>;
  mergeFields: Array<{
    value: string;
    dataRef: string;
  }>;
};

type DataCellProps = {
  error?: string | boolean;
  value: string;
  onBlur: React.FocusEventHandler;
  onFocus: React.FocusEventHandler;
};

function trackFocus(column: string) {
  heap.track(heap.EVENTS.launchPage.bulkSendCellFocused, { column });
}

function DataCell(props: DataCellProps) {
  const { error, value = "", onBlur, onFocus } = props;
  // By having a local state and only updating the parent state on blur we can reduce the lag to a trivial amount
  const [inputValue, setInputValue] = React.useState(value);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(event.target.value);
  const classNames = [styles.tableInput, error && styles.invalid]
    .filter((cn) => cn)
    .join(" ");

  // The invisible field sets the width so that it is based on content not the input size.
  return (
    <TableCell className={styles.td}>
      <div className={styles.invisible}>{inputValue}</div>
      <input
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames}
        onFocus={onFocus}
      />
      {error && (
        <div>
          <span className={styles.cellErrorIcon}>
            <DangerIcon16 />
          </span>
          <span className={styles.cellErrorText}>{error}</span>
        </div>
      )}
    </TableCell>
  );
}

type DataRowProps = {
  row: DataRowValues;
  onNameChange: (idx: number, value: string) => void;
  onEmailChange: (idx: number, value: string) => void;
  onMergeFieldChange: (idx: number, value: string) => void;
};

function DataRow(props: DataRowProps) {
  const { row, onNameChange, onEmailChange, onMergeFieldChange } = props;
  const { participants, mergeFields = [] } = row;

  const rowHasError =
    row.status === STATUS.ERROR_EMAIL ||
    participants.some(
      (par) => !validateName(par.nameValue) || !validateEmail(par.emailValue)
    );
  const rowClasses = rowHasError ? styles.error : "";

  return (
    <TableRow className={rowClasses}>
      {participants.reduce((acc, participant, participantIndex) => {
        const setName = (event: React.FocusEvent<HTMLInputElement>) =>
          onNameChange(participantIndex, event.target.value);
        const setEmail = (event: React.FocusEvent<HTMLInputElement>) =>
          onEmailChange(participantIndex, event.target.value);

        const validName = validateName(participant.nameValue);
        const validEmail = validateEmail(participant.emailValue);
        const nameFocus = () => trackFocus(`${participant.id}-name`);
        const emailFocus = () => trackFocus(`${participant.id}-email`);

        acc.push(
          <DataCell
            key={`${participant.id}-name`}
            error={!validName && "Name cannot be blank"}
            value={participant.nameValue}
            onBlur={setName}
            onFocus={nameFocus}
          />
        );
        acc.push(
          <DataCell
            key={`${participant.id}-email`}
            error={
              // $FlowIgnore
              !validEmail && "Invalid email"
            }
            value={participant.emailValue}
            onBlur={setEmail}
            onFocus={emailFocus}
          />
        );
        return acc;
      }, [] as React.ReactNode[])}
      {mergeFields.map((mergeField, mergeFieldIndex) => {
        const setMergeField = (event: React.FocusEvent<HTMLInputElement>) =>
          onMergeFieldChange(mergeFieldIndex, event.target.value);

        const onFocus = () => trackFocus(`merge field ${mergeField.dataRef}`);

        return (
          <DataCell
            key={mergeField.dataRef}
            value={mergeField.value}
            onBlur={setMergeField}
            onFocus={onFocus}
          />
        );
      })}
    </TableRow>
  );
}

type DataBodyProps = {
  entries: Array<DataRowValues>;
  onNameChange: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
  onEmailChange: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
  onMergeFieldChange: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
};

function DataBody(props: DataBodyProps) {
  const { entries, onNameChange, onEmailChange, onMergeFieldChange } = props;

  const getRow = (row: DataRowValues, rowIndex: number) => {
    const onNameChangeClosure = (participantIndex: number, value: string) =>
      onNameChange(rowIndex, participantIndex, value);
    const onEmailChangeClosure = (participantIndex: number, value: string) =>
      onEmailChange(rowIndex, participantIndex, value);
    const onMergeFieldChangeClosure = (
      mergeFieldIndex: number,
      value: string
    ) => onMergeFieldChange(rowIndex, mergeFieldIndex, value);

    return (
      <DataRow
        key={row.csvIndex}
        row={row}
        onNameChange={onNameChangeClosure}
        onEmailChange={onEmailChangeClosure}
        onMergeFieldChange={onMergeFieldChangeClosure}
      />
    );
  };

  return <TableBody>{entries && entries.map(getRow)}</TableBody>;
}

type DataHeaderProps = {
  headers: Array<string>;
};

function DataHeader(props: DataHeaderProps) {
  const { headers } = props;

  return (
    <TableHead>
      <TableRow className={styles.headRow}>
        {headers.map((col, index) => (
          <TableCell key={index} header className={styles.th}>
            {col}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type BulkTableProps = {
  headers: Array<string>;
  entries: Array<DataRowValues>;
  onNameChange: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
  onEmailChange: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
  onMergeFieldChange: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
  blocked: boolean;
  bannerOffset?: number;
};

export default function BulkTable(props: BulkTableProps) {
  const {
    headers,
    entries,
    onNameChange,
    onEmailChange,
    onMergeFieldChange,
    blocked,
    bannerOffset = 0,
  } = props;

  return (
    <div className={styles.container}>
      {blocked && (
        <div className={styles.blocker}>
          <div
            className={styles.sendingMessage}
            style={{
              top: `calc(50% - ${bannerOffset}em`,
            }}
          >
            <Loader extend={{ justifyContent: "normal" }} />
            <div>Sending...</div>
          </div>
        </div>
      )}
      <Table
        extend={{
          whiteSpace: "nowrap",
          position: "relative",
        }}
      >
        <DataHeader headers={headers} />
        <DataBody
          entries={entries}
          onNameChange={onNameChange}
          onEmailChange={onEmailChange}
          onMergeFieldChange={onMergeFieldChange}
        />
      </Table>
    </div>
  );
}
