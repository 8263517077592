/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  autoComplete?: string;
  "aria-label"?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  min?: string;
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'max'.
  max?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  onKeyUp?: (e: KeyboardEvent) => unknown;
  required?: boolean;
  title?: string;
  type?: string;
  value?: string | number;
  attrs?: Record<string, unknown>;
  placeholder?: string;
  autoFocus?: boolean;
  maxLength?: number;
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'max'.
  max?: string;
  // used for type="date" fields to constrain input
  "data-testid"?: string;
  "data-guide-id"?: string;
  "data-track-id"?: string;
  "data-automation-id"?: string;
  onClick?: (...args: Array<any>) => any;
  readOnly?: boolean;
  extend?: Extend | null;
  onFocus?: (...args: Array<any>) => any;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  step?: number;
  inputMode?: string;
  pattern?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

/**
 *
 * The `input` module contains low-level components for working with inputs.
 *
 * Basic example:
 *
 * ```jsx
 * <Input type="text" onChange={e => {}} placeholder="Name..." />
 * ```
 *
 * Since this is a low-level element, you can supply an `attrs` prop that will be
 * spread onto the underlying `input` element.
 *
 * ```jsx
 * <Input type="text" placeholder="Email" attrs={{ autoComplete: "email " }} />
 * ```
 *
 * An `InputLabel` is also included in this module:
 *
 * ```jsx
 * <div>
 *   <InputLabel>Email</InputLabel>
 *   <Input />
 * </div>
 * ```
 */
// eslint-disable-next-line react/display-name
const InputElement = React.forwardRef(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'attrs' does not exist on type '{ childre... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ({ attrs, extend = () => {}, ...rest }, ref) => (
    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    <input
      {...rest}
      {...attrs}
      ref={ref}
      className={useStyle(
        "ui-input",
        inputStyle,
        /* $FlowFixMe[incompatible-call] $FlowFixMe This comment suppresses an
         * error found when upgrading Flow to v0.132.0. To view the error,
         * delete this comment and run Flow. */
        /* $FlowFixMe[extra-arg] $FlowFixMe This comment suppresses an error
         * found when upgrading Flow to v0.132.0. To view the error, delete
         * this comment and run Flow. */
        /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
         * an error found when upgrading Flow to v0.132.0. To view the error,
         * delete this comment and run Flow. */
        typeof extend === "function" ? extend({ ...rest, ...attrs }) : extend
      )}
    />
  )
);

const inputStyle = {
  borderColor: theme.color.gray400,
  borderRadius: theme.corner.sm,
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  color: theme.color.textStandard,
  fontSize: theme.fontSize.ms,
  letterSpacing: "0.05em",
  paddingTop: theme.space.sm,
  paddingBottom: theme.space.sm,
  paddingLeft: theme.space.ms,
  paddingRight: theme.space.ms,
  transitionDuration: theme.transitionSpeed.fast,
  transitionProperty: "box-shadow, border-color",
  width: "100%",
  ":hover": {
    borderColor: theme.color.gray500,
  },
  ":focus": {
    borderColor: theme.color.uiFocus,
    outline: "none",
  },
  ":disabled": {
    backgroundColor: theme.color.inputBgDisabled,
    borderColor: theme.color.inputBorderDisabled,
    color: theme.color.gray500,
    cursor: "not-allowed",
    textFillColor: theme.color.gray500,
  },
};

export default InputElement as React.ComponentType<Props>;
