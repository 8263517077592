import * as React from "react";
import { DropTarget } from "react-dnd";
import { getIn } from "timm";
import type { DropTargetMonitor } from "react-dnd";
import type { Path } from "hw/common/types";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { DRAG_TYPE_FIELD } from "../../constants";
import { FieldTypes } from "../../../constants";
import { isSameBasePath } from "../../../utils";

const INDICATOR_BOTTOM = "BOTTOM";
const INDICATOR_TOP = "TOP";
type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any>;

  /**
   * Target path
   */
  path: Path;

  /**
   * Flag that defines if it is dropping in a nested component or not
   */
  isNested?: boolean;

  /**
   * Nested attributes that are passed to the dropped field
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  nestedAttrs?: {};
};
const Droppable = React.memo(function DroppableElement({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'indicatorPosition' does not exist on typ... Remove this comment to see the full error message
  indicatorPosition,
  ...rest
}) {
  const cn = useStyle("droppable-element", {
    position: "relative",
    ":before": {
      transition: "opacity 150ms",
      opacity: indicatorPosition === INDICATOR_TOP ? 1 : 0,
      content: "''",
      position: "absolute",
      top: "-20px",
      width: "100%",
      height: "3px",
      backgroundColor: theme.color.borderSelectedColor,
      borderRadius: theme.corner.sm,
    },
    ":after": {
      transition: "opacity 150ms",
      opacity: indicatorPosition === INDICATOR_BOTTOM ? 1 : 0,
      content: "''",
      position: "absolute",
      bottom: "-20px",
      width: "100%",
      height: "3px",
      backgroundColor: theme.color.borderSelectedColor,
      borderRadius: theme.corner.sm,
    },
  });
  return <div {...rest} className={cn} />;
});

/**
 * Handles events related to dropping
 */
export const dropSpec = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drop(props: any, monitor: DropTargetMonitor) {
    const didDrop = monitor.didDrop();

    if (didDrop) {
      return;
    }

    const source = monitor.getItem();
    const sourcePath = source.path;
    const targetPath = props.path;
    if (!canDrop(source.field.type, props.isNested)) return;
    // If we are moving it to a nested component, we should give them the
    // same role
    props.moveField(sourcePath, targetPath, props.nestedAttrs);
  },
};

/**
 * This function provides props to the item being dropped on
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
function dropCollect(connect, monitor) {
  const sourceItem = monitor.getItem();
  return {
    connectDropTarget: connect.dropTarget(),
    sourcePath: getIn(sourceItem, ["path"]),
    sourceType: getIn(sourceItem, ["field", "type"]),
    // The `shallow` flag indicates that we only care when this exact item
    // is hovered over, not nested drop targets
    isOver: monitor.isOver({
      shallow: true,
    }),
  };
}

// For now, the only condition is that if the target is nested it can't include
// groups or paragraphs as children
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'sourceType' implicitly has an 'any' typ... Remove this comment to see the full error message
function canDrop(sourceType, targetIsNested?: boolean) {
  return !(
    targetIsNested &&
    (sourceType === FieldTypes.Group || sourceType === FieldTypes.Paragraph)
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DroppableSection extends React.PureComponent<any> {
  render() {
    const {
      connectDropTarget,
      isOver,
      sourcePath,
      sourceType,
      isNested,
      path,
      children,
      forwardedRef,
    } = this.props;
    const indicatorPosition =
      isOver && canDrop(sourceType, isNested) && sourcePath
        ? getIndicatorPosition(sourcePath, path)
        : null;
    return connectDropTarget(
      <div ref={forwardedRef}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: ReactNode; indicatorPosition: st... Remove this comment to see the full error message */}
        <Droppable indicatorPosition={indicatorPosition}>{children}</Droppable>
      </div>
    );
  }
}

/**
 * Determines the placement of the drop indicator
 */
function getIndicatorPosition(sourcePath: Path, targetPath: Path) {
  if (isSameBasePath(sourcePath, targetPath)) {
    // Same group
    // Direction changes depending on if coming from above or below
    const sourceIndex = Number(sourcePath[sourcePath.length - 1]);
    const targetIndex = Number(targetPath[targetPath.length - 1]);
    const topToBottom = sourceIndex < targetIndex;
    const bottomToTop = sourceIndex > targetIndex;

    // When dragging from top to bottom within the same group, the
    // dropped item will be placed after the item being dragged over.
    if (topToBottom) {
      return INDICATOR_BOTTOM;
    } else if (bottomToTop) {
      return INDICATOR_TOP;
    }
  } else {
    // For now, when moving between groups we'll place items after the
    // dropped item by default.
    return INDICATOR_BOTTOM;
  }
}

// @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'DndComponentClass<typeof Dropp... Remove this comment to see the full error message
const DroppableComponent = DropTarget(
  DRAG_TYPE_FIELD,
  dropSpec,
  dropCollect
)(DroppableSection) as React.ComponentType<Props>;
/* eslint-disable react/display-name */

export default React.forwardRef((props, ref) => (
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  <DroppableComponent {...props} forwardedRef={ref} />
));
