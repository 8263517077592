import * as React from "react";
import { createComponent } from "hw/ui/style";
import { AddIcon } from "hw/ui/icons";

/**
 * @one-off Button
 */
const Button = createComponent(
  function OrderableListAddButton({ theme }) {
    return {
      fontFamily: "inherit",
      backgroundColor: "transparent",
      borderColor: theme.color.gray300,
      borderWidth: "1px",
      borderStyle: "dashed",
      borderRadius: theme.corner.sm,
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.md,
      fontSize: theme.fontSize.ms,
      display: "flex",
      fontWeight: "normal",
      flexDirection: "row",
      alignItems: "center",
      color: theme.color.gray300,
      cursor: "pointer",
      width: "100%",
    };
  },
  "button",
  ["onClick"]
);

const AddIconWrapper = createComponent(function OrderableListAddIcon({
  theme,
}) {
  return {
    marginRight: theme.space.sm,
    "& svg": {
      borderColor: theme.color.gray300,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: theme.corner.round,
      display: "block",
      fill: theme.color.gray300,
    },
  };
});

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...args: Array<any>) => any;
  children: React.ReactNode;
};

/**
 * Button component for adding a new item to the list
 *
 * TODO: I'm unsure if this needs to be a variation of our existing `Button`
 * component or if this is a one-off.
 */

export default function AddButton({ onClick, children }: Props) {
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: a... Remove this comment to see the full error message
    <Button onClick={onClick} data-testid="add-button">
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type ' */}
      <AddIconWrapper>
        <AddIcon />
      </AddIconWrapper>
      {children}
    </Button>
  );
}
