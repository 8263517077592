/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NOTE: These components are deprecated. Please use the `Text` and
 * `Heading` components in the `./text.jsx` module
 */
import { createComponent } from "hw/ui/style";

const TextBody1 = createComponent(function BodyText({ theme }: any) {
  return {
    fontSize: theme.fontSize.ms,
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "20px", // TODO: having lineHeight in the theme
  };
}, "p");

const TextBody2 = createComponent(function BodyText({ theme }: any) {
  return {
    fontSize: theme.fontSize.md,
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "24px", // TODO: having lineHeight in the theme
  };
}, "p");

export { TextBody1, TextBody2 };
