import * as React from "react";

export default function WarningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <rect width="16" height="16" x="4" y="4" fill="#FCB212" rx="8" />
        <path
          fill="#FFF"
          d="M12.00655,14.4619883 C11.4919301,14.4619883 11.0898833,14.0760234 11.0577196,13.5614035 L10.8164915,8.23830409 C10.7843278,7.5628655 11.3311114,7 11.9904681,7 C12.6659067,7 13.2126903,7.54678363 13.1805266,8.23830409 L12.9553804,13.5614035 C12.9232166,14.0760234 12.505088,14.4619883 12.00655,14.4619883 Z M10.7200003,16.7134503 C10.7200003,16.005848 11.2989476,15.4108187 12.00655,15.4108187 C12.7141523,15.4108187 13.2930997,16.005848 13.2930997,16.7134503 C13.2930997,17.4049708 12.7141523,18 12.00655,18 C11.2989476,18 10.7200003,17.4049708 10.7200003,16.7134503 Z"
        />
      </g>
    </svg>
  );
}
