/* eslint-disable @typescript-eslint/no-explicit-any */
import { setIn, omit } from "timm";
import { invariant } from "hw/common/utils/assert";
import generate from "nanoid/generate";
import type { PdfMapping as PdfMappingType } from "hw/portal/modules/common/draft";
import logger from "hw/common/utils/logger";
import { linesToFitChars } from "hw/portal/modules/common/draft/utils";
import {
  AddressFormats,
  MultipleChoiceFormats,
  MappingTypes,
  FontSize,
} from "./constants";
import * as ValueExpr from "./value-expr";
import { withRawValue, updateWhere, isSourcedBy, isLinkedTo } from "./utils";

const shortid = () =>
  generate("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 6);

/**
 * Creates a new `Text` mapping.
 *
 * NOTE: All props are overridable except:
 *   - type
 *   - height: Computed based on the width and font size
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function Text(props: Partial<PdfMappingType>) {
  const mapping = {
    value: "",
    fontSize: FontSize,

    id: shortid(),
    ...props,
    dimensions: {
      width: 125,
      ...props.dimensions,
    },
    type: MappingTypes.Text,
  };

  invariant(
    mapping.fontSize,
    `You must provide a 'fontSize' for the mapping in order to set the mapping height`
  );

  mapping.dimensions.height = mapping.fontSize;
  // Ensure unique labels
  mapping.label = `Mapping_${mapping.id}`;

  // @ts-expect-error refactor
  return withRawValue(mapping);
}

/**
 * Creates a new `Checkmark` mapping.
 *
 * NOTE: All props are overridable except:
 *   - dimensions: To ensure these are always square
 *   - type
 *   - id
 *   - label
 */
export function Checkmark(props: Partial<PdfMappingType>) {
  const mapping = omit(
    {
      value: "",
      id: shortid(),
      ...props,
      dimensions: {
        width: 24,
        height: 24,
      },
      type: MappingTypes.Checkmark,
    },

    // Backend will fail if it finds fontSize for checkmark?
    ["fontSize"]
  );

  // Ensure unique labels
  mapping.label = `Mapping_${mapping.id}`;

  // @ts-expect-error refactor
  return withRawValue(mapping);
}

/**
 * Creates a new `Signature` mapping type
 *
 * Dimensions are fixed for this to start
 */
export function Signature(props: Partial<PdfMappingType>) {
  const mapping = withRawValue(
    // @ts-expect-error refactor
    omit(
      {
        value: "",
        id: shortid(),
        ...props,
        type: MappingTypes.Signature,
        dimensions: {
          width: 200,
          height: 40,
        },
      },
      // Backend will fail if it finds fontSize for Signature?
      ["fontSize"]
    )
  );

  // Ensure unique labels
  mapping.label = `Mapping_${mapping.id}`;

  return mapping;
}

/**
 * Creates a new `Multi line` mapping.
 *
 * NOTE: All props are overridable except:
 *   - type
 *   - height: Computed based on the width and font size
 */
export function Multiline(props: Partial<PdfMappingType>, maxLength?: number) {
  const mapping = {
    value: "",
    fontSize: FontSize,

    id: shortid(),
    ...props,
    dimensions: {
      width: 200,
      ...props.dimensions,
    },
    type: MappingTypes.Multiline,
  };

  invariant(
    mapping.fontSize,
    `You must provide a 'fontSize' for the mapping in order to set the mapping height`
  );

  // We add a padding to make sure the lines are visible
  const fontPadding = 4;

  // We add a number of lines that will fit the characters
  const lines = linesToFitChars(maxLength || 500, {
    width: mapping.dimensions.width,
    fontSize: mapping.fontSize,
  });

  // We start with 2 lines
  mapping.dimensions.height = mapping.fontSize * lines + fontPadding;
  // Ensure unique labels
  mapping.label = `Mapping_${mapping.id}`;

  // @ts-expect-error refactor
  return withRawValue(mapping);
}

/**
 * Creates a new mapping for a `TextInput` field
 */
export function TextInput(field: any, mapping: Partial<PdfMappingType>) {
  invariant(field.dataRef, `Field ${field.id} does not have a dataRef`);

  return Text({
    ...mapping,
    source: field.id,
    value: ValueExpr.Formats.single(field.dataRef),
  });
}

/**
 * Creates a new mapping for a `DateInput` field
 */
export function DateInput(field: any, mapping: Partial<PdfMappingType>) {
  invariant(field.dataRef, `Field ${field.id} does not have a dataRef`);

  return Text({
    ...mapping,
    source: field.id,
    value: ValueExpr.Formats.date(field.dataRef),
  });
}

/**
 * Creates a new mapping for a `Multiline` field
 */
export function MultilineMapping(field: any, mapping: Partial<PdfMappingType>) {
  invariant(field.dataRef, `Field ${field.id} does not have a dataRef`);

  return Multiline(
    {
      ...mapping,
      source: field.id,
      value: ValueExpr.Formats.single(field.dataRef),
    },
    field.maxLength
  );
}

/**
 * Creates a new mapping for an `AddressGroup` field
 */
export function AddressGroup(field: any, mapping: Partial<PdfMappingType>) {
  return Text({
    ...mapping,
    value: ValueExpr.Formats.AddressGroup.SingleLine(field),
    options: {
      format: AddressFormats.SingleLine,
    },
    source: field.id,
    dimensions: {
      // TODO: Better flow types
      // This gets thrown away. The height is taken from the font size but
      // flow wants a number
      height: 0,

      // TODO: Automated default width?
      // Setting this to a hard-coded value for now so that the full address
      // group label fits without truncation
      width: 303,
    },
    meta: {
      // TODO: All mappings should have group IDs
      group: shortid(),
      ...mapping.meta,
    },
  });
}

/**
 * Converts an existing `AddressGroup` mapping from one format to another
 */
AddressGroup.convertFormat = function convertFormat(
  mapping: PdfMappingType,
  field: any,
  nextFormat: string
) {
  const nextMapping = setIn(mapping, ["options", "format"], nextFormat);

  if (nextFormat === AddressFormats.SingleLine) {
    // @ts-expect-error refactor
    nextMapping.value = ValueExpr.AddressGroup.SingleLine(field);
  } else if (nextFormat === AddressFormats.SeparateFields) {
    const targetChild = field.children[0];
    if (targetChild) {
      // @ts-expect-error refactor
      nextMapping.value = ValueExpr.Formats.single(targetChild.dataRef);
    }
  } else if (nextFormat === AddressFormats.TwoLines) {
    // @ts-expect-error refactor
    nextMapping.value = ValueExpr.AddressGroup.FullStreet(field);
  } else if (nextFormat === AddressFormats.ThreeLines) {
    // @ts-expect-error refactor
    nextMapping.value = ValueExpr.AddressGroup.StreetAddress(field);
  } else if (nextFormat === AddressFormats.FourLines) {
    // @ts-expect-error refactor
    nextMapping.value = ValueExpr.AddressGroup.FullStreet(field);
  }

  return nextMapping;
};

/**
 * Creates a new mapping for a `MultipleChoice` field
 */
export function MultipleChoice(field: any, mapping: Partial<PdfMappingType>) {
  return MultipleChoice.createText(field, mapping);
}

/**
 * Creates a text-based representation of a `MultipleChoice` field
 */
MultipleChoice.createText = function createMultipleChoiceText(
  field: any,
  mapping: Partial<PdfMappingType>
) {
  return MultipleChoice.syncMappingAndField(
    Text({
      ...mapping,
      source: field.id,
      value: ValueExpr.Formats.single(field.dataRef),
      meta: {
        // TODO: All mappings should have group IDs
        group: shortid(),
        ...mapping.meta,
      },
    }),
    field
  );
};

/**
 * Creates a checkbox-based representation of a `MultipleChoice` field. We need
 * to know the index of the option that the mapping is pointing to so that we
 * can keep it in sync in the event that the option value changes or is moved
 */
MultipleChoice.createCheckbox = function createMultipleChoiceCheckbox(
  field: any,
  attrs: Partial<PdfMappingType>,
  optionIndex: number
) {
  const mapping = MultipleChoice.syncMappingAndField(
    Checkmark({
      ...attrs,
      id: shortid(),
      source: field.id,
      meta: {
        // TODO: All mappings should have group IDs
        group: shortid(),
        ...attrs.meta,
        optionIndex,
      },
    }),
    field
  );

  invariant(
    mapping.meta?.optionIndex !== undefined,
    "All checkbox mappings for multiple choice should have an 'optionIndex' meta value"
  );

  return mapping;
};

/**
 * A specialized form of `isLinkedTo` that returns true if a mapping is linked
 * to a field. This only returns `true` if:
 *
 *   - The mapping has a source pointing to the field id (true for all field types)
 *   - The mapping has a `meta.optionIndex` value within the range of the
 *     field's options
 *   - The value extracted from the mapping value is the same as the value at
 *     field.options[meta.optionIndex]
 */
MultipleChoice.isLinkedTo = function isLinkedToMultipleChoice(
  mapping: PdfMappingType,
  field: any
) {
  if (!isSourcedBy(mapping, field)) return false;

  switch (mapping.type) {
    case MappingTypes.Checkmark: {
      const optionIndex = mapping.meta?.optionIndex;
      if (optionIndex === undefined) return false;
      if (optionIndex < 0 || optionIndex >= field.options.length) {
        return false;
      }
      const extractedValue = ValueExpr.MultipleChoice.extractOption(
        mapping.value
      );
      return field.options.includes(extractedValue);
    }
    default:
      return true;
  }
};

// @ts-expect-error refactor
MultipleChoice.getDisplayFormat = function getFormat(mapping) {
  if (mapping.type === MappingTypes.Text) return MultipleChoiceFormats.Text;
  if (mapping.type === MappingTypes.Checkmark)
    return MultipleChoiceFormats.Checkboxes;

  throw new Error(
    `Could not determine display format for mapping ${mapping.type}`
  );
};

/**
 * This function syncs the mapping value and with the field
 * settings. For `MultipleChoice` components, the mapping value changes
 * depending on the value of the `allowMultiple` setting.
 */
MultipleChoice.syncMappingAndField = function syncMappingAndField(
  // @ts-expect-error refactor
  mapping,
  // @ts-expect-error refactor
  field
) {
  switch (mapping.type) {
    case MappingTypes.Text:
      return {
        ...mapping,
        value: ValueExpr.Formats.MultipleChoice.Text(field),
      };
    case MappingTypes.Checkmark: {
      invariant(
        mapping.meta?.optionIndex !== undefined,
        "Must have an optionIndex"
      );

      // TODO: More explit initial option creation. This gets set to `0`
      // because we call this function when creating a `Checkmark` type
      // mapping for the first time.
      const option = field.options[mapping.meta?.optionIndex ?? 0];

      invariant(
        option !== undefined,
        "Mapping value should be tied to a field option"
      );

      return {
        ...mapping,
        value: ValueExpr.Formats.MultipleChoice.Checkbox(field, option),
      };
    }
    default:
      throw new Error("Unknown mapping type for field type MultipleChoice");
  }
};

/**
 * A function that takes the entire list of mappings and sync all mappings
 * linked to that field so that their values match the option in the field
 */
MultipleChoice.syncAllToField = function syncAllMappingsToMultipleChoiceField(
  mappings: Array<PdfMappingType>,
  field: any
) {
  return updateWhere(
    mappings,
    (mapping) => mapping.source === field.id,
    (mapping) => MultipleChoice.syncMappingAndField(mapping, field)
  );
};

/**
 * Converts an existing `MultipleChoice` mapping from one format to another.
 * The final value of the mapping depends on the settings of the field in this
 * case.
 */
MultipleChoice.convertFormat = function convertFormat(
  mapping: PdfMappingType,
  field: any,
  nextFormat: string
) {
  if (nextFormat === MultipleChoiceFormats.Text) {
    const convertedFormat = Text({
      ...mapping,
      dimensions: {
        ...mapping.dimensions,
        width: 250,
      },
    });

    return MultipleChoice.syncMappingAndField(convertedFormat, field);
  } else if (nextFormat === MultipleChoiceFormats.Checkboxes) {
    invariant(
      mapping.meta && mapping.meta.group,
      `A mapping in Checkmark format should always have a group ID`
    );

    return MultipleChoice.syncMappingAndField(
      Checkmark({
        ...mapping,
        meta: {
          optionIndex: 0,
          ...mapping.meta,
        },
      }),
      field
    );
  }

  throw new Error(`Unknown multiple choice format given ${nextFormat}`);
};

/**
 * Takes the entire list of mappings and updates the `optionIndex` value for
 * each mapping so that it matches the new order of the field options
 */
MultipleChoice.reorderOptionsFor = function reorderMultipleChocieOptionsFor(
  mappings: Array<PdfMappingType>,
  field: any,
  newOrder: Array<number>
) {
  if (field.type !== "MultipleChoice") return mappings;

  return updateWhere(
    mappings,
    (mapping) => isLinkedTo(mapping, field),
    (mapping) => ({
      ...mapping,
      meta: {
        ...mapping.meta,
        // @ts-expect-error refactor
        optionIndex: newOrder[mapping.meta.optionIndex],
      },
    })
  );
};

/**
 * Takes the entire list of mappings and does the following:
 *
 *   - Filters the mapping with `optionIndex` equal to the one being removed
 *   - Shifts the `optionIndex` of any related mappings so that they point to
 *     the correct option values
 */
MultipleChoice.removeOptionAt = function removeMultipleChoiceOptionAt(
  mappings: Array<PdfMappingType> = [],
  fieldBeforeUpdate: any,
  // @ts-expect-error refactor
  updatedField,
  removedIndex: number
) {
  const nextMappings = [];
  // @ts-expect-error refactor
  let deletedMapping;

  for (const mapping of mappings) {
    if (
      isLinkedTo(mapping, fieldBeforeUpdate) &&
      mapping.type === MappingTypes.Checkmark
    ) {
      const optionIndex = mapping.meta?.optionIndex;

      // Easier flow types. If we've gotten this far we should have an
      // `optionIndex`
      if (optionIndex === undefined) {
        nextMappings.push(mapping);
      }

      // If this is the mapping being removed, skip it in this loop so it
      // gets filtered out, but capture the value so we can copy it back in
      // if needed
      else if (optionIndex === removedIndex) {
        deletedMapping = mapping;
      }

      // This `optionIndex` is greater than the removed index so we need to
      // decrement it so it's pointing to the correct option
      else if (optionIndex > removedIndex) {
        nextMappings.push({
          ...mapping,
          meta: {
            ...mapping.meta,
            optionIndex: optionIndex - 1,
          },
        });
      }

      // Otherwise it's below the removed index and it can be kept as is
      else if (optionIndex < removedIndex) {
        nextMappings.push(mapping);
      }
    } else {
      nextMappings.push(mapping);
    }
  }

  // @ts-expect-error refactor
  function ensureAtLeastOneMapping(mappings) {
    // @ts-expect-error refactor
    const found = mappings.find((mapping) => isLinkedTo(mapping, updatedField));

    // We have at least one mapping linked to this field, so return mappings
    // as is
    if (found) return mappings;

    // This case shouldn't ever be true but I'm including it anyways
    // @ts-expect-error refactor
    if (!deletedMapping) {
      logger.warn("Expected to delete a mapping but didn't");
      return mappings;
    }

    if (updatedField.options.length === 0) {
      // We should prevent this from happening. This means a `MultipleChoice`
      // field exists with no options.
      logger.warn("Deleting all mappings for a 'MultipleChoice' field");
      return mappings;
    }

    return [
      ...mappings,
      MultipleChoice.createCheckbox(updatedField, deletedMapping, 0),
    ];
  }

  return ensureAtLeastOneMapping(nextMappings);
};

export function SignatureField(field: any, mapping: PdfMappingType) {
  return Signature({
    ...mapping,
    label: `Mapping_${shortid()}`,
    source: field.id,
    value: ValueExpr.Formats.single(field.dataRef),
  });
}

/**
 * Duplicates a mapping by creating a new mapping and copying over any
 * additional provided props
 */
export function duplicate(
  mapping: PdfMappingType,
  props: Partial<PdfMappingType>
) {
  return {
    ...mapping,
    ...props,
    id: shortid(),

    // Labels have to be unique for the backend
    label: `Mapping_${shortid()}`,
  };
}
