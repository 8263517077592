/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { getIn } from "timm";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import * as SchemaState from "../schema-state";
import * as Validation from "../validation";
import SchemaError from "./schema-error";
import type { DraftState } from "../state";

type Props = {
  draft: DraftState;
  errors: Array<DraftFeedback>;
};

/**
 * A higher-order component for wrapping tabs that deal with the parsed
 * schema state.  If the schema is in an error state, a fallback component
 * will be rendered.  If the schema is parsedable, a `parsedSchema` props will
 * be injected for convenience.
 *
 * @example
 * function WorkflowTab(props) {
 *   const { parsedSchema } = props;
 *
 *   // ....
 * }
 *
 * export default parsedSchemaEditor(WorkflowTab)
 */
function parsedSchemaEditor<P extends Props>(
  Component: React.ComponentType<P>
): React.ComponentType<any> {
  return function ParsedSchemaEditor(props: P) {
    const { draft, errors } = props;

    if (!SchemaState.isParsed(draft.schema)) {
      throw new Error("Trying to edit a parsed schema in raw mode!");
    }

    const canRender =
      SchemaState.isSuccessfullyParsed(draft.schema) &&
      Validation.canRenderParsedView(errors);

    if (!canRender) {
      return <SchemaError />;
    }

    return (
      <Component
        {...props}
        parsedSchema={getIn(draft, ["schema", "value", "value"])}
      />
    );
  };
}

export default parsedSchemaEditor;
