import type { Position, Type } from "./types";

/**
 * In the previous iteration of this component, we could fit the contents to
 * the container more easily because it was a direct child.  Now that we're
 * rendering via portal, we have to explicitly compute the width, so it's
 * now opt in.  For legacy reasons, certain configurations will have this
 * enabled by default
 */
export const shouldFitToContainer = ({
  type,
  position,
  fitToContainer,
}: {
  type: Type;
  position: Position;
  fitToContainer?: boolean;
}) =>
  fitToContainer !== undefined
    ? fitToContainer
    : type === "select" && (position === "bottom" || position === "top");

function isScrollParent(element: HTMLElement) {
  try {
    const { overflow, overflowY, overflowX } = window.getComputedStyle(element);
    return /(auto|scroll)/.test(overflow + overflowX + overflowY);
  } catch (e) {
    return false;
  }
}

/**
 * Returns the scrolling parent of the given element
 */
export function getScrollParent(
  element: HTMLElement | null
): HTMLElement | Window | null {
  if (!element || element.tagName === "BODY") {
    return window;
  } else if (isScrollParent(element)) {
    return element;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getScrollParent(element.parentNode);
  }
}
