import * as React from "react";

type Ref<V> =
  | React.RefCallback<V>
  | React.MutableRefObject<V>
  | React.ForwardedRef<V>;

/**
 * The following two functions are helpers for composing refs in components.
 * Refs are often important for dealing with imperative things like focus and
 * when composing multiple compoents we may need to both use a ref internally,
 * and expose to the parent with `forwardRef`
 */

/**
 * Sets ref with a value based on the ref kind
 */
export function setRef<V = unknown>(ref: Ref<V>, value: V) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

/**
 * A hook for using a 'forked' ref. This can be used if you want to have a
 * component forward a ref, but also have access to the ref within the component.
 * Often used with `setRef` above
 */
export function useForkRef<V>(refA: Ref<V>, refB: Ref<V>) {
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue: unknown) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}

let id = 0;
const genId = () => "hwui-" + ++id;

export function useId(idFromProps?: string) {
  return idFromProps ?? genId();
}
