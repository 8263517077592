// TODO: fix types
// import type { Field } from "./types";
//
// type FieldConstantsType = {
//   [key: $PropertyType<Field, "type">]: $PropertyType<Field, "type">,
// };
//
// /**
//  * Runtime type info.  This can be used in place of strings for runtime
//  * detection of fields if needed.
//  */
export const FieldConstants = {
  AddressGroup: "AddressGroup",
  Boolean: "Boolean",
  Date: "Date",
  EIN: "EIN",
  Group: "Group",
  Hidden: "Hidden",
  Initials: "Initials",
  List: "List",
  MultipleChoice: "MultipleChoice",
  Paragraph: "Paragraph",
  SelectGroup: "SelectGroup",
  SelectList: "SelectList",
  SelectTree: "SelectTree",
  SSN: "SSN",
  SIN: "SIN",
  Signature: "Signature",
  TextInput: "TextInput",
  Toggle: "Toggle",
  RoutingNumber: "RoutingNumber",
  DateInput: "DateInput",
};
