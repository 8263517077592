/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/**
 * Commands
 *
 * Comamnds are instructions to the editor to change the state or imperatively
 * perform some action.  Because different commands can compete for the same
 * keymap or input based on the state of the editor, a command should return
 * `true` if it actually handled an action, or `false` if it did not.
 */
import { setBlockType } from "prosemirror-commands";
import { wrapInList, liftListItem } from "prosemirror-schema-list";
import type { NodeType } from "prosemirror-model";

import type { Command } from "./types";
import * as utils from "./utils";

export const toggleHeading = (type: NodeType, attrs: {}): Command => {
  return function _toggleHeading(state, dispatch) {
    if (!isHeading(type, state.schema.nodes)) {
      return false;
    }

    const { paragraph, heading } = state.schema.nodes;

    if (utils.isBlockActive(state, type, attrs)) {
      return setBlockType(paragraph)(state, dispatch);
    } else {
      return setBlockType(heading, attrs)(state, dispatch);
    }
  };
};

/**
 * There are lots of ways to handling "toggling" of lists and I'm not sure
 * exactly which experience want yet.  For now I'm attempting to keep it simple
 * with the following:
 *
 * - At the top level of the list, either switch to the new list type or
 *   outdent the list based on the target `listType` given.
 * - If within a nested list, outdent the list
 */
export function toggleList(listType: NodeType): Command {
  return function _toggleList(state, dispatch, view) {
    if (!isList(listType, state.schema.nodes)) {
      return false;
    }

    const lift = liftListItem(state.schema.nodes.listItem);
    const wrap = wrapInList(listType);
    const { $from, $to } = state.selection;
    const range = $from.blockRange($to);

    if (!range) {
      return false;
    }

    // Look up the node tree one level and if the parent is the same list type
    // "lift" it one level
    if (range.depth >= 2 && $from.node(range.depth - 1).type === listType) {
      return lift(state, dispatch);
    }

    // Look up the tree one level and if the parent is a list, swap it to the
    // given `listType`
    else if (
      range.depth >= 2 &&
      isList($from.node(range.depth - 1).type, state.schema.nodes)
    ) {
      // First lift one level out
      lift(state, dispatch);

      // Then wrap into thte new `listType`
      // Note the use of `view.state` here.  The `lift` call returns a new state,
      // so we have to use `view.state` to reference the most up-to-date state.
      // Not sure if there's a better way to do this
      if (view) {
        wrap(view.state, dispatch);
      }

      return true;
    } else {
      return wrap(state, dispatch);
    }
  };
}

function isHeading(nodeType: NodeType, schemaNodes: any) {
  return nodeType === schemaNodes.heading;
}

function isList(nodeType: NodeType, schemaNodes: any) {
  return (
    nodeType === schemaNodes.bulletList || nodeType === schemaNodes.orderedList
  );
}
