import React from "react";
import { createComponent } from "hw/ui/style";

const LABEL_PLACEHOLDER = "[Condition]";
type Props = {
  label?: string;
};

/**
 * This is a one-off of the label we currently have in smartforms (and the common
 * library), since this one shouldn't be visible at all in smartforms.
 */
const Label = createComponent(function Label({ theme }) {
  return {
    color: theme.color.gray800,
    fontSize: theme.fontSize.ms,
    fontWeight: theme.fontWeight.semibold,
    wordBreak: "break-word",
  };
});
const WrapperLabel = createComponent(function WrapperLabel({ theme }) {
  return {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.space.ms,
    marginRight: theme.space.xs,
  };
});

function ConditionalLabel(props: Props) {
  const { label } = props;
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
    <WrapperLabel>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: s... Remove this comment to see the full error message */}
      <Label>{label || LABEL_PLACEHOLDER}</Label>
    </WrapperLabel>
  );
}

export default React.memo<Props>(ConditionalLabel);
