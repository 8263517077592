import accepts from "attr-accept";

/**
 * Utilities for managing file attachments.
 */

export const validFileFormats = [
  ".doc",
  ".docx",
  ".pdf",
  ".pptx",
  ".jpg",
  ".jpeg",
  ".png",
  ".xlsx",
  ".txt",
  ".gif",
];

export const fileStatus = {
  pending: "pending",
  rejected: "rejected",
  serviceUnavailable: "serviceUnavailable",
  success: "success",
  invalid: "invalid",
  timeout: "timeout",
};

// 20mb is our client side maximum, the server supports up to 40mb
export const maxImportSize = 20000000;
// Used to approximate the load time
export const bitesPerSecond = 20000;
// Wait 1 millisecond per 25 bytes.
export const timeOutInterval = 1 / 25;
// 5 minutes = 1000 milliseconds * 60 seconds * 5
export const timeOutMinimum = 300000;

export const isFormatValid = (file) => accepts(file, validFileFormats);
