import React from "react";

type Props = React.HTMLAttributes<HTMLTableSectionElement>;

export function TableBody(
  props: Props & { forwardedRef: React.ForwardedRef<HTMLTableSectionElement> }
) {
  const { forwardedRef, ...rest } = props;

  return <tbody {...rest} ref={forwardedRef} />;
}

export default React.forwardRef<HTMLTableSectionElement, Props>(
  function ForwardedTableBody(props, ref) {
    return <TableBody {...props} forwardedRef={ref} />;
  }
);
