/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { EditorView } from "prosemirror-view";

import HoverCard from "hw/ui/hover-card";
import { key } from "./plugin";
import EditWindow from "./edit-window";
import * as commands from "./commands";
import type { ActiveLink } from "./types";

/**
 * Renders the "edit window" for editing attributes on link nodes
 */
export default class LinkEditWindow extends React.Component<any> {
  render() {
    const { view, editorActive } = this.props;
    const pluginState = key.getState(view.state);

    return pluginState.activeLink && editorActive ? (
      <ActiveEditWindow view={view} link={pluginState.activeLink} />
    ) : null;
  }
}

type Props = {
  view: typeof EditorView;
  link: ActiveLink;
};

class ActiveEditWindow extends React.Component<Props> {
  runCommand(command: any) {
    const { view } = this.props;

    command(view.state, view.dispatch, view);
  }

  updateHref = (val: string) =>
    this.runCommand(commands.updateLinkHref(this.props.link, val));

  unlink = () => this.runCommand(commands.unlink(this.props.link));

  /**
   * The edit window renders in a portal that's outside of the editor DOM
   * tree, so we need to do some manual focusing in certain cases.  The
   * `EditWindow` component will call the `onExit` in cases that call for the
   * editor to be refocused
   */
  handleExit = () => this.runCommand(commands.exitWindow);

  render() {
    const { view, link } = this.props;

    if (!link) return null;

    // Get the DOM element
    const { node, offset } = view.domAtPos(link.range.from);
    const anchor = node?.childNodes?.[offset];
    const href = link.href;

    return anchor instanceof HTMLElement ? (
      <HoverCard
        renderDest="portal"
        active={true}
        type="dropdown"
        anchor={anchor}
      >
        <EditWindow
          value={href}
          onChange={this.updateHref}
          onUnlink={this.unlink}
          onExit={this.handleExit}
        />
      </HoverCard>
    ) : null;
  }
}
