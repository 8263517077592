import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  /**
   * The content of the menu list
   */
  children: React.ReactNode;
  extend?: Extend;
};

/**
 * Renders a list of menu items.
 */
function MenuList(props: Props) {
  const { extend = {}, ...rest } = props;
  return (
    <div
      {...rest}
      className={useStyle(
        "ui-menu-list",
        {
          marginTop: theme.space.xs,
          marginBottom: theme.space.xs,
        },
        extend
      )}
    />
  );
}

export default MenuList;
