/**
 * Field utils - These are utitlies for operating on fields.
 *
 * NOTE: Make sure to return a full rect if making changes to `x` and `y`
 * values.
 */
import type { Field, ResizeOrigin } from "./types";
import { AccidentalCollision } from "./constants";

/**
 * Updates the field with the given properties. It's important to use this
 * function when making changes to a field to ensure that it results in a
 * property `rect`, e.g. Changing the `with` also changes the `right`
 */
export function assign(
  field: Field,
  partial: { x: number; y: number; [otherKey: string]: unknown }
): Field {
  return expandRect({
    ...field,
    ...partial,
  });
}

/**
 * This function attempts to nudge fields into place in a best-effort way.
 * Meaning, if the field collides with a field within a certain threshold,
 * then attempt to nudge it so it's no longer colliding. The idea is to be
 * helpful when we think users are trying to place a field next to another,
 * but are just off slightly. If a user places and overlap over the threshold,
 * assume they know what they are doing and allow it. Always fallback to
 * the original placement if we can't find a spot
 */
const maxTries = 3;

export function bestEffortNudge(originalField: Field, fields: Field[]) {
  function runBestEffortNudge(
    field: Field,
    fields: Field[],
    tries: number
  ): Field {
    if (tries > maxTries) return originalField;
    let collision;

    for (const otherField of fields) {
      // Skip the field in question
      // Make sure we're only comparing fields on the same page
      if (otherField.id !== field.id && otherField.page === field.page) {
        const collisions = getCollisions(
          [field, otherField],
          AccidentalCollision.minThreshold,
          AccidentalCollision.maxThreshold
        );

        // Just grab the first collision and stop here
        if (collisions[0]) {
          collision = collisions[0];
          break;
        }
      }
    }

    if (!collision) return field;

    let dx = 0;
    let dy = 0;

    if (collision.width <= collision.height) {
      const offset = collision.width;
      dx = collision.x > field.x ? -offset : offset;
    } else {
      const offset = collision.height;
      dy = collision.y > field.y ? -offset : offset;
    }

    const movedField = assign(field, {
      x: field.x + dx,
      y: field.y + dy,
    });

    return runBestEffortNudge(movedField, fields, tries + 1);
  }

  return runBestEffortNudge(originalField, fields, 0);
}

/**
 * Adjust the field to ensure it's within the bounds of the page dimensions.
 * There are two 'strategies':
 *
 *   - `coords` will adjust the `x` and `y` values to bring the field into the
 *      page. This is what's used when dragging
 *   - `dims` will adjust the `width` and `height` to bring the field into the
 *      page. This is used when resizing
 */
export function ensureInPage(
  field: Field,
  page: { width: number; height: number },
  strategy: "coords" | "dims" = "coords"
) {
  let { x, y, width, height } = field;
  const { lockAspectRatio } = field;
  const originalRatio = height / width;

  // Field is extending past the right of the page
  if (field.right > page.width) {
    const offset = field.right - page.width;
    if (strategy === "dims") {
      // Decrease the width by the amount that's off the page
      width -= offset;
    } else {
      // Nudge the `x` value left until it's on the page
      x -= offset;
    }
  }

  // Field is extend past the left of the page
  else if (field.left < 0) {
    // In either strategy, set the `x` value to `0`
    x = 0;

    if (strategy === "dims") {
      const offset = 0 - field.left;
      // Decrease the `width` by the offset amount
      width -= offset;
    }
  }

  // Field is extending past the bottom of the page
  if (field.bottom > page.height) {
    const offset = field.bottom - page.height;
    if (strategy === "dims") {
      // Decrease the height by the offset amount
      height -= offset;
    } else {
      // Nudge the `y` value up until it's on the page
      y -= offset;
    }
  }

  // Field is extending past the top of the page
  else if (field.top < 0) {
    // In either strategy, set `y` to `0`
    y = 0;

    if (strategy === "dims") {
      const offset = 0 - field.top;
      // Decrease the height by the offset amount
      height -= offset;
    }
  }

  if (lockAspectRatio) {
    height = width * originalRatio;
  }

  return assign(field, {
    // Ensure `x` and `y` are always greater than or equal to 0. This still leaves the
    // possibility that the fields will extend past the right and bottom
    // edge, but the scroll container will still allow users to reach these
    // fields
    x: Math.max(x, 0),
    y: Math.max(y, 0),
    width,
    height,
  });
}

/**
 * Sorts fields based on their `page`, `x`, and `y` values
 */
export function sortFields<T>(
  fields: T[],
  toFieldRect: (item: T) => Field
): T[] {
  return fields.slice().sort((_a, _b) => {
    const a = toFieldRect(_a);
    const b = toFieldRect(_b);
    const pageDiff = a.page - b.page;
    if (pageDiff !== 0) return pageDiff;
    if (intersectY(a, b)) {
      return a.x - b.x;
    }
    return a.y - b.y;
  });
}

/**
 * Sort fields by a particular property
 *
 * NOTE: This is in ascending order by default. Use `reverse` if you want
 * descending
 */
export function sortFieldsBy(
  fields: Field[],
  prop:
    | "x"
    | "y"
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "width"
    | "height"
    | "page"
): Field[] {
  return fields.slice().sort((a, b) => {
    const aProp = a[prop];
    const bProp = b[prop];

    return aProp - bProp;
  });
}

/**
 * Returns a list of collision rects for the given fields
 */
export function getCollisions(
  fields: Field[],
  minThreshold = 10,
  maxThreshold = Infinity
) {
  const collisions = [];
  for (let current = 0; current < fields.length; current++) {
    for (let next = current + 1; next < fields.length; next++) {
      const currentField = fields[current];
      const nextField = fields[next];
      if (currentField && nextField) {
        const intersection = getIntersection(currentField, nextField);
        if (intersection.width > 0 && intersection.height > 0) {
          if (
            (intersection.width > minThreshold &&
              intersection.width < maxThreshold) ||
            (intersection.height > minThreshold &&
              intersection.height < maxThreshold)
          ) {
            collisions.push(intersection);
          }
        }
      }
    }
  }
  return collisions;
}

/**
 * Resizes the given field based on the `origin`, `dx`, and `dy` values
 */

// Potentially configurable
const minimumWidth = 10;
const minimumHeight = 10;

export function resize(
  field: Field,
  origin: ResizeOrigin,
  dx: number,
  dy: number
) {
  const { lockAspectRatio } = field;
  let { x, y, width, height } = field;

  const invertY = /n/.test(origin);
  const invertX = /w/.test(origin);

  if (invertX) {
    dx = -dx;
  }

  if (invertY) {
    dy = -dy;
  }

  // Likely more succint ways to derive this logic
  switch (origin) {
    case "w":
      width += dx;
      x -= dx;
      height = lockAspectRatio ? height + dx : height;
      break;
    case "nw":
      width += dx;
      x -= dx;
      height += lockAspectRatio ? dx : dy;
      y -= lockAspectRatio ? dx : dy;
      break;
    case "n":
      height += dy;
      y -= dy;
      width = lockAspectRatio ? width + dy : width;
      break;
    case "ne":
      height += lockAspectRatio ? dx : dy;
      y -= lockAspectRatio ? dx : dy;
      width += dx;
      break;
    case "e":
      width += dx;
      height = lockAspectRatio ? height + dx : height;
      break;
    case "se":
      height += lockAspectRatio ? dx : dy;
      width += dx;
      break;
    case "s":
      height += dy;
      width = lockAspectRatio ? width + dy : width;
      break;
    case "sw":
      height += lockAspectRatio ? dx : dy;
      width += dx;
      x -= dx;
      break;
    default:
      throw new Error(`Unknown origin '${origin}'`);
  }

  // Ensure we don't go over the minimums
  width = Math.max(minimumWidth, width);
  height = Math.max(minimumHeight, height);
  x = Math.min(x, field.x + field.width - minimumWidth);
  y = Math.min(y, field.y + field.height - minimumHeight);

  return assign(field, {
    x,
    y,
    width,
    height,
  });
}

/**
 * -- Utils --
 */

function intersectY(a: Field, b: Field) {
  return a.y < b.y + b.height && a.y + a.height > b.y;
}

export type Intersection = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export function getIntersection(a: Field, b: Field): Intersection {
  const x = Math.max(a.x, b.x);
  const y = Math.max(a.y, b.y);
  const xx = Math.min(a.x + a.width, b.x + b.width);
  const yy = Math.min(a.y + a.height, b.y + b.height);
  return { x, y, width: xx - x, height: yy - y };
}

/**
 * Ensures that a field is a proper `rect` by computing the `right` and
 * `bottom` values based on `x`, `y`, `width`, and `height`.
 *
 * NOTE: This assumes `x` and `y` are the properties being updated
 */
export function expandRect<
  F extends {
    x: number;
    y: number;
    width: number;
    height: number;
    [other: string]: unknown;
  }
>(field: F): F & { right: number; bottom: number; left: number; top: number } {
  const { x, y, width, height } = field;

  return {
    ...field,
    x,
    y,
    left: x,
    top: y,
    right: x + width,
    bottom: y + height,
    width: Math.floor(width),
    height: Math.floor(height),
  };
}
