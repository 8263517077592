import React from "react";
import { BulletWrapper, BulletIcon } from "./styled";

export default function Bullet() {
  return (
    <BulletWrapper>
      <BulletIcon />
    </BulletWrapper>
  );
}
