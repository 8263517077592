import * as React from "react";
import { Flex } from "hw/ui/blocks";
import { TextBody1 } from "hw/ui/text";
import type { ParagraphField, Role } from "hw/portal/modules/common/draft";
import { Wrapper } from "./common/basics";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  field: ParagraphField;
  roles: Array<Role>;
};
export default function ParagraphSettings(props: Props) {
  const { roles } = props;
  return (
    <Flex flexDirection="column">
      {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element[]; }' is missing the fol... Remove this comment to see the full error message */}
      <Wrapper>
        <TextBody1 extend={Copy}>
          Paragraph blocks will be visible to all participant roles assigned to
          this form.
        </TextBody1>
        <RoleSummary roles={roles} />
      </Wrapper>
    </Flex>
  );
} // TODO: Not sure these styles are all correct

function Copy() {
  return {
    fontStyle: "italic",
  };
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
function RoleSummaryStyle({ theme }) {
  return {
    fontWeight: theme.fontWeight.semibold,
  };
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'roles' implicitly has an 'any' ty... Remove this comment to see the full error message
function RoleSummary({ roles }) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
  const roleString = roles.map((role) => role.title).join(", ");
  return <TextBody1 extend={RoleSummaryStyle}>({roleString})</TextBody1>;
}

ParagraphSettings.defaultProps = {
  mergeFields: [],
};
