import * as React from "react";
import { createComponent } from "hw/ui/style";
import Field from "./field";
import { DRAG_TYPE_FIELD } from "../../constants";

const Wrapper = createComponent(({ width, height, theme }) => ({
  width: `${width}px`,
  height: `${height}px`,
  overflowY: "hidden",
  boxShadow: theme.shadow[3],
}));

/**
 * Custom drag preview for `Field` components.  This is a light wrapper around
 * the underlying `Field` component with some extra styles applied.  We get the
 * `width` from the dragged item spec because we set the field component to
 * fill the entire container.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FieldDragPreview extends React.PureComponent<any> {
  render() {
    const { width, height, ...rest } = this.props;
    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
      <Wrapper width={width} height={height}>
        <Field
          {...rest}
          // @ts-expect-error refactor
          mapping={[]}
          mergeFields={[]}
          roleDescriptions={{}}
          draggingType={DRAG_TYPE_FIELD}
          height={height}
          isDragLayer
        />
      </Wrapper>
    );
  }
}
export default FieldDragPreview;
