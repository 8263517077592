import React from "react";
import { Flex, Box } from "hw/ui/blocks";
import type { Role } from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import RoleBadge from "../../preview/role-badge";
import type { SettingsProps } from "../../../types";
import { Wrapper, Select } from "./basics";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openRoleModal: (...args: Array<any>) => any;
  assigned: Role["id"] | null | undefined;
  roles: Array<Role>;
  setFieldRole: (path: Path, roleId: string) => void;
  roleDescriptions: Record<
    Role["id"],
    {
      title: string;
      badge: string;
    }
  >;
};
type RoleOption = {
  id: string;
  label: string;
};
export default class RoleSetting extends React.PureComponent<Props> {
  handleSelect = (item: RoleOption) => {
    const { openRoleModal, setFieldRole, path } = this.props;

    if (item.id === "add-or-edit-roles") {
      openRoleModal();
    } else {
      return setFieldRole(path, item.id);
    }
  };

  renderSelected = (selectedItem: RoleOption | null | undefined) => {
    const { roleDescriptions } = this.props;

    if (!selectedItem) {
      return "Select role...";
    }

    const roleDesc = roleDescriptions[selectedItem.id];
    return (
      <Flex justifyContent="center" flexDirection="row" alignItems="center">
        <Box mr="sm">
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          <RoleBadge hasBorder>{roleDesc.badge}</RoleBadge>
        </Box>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {roleDesc.title}
      </Flex>
    );
  };

  triggerProps = {
    renderSelected: this.renderSelected,
  };

  render() {
    const { roles, assigned, roleDescriptions } = this.props;
    const roleDesc = assigned && roleDescriptions[assigned];
    const selectedRole = assigned &&
      roleDesc && {
        id: assigned,
        label: roleDesc.title,
      };
    const roleOptions = roles
      .map((role) => ({
        id: role.id,
        label: role.title,
      }))
      .concat({
        label: "+ Add or edit roles",
        id: "add-or-edit-roles",
      });
    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type '{ children: Elem... Remove this comment to see the full error message
      <Wrapper data-testid="role-setting" label="Assigned To">
        <Select
          triggerProps={this.triggerProps}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Option'.
          getItemKey={(role) => role.id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(item: RoleOption) => void' is not assignabl... Remove this comment to see the full error message
          onChange={this.handleSelect}
          // @ts-expect-error refactor
          selectedItem={selectedRole || null}
          placeholder="Select role..."
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: any; label: any; }[]' is not assignabl... Remove this comment to see the full error message
          options={roleOptions}
          fillContainer
        />
      </Wrapper>
    );
  }
}
