/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogger } from "hw/common/utils/logger";
import { getIn } from "timm";
import { invariant } from "hw/common/utils/assert";
import uuid from "nanoid/non-secure";

const logger = createLogger("draft-editor-state");
export const COMMIT = "COMMIT";
export const UNDO = "UNDO";
export const REDO = "REDO";
const PAST = "PAST";
const STAGED = "STAGED";
const defaultConfig = {};
export type History<State> = {
  past: any;
  future: any;
  staged: any;
  present: State;
};
type Action = any;
type Reducer<State> = (state: State, action: Action) => any;
type Config = {
  limit?: number;
};

/**
 * Returns the first item from the future stack.  This can be used to "subscribe"
 * to undo actions by checking if the first item on the history stack has
 * referentially chaged
 */
export const lastUndone = (history: any) => Stack.first(history.future);
export const lastCommitted = (history: any) => Stack.first(history.past);

/**
 * Undoable reducer maker.  This is a higher-order reducer that takes a reducer
 * and returns another reducer that tracks the state history.  When an action
 * comes through, it calls the given reducer to achieve the state result and
 * then stores that result in the history.  If an `UNDO` or `REDO` action is
 * dispatched, this reducer will handle restoring the correct state.  The
 * consumer of this function needs to access the current state with at the
 * `present` value.
 *
 * const reducer = undoable(function reducer(state, action) {
 *   return state;
 * });
 * const state = reducer({ count: 1 }, { type: "INC" })
 * //=> state.present.count === 2;
 *
 * This works by storing two stacks (undo and redo), one queue (staged), and
 * one current value (present).  When an action comes through with `change`
 * information, it's pushed to the `staged` queue.  At `commit` time, all
 * staged changes of the same type are batches as a single `past` event and pushed
 * to the `undo` stack.  The `past` stores a snapshot of the state for later
 * restoration.  Any undone event is pushed to the redo stack.
 *
 * There are some requirements to this undo/redo strategy:
 *   - All actions that change the state must be undoable.  If an action changes
 *     the state but is not undoable, we must clear the history so that an `undo`
 *     action doesn't restore and out-of-sync state.
 *  - State is immutable.  We use this to determine if the state has changed
 */
export function undoable<State>(reducer: Reducer<State>, config: Config = {}) {
  config = { ...defaultConfig, ...config };
  return function undoableReducer(state: any, action: any) {
    // Create the initial history if it hasn't already been created
    let history = isHistory(state) ? state : createHistory(state);
    let res;

    switch (action.type) {
      case undefined:
        res = history;
        history = res;
        break;

      case UNDO:
        history = undo(history);
        break;

      case REDO:
        history = redo(history);
        break;

      case COMMIT:
        res = reducer(history.present, action);
        history = commit(history, action, res, config);
        break;

      default:
        res = reducer(history.present, action);
        history = stage(history, action, res);
    }

    return history;
  };
}

/**
 * Takes the most recent event on the `future` stack and
 *   - Applies the snapshot to the current state
 *   - Stores the original event back into the `past` stack
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
function redo(history) {
  const { future, past } = history;

  if (future.length === 0) {
    logger.debug("Nothing to redo");
    return history;
  }

  // Grab the first future item off of the future stack
  const evt = Stack.first(future);
  invariant(
    evt.type === UNDO,
    "Trying to redo something other than an undo event"
  );
  // The next future will be all of the current future except for the first
  const nextFuture = Stack.rest(future);
  // Use the undone change from the future stack to restore the original state
  const nextPresent = evt.snapshot;
  const sourceEvent = evt.source;
  // Push the original change back to the past stack
  const change = PastEvent(sourceEvent);
  const newPast = Stack.push(past, change);
  return {
    ...history,
    present: nextPresent,
    future: nextFuture,
    past: newPast,
  };
}

/**
 * Takes the most recent event on the `past` stack and
 *   - Applies the snapshot to the current state
 *   - Pushes a special `UNDO` event to the future stack
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
function undo(history) {
  const { past, future } = history;

  if (past.length === 0) {
    logger.debug("Nothing to undo");
    return history;
  }

  // Pop the first change from the past stack
  const evt = Stack.first(past);
  invariant(
    evt.type === "PAST",
    "Trying to undo something other than a past event"
  );
  // Past stack will be all of the current past except for the first
  const newPast = Stack.rest(past);
  // Reset the state from the saved snapshot
  const newPresent = evt.snapshot;
  // Push an `UNDO` change to the future stack
  const undoEvent = UndoEvent(evt, history.present);
  const nextFuture = Stack.push(future, undoEvent);
  return { ...history, present: newPresent, past: newPast, future: nextFuture };
}

/**
 * Takes all of the staged changes and "commits" them to the past.  Only
 * committed changes can be undone.
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
function commit(history, action, nextPresent, config) {
  const { staged, past } = history;
  if (!staged.length) return history;
  const { limit } = config;
  const historyOverflow = limit && lengthWithoutFuture(history) >= limit;
  // Take the queue of staged changes and compact them so that we only have
  // one type of each change for this entire commit
  const mergedQueue = compactEvents(staged);
  // Push each staged change to the past stack
  let nextPast = Stack.of(past);

  for (const evt of mergedQueue) {
    nextPast = Stack.push(nextPast, PastEvent(evt));
  }

  return {
    ...history,
    staged: [],
    past: Stack.skipLast(nextPast, historyOverflow ? 1 : 0),
  };
}

/**
 * Takes an action and stages the change for committing later.  We can immediately
 * stage changes and update the state and then later batch those changes so
 * that they can be undone in one event
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
function stage(history, action, nextPresent) {
  // If this action has been explicitly marked as not undoable, then we will
  // clear the history stack as a precaution against invalid states
  const notUndoable = isNotUndoable(action);

  if (notUndoable) {
    logger.debug("This action is not undoable.  Clearing the history.");
    return createHistory(nextPresent);
  }

  const change = getChangeInfoFromAction(action);
  // If this action has no change information and the state has not changed,
  // no need to clear the history
  if (!change && history.present === nextPresent) return history;

  // If there is no change info and the state has changed, then an action
  // has changed the state that has not been marked as undoable.
  // Clear the state as a precaution against restoring an invalid state
  if (!change && history.present !== nextPresent) {
    logger.debug(
      "The state has changed but the last action was not marked as undoable. Clearing the history stack."
    );
    return createHistory(nextPresent);
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'null'.
  const { type, description } = change;
  const { staged } = history;
  const evt = StagedEvent({
    change: {
      type,
      description,
    },
    snapshot: history.present,
  });
  return {
    ...history,
    present: nextPresent,
    staged: [...staged, evt],
    // The redo stack should be cleared at this point because we can only
    // redo undone actions.  Undo actions are not staged, they are applied
    // immediately.
    future: [],
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function getChangeInfoFromAction(action) {
  return getIn(action, ["meta", "change"]);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function isNotUndoable(action) {
  return getIn(action, ["meta", "change", "undoable"]) === false;
}

const HISTORY = Symbol("history");

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function isHistory(state) {
  return state[HISTORY];
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function createHistory(state) {
  return {
    // $FlowFixMe
    [HISTORY]: true,
    staged: [],
    past: Stack.of([]),
    present: state,
    future: Stack.of([]),
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
function lengthWithoutFuture(history) {
  return history.past.length + 1;
}

/**
 * Describes a change corresponding to the event.
 */
type Change = {
  type: string;
  description: string;
};
type HistoryEvent = {
  // A unique ID for this history event
  id: string;
  // A local order for the event.  Useful for debugging
  order: number;
  // The change that caused the creation of this event
  change: Change;
  // The snapshot of the state _before_ the change was applied.
  snapshot: any;
};
type PastEventT = HistoryEvent & {
  type: "PAST";
};
type StagedEventT = HistoryEvent & {
  type: "STAGED";
};
type UndoEventT = HistoryEvent & {
  type: "UNDO";
  // Undo events have a source event that will be later pushed back into the
  // past if they are redone
  source: PastEventT;
};
let order = 0;

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'change' implicitly has an 'any' t... Remove this comment to see the full error message
function StagedEvent({ change, snapshot }): StagedEventT {
  return {
    type: STAGED,
    id: uuid(),
    order: order++,
    change,
    snapshot,
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'snapshot' implicitly has an 'any' type.
function UndoEvent(pastEvent: PastEventT, snapshot): UndoEventT {
  return {
    type: UNDO,
    id: uuid(),
    order: order++,
    change: {
      type: "Undo",
      description: "Undo the previous event",
    },
    snapshot,
    source: pastEvent,
  };
}

function PastEvent(config: StagedEventT | PastEventT): PastEventT {
  const { change, snapshot } = config;
  return {
    type: PAST,
    id: uuid(),
    order: order++,
    change,
    snapshot,
  };
}

/**
 * Takes a past event and a future event and merges them together so that the
 * future event points to the same snapshot as the past event.  This is used
 * to "chunk" actions so that they can be undone in a single swoop.
 *
 * @example
 * eventA = { snapshot: { count: 1 }, id: 'a' }
 * eventB = { snapshot: { count: 2 }, id: 'b' }
 * merged = mergeEvents(eventA, eventB)
 * //=> { snapshot: { count 1 }, id: 'b' }
 */
function mergeEvents(
  futureEvent: StagedEventT,
  pastEvent: StagedEventT
): StagedEventT {
  invariant(
    futureEvent.order > pastEvent.order,
    "Trying to merge changes in the wrong order!"
  );
  return { ...futureEvent, snapshot: pastEvent.snapshot };
}

/**
 * Takes an array and "compacts it" by merging sibling events together.
 *
 * @example
 * compactBy(
 *   [{ type: 'a , value: 1 }, { type: 'a', value: 2 }, { type: 'b', value: 1 }],
 *   (a, b) => a.type === b.type,
 *   { ...a, ...b }
 * )
 * //=> [{ type: 'a', value: 2 }, { type: 'b', value: 1 }]
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'arr' implicitly has an 'any' type.
function compactEvents(arr) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'changeA' implicitly has an 'any' type.
  const predicate = (changeA, changeB) => changeA.type === changeB.type;

  const compactor = mergeEvents;
  const compacted = [];

  for (const current of arr) {
    // @ts-expect-error ts-migrate(7022) FIXME: 'prev' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    const prev = compacted[compacted.length - 1];

    if (prev && predicate(prev, current)) {
      compacted[compacted.length - 1] = compactor(current, prev);
    } else {
      compacted.push(current);
    }
  }

  return compacted;
}

/**
 * This is not a true stack implementation, but I found it helpful to model the
 * history operations as stack changes.  The order that the history items are
 * applied is important, so this helps ensure that
 */
const Stack = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'arr' implicitly has an 'any' type.
  of(arr) {
    return arr;
  },

  /**
   * Push an entry onto the stack
   */
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stack' implicitly has an 'any' type.
  push(stack, entry) {
    return [entry, ...stack];
  },

  /*
   * Returns the first entry in the stack
   */
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stack' implicitly has an 'any' type.
  first(stack) {
    return stack[0];
  },

  /**
   * Returns a new stack with everything but the first item
   */
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stack' implicitly has an 'any' type.
  rest(stack) {
    return stack.slice(1);
  },

  /**
   * Returns a new stack which excludes the last `amt` entries
   */
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'stack' implicitly has an 'any' type.
  skipLast(stack, amt) {
    return amt === 0 ? stack : stack.slice(0, -Math.max(0, amt));
  },
};
