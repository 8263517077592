/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { withStyle, combineRules } from "hw/ui/style";
import { CaretDownIcon } from "hw/ui/icons";
import Theme from "hw/ui/theme";
import sharedStyle, { getPadding } from "./style";
import type { TriggerProps } from "../dropdown/types";

type Props = TriggerProps & {
  /**
   * The placeholder to display when nothing is selected.
   */
  placeholder: string;
  renderSelected: (selectedItem: any | null | undefined) => React.ReactNode;
};

type FelaProps = {
  styles: {
    button: string;
    root: string;
    content: string;
    text: string;
    icon: string;
  };
  theme: typeof Theme;
};

type InternalProps = FelaProps & Props;

function ButtonTrigger(props: InternalProps) {
  const {
    disabled,
    placeholder,
    selectedItem,
    itemToString = (_item?: any) => "",
    styles,
    triggerPassthroughProps,
    renderSelected = (selectedItem) =>
      selectedItem ? itemToString(selectedItem) : placeholder,
    id,
  } = props;
  const { getButtonProps } = triggerPassthroughProps;

  /**
   * @one-off Button The button style here should be one of our standard UI button
   variations.  We're inlining it here but eventually we should delegate
   to that component
   * @provisional
   */
  return (
    <button
      disabled={disabled}
      className={styles.button}
      {...getButtonProps()}
      data-test-ref="ui-select-button-trigger"
      data-testid="ui-select-button-trigger"
      data-track-id={props["data-track-id"]}
      {...getTestAttributes(props["data-guide-id"])}
      id={id}
    >
      <span>{renderSelected(selectedItem)}</span>
      <i className={styles.icon}>
        <CaretDownIcon />
      </i>
    </button>
  );
}

const ButtonTriggerStyle = {
  icon: sharedStyle.icon,
  button: combineRules(
    // @ts-expect-error refactor
    sharedStyle.root,
    sharedStyle.content,
    sharedStyle.text,
    // @ts-expect-error refactor
    ({ theme, active, compacted, disabled, invalid }) => ({
      // TODO: This is implicitly linked to the transition on the
      // hover card.  We might want a more explicit way to tie these two
      // transition properties together
      transitionProperty: "border-color",
      transitionDuration: "200ms",
      backgroundColor: getBackgroundColor({
        // @ts-expect-error migration
        theme,
        disabled,
        compacted,
      }),
      paddingBottom: getPadding("Y", theme, compacted),
      borderColor: getBorderColor({
        // @ts-expect-error migration
        theme,
        disabled,
        active,
        invalid,
      }),
      borderRadius: theme.corner.sm,
      borderWidth: "1px",
      ":focus": {
        boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
        borderColor: theme.color.blue500,
        outline: "none",
      },
      textOverflow: "ellipsis",
      overflow: "hidden",
      "> span": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        overflowWrap: compacted && "break-word",
      },
    })
  ),
};

function getBackgroundColor(props: Props) {
  // @ts-expect-error migration
  const { theme, disabled, compacted } = props;
  if (disabled) return theme.color.buttonBgDisabled;
  return compacted ? theme.color.white : theme.color.white;
}

function getBorderColor(props: Props) {
  // @ts-expect-error migration
  const { theme, disabled, invalid } = props;
  if (disabled) return theme.color.gray90002;
  if (invalid) return theme.color.red500;
  return theme.color.gray500;
}

// @ts-expect-error refactor
export default withStyle(ButtonTriggerStyle)(ButtonTrigger);
