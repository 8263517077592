/**
 * This is a singleton module for listening to `window.resize` events in a
 * performant way.  I basically stole it from MDN and changed it around a little.
 *
 * @example
 * import * as resizeListener from 'hw/common/utils/dom/resizeListener';
 *
 * class MyComponent extends React.Component {
 *
 *      componentDidMount() {
 *          resizeListener.add(this.myListener)
 *      }
 *
 *      componentWillUnmount() {
 *          resizeListener.remove(this.myListener)
 *      }
 * }
 */
type Callback = () => void;
let callbacks: Array<Callback> = [];
let running = false;

function removeCallback(cb: Callback) {
  callbacks = callbacks.filter((callback) => callback !== cb);
}

// adds callback to loop
function addCallback(callback: Callback) {
  if (callback) {
    callbacks.push(callback);
  }
}

// run the actual callbacks
function runCallbacks() {
  callbacks.forEach(function runCallback(callback) {
    callback();
  });

  running = false;
}

// fired on resize event
function resize() {
  if (!running) {
    running = true;

    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(runCallbacks);
    } else {
      setTimeout(runCallbacks, 66);
    }
  }
}

// public method to add additional callback
export function add(callback: Callback) {
  if (!callbacks.length) {
    window.addEventListener("resize", resize);
  }
  addCallback(callback);
}
export function remove(cb: Callback) {
  removeCallback(cb);
}
