import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Input } from "hw/ui/input";
import Button from "hw/ui/button";
import { VisuallyHidden } from "hw/ui/text";
import { createComponent } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  /**
   * The value that appears inside the input text field which will be copied to the clipboard
   */
  value: string;

  /**
   * Text or element that appears inside the copy button
   */
  buttonText: React.ReactNode;

  onCopy?: () => void;

  dataTrackId?: string;
};

const Styled = getStyled();

// The amount of time before resetting back to the original copy after clicking
const FEEDBACK_DELAY = 800;

/**
 * A component for copying a single line text
 */
export default class CopyBar extends React.Component<Props> {
  static defaultProps = {
    buttonText: "Copy",
  };

  state = {
    copying: false,
  };

  ref = React.createRef();

  onFocus = () => this.selectInput();

  selectInput() {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    this.ref.current.select();
  }

  selectAndCopy = () => {
    this.selectInput();
    document.execCommand("copy");

    if (!this.state.copying) {
      if (this.props.onCopy) {
        this.props.onCopy();
      }

      this.setState({ copying: true }, () => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'CopyBar... Remove this comment to see the full error message
        this.timeout = setTimeout(
          () => this.setState({ copying: false }),
          FEEDBACK_DELAY
        );
      });
    }
  };

  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'CopyBar... Remove this comment to see the full error message
    if (this.timeout) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'CopyBar... Remove this comment to see the full error message
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { copying } = this.state;
    const { value, buttonText, dataTrackId } = this.props;

    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
      <Styled.CopyBarContainer>
        <VisuallyHidden>
          <label htmlFor="copybar-text">
            <FormattedMessage
              id="copyBar.textLabel"
              defaultMessage="Copy value"
            />
          </label>
        </VisuallyHidden>
        <Input
          readOnly
          id="copybar-text"
          name="copybar-text"
          value={value}
          extend={Styled.ExtendInput}
          onFocus={this.onFocus}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ readOnly: true; id: string; name: string; ... Remove this comment to see the full error message
          ref={this.ref}
        />
        <Button
          onClick={this.selectAndCopy}
          presentation="subtle"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '({ theme }: { theme: any; }) => { borderTopW... Remove this comment to see the full error message
          extend={Styled.ExtendButton}
          data-track-id={dataTrackId}
        >
          {copying ? (
            <FormattedMessage id="copyBar.copied" defaultMessage="Copied!" />
          ) : (
            buttonText
          )}
        </Button>
      </Styled.CopyBarContainer>
    );
  }
}

function getStyled() {
  return {
    CopyBarContainer: createComponent(function CopyBarContainer({ theme }) {
      return {
        alignItems: "center",
        display: "flex",
        width: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.color.gray400,
        borderRadius: theme.corner.sm,
        position: "relative",
      };
    }, "div"),

    ExtendInput() {
      return {
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        paddingTop: theme.space.sm,
        paddingBottom: theme.space.sm,
        borderStyle: "none",
        flex: 1,
      };
    },

    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
    ExtendButton({ theme }) {
      return {
        borderTopWidth: "0px",
        borderLeftWidth: "1px",
        borderLeftStyle: "solid",
        borderLeftColor: theme.color.gray400,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        paddingTop: theme.space.sm,
        paddingBottom: theme.space.sm,
        borderRadius: "0px",
        flexBasis: "100px",
        position: "relative",
        height: "100%",
      };
    },
  };
}
