import { useCallback, useEffect, useRef } from "react";
import { useDebouncedFn } from "hw/common/hooks";

/**
 * Handles 'hover' and 'onHover' events for an element in a debounced way to
 * avoid over-calling the events when they are likely not needed. This is
 * helpful for dragging and resizing where mouse events could be called many
 * times without much practical benefit
 */
const debounceTimeout = process.env.NODE_ENV === "test" ? 0 : 150;

type Props = {
  onHover: () => void;
  onUnhover: () => void;
};

export function useHover(props: Props) {
  const { onHover, onUnhover } = props;
  // Use a ref to keep track of the hover state
  const state = useRef("idle");
  const pointerState = useRef("up");

  const debouncedOnHover = useDebouncedFn(
    useCallback(() => {
      onHover();
      // If we've gotten here, consider the state 'hovered'
      state.current = "hovered";
    }, [onHover]),
    debounceTimeout
  );

  const onMouseEnter = useCallback(() => {
    if (pointerState.current !== "up") return;

    state.current = "pending";
    debouncedOnHover();
  }, [debouncedOnHover]);

  const onMouseLeave = useCallback(() => {
    // If the pointer is down while we're leaving the element, we're likely
    // dragging, so cancel any hover state changes here to avoid conflicting
    // with the drag events
    if (state.current === "pending" || pointerState.current === "down") {
      // If the hover is still pending cancel it without calling the cb
      debouncedOnHover.cancel();
    } else if (state.current === "hovered" && pointerState.current === "up") {
      // If the hover callback has been called, call the onHover callback
      onUnhover();
    }

    // In any case reset the hover state
    state.current = "idle";
  }, [onUnhover, debouncedOnHover]);

  useEffect(() => {
    return () => {
      debouncedOnHover.cancel();
    };
  }, [debouncedOnHover]);

  useEffect(() => {
    function onMouseDown() {
      // When we start the mouse down we're likely starting a drag, so cancel
      // any pending hover callbacks
      debouncedOnHover.cancel();
      pointerState.current = "down";
    }
    function onMouseUp() {
      pointerState.current = "up";
    }

    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [debouncedOnHover]);

  return { onMouseEnter, onMouseLeave };
}
