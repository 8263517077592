import React from "react";

export function createOptions({ options, placeholder, sortFn }) {
  let optionList;

  if (Array.isArray(options)) {
    optionList = createOptionsFromArray(options, sortFn);
  } else {
    optionList = createOptionsFromObject(options);
  }

  if (placeholder)
    optionList.unshift(createOption({ value: placeholder, key: "" }));
  return optionList;
}

function createOptionsFromArray(options = [], sortFn) {
  // Allow an array of strings where the label is the same as the value
  const normalizedOptions = options.map((opt) =>
    typeof opt === "string" ? { label: opt, value: opt } : opt
  );

  return sortFn(normalizedOptions).map(({ label, value }) =>
    createOption({ key: label, value })
  );
}

function createOptionsFromObject(options = {}, _placeholder) {
  const optionList = Object.keys(options)
    .sort()
    .map((key) => {
      return createOption({ key, value: options[key] });
    });

  return optionList;
}

function createOption({ key, value }) {
  return (
    <option value={key} key={key}>
      {value}
    </option>
  );
}

const SORT_ASC = "asc";
const SORT_NONE = "none";

const sortFns = {
  [SORT_ASC]: (arr) =>
    arr.sort(function compare(a, b) {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    }),
  [SORT_NONE]: (arr) => arr,
};

export function sortFn(sort) {
  return sortFns[sort] || sortFns[SORT_ASC];
}
