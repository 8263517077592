import * as React from "react";
import { DangerIcon } from "hw/ui/icons";
import styles from "./step0.module.css";
import SampleCsv from "./sample-csv";
import Upload from "./upload";

type Props = {
  workflowGuid: string;
  workflowName: string;
  participants: Array<unknown>;
  headers: Array<string>;
  onFileChange: (file: File) => void;
  invalidFileType: boolean;
};

export default function Step0(props: Props) {
  const {
    workflowGuid,
    workflowName,
    participants,
    headers,
    onFileChange,
    invalidFileType,
  } = props;

  return (
    <div>
      <div className={styles.row}>
        <span className={styles.numberCol}>1.</span>
        <span>Download the blank CSV template for ‘{workflowName}’</span>
      </div>
      <div className={[styles.subRow, styles.sampleCSV].join(" ")}>
        <span className={styles.numberCol}>&nbsp;</span>
        <SampleCsv
          guid={workflowGuid}
          name={workflowName}
          numberOfRoles={participants.length}
          headers={headers}
        />
      </div>
      <div className={styles.row}>
        <span className={styles.numberCol}>2.</span>
        <span>Enter participant info into the template and upload it here</span>
      </div>
      <Upload onChange={onFileChange} />
      {invalidFileType && (
        <div className={[styles.row, styles.error].join(" ")}>
          <div className={styles.dangerIcon}>
            <DangerIcon />
          </div>
          <span className={styles.errorText}>Invalid file type</span>
        </div>
      )}
    </div>
  );
}
