/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NOTE: These components are deprecated. Please use the `Text` and
 * `Heading` components in the `./text.jsx` module
 */
import { createComponent } from "hw/ui/style";

const Heading1 = createComponent(function HeadingText({ theme }) {
  return {
    fontSize: theme.fontSize.xxl, // 30px
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "2.5rem", // 40px
  };
});

const Heading2 = createComponent(function HeadingText({ theme }: any) {
  return {
    fontSize: theme.fontSize.xl, // 24px
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "2rem", // 32px
  };
});

const Heading3 = createComponent(function HeadingText({ theme }) {
  return {
    fontSize: theme.fontSize.lg, // 20px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1.75rem", // 28px
  };
});

const Heading4 = createComponent(function HeadingText({ theme }) {
  return {
    fontSize: theme.fontSize.md, // 16px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1.5rem", // 24px
  };
});

const Heading5 = createComponent(function HeadingText({ theme }) {
  return {
    fontSize: theme.fontSize.ms, // 14px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1.25rem", // 20px
  };
});

const Heading6 = createComponent(function HeadingText({ theme }) {
  return {
    fontSize: theme.fontSize.sm, // 12px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1rem", // 16px
  };
});

export { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 };
