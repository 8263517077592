import * as React from "react";

export default function ConditionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fill="#959CA4"
        d="M14 7V5a1 1 0 0 1 1.514-.857l5 3a1 1 0 0 1 0 1.714l-5 3A1 1 0 0 1 14 11V9H7a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v3h6zm-8 7v-3a1 1 0 0 1 2 0v3h2a1 1 0 0 1 .857 1.514l-3 5a1 1 0 0 1-1.714 0l-3-5A1 1 0 0 1 4 14h2z"
      />
    </svg>
  );
}
