import * as React from "react";

export default function Sparkle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.61186 1.30313C8.71026 0.894726 9.29119 0.894725 9.3896 1.30313L9.80734 3.03683C10.3089 5.11862 11.882 6.77528 13.9351 7.3839L14.7125 7.61437C15.0943 7.72754 15.0943 8.26821 14.7125 8.38138L13.9346 8.61199C11.8816 9.2206 10.3085 10.8773 9.80689 12.9591L9.3896 14.6909C9.29119 15.0993 8.71026 15.0993 8.61186 14.6909L8.19468 12.9595C7.69307 10.8777 6.11999 9.22105 4.06693 8.61243L3.28751 8.38138C2.90576 8.26821 2.90576 7.72754 3.28751 7.61437L4.06648 7.38345C6.11954 6.77484 7.69262 5.11817 8.19423 3.03638L8.61186 1.30313ZM2.75338 1.22322C2.78546 0.925622 3.21841 0.925622 3.25049 1.22322L3.281 1.50614C3.36228 2.26003 3.86291 2.90312 4.57383 3.16686L4.83949 3.26542C5.05688 3.34607 5.05689 3.65355 4.83949 3.7342L4.57379 3.83278C3.86288 4.09652 3.36225 4.73961 3.28096 5.4935L3.25049 5.77609C3.21841 6.07369 2.78546 6.07369 2.75338 5.77609L2.72296 5.49401C2.64168 4.74012 2.14105 4.09703 1.43013 3.83329L1.16304 3.7342C0.945652 3.65355 0.945652 3.34607 1.16304 3.26542L1.4301 3.16635C2.14101 2.90261 2.64164 2.25952 2.72293 1.50563L2.75338 1.22322Z"
        fill="#9844CD"
      />
    </svg>
  );
}
