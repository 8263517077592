import * as React from "react";
import { DragSource } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import type { DragSourceMonitor } from "react-dnd";
import type { Field as FieldT } from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import { DRAG_TYPE_FIELD } from "../../constants";
import DroppableSection from "./droppable-section";
import Field from "./field";
import type { FieldDragItem } from "../../types";

type Props = {
  field: FieldT;
  order: string;
  roleBadge: string;
  path: Path;
  fields: Array<FieldT>;
  showRoleBadge: boolean;
  prefillMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreate: (...args: Array<any>) => any;
};

/**
 * This component encompasses the draggable functionality for fields.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DraggableField extends React.PureComponent<any> {
  node: HTMLDivElement | null | undefined;

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const {
      isDragging,
      path,
      field,
      moveField,
      isNested,
      nestedAttrs,
      ...rest
    } = this.props;
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; path: any; moveField: a... Remove this comment to see the full error message
      <DroppableSection
        path={path}
        moveField={moveField}
        isNested={isNested}
        nestedAttrs={nestedAttrs}
        ref={(node) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'HTMLDivE... Remove this comment to see the full error message
          this.node = node;
        }}
      >
        <Field
          {...rest}
          path={path}
          isDragging={isDragging}
          field={field}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          moveField={moveField}
        />
      </DroppableSection>
    );
  }
}

/**
 * Handles events related to dragging
 */
export const dragSpec = {
  beginDrag(
    props: Props,
    monitor: DragSourceMonitor,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.Component<any, any> & {
      node?: HTMLDivElement;
    }
  ): FieldDragItem {
    const node = typeof component.node !== "undefined" && component.node;
    const rect =
      node && typeof node.getBoundingClientRect === "function"
        ? node.getBoundingClientRect()
        : {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type '{}'.
    const { width, height } = rect;
    const { roleBadge, showRoleBadge, prefillMessage } = props;
    return {
      path: props.path,
      field: props.field,
      order: props.order,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: Path; field: any; order: string; rol... Remove this comment to see the full error message
      roleBadge,
      showRoleBadge,
      prefillMessage,
      width,
      height,
    };
  },
};

/**
 * This function provides props to the item being dragged
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingType: monitor.getItemType(),
  };
}
export default DragSource(
  DRAG_TYPE_FIELD,
  dragSpec,
  dragCollect
)(DraggableField);
