import * as React from "react";
import { createComponent } from "hw/ui/style";
// TODO: Think about link styles
// This should mainly be taking from the default anchor styles, but we are
// doing some top-level overrides in the portal `root.css` that we are fighting
// against here
const Link = createComponent(
  function Link({ theme }) {
    return {
      color: theme.color.blue600,
      fontSize: theme.fontSize.ms,
      ":hover": {
        color: theme.color.blue600,
        textDecoration: "underline",
      },
    };
  },
  "a",
  ["onClick"]
);
type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...args: Array<any>) => any;
};
export default function JumpToDefLink(props: Props) {
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: s... Remove this comment to see the full error message
    <Link
      {...props}
      onClick={(evt) => {
        evt.preventDefault();
        props.onClick(evt);
      }}
    >
      Go to the code editor
    </Link>
  );
}
