import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { useId } from "hw/ui/utils";
import { invariant } from "hw/common/utils/assert";
import { Text } from "hw/ui/text";
import type { TextProps } from "hw/ui/text";

/**
 * List
 * ----
 */
export type ListProps = {
  /**
   * The element type to use to render the list
   */
  as?: React.ElementType;

  /**
   * Extend the styles on the list if needed
   */
  extend?: Extend;

  /**
   * Set a minimum width for the list if needed
   */
  minWidth?: string;

  /**
   * Set a maximum width for the list if needed
   */
  maxWidth?: string;

  children: React.ReactNode;
};

function List(props: ListProps, ref: React.ForwardedRef<HTMLElement>) {
  const { extend, minWidth, maxWidth, as = "div", ...rest } = props;
  const listCn = useStyle(
    "collection-list",
    {
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      minWidth,
      maxWidth,
      ":focus": {
        boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
        outline: "none",
      },

      // Cheating a bit and using global data attributes to style nested
      // groups. Easiest for now and can revisit if it becomes a problem
      "> [data-hwui-collection-group] + [data-hwui-collection-group]": {
        borderTopColor: theme.color.dividerDim,
        borderTopStyle: "solid",
        borderTopWidth: "1px",

        // Extra padding to be symmetrical with padding on top and bottom of
        // entire popover
        paddingTop: theme.space.xs,
        marginTop: theme.space.xs,
      },
    },
    extend
  );

  const El = as;

  // $FlowFixMe: Can't figure out how to type the 'as' prop
  return <El {...rest} className={listCn} ref={ref} />;
}

const _List = React.forwardRef<HTMLElement, ListProps>(List);
export { _List as List };

/**
 * Item
 */

export type ItemProps = {
  /**
   * An option icon to render with the item
   */
  icon?: React.ReactNode;

  /**
   * The HTML element to render for the item. Default is `button`
   */
  as?: React.ElementType;

  /**
   * Extend the styles of the item if needed
   */
  extend?: Extend;

  /**
   * An explicit state prop for the item. This is generally not needed, but is
   * helpful for storybook
   */
  state?: "idle" | "hovered" | "focused" | "pressed" | "disabled";

  /**
   * Content of the Item
   */
  children: React.ReactNode;

  disabled?: boolean;

  overflow?: TextProps["overflow"];

  iconHasIntrinsicPadding?: boolean;
};

function Item(props: ItemProps, ref: React.ForwardedRef<HTMLElement>) {
  const {
    extend,
    as = "button",
    state: _state,
    icon,
    children,
    overflow = "truncate",
    iconHasIntrinsicPadding,
    ...rest
  } = props;
  const state = rest.disabled ? "disabled" : _state;

  const cn = useStyle(
    "collection-item",
    {
      display: "grid",

      // NOTE: The `minmax(0, 1fr)` is to allow the `overflow-wrap` to work in
      // a grid context
      gridTemplateColumns: icon ? `auto minmax(0, 1fr)` : "minmax(0, 1fr)",
      gridGap: iconHasIntrinsicPadding ? theme.space.xs : theme.space.sm,
      gridTemplateRows: `minmax(${theme.space.lg}, 1fr)`,
      minWidth: "100%",
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      paddingLeft: iconHasIntrinsicPadding ? theme.space.sm : theme.space.ms,
      paddingRight: iconHasIntrinsicPadding ? theme.space.sm : theme.space.ms,
      backgroundColor: getBackgroundColor(state),
      cursor: getCursor(state),
      color: getTextColor(state),
      textAlign: "left",
      fontSize: theme.fontSize.ms,
      border: "none",
      marginLeft: "0px",
      marginRight: "0px",
      marginTop: "0px",
      marginBottom: "0px",
      alignItems: "center",
      ":hover": {
        backgroundColor: state !== "disabled" && getBackgroundColor("hovered"),
      },
      ":focus": {
        outline: "none",
        backgroundColor: state !== "disabled" && getBackgroundColor("focused"),
      },
      ":active": {
        backgroundColor: state !== "disabled" && getBackgroundColor("pressed"),
        color: state !== "disabled" && getTextColor("pressed"),
      },
    },
    extend
  );

  const iconCn = useStyle("menu-item-icon", {
    display: "inline-flex",
    color: getIconColor(state),
  });

  const El = as;

  return (
    <El {...rest} className={cn} ref={ref}>
      {icon && <span className={iconCn}>{icon}</span>}
      <Text
        as="span"
        extend={{ alignSelf: "center", lineHeight: "143%" }}
        overflow={overflow}
      >
        {children}
      </Text>
    </El>
  );
}

const _Item = React.forwardRef<HTMLElement, ItemProps>(Item);
export { _Item as Item };

function getBackgroundColor(state: ItemProps["state"]) {
  switch (state) {
    case "hovered":
    case "focused":
      return theme.color.gray050;
    case "pressed":
      return theme.color.uiActionPressed;
    default:
      return theme.color.white;
  }
}

function getTextColor(state: ItemProps["state"]) {
  switch (state) {
    case "pressed":
      return theme.color.uiActionBase;
    case "disabled":
      return theme.color.textLighter;
    default:
      return theme.color.textStandard;
  }
}

function getIconColor(state: ItemProps["state"]) {
  switch (state) {
    case "disabled":
      return theme.color.iconDisabled;
    default:
      return theme.color.iconDefault;
  }
}

function getCursor(state: ItemProps["state"]) {
  switch (state) {
    case "disabled":
      return "not-allowed";
    default:
      return "pointer";
  }
}

/**
 * Group
 * -----
 */
export type GroupProps = {
  /**
   * The label for this group
   */
  label?: string;

  /**
   * If the label for this group is not rendered visually, this value describes
   * the group for assistive devices
   */
  "aria-label"?: string;

  /**
   * Content of the group
   */
  children: React.ReactNode;

  /**
   * This is a hidden prop to allow for an "action" button to be displayed
   * within the group. It's marked as "unsafe" because it's not completely
   * accessible. It's here to provide backwards compatibility with existing
   * interfaces, but alternative patterns should be explored going forward.
   */
  unsafe_Action?: React.ReactNode;
};

function Group(props: GroupProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    label,
    "aria-label": ariaLabel,
    children,
    unsafe_Action: action,
    ...rest
  } = props;
  const labelId = useId();

  const labelContainerCn = useStyle("collection-group-label-wrap", {
    display: "flex",
    alignItems: "center",
    gap: theme.space.xs,
    justifyContent: "space-between",
  });

  const labelCn = useStyle("collection-group-label", {
    fontWeight: theme.fontWeight.semibold,
    fontSize: theme.fontSize.sm,
    lineHeight: "133%",
    color: theme.color.gray700,
    letterSpacing: "1px",
    textTransform: "uppercase",
    paddingLeft: theme.space.ms,
    paddingRight: theme.space.ms,
    paddingTop: theme.space.sm,
    paddingBottom: theme.space.sm,
    minHeight: theme.space.xl,
    display: "flex",
    alignItems: "center",
    "& + *": {
      marginRight: theme.space.xs,
    },
  });

  invariant(
    label || ariaLabel,
    `You must provide either a label for this group or an 'aria-label' if the label is not meant to be visible`
  );

  return (
    <div {...rest} ref={ref} data-hwui-collection-group>
      {label && (
        <div className={labelContainerCn}>
          <span id={labelId} aria-hidden="true" className={labelCn}>
            {label}
          </span>
          {action}
        </div>
      )}
      {ariaLabel && (
        <span aria-hidden="true" hidden>
          {ariaLabel}
        </span>
      )}
      <div role="group" aria-labelledby={labelId}>
        {children}
      </div>
    </div>
  );
}

const _Group = React.forwardRef<HTMLDivElement, GroupProps>(Group);
export { _Group as Group };

export function Hint(props: { text: React.ReactNode }) {
  const { text } = props;
  const cn = useStyle("collection-hint", {
    color: theme.color.textDim,
    fontSize: theme.fontSize.ms,
    paddingLeft: theme.space.ms,
    paddingTop: theme.space.xs,
    paddingBottom: theme.space.xs,
    paddingRight: theme.space.ms,
    fontStyle: "italic",
    minHeight: theme.space.xl,
    display: "flex",
    alignItems: "center",
    borderColor: theme.color.dividerDim,
    borderWidth: "1px",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
  });

  return <div className={cn}>{text}</div>;
}
