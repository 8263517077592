import React from "react";
import theme from "hw/ui/theme";
import Button from "hw/ui/button";

type Props = {
  onClick: () => void;
};
export default function AddButton(props: Props) {
  const { onClick } = props;
  return (
    <Button
      presentation="link"
      size="small"
      onClick={onClick}
      extend={{
        fontSize: theme.fontSize.sm,
        justifyContent: "flex-end",
      }}
      data-automation-id="addButton"
    >
      Add
    </Button>
  );
}
