import * as React from "react";
import * as Styled from "./styled";

type Props = {
  /**
   * The direction of the divider
   */
  direction?: "vertical" | "horizontal";

  /**
   * The amount to offset the "OR" text from the start of the divider. The start
   * is defined based on direction.  If `vertical`, the offset will be relative
   * to the top of the divider.  If `horiztonal`, the offset will be relative
   * to the left of the divider.  The `offset` value can be a string or a number
   * and is computed based on the following rules:
   *
   * - If number
   *   - If between 0 and 1, assume a percentage and place the offset relative to
   *     the center of the circle.  A value of `0.50` (the default) places the
   *     circle directly in the center of the divider
   *   - If greater than 1, assume pixels (e.g. `200` -> `200px`)
   * - If a string, assume a literal value (e.g. `100px` -> `100px`)
   */
  offset?: number | string;

  /**
   * If `none`, the divider will not take up any horizontal or vertical space.
   * This can be used if you need to separate two pieces of content but need
   * their to be no space between them.
   * If `default`, the divider will similar to a `block` or `inline-block`
   * element and take up as much space as needed based on the circle dimensions.
   */
  spacing?: "default" | "none";

  /**
   * The text to use in the separator.
   *
   * NOTE: This is not meant to be any text.  It's specifically meant to be the
   * text "OR" and is only provided as prop so a translation can be passed in.
   * The style dimensions depend on the text being only two characters currently.
   */
  text?: React.ReactNode;

  /**
   * Optional prop that specifies the circle diameter in pixels
   */
  circleDiameter?: number;
};

/**
 * The `Or` component is used to visually separate two pieces of content.
 *
 * ## Usage
 *
 * This component does its best to accomodate most typical scenarios, but it does have some relationship with the surrounding content.
 * For the most part, the component assumes that it will be within a flex box container and derives its internal style based on that assumption.
 * If you're using the `vertical` direction, ensure you are orienting the surrounding content with `flex-direction`.
 *
 * ```jsx
 * <div style={{ display: 'flex', flexDirection: 'row' }}>
 *   <div>some content</div>
 *   <Or direction='vertical' />
 *   <div>some other content</div>
 * </div>
 * ```
 *
 * When it comes to a vertically oriented divider the component is suceptible to an incorrect height value depending on the content, so you may need to `extend` this component in certain cases depending on the use case.
 *
 */
export default function Or(props: Props) {
  const { direction, offset, spacing, text, circleDiameter } = props;

  return (
    <Styled.Wrapper
      direction={direction}
      spacing={spacing}
      circleDiameter={circleDiameter}
    >
      <Styled.Line direction={direction} spacing={spacing} />
      <Styled.Circle
        offset={offset}
        direction={direction}
        circleDiameter={circleDiameter}
      >
        {text}
      </Styled.Circle>
    </Styled.Wrapper>
  );
}

Or.defaultProps = {
  direction: "horizontal",
  offset: 1 / 2,
  spacing: "default",
  text: "OR",
  circleDiameter: 40,
};
