/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import reduce from "lodash/reduce";
import { Flex } from "hw/ui/blocks";
import FlexOverflowParent from "hw/portal/modules/common/components/flex-overflow-parent";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import type { ParsedSchema } from "hw/portal/modules/common/draft";
import FormSidebar from "./form-sidebar";
import parsedSchemaEditor from "./parsed-schema-editor";
import type { RouteState } from "../types";
import { changeTypes, Actions } from "../state";
import { defaultWorkflowName, filterNonUTF83BCharacters } from "../utils";

type Props = {
  parsedSchema: ParsedSchema;
  routeState: RouteState;
  errors: Array<DraftFeedback>;
};
// eslint-disable-next-line @typescript-eslint/ban-types
type State = {};

/**
 * A higher-order component for editng the parsed state of an individual form
 * in the draft schema.
 *
 * @example
 * function WorkflowTabFormEditor(props) {
 *   const { form, path } = props;
 *
 *  //...
 * }
 *
 * export default formEditor(WorkflowTabFormEditor)
 */
function formEditor<P extends Props>(
  Component: React.ComponentType<P>
): React.ComponentType<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return class FormEditor extends React.Component<any, State> {
    paths = {};

    getActiveFormKey = () => {
      const { routeState, parsedSchema } = this.props;
      const idx = parsedSchema.forms.findIndex(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'form' implicitly has an 'any' type.
        (form) => form.id === routeState.form
      );
      return idx === -1 ? 0 : idx;
    };

    getActiveForm = () =>
      this.props.parsedSchema.forms[this.getActiveFormKey()];

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
    getCachedPath = (key) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!this.paths[key]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        this.paths[key] = ["forms", key];
      }

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return this.paths[key];
    };

    createFormFromTemplate = () => {
      const { routing, history, location } = this.props;
      routing.dispatch(routing.openTemplateLibrary(), history, location);
    };

    /**
     * New form creation happens in the template library, so we just need to
     * push the correct route.
     */
    createForm = () => {
      return this.createFormFromTemplate();
    };

    deleteForm = () => {
      const { parsedSchema, editorDispatch } = this.props;
      const activeForm = this.getActiveForm();
      const updatedForms = parsedSchema.forms.filter(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'form' implicitly has an 'any' type.
        (form) => form.id !== activeForm.id
      );
      const updatedMappings = reduce(
        parsedSchema.mapping,
        (acc, value, key) => {
          if (key.split(".")[0] !== activeForm.id) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            acc[key] = value;
          }

          return acc;
        },
        {}
      );
      const nextSelectedForm = updatedForms[updatedForms.length - 1];

      if (nextSelectedForm) {
        this.selectForm(nextSelectedForm);
      }

      if (this.shouldChangeWorkflowName(parsedSchema.forms)) {
        editorDispatch(
          Actions.SetDefaultWfName({
            changeType: changeTypes.DELETE_FORM,
            path: [],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            updater: (schema: any) => ({
              ...schema,
              forms: updatedForms,
              mapping: updatedMappings,
            }),
          })
        );
      } else {
        editorDispatch(
          Actions.UpdateParsedSchema({
            changeType: changeTypes.DELETE_FORM,
            path: [],
            updater: (schema) => ({
              ...schema,
              forms: updatedForms,
              mapping: updatedMappings,
            }),
          })
        );
      }
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'form' implicitly has an 'any' type.
    selectForm = (form) => {
      const { routing, history, location } = this.props;
      const { id } = form;
      routing.dispatch(routing.selectForm(id), history, location);
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'form' implicitly has an 'any' type.
    selectNewForm = (form) => {
      const { routing, history, location } = this.props;
      const { id } = form;
      routing.dispatch(routing.selectNewForm(id), history, location);
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    handleFormNameChange = (evt) => {
      const { editorDispatch, location, routing, parsedSchema } = this.props;
      const path = this.getCurrentPath();
      const { value } = evt.target;
      const filteredValue = filterNonUTF83BCharacters(value);
      const isNewForm = routing.isNewForm(location);
      if (isNewForm) this.removeNewFormParam();

      if (this.shouldChangeWorkflowName(parsedSchema.forms)) {
        editorDispatch(
          Actions.SetDefaultWfName({
            changeType: changeTypes.SET_FORM_NAME,
            path: [...path, "name"],
            updater: () => filteredValue,
          })
        );
      } else {
        editorDispatch(
          Actions.UpdateParsedSchema({
            changeType: changeTypes.SET_FORM_NAME,
            path: [...path, "name"],
            updater: () => filteredValue,
          })
        );
      }
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'previousForms' implicitly has an 'any' ... Remove this comment to see the full error message
    shouldChangeWorkflowName = (previousForms) => {
      return defaultWorkflowName(previousForms) === this.props.workflowName;
    };

    removeNewFormParam = () => {
      const form = this.getActiveForm();
      this.selectForm(form);
    };

    getCurrentPath = () => {
      const activeFormKey = this.getActiveFormKey();
      return this.getCachedPath(activeFormKey);
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'shouldOpen' implicitly has an 'any' typ... Remove this comment to see the full error message
    toggleSidebar = (shouldOpen) => {
      const { routing, history, location } = this.props;
      routing.dispatch(routing.toggleSidebar(shouldOpen), history, location);
    };

    render() {
      const { parsedSchema, routing, location, errors } = this.props;
      const activeForm = this.getActiveForm();
      const routeState = routing.getState(location);
      const isNewForm = routing.isNewForm(location);
      const path = this.getCurrentPath();
      const sidebarOpen =
        typeof routeState.sidebar !== "undefined" ? routeState.sidebar : true;
      return (
        <FlexOverflowParent extend={FormEditorTabWrapper}>
          <FormSidebar
            forms={parsedSchema.forms}
            onCreate={this.createForm}
            onSelect={this.selectForm}
            onToggle={this.toggleSidebar}
            open={sidebarOpen}
            activeId={activeForm && activeForm.id}
            title="Forms"
            errors={errors}
          />
          <Flex flexDirection="column" flex={1}>
            {activeForm && (
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ form: any; onSidebarToggle: (shouldOpen: a... Remove this comment to see the full error message
              <Component
                key={activeForm.id}
                {...this.props}
                form={activeForm}
                onSidebarToggle={this.toggleSidebar}
                sidebarOpen={sidebarOpen}
                path={path}
                onDelete={this.deleteForm}
                isNewForm={isNewForm}
                handleFormNameChange={this.handleFormNameChange}
                removeNewFormParam={this.removeNewFormParam}
              />
            )}
          </Flex>
        </FlexOverflowParent>
      );
    }
  };
}

function FormEditorTabWrapper() {
  return {
    display: "flex",
    flexDirection: "row",
    flex: "1 0 100%",
  };
}
/**
 * This component requires a parsed schema to function
 */

export default function parsedSchemaFormEditor(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any>
) {
  return parsedSchemaEditor(formEditor(Component));
}
