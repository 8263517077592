import * as React from "react";
import { Flex } from "hw/ui/blocks";
import type { SignatureField } from "hw/portal/modules/common/draft";
import { Sparkle } from "hw/ui/icons/svg-icons";
import Tooltip from "hw/ui/tooltip";
import { PremiumWrapper as Premium } from "hw/portal/modules/common/components/premium";
import { Button } from "./common/basics";
import { Required, ButtonGroup } from "./common";
import type { SettingsProps } from "../../types";
import { Actions } from "../../state";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: SignatureField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
};
export default function SignatureFieldSettings(props: Props) {
  const { field, path, editorDispatch, defaultSettings, referenceSetting } =
    props;
  const { required, noTap, noDraw, noType } = field;
  const dispatchEdit = React.useCallback(
    (fieldName, value) => {
      editorDispatch(
        Actions.ChangeFieldSetting({
          path,
          updater: (field) => ({
            ...field,

            /* $FlowFixMe[invalid-computed-prop] $FlowFixMe This comment
             * suppresses an error found when upgrading Flow to v0.132.0. To view
             * the error, delete this comment and run Flow. */
            [fieldName]: value,
          }),
        })
      );
    },
    [editorDispatch, path]
  );

  /**
   * Tap, draw, and type control the methods available in the signature component. Since you must always have one method
   * available we do not allow unchecking an option if it is the last option.
   */
  const onTapClick = React.useCallback(() => {
    const newValue = noDraw && noType ? false : !noTap;

    if (newValue !== noTap) {
      dispatchEdit("noTap", newValue);
    }
  }, [noTap, noDraw, noType, dispatchEdit]);
  const onDrawClick = React.useCallback(() => {
    const newValue = noTap && noType ? false : !noDraw;

    if (newValue !== noDraw) {
      dispatchEdit("noDraw", newValue);
    }
  }, [noTap, noDraw, noType, dispatchEdit]);
  const onTypeClick = React.useCallback(() => {
    const newValue = noDraw && noTap ? false : !noType;

    if (newValue !== noType) {
      dispatchEdit("noType", newValue);
    }
  }, [noTap, noDraw, noType, dispatchEdit]);
  const renderButtonGroup = React.useCallback(
    (hasAccess) => {
      return (
        <ButtonGroup
          label={
            <span>
              <span>Signing Options</span>
              {!hasAccess && (
                <span>
                  <Tooltip
                    tip={"This setting is a paid feature"}
                    position="top"
                  >
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
                    <div tabIndex="0">
                      <Sparkle />
                    </div>
                  </Tooltip>
                </span>
              )}
            </span>
          }
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; active: boolean; onClick... Remove this comment to see the full error message */}
          <Button active={!noTap} onClick={onTapClick} disabled={!hasAccess}>
            Single Tap
          </Button>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; active: boolean; onClick... Remove this comment to see the full error message */}
          <Button active={!noDraw} onClick={onDrawClick} disabled={!hasAccess}>
            Draw
          </Button>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; active: boolean; onClick... Remove this comment to see the full error message */}
          <Button active={!noType} onClick={onTypeClick} disabled={!hasAccess}>
            Type
          </Button>
        </ButtonGroup>
      );
    },
    [noTap, noDraw, noType, onTapClick, onDrawClick, onTypeClick]
  );
  return (
    <Flex flexDirection="column">
      {defaultSettings}
      <Required
        path={path}
        editorDispatch={editorDispatch}
        required={required}
        fieldType={field.type}
      />
      <div>
        <Premium
          feature="SignatureOptions"
          access={renderButtonGroup(true)}
          noAccess={renderButtonGroup(false)}
        />
      </div>
      {referenceSetting}
    </Flex>
  );
}
