import { Tabs as v1Tabs } from "hw/portal/modules/draft-editor/constants";
import { Tabs as v2Tabs } from "hw/portal/modules/draft-editor-v2/constants";
import createDebug from "debug";

const debug = createDebug("heap");

// A string unknown of all of the values below
//   i.e. "Editor - Editor Loaded" | "Editor - Build tab viewed" | ...
export type HeapEvent = FlattenValues<typeof EVENTS>;

export const EVENTS = {
  upgradePlanClicked: "Plan page - Upgrade clicked",
  upgradeLinkClicked: "Upgrade link clicked",
  enableShareableLinkClicked: "launch-shareable-link-enable_button",
  disableShareableLinkClicked: "launch-shareable-link-disable_button",
  editor: {
    EDIT_CODE: "Workflow Editor - Edit Code",
    VALIDATION_ERROR: "Workflow Editor - Validation Error",
    loaded: "Editor - Editor Loaded",
    componentMenuItemClicked: "Editor - Add Component Menu Item Clicked",
    requiredToggleClicked: "Editor - Component Required Toggle Clicked",
    deleteComponentBtnClicked: "Editor - Delete Component Button Clicked",
    duplicateComponentBtnClicked: "Editor - Duplicate Component Button Clicked",
    componentLabelFieldClicked: "Editor - Component Label Field Clicked",
    componentLabelFieldModified: "Editor - Component Label Field Modified",
    formNameModified: "Editor - Form Name Modified",
    formNameClicked: "Editor - Form Name Clicked",
    undoKeyCmd: "Editor - Undo Keyboard Shortcut Pressed",
    redoKeyCmd: "Editor - Redo Keyboard Shortcut Pressed",
    reviewAndPublish: {
      formNameClicked: "Editor - Form navigated",
    },
    addNewFormClicked: "Editor - Add New Form Clicked",
    reorderFormsClicked: "Editor - Reorder forms clicked",
    deleteComponentClicked: "Editor - Delete component button Clicked",
    duplicateComponentClicked: "Editor - Duplicate component button clicked",
    reorderFormsModalSave: "Reorder forms modal - save button clicked",
    navigateToTab: {
      [v2Tabs.Build.name]: "Editor - Build tab viewed", // same for both v1 and v2
      [v1Tabs.Code.name]: "Editor - Code tab viewed",
      [v1Tabs.Map.name]: "Editor - PDF Map tab viewed",
      [v2Tabs.LivePreview.name]: "Editor - Live Preview tab viewed", // same for both v1 and v2
      [v2Tabs.ReviewPublish.name]: "Editor - Review and Publish tab viewed",
      v3FormBuilder: "Editor - Form builder tab viewed",
      v3Workflow: "Editor - Workflow tab viewed",
      v3ReviewAndPublish: "Editor - Review and Publish tab viewed",
    },
    workflow: {
      insertMenuOpened: "Workflow - insert condition or form clicked",
      newConditionClicked: "Workflow - New condition clicked",
      conditionNameModified: "Workflow - Condition name modified",
      refConditionMenuOpened: "Workflow - if dropdown menu clicked",
      refConditionMenuItemClicked: "Workflow - if dropdown menu item clicked",
      formOrConditionMoved: "Workflow - Form or condition moved",
    },
  },
  autoBuild: {
    feedbackSubmitted: "Auto-build - Feedback Submitted",
    completedScanViewed: "Auto-build - Completed Scan Viewed",
  },
  launchPage: {
    launchBtnClicked: "Launch Page - Launch Workflow Button Clicked",
    bulkSendClicked: "Bulk send - modal launch button clicked",
    bulkSendDownloadClicked: "Bulk send - template downloaded",
    bulkSendUpload: "Bulk send - participant list uploaded",
    bulkSendSendClicked: "Bulk send modal - send button clicked",
    bulkSendCloseClicked: "Bulk send modal - cancel button clicked",
    bulkSendCellFocused: "Bulk send table field clicked",
  },
  wfList: {
    wfNameClicked: "Workflow List - Workflow Name Clicked",
    wfLaunchClicked: "Workflow List - Workflow Launch Clicked",
    deleteWfBtnClicked: "Workflow List - Delete Workflow Button Clicked",
    formSearchEntered: "Workflow List - Form Search Entered",
  },
  transactionPage: {
    downloadBtnClicked: "Sent page - Transaction Download Button Clicked",
    remindBtnClicked: "Sent page - Remind Participant Button Clicked",
    deleteTransactionBtnClicked:
      "Sent page - Delete Transaction Button Clicked",
    cancelTransactionBtnClicked:
      "Sent page - Cancel Transaction Button Clicked",
    csvDownloadBtnClicked: "Sent page - CSV Download Button Clicked",
    csvDownloading: "Sent page - CSV Downloading",
    omniSearchEntered: "Sent page - omni search entered",
  },
  templateLibrary: {
    /**
     * @deprecated Use the `newFormCreatorOpened` instead
     */
    templateLibraryOpened: "Template Library - Viewed",

    /**
     * @deprecated Use the `newFormCreatorCloseClicked` instead
     */
    templateLibraryCloseClicked: "Template Library - Close clicked",

    /**
     * @deprecated Unused
     */
    createBlankForm: "Template Library - Create blank form clicked",

    /**
     * @deprecated Unused
     */
    pdfUploaded: "Template Library - Upload a PDF clicked",
    templatePreSelected: "Template Library - Template Selected",

    // NOTE: This one seems to be duplicated with the `newFormTemplateUsed`
    // below. Keeping it in place for now.
    templateUsed: "Template Library - Template Used",
    pdfPreviewed: "Template Library - PDF Previewed",
    teamTemplatePreSelected: "Template Library - Team Template Selected",
    teamTemplateUsed: "Template Library - Team Template Used",
    roleAssigned: "Template Library - Role Assigned",

    // These are only applicable to the V2 template library
    workflowPreviewed: "Template Library - Workflow Previewed",
    formPreviewed: "Template Library - Form Previewed",
  },
  // NOTE: In some cases this "newFormCreator" label is synonymous with
  // "template library" and just has an unfortunate name.
  newFormCreator: {
    newFormCreatorOpened: "New form - Viewed",
    newFormCreatorCloseClicked: "New form - Close clicked",
    newFormCreated: "New form - Created",
    newFormTemplateUsed: "New form - Template used",
    newFormTeamTemplateUsed: "New form - Team Template used",
  },
  livePreview: {
    fieldVisited: "Live Preview - Field Visited",
  },
} as const;

export const IDS = {
  editor: {
    toggleVersion: "editor-toggle_version",
    addComponentTrigger: "editor-add_component_menu_trigger",
    sendTestEmail: "send-test-email",
    addParticipant: "editor-add_role",
    publishAndSend: "editor-workflow_publish_send",
  },
  autoBuild: {
    featureUnlock: "auto-build_feature-unlock-btn",
    beginScan: "auto-build_begin-scan-btn",
  },
};

export const Properties = {
  teamGuid: "Team Guid",
  internal: "Internal",
  jobId: "Job ID",
  formId: "Form ID",
  templateLibraryVersion: "Template Library Version",
  hsHwCrossPromo: "Cross Promo",
} as const;

export const trackEvent = {
  autoBuild: {
    completedScanViewed: ({
      jobId,
      formId,
    }: {
      jobId: string;
      formId: string;
    }) => {
      track(EVENTS.autoBuild.completedScanViewed, {
        [Properties.jobId]: jobId,
        [Properties.formId]: formId,
      });
    },
    feedbackSubmitted: ({
      jobId,
      formId,
      rating,
    }: {
      jobId: string;
      formId: string;
      rating: string;
    }) => {
      track(EVENTS.autoBuild.feedbackSubmitted, {
        [Properties.jobId]: jobId,
        [Properties.formId]: formId,
        Rating: rating,
      });
    },
  },
};

type EvtProps = Record<string, unknown>;

interface Heap {
  addEventProperties(evtProps: EvtProps): void;
  addUserProperties(evtProps: EvtProps): void;
  identify(id: string): void;
  clearEventProperties(): void;
  removeEventProperty(prop: string): void;
  track(evtName: string, evtProps?: EvtProps): void;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

export function track(evtName: HeapEvent, evtProps?: EvtProps) {
  debug(`event: '%s'${evtProps ? ", props: %o" : ""}`, evtName, evtProps ?? "");
  if (window.heap) {
    window.heap.track(evtName, evtProps);
  }
}

export function addEventProperties(evtProps: EvtProps) {
  debug("adding event props: %o", evtProps);
  if (window.heap) {
    window.heap.addEventProperties(evtProps);
  }
}

export function clearEventProperties() {
  if (window.heap) {
    window.heap.clearEventProperties();
  }
}

export function removeEventProperty(prop: string) {
  debug("removing event prop: '%s'", prop);
  if (window.heap) {
    window.heap.removeEventProperty(prop);
  }
}

export function identifyUser(
  id: string,
  teamGuid: string,
  isInternal: boolean,
  isCrossPromo?: boolean
) {
  if (!window.heap) {
    debug("Heap not present");
  }

  if (typeof id === "string") {
    debug("user id '%s'", id);
    if (window.heap) {
      window.heap.identify(id);
    }
  }

  const userProperties = {};

  if (typeof teamGuid === "string") {
    // @ts-expect-error refactor
    userProperties[Properties.teamGuid] = teamGuid;
  }

  if (typeof isInternal === "boolean") {
    // @ts-expect-error refactor
    userProperties[Properties.internal] = isInternal;
  }

  if (typeof isCrossPromo === "boolean") {
    // @ts-expect-error refactor
    userProperties[Properties.hsHwCrossPromo] = isCrossPromo;
  }

  debug("user properties: %o", userProperties);

  if (window.heap) {
    window.heap.addUserProperties(userProperties);
  }
}

/**
 * Recursively flattens a nested object where values ultimately end as
 * strings
 */
type FlattenValues<
  T extends Record<string, string | unknown>,
  Key = keyof T
> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? FlattenValues<T[Key]>
    : T[Key]
  : never;
