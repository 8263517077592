import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { Flex } from "hw/ui/blocks";
import { createComponent, useStyle } from "hw/ui/style";
import IconWrapper, { CloseIcon } from "hw/ui/icons";
import { Tooltip } from "hw/ui/tooltip";
import SmallHeader from "hw/portal/modules/common/components/small-header";
import theme from "hw/ui/theme";

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  onToggle: (isOpen: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Wrapper({ closed, ...rest }: any) {
  const cn = useStyle("draft-editor-sidebar", {
    overflowY: "auto",
    width: "240px",
    backgroundColor: theme.color.white,
    borderRightColor: theme.color.dividerDim,
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    boxShadow: theme.shadow[3],
    transition: "all 0.2s linear 0s",
    marginLeft: closed && "-240px",
  });
  return <div className={cn} {...rest} />;
}

const Close = createComponent(function SidebarClose() {
  return {
    height: "24px",
    cursor: "pointer",
  };
});
const HeaderContainer = createComponent(function SidebarHeaderContainer({
  theme,
}) {
  return {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    paddingTop: theme.space.ms,
    position: "relative",
    paddingBottom: theme.space.ms,
  };
});
export class Sidebar extends React.Component<Props> {
  render() {
    const { title, children, open, onToggle } = this.props;
    return (
      <Wrapper
        closed={!open}
        data-testid="editor-sidebar"
        {...getTestAttributes("editor-sidebar")}
      >
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message */}
        <HeaderContainer>
          {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: ReactNode; }' has no properties ... Remove this comment to see the full error message */}
          <SmallHeader>{title}</SmallHeader>
          <Close>
            <Tooltip tip="Hide form list">
              <IconWrapper
                onClick={() => onToggle(false)}
                {...getTestAttributes("editor-sidebar-close-btn")}
              >
                <CloseIcon />
              </IconWrapper>
            </Tooltip>
          </Close>
        </HeaderContainer>
        <Flex
          flexDirection="column"
          {...getTestAttributes("editor-sidebar-forms")}
        >
          {children}
        </Flex>
      </Wrapper>
    );
  }
}
export default Sidebar;
