import { getIn } from "timm";
import {
  maxImportSize,
  isFormatValid,
  fileStatus,
} from "../utils/file-attachment";

const dataTypeKeys = {
  file: "F",
  date: "DateInput",
};

function fileIsPresent(file) {
  const fileExists = file && file.guid && file.name != null;
  const filePartialyExists = [
    fileStatus.pending,
    fileStatus.timeout,
    fileStatus.serviceUnavailable,
  ].some((status) => status === getIn(file, ["metadata", "status"]));

  return fileExists || filePartialyExists;
}

/*
 * This is a special functionality for the date input. Since we can't get the
 * partial value for <input type="date" />, we check if is valid, and if it's not,
 * we set the value as null. This allow us to show an invalid message
 */
function dateIsPresent(value) {
  if (value === null) return true;

  return Boolean(value);
}

// Data Types
const dataTypes = {
  [dataTypeKeys.file]: {
    validation: (file) => {
      const status = getIn(file, ["metadata", "status"]);
      const errors = [];

      if (file) {
        if (file.size && file.size > maxImportSize) {
          errors.push("size");
        }

        if (file.type && !isFormatValid(file)) {
          errors.push("type");
        }

        switch (status) {
          case fileStatus.success: {
            // No errors, do nothing
            break;
          }
          case fileStatus.pending: {
            errors.push("pending");
            break;
          }
          case fileStatus.timeout: {
            errors.push("timeout");
            break;
          }
          case fileStatus.serviceUnavailable: {
            errors.push("serviceUnavailable");
            break;
          }
          default: {
            errors.push("invalid");
          }
        }
      }

      return errors;
    },
    isPresent: fileIsPresent,
    // These can be overridden from the schema
    errors: {
      size: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.file-attachment.errors.size",
        values: {
          maxSize: maxImportSize / 1000000,
        },
      },
      type: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.file-attachment.errors.type",
      },
      invalid: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.file-attachment.errors.invalid",
      },
      serviceUnavailable: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.file-attachment.errors.serviceUnavailable",
        hasHtml: true,
      },
      timeout: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.file-attachment.errors.timeout",
      },
      pending: "", // Invisible error so we can prevent next but not confuse the user with an error message.
    },
  },
  [dataTypeKeys.date]: {
    validation: (value) => {
      const errors = [];
      // If the value of the date is null, we set it as invalid
      if (value === null) {
        errors.push("invalidOrIncomplete");
      } else if (dateIsPresent(value)) {
        // Test that matches the regex NNNN-NN-NN where N is a digit
        // We check this first, since the constructor of date supports more
        // formats than YYYY-MM-DD
        const regexVal = /^\d{4}-\d{2}-\d{2}$/.test(value);

        if (!regexVal) {
          errors.push("invalidFormat");
        } else {
          try {
            // If the regex passes, check the date
            const date = new Date(value).toISOString();
            if (!date) errors.push("invalid");
          } catch (e) {
            errors.push("invalid");
          }
        }
      }
      return errors;
    },
    isPresent: dateIsPresent,
    errors: {
      invalidOrIncomplete: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.date-input.errors.invalidOrIncomplete",
      },
      invalidFormat: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.date-input.errors.invalidFormat",
      },
      invalid: {
        stringId:
          "sf.components.smart-view.smart-form.smart-components.date-input.errors.invalid",
      },
    },
  },
};

const fieldTypes = {
  FileAttachment: {
    requiredValidation: (data) => dataTypes[dataTypeKeys.file].isPresent(data),
  },
  DateInput: {
    requiredValidation: (data) => dataTypes[dataTypeKeys.date].isPresent(data),
  },
};

export default {
  dataTypeKeys,
  /**
   * Returns the validation function for a specific data type.
   * @param dataType
   * @returns function
   */
  getDataTypeValidator: (dataType) =>
    getIn(dataTypes, [dataType, "validation"]),
  /**
   * For the passed type get it's isPresent function.
   * @param type
   * @returns function
   */
  getDataTypeIsPresentValidator: (type) =>
    getIn(dataTypes, [type, "isPresent"]),
  /**
   * Wrapper for when we don't know a piece of data's data type. Normally we would just assume an object with no
   * metadata and no keys was empty. But because of legacy components that might store objects but still not have metadata
   * we can't be 100% confident. So instead we create a wrapper for all components where we want to use the metadata
   * approach.
   *
   * @param fieldType
   * @returns function
   */
  getFieldTypeRequiredValidator: (fieldType) =>
    getIn(fieldTypes, [fieldType, "requiredValidation"]),
  getDataTypeErrorMessage: (dataType, error) =>
    getIn(dataTypes, [dataType, "errors", error]),
};
