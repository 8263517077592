import * as React from "react";
import { withStyle } from "hw/ui/style";
import Theme from "hw/ui/theme";
import { CheckIcon } from "hw/ui/icons";
import { WordBreakText, WordBreakTextParent } from "hw/ui/text";
import style from "./style";

/**
 * Public props - These are props that are available for consumers to use
 */
export type Props = {
  /**
   * Sets wether the checkbox is disabled or not
   */
  disabled?: boolean;

  /**
   * Sets wether the checkbox is checked or unchecked
   */
  checked?: boolean;

  /**
   * Text that is displayed next to the checkbox
   */
  label?: string;

  /**
   * Optional font size override for label
   */
  labelFontSize?: string;

  /**
   * Function triggered when there is a change
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  /**
   * Function triggered when there is a blur.
   */
  onBlur?: (e: React.SyntheticEvent) => void | null | undefined;

  /**
   * Sets the checkbox as invalid, changing the style
   */
  invalid?: boolean;

  /**
   * Name of the field of the checkbox
   */
  name?: string;

  /**
   * Value of the checkbox
   */
  value: number | string | boolean;

  /**
   * Flag that sets autoFocus on checkbox
   */
  autoFocus?: boolean;

  /**
   * Test ID handle
   */
  dataTestId?: string;

  dataAutomationId?: string;
};

type FelaProps = {
  styles: {
    label: string;
    input: string;
    wrapper: string;
    icon: string;
  };
  theme: typeof Theme;
};

/**
 * The `Checkbox` component is a wrapper around a basic checkbox, keeping all the
 * functionality and accessibility but using a custom icon and style. It is a
 * controlled component, meant to be used with a light wrapper which handles the
 * logic when changing the value.
 */
export function Checkbox(props: Props & FelaProps) {
  const {
    disabled,
    checked,
    label,
    onChange,
    onBlur,
    name,
    value,
    autoFocus,
    styles,
    dataTestId,
  } = props;

  return (
    <label className={styles.label}>
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        name={name}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | boolean' is not assignable... Remove this comment to see the full error message
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus={autoFocus}
        data-testid={dataTestId}
        data-automation-id={dataTestId}
      />
      <WordBreakTextParent className={styles.wrapper}>
        <div className={styles.icon}>
          <CheckIcon />
        </div>
        <WordBreakText>{label}</WordBreakText>
      </WordBreakTextParent>
    </label>
  );
}
Checkbox.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBlur: () => {},
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: ({ theme, disabled, lab... Remove this comment to see the full error message
export default withStyle(style)(Checkbox) as React.ComponentType<Props>;
