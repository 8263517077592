import * as React from "react";

export default function Sparkle() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75914 1.1814C6.82816 0.939532 7.17094 0.939532 7.23995 1.1814L7.90035 3.49584C8.19915 4.54305 9.04199 5.34609 10.1024 5.59395L10.8062 5.75844C11.0636 5.81862 11.0636 6.18514 10.8062 6.24532L10.1027 6.40973C9.0423 6.65759 8.19947 7.46063 7.90066 8.50784L7.23995 10.8234C7.17094 11.0652 6.82816 11.0652 6.75914 10.8234L6.09842 8.50779C5.79961 7.46058 4.95678 6.65754 3.89636 6.40969L3.19312 6.24532C2.93565 6.18514 2.93565 5.81862 3.19312 5.75844L3.89667 5.594C4.95709 5.34614 5.79992 4.5431 6.09873 3.49589L6.75914 1.1814ZM2.35184 1.14105C2.37193 0.963543 2.62985 0.963542 2.64994 1.14105L2.73074 1.85512C2.77379 2.23559 3.03032 2.55807 3.39136 2.68559L3.90037 2.86538C4.03376 2.91249 4.03376 3.10114 3.90037 3.14825L3.39123 3.32808C3.0302 3.4556 2.77367 3.77808 2.73062 4.15855L2.64994 4.87153C2.62985 5.04904 2.37193 5.04904 2.35184 4.87153L2.27121 4.15905C2.22816 3.77858 1.97164 3.4561 1.6106 3.32858L1.10004 3.14825C0.966652 3.10114 0.966652 2.91249 1.10004 2.86538L1.61047 2.68509C1.97151 2.55757 2.22804 2.23509 2.27109 1.85462L2.35184 1.14105Z"
        fill="#9844CD"
      />
    </svg>
  );
}
