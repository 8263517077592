import React from "react";
import Tooltip from "hw/ui/tooltip";
import { ArrowSort } from "hw/ui/icons";
import styles from "./table-heading.module.css";
import CONSTANTS from "./constants";

type Props = {
  sortable: boolean;
  heading: string;
  isSortedCol: boolean;
  sortDirection: string;
  dataAutomationId: string;
  alignRight: boolean;
  first: boolean;
};

function TableHeading(props: Props) {
  const {
    sortable,
    heading,
    isSortedCol,
    sortDirection,
    dataAutomationId,
    alignRight,
    first,
    ...rest
  } = props;
  const tip = isSortedCol ? "Reverse sort order" : `Sort by ${heading}`;

  const classNames = [
    styles.header,
    sortable ? styles.sortable : "",
    alignRight ? styles.right : "",
    first && !alignRight ? styles.first : "",
  ].join(" ");

  const arrowWrapperClasses = [
    !isSortedCol ? styles.inactiveSort : "",
    sortDirection === CONSTANTS.SORT_ORDER.DESC ? styles.desc : "",
    styles.arrowWrapper,
  ].join(" ");

  const Heading = () =>
    sortable ? (
      <Tooltip tip={tip} position="top">
        <div>
          {alignRight && (
            <div className={arrowWrapperClasses}>
              <ArrowSort className={styles.arrowIcon} />
            </div>
          )}
          <span>{heading}</span>
          {!alignRight && (
            <div className={arrowWrapperClasses}>
              <ArrowSort className={styles.arrowIcon} />
            </div>
          )}
        </div>
      </Tooltip>
    ) : (
      <div>{heading}</div>
    );

  return (
    <div
      {...rest}
      className={classNames}
      data-automation-id={dataAutomationId || `${heading} column`}
    >
      <Heading />
    </div>
  );
}
export default TableHeading;
