import * as React from "react";
import theme from "hw/ui/theme";
import { useStyle, Extend } from "hw/ui/style";
import getTestAttributes from "hw/common/utils/get-test-attributes";

type Props = {
  color?: keyof typeof theme.color;
  extend?: Extend;
  "data-guide-id"?: string;
  "data-testid"?: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * @one-off Button special button to style an icon. This could be one variation
 * of the presentation prop in the button
 *
 * TODO: This needs to be inheriting the "subtle" button style
 */
function style(props: Props) {
  const color = props.color || "iconDefault";
  return {
    display: "inline-flex",
    flexShrink: 0,
    flexGrow: 0,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.corner.sm,
    textDecoration: "none",
    transitionProperty: "all",
    transitionDuration: theme.transitionSpeed.fast,
    color: theme.color[color],
    backgroundColor: theme.color.transparent,
    outline: "none",
    border: "none",
    cursor: "pointer",
    boxShadow: "none",
    padding: theme.space.xs,
    ":hover": {
      backgroundColor: theme.color.buttonBgStandardHover,
      color: theme.color.iconHover,
    },
    ":active": {
      boxShadow: "none",
      backgroundColor: theme.color.uiActionPressed,
      color: theme.color.iconPressed,
    },
    ":focus": {
      boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
      outline: "none",
    },
    ":disabled": {
      color: theme.color.iconDisabled,
      cursor: "not-allowed",
      opacity: 0.6,
      ":hover": {
        backgroundColor: theme.color.transparent,
        color: theme.color.iconDisabled,
        opacity: 0.6,
      },
    },
  };
}

function IconButton(props: Props, ref: React.ForwardedRef<HTMLButtonElement>) {
  const {
    extend,
    "data-guide-id": dataGuideId = "ui-icon-button",
    "data-testid": dataTestId = "ui-icon-button",
    ...rest
  } = props;
  const cn = useStyle(
    "icon-button",
    style(props), // $FlowIgnore
    typeof extend === "function" ? extend({ ...props, theme }) : extend
  );
  return (
    <button
      ref={ref}
      {...rest}
      className={cn}
      data-testid={dataTestId}
      {...getTestAttributes(dataGuideId)}
    />
  );
}

export default React.forwardRef<HTMLButtonElement, Props>(IconButton);
