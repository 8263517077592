/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Text } from "hw/ui/blocks";
import { Heading2, TextBody1 } from "hw/ui/text";
import { Stack } from "hw/ui/layout";
import Button from "hw/ui/button";
import {
  ParticipantFieldSets,
  MergeFieldInput,
  Divider,
} from "hw/portal/modules/launch-workflow";

export default function Form(props: any) {
  const {
    data,
    errorMsg,
    onSubmit,
    onChange,
    privacyPolicyUrl,
    disabled,
    roles,
    mergeFields,
    onChangeMergeField,
    name,
  } = props;

  const hasMergeFields = Boolean(mergeFields.length);
  const hasMultipleParticipants = roles.length > 1;

  return (
    <Stack space="lg">
      <div>
        <Heading2>{name}</Heading2>
      </div>
      <form onSubmit={onSubmit}>
        <Stack space="lg">
          <Stack space="md">
            <Divider />
            <div>
              <TextBody1>
                {hasMultipleParticipants
                  ? "Enter participant information for these forms. Each participant will receive an email to begin once the prior participant finishes."
                  : "Enter your name and email and a unique link will be sent to you."}
              </TextBody1>
              <TextBody1>
                {!hasMultipleParticipants &&
                  "(This step is included to comply with eSignature laws.)"}
              </TextBody1>
            </div>
            <ParticipantFieldSets
              roles={roles}
              data={data.roles}
              onChange={onChange}
            />
          </Stack>

          {hasMergeFields && (
            <Stack space="md">
              <Divider />
              <TextBody1>
                The following information is used in this{" "}
                {hasMultipleParticipants ? "workflow" : "form"}.
              </TextBody1>
              <Box extend={{ maxWidth: "240px" }}>
                {/* @ts-expect-error refactor */}
                {mergeFields.map((mergeField) => {
                  return (
                    <MergeFieldInput
                      key={mergeField.data_ref}
                      mergeField={{
                        ...mergeField,
                        dataRef: mergeField.data_ref,
                      }}
                      value={data.mergeFields[mergeField.data_ref]}
                      onChange={onChangeMergeField}
                    />
                  );
                })}
              </Box>
            </Stack>
          )}
          <Stack space="md">
            {errorMsg && (
              <Text fontSize="ms" color="red500" data-testid="err-message">
                {errorMsg}
              </Text>
            )}

            <Button type="submit" disabled={disabled}>
              Send
            </Button>
          </Stack>
        </Stack>
      </form>

      <Text
        as="a"
        fontSize="ms"
        // @ts-expect-error refactor
        href={privacyPolicyUrl}
        target="_blank"
        rel="noreferrer noopener"
        display="block"
      >
        <FormattedMessage
          id="onboarding.login.privacyPolicyLink"
          defaultMessage="Privacy Policy"
        />
      </Text>
    </Stack>
  );
}
