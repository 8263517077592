import React from "react";
import memoize from "lodash/memoize";
import isEmpty from "lodash/isEmpty";
import { getIn, omit } from "timm";
import type {
  GroupField,
  Role,
  Field,
  Mapping,
  Form,
  MergeField,
} from "hw/portal/modules/common/draft";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import type { Path } from "hw/common/types";
import { createComponent } from "hw/ui/style";
import ExpressionBuilder from "../../expression-builder";
import { useFormBuilderState } from "../../form-builder-context";
import { Actions } from "../../state";
import {
  Large as NewComponentMenuLarge,
  Small as NewComponentMenuSmall,
} from "./component-menu/dropdown";
import Group from "./group";
import DroppableSection from "./droppable-section";
import ConditionalLabel from "./conditional-label";

type Props = {
  field: GroupField;
  path: Path;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCreate: (path: Path, type: string, attrs?: {}) => void;
  order?: string;
  roleDescriptions?: Record<
    Role["id"],
    {
      title: string;
      badge: string;
    }
  >;
  // eslint-disable-next-line @typescript-eslint/ban-types
  jumpToDef: (jumpParams: {}) => void;
  isAnyFieldDragging?: boolean;
  fields: Array<Field>;
  isSelected?: boolean;
  formId: string;
  warnings: Array<DraftFeedback>;
  errors: Array<DraftFeedback>;
  mapping: Mapping;
  forms: Array<Form>;
  mergeFields: Array<MergeField>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: any;
  isPaid: boolean;
};
type ContextProps = {
  selectedFieldPath: Path | null | undefined;
};

class GroupPreview extends React.Component<Props & ContextProps> {
  /* $FlowFixMe[missing-annot] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  getPath = memoize(
    (path: Path) => path,
    (path) => path.join(".")
  );

  // TODO: add types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange = (newExpression: any) => {
    const { editorDispatch, path } = this.props;
    editorDispatch(
      Actions.ChangeFieldSetting({
        path: [...path, "visible"],
        updater: () => newExpression,
      })
    );
  };

  jumpToVisible = () => {
    const { path } = this.props;
    this.props.jumpToDef({
      path: [...path, "visible"],
    });
  };

  // NOTE: we are adding a key so the expression builder can re-render when we
  // remove fields. This is because the component holds a state with an especific
  // format for rules.
  getKey = () => {
    const { field } = this.props;
    const args = getIn(field, ["visible", "args"]);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'length' does not exist on type 'never'.
    if (args) return `conditional-rule-${field.id}-${args.length}`;
    // This only happens if someone goes to code view and remove "args". I'll
    // add this here so it doesn't break, but we shouldn't run into it.
    return `conditional-rule-${field.id}`;
  };

  nestedAttrs = {
    roles: this.props.field.roles,
  };

  triggerProps = {
    // We have to stop propagation in this case because otherwise
    // opening the menu will also select the field and cause a re-render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (evt: any) => evt.stopPropagation(),
  };

  render() {
    const {
      field,
      onCreate,
      fields,
      isSelected,
      isAnyFieldDragging,
      selectedFieldPath,
      isPaid,
    } = this.props;
    const { children, label } = field;
    const exprBuilderFeedback = getExpressionBuilderFeedback(this.props, [
      ...this.props.path,
      "visible",
    ]);
    const path = this.getPath([...this.props.path, "children"]);
    const passthroughProps = omit(this.props, ["warnings", "errors"]);
    return (
      <div>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message */}
        <WrapperTitle isSelected={isSelected}>
          <ConditionalLabel label={label} />
          <ExpressionBuilder
            fields={fields}
            field={field}
            onChange={this.handleChange}
            disabled={isAnyFieldDragging || !isSelected}
            initialExpression={this.props.field.visible}
            jumpToDef={this.jumpToVisible}
            feedback={exprBuilderFeedback}
            key={this.getKey()}
          />
        </WrapperTitle>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message */}
        <WrapperBody>
          {isEmpty(children) ? (
            <EmptySection {...this.props} path={path} />
          ) : (
            <React.Fragment>
              <Group
                {...passthroughProps}
                selectedFieldPath={selectedFieldPath}
                // @ts-expect-error refactor
                fields={field.children}
                path={path}
                isNested={true}
                nestedAttrs={this.nestedAttrs}
              />
              <NewComponentMenuLarge
                // @ts-expect-error refactor
                path={this.getPath([...path, children.length])}
                onSelect={onCreate}
                isNested={true}
                nestedAttrs={this.nestedAttrs}
                triggerProps={this.triggerProps}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: Path; onSelect: (path: Path, type: s... Remove this comment to see the full error message
                isPaid={isPaid}
              />
            </React.Fragment>
          )}
        </WrapperBody>
      </div>
    );
  }
}

function EmptySection(props: Props) {
  const { field, onCreate, isPaid } = props;
  // If you touch this, please fix the lint warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const path = React.useMemo(() => [...props.path, 0], [props.path.join(".")]);
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; path: (string | number)... Remove this comment to see the full error message
    <DroppableSection
      {...props}
      path={path}
      isNested={true}
      nestedAttrs={{
        roles: field.roles,
      }}
    >
      <NewComponentMenuSmall
        path={path}
        onSelect={onCreate}
        isNested={true}
        nestedAttrs={{
          roles: field.roles,
        }}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: (string | number)[]; onSelect: (path... Remove this comment to see the full error message
        isPaid={isPaid}
      />
    </DroppableSection>
  );
}

const WrapperTitle = createComponent(function WrapperTitle({
  theme,
  isSelected,
}) {
  return {
    backgroundColor: isSelected && theme.color.gray025,
    paddingTop: theme.space.md,
    paddingBottom: theme.space.ms,
    paddingLeft: theme.space.ms,
    paddingRight: theme.space.ms,
  };
});
const WrapperBody = createComponent(function WrapperBody({ theme }) {
  return {
    marginLeft: theme.space.ms,
    marginRight: theme.space.ms,
    marginTop: theme.space.md,
  };
});

/**
 * Flattens out the errors and warnings for data refs _specifically_ for the
 * expression builder.  This is not a long-term solution.
 *
 * See:  https://hellosign.atlassian.net/browse/HWD-1829
 */
export function getExpressionBuilderFeedback(
  { errors, warnings }: Partial<Props>,
  visiblePath: Path
) {
  const errFeedback = (errors || []).filter((err) => {
    return isPathSubset(err.path, visiblePath);
  });
  const warningFeedback = (warnings || []).filter((err) => {
    return isPathSubset(err.path, visiblePath);
  });
  return [...errFeedback, ...warningFeedback];
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function isPathSubset(path, subPath) {
  return path.join(".").includes(subPath.join("."));
}

function GroupPreviewWithContext(props: Props) {
  const state = useFormBuilderState();
  const { errors, warnings, form, selectedFieldPath } = state;
  return (
    <GroupPreview
      {...props}
      fields={form.fields}
      errors={errors}
      warnings={warnings}
      selectedFieldPath={selectedFieldPath}
    />
  );
}

export default GroupPreviewWithContext;
