export const Levels = {
  error: "error",
  warn: "warn",
  info: "info",
  log: "log",
  debug: "debug",
};

const Thresholds = {
  [Levels.error]: 1,
  [Levels.warn]: 2,
  [Levels.info]: 3,
  [Levels.log]: 4,
  [Levels.debug]: 5,
};

const isDev = process.env.NODE_ENV === "development";
const defaultLogLevel = isDev ? Levels.debug : Levels.error;
const noopHandler = (function createNoopHandler() {
  const handler = {};

  Object.keys(Levels).forEach((level) => {
    handler[level] = () => {};
  });

  return handler;
})();

/**
 * A shared logger meant to be used on either client or server.  Using this logger
 * requires dynamically setting the handler with the `init` method.  A
 * handler must implement all of the above log level functions.
 *
 * The handler should be a function that returns an object that implements a
 * basic console interface:
 *
 * function createHandler() {
 *   return {
 *     log: console.log.bind(console),
 *     warn: console.log.bind(console),
 *     //...
 *   }
 * }
 */
class Logger {
  constructor(options = {}) {
    this.namespace = options.namespace;
    this.createHandler = options.createHandler;
    this.level = options.level || defaultLogLevel;
    this.handler = null;

    this.patch(noopHandler);

    if (this.createHandler) {
      this.init(this.createHandler);
    }
  }

  /**
   * Sets the global logging handler to the result of the given `createHandler`
   * function.
   */
  init(createHandler) {
    // Sanity check - I don't think we'll ever want the logger to be initialized
    // more than once
    if (this.handler) {
      const msg = [
        "Trying to re-initialize the logger that's already been initialized.",
        "If this was intentional, call the `clearHandler()` method first",
      ].join("\n");
      throw new Error(msg);
    }

    this.createHandler = createHandler;
    this.handler = createHandler({
      namespace: this.namespace,
    });

    this.patch(this.handler);
  }

  /**
   * This iterates through the handler and assigns the keys directly to the
   * singleton instance.  This method trusts that the handler is implementing
   * the correct interface.  The methods are patched to the instance specifically
   * so that line numbers can be preserved when logging in the browser.  To
   * preserve line numbers, the `console` function must be called directly
   * without any kind of wrapper function.
   */
  patch(handler) {
    Object.keys(Levels).forEach((level) => {
      this[level] = handler[level];
    });

    this.warnOnce = logOnce(handler.warn);
  }

  /**
   * Clear the handler
   */
  clearHandler() {
    this.handler = null;
  }

  /**
   * Set the log level for this logger instance
   */
  setLevel(level) {
    this.level = level;
    if (this.handler) {
      this.patch(this.handler);
    }
  }

  /**
   * Creates a namespaced logger as a new instance
   */
  create(namespace) {
    return new Logger({
      namespace,
      createHandler: this.createHandler,
      level: this.level,
    });
  }

  /**
   * Returns `true` if the given level should be logged.  It can be helpful
   * to have the handlers call this so that actions can still be taken even if
   * nothing is logged (e.g. Reporting errors to Sentry)
   */
  shouldLogForLevel(level) {
    const currentLevel = this.level;
    const threshold = Thresholds[currentLevel];
    const value = Thresholds[level];

    if (Number.isNaN(value) || Number.isNaN(threshold)) {
      return false;
    }

    if (value <= threshold) {
      return true;
    } else {
      return false;
    }
  }
}

export const logger = new Logger();

/**
 * Calls the given `loggerFn` only if the message has not already been logged.
 * Only works with string messages to avoid any confusion around reference
 * equality
 */
function logOnce(loggerFn) {
  const cache = new Set();

  return (message, ...rest) => {
    if (typeof message !== "string") {
      throw new Error(
        `Expected a string message. Received "${typeof message}"`
      );
    }
    if (cache.has(message)) return;
    cache.add(message);
    loggerFn(message, ...rest);
  };
}
