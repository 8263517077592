/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import getTestAttributes from "hw/common/utils/get-test-attributes";

type Props = {
  /**
   * Maximum height of the text area before start scrolling
   */
  maxHeight?: number;
  /**
   * Minimum height of the text area
   */
  minHeight?: number;
  /**
   * Function triggered when the value changes
   */
  onChange: (...args: Array<any>) => any;
  /**
   * Placeholder value for the textarea
   */
  placeholder?: string;
  /**
   * Value assigned to the textarea
   */
  value?: string;
  /**
   * Maximum number of characters. If not defined, there is no limit
   */
  maxLength?: number;
  /**
   * Determines if it should be readonly/disabled
   */
  readOnly?: boolean;
  /**
   * Function triggered onBlur
   */
  onBlur?: (...args: Array<any>) => any;
};

/**
 * This component can be part of our foundational components
 */
export default function TextArea(props: Props) {
  const {
    maxHeight = 300,
    minHeight = 60,
    maxLength,
    onChange,
    placeholder,
    value,
    readOnly,
    onBlur,
    ...rest
  } = props;

  const ref = React.useRef(null);
  const scrollHeight = ref.current?.scrollHeight;
  const clientHeight = ref.current?.clientHeight;

  // This calculates the height base on the scroll height and min/max heights defined in the props
  const height = React.useMemo(() => {
    if (scrollHeight && clientHeight) {
      if (scrollHeight > clientHeight) {
        return scrollHeight > maxHeight ? maxHeight : scrollHeight;
      } else {
        return minHeight;
      }
    }
  }, [clientHeight, maxHeight, minHeight, scrollHeight]);

  const style = {
    height: `${height || minHeight}px`,
    fonSize: theme.fontSize.ms,
    width: "100%",
    borderColor: theme.color.gray400,
    borderWidth: theme.corner.xs,
    borderRadius: theme.corner.sm,
    padding: theme.space.ms,
    resize: "none",
  };

  const cn = useStyle("text-area", style);
  const handleChange = React.useCallback(
    (e) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <textarea
      ref={ref}
      className={cn}
      onChange={handleChange}
      placeholder={readOnly ? undefined : placeholder}
      value={value}
      maxLength={maxLength ? maxLength.toString() : undefined}
      readOnly={readOnly}
      disabled={readOnly}
      onBlur={onBlur}
      {...getTestAttributes("multiline")}
      {...rest}
    />
  );
}
