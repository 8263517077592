import { createComponent } from "hw/ui/style";

// @one-off
export const EditInput = createComponent(
  function Input({ theme }) {
    return {
      border: "none",
      fontSize: theme.fontSize.ms,
      fontFamily: "inherit",
      fontWeight: "inherit",
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.sm,
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      minWidth: "250px",
      ":focus": {
        outline: "none",

        // TODO: Decide on how we handle placeholders with focused states
        // In our `base.css` file we're globally setting placeholders to disappear
        // when inputs are focused.  I don't want that for this particular input
        // and I'm not sure we want that set globally.
        "::-webkit-input-placeholder": {
          opacity: 1,
        },
        "::-moz-input-placeholder": {
          opacity: 1,
        },
        "::-ms-input-placeholder": {
          opacity: 1,
        },
      },
    };
  },
  "input",
  ["placeholder", "value", "onChange", "autoComplete", "onBlur", "onKeyDown"]
);

export const EditContainer = createComponent(
  function Container({ theme }) {
    return {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
    };
  },
  "form",
  ["onSubmit", "onKeyDown", "onBlur"]
);

export const EditControls = createComponent(function Controls({ theme }) {
  return {
    display: "flex",
    borderLeftStyle: "solid",
    borderLeftColor: theme.color.gray200,
    borderLeftWidth: "1px",
    alignItems: "center",
    paddingLeft: theme.space.sm,
    paddingRight: theme.space.sm,
  };
});
