import * as React from "react";
import { Flex } from "hw/ui/blocks";
import { createComponent } from "hw/ui/style";

const duration = "1.4s";
const increment = 120;

const keyframe = () => ({
  "0%": {
    transform: "scale(0)",
  },
  "20%": {
    transform: "scale(0)",
  },
  "100%": {
    transform: "scale(0)",
  },
  "60%": {
    transform: `scale(1)`,
  },
});

const Dot = createComponent(({ delay, size = "0.8rem" }, renderer) => ({
  width: size,
  height: size,
  borderRadius: "50%",

  // Get from color template?
  backgroundColor: "rgba(114, 122, 131, 0.8)",
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  animationName: renderer.renderKeyframe(keyframe),
  animationDuration: duration,
  animationDelay: `${delay}ms`,
  animationFillMode: "both",
  animationIterationCount: "infinite",
  animationTimingFunction: "cubic-bezier(.5, 0, .5, 1)",
  marginLeft: `${0.2}rem`,
  marginRight: `${0.2}rem`,
}));

const HiddenText = createComponent(() => ({
  position: "absolute",
  visibility: "hidden",
  userSelect: "none",
}));

/**
 * Super basic loader component
 *
 * TODO: Research this more.  This is mainly a placeholder loader component
 * that we'll replace at a later point.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Loader(props: any) {
  return (
    <Flex justifyContent="center" my="md" width={1} {...props}>
      <HiddenText>Loading...</HiddenText>
      <Dot delay={0} size={props.size ?? "0.8rem"} />
      <Dot delay={increment} size={props.size ?? "0.8rem"} />
      <Dot delay={increment * 2} size={props.size ?? "0.8rem"} />
    </Flex>
  );
}

export default Loader;
