import * as React from "react";
import type {
  Field,
  MappedField,
  MergeField,
  FormMapping,
} from "hw/portal/modules/common/draft";
import { Factories } from "hw/portal/modules/common/draft";
import { SelectInput } from "hw/ui/select";
import PrefillMenu from "hw/portal/modules/draft-editor/components/prefill-menu";
import AddButton from "./basics/add-button";
import Wrapper from "./basics/wrapper";
import { genId } from "../utils";

type Props = {
  field: Field;
  activePrefill?: MappedField;
  mergeFields?: Array<MergeField>;
  formMappings: Array<FormMapping>;
  onChange: (mappedField: MappedField, field: Field) => void;
  onRemove: (field: Field) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal?: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreateMergeField?: (...args: Array<any>) => any;
};
/**
 * Handles the "Pre-fill" settings for a field.  If a field is "pre-filled"
 * that means that:
 *
 *   1. There is a merge field that exists at the workflow level
 *   2. There is mapping from the "start" step to this
 */

export default function PrefillSetting(props: Props) {
  const {
    formMappings = [],
    activePrefill,
    openMergeFieldsModal,
    field,
    onChange,
    onCreateMergeField,
    mergeFields,
    onRemove,
  } = props;
  const [checked, setChecked] = React.useState(Boolean(activePrefill));
  const handleSelect = React.useCallback(
    (item?: MappedField) => {
      if (!item) {
        if (activePrefill) onRemove(field);
      } else if (item.dataRef === "new" && onCreateMergeField) {
        const newItem = {
          label: item.label,
          dataRef: Factories.DataRef(item.label),
        };
        onCreateMergeField(newItem);
        onChange(newItem, field);
      } else {
        onChange(item, field);
      }
    },
    [activePrefill, field, onChange, onCreateMergeField, onRemove]
  );
  const allowMergeFields = field.type === "TextInput";
  const { current: id } = React.useRef(genId());
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; label: string; py: stri... Remove this comment to see the full error message
    <Wrapper label="Pre-fill" py="sm" data-testid="pre-fill-setting" id={id}>
      {checked ? (
        /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
         * found when upgrading Flow to v0.132.0. To view the error, delete
         * this comment and run Flow. */
        <SelectInput
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: (item?: any) => void; selectedIt... Remove this comment to see the full error message
          onChange={handleSelect}
          selectedItem={activePrefill || null}
          placeholder="Select source..."
          fillContainer
          usePreferredLayout={true}
          triggerProps={{
            id,
          }}
          justify="right"
          type="dropdown"
          renderDest="portal"
          render={({ getItemProps, highlightedIndex, inputValue }) => (
            <PrefillMenu // $FlowFixMe
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | any[] | undefined' is not assignable... Remove this comment to see the full error message
              mergeFields={allowMergeFields && mergeFields}
              formMappings={formMappings}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              selectedItem={activePrefill}
              inputValue={inputValue}
              openMergeFieldsModal={openMergeFieldsModal}
            />
          )}
        />
      ) : (
        <AddButton onClick={() => setChecked(true)} />
      )}
    </Wrapper>
  );
}
