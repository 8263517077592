import * as React from "react";

type Props = {
  size?: number;
};
export function LocateIcon(props: Props) {
  const { size = 24 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4a1 1 0 10-2 0v1.07A7.005 7.005 0 005.07 11H4a1 1 0 100 2h1.07A7.004 7.004 0 0011 18.93V20a1 1 0 102 0v-1.07A7.004 7.004 0 0018.93 13H20a1 1 0 100-2h-1.07A7.004 7.004 0 0013 5.07V4zm-1 13a5 5 0 100-10 5 5 0 000 10zm0-2a3 3 0 100-6 3 3 0 000 6z"
        fill="currentColor"
      />
    </svg>
  );
}
