/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/browser";
import isPlainObject from "lodash/isPlainObject";
// @ts-expect-error TODO
import { Levels } from "./logger";
import { isFSA, isError } from "../fsa-helpers";

/**
 * Takes a logger function and a level and returns a function which logs the
 * message as well as sends it to Sentry.
 */
export default function createSentryCapturer(
  loggerFn: any,
  level: any,
  SentryImpl = Sentry
) {
  const { error, warn, info } = Levels;

  return (msg: string, ...args: any[]) => {
    if (level === error) {
      SentryImpl.captureMessage(
        // @ts-expect-error refactor
        normalizeCapturedMessage(msg, ...args),
        "error" as Sentry.Severity
      );
    } else if (level === warn) {
      SentryImpl.captureMessage(
        normalizeCapturedMessage(msg),
        "warning" as Sentry.Severity
      );
    } else if (level === info) {
      SentryImpl.captureMessage(
        normalizeCapturedMessage(msg),
        "info" as Sentry.Severity
      );
    }

    return loggerFn(msg, ...args);
  };
}

/**
 * When capturing a message, it needs to be either an actual exception for
 * capturing errors or a string.  Sentry will coerce the message into a string
 * first, so if you try to send an object you'll end up with a message like:
 * "[object Object]"
 */
function normalizeCapturedMessage(error: any): any {
  // If we've passed a "Flux Standard Action" re-run the normalization with
  // the payload
  if (isFSA(error) && isError(error)) {
    return normalizeCapturedMessage(error.payload);
  } else if (isPlainObject(error) || Array.isArray(error)) {
    let stringifiedError;

    try {
      stringifiedError = JSON.stringify(error);
    } catch (e) {
      // If we've failed to stringify for some reason, just
      // send along the given error as is
      stringifiedError = error;
    }

    return stringifiedError;
  } else {
    return error;
  }
}
