/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Plugins
 *
 * This file contains a set of base plugins for the paragraph editor.  More
 * custom & complex plugins are extracted into their own folder within `/plugins`.
 */
import { detect } from "detect-browser";
import {
  inputRules as pmInputRules,
  wrappingInputRule,
  undoInputRule,
} from "prosemirror-inputrules";
import { Schema, Fragment } from "prosemirror-model";
import { undo, redo, history } from "prosemirror-history";
import { keymap as pmKeymap } from "prosemirror-keymap";
import { baseKeymap, toggleMark } from "prosemirror-commands";
import {
  splitListItem,
  sinkListItem,
  liftListItem,
} from "prosemirror-schema-list";
import { gapCursor } from "prosemirror-gapcursor";
import { dropCursor } from "prosemirror-dropcursor";
import type { EditorState, Transaction } from "prosemirror-state";

import * as commands from "./commands";
import type { NamedKeymap as NamedKeymapType } from "./types";

const browser = detect();
const isMac = browser && /Mac/.test(browser.os);

/**
 * Returns a map of "named" keymaps and input rules.
 *
 * It's useful to be able to refer to keymaps or input rules by name and attached additional
 * information to them, such as the description and shortcut text.  This is
 * used later in the toolbar for displaying the correct text for tooltips
 * and titles.
 */
export function NamedKeymap(config: any): NamedKeymapType {
  return {
    ...config,
    shortcut: createShortcut(config.key),
  };
}

export function NamedInputRule(config: any): any {
  return {
    ...config,
    shortcut: config.key,
  };
}

/**
 * Input Rules
 *
 * Input rules run commands based on certain input characters.  For example,
 * typing the characters "-" and then a space will insert a bullet list.
 */

export function inputRulesByName(schema: Schema) {
  const rules = {};
  function bind(config) {
    rules[config.name] = NamedInputRule(config);
  }

  bind({
    key: "- Space",
    name: "bulletList",
    rule: wrappingInputRule(/^\s*([-])\s$/, schema.nodes.bulletList),
    description: "Bulleted List",
  });

  bind({
    key: "1. Space",
    name: "orderedList",
    rule: wrappingInputRule(/^\s*([1])\.\s$/, schema.nodes.orderedList),
    description: "Numbered List",
  });

  return rules;
}

/**
 * Keymaps
 *
 * Keymaps are special keyboard combinations that trigger actions in the editor
 * but don't actually insert any text
 */

export function keymapsByName(schema: Schema) {
  const keys = {};
  function bind(config) {
    keys[config.name] = NamedKeymap(config);
  }

  bind({
    key: "Mod-z",
    name: "undo",
    description: "Undo",
    cmd: undo,
  });

  bind({
    key: "Shift-Mod-z",
    name: "redo",
    description: "Redo",
    cmd: redo,
  });

  bind({
    key: "Backspace",
    name: "backspace",
    description: "Backspace",
    cmd: undoInputRule,
  });

  bind({
    key: "Shift-Enter",
    name: "newLine",
    description: "New Line",
    cmd: insertNewLine,
  });

  bind({
    key: "Mod-b",
    name: "toggleBold",
    description: "Bold",
    cmd: toggleMark(schema.marks.strong),
  });

  bind({
    key: "Mod-i",
    name: "toggleItalic",
    description: "Italic",
    cmd: toggleMark(schema.marks.em),
  });

  bind({
    key: "Mod-u",
    name: "toggleUnderline",
    description: "Underline",
    cmd: toggleMark(schema.marks.underline),
  });

  bind({
    key: "Shift-Mod-x",
    name: "toggleStrikethrough",
    description: "Strikethrough",
    cmd: toggleMark(schema.marks.strike),
  });

  bind({
    key: "Enter",
    name: "splitListItem",
    description: "Split List Item",
    cmd: splitListItem(schema.nodes.listItem),
  });

  bind({
    key: "Tab",
    name: "sinkListItem",
    description: "Indent list item",
    cmd: sinkListItem(schema.nodes.listItem),
  });

  bind({
    key: "Shift-Tab",
    name: "liftListItem",
    description: "Outdent list item",
    cmd: liftListItem(schema.nodes.listItem),
  });

  bind({
    key: `Mod-Alt-1`,
    name: `toggleHeading1`,
    description: `Heading 1`,
    cmd: commands.toggleHeading(schema.nodes.heading, { level: 1 }),
  });

  bind({
    key: `Mod-Alt-2`,
    name: `toggleHeading2`,
    description: `Heading 2`,
    cmd: commands.toggleHeading(schema.nodes.heading, { level: 2 }),
  });

  return keys;
}

/**
 * Takes the map of named keymaps and binds them to the prosemirror view.  A
 * set of base keymaps are attached as well.
 */
export function bindKeymaps(keymapsByName: {}) {
  const customKeymaps = Object.keys(keymapsByName).reduce((map, name) => {
    const config = keymapsByName[name];
    map[config.key] = config.cmd;
    return map;
  }, {});

  return [pmKeymap(customKeymaps), pmKeymap(baseKeymap)];
}

/**
 * Takes the named input rules and binds them to ProseMirror
 */
export function bindInputRules(namedInputRules: {}) {
  const rules = Object.keys(namedInputRules).reduce((ruleList, name) => {
    const namedRule = namedInputRules[name];

    ruleList.push(namedRule.rule);

    return ruleList;
  }, []);

  return pmInputRules({ rules });
}

/**
 * Inserts a hard break node
 */
function insertNewLine(state: EditorState, dispatch: Transaction) {
  const { $from } = state.selection;
  const parent = $from.parent;
  const { hardBreak } = state.schema.nodes;

  if (hardBreak) {
    const hardBreakNode = hardBreak.create();

    if (parent && parent.type.validContent(Fragment.from(hardBreakNode))) {
      dispatch(state.tr.replaceSelectionWith(hardBreakNode));
      return true;
    }
  }

  return false;
}

/**
 * Creates a text string describing the shortcut text for a keymap
 */
function createShortcut(keymap) {
  let shortcut;
  if (isMac) {
    shortcut = keymap
      .replace(/Mod/i, "⌘")
      .replace(/Shift/i, "⇧")
      .replace(/Ctrl/i, "^")
      .replace(/Alt/i, "⌥");
  } else {
    shortcut = keymap;
  }

  const keys = shortcut.split("-");

  keys[keys.length - 1] = keys[keys.length - 1].toUpperCase();
  shortcut = keys.join(isMac ? "" : "+");

  return shortcut;
}

export { history, gapCursor, dropCursor };
