import * as React from "react";
import { createComponent } from "hw/ui/style";
import Item from "./item";
import type { OrderableListDragItem } from "../types";

type Props = OrderableListDragItem;
const Wrapper = createComponent(({ theme, width, height }) => ({
  height: `${height}px`,
  width: `${width}px`,
  boxShadow: theme.shadow[3],
}));
export function ItemDragPreview(props: Props) {
  const { index, item, width, height, renderItem } = props;
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
    <Wrapper width={width} height={height}>
      <Item index={index} item={item} renderItem={renderItem} />
    </Wrapper>
  );
}
export default React.memo(ItemDragPreview);
