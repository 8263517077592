/* eslint-disable @typescript-eslint/no-explicit-any */
import flatten from "lodash/flatten";
import { getIn, insert, removeAt, updateIn } from "timm";
import { splitPath, movePath } from "hw/portal/modules/draft-editor/utils";
import type {
  ParsedSchema,
  Role,
  Field,
  Form,
} from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import { invariant } from "hw/common/utils/assert";
import type { FieldDescriptor } from "./build/field-descriptor";
import { ROUTE } from "./constants";

/**
 * Caches a `path` value so that if the values are all the same, the same
 * array reference is returned. This is an unfortunate requirement because we
 * pass around `path` values as props, which means we're constantly constructing
 * new array values. Most of the time the arrays have the same values, but
 * since it's a new array it triggers a component re-render.
 */
const pathCache = new Map();

export function cachePath(arr: Path) {
  const key = arr.join(".");
  const cached = pathCache.get(key);

  if (cached) {
    return cached;
  } else {
    pathCache.set(key, arr);

    return arr;
  }
}

/**
 * Returns a valid route for the draft editor given a draftId
 */
export const route = (draftId: string): string =>
  ROUTE.replace(":workflowId", draftId);

/**
 * This function reorders the field list for a single form based on a re-order
 * within a specific role group. Because `Paragraph` fields are shared within
 * all role groups, we have to reorder the entire field list to ensure that
 * the individual role groups stay in their respective relative order. This is
 * particularly expensive right now becaause it fights against our data
 * structure. We only have a single field list, but we're to treat it as separate
 * lists in the UI.
 */
export function reorderWithinRole(
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  schema: ParsedSchema,
  sourceDescriptor: FieldDescriptor,
  targetDescriptor: FieldDescriptor
) {
  const sourcePath = sourceDescriptor.path;
  const targetPath = targetDescriptor.path;

  invariant(
    sourceDescriptor.roleGroup === targetDescriptor.roleGroup,
    "Cannot move fields between roles"
  );

  // :grimacing: This is probably not a long-term way to check for children.
  // Moving between parents and/or children should not affect the sorting of the
  // other role groups, so we can defer to the old logic. Checking for the
  // existence of `children` in the path is a quick way to do this without
  // needing to pass around more props or perform more lookups
  const anyChildren =
    sourceDescriptor.path.includes("children") ||
    targetDescriptor.path.includes("children");

  if (anyChildren) {
    return movePath(schema, sourcePath, targetPath);
  }

  const source = getIn(schema, sourceDescriptor.path);
  const target = getIn(schema, targetDescriptor.path);
  const targetRole = targetDescriptor.roleGroup;
  const [sourceItemsPath] = splitPath(sourceDescriptor.path);
  const [targetItemsPath] = splitPath(targetDescriptor.path);

  // The full field list being sorted
  const fields = getIn(schema, sourceItemsPath);
  // @ts-expect-error refactor
  const fieldsByRole = createRoleGroups(schema.roles, fields);

  // const targetRoleList = byRole(list, targetRole).map(item => item.field);
  // @ts-expect-error refactor
  const targetRoleGroup = fieldsByRole[targetRole].map((item) => item.field);

  // Find the local index of the items being sorted within their role group
  // @ts-expect-error refactor
  const localSourceIdx = targetRoleGroup.findIndex((field) => field === source);
  // @ts-expect-error refactor
  const localTargetIdx = targetRoleGroup.findIndex((field) => field === target);

  // Reorder the role group
  const reorderedTargetRoleList = move(
    targetRoleGroup,
    localSourceIdx,
    localTargetIdx
  );

  // Sort each of the other role groups' `Paragraph` fields based on the
  // order of the role group we just sorted so that the `Paragraph` fields
  // are in the same order for every group
  const otherRoleGroups = schema.roles
    .filter((r) => r.id !== targetRole)
    .map((role) =>
      sortShared(
        // @ts-expect-error refactor
        fieldsByRole[role.id].map((item) => item.field),
        reorderedTargetRoleList.filter(isSharedField)
      )
    );

  const allRoleGroups = [reorderedTargetRoleList, ...otherRoleGroups];
  // @ts-expect-error refactor
  const newList = [];
  const seen = new Set();

  // @ts-expect-error refactor
  function push(field) {
    if (!seen.has(field)) {
      newList.push(field);
      seen.add(field);
    }
  }

  for (const [roleListIdx, roleGroup] of allRoleGroups.entries()) {
    for (const field of roleGroup) {
      if (!seen.has(field)) {
        seen.add(field);

        if (!isSharedField(field)) {
          newList.push(field);
        } else {
          // Get the role lists positioned after the current one
          const followingRoleGroups = allRoleGroups.slice(roleListIdx + 1);
          const sharedField = field;

          // For each of the subsequent role groups, find the fields that come
          // before the shared field we're about to put into the list, but only
          // if we haven't already put them in the list
          const fieldsToReorder = flatten(
            followingRoleGroups.map((list) =>
              list.slice(0, list.indexOf(sharedField))
            )
          );

          fieldsToReorder.forEach(push);
          newList.push(sharedField);
        }
      }
    }
  }

  // @ts-expect-error refactor
  return updateIn(schema, targetItemsPath, () => newList);
}

// @ts-expect-error refactor
function move(arr, fromIdx, toIdx) {
  return insert(removeAt(arr, fromIdx), toIdx, arr[fromIdx]);
}

/**
 * Sorts the shared fields within the role group according to the given
 * order
 *
 * @example
 * sortShared(['Paragraph1', 'TextInput', 'Paragraph2'], ['Paragraph2', 'Paragraph1'])
 * //=> ["Paragraph2", "TextInput", "Paragraph1"]
 */
// @ts-expect-error refactor
function sortShared(list, order) {
  const copy = [...list];
  const sharedFieldIndexes = [];

  for (const [idx, field] of list.entries()) {
    if (isSharedField(field)) {
      sharedFieldIndexes.push(idx);
    }
  }

  const sharedFields = sharedFieldIndexes.map((idx) => list[idx]);
  const sortedValues = sharedFields.sort(
    (a, b) => order.indexOf(a) - order.indexOf(b)
  );

  for (const [idx, value] of sortedValues.entries()) {
    const targetIdx = sharedFieldIndexes[idx];

    copy[targetIdx] = value;
  }

  return copy;
}

/**
 * Creates a map of roleId -> Array<FieldDescriptor>. The field descriptor
 * contains not only the field itself, but the global path and roleGroup so
 * that it can be correctly targeted when needed.
 */
export function createRoleGroups(
  roles: Array<Role>,
  fields: Array<any>,
  path: Path = []
) {
  const groups = {};

  for (const role of roles) {
    const fieldsForRole = [];
    for (const [idx, field] of fields.entries()) {
      const fieldRole = getIn(field, ["roles", 0]);
      if (fieldRole === role.id || !fieldRole) {
        fieldsForRole.push(
          createDescriptor({
            field,
            path: [...path, idx],
            roleGroup: role.id,
          })
        );
      }
    }
    // @ts-expect-error refactor
    groups[role.id] = fieldsForRole;
  }

  return groups;
}

/**
 * Returns the list of field with only fields that are fillable. At compilation
 * time, the backend will filter out roles that do not have any fillable
 * fields assigned to them. This function mimics that behavior.
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function getFillableFields(fields: Array<Field>): Array<Field> {
  return fields.filter((field) => isFillableField(field));
}

/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
function isFillableField(field: Field): boolean {
  // @ts-expect-error refactor
  return hasExplicitRole(field) && field.type !== "Hidden";
}

/**
 * Returns the roles for this field
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function getFieldRoles(field: Field) {
  return field.roles ?? [];
}

/**
 * Returns `true` if this field has an explicitly assigned role
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function hasExplicitRole(field: Field): boolean {
  return Array.isArray(field.roles) && Boolean(field.roles[0]);
}

/**
 * Returns `true` if the field is assigned to the given role
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function isFieldAssignedToRole(field: Field, roleId: string): boolean {
  const fieldRoles = getFieldRoles(field);

  return fieldRoles.includes(roleId);
}

/**
 * Returns the unique list of _explicit_ role IDs used in a form. Fields without
 * a role id won't be assigned.
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function getFormRoleIds(form: Form) {
  const roles = new Set();

  for (const field of form.fields) {
    const fieldRoles = getFieldRoles(field);

    // @ts-expect-error refactor
    roles.add(...fieldRoles);
  }

  return [...roles];
}

export function createDescriptor({
  field,
  path,
  roleGroup,
  ...rest
}: FieldDescriptor): FieldDescriptor {
  return {
    ...rest,
    field,
    path,
    roleGroup,
  };
}

// @ts-expect-error refactor
function isSharedField(field) {
  return !field.roles[0];
}
