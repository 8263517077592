import { getIn } from "timm";
import Theme from "hw/ui/theme";
import { styleBySize, styleByState } from "./button-theme";
import type { Props } from "./button";

type Space = keyof typeof Theme["space"];
type Color = keyof typeof Theme["color"];

/**
 * getAttributeStyle - It returns the attribute style according to the state,
 * attribute and presentation of the button
 *
 * @param  {string} state        state of the component (hover, active or default)
 * @param  {string} attr         attribute we are trying to get (backgroundColor, color)
 * @param  {string} presentation presentation attribute of the button (in the constants)
 * @return {string}              value from styleByState object for the specific state, attr and presentation
 */
export function getAttributeStyle(
  state: string,
  attr: string,
  presentation?: string
): Color {
  // @ts-expect-error refactor
  return (getIn(styleByState, [presentation, attr, state]) ||
    // @ts-expect-error refactor
    getIn(styleByState, [presentation, attr, "default"]) ||
    getIn(styleByState, ["default", attr])) as Color;
}

/**
 * getStyleBySize - It returns the attribute style according to the size of the button
 */
function getStyleBySize(attr: string, size?: string): Space {
  // @ts-expect-error refactor
  return (getIn(styleBySize, [size, attr]) ||
    getIn(styleBySize, ["base", attr])) as Space;
}

export default function style(props: Props & { theme: typeof Theme }) {
  const { theme, size, presentation } = props;
  return {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: theme.fontWeight.semibold,
    borderRadius:
      // @ts-expect-error refactor
      theme.corner[getAttributeStyle("default", "corner", presentation)],
    textDecoration: "none",
    transitionProperty: "all",
    width: props.fitToContainer ? "100%" : "auto",
    transitionDuration: theme.transitionSpeed.fast,
    height: getStyleBySize("height", size),
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.space[getStyleBySize("padding", size)],
    paddingLeft: theme.space[getStyleBySize("padding", size)],
    fontSize: theme.fontSize[getStyleBySize("fontSize", size)],
    backgroundColor:
      theme.color[
        getAttributeStyle("default", "backgroundColor", presentation)
      ],
    color: theme.color[getAttributeStyle("default", "color", presentation)],
    opacity: 1,
    borderStyle:
      getAttributeStyle("default", "borderStyle", presentation) || "none",
    borderWidth:
      getAttributeStyle("default", "borderWidth", presentation) || "0",
    borderColor:
      theme.color[
        getAttributeStyle("default", "borderColor", presentation) ||
          "transparent"
      ],
    cursor: "pointer",
    boxShadow: "none",
    ":hover": {
      backgroundColor:
        theme.color[
          getAttributeStyle("hover", "backgroundColor", presentation)
        ],
      color: theme.color[getAttributeStyle("hover", "color", presentation)],
      boxShadow:
        // @ts-expect-error refactor
        theme.shadow[
          getAttributeStyle("hover", "boxShadow", presentation) || 1
        ],
      textDecoration:
        getAttributeStyle("hover", "textDecoration", presentation) || "none",
      borderColor:
        theme.color[
          getAttributeStyle("hover", "borderColor", presentation) ||
            "transparent"
        ],
    },
    ":active": {
      backgroundColor:
        theme.color[
          getAttributeStyle("active", "backgroundColor", presentation)
        ],
      color: theme.color[getAttributeStyle("active", "color", presentation)],
      boxShadow: "none",
      textDecoration:
        getAttributeStyle("active", "textDecoration", presentation) || "none",
      borderColor:
        theme.color[
          getAttributeStyle("active", "borderColor", presentation) ||
            "transparent"
        ],
    },
    ":focus": {
      boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
      outline: "none",
    },
    ":disabled": {
      backgroundColor:
        theme.color[
          getAttributeStyle("disabled", "backgroundColor", presentation)
        ] ?? theme.color.buttonBgDisabled,
      color:
        theme.color[getAttributeStyle("disabled", "color", presentation)] ??
        theme.color.textFieldDisabled,
      cursor: "not-allowed",
      opacity: 0.6,
      boxShadow: "none",
    },
  };
}
