import { PORTAL_TYPE } from "./constants";

export * from "./constants";
export { default, setFocusOn, resetFocus } from "./modal";
export type { Action, ActionType } from "./types";
export { Wrapper } from "./components/styled";

/**
 * This is a quick check to determine if there is a modal open on the page.
 * This will not tell you if a specific modal is open, so when using make sure
 * that's not a requirement for your use case
 */
export function isAnyModalOpen(document: Document) {
  return Boolean(document.querySelector(`[data-hw-portal="${PORTAL_TYPE}"]`));
}
