/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { toggleMark, chainCommands } from "prosemirror-commands";
import { Box } from "hw/ui/blocks";
import { VisuallyHidden } from "hw/ui/text";
import AnimateHeight from "hw/ui/animate-height";
import type { EditorView } from "prosemirror-view";
import type { EditorState, Transaction } from "prosemirror-state";
import type { MarkType, NodeType } from "prosemirror-model";

import {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  UnorderedList,
  OrderedList,
  H1,
  H2,
  Link,
  MergeTag,
} from "hw/ui/icons";
import schema from "../schema";
import * as utils from "../utils";
import { ToolbarSection, ToolbarContainer, ToolbarIconWrapper } from "./styled";
import * as commands from "../commands";
import { insertLinkKeymap } from "../plugins/link";
import type {
  NamedKeymap,
  KeymapName,
  InputRuleName,
  NamedInputRule,
} from "../types";

type Props = {
  isActive?: boolean;
  view: EditorView;
  onCreateLink: (...args: Array<any>) => any;
  onInsertMergeField: (...args: Array<any>) => any;
  canLink: (...args: Array<any>) => any;
  keymaps: Record<KeymapName, NamedKeymap>;
  inputRules: Record<InputRuleName, NamedInputRule>;
  v2Options?: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    extendToolbar?: {};
    shouldAnimateToolbar?: boolean;
    extraToolbarContent?: React.ReactNode;
  };
  isScrolled: boolean;
};

// TODO: We don't really need this. I was fighting with flow on this and gave up
const emptyAttrs = {};

/**
 * Editor toolbar menu
 */
class Toolbar extends React.Component<Props> {
  render() {
    const {
      view,
      onCreateLink,
      onInsertMergeField,
      canLink,
      keymaps,
      inputRules,
      isActive,
      v2Options = {},
      isScrolled,
    } = this.props;
    const {
      extendToolbar: extend,
      extraToolbarContent: extraContent,
      shouldAnimateToolbar: shouldAnimate = true,
    } = v2Options;

    const toolbar = (
      <ToolbarContainer extend={extend} isScrolled={isScrolled}>
        <Box display="flex" alignItems="center">
          <ToolbarSection>
            <MarkItem
              Icon={Bold}
              view={view}
              type={schema.marks.strong}
              title={titleForKeymap(keymaps.toggleBold)}
            />
            <MarkItem
              Icon={Italic}
              view={view}
              type={schema.marks.em}
              title={titleForKeymap(keymaps.toggleItalic)}
            />
            <MarkItem
              Icon={Underline}
              view={view}
              type={schema.marks.underline}
              title={titleForKeymap(keymaps.toggleUnderline)}
            />
            <MarkItem
              Icon={Strikethrough}
              view={view}
              type={schema.marks.strike}
              title={titleForKeymap(keymaps.toggleStrikethrough)}
            />
          </ToolbarSection>
          <ToolbarSection>
            <BlockItem
              type={schema.nodes.bulletList}
              Icon={UnorderedList}
              view={view}
              attrs={emptyAttrs}
              title={titleForInputRule(inputRules.bulletList)}
            />
            <BlockItem
              type={schema.nodes.orderedList}
              Icon={OrderedList}
              view={view}
              attrs={emptyAttrs}
              title={titleForInputRule(inputRules.orderedList)}
            />
          </ToolbarSection>
          <ToolbarSection>
            <BlockItem
              type={schema.nodes.heading}
              Icon={H1}
              attrs={{ level: 1 }}
              view={view}
              title={titleForKeymap(keymaps.toggleHeading1)}
            />
            <BlockItem
              type={schema.nodes.heading}
              Icon={H2}
              attrs={{ level: 2 }}
              view={view}
              title={titleForKeymap(keymaps.toggleHeading2)}
            />
          </ToolbarSection>
          <ToolbarSection last={true}>
            <MenuItem
              isActive={() => false}
              onClick={onCreateLink}
              isEnabled={canLink}
              Icon={Link}
              view={view}
              title={titleForKeymap(insertLinkKeymap)}
            />
            {/* TODO: Figure out a better way to pass this from the plugin instead of hardcoding */}
            <MenuItem
              isActive={() => false}
              onClick={onInsertMergeField}
              isEnabled={() => true}
              Icon={MergeTag}
              view={view}
              title="Insert Merge Field {"
            />
          </ToolbarSection>
        </Box>
        {extraContent}
      </ToolbarContainer>
    );

    if (shouldAnimate) {
      return (
        <AnimateHeight active={isActive}>
          {({ captureRef }) =>
            React.cloneElement(toolbar, { innerRef: captureRef })
          }
        </AnimateHeight>
      );
    } else {
      return toolbar;
    }
  }
}

/**
 * Block Menu Item
 *
 * Renders a menu item for block type nodes
 */
type BlockItemProps = {
  type: NodeType;
  // eslint-disable-next-line @typescript-eslint/ban-types
  attrs: {};
  view: EditorView;
  Icon: React.ComponentType<any>;
  title: string;
};

class BlockItem extends React.Component<BlockItemProps> {
  isActive = (state: any) =>
    utils.isBlockActive(state, this.props.type, this.props.attrs);

  isEnabled = (state: any) =>
    isBlockEnabled(state, this.props.type, this.props.attrs);

  onClick = chainCommands(
    commands.toggleHeading(this.props.type, this.props.attrs),
    commands.toggleList(this.props.type)
  );

  render() {
    const { Icon, view, title } = this.props;

    return (
      <MenuItem
        isActive={this.isActive}
        isEnabled={this.isEnabled}
        onClick={this.onClick}
        Icon={Icon}
        view={view}
        title={title}
      />
    );
  }
}

/**
 * Mark Menu Item
 *
 * Renders a menu item for mark nodes
 */
type MarkItemProps = {
  type: MarkType;
  view: EditorView;
  Icon: React.ComponentType<any>;
  title: string;
};

class MarkItem extends React.Component<MarkItemProps> {
  isActive = (state: any) => utils.isMarkActive(state, this.props.type);

  onClick = toggleMark(this.props.type);

  render() {
    const { view, Icon, title } = this.props;

    return (
      <MenuItem
        Icon={Icon}
        view={view}
        // @ts-expect-error refactor
        isActive={this.isActive}
        isEnabled={() => true}
        onClick={this.onClick}
        title={title}
      />
    );
  }
}

/**
 * Menu Item
 *
 * Renders a generic menu item
 */
type MenuItemProps = {
  Icon: React.ComponentType<any>;
  isEnabled: (state: EditorState) => boolean;
  isActive: (state: EditorState) => boolean;
  view: EditorView;
  title: string;
  onClick: (
    state: EditorState,
    dispatch: (tr: Transaction) => void,
    view: EditorView
  ) => void;
};

class MenuItem extends React.Component<MenuItemProps> {
  // @ts-expect-error refactor
  onClick = (evt) => {
    evt.preventDefault();
    const { view } = this.props;

    this.props.onClick(view.state, view.dispatch, view);

    view.focus();
  };

  render() {
    const { Icon, isActive, isEnabled, view, title } = this.props;
    const active = Boolean(isActive(view.state));

    return (
      <ToolbarIconWrapper
        selected={active}
        disabled={!isEnabled(view.state)}
        onClick={this.onClick}
        aria-pressed={active}
        title={title}
      >
        <Icon />
        <VisuallyHidden>{title}</VisuallyHidden>
      </ToolbarIconWrapper>
    );
  }
}

// Commands are expected to handle being called with just the `state` value and
// return a boolean if they are applicable or not.  We can use this signature to
// determine whether the list or heading icons should be enabled or not
function isBlockEnabled(state: any, nodeType: any, attrs: any) {
  return (
    commands.toggleHeading(nodeType, attrs)(state) ||
    commands.toggleList(nodeType)(state)
  );
}

function titleForKeymap(keymap: NamedKeymap) {
  return `${keymap.description} ${keymap.shortcut}`;
}

function titleForInputRule(inputRule: NamedInputRule) {
  return `${inputRule.description} ${inputRule.shortcut}`;
}

export default Toolbar;
