import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "hw/ui/blocks";
import PageHeader from "hw/portal/modules/common/components/page-header";
import QuotaMessage from "hw/portal/modules/common/components/quota-message";

type Props = {
  children: React.ReactNode;
};

export default function Page(props: Props) {
  const { children } = props;

  return (
    <>
      <QuotaMessage />
      <Box py="sm" px="ms">
        <PageHeader>
          <FormattedMessage
            id="LaunchWorkflow.title"
            defaultMessage="Send Form(s)"
          />
        </PageHeader>
        {children}
      </Box>
    </>
  );
}
