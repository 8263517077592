import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  /**
   * The content of the menu item
   */
  children: React.ReactNode;

  /**
   * Function or object to extend the style of the MenuItemText
   */
  extend?: Extend;
};

/**
 * A wrapper component for menu item text.
 */
export function MenuItemText(props: Props) {
  const { children, extend = {} } = props;

  return (
    <div className={useStyle("ui-menu-item-text", menuItemTextStyle, extend)}>
      {children}
    </div>
  );
}

const menuItemTextStyle = {
  fontSize: theme.fontSize.ms,
  paddingLeft: theme.space.xs,
  paddingRight: theme.space.xs,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default MenuItemText;
