import * as React from "react";
import { createComponentWithProxy, Extend } from "hw/ui/style";
import style from "./style";

/**
 * Here I'm defining the props that are not native to buttons and the ones we use
 * for styling purposes.
 */
export type Props = {
  /**
   * Presentation for the apperance of the button. Each one is tied up to a specific
   * style and use
   */
  presentation?:
    | "primary"
    | "standard"
    | "subtle"
    | "link"
    | "danger"
    | "primaryOutline";

  /**
   * If `true`, the button will be set to 100% width
   */
  fitToContainer?: boolean;

  /**
   * Size of the button
   */
  size?: "small" | "base" | "large";

  /**
   * @ignore Identifier for guide attribute
   */
  "data-guide-id"?: string;

  /**
   * @ignore Identifier for testing
   */
  "data-testid"?: string;

  /**
   * Component to be rendered with the button style
   */
  component?: React.ReactNode;

  extend?: Extend;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * createComponentWithProxy is going to pass all the props passed to the button
 * that are not being used in the rules.
 * If there is a prop used in the style and it also needs to be passed down to
 * the component, the passthroughProp is still available:
 *
 * i.e.: if we were using disabled to style the button
 *
 * createComponentWithProxy(style, "button", ["disabled"])
 */
const StyledButton = createComponentWithProxy(style);

StyledButton.displayName = "Button";

/**
 * Styled wrapper around the base `button` HTML element. Should be used for
 * any clickable actions in the app.
 */
export const Button = React.forwardRef<HTMLButtonElement, Props>(
  function Button(props: Props, ref) {
    const {
      component = "button",
      presentation = "primary",
      size = "base",
      ...rest
    } = props;

    return (
      <StyledButton
        {...rest}
        // @ts-expect-error refactor
        as={component}
        presentation={presentation}
        // @ts-expect-error refactor
        size={size}
        // @ts-expect-error refactor
        innerRef={ref}
      />
    );
  }
);

Button.displayName = "Button";
