import * as React from "react";
import { createComponent } from "hw/ui/style";
import { TextTooltip } from "hw/ui/tooltip";
import { InputLabel } from "hw/ui/input";
import { Box } from "hw/ui/blocks";

const Wrapper = createComponent(function SettingsLabelWrapper({
  theme,
  spacing,
}) {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: spacing ? theme.space.ms : 0,
  };
});
type Props = {
  /**
   * The content of the label
   */
  children: React.ReactNode;

  /**
   * Id of the input control for the label
   */
  htmlFor?: string;

  /**
   * An optional tooltip to display with the label
   */
  tooltip?: string;

  /**
   * If true, margin bottom will be applied
   */
  spacing?: boolean;
  ariaLabel?: string;
};
export function SettingsLabel({
  children,
  tooltip,
  htmlFor,
  spacing = true,
  ariaLabel,
}: Props) {
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: (... Remove this comment to see the full error message
    <Wrapper spacing={spacing}>
      <Box mr="sm">
        <InputLabel htmlFor={htmlFor}>{children}</InputLabel>
      </Box>
      {tooltip && <TextTooltip text={tooltip} ariaLabel={ariaLabel} />}
    </Wrapper>
  );
}
export default SettingsLabel;
