import monolithic from "fela-monolithic";
import prefixer from "fela-plugin-prefixer";
import fallbackValue from "fela-plugin-fallback-value";
import { createRenderer as createFelaRenderer } from "fela";

type Options = {
  dev?: boolean;
};

const isDev = process.env.NODE_ENV === "development";
const isTest = process.env.NODE_ENV === "test";

/**
 * Creates a style renderer with the given options.
 *
 * @options.dev {Boolean} - If `true`, the monolithic renderer will be used.
 * This will render a separate classname for each style rule and allow for
 * easier debugging.  This is used by default in development, but not in
 * production.
 */
export default function createRenderer(options: Options = {}) {
  const rendererOptions = {
    enhancers: [],
    plugins: [prefixer(), fallbackValue()],
    devMode: isDev,
  };

  const opts = {
    dev: isDev || isTest,
    ...options,
  };

  if (opts.dev) {
    rendererOptions.enhancers.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      monolithic({
        prettySelectors: true,
      })
    );
  }

  return createFelaRenderer(rendererOptions);
}
