import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

/**
 * This is an opinionated relate date format that formats a date as relative
 * ('yesterday', '30 minutes', etc.) only if it's a recent date.  Otherwise,
 * we display the full date
 */
export function fromNowOrFormatted(date: string | number | Date) {
  const today = dayjs();
  const target = dayjs(date);
  const diff = target.diff(today, "day");

  if (Math.abs(diff) < 2) {
    return target.fromNow();
  } else {
    // Formats as long-hand localized format
    return target.format("LL");
  }
}

/**
 * isLeapYear based on the criteria mentioned here:
 * https://www.timeanddate.com/date/leapyear.html
 */
export function isLeapYear(year: number): boolean {
  if (year % 100 === 0) {
    return year % 400 === 0;
  }

  return year % 4 === 0;
}

/**
 * Returns number of days in the month for any given month and year.
 * Months: 1 - 12
 */
export function numberOfDaysInMonth(month: number, year: number) {
  // February
  if (month === 2) {
    return isLeapYear(year) ? 29 : 28;
  }

  // April, June, September, November
  if ([4, 6, 9, 11].includes(month)) {
    return 30;
  }

  // The rest
  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    return 31;
  }

  return 0;
}

/**
 * Validates date string values from which to create a valid Javsacript Date. Expects 4-digit year input.
 * Years under 100 may do unexpected things, e.g. Chrome year input "0049" -> 2049, but "0050" -> 1950
 * so not allowing those.
 */
export function isAllowableDate(month: string, day: string, year: string) {
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(year, 10);
  const dayInt = parseInt(day, 10);
  const numberOfDays = numberOfDaysInMonth(monthInt, yearInt);

  if (numberOfDays) {
    const isAllowableDate =
      yearInt >= 100 &&
      monthInt >= 1 &&
      monthInt <= 12 &&
      dayInt >= 1 &&
      dayInt <= numberOfDays;

    return isAllowableDate;
  }

  return false;
}

/**
 * Parses a string to make sure it adheres to some date pattern,
 * checks to make sure it'll be an allowable/valid date,
 * then returns a Date object based on that string.
 */
export function parseDateStrict(
  value: string,
  pattern: string,
  endOfDay = false
) {
  const patterns: { [key: string]: RegExp } = {
    "YYYY-MM-DD": /^([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})$/,
    // eslint-disable-next-line
    "YYYY/MM/DD": /^([0-9]{4})[\/]([0-9]{2})[\/]([0-9]{2})$/,
  };

  const re = patterns[pattern];
  const match = re && re.exec(value);

  if (match) {
    const [, year, month, day] = match;

    // @ts-expect-error refactor
    if (isAllowableDate(month, day, year)) {
      return new Date(
        `${month}/${day}/${year} ${endOfDay ? "23:59:59" : "00:00:00"}`
      );
    }
  }
}

export function formatDate(date: string, pattern: string) {
  return dayjs(date).format(pattern);
}
