import * as React from "react";

type Props = {
  size?: number;
};
export default function ArrowRight(props: Props) {
  const { size = 12 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 12 12"
    >
      <defs>
        <path
          id="arrow-right-12-a"
          d="M3,6.5 C2.72385763,6.5 2.5,6.27614237 2.5,6 C2.5,5.72385763 2.72385763,5.5 3,5.5 L9.5,5.5 C9.77614237,5.5 10,5.72385763 10,6 C10,6.27614237 9.77614237,6.5 9.5,6.5 L3,6.5 Z M5.67460431,3.3796283 C5.46494139,3.19991723 5.44066062,2.88426723 5.6203717,2.67460431 C5.80008277,2.46494139 6.11573277,2.44066062 6.32539569,2.6203717 L9.82539569,5.6203717 C10.0582014,5.81991949 10.0582014,6.18008051 9.82539569,6.3796283 L6.32539569,9.3796283 C6.11573277,9.55933938 5.80008277,9.53505861 5.6203717,9.32539569 C5.44066062,9.11573277 5.46494139,8.80008277 5.67460431,8.6203717 L8.73170463,6 L5.67460431,3.3796283 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width={size} height={size} />
        <use fill="#959CA4" fillRule="nonzero" xlinkHref="#arrow-right-12-a" />
      </g>
    </svg>
  );
}
