import * as React from "react";
import { omit } from "timm";
import { Flex } from "hw/ui/blocks";
import type { SettingsProps } from "hw/portal/modules/draft-editor/form-builder/types";
import { Actions } from "../../../../state";
import Wrapper from "./wrapper";
import RemoveButton from "./remove-button";
import AddButton from "./add-button";
import { genId } from "../../utils";

type Props = SettingsProps & {
  text?: string;
  schemaPropName: string;
  name: string;
  placeHolder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputComponent: React.ComponentType<any>;
};

/**
 * A generic toggle for adding text to a components configuration.
 */
export function TextToggle(props: Props) {
  const {
    schemaPropName,
    editorDispatch,
    path,
    name,
    text,
    placeHolder,
    InputComponent,
  } = props;
  const [open, setOpen] = React.useState(text !== undefined);
  const [hovered, setHovered] = React.useState(false);
  const [btnFocused, setBtnFocused] = React.useState(false);
  const handleDelete = React.useCallback(() => {
    editorDispatch(
      Actions.ChangeFieldSetting({
        path,
        updater: (field) => omit(field, [schemaPropName]),
      })
    );
    setOpen(false);
  }, [editorDispatch, path, schemaPropName]);
  const handleChangeText = React.useCallback(
    (e) => {
      const { value } = e.target;
      editorDispatch(
        Actions.ChangeFieldSetting({
          path: [...path, schemaPropName],
          updater: () => value,
        })
      );
    },
    [editorDispatch, path, schemaPropName]
  );
  const { current: id } = React.useRef(genId());
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; label: string; py: stri... Remove this comment to see the full error message
    <Wrapper label={name} py={open ? "sm" : "ms"} id={id}>
      {open ? (
        <Flex
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; justifySelf: string; ... Remove this comment to see the full error message
          justifySelf="end"
          width={1}
          position="relative"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onBlur={() => setBtnFocused(false)}
        >
          <InputComponent
            value={text ?? ""}
            onChange={handleChangeText}
            placeholder={placeHolder}
            id={id}
          />

          <RemoveButton
            onClick={handleDelete}
            onFocus={() => setBtnFocused(true)}
            onBlur={() => setBtnFocused(false)}
            visible={btnFocused || hovered}
          />
        </Flex>
      ) : (
        <AddButton onClick={() => setOpen(true)} />
      )}
    </Wrapper>
  );
}
export default TextToggle;
