/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { createComponent, Extend, useStyle } from "hw/ui/style";
import theme, { variantOn } from "hw/ui/theme";

export { Text, Heading };

/**
 * Text component
 *
 * This is a base level text component meant to wrap any raw text in the app.
 */

export type TextProps = {
  variant?: "body1" | "body2";
  as?: string | React.ComponentType<any>;
  children: React.ReactNode;
  extend?: Extend;

  // NOTE!: Using `truncate` within a flex container requires that the container
  // have a `min-width` value set, e.g. `min-width: 0`
  overflow?: "default" | "wrap" | "truncate";
} & React.HTMLAttributes<HTMLElement>;

function Text(props: TextProps) {
  const { as = "p", variant, ...rest } = props;

  return (
    <BaseText
      {...rest}
      as={as}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ fontSize: ".875rem"; fontWeight: 400; colo... Remove this comment to see the full error message
      variantStyles={textVariant({
        variant,
      })}
    />
  );
}

const textVariant = variantOn("variant", {
  body1: {
    fontSize: theme.fontSize.ms,
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "20px", // TODO: having lineHeight in the theme
  },
  body2: {
    fontSize: theme.fontSize.md,
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "24px", // TODO: having lineHeight in the theme
  },
});

/**
 * Heading component
 *
 * This is a base level heading component meant to wrap any raw headings in
 * the app.
 *
 *   <Heading level="1">Employment Application</Heading>
 */

type HeadingProps = {
  level?: "1" | "2" | "3" | "4" | "5" | "6" | 1 | 2 | 3 | 4 | 5 | 6;
  as?: string | React.ComponentType<any>;
  children: React.ReactNode;
  extend?: Extend;

  // NOTE!: Using `truncate` within a flex container requires that the container
  // have a `min-width` value set, e.g. `min-width: 0`
  overflow?: "default" | "wrap" | "truncate";
} & React.HTMLAttributes<HTMLHeadingElement>;

function Heading(props: HeadingProps) {
  const { level = "2", as = `h${level}`, ...rest } = props;

  return (
    <BaseText
      {...rest}
      as={as}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ fontSize: "1.875rem"; fontWeight: 400; col... Remove this comment to see the full error message
      variantStyles={headingVariant({
        level,
      })}
    />
  );
}

const baseHeadingStyles = {
  marginTop: 0,
  marginBottom: 0,
};

const headingVariant = variantOn("level", {
  "1": {
    ...baseHeadingStyles,
    fontSize: theme.fontSize.xxl, // 30px
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "2.5rem", // 40px
  },
  "2": {
    ...baseHeadingStyles,
    fontSize: theme.fontSize.xl, // 24px
    fontWeight: theme.fontWeight.normal,
    color: theme.color.textStandard,
    lineHeight: "2rem", // 32px
  },
  "3": {
    ...baseHeadingStyles,
    fontSize: theme.fontSize.lg, // 20px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1.75rem", // 28px
  },
  "4": {
    ...baseHeadingStyles,
    fontSize: theme.fontSize.md, // 16px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1.5rem", // 24px
  },
  "5": {
    ...baseHeadingStyles,
    fontSize: theme.fontSize.ms, // 14px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1.25rem", // 20px
  },
  "6": {
    ...baseHeadingStyles,
    fontSize: theme.fontSize.sm, // 12px
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
    lineHeight: "1rem", // 16px
  },
});

type BaseTextProps = {
  as?: string | React.ComponentType<any>;
  children: React.ReactNode;
  extend?: Extend;
  // NOTE!: Using `truncate` within a flex container requires that the container
  // have a `min-width` value set, e.g. `min-width: 0`
  overflow?: "default" | "wrap" | "truncate";
};

function BaseText(
  props: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    variantStyles: {};
  } & BaseTextProps &
    React.HTMLAttributes<HTMLElement>
) {
  const {
    as = "p",

    /**
     * Going with 'wrap' as the default as I believe this should cover most of
     * our use cases
     */
    overflow = "wrap",
    variantStyles = {},
    extend,
    children,
    ...rest
  } = props;
  const El = as;
  const cn = useStyle(
    "text",
    variantStyles,
    {
      overflowWrap: overflow === "wrap" && "break-word",
      whiteSpace: overflow === "truncate" && "nowrap",
      overflow: overflow === "truncate" && "hidden",
      textOverflow: overflow === "truncate" && "ellipsis",
    },
    extend
  );

  return (
    <El {...rest} className={cn}>
      {children}
    </El>
  );
}

// NOTE: This component is deprecated. Please use the `Text` component above
export const TruncatedText = createComponent(function TruncatedText() {
  return {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
});

// NOTE: This component is deprecated. Please use the `Text` component above
export const TruncatedTextParent = createComponent(
  function TruncatedTextParent() {
    return {
      minWidth: 0,
    };
  }
);

/**
 * This is the same as TruncatedTextParent. I'm keeping it to be more explicit
 * for now, but this could be a more general TextParent component
 */
// NOTE: This component is deprecated. Please use the `Text` component above
export const WordBreakTextParent = createComponent(
  function WordBreakTextParent() {
    return {
      minWidth: 0,
    };
  }
);
// NOTE: This component is deprecated. Please use the `Text` component above
export const WordBreakText = createComponent(function WordBreakText() {
  return {
    overflowWrap: "break-word",
    minWidth: 0,
  };
});
