import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import Label from "./menu-item-group-label";

type Props = {
  /**
   * The content of the group.  This should be a collection of `MenuItem`
   * components
   */
  children: React.ReactNode;

  /**
   * The label for the group. If the label is a react element,
   * we render it as it is. Otherwise, we wrap it around a paragraph component.
   */
  label?: string | React.ReactNode;

  /**
   * Used to indicate if this is the last group of a series of groups.
   * If `true`, the bottom will not be rendered.
   */
  last?: boolean;

  /**
   * Used to indicate if the group is disabled or not
   */
  disabled?: boolean;

  extendLabel?: Extend;

  extend?: Extend;
};

/**
 * A component for styling a group of `MenuItem` components.
 */
function MenuItemGroup(props: Props) {
  const { children, label, disabled, extendLabel, extend = {} } = props;

  const cn = useStyle("ui-menu-item-group", style(props), extend);

  return (
    <div className={cn}>
      {label &&
        (React.isValidElement(label) ? (
          label
        ) : (
          <Label label={label} disabled={disabled} extend={extendLabel} />
        ))}
      {!disabled && children}
    </div>
  );
}

const style = ({ last, disabled }: Props) => ({
  borderBottomColor: theme.color.dividerDim,
  borderBottomStyle: "solid",
  borderBottomWidth: last ? 0 : "1px",
  marginBottom: theme.space.xs,
  cursor: disabled ? "not-allowed" : "pointer",
});

export default MenuItemGroup;
