import * as React from "react";
import { merge } from "timm";
import { Dropdown } from "hw/ui/dropdown";
import type { RenderProps } from "hw/ui/dropdown/types";
import type { Path } from "hw/common/types";
import * as heap from "hw/portal/modules/analytics/heap";
import ComponentMenu from "./grid-menu";
import SmallNewComponentButton from "./add-button-small";
import NewComponentButton from "./add-button";
import type { Option } from "./types";
import "./types";
// TYPES
export type Props = {
  path: Path;

  /**
   * Flag to determine if the menu is nested in a group component
   */
  isNested?: boolean;

  /**
   * List of attrs that need to be passed when creating a nested component
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  nestedAttrs?: {};

  /**
   * If this flag is true, the trigger will be a button instead of just an icon
   */
  fillContainer?: boolean;

  /**
   * Called with the type and optional attributes for creating the new field.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: (path: Path, type: string, attrs?: {}) => void;

  /**
   * If this flag is true, use the larger "Add Form Component" button, else use + icon button
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Trigger?: React.ComponentType<any>;

  /**
   * If this flag is true, open the menu as default
   */
  initialIsOpen?: boolean;

  /**
   * An option props object to be provided to the `Trigger` component.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  triggerProps?: {};
};
/**
 * Renders the menu for creating new fields
 */

export default class NewComponentDropdown extends React.PureComponent<Props> {
  static defaultProps = {
    isNested: false,
    nestedAttrs: {},
    fillContainer: false,
  };

  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
  }

  handleChange(option: Option) {
    if (!option) return;
    const { path, onSelect, nestedAttrs } = this.props;
    heap.track(heap.EVENTS.editor.componentMenuItemClicked, {
      "Component Type": option.value,
    });
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return onSelect(path, option.value, merge(option.attrs || {}, nestedAttrs));
  }

  renderMenu({ getItemProps, highlightedIndex }: RenderProps) {
    const { isNested } = this.props;
    return (
      <ComponentMenu
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
        isNested={isNested}
      />
    );
  }

  render() {
    return (
      /* $FlowFixMe[incompatible-type] $FlowFixMe This comment suppresses an
       * error found when upgrading Flow to v0.132.0. To view the error, delete
       * this comment and run Flow. */
      <Dropdown
        {...this.props}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onSelect: (option: Option) => void; render... Remove this comment to see the full error message
        onSelect={this.handleChange}
        render={this.renderMenu}
        renderDest="portal"
        fitToContainer={false}
      />
    );
  }
}
export function Large(props: Props) {
  return (
    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    <NewComponentDropdown
      Trigger={NewComponentButton}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: Path; isNested?: boolean | undefined... Remove this comment to see the full error message
      position="bottom"
      fillContainer={true}
      {...props}
    />
  );
}
export function Small(props: Props) {
  return (
    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    <NewComponentDropdown
      Trigger={SmallNewComponentButton}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: Path; isNested?: boolean | undefined... Remove this comment to see the full error message
      position="right"
      {...props}
    />
  );
}
