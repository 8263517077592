import { useRef, useEffect } from "react";

type Ref = React.MutableRefObject<HTMLElement | null>;

type Props = {
  ref: Ref;
  onOutsideClick: (evt: Event) => void;
};

export function useOutsideClick(props: Props) {
  const { ref, onOutsideClick } = props;
  const stateRef = useRef({
    isPointerDown: false,
  });

  const state = stateRef.current;

  useEffect(() => {
    const onMouseDown = (evt: MouseEvent) => {
      if (isValidEvent(evt, ref)) {
        state.isPointerDown = true;
      }
    };

    const onMouseUp = (evt: MouseEvent) => {
      if (state.isPointerDown && onOutsideClick && isValidEvent(evt, ref)) {
        state.isPointerDown = false;
        onOutsideClick(evt);
      }
    };

    document.addEventListener("mousedown", onMouseDown, true);
    document.addEventListener("mouseup", onMouseUp, true);

    return () => {
      document.removeEventListener("mousedown", onMouseDown, true);
      document.removeEventListener("mouseup", onMouseUp, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOutsideClick, ref, state.isPointerDown]);
}

function isValidEvent(evt: MouseEvent, ref: Ref) {
  const { target } = evt;

  // Not a left click
  if (evt.button > 0) return false;

  if (!(target instanceof Node)) return false;
  if (!target) return false;

  // if the event target is no longer in the document
  if (target) {
    const ownerDocument = target?.ownerDocument;
    if (!ownerDocument || !ownerDocument.documentElement?.contains(target)) {
      return false;
    }
  }

  return !ref.current?.contains(target);
}
