import "hw/ui/tokens";
// TODO: Investigate a better polyfill solution for end-user experiences
// Including the entire babel polyfill here is likely way overkill and adding to
// page bloat so I'm only including what I saw was needed to run on ie11.
// I'm including here because this app should have the same baseline
// support as smartforms, which brings in the entire babel polyfill
import "core-js/es/object/assign";
import "core-js/es/array/find";
import "core-js/es/array/find-index";
import "core-js/es/promise";

import "hw/common/css/base.css";
import * as React from "react";
import * as Sentry from "@sentry/browser";
import initSentry from "hw/common/sentry";
import { createLogger } from "hw/common/utils/logger";
import parseDomNodeDataset from "hw/common/utils/parse-dom-node-dataset";
import { Env } from "hw/common/env";
import ShareableLink from "./container";
import mount from "../common/mount";

Env.initFromDOM();

const logger = createLogger("shareable-link");
const initialState = parseDomNodeDataset("__initial-state__", "data");

logger.debug("initial state: ", initialState);

setupSentry();
mount(render);

function setupSentry() {
  initSentry();

  const extraContext = {
    "link:guid": initialState.guid,
  };

  Sentry.setExtras(extraContext);
}

function render() {
  const { guid, roles, name, token, privacyPolicyUrl, teamName, mergeFields } =
    initialState;

  return (
    <ShareableLink
      guid={guid}
      roles={roles}
      name={name}
      token={token}
      privacyPolicyUrl={privacyPolicyUrl}
      teamName={teamName}
      mergeFields={mergeFields}
    />
  );
}
