import * as React from "react";
import startCase from "lodash/startCase";
import { getIn } from "timm";
import { Flex } from "hw/ui/blocks";
import type { Path } from "hw/common/types";
import type {
  Field,
  Form,
  ParsedSchema,
  Role as RoleType,
  MappedField,
} from "hw/portal/modules/common/draft";
import ConditionIcon from "hw/ui/icons/svg-icons/condition-icon-16";
import PrefillIcon from "hw/ui/icons/svg-icons/prefill-icon";
import CalculationIcon from "hw/ui/icons/svg-icons/calculation-icon";
import * as heap from "hw/portal/modules/analytics/heap";
import MultipleChoice from "./multiple-choice";
import Paragraph from "./paragraph";
import TextInput from "./text-input";
import Signature from "./signature";
import PhoneNumber from "./phone-number";
import AddressGroup from "./address-group";
import FileAttachment from "./file-attachment";
import Ssn from "./ssn";
import Sin from "./sin";
import Ein from "./ein";
import Group from "./group";
import DateInput from "./date-input";
import Multiline from "./multi-line";
import Calculation from "./calculation";
import TitleBar from "./common/title";
import { Role, Reference } from "./common";
import FlexOverflowParent from "../../../../common/components/flex-overflow-parent";
import * as Styled from "../styled";
import { getFormMappings, getPrefill } from "../../../utils";

type Props = {
  field: Field;
  onDelete: (path: Path) => void;
  onDuplicate: (path: Path) => void;
  form: Form;
  path: Path;
  parsedSchema: ParsedSchema;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openRoleModal: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openDeleteFieldModal: (...args: Array<any>) => any;
  deleteMapping: (field: Field) => void;
  roleDescriptions: Record<
    RoleType["id"],
    {
      title: string;
      badge: string;
    }
  >;
  setMapping: (mappedField: MappedField, field: Field) => void;
  setFieldRole: (path: Path, roleId: string) => void;
  referenceConditionalCopy: string | null | undefined;
  referencePrefillCopy: string | null | undefined;
  referenceCalculationCopy: string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreateMergeField: (...args: Array<any>) => any;
  isPaid: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  insertFieldAt: (field: any, path: Path) => void;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
function canAssignField(field, form) {
  return field.type !== "Paragraph" && !isChildComponent(field, form);
}

// It looks through the form to check if the field id is in the toplevel components.
// It it is not, it's a child component
function isChildComponent(field: Field, form: Form) {
  return !form.fields.find((formField) => formField.id === field.id);
}

export default class FieldSettings extends React.Component<Props> {
  handleDelete = () => {
    const {
      path,
      onDelete,
      openDeleteFieldModal,
      referenceConditionalCopy,
      referencePrefillCopy,
      field,
    } = this.props;

    if (referenceConditionalCopy || referencePrefillCopy) {
      openDeleteFieldModal();
    } else {
      heap.track(heap.EVENTS.editor.deleteComponentBtnClicked, {
        "Component Type": field.type,
      });
      return onDelete(path);
    }
  };

  handleDuplicate = () => {
    const { path, onDuplicate, field } = this.props;
    heap.track(heap.EVENTS.editor.duplicateComponentBtnClicked, {
      "Component Type": field.type,
    });
    onDuplicate(path);
  };

  render() {
    const {
      field,
      path,
      form,
      parsedSchema,
      openMergeFieldsModal,
      openRoleModal,
      setMapping,
      setFieldRole,
      roleDescriptions,
      referenceConditionalCopy,
      referencePrefillCopy,
      referenceCalculationCopy,
      deleteMapping,
      editorDispatch,
      onCreateMergeField,
      isPaid,
      insertFieldAt,
    } = this.props;
    const defaultSettings = [];

    if (canAssignField(field, form)) {
      const roleProps = {
        key: "role",
        assigned: getIn(field, ["roles", 0]),
        roles: parsedSchema.roles,
        setFieldRole,
        path,
        openRoleModal,
        roleDescriptions,
        editorDispatch,
      };
      // @ts-expect-error refactor
      defaultSettings.push(<Role {...roleProps} />);
    }

    let referenceSetting;

    if (
      referenceConditionalCopy ||
      referencePrefillCopy ||
      referenceCalculationCopy
    ) {
      referenceSetting = (
        <>
          {referenceConditionalCopy && (
            <Reference
              copy={referenceConditionalCopy}
              Icon={<ConditionIcon />}
            />
          )}
          {referencePrefillCopy && (
            <Reference copy={referencePrefillCopy} Icon={<PrefillIcon />} />
          )}
          {referenceCalculationCopy && (
            <Reference
              copy={referenceCalculationCopy}
              Icon={<CalculationIcon />}
            />
          )}
        </>
      );
    }

    const formMappings = getFormMappings(field, form.id, parsedSchema.forms);
    const activePrefill = getPrefill(
      parsedSchema.mergeFields,
      formMappings,
      parsedSchema.mapping,
      form.id,
      field
    );
    const component = renderComponent(field, {
      defaultSettings,
      path,
      mergeFields: parsedSchema.mergeFields,
      mapping: parsedSchema.mapping,
      roles: parsedSchema.roles,
      formId: form.id,
      openMergeFieldsModal,
      setMapping,
      deleteMapping,
      openRoleModal,
      referenceSetting,
      formMappings,
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      activePrefill: getIn(activePrefill, ["value"]),
      editorDispatch,
      onCreateMergeField,
      isPaid,
      // Passing the list of fields so we get the information for calculation sources
      fields: form.fields,
      referenceCalculationCopy,
      insertFieldAt,
    });
    return (
      <FlexOverflowParent
        extend={Styled.SettingsContainer}
        className="ignore-react-onclickoutside"
        data-testid="settings-panel"
      >
        <Flex
          py="sm"
          justifyContent="space-between"
          alignItems="center"
          borderColor="dividerDim"
          borderWidth={1}
          borderBottomStyle="solid"
          flex="none"
        >
          <TitleBar
            title={field.label || typeToString(field.type)}
            onDeleteField={this.handleDelete}
            onDuplicateField={this.handleDuplicate}
          />
        </Flex>
        <Styled.ScrollContainer data-testid="settings-panel-container">
          {component}
        </Styled.ScrollContainer>
      </FlexOverflowParent>
    );
  }
} // Converts the component type to a human-readable string.  Right now it just
// passes to `startCase` but in the future we may want something more custom here

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
function typeToString(type) {
  switch (type) {
    case "Group":
      return "[Condition]";

    default:
      return `[${startCase(type)}]`;
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
function renderComponent(field, props) {
  switch (field.type) {
    case "TextInput":
      return <TextInput field={field} {...props} />;

    case "MultipleChoice":
      return <MultipleChoice field={field} {...props} />;

    case "Paragraph":
      return <Paragraph field={field} {...props} />;

    case "PhoneNumber":
      return <PhoneNumber field={field} {...props} />;

    case "AddressGroup":
      return <AddressGroup field={field} {...props} />;

    case "FileAttachment":
      return <FileAttachment field={field} {...props} />;

    case "Signature":
      return <Signature field={field} {...props} />;

    case "SSN":
      return <Ssn field={field} {...props} />;

    case "SIN":
      return <Sin field={field} {...props} />;

    case "EIN":
      return <Ein field={field} {...props} />;

    case "Group":
      return <Group field={field} {...props} />;

    case "DateInput":
      return <DateInput field={field} {...props} />;

    case "Multiline":
      return <Multiline field={field} {...props} />;

    case "Calculation":
      return <Calculation field={field} {...props} />;

    default:
      return null;
  }
}
