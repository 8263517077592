/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { VisuallyHidden } from "hw/ui/text";
import cx from "classnames";
import { Box, Flex, Text } from "hw/ui/blocks";
/* $FlowFixMe[missing-export] $FlowIgnore - will be removed when we finish the
 * TS migration. */
import type { Role } from "hw/portal/modules/common/draft";
import Button from "hw/ui/button";
import theme from "hw/ui/theme";
import { useStyle } from "hw/ui/style";
import IconButton, { TriangleIcon, MoreVerticalIcon } from "hw/ui/icons";
import Expandable from "hw/ui/expandable";
import AnimateHeight from "hw/ui/animate-height";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import { Dropdown, IconTrigger } from "hw/ui/dropdown";
import styles from "./participants.module.css";
import InlineEdit from "../components/inline-edit";
import { NEW_ROLE_NAME } from "../constants";

const roleColors = [
  { id: 1, base: theme.color.blue500, light: theme.color.blue100 },
  { id: 4, base: "#B800CE", light: "#F9D7FF" },
  { id: 7, base: "#EA9800", light: "#FFF0D7" },
  { id: 8, base: "#A4DE00", light: "#F5FFD7" },
  { id: 3, base: "#4701CE", light: "#E2D7FF" },
  { id: 6, base: "#CF0000", light: "#FFD7D7" },
  { id: 9, base: "#0ACC00", light: "#DBFFD7" },
  { id: 2, base: "#012BCE", light: "#D7E2FF" },
  { id: 5, base: "#CE0074", light: "#FFCEEA" },
  { id: 10, base: "#00E2BA", light: "#D7FFEC" },
];

export function getRoleTheme(roleIndex: number) {
  return roleColors[roleIndex % roleColors.length] || roleColors[0];
}

type ParticipantGroupProps = {
  children: React.ReactNode;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  role: Role;
  mode: "single" | "multi";
  headerOptions: Array<{ text: string; onClick: Function }>;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  updateRoleTitle: (role: Role, newTitle: string) => void;
  roleIndex: number;
};

/**
 * A participant group is the main wrapping component around a list of fields
 * for a participant. If we're in multi-participant mode, we'll show a
 * header and allow the sections to collapse.
 */
export function ParticipantGroup(props: ParticipantGroupProps) {
  const {
    role,
    children,
    mode,
    headerOptions,
    updateRoleTitle,
    roleIndex,
    ...rest
  } = props;

  if (mode === "single") {
    return (
      <ParticipantContainer>
        <ParticipantCanvas grouped={false}>{children}</ParticipantCanvas>
      </ParticipantContainer>
    );
  }

  return (
    <Expandable defaultExpanded={true} id={`participant-header-${role.id}`}>
      {({ getButtonProps, getContentProps, expanded }) => {
        return (
          <ParticipantContainer {...rest}>
            <ParticipantHeader
              getButtonProps={getButtonProps}
              expanded={expanded}
              options={headerOptions}
              // @ts-expect-error refactor
              onChangeTitle={(evt) => updateRoleTitle(role, evt.target.value)}
              role={role}
              roleIndex={roleIndex}
            >
              {role.title}
            </ParticipantHeader>
            {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment
             * suppresses an error found when upgrading Flow to v0.132.0. To
             * view the error, delete this comment and run Flow. */}
            <AnimateHeight active={expanded} {...getContentProps()}>
              {({ captureRef }) => (
                <ParticipantCanvas grouped={true} ref={captureRef}>
                  {children}
                </ParticipantCanvas>
              )}
            </AnimateHeight>
          </ParticipantContainer>
        );
      }}
    </Expandable>
  );
}

type AddParticipantDropdownProps = {
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  roles: Array<Role>;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  onSelect: (item: Role) => void;
  onAddNew: Function;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  isDisabled: (item: Role) => boolean;

  /**
   * If `true`, this will operate as a button and immediately trigger the
   * `onAddNew` callback.
   */
  asCreateButton: boolean;
};

export function AddParticipantDropdown(props: AddParticipantDropdownProps) {
  const { roles, asCreateButton, onSelect, onAddNew, isDisabled } = props;

  if (asCreateButton) {
    return (
      <AddParticipantTrigger
        active={false}
        triggerPassthroughProps={{
          getButtonProps: () => ({
            onClick: onAddNew,
          }),
        }}
      />
    );
  }

  return (
    <Dropdown
      data-testid="add-participant-menu"
      // @ts-expect-error refactor
      onSelect={(item) => {
        if (!item) return;
        if (item === "new") onAddNew();
        else onSelect(item);
      }}
      Trigger={AddParticipantTrigger}
      renderDest="portal"
      justify="left"
      render={({ getItemProps, highlightedIndex }) => (
        <ParticipantDropdownOptions
          roles={roles}
          isDisabled={isDisabled}
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
        />
      )}
    />
  );
}

export function ParticipantDropdownOptions(props: any) {
  const { roles, highlightedIndex, isDisabled, getItemProps } = props;

  return (
    <>
      <MenuList>
        {/* @ts-expect-error refactor */}
        {roles.map((role, index) => (
          <MenuItem
            key={index}
            active={index === highlightedIndex}
            disabled={isDisabled(role)}
            extend={{
              color: isDisabled(role) && theme.color.textFieldDisabled,
              paddingLeft: theme.space.sm,
              paddingRight: theme.space.sm,
            }}
            passthroughProps={getItemProps({
              item: role,
              disabled: isDisabled(role),
            })}
          >
            <ParticipantLabel roleIndex={roles.indexOf(role)}>
              {role.title}
            </ParticipantLabel>
          </MenuItem>
        ))}
        <MenuItem
          active={highlightedIndex === roles.length}
          passthroughProps={getItemProps({ item: "new" })}
          extend={{ paddingLeft: theme.space.sm, paddingRight: theme.space.sm }}
        >
          <MenuItemWithIcon icon={<AddParticipantIcon />}>
            Create a new participant role
          </MenuItemWithIcon>
        </MenuItem>
      </MenuList>
    </>
  );
}

type ParticipantMenuProps = {
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  roles: Array<Role>;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  onSelect: (item: Role) => void;
  onAddNew: Function;
};

/**
 * Menu presented for forms that do not yet have any assigned roles within a
 * multi-participant workflow
 */
export function ParticipantMenu(props: ParticipantMenuProps) {
  const { roles, onSelect, onAddNew } = props;

  return (
    <ParticipantCanvas grouped={false}>
      <Text
        fontSize="sm"
        fontWeight="semibold"
        color="textStandard"
        bg="blue050"
        p="md"
      >
        Who needs to fill this form?
      </Text>
      {roles.map((role) => (
        <ParticipantMenuButton key={role.id} onClick={() => onSelect(role)}>
          <ParticipantLabel roleIndex={roles.indexOf(role)}>
            {role.title}
          </ParticipantLabel>
        </ParticipantMenuButton>
      ))}
      <ParticipantMenuButton onClick={onAddNew}>
        <MenuItemWithIcon icon={<AddParticipantIcon />}>
          Create a new participant role
        </MenuItemWithIcon>
      </ParticipantMenuButton>
    </ParticipantCanvas>
  );
}

const ParticipantCanvas = React.forwardRef<any, any>(function ParticipantCanvas(
  props,
  ref
) {
  const { grouped, ...rest } = props;

  return (
    <div
      {...rest}
      className={cx(styles.canvas, grouped && styles["canvas--grouped"])}
      ref={ref}
    />
  );
});

/**
 * Dropdown menu for adding a new participant to a form
 *
 * @one-off This trigger is different than our usual button trigger. It
 * needs special padding and background colors
 */
function AddParticipantTrigger(props: any) {
  const { triggerPassthroughProps, active } = props;
  const { getButtonProps } = triggerPassthroughProps;

  return (
    <Button
      presentation="standard"
      data-track-id="editor-add_role"
      {...getButtonProps()}
      extend={{
        fontSize: theme.fontSize.sm,
        paddingTop: theme.space.sm,
        paddingBottom: theme.space.sm,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        height: "auto",
        backgroundColor: active ? theme.color.gray200 : "transparent",
        outline: "none",
        ":hover": {
          backgroundColor: theme.color.gray100,
          boxShadow: "none",
        },
        ":focus": {
          outline: active && "none",
        },
      }}
    >
      {/* @ts-expect-error refactor */}
      <Flex mr="2px">
        <AddParticipantIcon />
      </Flex>
      Add another role to this form
    </Button>
  );
}

function ParticipantContainer(props: any) {
  return (
    <Box
      {...props}
      className={styles.container}
      data-testid="participant-field-list"
    />
  );
}

/**
 * The header for a list of fields for a particular form within a
 * multi-participant workflow
 */
function ParticipantHeader(props: any) {
  const { getButtonProps, expanded, onChangeTitle, options, role, roleIndex } =
    props;
  const [hovered, setHovered] = React.useState(false);

  // We have to JS hover/blur events because there are nested hover states within
  // the header
  const onHover = React.useCallback((_evt) => setHovered(true), []);
  const onBlur = React.useCallback(() => setHovered(false), []);

  return (
    <div
      className={cx(
        styles.header,
        expanded && styles["header--expanded"],
        hovered && styles["header--hovered"]
      )}
    >
      <IconButton {...getButtonProps()}>
        <ExpandedIcon expanded={expanded} />
        <VisuallyHidden>Expand Participant Group</VisuallyHidden>
      </IconButton>
      {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */}
      <div
        className={styles["header-label-container"]}
        onMouseOver={onHover}
        onMouseOut={onBlur}
        {...getButtonProps()}
      >
        <ParticipantLabel roleIndex={roleIndex}>
          <InlineEdit
            value={role.title === NEW_ROLE_NAME ? "" : role.title}
            onChange={onChangeTitle}
            onClick={(evt) => evt.stopPropagation()}
            onMouseOver={(evt) => evt.stopPropagation()}
            placeholder="Unnamed Role"
            initialIsEditing={role.title === NEW_ROLE_NAME}
            extendReadView={{
              // @ts-expect-error refactor
              fontWeight: theme.fontWeight.semibold,
              color: theme.color.textStandard,
              minHeight: "none",
              paddingTop: theme.space.sm,
              paddingBottom: theme.space.sm,

              // To account for the padding in the input
              marginLeft: `-${theme.space.xs}`,
            }}
            extendEditView={{
              // @ts-expect-error refactor
              fontWeight: theme.fontWeight.semibold,

              // To account for the padding in the input
              marginLeft: `-${theme.space.xs}`,
            }}
          />
        </ParticipantLabel>
      </div>
      <div className={styles["header-menu-container"]}>
        <Dropdown
          data-testid="participant-menu"
          // @ts-expect-error refactor
          onChange={(item) => item.onClick && item.onClick()}
          Trigger={IconTrigger}
          triggerProps={{ Icon: MoreVerticalIcon }}
          renderDest="portal"
          justify="right"
          render={({ getItemProps, highlightedIndex }) => (
            <MenuList>
              {/* @ts-expect-error refactor */}
              {options.map((item, index) => (
                <MenuItem
                  key={index}
                  active={index === highlightedIndex}
                  passthroughProps={getItemProps({
                    item,
                  })}
                >
                  <MenuItemText>{item.text}</MenuItemText>
                </MenuItem>
              ))}
            </MenuList>
          )}
        />
      </div>
    </div>
  );
}

type MenuItemWithIconProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
};

function MenuItemWithIcon(props: MenuItemWithIconProps) {
  const { icon, children } = props;

  return (
    <>
      <Flex mr="xs">{icon}</Flex>
      <Text fontWeight="semibold" fontSize="sm">
        {children}
      </Text>
    </>
  );
}

/**
 * TODO: There's a few implementations of this. We should consolidate into
 * its own component.
 */
function ExpandedIcon(props: any) {
  const { expanded } = props;

  const iconCn = useStyle("toggle-btn-icon", {
    display: "flex",
    color: theme.color.iconDefault,
    "> svg": {
      transform: expanded ? "rotate(90deg)" : "none",
      transitionProperty: "transform",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      transitionDuration: theme.transitionSpeed.fast,
    },
  });

  return (
    <span className={iconCn}>
      <TriangleIcon />
    </span>
  );
}

/**
 * @one-off This is a group of vertical buttons, so our usual buttons
 * needed some tweaking to fit.
 */
function ParticipantMenuButton(props: any) {
  return (
    <Button
      {...props}
      presentation="standard"
      extend={{
        justifyContent: "flex-start",
        paddingTop: theme.space.sm,
        paddingBottom: theme.space.sm,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        height: "auto",
        backgroundColor: "transparent",
        color: theme.color.textStandard,
        ":hover": {
          color: theme.color.textStandard,
        },
        ":focus": {
          // Have to use inset because the container will cut off the
          // overflow on the normal shadow
          boxShadow: `inset 0 0 0 2px ${theme.color.uiFocus}`,
        },
      }}
    />
  );
}

type ParticipantLabelProps = {
  roleIndex: number;
  children: React.ReactNode;
};

export function ParticipantLabel(props: ParticipantLabelProps) {
  const { roleIndex, children } = props;

  return (
    <MenuItemWithIcon icon={<ParticipantIcon roleIndex={roleIndex} />}>
      {children}
    </MenuItemWithIcon>
  );
}

type ParticipantIconGroupProps = {
  roleIndexes: Array<number>;
};

/**
 * Creates a stacked group of participant icons
 */
export function ParticipantIconGroup(props: ParticipantIconGroupProps) {
  const { roleIndexes } = props;

  return (
    <div
      className={styles["participant-icon-group"]}
      // @ts-expect-error refactor
      style={{ "--count": roleIndexes.length }}
    >
      {roleIndexes.map((idx, pos) => (
        <span
          key={idx}
          className={styles["participant-icon-group-item"]}
          // @ts-expect-error refactor
          style={{ "--position": pos }}
          data-testid={`participant-icon-idx-${idx}`}
        >
          <ParticipantIcon roleIndex={idx} />
        </span>
      ))}
    </div>
  );
}

export function ParticipantIcon({ roleIndex }: { roleIndex: number }) {
  const roleTheme = getRoleTheme(roleIndex);

  return (
    <svg
      // @ts-expect-error refactor
      style={{ "--base": roleTheme.base, "--light": roleTheme.light }}
      className={styles["participant-icon"]}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="8" />
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4008 17.902C15.9776 19.205 14.0817 20 12.0001 20C9.9186 20 8.02274 19.205 6.59961 17.9021L6.9641 16.6264C7.57739 14.4799 9.53932 13 11.7717 13H12.2287C14.4611 13 16.423 14.4799 17.0363 16.6264L17.4008 17.902ZM12.0002 12C13.6571 12 15.0002 10.6569 15.0002 9C15.0002 7.34315 13.6571 6 12.0002 6C10.3434 6 9.00022 7.34315 9.00022 9C9.00022 10.6569 10.3434 12 12.0002 12Z"
      />
    </svg>
  );
}

function AddParticipantIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.524 9C12.524 10.6569 11.1808 12 9.52395 12C7.8671 12 6.52395 10.6569 6.52395 9C6.52395 7.34315 7.8671 6 9.52395 6C11.1808 6 12.524 7.34315 12.524 9ZM8.90912 13C6.86459 13 5.02605 14.2448 4.26673 16.143L4.07251 16.6286C3.80976 17.2855 4.29352 18 5.00099 18H14.0469C14.7544 18 15.2381 17.2855 14.9754 16.6286L14.7812 16.143C14.0219 14.2448 12.1833 13 10.1388 13H8.90912Z"
        fill="#959CA4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8C19 7.44772 18.5523 7 18 7C17.4477 7 17 7.44772 17 8V10H15C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12H17V14C17 14.5523 17.4477 15 18 15C18.5523 15 19 14.5523 19 14V12H21C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10H19V8Z"
        fill="#959CA4"
      />
    </svg>
  );
}
