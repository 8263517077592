/**
 * NOTE: This is shared file with the shareable links launch page
 */
import * as React from "react";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { TextField } from "hw/ui/input";
import { Heading5 } from "hw/ui/text";
import { Stack } from "hw/ui/layout";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import type { ParticipantInput } from "../types";

type Role = { label: string; id: string };

type ParticipantFieldSetsProps = {
  roles: Array<Role>;
  data: {
    [roleId: string]: ParticipantInput;
  };
  onChange: (
    roleId: string,
    property: "fullName" | "value",
    value: string
  ) => void;
};

export function ParticipantFieldSets(props: ParticipantFieldSetsProps) {
  const { roles, data, onChange } = props;

  return (
    <Stack space="md">
      {roles.map((role) => (
        <ParticipantFieldSet
          key={role.id}
          // @ts-expect-error refactor
          participant={data?.[role.id]}
          role={role}
          onChange={onChange}
          withLegend={roles.length > 1}
        />
      ))}
    </Stack>
  );
}

function ParticipantFieldSet(props: {
  participant: ParticipantInput;
  role: Role;
  onChange: ParticipantFieldSetsProps["onChange"];
  withLegend: boolean;
}) {
  const { participant, role, onChange, withLegend } = props;

  const onNameChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    onChange(role.id, "fullName", evt.target.value);
  const onValueChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    onChange(role.id, "value", evt.target.value);

  return (
    <Stack
      space="sm"
      as="fieldset"
      extend={{
        border: "none",
        padding: "0",
        marginLeft: "0",
        marginRight: "0",
      }}
    >
      {withLegend ? (
        // @ts-expect-error refactor
        <Heading5 as="legend" extend={{ padding: "0" }}>
          {role.label}
        </Heading5>
      ) : null}
      <Switcher proportions={[3, 5]} threshold="400px">
        <TextField
          appearance="compact"
          id={`${role.id}-participant-name`}
          label="Name"
          required
          onChange={onNameChange}
          type="text"
          value={participant.fullName}
          pattern="^[\u0001-\uA69F]*$"
          title="Please provide only accepted characters. Emojis and other 4 byte unicode characters are not supported."
          {...getTestAttributes(`participant-${role.label}-name`)}
        />
        <TextField
          appearance="compact"
          id={`${role.id}-participant-email`}
          label="Email"
          onChange={onValueChange}
          type="email"
          value={participant.value}
          required={true}
          pattern="^[\u0001-\uA69F]*$"
          title="Please provide only accepted characters. Emojis and other 4 byte unicode characters are not supported."
          {...getTestAttributes(`participant-${role.label}-email`)}
        />
      </Switcher>
    </Stack>
  );
}

type MergeFieldInputProps = {
  mergeField: {
    label: string;
    dataRef: string;
  };
  value: string;
  onChange: (dataRef: string, value: string) => void;
};

export function MergeFieldInput(props: MergeFieldInputProps) {
  const { mergeField, value, onChange } = props;

  return (
    <TextField
      label={mergeField.label}
      id={mergeField.dataRef}
      type="text"
      value={value}
      onChange={(evt) => onChange(mergeField.dataRef, evt.target.value)}
      pattern="^[\u0001-\uA69F]*$"
      title="Please provide only accepted characters. Emojis and other 4 byte unicode characters are not supported."
      {...getTestAttributes(`merge-field-${mergeField.label}`)}
    />
  );
}

export function Divider() {
  return (
    <div
      className={useStyle("divider", {
        height: "1px",
        backgroundColor: theme.color.dividerDefault,
      })}
    />
  );
}

/**
 * This is a generic 'Switcher' component that renders child elements in a column
 * layout until the parent container reaches the threshold width. This allows
 * for a simple responsive layout that's not hard-coded with media queries.
 */
type SwitcherProps = {
  children: React.ReactNode;

  /**
   *  This is the breakpoint where child elements should render as a row. This is
   *  a `flex-basis` so it effectively is a container-specific media query instead
   *  of a viewport media query, i.e. when the parent element is at least this
   *  wide render children as rows
   */
  threshold?: string;

  /**
   * The `space` is the space _between_ each child element. This is achieved by
   * applying an equal margin of `space / 2` to each child element and a
   * _negative_ margin of `space / 2` to the parent. Applying margins equally to
   * all child elements makes the `calc` functions easier (as opposed to only
   * applying margin to the right of all elements except the last)
   */
  space?: keyof typeof theme.space;

  /**
   * Can be used to manage the proportions of individual child elements. For
   * example, if you want the first child to be two times as large as everything
   * else, you could supply:
   *
   *    <Switcher proportions={[2]} />
   */
  proportions?: Array<number>;
};

function Switcher(props: SwitcherProps) {
  const { threshold = "0rem", space = "md", proportions = [] } = props;

  // Adds specific `flex-grow` properties for individual child elements to
  // get specific proportions on the elements when displayed in a row
  const flexGrows = proportions.reduce((obj, flexGrow, idx) => {
    // @ts-expect-error refactor
    obj[`> * > :nth-child(${idx + 1})`] = {
      flexGrow,
    };
    return obj;
  }, {});

  const cn = useStyle("switcher", {
    "> *": {
      display: "flex",
      flexWrap: "wrap",
      margin: `calc(${theme.space[space]} / 2 * -1)`,
    },
    "> * > *": {
      margin: `calc(${theme.space[space]} / 2)`,
      flexGrow: 1,
      flexBasis: `calc((${threshold} - (100% - ${theme.space[space]})) * 999)`,
    },
    ...flexGrows,
  });

  return (
    <div className={cn}>
      <div>{props.children}</div>
    </div>
  );
}
