/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { Flex, Box } from "hw/ui/blocks";
import { getIn } from "timm";
import { InputLabel } from "hw/ui/input";
import theme from "hw/ui/theme";
import Select from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import type { Path } from "hw/common/types";
import type { Role, Field } from "hw/portal/modules/common/draft";
import * as componentMenuIcons from "hw/portal/modules/draft-editor/form-builder/components/preview/component-menu/icons";
import { FieldTypes } from "hw/portal/modules/draft-editor/constants";
import * as Actions from "./actions";
import { ParticipantLabel, ParticipantDropdownOptions } from "./participants";

type AssignToProps = {
  editorDispatch: Function;
  field: Field;
  path: Path;
  roles: Array<Role>;
  openRoleModal: Function;
  extend?: {} | Function;
};

export function AssignTo(props: AssignToProps) {
  const { editorDispatch, roles, field, path, openRoleModal, extend } = props;
  const reassign = React.useCallback(
    (role) => {
      if (!role) return;

      if (role === "new") {
        openRoleModal();
        return;
      }

      editorDispatch(
        Actions.requestFieldReassignment({
          field,
          path,
          roleId: role.id,
        })
      );
    },
    [editorDispatch, path, openRoleModal, field]
  );

  const triggerProps = {
    compacted: true,
    // @one-off Another custom dropdown button requirement. Our normal
    // select buttons have a transparent background
    extend: { button: { backgroundColor: "white" } },
    // eslint-disable-next-line react/display-name
    // @ts-expect-error refactor
    renderSelected: (selectedItem) => {
      if (!selectedItem) return "Select role...";
      const roleIndex = roles.findIndex((role) => role.id === selectedItem.id);

      return (
        <Flex alignItems="center">
          <ParticipantLabel roleIndex={roleIndex}>
            {selectedItem.title}
          </ParticipantLabel>
        </Flex>
      );
    },
  };

  return (
    <FieldSet
      data-testid="role-setting"
      label="Assigned To"
      id={genId(field, "role")}
      extend={extend}
    >
      {/* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
       * found when upgrading Flow to v0.132.0. To view the error, delete this
       * comment and run Flow. */}
      <Select
        triggerProps={triggerProps}
        // @ts-expect-error refactor
        getItemKey={(role) => role.id}
        onChange={reassign}
        selectedItem={
          roles.find((role) => role.id === getIn(field, ["roles", 0])) || null
        }
        placeholder="Select role..."
        fillContainer
        render={({ getItemProps, highlightedIndex }) => (
          <ParticipantDropdownOptions
            roles={roles}
            // @ts-expect-error refactor
            isDisabled={(role) => role.id === getIn(field, ["roles", 0])}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
          />
        )}
      />
    </FieldSet>
  );
}

// @ts-expect-error refactor
const genId = (field, str) => `${field.id}-${str}`;

type FieldSetProps = {
  stacked?: boolean;
  label: React.ReactNode;
  id: string;
  children: React.ReactNode;
  extend?: any;
};

export function FieldSet(props: FieldSetProps) {
  const { stacked = false, label, id, children, extend, ...rest } = props;

  return (
    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    <Box
      display="grid"
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      extend={{
        // I'm fudging this column width. Should probably be better laid out
        // so we're not using hard-coded values
        gridTemplateColumns: stacked ? "1fr" : "25% minmax(220px, 1fr)",
        gridColumnGap: stacked ? "0" : theme.space.md,
        ...extend,
      }}
      mx="ms"
      py="ms"
      justifyContent={stacked ? "flex-start" : "space-between"}
      alignItems={stacked ? "flex-start" : "center"}
      flexDirection={stacked ? "column" : "row"}
      {...rest}
    >
      <InputLabel
        appearance="compact"
        htmlFor={id}
        extend={{
          marginBottom: stacked && theme.space.xs,
          whiteSpace: "nowrap",
          marginRight: theme.space.lg,
          marginLeft: 0,
        }}
      >
        {label}
      </InputLabel>
      {children}
    </Box>
  );
}

const iconsForType = {
  ...componentMenuIcons,
  DateInput: componentMenuIcons.DateComponent,
};

const availableTypes = [
  { value: FieldTypes.TextInput, label: "Text Input" },
  { value: FieldTypes.DateInput, label: "Date" },
  { value: FieldTypes.PhoneNumber, label: "Phone Number" },
  { value: FieldTypes.Signature, label: "Signature" },
  { value: FieldTypes.SSN, label: "Social Security Number" },
];

type InputTypeProps = {
  editorDispatch: Function;
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  field: Field;
  path: Path;
  extend?: {} | Function;
};

export function InputType(props: InputTypeProps) {
  const { field, editorDispatch, path, extend } = props;
  const options = availableTypes.map(({ value, label }) => ({
    id: value,
    label,
    Icon: iconsForType[value],
  }));

  const onSelect = React.useCallback(
    (option) => {
      if (!option) return;

      const type = option.id;
      if (!field) return;

      editorDispatch(
        Actions.changeComponentType({
          fieldPath: path,
          type,
        })
      );
    },
    [editorDispatch, path, field]
  );

  if (!options.find(({ id }) => id === field.type)) return null;

  const triggerProps = {
    compacted: true,
    // @one-off Another custom dropdown button requirement. Our normal
    // select buttons have a transparent background
    extend: { button: { backgroundColor: "white" } },
    // eslint-disable-next-line react/display-name
    // @ts-expect-error refactor
    renderSelected: (selectedItem) => {
      if (!selectedItem) return "Select input type...";

      return (
        <Flex alignItems="center">
          {selectedItem.Icon && (
            <Flex mr="xs">
              <selectedItem.Icon size={24} />
            </Flex>
          )}
          {selectedItem.label}
        </Flex>
      );
    },
  };

  return (
    <FieldSet
      data-testid="input-type-setting"
      label="Input type"
      id={genId(field, "input-type")}
      extend={extend}
    >
      {/* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
       * found when upgrading Flow to v0.132.0. To view the error, delete this
       * comment and run Flow. */}
      <Select
        triggerProps={triggerProps}
        // @ts-expect-error refactor
        getItemKey={(option) => option.id}
        onChange={onSelect}
        selectedItem={options.find(({ id }) => id === field?.type) || null}
        placeholder="Select role..."
        fillContainer
        renderDest="portal"
        render={({ getItemProps, highlightedIndex }) => (
          <MenuList data-testid="menu-list">
            {options.map((option, index) => (
              <MenuItem
                small={true}
                key={option.id}
                active={index === highlightedIndex}
                passthroughProps={getItemProps({ item: option })}
                extend={{ fontSize: theme.fontSize.ms }}
              >
                {option.Icon && (
                  <Flex>
                    <option.Icon size={24} />
                  </Flex>
                )}
                <MenuItemText>{option.label}</MenuItemText>
              </MenuItem>
            ))}
          </MenuList>
        )}
      />
    </FieldSet>
  );
}
