/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NOTE: This is is legacy component.  If you need a button group component,
 * use the one defined in `ui/button`
 */
import React from "react";
import { createComponent } from "hw/ui/style";

type Props = {
  value?: string;
  onChange: (value: string) => void;
  options: Array<string>;
};

// @ts-expect-error refactor
const ButtonContainer = createComponent(function ButtonContainer({
  theme,
}: any) {
  return {
    "& > button:not(:last-child)": {
      borderRightWidth: "0px",
    },
    "& > button:last-child": {
      borderTopRightRadius: theme.corner.sm,
      borderBottomRightRadius: theme.corner.sm,
    },
    "& > button:first-child": {
      borderTopLeftRadius: theme.corner.sm,
      borderBottomLeftRadius: theme.corner.sm,
    },
  };
});

const Button = createComponent(
  function Button({ theme, active }: any) {
    return {
      paddingLeft: theme.space.sm,
      paddingRight: theme.space.sm,
      paddingTop: theme.space.xs,
      paddingBottom: theme.space.xs,
      backgroundColor: active ? theme.color.blue050 : "transparent",
      borderStyle: "solid",
      cursor: "pointer",
      borderWidth: theme.corner.xs,
      borderColor: active ? theme.color.blue600 : theme.color.gray500,
      color: theme.color.textStandard,
      fontWeight: 600,
      fontSize: theme.fontSize.sm,
      lineHeight: "16px",
      textTransform: "capitalize",
      marginLeft: 0,
      marginRight: 0,
    };
  },
  "button",
  ["onClick"]
);

function ButtonGroup(props: Props) {
  const { value, onChange, options } = props;

  return (
    // @ts-expect-error refactor
    <ButtonContainer flexDirection="row">
      {options.map((option) => (
        <Button
          key={option}
          onClick={() => onChange(option)}
          active={value === option}
        >
          {option}
        </Button>
      ))}
    </ButtonContainer>
  );
}

export default ButtonGroup;
