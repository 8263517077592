import { graphql } from "@apollo/client/react/hoc";
import FETCH_WORKFLOWS_QUERY from "./workflows.graphql";
import BULK_SEND_MUTATION from "./bulk-send.graphql";

/**
 * Fetch the workflow with the guid found in the URL parameters
 */
const FetchWorkflows = {
  options: () => ({
    variables: {
      orderBy: { field: "NAME", direction: "ASC" },
    },

    // Always fetch this data from the network to optimize for data consistency
    // with the server
    fetchPolicy: "network-only",
  }),
};

const BulkSend = {
  // @ts-expect-error refactor
  props: ({ mutate }) => ({
    // @ts-expect-error refactor
    bulkSend: (inputs) => {
      return new Promise((resolve) => {
        mutate({
          variables: { inputs },
        })
          // @ts-expect-error refactor
          .then((response) => {
            resolve({ response, error: null });
          })
          // @ts-expect-error refactor
          .catch((err) => resolve({ response: null, error: err }));
      });
    },
  }),
};

// @ts-expect-error refactor
export const bulkSendMutation = graphql(BULK_SEND_MUTATION, BulkSend);

// @ts-expect-error refactor
export default graphql(FETCH_WORKFLOWS_QUERY, FetchWorkflows);
