import * as React from "react";
import cx from "classnames";
import styles from "./button-group.module.css";

type Props = {
  /**
   * Determines if the button group should have a compacted style
   */
  compact?: boolean;

  children: React.ReactNode;
};

function ButtonGroup(props: Props) {
  const { compact = false, children } = props;
  return (
    <div
      className={cx(
        styles.standard,
        compact ? styles.compacted : styles.expanded
      )}
    >
      {children}
    </div>
  );
}

export default ButtonGroup;
