import type { Field } from "hw/portal/modules/common/draft";
import uniq from "lodash/uniq";
import type { Option, FieldOption } from "./types";
import * as constants from "./constants";
import * as DraftUtils from "../../utils";
import parseExpression from "./parser";
import composeExpression from "./composer";

export const ExprState = {
  fromExpression: parseExpression,
  toExpression: composeExpression,
};

/**
 * Given the full list of fields and the target field, returns a normalized
 * map of dataRef -> FieldOption
 */
export function validSubjectsByDataRef(
  fields: Array<Field>,
  currentField: Field
): Record<string, FieldOption> {
  // Get the index where the field is
  const index = fields.findIndex((field) => field.id === currentField.id);

  if (index === undefined) {
    throw new Error(
      `The field with the id ${currentField.id} should be in the list of fields, but it isn't`
    );
  }

  const roles = currentField.roles;

  // We need roles in order to determine if the available fields share the same
  // role as the field we're applying the condition to
  if (!Array.isArray(roles)) {
    return {};
  }

  return getFieldsByDataRef(fields, roles, index);
}

/**
 * Gives you the conditions in the rule creator
 */
export function getConditions(field: FieldOption): Array<Option> {
  const { type, options, required } = field;
  const basicConditions = required
    ? constants.REQUIRED_CONDITIONS
    : constants.BASE_CONDITIONS;

  // If the options are not valid, we only display the basic conditions
  if (type === "MultipleChoice" && validOptions(options)) {
    return toOptions(
      constants.MULTIPLE_CHOICE_CONDITIONS.concat(basicConditions)
    );
  }

  return toOptions(basicConditions);
}

function validOptions(options?: Array<string>) {
  if (!options) return false;
  return options.some((option) => option.length > 0);
}

export function toOptions(
  options: Array<string> | null | undefined
): Array<Option> {
  if (!options) return [];
  return uniq(options).map((condition) => ({
    id: condition,
    label: condition,
  }));
}
// Determines if we should show the 3rd option or not depending on the condition
export function shouldDisplayValue(condition: string): boolean {
  return constants.MULTIPLE_CHOICE_CONDITIONS.includes(condition);
}

/**
 * When changing the subject, we need to check if we need to clean up the rule
 * or not depending on the fieldtype and the condition
 */
export function shouldCleanUpRule(
  fieldType: string,
  condition: string
): boolean {
  return (
    fieldType !== "MultipleChoice" &&
    constants.MULTIPLE_CHOICE_CONDITIONS.includes(condition)
  );
}

// -----------------------

/**
 * Takes the fields and returns a map by dataRef with the field attributes
 * that we need
 */
function getFieldsByDataRef(
  fields: Array<Field>,
  currentRoles: Array<string>,
  index: number,
  accumulator: Record<string, FieldOption> = {},
  uniqueLabels: Set<string> = new Set()
): Record<string, FieldOption> {
  return fields.reduce((acc, field, currentIdx) => {
    // Flow complains over the presence of options that could not be in the field
    // @ts-expect-error refactor
    const { dataRef, type, label, options, roles, placeholder, id } = field;
    const sameRole = DraftUtils.shareSameRole(currentRoles, roles);

    if (DraftUtils.fieldCanBeReferenced(field) && dataRef) {
      // NOTE: We shouldn't have duplicated dataRefs for fields that can be referenced
      // because we rely on the uniqueness of dataRefs for the conditional logic UI.
      if (acc[dataRef]) {
        throw new Error(
          `There can't be duplicated dataRefs, but ${dataRef} is duplicated`
        );
      }

      const uniqueLabel = DraftUtils.getUniqueLabel(
        label || placeholder,
        uniqueLabels,
        type
      );
      uniqueLabels.add(uniqueLabel);
      const enhancedOptions = getEnhancedOptionsIfNeeded(options, type);

      // @ts-expect-error refactor
      acc[dataRef] = {
        ...field,
        id: dataRef || id,
        label: uniqueLabel,
        valid: sameRole && currentIdx < index,
      };

      if (enhancedOptions) {
        // @ts-expect-error refactor
        acc[dataRef].options = enhancedOptions;
      }
    }

    if (field.children && areChildrenUsable(field.type)) {
      return getFieldsByDataRef(
        field.children,
        currentRoles,
        currentIdx < index ? field.children.length : 0,
        acc,
        uniqueLabels
      );
    } else {
      return acc;
    }
  }, accumulator);
}

function getEnhancedOptionsIfNeeded(
  options?: Array<string>,
  // @ts-expect-error ts-migrate(1016) FIXME: A required parameter cannot follow an optional par... Remove this comment to see the full error message
  fieldType: string
): Array<string> | null | undefined {
  if (options && fieldType === "MultipleChoice") {
    const uniqueNonEmptyValues = [...new Set(options)].filter(Boolean);

    if (uniqueNonEmptyValues.length === 0) {
      return undefined;
    }

    return uniqueNonEmptyValues;
  }
}

function areChildrenUsable(fieldType: string) {
  if (fieldType === "Group" || fieldType === "AddressGroup") {
    return true;
  }

  return false;
}
