import * as React from "react";
import { createComponent } from "hw/ui/style";

type Props = {
  copy: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: React.ReactElement<any>;
};
/**
 * Settings module. It displays if a component has been referenced in a rule
 */

export default function ReferenceConditionalLogic(props: Props) {
  const { copy, Icon } = props;
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
    <Wrapper data-testid="reference-setting">
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: R... Remove this comment to see the full error message */}
      <WrapperIcon>{Icon}</WrapperIcon>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: s... Remove this comment to see the full error message */}
      <Copy>{copy}</Copy>
    </Wrapper>
  );
}
const Copy = createComponent(function ReferenceCopy({ theme }) {
  return {
    fontSize: theme.fontSize.ms,
    lineHeight: "20px",
    color: theme.color.textDim,
    overflowWrap: "break-word",
    minWidth: 0,
    maxWidth: "250px",
  };
});
const WrapperIcon = createComponent(function WrapperIcon({ theme }) {
  return {
    flexShrink: 0,
    marginRight: theme.space.xs,
    marginTop: theme.space.xs,
  };
});
const Wrapper = createComponent(
  function Wrapper({ theme }) {
    return {
      display: "flex",
      alignItems: "end",
      padding: theme.space.ms,
      borderBottomWidth: "1px",
      borderBottomColor: theme.color.dividerDim,
      borderBottomStyle: "solid",
      minWidth: 0,
    };
  },
  "div",
  ["data-testid"]
);
