import * as React from "react";

export default function InvalidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="26"
          height="34"
          x="11"
          y="7"
          stroke="#DEE3E9"
          strokeWidth="2"
          rx="2"
        />
        <path
          stroke="#DD1D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M24 24l-4-4 4 4 4-4-4 4zm0 0l4 4-4-4-4 4 4-4z"
        />
      </g>
    </svg>
  );
}
