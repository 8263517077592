import * as React from "react";
import { DragLayer } from "react-dnd";
import compose from "lodash/flowRight";
import { withStyle } from "hw/ui/style";
import {
  OrderableListItemDragPreview,
  DRAG_TYPE_ORDERABLE_LIST_ITEM,
} from "hw/ui/orderable-list";
import FieldDragPreview from "./preview/field-drag";
import { DRAG_TYPE_FIELD } from "../constants";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;

  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

type Props = {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'DragType'.
  itemType: DragType;
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'DragItem'.
  item: DragItem;
  initialOffset: {
    x: number;
    y: number;
  };
  currentOffset: {
    x: number;
    y: number;
  };
};
type StyleProps = {
  styles: {
    root: string;
  };
};

/**
 * The HTML5 Drag and Drop API shows a screenshot of the dragging item by
 * default.  If you want to customize that you need to rendering something into
 * the DOM in a specific way.  This is react-dnd's API for setting that custom
 * preview image.
 */
export class DragPreviewLayer extends React.Component<StyleProps & Props> {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  renderItem(type, item) {
    switch (type) {
      case DRAG_TYPE_FIELD: {
        return <FieldDragPreview {...item} />;
      }

      case DRAG_TYPE_ORDERABLE_LIST_ITEM:
        return <OrderableListItemDragPreview {...item} />;

      default:
        return null;
    }
  }

  render() {
    const { itemType, item, styles } = this.props;
    return (
      <div className={styles.root}>
        <div style={getItemStyles(this.props)}>
          {this.renderItem(itemType, item)}
        </div>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'monitor' implicitly has an 'any' type.
function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

function style() {
  return {
    root: {
      position: "fixed",
      pointerEvents: "none",
      zIndex: 100,
      left: 0,
      top: 0,
      height: "100%",
    },
  };
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { root: { position: string... Remove this comment to see the full error message
export default compose(DragLayer(collect), withStyle(style))(DragPreviewLayer);
