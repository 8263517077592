/* eslint-disable @typescript-eslint/no-explicit-any */
import mapValues from "lodash/mapValues";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";

const DIRECTIONS = {
  left: "x",
  right: "x",
  top: "y",
  bottom: "y",
};

const toRect = (
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'x' implicitly has an 'any' type.
  { coords: { x, y }, dimensions: { width, height } },
  padding = 0
) => {
  return {
    left: x - padding,
    right: x + width + padding,
    top: y - padding,
    bottom: y + height + padding,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const isLeftBetweenEdges = (rectA, rectB) =>
  rectA.left >= rectB.left && rectA.left < rectB.right;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const isRightBetweenEdges = (rectA, rectB) =>
  rectA.right > rectB.left && rectA.right <= rectB.right;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const isTopBetweenEdges = (rectA, rectB) =>
  rectA.top >= rectB.top && rectA.top < rectB.bottom;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const isBottomBetweenEdges = (rectA, rectB) =>
  rectA.bottom > rectB.top && rectA.bottom <= rectB.bottom;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const hasLeftCollision = (rectA, rectB) =>
  isLeftBetweenEdges(rectA, rectB) || isRightBetweenEdges(rectB, rectA);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const hasRightCollision = (rectA, rectB) =>
  isRightBetweenEdges(rectA, rectB) || isLeftBetweenEdges(rectB, rectA);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const hasTopCollision = (rectA, rectB) =>
  isTopBetweenEdges(rectA, rectB) || isBottomBetweenEdges(rectB, rectA);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rectA' implicitly has an 'any' type.
const hasBottomCollision = (rectA, rectB) =>
  isBottomBetweenEdges(rectA, rectB) || isTopBetweenEdges(rectB, rectA);

const hasCollision = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mappingA' implicitly has an 'any' type.
  mappingA,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mappingB' implicitly has an 'any' type.
  mappingB,
  {
    low = {
      x: 0,
      y: 0,
    },
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'high' implicitly has an 'any' typ... Remove this comment to see the full error message
    high,
  },
  padding = 0
) => {
  const rectA = toRect(mappingA, padding);
  const rectB = toRect(mappingB, padding);
  const left = rectB.right - rectA.left;
  const right = rectB.left - rectA.right;
  const top = rectB.bottom - rectA.top;
  const bottom = rectB.top - rectA.bottom;
  const leftOutside = rectA.right + left > high.x;
  const rightOutside = rectA.left + right < low.x;
  const topOutside = rectA.bottom + top > high.y;
  const bottomOutside = rectA.top + bottom < low.y;
  const leftCollide = hasLeftCollision(rectA, rectB);
  const rightCollide = hasRightCollision(rectA, rectB);
  const topCollide = hasTopCollision(rectA, rectB);
  const bottomCollide = hasBottomCollision(rectA, rectB);
  return {
    collide: (leftCollide || rightCollide) && (topCollide || bottomCollide),
    leftCollide,
    rightCollide,
    topCollide,
    bottomCollide,
    left: leftOutside ? Infinity : left,
    right: rightOutside ? Infinity : right,
    top: topOutside ? Infinity : top,
    bottom: bottomOutside ? Infinity : bottom,
    raw: {
      left,
      right,
      top,
      bottom,
    },
  };
};

const isShiftValid = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mapping' implicitly has an 'any' type.
  mapping,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mappings' implicitly has an 'any' type.
  mappings,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'bounds' implicitly has an 'any' type.
  bounds,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'shiftCoord' implicitly has an 'any' typ... Remove this comment to see the full error message
  shiftCoord,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'shiftValue' implicitly has an 'any' typ... Remove this comment to see the full error message
  shiftValue,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'padding' implicitly has an 'any' type.
  padding
) => {
  const shiftedMapping = {
    ...mapping,
    coords: {
      ...mapping.coords,
      [shiftCoord]: mapping.coords[shiftCoord] + shiftValue,
    },
  };
  return mappings.every(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'map' implicitly has an 'any' type.
    (map) => !hasCollision(shiftedMapping, map, bounds, padding).collide
  );
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mapping' implicitly has an 'any' type.
const hasCollisions = (mapping, mappings, bounds, padding) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'map' implicitly has an 'any' type.
  const allCollisions = mappings.map((map) =>
    hasCollision(mapping, map, bounds, padding)
  );
  const validShifts = mapValues(DIRECTIONS, (coord, direction) =>
    allCollisions
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'collision' implicitly has an 'any' type... Remove this comment to see the full error message
      .map((collision) => collision[direction])
      .filter(
        (
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'shiftValue' implicitly has an 'any' typ... Remove this comment to see the full error message
          shiftValue // eslint-disable-next-line no-restricted-globals
        ) =>
          // eslint-disable-next-line no-restricted-globals
          isFinite(shiftValue) &&
          isShiftValid(mapping, mappings, bounds, coord, shiftValue, padding)
      )
  );
  const minValidShifts = mapValues(
    validShifts,
    (places) => minBy(places, Math.abs) || Infinity
  );
  return (
    allCollisions
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'collide' implicitly has an 'any' ... Remove this comment to see the full error message
      .filter(({ collide }) => collide)
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'left' implicitly has an 'any' typ... Remove this comment to see the full error message
      .map(({ left, right, top, bottom, ...collision }) => ({
        ...collision,
        left: validShifts.left.includes(left) ? left : minValidShifts.left,
        right: validShifts.right.includes(right) ? right : minValidShifts.right,
        top: validShifts.top.includes(top) ? top : minValidShifts.top,
        bottom: validShifts.bottom.includes(bottom)
          ? bottom
          : minValidShifts.bottom,
      }))
  );
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mapping' implicitly has an 'any' type.
const getCollisionDiff = (mapping, mappings, bounds, padding) => {
  const collisions = hasCollisions(mapping, mappings, bounds, padding);
  const validCollision = collisions.reduce(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
    (acc, collision) => ({
      collide: acc.collide || collision.collide,
      left: collision.leftCollide
        ? maxBy([acc.left, collision.left], Math.abs)
        : collision.left,
      right: collision.rightCollide
        ? maxBy([acc.right, collision.right], Math.abs)
        : collision.right,
      top: collision.topCollide
        ? maxBy([acc.top, collision.top], Math.abs)
        : collision.top,
      bottom: collision.bottomCollide
        ? maxBy([acc.bottom, collision.bottom], Math.abs)
        : collision.bottom,
    }),
    {
      collide: false,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    }
  );
  const x = minBy([validCollision.left, validCollision.right], Math.abs);
  const y = minBy([validCollision.top, validCollision.bottom], Math.abs);
  return {
    ...validCollision,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    x: Math.abs(x) <= Math.abs(y) ? x : 0,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    y: Math.abs(x) > Math.abs(y) ? y : 0,
  };
};

function getCoordsInBounds(
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'coords' implicitly has an 'any' t... Remove this comment to see the full error message
  { coords, dimensions: { width, height } },
  {
    low = {
      x: 0,
      y: 0,
    },
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'high' implicitly has an 'any' typ... Remove this comment to see the full error message
    high,
  }
) {
  const maxX = high.x - width;
  const maxY = high.y - height;
  let x = coords.x;
  let y = coords.y;
  let collide = false;

  if (x < low.x) {
    x = low.x;
    collide = true;
  } else if (x > maxX) {
    x = maxX;
    collide = true;
  }

  if (y < low.y) {
    y = low.y;
    collide = true;
  } else if (y > maxY) {
    y = maxY;
    collide = true;
  }

  return {
    collide,
    x,
    y,
  };
}

export const getLimitedCoords = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mapping' implicitly has an 'any' type.
  mapping,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mappings' implicitly has an 'any' type.
  mappings,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'currentRect' implicitly has an 'any' ty... Remove this comment to see the full error message
  currentRect,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'targetRect' implicitly has an 'any' typ... Remove this comment to see the full error message
  targetRect,
  targetPage = undefined,
  /**
   * Padding will expand the dimensions of the mapping when collision detection
   * are calculated. Use this if you want to determine coordinates for a
   * mapping with some buffered space between
   */
  padding = 0
) => {
  const bounds = {
    high: {
      x: targetRect.width,
      y: targetRect.height,
    },
  };
  let {
    coords: { x, y },
  } = mapping;
  let pageCollision = false;

  if (targetPage !== undefined) {
    const coordsInBounds = getCoordsInBounds(mapping, bounds);
    pageCollision = coordsInBounds.collide;
    x = coordsInBounds.x;
    y = coordsInBounds.y;
  }

  const collisionDiff = getCollisionDiff(
    {
      ...mapping,
      coords: { ...mapping.coords, x: Math.round(x), y: Math.round(y) },
    },
    mappings,
    bounds,
    padding
  );
  x = Math.round(x + collisionDiff.x - currentRect.x);
  y = Math.round(y + collisionDiff.y - currentRect.y);
  return {
    collide: collisionDiff.collide || pageCollision,
    x,
    y,
    collisionDiff,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'coords' implicitly has an 'any' type.
const addCoords = (coords, deltaCoords) => ({
  ...coords,
  x: coords.x + deltaCoords.x,
  y: coords.y + deltaCoords.y,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dimensions' implicitly has an 'any' typ... Remove this comment to see the full error message
const addDim = (dimensions, deltaDim) => ({
  ...dimensions,
  width: dimensions.width + deltaDim.width,
  height: dimensions.height + deltaDim.height,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'delta' implicitly has an 'any' type.
const getDeltaCoords = (delta, direction) => {
  switch (direction) {
    case "top":
      return {
        x: 0,
        y: -delta.height,
      };

    case "left":
      return {
        x: -delta.width,
        y: 0,
      };

    case "topLeft":
      return {
        x: -delta.width,
        y: -delta.height,
      };

    case "topRight":
      return {
        x: 0,
        y: -delta.height,
      };

    case "bottomLeft":
      return {
        x: -delta.width,
        y: 0,
      };

    default:
      return {
        x: 0,
        y: 0,
      };
  }
};

const getDimWithRatio = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dimensions' implicitly has an 'any' typ... Remove this comment to see the full error message
  dimensions,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'direction' implicitly has an 'any' type... Remove this comment to see the full error message
  direction,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'aspectRatio' implicitly has an 'any' ty... Remove this comment to see the full error message
  aspectRatio,
  withinDim = null
) => {
  const dimensionsWithRatio = {
    width:
      direction === "left" || direction === "right"
        ? dimensions.width
        : dimensions.height * aspectRatio,
    height:
      direction === "top" || direction === "bottom"
        ? dimensions.height
        : dimensions.width / aspectRatio,
  };

  if (withinDim) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (dimensionsWithRatio.width > withinDim.width) {
      return {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        width: withinDim.width,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        height: withinDim.width / aspectRatio,
      };
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (dimensionsWithRatio.height > withinDim.height) {
      return {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        width: withinDim.height * aspectRatio,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        height: withinDim.height,
      };
    }
  }

  return dimensionsWithRatio;
};

const getDeltaInBounds = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mapping' implicitly has an 'any' type.
  mapping,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'delta' implicitly has an 'any' type.
  delta,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'direction' implicitly has an 'any' type... Remove this comment to see the full error message
  direction,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'bounds' implicitly has an 'any' type.
  bounds,
  aspectRatio = null
) => {
  const deltaCoords = getDeltaCoords(delta, direction);
  const coords = addCoords(mapping.coords, deltaCoords);
  const dimensions = addDim(mapping.dimensions, delta);
  const { collide, x, y } = getCoordsInBounds(
    {
      coords,
      dimensions,
    },
    bounds
  );
  const deltaDim = addDim(delta, {
    width: -Math.abs(coords.x - x),
    height: -Math.abs(coords.y - y),
  });
  const deltaDimWithRatio = aspectRatio
    ? getDimWithRatio(deltaDim, direction, aspectRatio, deltaDim)
    : deltaDim;
  return {
    collide,
    delta: deltaDimWithRatio,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'direction' implicitly has an 'any' type... Remove this comment to see the full error message
const getChangingDirections = (direction, keepAspectRatio) => {
  switch (direction) {
    case "left":
      return keepAspectRatio ? ["left", "bottom"] : [direction];

    case "right":
      return keepAspectRatio ? ["right", "bottom"] : [direction];

    case "top":
      return keepAspectRatio ? ["top", "right"] : [direction];

    case "bottom":
      return keepAspectRatio ? ["bottom", "right"] : [direction];

    case "topLeft":
      return ["top", "left"];

    case "topRight":
      return ["top", "right"];

    case "bottomLeft":
      return ["bottom", "left"];

    case "bottomRight":
      return ["bottom", "right"];

    default:
      return [];
  }
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'aspectRatio' implicitly has an 'any' ty... Remove this comment to see the full error message
const getDirectionScales = (aspectRatio) => {
  if (aspectRatio === null) {
    return {
      left: 1,
      right: 1,
      top: 1,
      bottom: 1,
    };
  }

  return {
    left: 1,
    right: 1,
    top: aspectRatio,
    bottom: aspectRatio,
  };
};

const createDistanceComparator =
  (directionScales: any) =>
  ({
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'direction' implicitly has an 'any... Remove this comment to see the full error message
    direction,
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'distance' implicitly has an 'any'... Remove this comment to see the full error message
    distance,
  }) =>
    Math.abs(distance * directionScales[direction]);

export const getLimitedDelta = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mapping' implicitly has an 'any' type.
  mapping,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mappings' implicitly has an 'any' type.
  mappings,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'currentRect' implicitly has an 'any' ty... Remove this comment to see the full error message
  currentRect,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'delta' implicitly has an 'any' type.
  delta,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'direction' implicitly has an 'any' type... Remove this comment to see the full error message
  direction,
  keepAspectRatio = false
) => {
  const bounds = {
    high: {
      x: currentRect.width,
      y: currentRect.height,
    },
  };
  const aspectRatio = mapping.dimensions.width / mapping.dimensions.height;
  const deltaWithRatio = keepAspectRatio
    ? getDimWithRatio(delta, direction, aspectRatio)
    : delta;
  const { delta: deltaInBounds } = getDeltaInBounds(
    mapping,
    deltaWithRatio,
    direction,
    bounds,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
    keepAspectRatio ? aspectRatio : null
  );
  const deltaCoordsInBounds = getDeltaCoords(deltaInBounds, direction);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  const collisions = hasCollisions(
    {
      ...mapping,
      coords: addCoords(mapping.coords, deltaCoordsInBounds),
      dimensions: addDim(mapping.dimensions, deltaInBounds),
    },
    mappings,
    bounds
  );
  const changingDirections = getChangingDirections(direction, keepAspectRatio);
  const directionScales = getDirectionScales(
    keepAspectRatio ? aspectRatio : null
  );
  const distanceComparator = createDistanceComparator(directionScales);
  const maxDistances = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'collision' implicitly has an 'any' type... Remove this comment to see the full error message
  collisions.forEach((collision) => {
    const collisionsInChangingDirections = changingDirections.map(
      (changingDirection) => ({
        direction: changingDirection,
        distance: collision.raw[changingDirection],
      })
    );
    const collisionWithMinDistance = minBy(
      collisionsInChangingDirections,
      distanceComparator
    );
    const collisionWithMaxDistanceInDirection = maxBy(
      [
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: any; distance: any; } | undefin... Remove this comment to see the full error message
        collisionWithMinDistance,
        {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          direction: collisionWithMinDistance.direction,
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          distance: maxDistances[collisionWithMinDistance.direction],
        },
      ],
      distanceComparator
    );
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    maxDistances[collisionWithMinDistance.direction] =
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      collisionWithMaxDistanceInDirection.distance;
  });
  const deltaDim = {
    ...deltaInBounds,
    width: deltaInBounds.width - maxDistances.left + maxDistances.right,
    height: deltaInBounds.height - maxDistances.top + maxDistances.bottom,
  };
  const deltaDimWithRatio = keepAspectRatio
    ? getDimWithRatio(deltaDim, direction, aspectRatio, deltaDim)
    : deltaDim;
  const deltaCoords = {
    ...deltaCoordsInBounds,
    ...getDeltaCoords(deltaDimWithRatio, direction),
  };
  return {
    deltaCoords: {
      x: Math.round(deltaCoords.x),
      y: Math.round(deltaCoords.y),
    },
    deltaDim: {
      width: Math.round(deltaDimWithRatio.width),
      height: Math.round(deltaDimWithRatio.height),
    },
  };
};
