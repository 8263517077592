import React from "react";

export default function DangerIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="24" />
        <path
          fill="#D82929"
          d="M13.7276886,4.9895714 L20.7248439,16.9927635 C21.2811253,17.9470301 20.9584945,19.1715713 20.0042278,19.7278526 C19.698475,19.9060885 19.3509021,20 18.9969913,20 L5.00268058,20 C3.89811108,20 3.00268058,19.1045695 3.00268058,18 C3.00268058,17.6460892 3.09659206,17.2985163 3.27482797,16.9927635 L10.2719833,4.9895714 C10.8282647,4.03530475 12.0528058,3.71267393 13.0070725,4.26895529 C13.3054733,4.44290543 13.5537384,4.69117056 13.7276886,4.9895714 Z"
        />
        <path
          fill="#FFF"
          d="M12.048,14.64 C11.536,14.64 11.136,14.256 11.104,13.744 L10.864,8.448 C10.832,7.776 11.376,7.216 12.032,7.216 C12.704,7.216 13.248,7.76 13.216,8.448 L12.992,13.744 C12.96,14.256 12.544,14.64 12.048,14.64 Z M10.768,16.88 C10.768,16.176 11.344,15.584 12.048,15.584 C12.752,15.584 13.328,16.176 13.328,16.88 C13.328,17.568 12.752,18.16 12.048,18.16 C11.344,18.16 10.768,17.568 10.768,16.88 Z"
        />
      </g>
    </svg>
  );
}
