/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PdfMapping, PageSize } from "hw/portal/modules/common/draft";
import { getLimitedCoords } from "hw/portal/modules/draft-editor/pdf-mapper/utils/collisions";
import theme from "hw/ui/theme";
import { PageRect } from "hw/ui/document-mapper";
import { withRawValue, createForField } from "../mapping";

interface Coords {
  x: number;
  y: number;
  page: number;
}

/**
 * Attempts to place an output field into the document in a spot that's not
 * overlapping with another output field. If no spot is found, the originally
 * requested placement will be returned, likely resulting in an overlap.
 * Overlaps are OK when actively building a form and we assume users will
 * eventually move the output fields to non-overlapping locations.
 */
export function autoPlaceOutputField(
  field: any,
  mappings: Array<PdfMapping> = [],
  pageRect: PageRect
) {
  const { x, y, width, height, number } = pageRect;

  let outputField = createForField(field, {
    label: `Output Field ${(mappings || []).length + 1}`,
    coords: {
      // Start with the `x` and `y` values from the given visible page rect
      x,
      y: y + 48,
      page: number,
      rawValue: "%{ x: 10, y: 10, page: 1}",
    },
  });

  // Each output field has default sizes for the component it's related to, so
  // once we have those default sizes, nudge the `x` coordinate back from the
  // right edge so we land the output field in the upper right corner of the
  // visible page.
  outputField.coords.x =
    x + width - outputField.dimensions.width - parseInt(theme.space.lg, 10);

  outputField = withRawValue(outputField);

  const adjustedOutputField = findPlacement(
    outputField,
    outputField.coords,
    mappings,
    {
      width,
      height,
    }
  );

  return adjustedOutputField ?? outputField;
}

/**
 * Adds two coordinates together
 */
function addCoords(coords: Coords, delta: Coords) {
  /* $FlowFixMe[cannot-spread-interface] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  return {
    ...coords,
    x: coords.x + delta.x,
    y: coords.y + delta.y,
  };
}

/**
 * Sets the coordinates of the given mapping
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
function moveTo(mapping: PdfMapping, coords: Coords) {
  return withRawValue({
    ...mapping,
    /* $FlowFixMe[cannot-spread-interface] $FlowFixMe This comment suppresses
     * an error found when upgrading Flow to v0.132.0. To view the error,
     * delete this comment and run Flow. */
    coords: {
      ...mapping.coords,
      ...coords,
    },
  });
}

/**
 * TODO: Change this API! This function computes placement based on the outdated
 * assumption that output fields cannot overlap.
 *
 * Takes a mapping and a coordinate preference and adjusts the placement as needed so
 * that it is within the bounds of the page and it is not overlapping on top
 * of other mappings.  A few other notes:
 *
 * - This function expects to receive the _original_ mapping and the coordinates
 *   for wherever you'd like it to be placed _relative_ to the page. So for a
 *   drag and drop operation, it should have the offset coordinates already
 *   applied and then converted to relative coords.
 * - The `potentialCollisions` should be the list of mappings that may potentially
 *   collide with this mapping. For most operations, this will be all mappings on the
 *   same page as the mapping being operated on _excluding_ the mapping itself,
 *   i.e. All other mappings on the page besides the one being place.
 * - The bounding rect should be the rect of the page where the mapping should
 *   be placed
 * - In some cases we won't be able to determine a good place for the mapping,
 *   in which case this function will return the original mapping.
 */
export function placeAt(
  mapping: PdfMapping,
  coords: Coords,
  potentialCollisions: Array<PdfMapping>,
  pageSize: PageSize,
  padding = 4
) {
  const moved = moveTo(mapping, coords);
  /**
   * Constrained coordinates are computed _relative_ to the given page rect.
   * The `currentOffset` value is relative to the viewport
   */
  const { x, y } = getLimitedCoords(
    moved,
    potentialCollisions,
    { x: 0, y: 0 },
    pageSize,
    // @ts-expect-error refactor
    moved.coords.page,
    padding
  );

  // `Infinity` can be returned if there's no appropriate coordinates returned
  // for the given config. In that case, return the original mapping.
  if (Number.isFinite(x) && Number.isFinite(y)) {
    return moveTo(moved, { ...moved.coords, x, y });
  } else {
    return mapping;
  }
}

/**
 * Tries to find a placement for a mapping based on the other mappings on the page
 * or throws. This does not exhaustively try to find the the best placement
 * for a mapping, but attempts to cover basic cases.
 */
// @ts-expect-error refactor
function findPlacement(
  mapping: PdfMapping,
  coords: Coords,
  potentialCollisions: Array<PdfMapping>,
  pageSize: PageSize,
  tries = 0
) {
  const placed = placeAt(mapping, coords, potentialCollisions, pageSize);

  if (tries > 20) {
    return null;
  }

  if (placed === mapping) {
    // Cheap attempt at finding the next placement. Move the mapping one length
    // to the right
    const nextAttemptCoords = addCoords(mapping.coords, {
      ...mapping.coords,
      x: mapping.dimensions.width,
    });

    return findPlacement(
      mapping,
      nextAttemptCoords,
      potentialCollisions,
      pageSize,
      tries + 1
    );
  } else {
    return placed;
  }
}
