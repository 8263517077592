/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import CopyBar from "hw/ui/copybar";
import { TextBody1 } from "hw/ui/text";
import { Box } from "hw/ui/blocks";
import Toggle from "hw/ui/toggle";
import { createComponent } from "hw/ui/style";
import theme from "hw/ui/theme";
import * as heap from "hw/portal/modules/analytics/heap";

type Props = {
  workflowGuid: string;
  workflowName: string;
  onConfirm: (...args: Array<any>) => any; // callback
  onCancel: (...args: Array<any>) => any; // callback
  linkIsEnabled: boolean; // last saved state of link, enabled or disabled
  linkUrl: string; // value of a link that has been previously created
  linkGuid: string; // The guid of the shareable link
  saving: boolean;
  addSuccess: (...args: Array<any>) => any;
  heading?: React.ReactNode;
  showDescriptionBelow?: boolean;
  workflowParticipantMode: "single" | "multi";
};

type State = {
  toggleChecked: boolean; // maintain checkbox state. when toggle is checked, link is currently (or ready to be) enabled
};

/**
 * This component handles the management of a shareable link, acting as an interface
 * for link creation, link copying to clipboard, deactivation,
 * and user alerts with regard to link limitations (e.g. with merge fields, multiple roles, etc.)
 */

/* eslint-disable no-nested-ternary */

export default class ShareableLinksInterface extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    const { linkUrl, linkIsEnabled } = props;

    this.state = {
      toggleChecked: linkUrl ? linkIsEnabled : false,
    };
  }

  onToggle = (enable: boolean) => {
    const { onConfirm, workflowGuid, workflowName, linkUrl, linkGuid } =
      this.props;

    if (onConfirm) {
      onConfirm({
        workflowGuid,
        workflowName,
        linkUrl,
        linkIsEnabled: enable,
        errorMessage: null,
        linkGuid,
        onError: () => {
          this.setState({ toggleChecked: this.props.linkIsEnabled });
        },
      });
    }

    if (enable) {
      heap.track(heap.EVENTS.enableShareableLinkClicked);
    } else {
      heap.track(heap.EVENTS.disableShareableLinkClicked);
    }

    this.setState(() => ({
      toggleChecked: enable,
    }));
  };

  handleOnCopy = () => {
    this.props.addSuccess("Shareable link copied to clipboard!");
  };

  render() {
    const {
      linkUrl,
      linkIsEnabled,
      heading,
      showDescriptionBelow,
      workflowParticipantMode,
      saving,
    } = this.props;
    const { toggleChecked } = this.state;

    return (
      <React.Fragment>
        {!showDescriptionBelow && (
          <FeatureDescription
            workflowParticipantMode={workflowParticipantMode}
          />
        )}
        <ToggleContainer>
          {heading || (
            <TextBody1
              // @ts-expect-error refactor
              extend={({ theme }) => ({
                fontWeight: theme.fontWeight.semibold,
              })}
            >
              <FormattedMessage
                id="shareableLinksInterface.toggleLabel"
                defaultMessage="Enable shareable link"
              />
            </TextBody1>
          )}

          <Toggle
            on={toggleChecked}
            id="enable-shareable-link-toggle"
            label="Enable shareable link"
            onClick={this.onToggle}
            disabled={saving}
          />
        </ToggleContainer>

        {showDescriptionBelow && (
          <FeatureDescription
            workflowParticipantMode={workflowParticipantMode}
            showDescriptionBelow
          />
        )}

        {linkUrl && linkIsEnabled && (
          <CopyBar
            onCopy={this.handleOnCopy}
            value={linkUrl}
            dataTrackId="launch-shareable-link-link_copy"
            buttonText={
              <FormattedMessage
                id="shareableLinksInterface.copyButton"
                defaultMessage="Copy"
              />
            }
          />
        )}
      </React.Fragment>
    );
  }
}

const FeatureDescription = (props: {
  workflowParticipantMode: "single" | "multi";
  showDescriptionBelow?: boolean;
}) => {
  const { workflowParticipantMode } = props;

  return (
    <Box mb="md">
      <TextBody1
        extend={
          props.showDescriptionBelow ? { fontSize: theme.fontSize.sm } : null
        }
      >
        {workflowParticipantMode === "multi" &&
          "Allows anyone with the link to send out this workflow. "}
        {workflowParticipantMode === "single" &&
          "Allows anyone with the link to fill out this form. "}
        The link will always go to the latest published version.
      </TextBody1>
    </Box>
  );
};

const ToggleContainer = createComponent(function ToggleContainer({
  theme,
}: any) {
  return {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.space.md,
  };
},
"div");

export const ShareableLinkWarningContainer = createComponent(
  function ShareableLinkWarningContainer() {
    return {
      display: "flex",
      alignItems: "flex-start",
    };
  },
  "div"
);

/* eslint-enable no-nested-ternary */
