// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ResolvedPosition, Mark } from "prosemirror-model";

/**
 * Returns the position range for a mark within an invidual node.
 *
 * In ProseMirror, when sibling nodes contain the same mark they are represented
 * as a single HTML element in the DOM. For example, a document with the following
 * structure:
 *
 * ```
 * {
 *   type: 'paragraph',
 *   content: [
 *     {
 *       text: 'hello ',
 *       marks: [
 *         { type: 'link', attrs: { href: 'https://helloworks.com' } }
 *       ]
 *     },
 *     {
 *       text: 'world',
 *       marks: [
 *         { type: 'link', attrs: { href: 'https://helloworks.com' } }
 *       ]
 *     },
 *   ]
 * ```
 *
 * ...will translate to this in the DOM:
 *
 * ```
 * <a href='https://helloworks.com'>hello world</a>
 * ```
 *
 * So although there are two nodes with individual `href` attributes, in the
 * DOM it's a single element. If we want to update the `href` value for the
 * above `hello world` link, we'd need to update _two_ different nodes.
 * If you place your cursor on the `world` part of the word in order to edit
 * the `href`, you likely also want to update the `href` for the `hello ` part
 * of the word as well. This function handles that logic, but inspecting the
 * current selection and 'expanding' the position range for a particular mark.
 * Using the result, you can tell ProseMirror to insert a new mark around the
 * position range.
 *
 * A couple more notes:
 *   - `index` is the index of the node at its current depth in the tree. In
 *      the above example, `hello ` has index `0` and `world` has index `1`
 *      (their `index` values in the `content` array)
 *   - `position` is a ProseMirror concept that points to a particular place in
 *      the node tree.
 */
export function markExtend($start: typeof ResolvedPosition, mark: typeof Mark) {
  // Take the starting position and figure out where we are in the current
  // node.
  let startIndex = $start.index();
  let endIndex = $start.indexAfter();

  // Walk backwards in the current node to see if any preceding nodes contain
  // the mark
  while (
    startIndex > 0 &&
    mark.isInSet($start.parent.child(startIndex - 1).marks)
  ) {
    startIndex--;
  }

  // Walk forwards in the current node to see if any subsequent nodes contain
  // the mark
  while (
    endIndex < $start.parent.childCount &&
    mark.isInSet($start.parent.child(endIndex).marks)
  ) {
    endIndex++;
  }

  // Now that we have the index range, walk through each node in the range and
  // compute the total size. This gives us the position ranges.

  // This is the absolute ProseMirror position of the parent node
  let startPos = $start.start();
  let endPos = startPos;

  // Walk through each sibling and accumlate the total size
  for (let i = 0; i < endIndex; i++) {
    const child = $start.parent.child(i);

    // The 'size' is frequently the text length of a word but not always. In
    // the case of a merge field tag, the size would just be `1` because it's
    // treated like a single node
    const size = child.nodeSize;
    if (i < startIndex) {
      startPos += size;
    }
    endPos += size;
  }
  return { from: startPos, to: endPos };
}
