import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import * as heap from "hw/portal/modules/analytics/heap";
import { PremiumWrapper as Premium } from "hw/portal/modules/common/components/premium";
import { useStyle } from "hw/ui/style";
import { formatDate } from "hw/common/date";
import Banner, { TYPES } from "hw/ui/banner";
import { Box } from "hw/ui/blocks";
import { A } from "hw/ui/text";
import { isOnFreeTrial } from "hw/portal/modules/common/components/free-trial-modal";
import FreeTrialStatusQuery from "hw/portal/modules/common/graphql/team/free-trial-status.graphql";
import type { Billing, User } from "hw/portal/modules/common/graphql/schema";
import FETCH_BILLING_INFO_QUERY from "../graphql/team/billing.graphql";
import UserRoleQuery from "../graphql/team/user-info.graphql";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleUpgradeLinkClick(source: any) {
  heap.track("Upgrade link clicked", {
    Source: source,
  });
}

const PLAN_UPGRADE_PAGE = "/plan";

const messages = defineMessages({
  freeTrialBannerMessageDay: {
    id: "quotamessage.freetrail.banner.message.day",
    description:
      "banner, message, informs user that user is on free trial of paid plan and how many days left on their free trial. Links to contact form for sales or checkout plans and pricing page",
    defaultMessage:
      "{length," +
      "plural," +
      "one {You have 1 day remaining in your free {plan} plan. <sales>Contact sales</sales> or <plans>view plans</plans> to upgrade.}" +
      "other {You have # days remaining in your free {plan} plan. <sales>Contact sales</sales> or <plans>view plans</plans> to upgrade.}}",
  },
  freeTrialBannerMessageHours: {
    id: "quotamessage.freetrail.banner.message.hours",
    description:
      "banner, message, informs user that user is on free trial of paid plan and how many hours left on their free trial. Links to contact form for sales or checkout plans and pricing page",
    defaultMessage:
      "{length," +
      "plural," +
      "one {You have 1 hour remaining in your free {plan} plan. <sales>Contact sales</sales> or <plans>view plans</plans> to upgrade.}" +
      "other {You have # hours remaining in your free {plan} plan. <sales>Contact sales</sales> or <plans>view plans</plans> to upgrade.}}",
  },
  freeTrialBannerMessageMinutes: {
    id: "quotamessage.freetrail.banner.message.minutes",
    description:
      "banner, message, informs user that user is on free trial of paid plan and how many minutes left on their free trial. Links to contact form for sales or checkout plans and pricing page",
    defaultMessage:
      "{length," +
      "plural," +
      "one {You have 1 minute remaining in your free {plan} plan. <sales>Contact sales</sales> or <plans>view plans</plans> to upgrade.}" +
      "other {You have # minutes remaining in your free {plan} plan. <sales>Contact sales</sales> or <plans>view plans</plans> to upgrade.}}",
  },
  salesPageUpgradeLinkWithMessage: {
    id: "quotamessage.sales.page.upgrade.link.with.message",
    description:
      "banner, message, link, provides user with a link to see pricing page and compare plans, contact sales to upgrade plan",
    defaultMessage: "View plans and upgrade to send more forms",
  },
  selfServePageUpgradeLink: {
    id: "quotamessage.self.sere.page.upgrade.link",
    description:
      "banner, message, link, provides user with a link to see pricing page and compare plans, and upgrade",
    defaultMessage: "Upgrade now",
  },
});

function getFreeTrialLengthString(unit: string) {
  switch (unit) {
    case "DAY":
      return messages.freeTrialBannerMessageDay;
    case "HOUR":
      return messages.freeTrialBannerMessageHours;
    case "MINUTE":
      return messages.freeTrialBannerMessageMinutes;
    default:
      throw new Error("improper unit provided");
  }
}

export default function QuotaMessage() {
  let billingMessage;
  const intl = useIntl();
  // @ts-expect-error refactor
  const {
    error,
    // @ts-expect-error refactor
    data = { billing: {} },
    loading,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    data: { billing: Billing };
    loading: boolean;
  } = useQuery(FETCH_BILLING_INFO_QUERY, {
    fetchPolicy: "network-only",
  });
  // @ts-expect-error refactor
  const {
    data: userRoleFetchData = { user: { id: "", role: "" } },
    loading: userRoleFetchLoading,
    error: userRoleFetchError,
  }: {
    data: { user: User };
    loading: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    error: {};
  } = useQuery(UserRoleQuery, {
    fetchPolicy: "network-only",
  });

  const { data: { freeTrialInfo } = {}, loading: isFreeTrialLoading } =
    useQuery(FreeTrialStatusQuery, {
      fetchPolicy: "network-only",
    });

  const { role } = userRoleFetchData.user;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const salesText = (...chunks: any[]) => {
    return (
      <A
        href="https://www.hellosign.com/form/helloworks-lets-talk"
        rel="noopener noreferrer"
        target="_blank"
        data-track-id="free_trial_banner-contact_sales"
      >
        {chunks}
      </A>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const plansText = (...chunks: any[]) => {
    return (
      <A href="/plan" data-track-id="free_trial_banner-view_plans_page">
        {chunks}
      </A>
    );
  };

  const showQuotaMessage =
    data &&
    !loading &&
    !error &&
    !userRoleFetchLoading &&
    !userRoleFetchError &&
    !isFreeTrialLoading;

  if (showQuotaMessage) {
    const { transactionCount, transactionLimit, endDate, onFreePlan } =
      data.billing;
    const showTrialBanner =
      !isFreeTrialLoading && freeTrialInfo && isOnFreeTrial(freeTrialInfo);

    if (transactionLimit < 1 && !showTrialBanner) {
      return null;
    }

    const formattedDate = formatDate(endDate, "MMMM D");
    const transactionsUsed = transactionCount / transactionLimit;
    const linkFragment =
      role === "admin"
        ? {
            contactLinkText: intl.formatMessage(
              messages.selfServePageUpgradeLink
            ),
            contactUrl: PLAN_UPGRADE_PAGE,
          }
        : {};

    if (showTrialBanner) {
      billingMessage = {
        // @ts-expect-error refactor
        bannerStyle: TYPES.INFO,
        text: intl.formatMessage(getFreeTrialLengthString(freeTrialInfo.unit), {
          length: freeTrialInfo.length,
          plan: freeTrialInfo.packageName,
          sales: salesText,
          plans: plansText,
        }),
        tracking: "Portal banner - Blue",
      };
    } else if (transactionsUsed >= 0.8 && transactionsUsed < 1.0) {
      // YELLOW BANNER
      billingMessage = {
        bannerStyle: TYPES.WARNING,
        text: `You have sent ${transactionCount} of ${transactionLimit} free monthly forms.`,
        ...linkFragment,
        tracking: "Portal banner - Yellow",
      };
      // RED BANNER
    } else if (transactionsUsed >= 1.0) {
      billingMessage = {
        bannerStyle: TYPES.DANGER,
        text: `You have reached the limit for free forms you can send until ${formattedDate}.`,
        ...linkFragment,
        tracking: "Portal banner - Red",
      };
      // BLUE BANNER
    } else if (onFreePlan) {
      billingMessage = {
        bannerStyle: TYPES.INFO,
        text: `You are currently on a free plan and can send up to ${transactionLimit} forms per month.`,
        ...linkFragment,
        tracking: "Portal banner - Blue",
      };
    } else {
      return null;
    }

    return (
      <React.Fragment>
        <Premium
          feature="MoreTransactions"
          access={null}
          noAccess={
            <Box mb="md">
              <Banner type={billingMessage.bannerStyle}>
                {billingMessage.text}{" "}
                <ContactLink billingMessage={billingMessage} />
              </Banner>
            </Box>
          }
        />
      </React.Fragment>
    );
  }

  return null;
}

export const OVER_MONTHLY_QUOTA =
  "You have reached the limit of 50 workflows that can be sent in a month.";

export const OVER_MINUTE_QUOTA =
  "You have reached the limit of 2 workflows that can be sent in a minute.";

function ContactLink(props: {
  billingMessage: {
    contactUrl?: string;
    contactLinkText?: string;
    tracking?: string;
  };
}) {
  const { contactUrl, contactLinkText, tracking } = props.billingMessage;
  const style = {
    textDecoration: "underline",
  };
  const cn = useStyle("contact-link", style);

  const onClick = React.useCallback(() => {
    handleUpgradeLinkClick(tracking);
  }, [tracking]);

  if (!contactUrl || !contactLinkText) {
    return null;
  }

  return (
    <a href={contactUrl} className={cn} onClick={onClick}>
      {contactLinkText}
    </a>
  );
}
