/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  /**
   * Indicates whether the item is currently focused or activated.  This can
   * happen when the user mouses over the option of navigates to it with the
   * keyboard
   */
  active?: boolean;

  Component?: string | React.ComponentType<any>;

  /**
   * Indicates whether the item is disabled or not.
   */
  disabled?: boolean;

  /**
   * The content of the menu item
   */
  children?: React.ReactNode;

  /**
   * These are props that must be spread onto the underlying `div` element
   * when rendered in a dropdown.  It does things like attaches click handlers
   * and aria labels.
   */
  passthroughProps?: any;
  small?: boolean;
  "data-guide-id"?: string;

  /**
   * Tracking id for analytics
   */
  dataTrackId?: string;

  /**
   * Function or object to extend the style of the MenuItem
   */
  extend?: Extend;

  /**
   * Id used for testing
   */
  "data-testid"?: string;
};

/**
 * A light wrapper for styling menu items.
 */
function MenuItem(props: Props) {
  const {
    children,
    passthroughProps,
    extend = {},
    disabled,
    dataTrackId,
    Component = "div",
  } = props;

  return React.createElement(
    Component,
    {
      className: useStyle("ui-menu-item", menuItemStyle(props), extend),
      ...passthroughProps,
      disabled,
      "data-track-id": dataTrackId,
      "data-test-ref": "ui-menu-item",
      "data-testid": props["data-testid"],
      ...getTestAttributes(props["data-guide-id"]),
    },
    children
  );
}

MenuItem.defaultProps = {
  Component: "div",
  "data-guide-id": "ui-menu-item",
  "data-testid": "ui-menu-item",
};

const menuItemStyle = ({ active, disabled, small }: Props) => ({
  display: "flex",
  alignItems: "center",
  minWidth: small ? 0 : "120px",
  width: "100%",
  padding: theme.space.sm,
  backgroundColor:
    active && !disabled ? theme.color.gray200 : theme.color.white,
  cursor: disabled ? "default" : "pointer",
  color: disabled && theme.color.textLight,
  ...(disabled
    ? {}
    : {
        ":hover": {
          backgroundColor: theme.color.gray050,
        },
        ":focus": {
          backgroundColor: theme.color.gray050,
        },
        ":active": {
          backgroundColor: theme.color.blue100,
          color: theme.color.blue600,
        },
      }),
});

export default MenuItem;
