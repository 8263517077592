import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HtmlRenderer } from "hw/common/rich-text/renderer";

/**
 * @typedef Props
 *
 * @prop {string} stringId
 * @prop {boolean | undefined} hasHtml
 * @prop {Object | undefined} values
 * @prop {string | undefined} className
 */

/**
 * Simple wrapper for FormattedMessage, it's primary focus is to block functionality that we as a team do not want
 * exposed, such as defaultMessage. It also provides our own global message styling.
 *
 * @param {Props} props
 */
export default function Message(props) {
  const { stringId, hasHtml, values, className } = props;
  const intl = useIntl();

  /**
   * intl.formatMessage doesn't play nice when doing both html and tokenized strings. If you want to do both you have to
   * do some shenanigans like
   * intl.formatMessage({ id: stringId }, { span: (innerValue) => `<span>${innerValue}</span>` ...
   *
   * To simplify this we push the tokenization onto HtmlRenderer instead while still using intl.formatMessage to get the
   * string
   */
  return hasHtml ? (
    <HtmlRenderer
      data={values}
      className={className}
      html={intl.formatMessage({ id: stringId })}
      sanitizeOptions={{ ADD_ATTR: ["class", "target", "rel"] }}
      data-automation-id={stringId}
    />
  ) : (
    <FormattedMessage values={values} id={stringId}>
      {(...content) => (
        <span data-automation-id={stringId} className={className}>
          {content}
        </span>
      )}
    </FormattedMessage>
  );
}
