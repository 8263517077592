/* eslint-disable @typescript-eslint/ban-types */
import camelCase from "lodash/camelCase";
import { ensureStartsWithAlpha, shortid } from "./utils";
import type * as Types from "./types";
import { FieldLabels } from "./constants";

// eslint-disable-next-line @typescript-eslint/ban-types
export function Form(props: {}) {
  const id = `Form_${shortid()}`;

  /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  return {
    enabled: true,
    id,
    name: id,
    fields: [],
    macros: {},
    settings: {
      preview: true,
    },
    ...props,
  };
}

export function DataRef(prefix: string) {
  const safePrefix = ensureStartsWithAlpha(camelCase(prefix));
  const id = shortid();

  return `${safePrefix}_${id}`;
}

export function Role(props: { id?: string; title: string }) {
  const { id, title } = props;
  const safeId = id ? ensureStartsWithAlpha(id) : DataRef(title);

  return {
    id: safeId,
    title,
  };
}

/** Usable from form builder */
const TextInput = (attrs: Partial<Types.TextInputField>) => ({
  label: FieldLabels.TextInput,
  ...attrs,
  type: "TextInput",
});

const Signature = (attrs: Partial<Types.SignatureField>) => ({
  ...attrs,
  type: "Signature",
  errors: {
    required: "This field is required",
  },
  required: true,
});

const Paragraph = (attrs: Partial<Types.ParagraphField>) => ({
  value: {
    type: "doc",
    content: [{ type: "paragraph" }],
  },
  ...attrs,
  type: "Paragraph",
});

const MultipleChoice = (attrs: Partial<Types.MultipleChoiceField>) => ({
  label: FieldLabels.MultipleChoice,
  presentation: "buttons",
  allowMultiple: false,
  options: ["Option 1", "Option 2"],
  ...attrs,
  type: "MultipleChoice",
});

const PhoneNumber = (attrs: Partial<Types.PhoneNumberField>) => ({
  label: FieldLabels.PhoneNumber,
  ...attrs,
  errors: { default: "Phone number must have 10 digits" },
  validators: [
    Expression("phoneNumber", [Expression("val", [attrs.dataRef || ""])]),
  ],
  type: "PhoneNumber",
});

const SSN = (attrs: Partial<Types.SsnField>) => ({
  label: FieldLabels.SSN,
  ...attrs,
  errors: { default: "Social security number must have 9 digits" },
  validators: [Expression("ssn", [Expression("val", [attrs.dataRef || ""])])],
  type: "SSN",
});

const FileAttachment = (attrs: Partial<Types.FileAttachmentField>) => ({
  label: FieldLabels.FileAttachment,
  ...attrs,
  type: "FileAttachment",
  required: true,
  errors: {
    required: "This attachment is required",
  },
});

const AddressGroup = (
  attrs: Partial<Types.AddressGroupField> & { roles: string[]; dataRef: string }
) => {
  const { roles, dataRef } = attrs;

  return {
    label: FieldLabels.Address,
    ...attrs,
    type: "AddressGroup",
    children: [
      TextInput({
        ...addressGroupChild(roles, dataRef, "street"),
        label: "Street Address",
        errors: {
          required: "Street address is required",
          default: "Invalid",
        },
      }),
      TextInput({
        ...addressGroupChild(roles, dataRef, "street2"),
        placeholder: "Apartment, suite, unit, building, floor, etc (optional)",
        label: "Address - Line 2",
      }),
      TextInput({
        ...addressGroupChild(roles, dataRef, "city"),
        label: "City",
        errors: {
          required: "City is required",
          default: "Invalid",
        },
      }),
      stateSelectList(roles, dataRef),
      zipCode(roles, dataRef),
    ],
  };
};

/** Code View Only */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectList = (attrs: any) => ({
  placeholder: "",
  ...attrs,
  type: "SelectList",
});

const Hidden = (attrs: Partial<Types.HiddenField>) => ({
  ...attrs,
  type: "Hidden",
});

/** other fields */
const stateSelectList = (roles: string[], dataRef: string) =>
  SelectList({
    ...addressGroupChild(roles, dataRef, "state"),
    options: [
      "",
      "AK",
      "AL",
      "AR",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    label: "State",
    errors: {
      required: "State is required",
      default: "Invalid",
    },
  });

const zipCode = (roles: string[], dataRef: string) =>
  TextInput({
    ...addressGroupChild(roles, dataRef, "zip"),
    errors: {
      required: "Zip code is required",
      default: "Invalid zip code",
    },
    label: "Zip Code",
    type: "TextInput",
    validators: [
      Expression("regex", [
        Expression("val", [`zip_${dataRef}` || ""]),
        "/^\\d{5}$/",
      ]),
    ],
  });

const SIN = (attrs: Partial<Types.SinField>) => ({
  ...attrs,
  validators: [Expression("sin", [Expression("val", [attrs.dataRef || ""])])],
  type: "SIN",
});

const EIN = (attrs: Partial<Types.EinField>) => ({
  ...attrs,
  validators: [Expression("ein", [Expression("val", [attrs.dataRef || ""])])],
  type: "EIN",
});

const Group = (attrs: Partial<Types.GroupField>) => ({
  label: FieldLabels.Group,
  visible: Expression("all"),
  children: [],
  ...attrs,
  type: "Group",
});

const DateInput = (attrs: Partial<Types.DateInputField>) => ({
  label: FieldLabels.DateInput,
  ...attrs,
  value: Expression("today"),
  type: "DateInput",
});

const addressGroupChild = (roles: string[], dataRef: string, name: string) => ({
  roles,
  dataRef: `${name}_${dataRef}`,
  id: `${name}_${dataRef}`,
  validators: [],
});

const EmailAddress = (attrs: Partial<Types.TextInputField>) => ({
  label: FieldLabels.EmailAddress,
  ...attrs,
  errors: { emailAddress: "This field can only be an email address" },
  validators: [
    Expression("emailAddress", [Expression("val", [attrs.dataRef || ""])]),
  ],
  type: "TextInput",
});

const Multiline = (attrs: Partial<Types.MultilineField>) => ({
  label: FieldLabels.Multiline,
  maxLength: 50,
  ...attrs,
  type: "Multiline",
});

const Calculation = (attrs: Partial<Types.CalculationField>) => ({
  label: FieldLabels.Calculation,
  ...attrs,
  type: "Calculation",
});

/**
 * This is a facade only component to create "numberOnly" inputs from calculations
 */
const NumberInput = (attrs: Partial<Types.TextInputField>) => ({
  ...attrs,
  validators: [
    Expression("numbersOnly", [Expression("val", [attrs.dataRef || ""])]),
  ],
  type: "TextInput",
  errors: {
    numbersOnly: "Can only include numbers",
  },
});

const Fields = {
  TextInput,
  Signature,
  Paragraph,
  MultipleChoice,
  Hidden,
  PhoneNumber,
  AddressGroup,
  FileAttachment,
  SSN,
  SIN,
  EIN,
  Group,
  DateInput,
  EmailAddress,
  Multiline,
  Calculation,
  NumberInput,
};

const createFieldWithDataRef = (type: string) => type !== "Paragraph";

export function Field(props: {
  type: keyof typeof Fields;
  id?: string;
  roles: Array<string>;
}) {
  const { type, id, ...rest } = props;
  const safeId = id ? ensureStartsWithAlpha(id) : DataRef("field");
  /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  const attrs = {
    id: safeId,
    validators: [],
    ...(createFieldWithDataRef(type) ? { dataRef: safeId } : {}),
    ...rest,
  };

  const Constructor = Fields[type];

  if (!Constructor) {
    throw new Error(`Cannot create field for type "${type}"`);
  }

  // @ts-expect-error refactor
  return Constructor(attrs);
}

export function MergeField(props: Types.MergeField): Types.MergeField {
  return props;
}

export function Expression(
  methodName: string,
  args: Array<string | Types.DataRef | Types.Expression> = [],
  props: {} = {}
) {
  /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  return {
    args,
    macroName: null,
    methodName,
    ...props,
  };
}

export function Mapping(from: Types.DataRef, to: string): Types.Mapping {
  return {
    [to]: Expression("val", [from]),
  };
}

export function PDFMapping(props: Partial<Types.Mapping>) {
  return {
    label: `pdf_map_${shortid()}`,
    value: "",
    ...props,
  };
}
