import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  space?: keyof typeof theme["space"];
  as?: React.ElementType;
  extend?: Extend;
} & React.HTMLAttributes<HTMLElement>;

/**
 * A simple component that adds spacing between child elements.
 */
export function Stack({ space = "md", as = "div", extend, ...rest }: Props) {
  const El = as;
  const cn = useStyle(
    "stack",
    {
      // Any margin-top only when a child element is preceded by another element
      "> * + *": {
        marginTop: theme.space[space],
      },
    },
    extend
  );

  return <El {...rest} className={cn} />;
}
