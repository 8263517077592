/**
 * Conditional Assertions
 *
 * These are functions that can be used at runtime to assert or warn against
 * invariants.  Both of these functions are compiled build time so that they
 * have special prod-only variants.
 *
 * If the given `condition` is false, then an error will be thrown with the
 * given message.
 *
 * At build time, this is compiled so that the messages are not present, but the
 * invariant still holds
 *
 * Turns this code:
 *
 * ```js
 *  invariant(condition, msg);
 * ```
 *
 *  into this:
 *
 * ```js
 *  if (!condition) {
 *    if ("production" !== process.env.NODE_ENV) {
 *      invariant(false, msg);
 *    } else {
 *      invariant(false);
 *    }
 *  }
 * ```
 *
 * The bundler will then remove the `("production" !== process.env.NODE_ENV)`
 * block so that we only end up with
 *
 * ```
 * if (!condition) {
 *   invariant(false)
 * }
 * ```
 *
 * @example
 * function myFn(data) {
 *   // will throw if `data` is not a string
 *   invariant(typeof data === 'string', 'Data should be a string!');
 *
 *   // data is guaranteed to be a string if we get here
 *   return data.trim();
 * }
 */
const invariantPrefix = "Invariant failed";
const isProduction: boolean = process.env.NODE_ENV === "production";

export function invariant(
  condition: unknown,
  message?: string
): asserts condition {
  if (condition) {
    return;
  }

  // If we're here, the condition did not pass
  // In production, log a general "Invariant failed" error and rely on source
  // maps to debug. Otherwise, throw and error with the given message.

  if (isProduction) {
    throw new Error(invariantPrefix);
  } else {
    throw new Error(`${invariantPrefix}: ${message || ""}`);
  }
}

/**
 * Helper for dev-only warning messages.
 *
 * At build time, turns this code:
 *
 * warning(condition, message);
 *
 * into this:
 *
 * if ("production" !== process.env.NODE_ENV) {
 *   warning(condition, message);
 * }
 *
 * The goal is to strip out warning calls entirely in production.
 *
 * @example
 * function someFunction() {
 *   warning(condition, "Careful!")
 * }
 */
export function warning(condition: boolean, message: string) {
  // don't do anything in production
  // wrapping in production check for better dead code elimination
  if (!isProduction) {
    if (condition) {
      return;
    }

    // If we're here, the condition did not pass. Send a warning to the console

    const text = `Warning: ${message}`;

    // eslint-disable-next-line no-console
    console.warn(text);

    // Throwing an error and catching it immediately
    // to improve debugging
    // A consumer can use 'pause on caught exceptions'
    // https://github.com/facebook/react/issues/4216
    try {
      throw Error(text);
    } catch (x) {
      // nothing...
    }
  }
}
