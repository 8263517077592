import React from "react";
import { Env } from "hw/common/env";

export function LogoLockup(props: React.HTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      alt={`${brandName()} logo`}
      src={Env.get("logoLockupUrl")}
    />
  );
}

export function LogoGlyph32(props: React.HTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      src={Env.get("logoGlyph32Url")}
      alt={`${brandName()} Logo`}
    />
  );
}

export function brandName(siteCode: "hw" | "hs" = "hw") {
  if (siteCode === "hw") {
    return Env.get("brandName");
  } else if (siteCode === "hs") {
    return Env.get("hsBrandName");
  }
}
