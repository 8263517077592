import * as React from "react";
import { updateIn } from "timm";
import Modal, { ACTION_TYPE } from "hw/ui/modal";
import { Text } from "hw/ui/text";
import theme from "hw/ui/theme";
import { Flex } from "hw/ui/blocks";
import { ButtonGroup, SelectableButton } from "hw/ui/button";
import { SelectInput, Utils as SelectInputUtils } from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import {
  OperatorAdd,
  OperatorSubstract,
  OperatorMultiply,
  OperatorDivide,
} from "hw/ui/icons";
import type { Path } from "hw/common/types";
import { useUpdateEffect } from "hw/common/hooks";
import type { CalculationField, Field } from "hw/portal/modules/common/draft";
import { Factories } from "hw/portal/modules/common/draft";
import { PremiumBox1 as Premium } from "hw/portal/modules/common/components/premium";
import * as DraftUtils from "../../../utils";
import { Label } from "./common";
import Wrapper from "./common/basics/wrapper";
import type { SettingsProps } from "../../types";
import { genId } from "./utils";
import { Actions } from "../../state";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: CalculationField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
  fields: Array<Field>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  insertFieldAt: (field: any, path: Path) => void;
};
export function CalculationSettings(props: Props) {
  const {
    field,
    path,
    editorDispatch,
    defaultSettings,
    referenceSetting,
    fields,
    insertFieldAt,
  } = props;
  const { label, type, value } = field;
  return (
    <Flex flexDirection="column">
      <Premium feature="Calculation" />
      {defaultSettings}
      <Label
        path={path}
        editorDispatch={editorDispatch}
        label={label}
        fieldType={type}
        placeholder="Total, Subtotal, Number of hours"
      />
      <Operation
        expression={value}
        fields={fields}
        editorDispatch={editorDispatch}
        path={path}
        field={field}
        insertFieldAt={insertFieldAt}
      />
      {referenceSetting}
    </Flex>
  );
}
const operationOptions = [
  {
    value: "add",
    Icon: <OperatorAdd />,
  },
  {
    value: "sub",
    Icon: <OperatorSubstract />,
  },
  {
    value: "multiply",
    Icon: <OperatorMultiply />,
  },
  {
    value: "divide",
    Icon: <OperatorDivide />,
  },
];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function Operation(props) {
  const { expression, fields, editorDispatch, path, field, insertFieldAt } =
    props;
  const [expr, setExpr] = React.useState(() => {
    return tryParseExpression(expression);
  });
  const {
    values: [arg1, arg2],
    operation,
  } = expr.value;

  // Handy facade setters for setExpr
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'op' implicitly has an 'any' type.
  const setOperation = (op) =>
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    setExpr(updateIn(expr, ["value", "operation"], () => op));

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val1' implicitly has an 'any' type.
  const setArg1 = (val1) =>
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    setExpr(updateIn(expr, ["value", "values", 0], () => val1));

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val1' implicitly has an 'any' type.
  const setArg2 = (val1) =>
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    setExpr(updateIn(expr, ["value", "values", 1], () => val1));

  useUpdateEffect(() => {
    if (isValid(expr)) {
      const value = expressionComposer(expr);
      editorDispatch(
        Actions.ChangeFieldSetting({
          path,
          updater: (field) => {
            return { ...field, value };
          },
        })
      );
    }
  }, [expr]);
  return (
    <>
      <ValueInput
        label="Value 1"
        value={arg1}
        fields={fields}
        onChange={setArg1}
        field={field}
        path={path}
        editorDispatch={editorDispatch}
        insertFieldAt={insertFieldAt}
      />
      <Wrapper label="Operator" id={`${field.id}-calculation-operator`}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; justifySelf: string; }'... Remove this comment to see the full error message */}
        <Flex justifySelf="end">
          <ButtonGroup compact>
            {operationOptions.map((op) => (
              <SelectableButton
                presentation="primaryOutline"
                selected={operation === op.value}
                key={op.value}
                aria-label={op.value}
                onClick={() => setOperation(op.value)}
                /**
                 * TODO: Add this style to Selectable Button
                 * This is super annoying! The selectable button component
                 * doesn't have a styling to reflect it's selected unless
                 * it has no "presentation" type. I don't want to change the
                 * behaviour now because there are other <SelectableButton> instances
                 * that could get affected by those changes.
                 */
                extend={{
                  height: "32px",
                  width: "46px",
                  borderColor:
                    operation === op.value
                      ? theme.color.blue600
                      : theme.color.gray500,
                  backgroundColor:
                    operation === op.value
                      ? theme.color.blue100
                      : theme.color.white,
                }}
              >
                {op.Icon}
              </SelectableButton>
            ))}
          </ButtonGroup>
        </Flex>
      </Wrapper>
      <ValueInput
        label="Value 2"
        value={arg2}
        fields={fields}
        onChange={setArg2}
        field={field}
        path={path}
        editorDispatch={editorDispatch}
        insertFieldAt={insertFieldAt}
      />
    </>
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function ValueInput(props) {
  const {
    label,
    value,
    fields,
    onChange,
    field,
    path,
    editorDispatch,
    insertFieldAt,
  } = props;
  const { current: id } = React.useRef(genId());
  const [modalOpen, setModalOpen] = React.useState(false);
  const [fieldToNumber, setFieldToNumber] = React.useState(null);
  const calculationOptions = getCalculationOptions(field, path, fields);
  const selectedItem = React.useMemo(() => {
    if (value?.type === "DATAREF") {
      return calculationOptions.find((option) => option.value === value?.value);
    } else if (value?.type === "CONSTANT") {
      // Add to list and return it
      const item = {
        value: value.value,
        label: `${value.value}`,
        type: value.type,
      };
      calculationOptions.push(item);
      return item;
    }
  }, [calculationOptions, value]);
  const handleChange = React.useCallback(
    (opt) => {
      if (opt) {
        if (opt.type === "INVALID_DATAREF") {
          setFieldToNumber(opt.value);
          setModalOpen(true);
        } else if (opt.type === "NEW_INPUT") {
          const newField = Factories.Field({
            type: "NumberInput",
            // @ts-expect-error refactor
            label: opt.label,
            roles: field.roles || [],
          });
          insertFieldAt(newField, path);
          onChange({
            value: newField.dataRef,
            type: "DATAREF",
          });
        } else {
          onChange({
            value: opt.value,
            type: opt.type,
          });
        }
      } else {
        onChange(null);
      }
    },
    [field.roles, insertFieldAt, onChange, path]
  );
  const changeFieldToNumber = React.useCallback(() => {
    const baseFieldPath = path.slice(0, 3);
    const fieldPath = getPathById(baseFieldPath, fields, fieldToNumber);

    if (fieldPath) {
      editorDispatch(
        Actions.ChangeFieldSetting({
          path: fieldPath,
          updater: (field) => {
            return {
              ...field,
              validators: [
                Factories.Expression("numbersOnly", [
                  Factories.Expression("val", [field.dataRef]),
                ]),
              ],
              errors: {
                ...field.errors,
                numbersOnly: "Can only include numbers",
              },
            };
          },
        })
      );
      onChange({
        value: fieldToNumber,
        type: "DATAREF",
      });
    }

    setFieldToNumber(null);
    setModalOpen(false);
  }, [editorDispatch, fieldToNumber, fields, onChange, path]);
  return (
    <>
      {modalOpen && (
        <CalculationModal
          onCancel={() => setModalOpen(false)}
          onConfirm={changeFieldToNumber}
        />
      )}
      <Wrapper
        label={label}
        data-testid="calculation-setting-source-value"
        id={id}
      >
        {/* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error *
        found when upgrading Flow to v0.132.0. To view the error, delete * this
        comment and run Flow. */}
        <SelectInput
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: (opt: any) => void; selectedItem... Remove this comment to see the full error message
          onChange={handleChange}
          selectedItem={selectedItem || null}
          placeholder="Select source..."
          fillContainer
          usePreferredLayout={true}
          triggerProps={{
            id,
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'opt' implicitly has an 'any' type.
            displaySelectedItem: (opt) => opt.type === "DATAREF",
          }}
          renderDest="portal"
          render={({ getItemProps, highlightedIndex, inputValue }) => {
            const isSelected = inputValue === selectedItem?.label;
            const filteredValues = isSelected
              ? calculationOptions
              : SelectInputUtils.filterOptions(calculationOptions, inputValue);
            const inOptions = filteredValues.some(
              (fv) => fv.label === inputValue
            );
            return (
              <MenuList
                data-testid={`menu ${label}`} // This is pretty annoying. I'm adding this to make it scroll after
                // a certain threshold
                extend={{
                  maxHeight: "150px",
                  overflow: "auto",
                }}
              >
                {(!inputValue || isSelected) && (
                  <MenuItem
                    disabled
                    extend={{
                      ...(filteredValues.length && {
                        borderBottomWidth: "1px",
                        borderBottomColor: theme.color.dividerDim,
                        borderBottomStyle: "solid",
                      }),
                    }}
                  >
                    <MenuItemText
                      extend={{
                        color: theme.color.textSelectPrompt,
                      }}
                    >
                      Type to create a value...
                    </MenuItemText>
                  </MenuItem>
                )}
                {filteredValues.map((option, index) => (
                  <MenuItem
                    key={`source-option${option.value || ""}`}
                    active={index === highlightedIndex}
                    passthroughProps={getItemProps({
                      item: option,
                      index,
                    })}
                    data-testid={`source-option-${option.value || ""}`}
                  >
                    <MenuItemText>{option.label || ""}</MenuItemText>
                  </MenuItem>
                ))}
                {isValidNumber(inputValue) &&
                  !(selectedItem && selectedItem.label === inputValue) && (
                    <MenuItem
                      active={filteredValues.length === highlightedIndex}
                      passthroughProps={getItemProps({
                        item: {
                          label: inputValue,
                          value: `${floorNumber(inputValue)}`,
                          type: "CONSTANT",
                        },
                        index: filteredValues.length,
                      })}
                      extend={{
                        ...(filteredValues.length && {
                          borderTopWidth: "1px",
                          borderTopColor: theme.color.dividerDim,
                          borderTopStyle: "solid",
                        }),
                      }}
                    >
                      <MenuItemText>{`Create value "${floorNumber(
                        inputValue
                      )}"`}</MenuItemText>
                    </MenuItem>
                  )}
                {inputValue.length > 0 &&
                  !isValidNumber(inputValue) &&
                  !inOptions && (
                    <MenuItem
                      active={filteredValues.length === highlightedIndex}
                      passthroughProps={getItemProps({
                        item: {
                          label: inputValue,
                          value: inputValue,
                          type: "NEW_INPUT",
                        },
                        index: filteredValues.length,
                      })}
                      extend={{
                        ...(filteredValues.length && {
                          borderTopWidth: "1px",
                          borderTopColor: theme.color.dividerDim,
                          borderTopStyle: "solid",
                        }),
                      }}
                    >
                      <MenuItemText>{`Create input for "${inputValue}"`}</MenuItemText>
                    </MenuItem>
                  )}
              </MenuList>
            );
          }}
        />
      </Wrapper>
    </>
  );
}

// This is a super simplified version of the expression builder
// for the calculation use case. All of this should change with
// draft 11 so I'm just using what we currently have
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'expression' implicitly has an 'any' typ... Remove this comment to see the full error message
function tryParseExpression(expression) {
  try {
    return {
      supported: true,
      value: DraftUtils.parseCalculationExpression(expression) ?? {
        values: [],
        operation: "add",
      },
    };
  } catch (err) {
    return {
      supported: false,
      value: {
        values: [],
        operation: "add",
      },
      reason: err,
    };
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'currentField' implicitly has an 'any' t... Remove this comment to see the full error message
function getCalculationOptions(currentField, path, fields) {
  const sources = DraftUtils.calculationSources(currentField, path, fields);
  return sources.map((field) => ({
    value: field.dataRef,
    label: field.label,
    type: optionType(field) ? "DATAREF" : "INVALID_DATAREF",
  }));
}

/**
 * Checks if a field is valid to be used as the source of a calculation
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
function optionType(field) {
  if (field.type === "Calculation") return true;
  const { validators } = field;
  return (
    validators &&
    validators.length > 0 &&
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    validators.some((val) => val.methodName === "numbersOnly")
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'expr' implicitly has an 'any' type.
function isValid(expr) {
  const {
    values: [arg1, arg2],
    operation,
  } = expr.value;
  return arg1?.value && arg2?.value && operation;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'input' implicitly has an 'any' type.
function isValidNumber(input) {
  return input && Number.isFinite(Number(input));
}

/**
 * Caps a number to 3 decimal points
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
function floorNumber(number) {
  return Math.floor(number * 1000) / 1000;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'expr' implicitly has an 'any' type.
function expressionComposer(expr) {
  const {
    values: [arg1, arg2],
    operation,
  } = expr.value;
  const args = [arg1, arg2].map((val) => {
    if (val?.type === "DATAREF") {
      return Factories.Expression("val", [val.value]);
    } else if (val?.type === "CONSTANT") {
      return val.value;
    } else {
      // We should never run into this case
      return val;
    }
  });
  return Factories.Expression(operation, args);
}

/**
 * It looks for the path of a dataRef in a list of fields
 * if it doesn't find a path it returns null
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'basePath' implicitly has an 'any' type.
function getPathById(basePath, fields, dataRef) {
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].dataRef === dataRef) {
      return [...basePath, i];
    }

    if (Array.isArray(fields[i].children)) {
      // We traverse the child components
      for (let j = 0; j < fields[i].children.length; j++) {
        if (fields[i].children[j].dataRef === dataRef) {
          return [...basePath, i, "children", j];
        }
      }
    }
  }

  return null;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function CalculationModal(props) {
  const { onCancel, onConfirm } = props;
  return (
    <Modal
      heading="Change input type to number"
      layer="hoverCard"
      onClose={onCancel}
      primaryAction={{
        text: "Accept",
        onClick: onConfirm,
        type: ACTION_TYPE.CONFIRM,
      }}
      secondaryAction={{
        text: "Cancel",
        onClick: onCancel,
      }}
    >
      <Text variant="body1">
        To be able to use this input in a calculation component it needs to be a
        number
      </Text>
    </Modal>
  );
}

export default CalculationSettings;
