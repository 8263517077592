import * as React from "react";
import { createComponent } from "hw/ui/style";
import type { Theme } from "hw/portal/app";

type Props = {
  active?: boolean;
  onClick: (evt: React.SyntheticEvent<HTMLAnchorElement>) => void;
};
type StyleProps = {
  theme: Theme;
};

/**
 * A component for rendering `NavLink` components in the sidebar
 *
 * @example
 * const sidebarLinks = myArray.map(thing =>
 *      <SidebarLink onClick={() => {}} active={true}>{thing.name}</SidebarLink>);
 *
 * <Sidebar>{sidebarLinks}</Sidebar>
 */
// @ts-expect-error ts-migrate(2322) FIXME: Type 'FelaHtmlComponent<Props & StyleProps, HTMLAn... Remove this comment to see the full error message
const SidebarLink: React.ComponentType<Props> = createComponent(style, "a", [
  "onClick",
  "data-test-ref",
  "data-testid",
  "data-track-id",
]);
SidebarLink.displayName = "SidebarLink";

function style(props: Props & StyleProps) {
  const { active, theme } = props;
  return {
    backgroundColor: active && theme.color.gray100,
    fontSize: theme.fontSize.ms,
    paddingTop: theme.space.ms,
    paddingBottom: theme.space.ms,
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1,
    ":hover": {
      color: "inherit",
      backgroundColor: theme.color.gray200,
    },
    ":active": {
      backgroundColor: theme.color.gray300,
    },
  };
}

export default SidebarLink;
