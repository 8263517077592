/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

export type ExpandButtonProps = {
  onClick: (...args: Array<any>) => any;
  "aria-expanded": boolean;
  "aria-controls": string;
  id: string;
  "data-automation-id": string;
  type: "button";
};

type ExpandableProps = {
  expanded: boolean;
  getButtonProps: () => ExpandButtonProps;
  getContentProps: () => {
    id: string;
    "aria-hidden": boolean;
  };
  toggle: (...args: Array<any>) => any;
};

type Props = {
  /**
   * A function that receives the described props object and
   * renders a React node
   */
  children: (props: ExpandableProps) => React.ReactNode;

  /**
   * If `true`, the content will be expanded initially by default
   */
  defaultExpanded?: boolean;

  /**
   * An HTML `id` attribute.  Used for accessibility
   */
  id: string;
};

/**
 *
 * A "headless" component for managing the state of expandable/collapsble components
 */
export default class Expandable extends React.Component<Props, any> {
  state = {
    expanded:
      this.props.defaultExpanded !== undefined
        ? this.props.defaultExpanded
        : false,
  };

  toggle = () => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
    this.setState((state) => ({
      ...state,
      expanded: !state.expanded,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  getButtonProps = (props?: {}) => {
    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    return {
      type: "button",
      "aria-expanded": this.state.expanded,
      "aria-controls": this.props.id,
      id: `expandable-control-${this.props.id}`,
      "data-automation-id": "expand-button",
      onClick: this.toggle,
      ...props,
    };
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  getContentProps = (props?: {}) => {
    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    return {
      id: this.props.id,
      "aria-hidden": !this.state.expanded,
      ...props,
    };
  };

  getProps = () => {
    return {
      ...this.state,
      getButtonProps: this.getButtonProps,
      getContentProps: this.getContentProps,
      toggle: this.toggle,
    };
  };

  render() {
    const { children } = this.props;

    // @ts-expect-error migration
    return children(this.getProps());
  }
}
