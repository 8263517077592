import generate from "nanoid/generate";

export function ensureStartsWithAlpha(string: string) {
  const validString = ensureIncludesAcceptedCharacters(string);
  return /^[a-z]/.test(validString) ? validString : `dr_${validString}`;
}

/**
 * ID string to append into the form id. Alphanumeric.
 */
export const shortid = () =>
  generate("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 6);

function ensureIncludesAcceptedCharacters(string: string) {
  return string
    .toString()
    .replace(/[^0-9a-zA-Z_]/g, "")
    .trim();
}

/**
 * It determines the maximum numbers of characters an area can contain, using an helvetica font
 */
export function maxCharsInArea(dimensions: {
  width: number;
  height: number;
  fontSize?: number;
}) {
  const { width, height, fontSize = 14 } = dimensions;

  // This is a ratio specific for helvetica font
  const ratio = 0.52;

  const lines = Math.floor(height / fontSize) || 1;
  const charsPerLine = Math.floor(width / (fontSize * ratio)) || 1;

  return lines * charsPerLine;
}

/**
 * Function that determines the approximate number of lines to fit a certain number of characters
 * based on the width and font size
 */
export function linesToFitChars(
  chars: number,
  dimensions: { width: number; fontSize: number }
) {
  const { width, fontSize } = dimensions;

  // This is a ratio specific for helvetica font
  const ratio = 0.52;

  const charsPerLine = Math.floor(width / (fontSize * ratio)) || 1;

  return Math.ceil(chars / charsPerLine);
}
