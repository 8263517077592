/**
 * NOTE: Types will be better defined in the `draft-editor-v3` version of this
 * module
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { createLogger } from "hw/common/utils/logger";
import mapTree from "./map-tree";

const logger = createLogger("rich-text-renderer");

type Props = {
  data: Record<string, string>;
  markHandlers: Record<string, React.ComponentType<any>>;
  nodeHandlers: Record<string, React.ComponentType<any>>;
  node: any;
};

/**
 * Renders the entire rich text document
 */
function Doc(props: Props) {
  const children = (props.node.content || []).map((child: any) =>
    mapTree(child, props)
  );

  return React.createElement("article", {}, ...children);
}

function Data(props: Props) {
  const { node, data = {} } = props;
  const { ref } = node.attrs;
  const value = data[ref];

  if (value == null || Number.isNaN(value)) {
    logger.debug(`No value for text variable "${ref}"`);

    return null;
  }

  const textNode = {
    ...node,
    text: value,
  };

  // Use the `Text` handler here so that data refs are wrapped in marks if
  // they are present
  return <Text {...props} node={textNode} />;
}

function Text(props: Props) {
  const { node, markHandlers } = props;
  const marks = node.marks || [];

  return marks.reduceRight(function reduceMark(child: any, mark: any) {
    const markHandler = markHandlers[mark.type];

    if (!markHandler) {
      throw new Error(`No handler for mark type ${mark.type}`);
    }

    return React.createElement(markHandler, mark.attrs, child);
  }, node.text);
}

function Heading(props: Props) {
  const { node } = props;
  const { level } = node.attrs;
  const children =
    node.content && node.content.map((child: any) => mapTree(child, props));

  const H = `h${level}`;

  return children
    ? React.createElement(H, {}, ...children)
    : React.createElement(H);
}

export default {
  doc: Doc,
  text: Text,
  paragraph: "p",
  data: Data,
  heading: Heading,
  bulletList: "ul",
  orderedList: "ol",
  listItem: "li",
  hardBreak: "br",
};
