import * as React from "react";
import "./container.css";

type Props = {
  children: React.ReactNode;
};

/**
 * Wraps rich text content and applies the appropriate styles
 */
export default function RichTextContainer({ children }: Props) {
  return (
    <div className="rich-text-container" data-qa-ref="rich-text-container">
      {children}
    </div>
  );
}
