import * as React from "react";
import type { IRenderer } from "fela";
import { RendererProvider as Provider, ThemeProvider } from "react-fela";
import defaultTheme from "hw/ui/theme";
import createRenderer from "./create-renderer";

const defaultRenderer = createRenderer();

type Props = {
  children?: React.ReactNode;
  renderer: IRenderer;
  theme: typeof defaultTheme;
};

/**
 * The style provider component providers the style renderer and theme to all
 * other components in the library. It _must_ be set at the root level of your
 * app as part of the initial setup.
 *
 * @example
 * import { StyleProvider } from 'hw/ui/style';
 *
 * const theme = {
 *   colors: {
 *     primary: 'blue'
 *   }
 * };
 *
 * function MyApp() {
 *   return <StyleProvider theme={theme}>
 *     <div>...</div>
 *   </StyleProvider>;
 * }
 */
export class StyleProvider extends React.Component<Props> {
  theme: typeof defaultTheme;

  static defaultProps = {
    renderer: defaultRenderer,
    theme: defaultTheme,
  };

  constructor(props: Props) {
    super(props);

    this.theme = {
      ...defaultTheme,
      ...this.props.theme,
    };
  }

  render() {
    const { children, renderer } = this.props;

    return (
      <Provider renderer={renderer}>
        <ThemeProvider theme={this.theme}>
          {React.Children.only(children)}
        </ThemeProvider>
      </Provider>
    );
  }
}

export default StyleProvider;
