/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import logger from "hw/common/utils/logger";
import Api from "./api";
import ShareableLink from "./shareable-link";
import type { ApiInput, StartWorkflowResponse } from "./types";

type Props = {
  guid: string;
  name: string;
  privacyPolicyUrl: string;
  roles: Array<{ id: string; label: string }>;
  token: string;
  teamName: string;
  mergeFields: Array<{ data_ref: string; label: string }>;
};

/**
 * Shareable Link Container
 *
 * Responsible for calling the API service and returning a promise of the results
 */
export default class ShareableLinkContainer extends React.Component<Props> {
  op:
    | {
        name: string;
        cancel: (...args: Array<any>) => any;
      }
    | null
    | undefined = null;

  api = Api(this.props.token);

  componentWillUnmount() {
    const cancel = this.op && this.op.cancel;
    if (typeof cancel === "function") {
      cancel();
    }

    this.removeOp("Cancelled");
  }

  addOp = (name: string, cancel: (...args: Array<any>) => any) => {
    // If there is an in-flight request, cancel it.  (edge case)
    if (this.op) {
      this.op.cancel();
    }

    this.op = { name, cancel };

    log("%cAPI Call Start '%s'", "color: grey", name);
  };

  removeOp(reason: string) {
    const op = this.op;

    if (!op) {
      return;
    }

    this.op = null;

    log(
      "%c API Call End  '%s' (%c%s)",
      "color: grey",
      op.name,
      "color: grey; font-style: italic",
      reason
    );
  }

  startWorkflow = (
    guid: string,
    data: ApiInput
  ): Promise<StartWorkflowResponse> => {
    return new Promise((resolve, reject) => {
      const cancel = this.api.startWorkflow(guid, data).fork(
        (err) => {
          this.removeOp("Rejected");
          reject(err);
        },
        (result) => {
          this.removeOp("Succeeded");
          // @ts-expect-error refactor
          resolve(result);
        }
      );

      this.addOp("start workflow", cancel);
    });
  };

  resend = (guid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const cancel = this.api.resend(guid).fork(
        (err) => {
          this.removeOp("Rejected");
          reject(err);
        },
        (result) => {
          this.removeOp("Succeeded");
          resolve(result);
        }
      );

      this.addOp("resend", cancel);
    });
  };

  render() {
    return (
      <ShareableLink
        {...this.props}
        onSubmit={this.startWorkflow}
        onResend={this.resend}
      />
    );
  }
}

// @ts-expect-error refactor
function log(msg, ...rest) {
  if (process.env.NODE_ENV !== "test") {
    logger.log("%cshareable-link: " + msg, "color: lightseagreen", ...rest);
  }
}
