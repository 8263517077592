/**
 * Schema
 *
 * This defines the allowed node types within the paragraph editor
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Schema } from "prosemirror-model";
import type { MarkSpec, NodeSpec } from "prosemirror-model";
import styles from "./components/editor.module.css";

/**
 * Unordered lists
 */
const BulletListNode: NodeSpec = {
  group: "block",
  content: "listItem+",
  parseDOM: [{ tag: "ul" }],
  toDOM() {
    return ["ul", 0];
  },
};

/**
 * A custom node for representing data refs within the paragraph component
 */
const DataNode: NodeSpec = {
  inline: true,
  group: "inline",
  selectable: true,

  // TODO: Make this draggable
  // The drag events here conflict with the drag/drop events in the builder
  draggable: false,
  attrs: {
    ref: { default: "" },
    label: { default: "" },
  },
  parseDOM: [
    {
      tag: "span[data-ref]",
      getAttrs(dom) {
        return {
          // @ts-expect-error refactor
          ref: dom.getAttribute("data-ref") || DataNode.attrs.ref.default,
          // @ts-expect-error refactor
          label: dom.textContent || DataNode.attrs.label.default,
        };
      },
    },
  ],
  toDOM(node: any) {
    const { ref, label } = node.attrs;
    const attrs = {
      "data-ref": ref,
      contenteditable: "false",
      class: styles["data-node"],
    };
    return [
      "span",
      attrs,
      ["span", { class: styles["merge-field-tag"] }, label],
    ];
  },
};

const DocNode: NodeSpec = {
  content: "block+",
};

const HeadingNode: NodeSpec = {
  attrs: { level: { default: 1 } },
  content: "inline*",
  group: "block",
  defining: true,
  parseDOM: [
    { tag: "h1", attrs: { level: 1 } },
    { tag: "h2", attrs: { level: 2 } },
  ],
  toDOM(node: any) {
    return ["h" + node.attrs.level, 0];
  },
};

const ListItemNode: NodeSpec = {
  parseDOM: [{ tag: "li" }],
  toDOM() {
    return ["li", 0];
  },

  content: "paragraph block*",
  defining: true,
};

const OrderedListNode: NodeSpec = {
  group: "block",
  content: "listItem+",
  attrs: {
    order: {
      default: 1,
    },
  },
  parseDOM: [
    {
      tag: "ol",
      getAttrs(dom) {
        // @ts-expect-error refactor
        const order = dom.hasAttribute("start")
          ? // @ts-expect-error refactor
            parseInt(dom.getAttribute("start"), 10)
          : 1;

        return {
          order,
        };
      },
    },
  ],
  toDOM(node: any) {
    return node.attrs.order === 1
      ? ["ol", 0]
      : ["ol", { start: node.attrs.order }, 0];
  },
};

const ParagraphNode: NodeSpec = {
  content: "inline*",
  group: "block",
  parseDOM: [{ tag: "p" }],

  toDOM() {
    return ["p", 0];
  },
};

const TextNode = {
  group: "inline",
};

const HardBreakNode: NodeSpec = {
  inline: true,
  group: "inline",
  selectable: false,
  parseDOM: [{ tag: "br" }],
  toDOM() {
    return ["br"];
  },
};

const LinkMark: MarkSpec = {
  group: "link",
  attrs: {
    href: {},
    title: { default: null },
  },
  inclusive: false,
  parseDOM: [
    {
      tag: "a[href]",
      getAttrs(dom) {
        return {
          // @ts-expect-error refactor
          href: dom.getAttribute("href"),
          // @ts-expect-error refactor
          title: dom.getAttribute("title"),
        };
      },
    },
  ],
  toDOM(node: any) {
    return ["a", node.attrs, 0];
  },
};

const StrongMark: MarkSpec = {
  toDOM() {
    return ["strong", 0];
  },
  parseDOM: [
    { tag: "strong" },
    // This works around a Google Docs misbehavior where
    // pasted content will be inexplicably wrapped in `<b>`
    // tags with a font-weight normal.
    {
      tag: "b",
      // @ts-expect-error refactor
      getAttrs: (node) => node.style.fontWeight !== "normal" && null,
    },
    {
      style: "font-weight",
      getAttrs: (value: any) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
    },
  ],
};

const EmMark: MarkSpec = {
  parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
  toDOM() {
    return ["em", 0];
  },
};

const StrikeMark: MarkSpec = {
  inclusive: true,
  parseDOM: [
    { tag: "strike" },
    { tag: "s" },
    { tag: "del" },
    {
      style: "text-decoration",
      getAttrs: (value: any) => value === "line-through" && null,
    },
  ],
  toDOM(): [string] {
    return ["s"];
  },
};

const UnderlineMark = {
  inclusive: true,
  parseDOM: [
    { tag: "u" },
    {
      style: "text-decoration",
      getAttrs: (value: any) => value === "underline" && null,
    },
  ],
  toDOM(): [string] {
    return ["u"];
  },
};

const MergeFieldQueryMark: MarkSpec = {
  excludes: "searchQuery",
  inclusive: true,
  inline: true,
  group: "searchQuery",
  parseDOM: [{ tag: "span[data-merge-field-query]" }],

  // @ts-expect-error refactor
  toDOM(node) {
    return [
      "span",
      {
        "data-merge-field-query": true,
        "data-active": node.attrs.active,
      },
    ];
  },
  attrs: {
    active: {
      default: true,
    },
  },
};

const ContentBlockSchema = new Schema({
  // NOTE: Order is important here.
  // This is parsed as an OrderedMap and determines the precendence of how
  // values are pared
  nodes: {
    doc: DocNode,
    paragraph: ParagraphNode,
    text: TextNode,
    heading: HeadingNode,
    bulletList: BulletListNode,
    orderedList: OrderedListNode,
    listItem: ListItemNode,
    data: DataNode,
    hardBreak: HardBreakNode,
  },
  marks: {
    link: LinkMark,
    em: EmMark,
    strong: StrongMark,
    strike: StrikeMark,
    underline: UnderlineMark,
    mergeFieldQuery: MergeFieldQueryMark,
  },
});

export default ContentBlockSchema;
