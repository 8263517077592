// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import Select from "hw/ui/select";
import type { PresentationProps } from "../types";

type Props = PresentationProps;

/**
 * Multiple Choice - Dropdown presentation
 */
export function MultipleChoiceDropdown(props: Props) {
  const {
    value,
    options,
    onSelect,
    disabled,
    qaRef,
    id,
    placeholder,
    minWidth,
  } = props;

  return (
    <Select
      native
      disabled={disabled}
      onChange={(e) => onSelect(e.currentTarget.value)}
      selectedItem={value}
      data-qa-ref={qaRef}
      id={id}
      role="presentation"
      placeholder={placeholder}
      minWidth={minWidth}
    >
      {options.map((opt, index) => (
        <option key={`${opt}-${index}`} value={opt}>
          {opt}
        </option>
      ))}
    </Select>
  );
}

export default MultipleChoiceDropdown;
