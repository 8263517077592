import React from "react";
import { Box } from "hw/ui/blocks";

const style = {
  width: 1,
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export function Table(props) {
  return <Box {...style} {...props} as="table" />;
}

export default Table;
