/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import cx from "classnames";
import { Transition } from "react-transition-group";
import IconButton, { CloseIcon } from "hw/ui/icons";
import styles from "./toast.module.css";

type Props = {
  message: string; // Supports react components/HTML
  removeMessage: (...args: Array<any>) => any; // Will remove the message from the toast wrapper
  dismissible: boolean; // Is there a clickable x to remove this message
  timeOut: number; // The message timeout, if less than 0 it is infinite
};

function defaultTimeout() {
  if (process.env.NODE_ENV === "test") {
    return 1000;
  }

  return 5000;
}

/**
 * Wrapper for all messages to be put in toast, supports a self destruct timer and manual destruction. Coupled to toast.
 *
 * Messages with no icons should include their own left margin of 8px for a total of 24px. Because of timers and
 * useEffect the properties should be treated as immutable, doing otherwise will introduce bugs.
 *
 * @constructor
 */
export default function Message(props: Props) {
  const [removing, setRemoving] = React.useState(false);
  const {
    message,
    removeMessage,
    dismissible,
    timeOut = defaultTimeout(),
  } = props;
  const classNames = cx(styles.message, dismissible && styles.dismissible);

  const autoRemoveTimeout = React.useRef();
  const transitionTimer = 1000;

  const onClick = () => {
    setRemoving(true);
    if (autoRemoveTimeout.current !== null) {
      clearTimeout(autoRemoveTimeout.current);
    }
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  /**
   * Should only run on mount and unmount, but if somehow timeout does change it will rerun.
   */
  React.useEffect(() => {
    if (timeOut > 0) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'undefined... Remove this comment to see the full error message
      autoRemoveTimeout.current = setTimeout(
        () => setRemoving(true),
        timeOut - transitionTimer
      );
      return () => clearTimeout(autoRemoveTimeout.current);
    }
  }, [timeOut]);

  const transitionState = !removing;

  return (
    <Transition
      in={transitionState}
      timeout={transitionTimer}
      onExited={removeMessage}
      unmountOnExit={true}
    >
      {(state) => (
        <div
          className={styles.messageContainer}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          style={{ ...transitionStyles[state] }}
        >
          <div className={classNames}>
            <div>{message}</div>
            {dismissible && (
              <IconButton
                className={styles.closeButton}
                onClick={onClick}
                extend={{ margin: "0 -12px 0 0" }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </Transition>
  );
}
