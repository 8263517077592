import pipe from "lodash/flow";
import type { Dispatch } from "redux";
import type { State, Evt, ResizeOrigin, Field, PageRect } from "./types";
import {
  ensureInPage,
  assign,
  bestEffortNudge,
  expandRect,
  resize,
} from "./utils";
import { originStyle } from "./document-mapper";
import { SloppyClickThreshold } from "./constants";

const primaryButton = 0;

export { queryField };

/**
 * Attrs to be applied to DOM elements. These are for various DOM operations in
 * the component. These are meant to be semi-unique to avoid clashing, but are
 * not guaranteed to be unique
 */
export const ns = (str: string) => `data-dm-${str}`;

export const attrs = {
  field: ns("field"),
  forField(id: string) {
    return {
      [attrs.field]: id,
    };
  },
  page: ns("page"),
  forPage(n: number) {
    return {
      [attrs.page]: n,
    };
  },
  handle: ns("handle"),
  handleOrigin: ns("origin"),
  forHandle(fieldId: string, origin: ResizeOrigin) {
    return {
      [attrs.handle]: fieldId,
      [attrs.handleOrigin]: origin,
    };
  },
};

/**
 * Selector values used to query the elements from the DOM
 */
const selectors = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  field(id) {
    // Replace `"` and `\` with their escape versions to make sure we create a
    // valid query selector
    if (id) return `[${attrs.field}="${id.replace(/["\\]/g, "\\$&")}"]`;
    return `[${attrs.field}]`;
  },
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
  page(number) {
    if (number) return `[${attrs.page}="${number}"]`;
    return `[${attrs.page}]`;
  },
};

/**
 * Sets the root cursor based on the current state. This is useful when
 * dealing with mouse movements where the mouse may temporarily slide off
 * the element and cause the cursor to change, such as resize handles.
 */
export function setCursorForState(state: State) {
  switch (state.value) {
    case "move.dragging":
      return setCursor("grabbing");
    case "move.resizing": {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'cursor' does not exist on type '{ positi... Remove this comment to see the full error message
      const { cursor } = originStyle(state.resizeOrigin);
      return setCursor(cursor);
    }
    default:
      return setCursor("");
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'cursorStyle' implicitly has an 'any' ty... Remove this comment to see the full error message
function setCursor(cursorStyle) {
  const docEl = document.documentElement;

  if (!docEl) return;

  const cursorProp = `--${ns("cursor")}`;

  if (cursorStyle) {
    docEl.style.cursor = cursorStyle;
    docEl.style.setProperty(cursorProp, cursorStyle);
  } else {
    docEl.style.cursor = "";
    docEl.style.removeProperty(cursorProp);
  }
}

/**
 * Bind the DOM events for the current state value.
 *
 * For each indivdual state value, there are various DOM events listeners
 * in place at the top level. These are implemented here instead of on individual
 * elements for a few reasons:
 *
 *   - Having all of them here together is easier to manage
 *   - Some events need to be attached to the top level. For example, when
 *     resizing, we want to listen for `mousemove` on the `window` instead of
 *     on a resize handle because the resize handle is very small and the m
 *     mouse could slip off and stop our resie
 *
 * For the most part, logic should be delegated to the reducer with the exception
 * of external events meant for the consumer. Those events need to package all
 * info necessary for the consumer to make the necessary state updates because
 * these events are not handled by the reducer. In other exception cases, it's
 * easier to handle logic here to avoid clashing with various events and
 * propagation (e.g. `mouseup` vs. `click`).
 */
export function bindStateEvents(state: State, send: Dispatch<Evt>) {
  switch (state.value) {
    case "move.idle":
      return bindIdleMoveEvents(state, send);
    case "move.dragging":
      return bindMoveDraggingEvents(state, send);
    case "move.resizing":
      return bindMoveResizingEvents(state, send);
    default:
      break;
  }
}

/**
 * Binds the DOM events necessary for the `move.idle` state
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function bindIdleMoveEvents(state, send) {
  const bindings = [];
  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'unbindFns' implicitly has type 'any[]' i... Remove this comment to see the full error message
  const unbindFns = [];

  bindings.push(
    /**
     * When a `click` event occurs:
     *
     *   - If the element clicked was a field, select it
     *   - If the element clicked was a page, clear the selection
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    evts.click((evt) => {
      const target = getTarget(evt.target);
      if (!target) return;

      if (target.type === "field") {
        send({
          type: "field.selected",
          id: target.id,
        });
      } else if (target.type === "page") {
        send({
          type: "selection.cleared",
        });
      }
    }),

    /**
     * When a `mousedown` event occurs:
     *
     *   - If the target is a field, wait for a `mousemove` event and then
     *     transition to the dragging state
     *   - If the target is a resize handle, wait for a `mouseove` event and
     *   then transition to the resizing state
     *
     * In either case, we want to wait for a `mousemove` after a `mousedown` to
     * make sure users are dragging and not clicking.
     *
     *   - `mousedown` then `mousemove = dragging
     *   - `mousedown` then `mouseup` = click
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    evts.mousedown((evt) => {
      // For now, don't do anything here if any special characters are present
      // NOTE: Would likely do a shift+drag here for a clone operation
      if (evt.shiftKey || evt.ctrlKey || evt.metaKey || evt.altKey) {
        return;
      }

      const target = getTarget(evt.target);
      if (!target) return;

      // Stash the initial mousedown coords so we can compare later
      const initialCoords = {
        x: evt.clientX,
        y: evt.clientY,
      };

      if (target.type === "field") {
        unbindFns.push(
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
          bindEvents(window, [
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
            evts.mousemove((evt) => {
              const coords = {
                x: evt.clientX,
                y: evt.clientY,
              };

              if (sloppyClickThresholdExceeded(initialCoords, coords)) {
                evt.preventDefault();
                preventNextClick();

                // We've exceed the click threshold and are trying to drag
                send({
                  type: "drag.init",
                  id: target.id,
                  x: coords.x,
                  y: coords.y,
                });
              }
            }),
          ])
        );
      } else if (target.type === "handle") {
        unbindFns.push(
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
          bindEvents(window, [
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
            evts.mousemove((evt) => {
              const coords = {
                x: evt.clientX,
                y: evt.clientY,
              };

              if (sloppyClickThresholdExceeded(initialCoords, coords)) {
                evt.preventDefault();
                preventNextClick();
                // We've exceed the click threshold and are trying to drag
                send({
                  type: "resize.init",
                  id: target.id,
                  x: coords.x,
                  y: coords.y,
                  origin: target.origin,
                });
              }
            }),
          ])
        );
      }
    }),

    /**
     * Handle keydown events.
     *
     *   - If a field is focused, use the arrow keys to nudge it
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    evts.keydown((evt) => {
      switch (evt.key) {
        case "ArrowUp":
        case "ArrowLeft":
        case "ArrowRight":
        case "ArrowDown": {
          const activeElement = document.activeElement;

          if (activeElement) {
            const target = getTarget(activeElement);
            if (!target) return;

            if (target.type === "field") {
              evt.preventDefault();
              // Send the external event for the responder
              send({
                type: "external.move",
                result: createShiftResult(state, {
                  key: evt.key,
                  extra: evt.shiftKey,
                  target,
                }),
              });
            } else if (target.type === "handle") {
              evt.preventDefault();

              send({
                type: "external.resize",
                result: createResizeShiftResult(state, {
                  key: evt.key,
                  extra: evt.shiftKey,
                  target,
                }),
              });
            }
          }
          break;
        }
        case "Backspace":
        case "Delete": {
          const activeElement = document.activeElement;

          if (activeElement) {
            const target = getTarget(activeElement);
            if (!target) return;

            if (target.type === "field") {
              evt.preventDefault();
              // Send the external event for the responder
              send({
                type: "external.delete",
                result: target.id,
              });
            }
          }
          break;
        }
        default:
          break;
      }
    })
  );

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  unbindFns.push(bindEvents(window, bindings));
  return function unbindAll() {
    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'unbindFns' implicitly has an 'any[]' typ... Remove this comment to see the full error message
    unbindFns.forEach((fn) => fn());
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function bindMoveDraggingEvents(state, send) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  return bindEvents(window, [
    /**
     * Update dragging coords with each mouse move
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    evts.mousemove((evt) => {
      evt.preventDefault();
      send({
        type: "drag.step",
        x: evt.clientX,
        y: evt.clientY,
      });
    }),

    /**
     * On `mouseup` send the drop event to reset the internal state and then
     * send an additional `external.move` event so that consumers can update
     * their state
     *
     * NOTE: Order is important here. We want to get the result before we
     * update any internal state
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_evt' implicitly has an 'any' type.
    evts.mouseup((_evt) => {
      // Send the external event for consumer
      send({
        type: "external.move",
        result: createDropResult(state),
      });

      // Send the drop to reset the internal state
      send({
        type: "drag.drop",
      });
    }),

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_evt' implicitly has an 'any' type.
    evts.escapeKey((_evt) => {
      send({
        type: "cancel",
      });
    }),
  ]);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function bindMoveResizingEvents(state, send) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  return bindEvents(window, [
    /**
     * Update resizing coords with each mouse move
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    evts.mousemove((evt) => {
      evt.preventDefault();
      send({
        type: "resize.step",
        x: evt.clientX,
        y: evt.clientY,
      });
    }),

    /**
     * On `mouseup` send the `resize.stop` event to reset the internal state and then
     * send an additional `external.resize` event so that consumers can update
     * their state
     */
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_evt' implicitly has an 'any' type.
    evts.mouseup((_evt) => {
      // NOTE: Order matters! This has to be calculated first before the
      // state changes
      // Send the external event for consumer
      send({
        type: "external.resize",
        result: createResizeResult(state),
      });

      // Send the drop to reset the internal state
      send({
        type: "resize.done",
      });
    }),

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_evt' implicitly has an 'any' type.
    evts.escapeKey((_evt) => {
      send({
        type: "cancel",
      });
    }),
  ]);
}

/**
 * Convenience functions for pre-built bindings
 */
const evts = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
  escapeKey: (fn) =>
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    evts.keydown((evt) => {
      if (evt.key === "Escape") {
        fn(evt);
      }
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
  click: (fn) => ({
    eventName: "click",
    fn,
  }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
  keydown: (fn) => ({
    eventName: "keydown",
    fn,
  }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
  mousedown: (fn) => ({
    eventName: "mousedown",
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
    fn: (evt) => {
      if (evt.button !== primaryButton) return;
      return fn(evt);
    },
  }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
  mouseup: (fn) => ({
    eventName: "mouseup",
    fn,
  }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
  mousemove: (fn) => ({
    eventName: "mousemove",
    fn,
  }),
};

/**
 * -- Responders --
 *
 * These functions create the results that are sent to the consumers.
 */

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function createDropResult(state) {
  const { dragging } = state;

  const result = {};

  for (const id of dragging) {
    const dropResult = dropField(state, id);

    if (dropResult) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[id] = dropResult;
    }
  }

  return result;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function dropField(state, id): Field | null | undefined {
  const { fields, scale } = state;
  const field = fields[id];

  // This could potentially happen if a field was unregistered during a
  // drag op.
  if (!field) return;
  // Find the element at the point that the field was dropped.
  // NOTE: This will be scaled
  const fieldEl = queryField(id);

  if (!fieldEl) return;

  const rect = fieldEl.getBoundingClientRect();

  // Attempt to find the closest page based on the field rect
  const closestPage = findClosestPage(rect);

  if (!closestPage) return;

  const number = closestPage.getAttribute(attrs.page);

  // Flow wants this
  if (!number) return;

  const page = state.pages[number];

  if (!page) return;

  // Flow doesn't understand `Object.values` for some reason
  const allFields = Object.keys(fields).map((id) => fields[id]);

  // Make the `left` and `top` values of the rect relative to the top left of the
  // closest page we just found
  const { left, top } = relativeToPage(rect, closestPage);

  // Scale back the x and y values before storing back in the field
  const x = left / scale;
  const y = top / scale;

  return pipe(
    // Move the field to those new coordinates
    // Make sure `page` is a number and not a string
    (f) => assign(f, { x, y, page: Number(number) }), // Attempt to nudge the field if it's overlapping by a small amount
    (f) => bestEffortNudge(f, allFields), // Ensure the new field fits in the page bounds
    (f) => ensureInPage(f, page)
  )(field);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function createResizeResult(state) {
  const { resizing } = state;
  const result = {};

  for (const id of resizing) {
    const resizedField = resizeField(state, id);

    if (resizedField) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[id] = resizedField;
    }
  }

  return result;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function resizeField(state, id): Field | null | undefined {
  const { scale } = state;
  const field = state.fields[id];
  const number = field.page;
  const page = state.pages[String(number)];

  // This could potentially happen if a field was unregistered during a
  // drag op.
  if (!(field && page)) return;

  // Find the element at the point that the field was dropped.
  // NOTE: This will be scaled
  const fieldEl = queryField(id);
  if (!fieldEl) return;

  const rect = fieldEl.getBoundingClientRect();
  const pageEl = queryPage(number);

  if (!pageEl) return;

  // Make the `left` and `top` values of the rect relative to the top left of the
  // closest page we just found
  const { left, top } = relativeToPage(rect, pageEl);

  // Make sure all props are scaled back to their original values
  const x = left / scale;
  const y = top / scale;
  const width = rect.width / scale;
  const height = rect.height / scale;

  return pipe(
    (f) =>
      assign(f, {
        x,
        y,
        width,
        height,
      }),
    // Ensure the new field fits in the page bounds
    (f) => ensureInPage(f, page, "dims")
  )(field);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function createShiftResult(state, { key, extra, target }) {
  const { scale, fields } = state;
  const result = {};
  const shift = createShift(key, extra, scale);
  const field = fields[target.id];
  const page = state.pages[String(field?.page)];

  if (field && shift && page) {
    const moved: Field = pipe(
      (f) =>
        assign(f, {
          x: field.x + shift.dx,
          y: field.y + shift.dy,
        }),
      // Ensure the new field fits in the page bounds
      (f) => ensureInPage(f, page)
    )(field);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[field.id] = moved;
  }

  return result;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function createResizeShiftResult(state, { key, extra, target }) {
  const { scale, fields } = state;
  const result = {};
  const shift = createShift(key, extra, scale);
  const field = fields[target.id];
  const page = state.pages[String(field?.page)];
  const { origin } = target;

  if (field && shift && page) {
    const moved: Field = pipe(
      (f) => resize(f, origin, shift.dx, shift.dy),
      // Ensure the new field fits in the page bounds
      (f) => ensureInPage(f, page, "dims")
    )(field);

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[field.id] = moved;
  }

  return result;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
function createShift(key, extra, scale) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const baseShift = shiftsForKey[key];

  if (!baseShift) return;

  let { dx, dy } = baseShift;

  if (extra) {
    dx *= 10;
    dy *= 10;
  }

  return {
    dx: dx / scale,
    dy: dy / scale,
  };
}

const shiftsForKey = {
  ArrowDown: {
    dx: 0,
    dy: 1,
  },
  ArrowUp: {
    dx: 0,
    dy: -1,
  },
  ArrowRight: {
    dx: 1,
    dy: 0,
  },
  ArrowLeft: {
    dx: -1,
    dy: 0,
  },
};

/**
 * -- Utils --
 */

/**
 * Finds the field in the DOM given its `id`
 */
function queryField(id: string) {
  return document.querySelector(selectors.field(id));
}

/**
 * Finds all pags in the DOM
 */
function queryPages() {
  // @ts-expect-error ts-migrate(2550) FIXME: Property 'from' does not exist on type 'ArrayConst... Remove this comment to see the full error message
  return Array.from(document.querySelectorAll(selectors.page()));
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
function queryPage(number) {
  return document.querySelector(selectors.page(number));
}

/**
 * Returns the potential target need for an option based on the `event.target`
 * value.
 *
 * NOTE: Order does matter here as some elements may be nested
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'target' implicitly has an 'any' type.
function getTarget(target) {
  if (target.hasAttribute(attrs.handle)) {
    const id = target.getAttribute(attrs.handle);
    const origin = getResizeOrigin(target);

    if (id && origin) {
      return {
        type: "handle",
        id,
        origin,
      };
    }
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  const field = target.closest(selectors.field());

  if (field) {
    const id = field.getAttribute(attrs.field);

    if (id) {
      return {
        type: "field",
        id,
        el: field,
      };
    }
  }

  if (target.hasAttribute(attrs.page)) {
    return {
      type: "page",
      number: target.getAttribute(attrs.page),
    };
  }
}

// For flow...
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'el' implicitly has an 'any' type.
function getResizeOrigin(el) {
  const origin = el.getAttribute(attrs.handleOrigin);
  if (origin === "n") return "n";
  if (origin === "s") return "s";
  if (origin === "e") return "e";
  if (origin === "w") return "w";
  if (origin === "nw") return "nw";
  if (origin === "sw") return "sw";
  if (origin === "ne") return "ne";
  if (origin === "se") return "se";
}

/**
 * Helper for binding DOM events. Returns a function to unbind the events
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'el' implicitly has an 'any' type.
function bindEvents(el, bindings, sharedOptions) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'binding' implicitly has an 'any' type.
  const unbindings = bindings.map((binding) => {
    const options = {
      ...sharedOptions,
      ...(binding.options ? binding.options : {}),
    };

    el.addEventListener(binding.eventName, binding.fn, options);

    return function unbind() {
      el.removeEventListener(binding.eventName, binding.fn, options);
    };
  });

  // Return a function to unbind events
  return function unbindAll() {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'unbind' implicitly has an 'any' type.
    unbindings.forEach((unbind) => {
      unbind();
    });
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
function sloppyClickThresholdExceeded(a, b) {
  return (
    Math.abs(a.x - b.x) >= SloppyClickThreshold ||
    Math.abs(a.y - b.y) >= SloppyClickThreshold
  );
}

/**
 * Does a quick binding to capture clicks and then immediately unbinds. Prevents
 * triggering both a `click` and `mouseup` event that might clash
 */
function preventNextClick() {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  const unbind = bindEvents(window, [
    {
      eventName: "click",
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
      fn: (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      },
      options: {
        capture: true,
        once: true,
      },
    },
  ]);

  setTimeout(unbind);
}

/**
 * -- `ClientRect` utils --
 */

/**
 * Returns `true` if the two rects are intersecting
 */
function intersects(a: DOMRect, b: DOMRect, mode: "touch" | "center") {
  switch (mode || "touch") {
    // `true` if the rects are touching at all
    case "touch": {
      return (
        a.right >= b.left &&
        a.left <= b.right &&
        a.bottom >= b.top &&
        a.top <= b.bottom
      );
    }

    // `true` if the center of `b` is within `a`
    case "center": {
      const byc = Math.floor(b.top + b.height / 2);
      const bxc = Math.floor(b.left + b.width / 2);

      return bxc >= a.left && bxc <= a.right && byc >= a.top && byc <= a.bottom;
    }
    default:
      throw new Error(`Unknown intersection mode: ${mode}`);
  }
}

/**
 * Returns the `x` and `y` of the given rect relative to the `x` and `y` of
 * the given apge
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rect' implicitly has an 'any' type.
function relativeToPage(rect, page) {
  const pageRect = page.getBoundingClientRect();
  const x = rect.left - pageRect.left;
  const y = rect.top - pageRect.top;
  return expandRect({
    x,
    y,
    width: rect.width,
    height: rect.height,
  });
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rect' implicitly has an 'any' type.
function findClosestPage(rect) {
  const pages = queryPages();
  return pages.find((page) => {
    const pageRect = page.getBoundingClientRect();
    return intersects(pageRect, rect, "center");
  });
}

/**
 * This function returns the rect for the most visible page at the time this
 * function was called. It determines the page with the most visible area
 * first and then converts that to coordinates relative to that page for use
 * by the consumer.
 *
 * For example, given two pages:
 *   [ { width: 100, height: 400 }, { width: 100, height: 400 }]
 *
 * If the user has scrolled down so that the bottom 1/4 of page 1 is visible
 * and the top 3/4 of page 2 are visible, this function will return:
 *   {
 *     number: 2,
 *     x: 0,
 *     y: 0,
 *     width: 100, // Assuming the entire page width is in view
 *     height: 300,
 *  }
 */
export function getMostVisiblePageRect(
  scrollContainer: HTMLElement,
  scale: number
): PageRect {
  // The scroll container is the ultimate bounds for the visible area
  const bounds = scrollContainer.getBoundingClientRect();
  const pages = queryPages();

  const rects = pages
    .map((page, idx) => {
      const clientRect = page.getBoundingClientRect();

      // Get the visible area for this page based on the scroll container
      const left = Math.max(bounds.left, clientRect.left);
      const right = Math.min(bounds.right, clientRect.right);
      const width = Math.max(right - left, 0);
      const bottom = Math.min(bounds.bottom, clientRect.bottom);
      const top = Math.max(bounds.top, clientRect.top);
      const height = Math.max(bottom - top, 0);

      const number = idx + 1;
      const visibleRect = expandRect({
        x: (left - clientRect.left) / scale,
        y: (top - clientRect.top) / scale,
        width: width / scale,
        height: height / scale,
      });

      return {
        ...visibleRect,
        number,
      };
    }) // Now sort by the most visible area
    .sort((a, b) => b.width * b.height - a.width * a.height);

  // @ts-expect-error migration
  return rects[0];
}
