import * as React from "react";

export default function Close12() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" fill="white" />
      <path
        d="M9.28106 1.3098L6 4.59087L2.71894 1.3098C1.77952 0.370383 0.370383 1.77952 1.3098 2.71894L4.59087 6L1.3098 9.28106C0.370383 10.2205 1.77952 11.6296 2.71894 10.6902L6 7.40913L9.28106 10.6902C10.2205 11.6296 11.6296 10.2205 10.6902 9.28106L7.40913 6L10.6902 2.71894C11.6296 1.77952 10.2205 0.370383 9.28106 1.3098Z"
        fill="currentColor"
      />
    </svg>
  );
}
