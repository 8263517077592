import * as React from "react";
import { DragSource, DropTarget } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import compose from "lodash/flowRight";
import { getIndicatorPosition } from "../utils";
import { DRAG_TYPE_ORDERABLE_LIST_ITEM } from "../constants";
import Item from "./item";
import type { ItemProps, OrderableListDragItem } from "../types";

type CollectedDropProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectDropTarget: (el: React.ReactElement<any>) => React.ReactNode;
};
type ReactDnDProps = CollectedDropProps & {
  isOver: boolean;
  sourceIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectDragPreview: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectDragSource: (el: React.ReactElement<any>) => React.ReactNode;
  isDragging: boolean;
  isLast: boolean;
  onMove: (arg0: { fromIndex: number; toIndex: number }) => void;
};
type CombinedProps = ReactDnDProps & ItemProps;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentInstance = React.Component<any, any> & {
  node?: HTMLDivElement;
};

/**
 * Drag source contract
 */
const dragSpec = {
  beginDrag(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    props,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_monitor' implicitly has an 'any' type.
    _monitor,
    component: ComponentInstance
  ): OrderableListDragItem {
    // This is not very pretty, but when rendering custom drag previews
    // you have to explicity pass a width otherwise it just renders the
    // width of the entire page.
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | HTMLDivElement' is not assignable to... Remove this comment to see the full error message
    const node: HTMLDivElement =
      /* $FlowFixMe[incompatible-type] $FlowFixMe This comment suppresses an
       * error found when upgrading Flow to v0.132.0. To view the error, delete
       * this comment and run Flow. */
      typeof component.node !== "undefined" && component.node;
    const rect =
      node && typeof node.getBoundingClientRect === "function"
        ? node.getBoundingClientRect()
        : null;
    return {
      index: props.index,
      item: props.item,
      renderItem: props.renderItem,
      width: rect && rect.width,
      height: rect && rect.height,
    };
  },
};

/**
 * Drop source contract
 */
const dropSpec = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  drop(props, monitor) {
    const sourceItem = monitor.getItem();
    const fromIndex = sourceItem.index;
    const toIndex = props.index;
    props.onMove({
      fromIndex,
      toIndex,
    });
  },
};

/**
 * Provides props to the draggable item
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  };
}

/**
 * Provides props to the droppable item
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'connect' implicitly has an 'any' type.
function dropCollect(connect, monitor): CollectedDropProps {
  const source = monitor.getItem();
  return {
    /* $FlowFixMe[incompatible-return] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    connectDropTarget: connect.dropTarget(),
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ connectDropTarget: any; isOver: any; sourc... Remove this comment to see the full error message
    isOver: monitor.isOver(),
    sourceIndex: source ? source.index : -1,
  };
}

/**
 * Provides dragging and dropping functionality to the item.
 */
export class DraggableItem extends React.Component<CombinedProps> {
  node: HTMLDivElement | null | undefined;

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const {
      item,
      index,
      connectDragSource,
      connectDropTarget,
      onRemove,
      isOver,
      isModal,
      sourceIndex,
      isDragging,
      renderItem,
      renderItemMeta,
      meta,
      onMove,
      isLast,
      disallow,
    } = this.props;
    const indicatorPosition =
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
      isOver && sourceIndex !== "undefined"
        ? getIndicatorPosition(sourceIndex, index)
        : null;
    return connectDropTarget(
      <div
        ref={(node) => {
          this.node = node;
        }}
      >
        {/**
         * This is UI that's currently _only_ implemented for the purposes of
         * testing. If dragging is the only way to reorder items in the list, then
         * we have no way to test this functionality within our current framework.
         * Adding a set of test-only move up/down buttons allows us to exercise this
         * behavior. This is likely something we'll want in the actual UI at some point,
         * but since we don't have a design for it I'm leaving it out
         */}
        {process.env.NODE_ENV === "test" && (
          <TestOnlyMoveButtons isLast={isLast} index={index} onMove={onMove} />
        )}
        <Item
          indicatorPosition={indicatorPosition}
          isOver={isOver}
          isModal={isModal}
          index={index}
          connectDragSource={connectDragSource}
          item={item}
          onRemove={onRemove}
          isDragging={isDragging}
          renderItem={renderItem}
          renderItemMeta={renderItemMeta}
          meta={meta}
          disallow={disallow}
        />
      </div>
    );
  }
}

/**
 * Used in tests only. See above
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function TestOnlyMoveButtons(props) {
  const { index, isLast, onMove } = props;
  const isFirst = index === 0;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'direction' implicitly has an 'any' type... Remove this comment to see the full error message
  const move = (direction) => {
    if (direction === "up") {
      onMove({
        fromIndex: index,
        toIndex: index - 1,
      });
    } else {
      onMove({
        fromIndex: index,
        toIndex: index + 1,
      });
    }
  };

  return (
    // Display 'none' for good measure, but this should not be here unless
    // we are running tests
    <div
      style={{
        display: "none",
      }}
    >
      {!isFirst && <button onClick={() => move("up")}>Move up</button>}
      {!isLast && <button onClick={() => move("down")}>Move down</button>}
    </div>
  );
}

export default compose(
  // $FlowFixMe
  DropTarget(DRAG_TYPE_ORDERABLE_LIST_ITEM, dropSpec, dropCollect),
  DragSource(DRAG_TYPE_ORDERABLE_LIST_ITEM, dragSpec, dragCollect)
)(DraggableItem);
