import * as React from "react";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  active?: boolean;
};

export default function Add(props: Props) {
  const cn = useStyle(style(props));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        id="add-24-a"
        d="M11,11 L11,5 C11,3.66666667 13,3.66666667 13,5 L13,11 L19,11 C20.3333333,11 20.3333333,13 19,13 L13,13 L13,19 C13,20.3333333 11,20.3333333 11,19 L11,13 L5,13 C3.66666667,13 3.66666667,11 5,11 L11,11 Z"
      />
    </svg>
  );
}

const style = (props: Props) => ({
  transitionProperty: "transform",
  transitionDuration: "250ms",
  transitionTimingFunction: "ease-in-out",
  transform: props.active && `rotate(45deg)`,
  borderRadius: theme.corner.round,
});
