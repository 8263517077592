import * as React from "react";
import { getIn } from "timm";
import {
  Container,
  ContentWrapper,
  InnerContainer,
  InputContainer,
  ItemWrapper,
  Order,
  PositionIndicator,
  MetaWrapper,
  MetaSideContentWrapper,
} from "./styled";
import RemoveButton from "./remove-button";
import type { ItemProps } from "../types";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
const identity = (i) => i;

type State = {
  removeBtnFocused: boolean;
  hovered: boolean;
};
export default class Item extends React.Component<ItemProps, State> {
  constructor(props: ItemProps) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveBtnBlur = this.handleRemoveBtnBlur.bind(this);
    this.handleRemoveBtnFocus = this.handleRemoveBtnFocus.bind(this);
    this.state = {
      removeBtnFocused: false,
      hovered: false,
    };
  }

  handleRemoveBtnBlur() {
    this.setState({
      removeBtnFocused: false,
    });
  }

  handleRemoveBtnFocus() {
    this.setState({
      removeBtnFocused: true,
    });
  }

  handleMouseEnter() {
    this.setState({
      hovered: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      hovered: false,
    });
  }

  handleRemove() {
    if (typeof this.props.onRemove === "function") {
      this.props.onRemove(this.props.index);
    }
  }

  render() {
    const { removeBtnFocused, hovered } = this.state;
    const {
      connectDragSource = identity,
      index,
      indicatorPosition,
      isDragging,
      isOver,
      isModal,
      item,
      renderItem,
      renderItemMeta,
      meta,
      disallow,
    } = this.props;
    const disabled = getIn(item, ["value", "disabled"]);
    const disabledMessage = getIn(item, ["value", "disabledMessage"]);
    const sideContent = getIn(item, ["value", "metaSideContent"]);
    const allowDelete = disallow ? !disallow.includes("delete") : true;

    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: (... Remove this comment to see the full error message
      <Container data-automation-id={`mf-modal-row-${item?.value?.id}`}>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ position: s... Remove this comment to see the full error message */}
        <PositionIndicator
          position="top"
          active={indicatorPosition === "top"}
        />
        <InnerContainer
          isOver={isOver}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: (Element | null)[]; isOver: bool... Remove this comment to see the full error message
          indicatorPosition={indicatorPosition}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any[]; isModal: boolean | undefi... Remove this comment to see the full error message */}
          <ContentWrapper isModal={isModal}>
            {connectDragSource(
              <div>
                {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: n... Remove this comment to see the full error message */}
                <Order isDragging={isDragging}>{index + 1}</Order>
              </div>
            )}
            <InputContainer>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; compacted: boolean; }' is n... Remove this comment to see the full error message */}
              <ItemWrapper
                compacted={allowDelete && (removeBtnFocused || hovered)}
              >
                {renderItem(item, index)}
              </ItemWrapper>
              <RemoveButton
                visible={allowDelete && (removeBtnFocused || hovered)}
                onClick={this.handleRemove}
                onFocus={this.handleRemoveBtnFocus}
                onBlur={this.handleRemoveBtnBlur}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean | u... Remove this comment to see the full error message
                disabled={disabled}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                disabledMessage={disabledMessage}
              />
            </InputContainer>
          </ContentWrapper>
          {sideContent && (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: n... Remove this comment to see the full error message
            <MetaSideContentWrapper>{sideContent}</MetaSideContentWrapper>
          )}
        </InnerContainer>
        {typeof renderItemMeta === "function" && (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: a... Remove this comment to see the full error message
          <MetaWrapper>{renderItemMeta(item, index, meta)}</MetaWrapper>
        )}
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ position: s... Remove this comment to see the full error message */}
        <PositionIndicator
          position="bottom"
          active={indicatorPosition === "bottom"}
        />
      </Container>
    );
  }
}
