import React from "react";
import { Box } from "hw/ui/blocks";
import { updateIn, getIn, removeAt } from "timm";
import { VisuallyHidden } from "hw/ui/text";
import { createLogger } from "hw/common/utils/logger";
import type { Field } from "hw/portal/modules/common/draft/types";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import type { Rule } from "./types";
import {
  Line,
  Row,
  HighlightedText,
  SelectWrapper,
  Grid,
  SelectorCell,
  ErrorWrapper,
  Error,
} from "./styled";
import Select from "./select";
import Bullet from "./bullet";
import ControlRule from "./control-rule";
import Fallback from "./fallback";
import * as Constants from "./constants";
import * as utils from "./utils";
import * as Validation from "./validation";

type Props = {
  /**
   * The expression to use for rendering the rules
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialExpression: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpToDef?: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: Array<any>) => any;
  disabled?: boolean;

  /**
   * The field to which the rules should be applied
   */
  field: Field;

  /**
   * The list of _all_ available fields in the form
   */
  fields: Array<Field>;
  feedback: Array<DraftFeedback>;

  /**
   * Text to display to users to indicate what will happen if the condition is met
   *
   * NOTE: Only used in v2
   */
  thenText: string;
};
type SupportedExpressionState = {
  supported: true;
  value: {
    globalSelector: "All" | "Any";
    rules: Array<Rule>;
  };
};
type UnsupportedExpressionState = {
  supported: false;
  reason: typeof Error;
};
type State = {
  hovered: null | number;
  btnFocused: null | number;
  expr: SupportedExpressionState | UnsupportedExpressionState;
};

class ExpressionBuilder extends React.Component<Props, State> {
  static defaultProps = {
    thenText: "then show the following components",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      hovered: null,
      btnFocused: null,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ supported: boolean; value: { rules: Rule[]... Remove this comment to see the full error message
      expr: this.tryParseExpression(this.props.initialExpression),
    };

    if (!this.state.expr.supported) {
      this.logger.debug(this.state.expr.reason);
    }
  }

  logger = createLogger(`expression-builder-${this.props.field.id}`);

  /**
   * Because we only support a small subset of expression formats for the
   * rule creator, we need to ensure that we can actually display the given
   * expression.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tryParseExpression(expr: any) {
    try {
      return {
        supported: true,
        value: utils.ExprState.fromExpression(expr),
      };
    } catch (err) {
      return {
        supported: false,
        reason: err,
      };
    }
  }

  /**
   * Returns all available fields in the form in an optimized
   * format -- an object of dataRef -> FieldObject
   */
  getSubjects() {
    const { field, fields } = this.props;
    return utils.validSubjectsByDataRef(fields, field);
  }

  /**
   * Returns a list of only valid fields available for selection in conditions
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValidSubjectOptions(subjectsByDataRef: any) {
    const { disabled } = this.props;

    if (disabled) {
      return [];
    }

    return Object.keys(subjectsByDataRef).reduce((acc, key) => {
      const { id, label, valid } = subjectsByDataRef[key];

      if (valid) {
        acc.push({
          id,
          label,
        });
      }

      return acc;
    }, [] as Array<{ id: string; label: string }>);
  }

  getExpressionOrThrow = () => {
    const { expr } = this.state;

    if (!expr.supported) {
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      throw new Error("Trying to use expression when it's in an invalid state");
    }

    return expr.value;
  };

  updateExpr = (
    path: Array<string | number>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updater: (...args: Array<any>) => any
  ) => {
    const { expr } = this.state;

    if (!expr.supported) {
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      throw new Error(
        "Trying to update expression when it's in an invalid state"
      );
    }

    this.setState(
      (prevState) => updateIn(prevState, ["expr", "value", ...path], updater),
      this.syncRules
    );
  };

  handleSubjectChange = (index: number, dataRef: string) => {
    const subjectsByDataRef = this.getSubjects();
    const expr = this.getExpressionOrThrow();
    const subject = subjectsByDataRef[dataRef];
    const rule = getIn(expr, ["rules", index]);

    if (!subject) {
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      throw new Error(
        `The dataRef selected ${dataRef} doesn't exist in the list of fields`
      );
    }

    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const cleanUp = utils.shouldCleanUpRule(subject.type, rule[1]);

    if (cleanUp) {
      this.updateExpr(["rules", index], () => [dataRef]);
    } else {
      this.updateExpr(["rules", index, 0], () => dataRef);
    }
  };

  handleConditionChange = (
    index: number,
    condition: string,
    options: string[]
  ) => {
    // If the new condition is in the list of conditions that can't have a value,
    // we need to remove it
    const mantainValue = utils.shouldDisplayValue(condition);

    if (mantainValue) {
      // set a default option or initialize to null to prevent an undefined value from being passed
      const defaultOption = options.length > 0 ? options[0] : null;
      this.updateExpr(["rules", index], (rule) => [
        rule[0],
        condition,
        defaultOption,
      ]);
    } else {
      this.updateExpr(["rules", index], (rule) => [rule[0], condition]);
    }
  };

  handleGlobalSelectorChange = (option: { id: "All" | "Any" }) => {
    if (!option) return;
    this.updateExpr(["globalSelector"], () => option.id);
  };

  handleValueChange = (index: number, value: string) => {
    this.updateExpr(["rules", index, 2], () => value);
  };

  handleAddRule = (index: number) => {
    this.updateExpr(["rules"], (rules) => [
      ...rules.slice(0, index),
      Constants.EMPTY_RULE,
      ...rules.slice(index),
    ]);
  };

  handleRemoveRule = (index: number) => {
    this.updateExpr(["rules"], (rules) => removeAt(rules, index));
  };

  handleMouseEnter = (index: number) => {
    this.setState({
      hovered: index,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: null,
      btnFocused: null,
    });
  };

  handleBtnFocus = (index: number) => {
    this.setState({
      btnFocused: index,
    });
  };

  handleBtnBlur = () => {
    this.setState({
      btnFocused: null,
    });
  };

  syncRules = () => {
    if (!this.state.expr.supported) {
      return;
    }

    const newExpression = utils.ExprState.toExpression(this.state.expr.value);
    this.props.onChange(newExpression);
  };

  render() {
    if (this.state.expr.supported === false) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
      return <Fallback jumpToDef={this.props.jumpToDef} />;
    }

    const { disabled, field, thenText } = this.props;
    const expr = this.getExpressionOrThrow();
    const { rules, globalSelector } = expr;
    const subjectsByDataRef = this.getSubjects();
    const { id } = field;
    const feedback = Validation.gatherFeedback(
      this.props.feedback,
      rules,
      subjectsByDataRef
    );
    const { hovered, btnFocused } = this.state;
    const subjectOptions = this.getValidSubjectOptions(subjectsByDataRef);
    return (
      /**
       * Because this is conditionally shown or hidden, apply margin to the
       * top to keep the spacing consistent
       */
      <>
        <GlobalCombinator
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: any; globalSelector: "All" | "Any"; on... Remove this comment to see the full error message
          id={id}
          globalSelector={globalSelector}
          onChange={this.handleGlobalSelectorChange}
          disabled={disabled}
        />
        <Grid>
          {rules.map((rule, idx) => {
            // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
            const subject = subjectsByDataRef[rule[0]];
            const rulePosition = getRulePosition(rules, idx);
            const errors = getIn(feedback, [idx]);
            let selectedItem;

            if (subject) {
              selectedItem = {
                id: rule[0],
                label: subject.label,
              };
            }

            return (
              <Row
                key={idx}
                onMouseEnter={() => this.handleMouseEnter(idx)}
                onMouseLeave={this.handleMouseLeave}
                last={idx === rules.length - 1}
                data-testid="rule"
              >
                <Box display="flex" alignItems="center" className="row-inner">
                  {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ index: number; total: number; }' is not as... Remove this comment to see the full error message */}
                  <BulletRuleForIdx index={idx} total={rules.length} />
                  <SelectorCell>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; id: string; }' is not as... Remove this comment to see the full error message */}
                    <VisuallyHiddenLabel
                      id={`rule-subject-select-${id}-${idx}`}
                    >
                      Select Rule Field
                    </VisuallyHiddenLabel>
                    <Select
                      options={subjectOptions}
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      selectedItem={selectedItem}
                      onChange={(item) => {
                        if (item) this.handleSubjectChange(idx, item.id);
                      }}
                      disabled={disabled}
                      id={`rule-subject-select-${id}-${idx}`}
                      invalid={anyErrorsAtPosition(0, errors)}
                      /** This is a completely arbitrary number to make the value fit the container when the content is small enough.
                  Properly adressing this issue is something that should be done when refactoring select/menu UI components, so there is
                  a more standarized and consistent way to address it */
                      fitToContainer={subject?.label.length < 13}
                    />
                  </SelectorCell>
                  <SelectorCell>
                    {selectedItem && (
                      <>
                        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; id: string; }' is not as... Remove this comment to see the full error message */}
                        <VisuallyHiddenLabel
                          id={`rule-condition-select-${id}-${idx}`}
                        >
                          Select Rule Condition
                        </VisuallyHiddenLabel>
                        <Select
                          key={`condition-${rule[0]}-${idx}`}
                          options={utils.getConditions(subject)}
                          selectedItem={rule[1]}
                          onChange={(item) => {
                            const options = subject.options ?? [];
                            if (item)
                              this.handleConditionChange(
                                idx,
                                item.id,
                                utils.toOptions(options).map((item) => item.id)
                              );
                          }}
                          disabled={disabled}
                          id={`rule-condition-select-${id}-${idx}`}
                          invalid={anyErrorsAtPosition(1, errors)}
                        />
                      </>
                    )}
                  </SelectorCell>
                  <SelectorCell>
                    {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                    {selectedItem && utils.shouldDisplayValue(rule[1]) && (
                      <>
                        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; id: string; }' is not as... Remove this comment to see the full error message */}
                        <VisuallyHiddenLabel
                          id={`rule-value-select-${id}-${idx}`}
                        >
                          Select Rule Value
                        </VisuallyHiddenLabel>
                        <Select
                          key={`value-${rule[0]}-${idx}`}
                          options={utils.toOptions(subject.options)}
                          selectedItem={rule[2]}
                          onChange={(item) => {
                            if (item) this.handleValueChange(idx, item.id);
                          }}
                          disabled={disabled}
                          id={`rule-value-select-${id}-${idx}`}
                          invalid={anyErrorsAtPosition(2, errors)}
                        />
                      </>
                    )}
                  </SelectorCell>
                  <ControlRuleForIdx
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ idx: number; visible: boolean; onFocus: (i... Remove this comment to see the full error message
                    idx={idx}
                    visible={
                      !disabled && (hovered === idx || btnFocused === idx)
                    }
                    onFocus={this.handleBtnFocus}
                    onBlur={this.handleBtnBlur}
                    ruleCount={rules.length}
                    addRule={this.handleAddRule}
                    removeRule={this.handleRemoveRule}
                  />
                </Box>
                {/* @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'. */}
                {Boolean(errors.length) &&
                  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                  errors.map((error, idx) => (
                    <ErrorWrapper key={idx}>
                      <Error>{error.message}</Error>
                    </ErrorWrapper>
                  ))}
                {rules.length > 1 && <Line rulePosition={rulePosition} />}
              </Row>
            );
          })}
        </Grid>
        <HighlightedText disabled={disabled}>{thenText}</HighlightedText>
      </>
    );
  }
}

const VisuallyHiddenLabel = React.memo(function VisuallyHiddenLabel(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ children?:... Remove this comment to see the full error message
  const { id, children } = props;
  return (
    <VisuallyHidden>
      <label htmlFor={id}>{children}</label>
    </VisuallyHidden>
  );
});
const GlobalCombinator = React.memo(function GlobalCombinator(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'disabled' does not exist on type '{ chil... Remove this comment to see the full error message
  const { disabled, globalSelector, onChange, id } = props;
  return (
    <Box mb="ms">
      <HighlightedText disabled={disabled} withDropdown>
        If
        <SelectWrapper>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; id: string; }' is not as... Remove this comment to see the full error message */}
          <VisuallyHiddenLabel id={`rule-${id}-combinator`}>
            Select Rule Conjunction
          </VisuallyHiddenLabel>
          <Select
            options={utils.toOptions(["All", "Any"])}
            selectedItem={globalSelector}
            onChange={onChange}
            disabled={disabled}
            id={`rule-${id}-combinator`}
          />
        </SelectWrapper>
        of the following are true
      </HighlightedText>
    </Box>
  );
});
const ControlRuleForIdx = React.memo(function ControlRuleForIdx(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'visible' does not exist on type '{ child... Remove this comment to see the full error message
  const { visible, idx, onBlur, ruleCount } = props;
  // If you touch this, please fix the lint warning
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'PropsWi... Remove this comment to see the full error message
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFocus = React.useCallback(() => props.onFocus(idx), [idx]);
  // If you touch this, please fix the lint warning
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'addRule' does not exist on type 'PropsWi... Remove this comment to see the full error message
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addRule = React.useCallback(() => props.addRule(idx + 1), [idx]);
  // If you touch this, please fix the lint warning
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'removeRule' does not exist on type 'Prop... Remove this comment to see the full error message
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeRule = React.useCallback(() => props.removeRule(idx), [idx]);
  return (
    <ControlRule
      visible={visible}
      onFocus={onFocus}
      onBlur={onBlur}
      showRemoveBtn={ruleCount > 1}
      addRule={addRule}
      removeRule={removeRule}
    />
  );
});
const BulletRuleForIdx = React.memo(function BulletRuleForIdx(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'index' does not exist on type '{ childre... Remove this comment to see the full error message
  const { index, total } = props;
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ index: any; total: any; }' is not assignab... Remove this comment to see the full error message
  return <Bullet index={index} total={total} />;
});

/**
 * Returns a string value description for the rule position.  This is used to
 * style the left connecting line properly for each rule
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rules' implicitly has an 'any' type.
function getRulePosition(rules, idx) {
  if (idx === rules.length - 1) {
    return "last";
  } else if (idx === 0) {
    return "first";
  }
}

/**
 * Returns `true` if there are any errors at the given index.  Used to highlight
 * a specific dropdown if it contains an error.
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'idx' implicitly has an 'any' type.
function anyErrorsAtPosition(idx, errors) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  return errors.some((error) => error.pos === idx);
}

export default ExpressionBuilder;
