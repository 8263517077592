import * as React from "react";
import { getIn } from "timm";
import { Flex, Box } from "hw/ui/blocks";
import { Factories } from "hw/portal/modules/common/draft";
import type { DataRef, Expression } from "hw/portal/modules/common/draft";
import { Input } from "hw/ui/input";
import { Select, Wrapper, AddButton, RemoveButton } from "./basics";
import type { SettingsProps } from "../../../types";
import { Actions } from "../../../state";

/**
 * Function that returns a boolean whether the string is a valid regex or not
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
const isRegex = (selectedItem) => getIn(selectedItem, ["name"]) === "regex";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'expr' implicitly has an 'any' type.
const extractRegexValue = (expr) => getIn(expr, ["args", 1]);

/**
 * Creates a nested validation expression with option arguments.  For example,
 * creates the long hand version of `E|regex(val(fieldDataRef), 'someregex'))`
 */
function validationExpr(methodName: string) {
  return function validateExprForMethod(
    dataRef: DataRef,
    ...rest: Array<string>
  ) {
    return Factories.Expression(methodName, [
      Factories.Expression("val", [dataRef]),
      ...rest,
    ]);
  };
}

/**
 *List of options for the text input. This should be in a different file
 */
const options = [
  {
    label: "Letters Only",
    name: "lettersOnly",
    value: validationExpr("lettersOnly"),
    defaultMessage: "Can only include letters",
  },
  {
    label: "Numbers Only",
    name: "numbersOnly",
    value: validationExpr("numbersOnly"),
    defaultMessage: "Can only include numbers",
  },
  {
    label: "Letters and Numbers Only",
    name: "lettersAndNumbersOnly",
    value: validationExpr("lettersAndNumbersOnly"),
    defaultMessage: "Can only include letters and numbers",
  },
  {
    label: "Email Address",
    name: "emailAddress",
    value: validationExpr("emailAddress"),
    defaultMessage: "This field can only be an email address",
  },
  {
    label: "Custom Regex",
    name: "regex",
    value: validationExpr("regex"),
    defaultMessage: "Must be valid",
  },
];
type Props = SettingsProps & {
  dataRef: DataRef;
  validators: Array<Expression>;
  disabled?: boolean;
};

/**
 * Settings module. It adds suppot for input validation
 */
export function InputValidation(props: Props) {
  const {
    validators = [],
    dataRef,
    path,
    editorDispatch,
    disabled = false,
  } = props;
  const [open, setOpen] = React.useState(validators.length > 0);
  const [hovered, setHovered] = React.useState(false);
  const [btnFocused, setBtnFocused] = React.useState(false);
  const handleChange = React.useCallback(
    (option) => {
      if (!option) return;
      editorDispatch(
        Actions.ChangeFieldSetting({
          path,
          updater: (field) => {
            return {
              ...field,
              validators: [option.value(dataRef)],
              errors: { ...field.errors, [option.name]: option.defaultMessage },
            };
          },
        })
      );
    },
    [dataRef, editorDispatch, path]
  );
  const handleRegexChange = React.useCallback(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    (selectedItem) => (e) => {
      const value = e.target.value;
      return handleChange({
        ...selectedItem,
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dataRef' implicitly has an 'any' type.
        value: (dataRef) => validationExpr("regex")(dataRef, value),
      });
    },
    [handleChange]
  );
  const handleDelete = React.useCallback(() => {
    editorDispatch(
      Actions.ChangeFieldSetting({
        path: [...path, "validators"],
        updater: () => [],
      })
    );
    setOpen(false);
  }, [editorDispatch, path]);

  /**
   * Find the first option that matches the list of validators on the field.
   */
  const selectedItem = React.useMemo(() => {
    const validatorMethodNames = validators.map((expr) => expr.methodName);
    return options.find((option) => validatorMethodNames.includes(option.name));
  }, [validators]);
  const multipleValidations = validators && validators.length > 1;
  if (multipleValidations) return null;
  return (
    <Wrapper
      data-testid="input-validation-setting"
      label="Input validation"
      id={`${dataRef}-input-validation`}
    >
      {open ? (
        <Flex
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: (false | Element)[]; justifySelf... Remove this comment to see the full error message
          justifySelf="end"
          width={1}
          extend={{
            position: "relative",
            "> :first-child": {
              flexGrow: 1,
            },
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onBlur={() => setBtnFocused(false)}
        >
          <Select
            disabled={disabled}
            placeholder="Select validation..."
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; name: string; value: (dataR... Remove this comment to see the full error message
            selectedItem={selectedItem || null}
            onChange={handleChange}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; name: string; value: (dataR... Remove this comment to see the full error message
            options={options}
            data-test-ref="field-settings-validation-select"
            data-testid="field-settings-validation-select"
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Option'.
            getItemKey={(opt) => opt.name}
            fillContainer
            triggerProps={{
              compacted: true,
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
              // eslint-disable-next-line react/display-name
              renderSelected: (selectedItem) => {
                return (
                  <Flex py="xs" pr="xs">
                    {selectedItem ? selectedItem.label : "Select validation..."}
                  </Flex>
                );
              },
            }}
          />
          {!disabled && (
            <RemoveButton
              onClick={handleDelete}
              onFocus={() => setBtnFocused(true)}
              onBlur={() => setBtnFocused(false)}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | { label: string; name: string; value... Remove this comment to see the full error message
              visible={(btnFocused || hovered) && selectedItem}
            />
          )}
        </Flex>
      ) : (
        <AddButton onClick={() => setOpen(true)} />
      )}
      {selectedItem && isRegex(selectedItem) && (
        <Box
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; w: number; mt: "sm"; ex... Remove this comment to see the full error message
          w={1}
          mt="sm"
          extend={{
            gridColumn: "1 / span 2",
          }}
        >
          <Input
            type="text"
            value={extractRegexValue(validators[0]) || ""}
            onChange={handleRegexChange(selectedItem)}
            placeholder="Regex expression"
            data-testid="field-settings-validators-regex-input"
          />
        </Box>
      )}
    </Wrapper>
  );
}
export default InputValidation;
