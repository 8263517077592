import React from "react";
import Select from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";

type Option = {
  label: string;
  value: string | number;
};
type Props = {
  fillContainer?: boolean;
  getItemKey: (option: Option, index: number) => string;
  options: Array<Option>;
  placeholder: string;
  selectedItem: {
    label: string;
    value: string | number;
  };
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  triggerProps: {};
  disabled?: boolean;
};

function SelectSettings(props: Props) {
  const {
    getItemKey,
    options,
    onChange,
    placeholder,
    selectedItem,
    fillContainer,
    triggerProps,
    disabled = false,
  } = props;
  return (
    /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    <Select
      disabled={disabled}
      fillContainer={fillContainer}
      placeholder={placeholder}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: boolean; fillContainer: boolean ... Remove this comment to see the full error message
      selectedItem={selectedItem}
      onChange={onChange}
      triggerProps={triggerProps}
      render={({ getItemProps, highlightedIndex }) => (
        <MenuList>
          {options.map((option, index) => (
            <MenuItem
              key={getItemKey(option, index)}
              active={index === highlightedIndex}
              passthroughProps={getItemProps({
                item: option,
              })}
            >
              <MenuItemText>{option.label}</MenuItemText>
            </MenuItem>
          ))}
        </MenuList>
      )}
    />
  );
}

SelectSettings.defaultProps = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'opt' implicitly has an 'any' type.
  getItemKey: (opt) => opt.value,
};
export default SelectSettings;
