import * as React from "react";

import theme from "hw/ui/theme";
import { Button } from "./button";
import { getAttributeStyle } from "./style";
import type { Props } from "./button";
import { Extend } from "../style";

type SelectableButtonProps = {
  selected?: boolean;
  extend?: Extend;
  presentation?: Props["presentation"];
};

export default function SelectableButton(props: SelectableButtonProps & Props) {
  return (
    <Button
      {...props}
      presentation={props.presentation || "standard"}
      extend={ExtendButton(props)}
    />
  );
}

function ExtendButton(props: SelectableButtonProps) {
  const { selected, extend, presentation } = props;
  const baseExtendStyles = presentation
    ? {}
    : {
        backgroundColor: selected
          ? theme.color.blue900
          : getDefault("default", "backgroundColor"),
        color: selected ? theme.color.white : getDefault("default", "color"),
        ":hover": {
          backgroundColor: selected
            ? theme.color.blue900
            : getDefault("hover", "backgroundColor"),
          color: selected ? "white" : getDefault("hover", "color"),
        },
        ":active": {
          backgroundColor: theme.color.blue800,
          color: "white",
        },
      };

  function getDefault(state: string, attr: string) {
    return theme.color[getAttributeStyle(state, attr, "standard")];
  }

  return {
    ...baseExtendStyles,
    ...extend,
  };
}
