import * as React from "react";

export default function Underline() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="underline-icon-24-a"
          d="M12 17c3.315 0 6-2.685 6-6V4a1 1 0 0 0-1-1h-.5a1 1 0 0 0-1 1v7c0 1.935-1.565 3.5-3.5 3.5A3.497 3.497 0 0 1 8.5 11V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v7c0 3.315 2.685 6 6 6zm-7 3a1 1 0 0 0 1 1h12a1 1 0 0 0 0-2H6a1 1 0 0 0-1 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#underline-icon-24-a" />
      </g>
    </svg>
  );
}
