/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { trackEvent } from "hw/portal/modules/analytics/heap";
import { updateDraft } from "../../actions";
import { JobStatus } from "../../constants";

import JobStatusQuery from "./graphql/JobStatus.graphql";
import MergeAnalyzedDraft from "./graphql/MergeAnalyzedDraft.graphql";
import AnalyzeForm from "./graphql/AnalyzeForm.graphql";
import GetJobQuery from "./graphql/GetJob.graphql";
import TeamUsageQuery from "./graphql/TeamUsage.graphql";
import ActivateMutation from "./graphql/Activate.graphql";

type UseAnalyzerProps = {
  workflowGuid: string;
  formId: string;
  jobId?: string;
  /**
   * Function that sets the job into the context
   */
  setJob: Function;
  dispatch: Function;
  participants: string;
};

/**
 * This hook returns [analyzeForm, status] where analyze form is a function
 * that triggers the form analysis.
 *
 * If a jobId is passed, the status starts getting pulled without the need of
 * calling analyzeForm.
 *
 * Once the job is completed, `onCompleted` is called with the jobId generated
 */
export function useAnalyzer(props: UseAnalyzerProps) {
  const { workflowGuid, formId, setJob, participants, dispatch } = props;

  const [mergeDraft] = useMergeDraft({
    workflowGuid,
    formId,
    participants,
    dispatch,
    setJob,
  });

  const [analyzeForm, { data, error }] = useMutation(AnalyzeForm, {
    variables: {
      input: { workflowGuid, formId },
    },

    /**
     * This operates like the `catch` on the promise. Be careful of putting any
     * logic that sets state in here, as it may happen after the component
     * unmounts
     */
    onError(error) {
      logging("info", "Error sending job to analyze", {
        formId,
        workflowGuid,
        errorMessage: error.message,
      });
    },
  });

  const jobId = props.jobId ?? data?.pdfAnalyzerStartJob?.jobId;

  const onCompleted = useCallback(() => {
    trackEvent.autoBuild.completedScanViewed({
      jobId,
      formId,
    });
    // @ts-expect-error refactor
    mergeDraft(jobId);
  }, [formId, jobId, mergeDraft]);

  const [status] = useGetStatus(jobId, setJob);

  useEffect(() => {
    if (status === JobStatus.Completed) {
      onCompleted();
    }
  }, [status, onCompleted]);

  useEffect(() => {
    if (!jobId) return;

    // @ts-expect-error refactor
    setJob((job) => ({
      ...job,
      id: jobId,
    }));
  }, [jobId, setJob]);

  useEffect(() => {
    if (!error) return;

    // @ts-expect-error refactor
    setJob((job) => ({
      ...job,
      status: JobStatus.ErrorStarting,
    }));
  }, [error, setJob]);

  return [analyzeForm];
}

type UseGetJobProps = {
  formId: string;
  workflowGuid: string;
  onCompleted: Function;
};

/**
 * Hook that gets the job id for a form.
 * If a job is not found, it returns null
 */
export function useGetJob(props: UseGetJobProps) {
  const { formId, workflowGuid, onCompleted } = props;
  const { data, loading } = useQuery(GetJobQuery, {
    variables: { formId, workflowGuid },
    fetchPolicy: "network-only",
    onCompleted: ({ pdfAnalyzerGetJob }) => {
      if (pdfAnalyzerGetJob) {
        const { status, jobId } = pdfAnalyzerGetJob;
        // Filtering here makes the error message not to persist
        if (
          jobId &&
          (status === JobStatus.Running || status === JobStatus.Completed)
        ) {
          onCompleted({ status, id: jobId });
        }
      }
    },
    onError: (error) => {
      logging("error", "Error getting a pending job", {
        errorMessage: error.message,
      });
    },
  });

  const status = data?.pdfAnalyzerGetJob?.status;
  const jobId =
    status === JobStatus.Running || status === JobStatus.Completed
      ? data?.pdfAnalyzerGetJob?.jobId
      : undefined;

  return [jobId, loading];
}

type UseMergeDraftProps = {
  formId: string;
  workflowGuid: string;
  participants: string;
  dispatch: Function;
  setJob: Function;
};

/**
 * Hook that returns the function that merges the draft with the one generated
 * by the job.
 */
function useMergeDraft(props: UseMergeDraftProps) {
  const { formId, workflowGuid, participants, dispatch, setJob } = props;

  const [mergeDraftMutation, { data, error }] = useMutation(
    MergeAnalyzedDraft,
    {
      onError: (error) => {
        logging("error", "Analyzed draft couldn't be merged", {
          formId,
          workflowGuid,
          message: error.message,
        });
      },
    }
  );

  useEffect(() => {
    if (error) {
      // @ts-expect-error refactor
      setJob((job) => ({
        ...job,
        status: JobStatus.Error,
      }));
    }

    if (!data) return;

    // @ts-expect-error refactor
    setJob((job) => ({
      ...job,
      status: JobStatus.Merged,
    }));
    const { pdfAnalyzerJobMergeAnalyzedDraft: input } = data;
    const schema = JSON.parse(input.schema);

    dispatch(
      updateDraft({
        schema,
        name: input.name,
      })
    );
  }, [data, dispatch, setJob, error]);

  const mergeDraft = useCallback(
    (jobId: string) => {
      mergeDraftMutation({
        variables: {
          input: {
            workflowGuid,
            formId,
            id: jobId,
            participants,
          },
        },
      });
    },
    [mergeDraftMutation, workflowGuid, participants, formId]
  );

  return [mergeDraft];
}

/**
 * Hook that gets the number of pages available a team has.
 * If the allocated pages are -1, it means it has unlimited pages.
 */
export function useTeamUsage() {
  const { data, loading, error } = useQuery(TeamUsageQuery, {
    fetchPolicy: "network-only",
    onError: (error) => {
      logging("error", "Team usage error", { errorMessage: error.message });
    },
  });
  const usage = data?.pdfAnalyzerTeamUsage;
  const pagesLeft = usage ? getPagesLeft(usage) : 0;

  return [
    pagesLeft,
    {
      authorized: usage?.totalPagesAllocated !== null,
      loading,
      error,
    },
  ];
}

export function useActivateTeam() {
  const [activateTeamMutation, { loading }] = useMutation(ActivateMutation);

  const activateTeam = useCallback(
    (activationCode: string) =>
      activateTeamMutation({
        variables: {
          activationCode,
        },
      }),
    [activateTeamMutation]
  );

  return [activateTeam, { loading }];
}

/**
 * It returns the pages a user has left based on the result returned from graphql
 */
// @ts-expect-error refactor
function getPagesLeft(usage) {
  const { totalPagesAllocated, totalPagesScanned } = usage;
  if (totalPagesAllocated === -1) return -1;
  if (totalPagesAllocated < totalPagesScanned) return 0;

  return totalPagesAllocated - totalPagesScanned;
}

/**
 * Hook that queries the status for a certain job id in the interval specified.
 * It returns the status and a function to stop polling
 */
function useGetStatus(jobId: string | null | undefined, setJob: Function) {
  const { refetch, data, error } = useQuery(JobStatusQuery, {
    variables: { id: jobId },
    skip: !jobId,
    notifyOnNetworkStatusChange: true,
  });

  const status = data?.pdfAnalyzerJobStatus?.status;

  useEffect(() => {
    if (!status) return;

    // @ts-expect-error refactor
    setJob((job) => ({
      ...job,
      status,
    }));

    if (status === JobStatus.Running) {
      refetch();
    } else if (status === JobStatus.FailedToStart) {
      logging("error", "Job failed to start", { jobId });
    } else if (status === JobStatus.CompletedWithErrors) {
      logging("error", "Job status completed with errors", {
        jobId,
      });
    } else if (status === JobStatus.Cancelled) {
      // We are not cancelling jobs from a frontend perspective, but they
      // could be cancelled from the admin app so I thought it would be good
      // to log it.
      logging("info", "Job status cancelled", {
        jobId,
      });
    }
  }, [status, refetch, setJob, jobId]);

  useEffect(() => {
    if (!error) return;

    // @ts-expect-error refactor
    setJob((job) => ({
      ...job,
      status: JobStatus.Error,
    }));

    logging("error", "Job status error", {
      errorMessage: error.message,
      jobId,
    });
  }, [error, jobId, setJob]);

  return [status];
}

/**
 * Logging function that sends information to sentry
 */
function logging(
  level: string,
  message: string,
  extras: { [id: string]: any }
) {
  Sentry.withScope((scope) => {
    // @ts-expect-error refactor
    scope.setLevel(level);
    Object.keys(extras).forEach((id) => {
      scope.setExtra(id, extras[id]);
    });
    Sentry.captureMessage(message);
  });
}
