import * as React from "react";
import AddressGroup, {
  isLegacy,
} from "hw/common/components/smartforms/address-group";
import TextInput from "hw/common/components/smartforms/text-input";
import SelectList from "hw/common/components/smartforms/select-list";
import type { AddressGroupField } from "hw/portal/modules/common/draft";
import {
  createOptions,
  sortFn,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hw/s... Remove this comment to see the full error message
} from "hw/sf/components/smart-view/smart-form//smart-components/select-list/utils";

type Props = {
  field: AddressGroupField;
  prefillMessage: string | null | undefined;
};
type State = {
  value: string;
};
export class AddressGroupPreview extends React.Component<Props, State> {
  state = {
    value: this.props.prefillMessage || "",
  };

  onChange = (newValue: string) =>
    this.setState({
      value: newValue,
    });

  componentDidUpdate(prevProps: Props) {
    if (prevProps.prefillMessage !== this.props.prefillMessage) {
      this.setState({
        value: this.props.prefillMessage || "",
      });
    }
  }

  render() {
    const { field } = this.props;
    const { value } = this.state;

    /* $FlowFixMe[incompatible-use] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    const { label, helpText, children = [] } = field;
    // The `required` field could be a macro in which case the form builder won't
    // know if the field is required or not because macros are not being
    // evaluated.  For V1, we'll coerce string values to `true` and treat them
    // as required.
    const isRequired = Boolean(children.some(({ required }) => required));

    /**
     * Child components have a special renderer in smart forms that assumes certain things that require they be in
     * smart forms. So instead we create a builder type implementation of each child.
     *
     * Flow being flow and not liking mixed types.
     */
    const childComponents = children.map((child) => {
      const {
        // @ts-expect-error refactor
        options = {},
        id,
        required,
        // @ts-expect-error refactor
        placeholder,
        // @ts-expect-error refactor
        value,
        ...restProps
      } = child;
      const childRequired = Boolean(required);
      const childProps = {
        onChange: () => {},
        key: id,
        id,
        grouped: false,
        isVisited: false,
        readOnly: false,
        required: childRequired,
        theme: {
          classNames: {
            inputBorderFocus: "",
          },
        },
        value: value || "",
        // To make flow happy we explicitly pass value.
        ...restProps,
      };

      switch (child.type) {
        // @ts-expect-error refactor
        case "SelectList":
          return (
            <SelectList
              {...childProps}
              handleChange={childProps.onChange}
              isValid={true}
            >
              {createOptions({
                options,
                placeholder,
                sortFn: sortFn(),
              })}
            </SelectList>
          );

        case "TextInput":
        default:
          return <TextInput placeholder={placeholder} {...childProps} />;
      }
    });
    return (
      <AddressGroup
        helpText={helpText}
        isLegacy={isLegacy(children)}
        label={label}
        required={isRequired}
        value={value}
        onChange={this.onChange}
      >
        {/* @ts-expect-error refactor */}
        {childComponents}
      </AddressGroup>
    );
  }
}
export default AddressGroupPreview;
