import * as React from "react";
import { Flex, Box } from "hw/ui/blocks";
import IconWrapper, { DeleteIcon, Duplicate } from "hw/ui/icons";
import { TruncatedText, TruncatedTextParent } from "hw/ui/text";
import { Tooltip } from "hw/ui/tooltip";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteField: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDuplicateField: (...args: Array<any>) => any;
  title: string;
};
export default function TitleBar(props: Props) {
  const { onDeleteField, onDuplicateField, title } = props;
  return (
    <React.Fragment>
      <Box pl="ms" width="calc(100% - 56px)">
        <TruncatedTextParent extend={TitleWrapperExtension}>
          <TruncatedText>{title}</TruncatedText>
        </TruncatedTextParent>
      </Box>

      <Flex alignItems="center" pr="ms">
        <Tooltip tip="Duplicate this component">
          <IconWrapper
            onClick={onDuplicateField}
            data-testid="duplicate-component-btn"
          >
            <Duplicate />
          </IconWrapper>
        </Tooltip>
        <Tooltip tip="Delete this component">
          <IconWrapper
            onClick={onDeleteField}
            data-testid="delete-component-btn"
          >
            <DeleteIcon />
          </IconWrapper>
        </Tooltip>
      </Flex>
    </React.Fragment>
  );
}

function TitleWrapperExtension() {
  return {
    display: "flex",
    alignItems: "center",
  };
}
