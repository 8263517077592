import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "./components/page";
import { LaunchWorkflowV2 } from "./launch-workflow";
import WithCompletedSignup from "../common/signup-completion";
import { AwaitWorkflow, WorkflowReady } from "./queries";
import { LazyWorkflowSelector } from "./components/workflow-selector";

export default function LaunchWorkflowRootV2() {
  const { guid } = useParams<{ guid?: string }>();
  const history = useHistory();

  // Determine if we had an initial guid when we first loaded this page. If so,
  // we'll wait for the workflow to load before rendering anything to avoid a
  // flash of content.
  const [guidOnLoad] = React.useState(guid);

  function onChangeGuid(guid: string) {
    history.push(`/workflow/launch/${guid}`);
  }

  return (
    <WithCompletedSignup>
      {(modalOrNull) => (
        <>
          {modalOrNull}
          <Page>
            {guidOnLoad ? (
              // Wait for this guid we had on initial page load to be ready before
              // rendering the selector in this case. This is an optimization so
              // that the select always has an active value to display. After
              // the initial page load, the active value comes from the list of
              // workflows
              <AwaitWorkflow guid={guidOnLoad}>
                <LazyWorkflowSelector onChange={onChangeGuid} value={guid} />
              </AwaitWorkflow>
            ) : (
              <LazyWorkflowSelector onChange={onChangeGuid} value={guid} />
            )}
            {guid ? (
              <WorkflowReady guid={guid}>
                {(workflow) => <LaunchWorkflowV2 workflow={workflow} />}
              </WorkflowReady>
            ) : null}
          </Page>
        </>
      )}
    </WithCompletedSignup>
  );
}
