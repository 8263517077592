import * as React from "react";
import { VisuallyHidden } from "hw/ui/text";
import { ToggleCheckIcon, ToggleUncheckIcon } from "hw/ui/icons";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import getTestAttributes from "hw/common/utils/get-test-attributes";

type Props = {
  on: boolean;
  onClick: (nextIsOn: boolean) => void;
  disabled?: boolean;
  label?: string;
  "data-guide-id"?: string;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick">;

/**
 * A checkbox-like component used for toggling boolean value.
 */
export default function Toggle(props: Props) {
  const { on = false, onClick, label, disabled, ...rest } = props;
  const btnCn = useStyle("ui-toggle-btn", style(props));
  const sliderCn = useStyle("ui-toggle-slider", sliderStyle(props));
  const internalOnClick = React.useCallback(() => onClick(!on), [on, onClick]);

  return (
    // @ts-expect-error ts-migrate
    <button
      {...rest}
      {...getTestAttributes(props["data-guide-id"])}
      type="button"
      aria-pressed={String(on)}
      onClick={internalOnClick}
      className={btnCn}
      disabled={disabled}
      data-testid="ui-toggle"
    >
      {label && <VisuallyHidden>{label}</VisuallyHidden>}
      <span className={sliderCn}>
        {on ? <ToggleCheckIcon /> : <ToggleUncheckIcon />}
      </span>
    </button>
  );
}

const gutter = 2;
const iconSize = 14;
const width = iconSize * 2 + gutter * 2;
const height = iconSize + gutter * 2;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
const px = (x) => x + "px";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function style(props) {
  const { disabled } = props;

  return {
    background: "none",
    border: "none",
    width: px(width),
    height: px(height),
    cursor: disabled ? "not-allowed" : "pointer",
    position: "relative",
    borderRadius: px(iconSize * 2),
    ":focus": {
      boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
      outline: "none",
    },
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function sliderStyle(props) {
  const { on } = props;

  return {
    position: "absolute",
    display: "flex",
    backgroundColor: on ? theme.color.blue600 : theme.color.gray500,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: px(iconSize * 2),

    "> svg": {
      position: "absolute",
      right: !on && px(gutter),
      left: on && px(gutter),
      top: px(gutter),
    },

    ":before": {
      position: "absolute",
      content: '" "',
      height: px(iconSize),
      width: px(iconSize),
      left: px(gutter),
      bottom: px(gutter),
      transition: theme.transitionSpeed.fast,
      borderRadius: "50%",
      backgroundColor: "white",
      transform: on && `translateX(${px(iconSize)})`,
    },
  };
}
