/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ErrorMessage from "hw/common/components/smartforms/common/error-message";
import MaskedInput from "./masked-input/masked-input";
import Label from "./common/label";

type Props = {
  errorMessage?: string; // Any validation errors
  handleVisited?: (...args: Array<any>) => any; // Used to track the field for validation
  helpText?: string; // The help bubble next to the label, if null there is no bubble
  hidden: boolean;
  id?: string; // Passed through to the masked input
  isValid?: boolean;
  label?: string; // Label attached to the input
  onChange?: (...args: Array<any>) => any;
  readOnly: boolean;
  required: boolean; // Is the input required
  value?: string;
};

/**
 * A component for displaying a phone number and it's relative metadata.
 */
export default class PhoneNumber extends React.Component<Props> {
  static defaultProps = {
    hidden: false,
    readOnly: false,
    required: false,
  };

  /**
   * When the masked input changes call the passed function.
   *
   * @param rawValue - The new value provided by the masked input.
   */
  onChange = (rawValue: string | null | undefined) =>
    this.props.onChange && this.props.onChange(rawValue);

  render() {
    const {
      errorMessage,
      handleVisited,
      helpText,
      hidden,
      id,
      isValid,
      label,
      readOnly,
      required,
      value,
    } = this.props;

    const error = !isValid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

    const token = "_";
    const mask = `(${token}${token}${token}) ${token}${token}${token}-${token}${token}${token}${token}`;
    const maxLength = 10; // The number of tokens in mask
    const notANumber = /\D/;

    const maskedInputProps = {
      attrs: {
        disabled: readOnly,
        id,
        type: "tel",
      },
      invalidValueExp: notANumber,
      isValid,
      mask,
      maxLength,
      onBlur: handleVisited,
      onChange: this.onChange,
      readOnly,
      token,
      value,
    };

    const labelComp = label && (
      <Label required={required} helpText={helpText} htmlFor={id}>
        {label}
      </Label>
    );

    return (
      <div hidden={hidden} aria-hidden={hidden}>
        {labelComp}
        <MaskedInput {...maskedInputProps} />
        {error}
      </div>
    );
  }
}
