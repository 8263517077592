/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import BaseModal from "hw/ui/modal";
import { setIn, getIn } from "timm";
import { ToastConsumer } from "hw/ui/toast";
import type { Billing } from "hw/portal/modules/common/graphql/schema";
import * as heap from "hw/portal/modules/analytics/heap";
import type { User } from "hw/portal/modules/user";
import theme from "hw/ui/theme";
import {
  PremiumBadge,
  PremiumWrapper,
} from "hw/portal/modules/common/components/premium";
import { isAuthorizedTo, permissions } from "hw/portal/modules/authn";
import styles from "./modal.module.css";
import UpgradeBanner from "./upgrade-banner";
import Constants from "./constants";
import { validateName, validateEmail } from "./validations";
import Step0 from "./step0";
import Step1 from "./step1";
import type * as Types from "../types";

const { STATUS, MAX_SEND } = Constants;

type Props = {
  file?: Blob;
  mergeFields: Array<{
    label: string;
    dataRef: string;
  }>;
  participants: Array<{
    id: string;
    label: string;
  }>;
  workflowGuid: string;
  bulkSend: Types.BulkSendOperation;
  closeBulk: () => void;
  headers: Array<string>;
  workflowName: string;
  addSuccess: (
    msg: string | React.ReactElement<any>,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
  addWarning: (
    msg: string | React.ReactElement<any>,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
  addDanger: (
    msg: string | React.ReactElement<any>,
    dismissible?: boolean,
    timeOut?: number
  ) => void;
  billing: Billing;
  user: User;
  documentDeliveryType: string;
  notifyWhenComplete: boolean;
};

type StateRow = {
  status: string;
  csvIndex: number; // Originally designed to map against the csv file, now used as a unique id
  participants: Array<{ nameValue: string; emailValue: string; id: string }>;
  mergeFields: Array<{ value: string; dataRef: string }>;
};

type State = {
  rows: Array<StateRow>;
  sending: boolean;
  idMismatch: boolean;
  dataError: boolean;
  invalidFileType: boolean;
  step: number;
};

class Modal extends React.Component<Props, State> {
  state = {
    rows: [],
    sending: false,
    idMismatch: false,
    dataError: false,
    invalidFileType: false,
    step: 0,
  };

  isMounted = true;

  componentWillUnmount() {
    this.isMounted = false;
  }

  getStatusFromError = (error: string, billing: Billing, user: User) => {
    if (!error) {
      return STATUS.SUCCESS;
    }
    if (error.includes("email")) {
      return STATUS.ERROR_EMAIL;
    }

    if (error.includes("exceeded_transaction_limit")) {
      return (
        <span>
          You have reached the limit of {billing.transactionLimit} workflows
          that can be sent in a month. {this.getUpgradeLink(user)}
        </span>
      );
    }

    if (error.includes("rate_limit")) {
      return STATUS.ERROR_RATE_LIMIT_MINUTE;
    }

    if (error.includes("bulk_send_not_enabled")) {
      return (
        <span>
          Bulk sending of transactions is not available for this team.{" "}
          {this.getUpgradeLink(user)}
        </span>
      );
    }

    if (error.includes("transactions_blocked")) {
      return STATUS.TRANSACTIONS_BLOCKED;
    }

    if (error.includes("exceeded_max_bulk_send_size")) {
      return STATUS.EXCEEDED_MAX_BULK_SEND_SIZE;
    }

    return STATUS.ERROR;
  };

  getUpgradeLink(user: User) {
    if (user?.role === "admin") {
      return (
        <u>
          <a href="/plan">View plans to upgrade.</a>
        </u>
      );
    } else {
      return (
        <u>
          <a
            href="https://www.hellosign.com/info/hs-pricing"
            target="_blank"
            rel="noreferrer"
          >
            View plans to upgrade.
          </a>
        </u>
      );
    }
  }

  /**
   * Called after ALL the sends are resolved.
   */
  postBulkSend = (response: Types.BulkSendResponse) => {
    if (!this.isMounted) {
      return;
    }

    const { addSuccess, addDanger, billing, user } = this.props;
    this.setState((state) => {
      const { rows } = state;
      const formCount = rows.length;
      const failures = [];

      if (response.response) {
        addSuccess(
          `${formCount} forms have been queued to be sent, view the 'Bulk Send Queue' for more details.`
        );
      }

      if (response.error) {
        failures.push({ status: response.error.message, participants: [] });
        addDanger(
          <span>
            {formCount} forms could not be sent.{" "}
            {this.getStatusFromError(response.error.message, billing, user)}
          </span>,
          true,
          10000
        );

        return {
          rows,
          sending: false,
        };
      }

      return {
        rows: [],
        sending: false,
      };
    });
  };

  formatEntriesForSend = (
    entries: Array<StateRow>
  ): Types.CreateWorkflowInstanceInput[] =>
    // $FlowIgnore
    entries.map((entry) => {
      const { workflowGuid, documentDeliveryType, notifyWhenComplete, user } =
        this.props;
      const { role } = user;

      const formatedEntry = {
        guid: workflowGuid,
        isTest: false,
        participants: entry.participants.map(
          (participant) =>
            ({
              fullName: participant.nameValue,
              roleId: participant.id,
              type: "EMAIL",
              value: participant.emailValue,
            } as const)
        ),
        mergeFields: entry.mergeFields
          .filter((mergeField) => mergeField.value)
          .map((mergeField) => ({
            dataRefId: mergeField.dataRef,
            dataRefValue: mergeField.value,
          })),
      };

      if (isAuthorizedTo(permissions.UpdateWorkflow, role)) {
        // @ts-expect-error refactor
        formatedEntry.documentDeliveryType = documentDeliveryType;

        // @ts-expect-error refactor
        formatedEntry.notifyWhenComplete = notifyWhenComplete;
      }

      return formatedEntry;
    });

  /**
   * Send a workflow instance for each selected entry
   */
  bulkSend = () => {
    heap.track(heap.EVENTS.launchPage.bulkSendSendClicked);

    const { bulkSend } = this.props;
    const { rows } = this.state;
    const formattedEntries = this.formatEntriesForSend(rows);

    if (rows) {
      this.setState({ sending: true });

      Promise.resolve(bulkSend(formattedEntries)).then((response) => {
        this.postBulkSend(response);
      });
    }
  };

  setName = (rowIndex: number, participantIndex: number, value: string) =>
    !this.state.sending &&
    // @ts-expect-error refactor
    this.setState((state) => ({
      rows: setIn(
        state.rows,
        [rowIndex, "participants", participantIndex, "nameValue"],
        value
      ),
    }));

  setEmail = (rowIndex: number, participantIndex: number, value: string) =>
    !this.state.sending &&
    this.setState((state) => {
      const rows = [...state.rows];
      const row = getIn(rows, [rowIndex]);
      // @ts-expect-error refactor
      row.participants[participantIndex].emailValue = value;
      // @ts-expect-error refactor
      row.status = STATUS.PENDING; // Since the value changed and status refers to the server validation we have to reset it.
      // @ts-expect-error refactor
      rows[rowIndex] = row;

      return { rows };
    });

  setMergeField = (rowIndex: number, mergeFieldIndex: number, value: string) =>
    !this.state.sending &&
    // @ts-expect-error refactor
    this.setState((state) => ({
      rows: setIn(
        state.rows,
        [rowIndex, "mergeFields", mergeFieldIndex, "value"],
        value
      ),
    }));

  onFileChange = (file: File) => {
    heap.track(heap.EVENTS.launchPage.bulkSendUpload);

    const { participants, mergeFields, workflowGuid, headers, addWarning } =
      this.props;

    const reader = new FileReader();
    // $FlowIgnore
    const { name: fileName } = file;

    const invalidFileType =
      fileName.substring(fileName.lastIndexOf(".") + 1) !== "csv";

    if (invalidFileType) {
      this.setState({ invalidFileType });
      return;
    }

    reader.onload = (evt) => {
      // @ts-expect-error refactor
      const rawData = (evt?.target.result || "").split("\n") as string[];
      // due to splitting on `\n`, the result could include an extra blank lines
      // and cause an issue with rendering the right number of rows. Removing the blank
      // results aids in this issue
      const data = rawData.filter((row) => row.length !== 0);
      // @ts-expect-error refactor
      const csvMetaData = data[0].split(",");
      const csvGuid = csvMetaData[0];
      // @ts-expect-error refactor
      const csvComments = data[1].split(",");
      // @ts-expect-error refactor
      const csvHeaders = data[2].split(",");
      const dataRows = data.slice(3).map((rowString) => rowString.split(","));

      const expectedDataSize = headers.length;

      const idMismatch = csvGuid !== workflowGuid;

      const dataError =
        csvMetaData.filter((cell) => !!cell.trim()).length !== 2 ||
        csvComments.filter((cell) => !!cell.trim()).length !== 1 ||
        csvHeaders.filter((cell) => !!cell.trim()).length !==
          expectedDataSize ||
        dataRows.some((row) => row.length !== expectedDataSize);

      const rows = dataRows.map((row, index) => ({
        csvIndex: index + 4,
        status: STATUS.PENDING,
        participants: participants.map((participant, index) => ({
          ...participant,
          nameValue: row[index * 2],
          emailValue: row[index * 2 + 1],
        })),
        mergeFields: mergeFields.map((mergeField, index) => ({
          ...mergeField,
          value: row[participants.length * 2 + index],
        })),
      }));

      if (rows.length > MAX_SEND) {
        addWarning(
          `Your list has been truncated to the first ${MAX_SEND} rows. You can send ${MAX_SEND} instances per batch.`,
          true,
          0
        );
      }

      this.setState({
        // @ts-expect-error refactor
        rows: rows.slice(0, MAX_SEND),
        idMismatch,
        dataError,
        step: 1,
      });
    };

    reader.readAsText(file);
  };

  render() {
    const { closeBulk, headers, workflowName, workflowGuid, participants } =
      this.props;
    const { rows, sending, idMismatch, dataError, invalidFileType, step } =
      this.state;

    const disableSend =
      sending ||
      rows.some((row) =>
        // @ts-expect-error refactor
        row.participants.some(
          // @ts-expect-error refactor
          (participant) =>
            !validateName(participant.nameValue) ||
            !validateEmail(participant.emailValue)
        )
      );

    const modalConfig =
      step === 0
        ? {
            heading: (
              <span>
                <span>Add participants for bulk send</span>
                <PremiumBadge feature="BulkSend" />
              </span>
            ),
            onClose: closeBulk,
          }
        : {
            heading: `${workflowName} Bulk Send`,
            primaryAction: {
              text: "Send",
              onClick: this.bulkSend,
              disabled: disableSend,
              attrs: {
                "data-track-id": "bulk-send-send-button",
              },
            },
            secondaryAction: {
              text: "Close",
              onClick: closeBulk,
            },
            width: `calc(100% - ${theme.space.md} * 2)`,
            extend: {
              height: "100%",
              maxHeight: `calc(100% - ${theme.space.md} * 2)`,
              margin: 0,
            },
            portalExtend: {
              paddingTop: theme.space.md,
            },
            bodyExtend: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
            },
          };

    const stepNode =
      step === 0 ? (
        <Step0
          workflowGuid={workflowGuid}
          workflowName={workflowName}
          participants={participants}
          headers={headers}
          onFileChange={this.onFileChange}
          invalidFileType={invalidFileType}
        />
      ) : (
        <Step1
          rows={rows}
          headers={headers}
          idMismatch={idMismatch}
          dataError={dataError}
          setName={this.setName}
          setEmail={this.setEmail}
          setMergeField={this.setMergeField}
          sending={sending}
        />
      );

    return (
      <BaseModal {...modalConfig}>
        <div className={styles.content}>
          <PremiumWrapper
            feature="BulkSend"
            access={null}
            noAccess={
              <UpgradeBanner
                className={step === 0 ? "" : styles.step1Adjustment}
              />
            }
          />
          {stepNode}
        </div>
      </BaseModal>
    );
  }
}

export default function WrappedBulkModal(
  props: Omit<
    React.ComponentProps<typeof Modal>,
    "addSuccess" | "addDanger" | "addWarning"
  >
) {
  return (
    <React.Fragment>
      <ToastConsumer>
        {({ addSuccess, addWarning, addDanger }) => (
          <Modal
            addSuccess={addSuccess}
            addDanger={addDanger}
            addWarning={addWarning}
            {...props}
          />
        )}
      </ToastConsumer>{" "}
    </React.Fragment>
  );
}
