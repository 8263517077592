/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import cx from "classnames";
import escapeRegExp from "lodash/escapeRegExp";
import "./masked-input.css";
import theme from "hw/ui/theme";

const { transparent, gray025, gray200, gray400, blue500 } = theme.color;
// Block style parameters that are static
const padding = 6; // Visual padding for the blocks
const letterSize = 9.5; // Size of the character as determined by the font
const letterSpacing = 16; // Space between each character
const letterPadding = Math.floor(padding + letterSize / 2); // How far to move the first character ver
const characterSlotSize = letterSpacing + letterSize; // How big an individual character "slot" is
const spacerBGSize = 6; // The width of the space between two backgrounds
const characterBGSize = characterSlotSize - spacerBGSize; // The width of each background

type Props = {
  attrs?: {};
  className?: string;
  /**
   * A regular expression representing all invalid values, includes mask and token characters. It should NOT contain
   * any flags (ie /\D/ is correct but /\D/g will have unknown results).
   */
  invalidValueExp: RegExp;
  isBlockFormat?: boolean; // Flag that toggles on the special styling for block format inputs (like ssn)
  /**
   * When set to true it will replace the existing character instead of pushing results to the right.
   * For 123-4 with a max length of 5 and the carrot between the 2 and 3
   * typing 5 gives
   * isInsertMode is false: 125-34
   * isInsertMode is true: 125-4
   * And typing 6 would produce
   * isInsertMode is false: 125-63
   * isInsertMode is true: 125-6
   */
  isInsertMode?: boolean;
  isValid?: boolean; // Is the field valid as determined by the smart forms framework.
  mask: string; // The mask to fit around the data, must not contain valid values
  /**
   * The max size via truncation of the raw value. We do it like this instead of maxsize on the input so the user
   * can paste in things like "home #(555) 555 5555" and get "(555) 555 5555" instead of "(555)".
   */
  maxLength: number;
  onChange?: (...args: Array<any>) => any; // Function called after the masked input on change triggers, receives the new raw value
  readOnly?: boolean; // Is the field read only as determined by the smart forms framework.
  token: string; // The token to replace with a valid value, must not be a valid value
  value?: string; // The initial value

  // pass throughs to the underlying input element.
  onBlur?: (...args: Array<any>) => any;
};

type State = {
  value: string | null | undefined;
  style: {} | null | undefined;
};

/**
 * Controlled input element that will fit the data into the [mask] provided, allowing up to [maxLength] valid characters
 * Invalid characters are eaten
 *
 * TODO: Support shift selection when typing, currently disabled
 * TODO: Fix undo/redo
 *
 */
export default class MaskedInput extends React.Component<Props, State> {
  inputRef: any;

  constructor(props: Props) {
    super(props);
    const { maxLength, value } = props;
    this.inputRef = React.createRef();

    this.state = {
      value: this.toFullMask(
        this.toMaskedValue(
          this.toRawValue(value).slice(0, maxLength) // Enforce raw data max length
        )
      ),
      style: this.getBackgroundStyle(),
    };
  }

  /**
   * Strips value of all invalid characters returning the raw value.
   *
   * @param value
   * @returns string
   */
  toRawValue = (value: string | null | undefined = "") =>
    /* eslint-disable security-node/non-literal-reg-expr */
    value.replace(new RegExp(this.props.invalidValueExp, "g"), "");
  /* eslint-enable security-node/non-literal-reg-expr */

  /**
   * When the data changes sanitize it, mask it, and set the new cursor position.
   * Triggers the passed on change function passing it the raw data value.
   */
  handleChange = () => {
    const { maxLength, onChange, isInsertMode } = this.props;
    const { current: inputEl } = this.inputRef;
    const { selectionEnd, value } = inputEl;
    const { value: oldFullMaskedValue } = this.state;

    const preSelection = value.slice(0, selectionEnd);
    const postSelection = value.slice(selectionEnd);
    const rawLengthDelta =
      this.toRawValue(value).length -
      this.toRawValue(oldFullMaskedValue).length;
    const rawPreSelection = this.toRawValue(preSelection);

    const rawPostSelection =
      isInsertMode && rawLengthDelta > 0
        ? this.toRawValue(postSelection).slice(rawLengthDelta)
        : this.toRawValue(postSelection);

    const rawValue = rawPreSelection
      .concat(rawPostSelection)
      .slice(0, maxLength);

    const maskedPreSelection = this.toMaskedValue(rawPreSelection);
    const maskedValue = this.toMaskedValue(rawValue);
    const fullMaskedValue = this.toFullMask(maskedValue);
    const maskedPostSelection = fullMaskedValue.slice(
      maskedPreSelection.length
    );
    // Prevent the selection point from ever being to the left of mask characters on change
    const adjustmentForPostSelectionMask = this.getLeftCount(
      maskedPostSelection,
      this.isMask
    );

    const newPoint = this.getPointInMinMax(
      maskedValue,
      fullMaskedValue,
      maskedPreSelection.length + adjustmentForPostSelectionMask
    );

    const newState: { value?: string } = {
      value: fullMaskedValue,
      style: this.getBackgroundStyle(newPoint),
    };
    const onStateChange = () => {
      inputEl.selectionStart = newPoint;
      inputEl.selectionEnd = newPoint;
      if (onChange) {
        onChange(rawValue);
      }
    };
    this.setState(newState, onStateChange);
  };

  /**
   * Returns a count of how many prepended characters the value has.
   *
   * param value - The value to check
   * param isValid - Determines if a value is valid, defaults to testing with props.invalidValueExp
   * returns {number} - The count of leading invalid characters.
   */
  getLeftCount = (
    value = "",
    isValid: (value: string) => boolean = (
      // (value: string) => boolean is flow and not functional
      value
    ) => this.props.invalidValueExp.test(value)
  ) => {
    const { length } = value;
    let index = 0;

    while (index < length && isValid(value.charAt(index))) {
      index++;
    }

    return index;
  };

  /**
   * Returns a count of how many prepended characters the value has.
   *
   * param value - The value to check
   * param isValid - Determines if a value is valid, defaults to testing with props.invalidValueExp
   * returns {number} - The count of leading invalid characters.
   */
  getRightCount = (
    value = "",
    isValid: (value: string) => boolean = (
      // (value: string) => boolean is flow and not functional
      value
    ) => this.props.invalidValueExp.test(value)
  ) => this.getLeftCount([...value].reverse().join(""), isValid);

  /**
   * Converts a rawvalue to it's masked form, if the max length and raw value are greater then the number of tokens it
   * appends the remaining values to the end. It will never end with a non valid value.
   *
   * param rawValue - A string that has only valid characters
   * returns {string} - A string with the raw values injected into the mask
   */
  toMaskedValue = (rawValue = "") => {
    const { token, mask } = this.props;
    const maskLength = mask.length;
    const rawLength = rawValue.length;
    let valueIndex = 0;
    const maskedValue = [];

    for (let index = 0; index < maskLength && valueIndex < rawLength; index++) {
      const char = mask.charAt(index);
      const maskedValueChar =
        token !== char ? char : rawValue.charAt(valueIndex++);
      maskedValue.push(maskedValueChar);
    }

    // Add any characters beyond the mask.
    while (valueIndex < rawLength) {
      maskedValue.push(rawValue[valueIndex++]);
    }

    return maskedValue.join("");
  };

  /**
   * Appends any mask characters that were not added do to not having values for the tokens.
   *
   * param maskedValue
   * returns {string}
   */
  toFullMask = (maskedValue = "") =>
    maskedValue.length < this.props.mask.length
      ? maskedValue + this.props.mask.slice(maskedValue.length)
      : maskedValue;

  /**
   * Delete the next valid value, reading from right to left of preSelection. Update the input's value
   * with the full mask on the full raw value after.
   *
   * @param preSelection
   * @param postSelection
   */
  handleBackspace = (
    preSelection: string | null | undefined,
    postSelection: string | null | undefined
  ) => {
    const { current: inputEl } = this.inputRef;

    const rawPreSelection = this.toRawValue(preSelection);
    const rawPostSelection = this.toRawValue(postSelection);

    const newRawPreSelection = rawPreSelection.slice(0, -1);
    const newPreSelectionMask = this.toMaskedValue(newRawPreSelection);
    const newRawValue = newRawPreSelection + rawPostSelection;
    const newMaskedValue = this.toMaskedValue(newRawValue);
    const newFullMaskedValue = this.toFullMask(newMaskedValue);

    inputEl.value = this.toFullMask(newMaskedValue);
    const newPoint = this.getPointInMinMax(
      newMaskedValue,
      newFullMaskedValue,
      newPreSelectionMask.length
    );

    inputEl.selectionStart = newPoint;
    inputEl.selectionEnd = newPoint;
  };

  /**
   * Delete the next valid value, reading from left to right of postSelection. Update the input's value
   * with the full mask on the full raw value after.
   *
   * @param preSelection
   * @param postSelection
   */
  handleDelete = (
    preSelection: string | null | undefined,
    postSelection: string | null | undefined
  ) => {
    const { current: inputEl } = this.inputRef;
    const { selectionEnd } = inputEl;

    const rawPreSelection = this.toRawValue(preSelection);
    const rawPostSelection = this.toRawValue(postSelection);
    const newRawPostSelection = rawPostSelection.slice(1);
    const newRawValue = rawPreSelection + newRawPostSelection;

    inputEl.value = this.toFullMask(this.toMaskedValue(newRawValue));
    inputEl.selectionStart = selectionEnd;
    inputEl.selectionEnd = selectionEnd;
  };

  /**
   * When the user clicks left skip over any non valid characters.
   *
   * @param preSelection
   */
  handleArrowLeft = (preSelection = "") => {
    const { current: inputEl } = this.inputRef;
    const { selectionStart, value: fullMaskedValue } = inputEl;
    const maskedValue = this.toMaskedValue(this.toRawValue(fullMaskedValue));

    const newPoint = this.getPointInMinMax(
      maskedValue,
      fullMaskedValue,
      selectionStart - 1 - this.getRightCount(preSelection)
    );

    const newStyle = this.getBackgroundStyle(newPoint);
    this.setState({ style: newStyle });

    inputEl.selectionStart = newPoint;
    inputEl.selectionEnd = newPoint;
  };

  /**
   * When the user clicks right skip over any invalid characters
   * TODO: There is an error here where when moving right and there are no longer valid values it will move to the end
   * of the full masked input. However this bug is "fixed" by getPointInMinMax. We should fix this function so it isn't
   * dependant on getPointInMinMax.
   *
   * @param postSelection
   */
  handleArrowRight = (postSelection = "") => {
    const { current: inputEl } = this.inputRef;
    const { selectionEnd, value: fullMaskedValue } = inputEl;
    const maskedValue = this.toMaskedValue(this.toRawValue(fullMaskedValue));

    const newPoint = this.getPointInMinMax(
      maskedValue,
      fullMaskedValue,
      // Skip over any invalid or the first character and then any invalid
      selectionEnd +
        1 +
        /**
         * Only possible with the mouse
         *
         * 1 2 3 - 4 5 - 6 7 8 9
         *      ^
         */
        (this.getLeftCount(postSelection) ||
          /**
           * When going right skip any invalid characters.
           *
           * 1 2 3 - 4 5 - 6 7 8 9
           *    ^
           */
          this.getLeftCount(postSelection.slice(1)))
    );

    const newStyle = this.getBackgroundStyle(newPoint);
    this.setState({ style: newStyle });

    inputEl.selectionStart = newPoint;
    inputEl.selectionEnd = newPoint;
  };

  /**
   * Handles left/right so they flow around the mask, handles delete and backspace so they always remove a value
   *
   * @param event
   */
  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { invalidValueExp } = this.props;
    const { key } = event;
    const { current: inputEl } = this.inputRef;
    const { value } = inputEl;
    const { selectionStart, selectionEnd } = inputEl;
    const selectionSize = selectionEnd - selectionStart;
    const preSelection = value.slice(0, selectionEnd);
    const postSelection = value.slice(selectionEnd);

    switch (key) {
      case "Backspace":
        if (!selectionSize) {
          this.handleBackspace(preSelection, postSelection);
          this.handleChange();
          event.preventDefault();
        }
        break;
      case "Delete":
        if (!selectionSize && invalidValueExp.test(postSelection.charAt(0))) {
          this.handleDelete(preSelection, postSelection);
          this.handleChange();
          event.preventDefault();
        }
        break;
      case "Left": // IE specific value

      // eslint-disable-next-line no-fallthrough
      case "ArrowLeft":
        this.handleArrowLeft(preSelection);
        event.preventDefault();
        break;
      case "Right": // IE specific value

      // eslint-disable-next-line no-fallthrough
      case "ArrowRight":
        this.handleArrowRight(postSelection);
        event.preventDefault();
        break;
      default:
    }
  };

  /**
   * Returns if the characters is a masking character, token characters are not considered valid
   *
   * param value
   * returns {boolean}
   */
  isMask = (value = "") =>
    this.props.invalidValueExp.test(value) &&
    // eslint-disable-next-line security-node/non-literal-reg-expr
    !new RegExp(escapeRegExp(this.props.token)).test(value);

  /**
   * Returns if the character is not a token character
   * param value
   * returns {boolean}
   */
  isNotToken = (value = "") =>
    // eslint-disable-next-line security-node/non-literal-reg-expr
    new RegExp(`[^${escapeRegExp(this.props.token)}]`).test(value);

  /**
   * Checks that the point is somewhere between the min and max point allowed, as determined by the mask and value.
   * If it is it returns the point, else it returns the min or max depending on which was violated.
   *
   * NOTE: Min takes precedence over max
   *
   * param maskedValue
   * param fullMaskedValue
   * param point
   * returns {*}
   */
  getPointInMinMax = (maskedValue = "", fullMaskedValue = "", point = 0) => {
    const postMaskValue = fullMaskedValue.slice(maskedValue.length);
    const minPoint = this.getLeftCount(this.props.mask, this.isNotToken);
    const maxPoint =
      maskedValue.length + this.getLeftCount(postMaskValue, this.isMask);

    if (point < minPoint || maxPoint < minPoint) {
      return minPoint;
    } else if (point > maxPoint) {
      return maxPoint;
    }

    return point;
  };

  /**
   * Generates the inline style for the block format. Using the configuration at the top it generates the corresponding
   * css, the background images that produces the blocks behind the tokens, and the underlines for each token.
   *
   * param activeCharIndex If a number and block styling is on it makes that block appear active, else it does nothing.
   * returns {{
   *   letterSpacing: number,
   *   padding: string,
   *   backgroundPosition: string,
   *   backgroundSize: string,
   *   backgroundImage: string
   * }}
   */
  getBackgroundStyle(activeCharIndex?: number) {
    const { mask, token, isBlockFormat, readOnly } = this.props;

    if (!isBlockFormat) {
      return {};
    }

    const width =
      letterPadding + characterSlotSize * mask.length + letterSize + 2;

    return {
      letterSpacing,
      padding: `0 0 0 ${letterPadding}px`,
      backgroundPosition: `left ${padding}px bottom ${padding}px, ${padding}px ${padding}px`,
      backgroundSize: `100% 1px, 100% calc(100% - ${padding * 2 + 1}px)`,
      backgroundImage: getBackgroundImages(
        mask,
        token,
        readOnly,
        activeCharIndex
      ),
      width,
    };
  }

  /**
   * When the user clicks into the input box update the background.
   * Because of we want the most recent carrot position we can't use mouse down.
   */
  handleClick = () => {
    const { current: inputEl } = this.inputRef;
    const { selectionEnd, value } = inputEl;

    const postSelection = value.slice(selectionEnd);
    const maskedValue = this.toMaskedValue(this.toRawValue(value));
    const adjustmentForPostSelectionMask = this.getLeftCount(
      postSelection,
      this.isMask
    );

    const newPoint = this.getPointInMinMax(
      maskedValue,
      value,
      selectionEnd + adjustmentForPostSelectionMask
    );

    this.setState(
      {
        style: this.getBackgroundStyle(newPoint),
      },
      () => {
        inputEl.selectionStart = newPoint;
        inputEl.selectionEnd = newPoint;
      }
    );
  };

  handleBlur = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;

    this.setState({
      style: this.getBackgroundStyle(),
    });
    if (onBlur) {
      onBlur(event);
    }
  };

  render() {
    const { attrs, isBlockFormat, className, isValid, mask, readOnly } =
      this.props;
    const { value, style } = this.state;

    /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
     * error found when upgrading Flow to v0.132.0. To view the error, delete
     * this comment and run Flow. */
    const inputProps = {
      autoComplete: "off",
      autoCorrect: "off",
      autoCapitalize: "off",
      spellCheck: "false",
      className: cx(className, {
        "m-sf-masked-input-field": true,
        "block-format": isBlockFormat,
        invalid: !isValid,
        readonly: readOnly,
      }),
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onClick: this.handleClick,
      onKeyDown: this.handleKeyDown,
      ref: this.inputRef,
      size: mask.length,
      style,
      value,
      ...attrs,
    };

    return <input {...inputProps} />;
  }
}

/**
 * Generates the inline background style for the block format. Using the configuration at the top it generates the
 * corresponding gradients that combined with the general background css produces the blocks behind the tokens, and the
 * underlines for each token.
 *
 * Exported for testing purposes since jsdom doesn't like background-images
 *
 * param mask
 * param token
 * param readOnly
 * param activeCharIndex If a number it makes that block appear active, else it does nothing.
 * returns {string}
 */
export function getBackgroundImages(
  mask: string,
  token: string,
  readOnly = false,
  activeCharIndex?: number
) {
  const underlineArr = ["linear-gradient(to right"];
  const backgroundArr = [", linear-gradient(to right"];

  [...mask].forEach((char, index) => {
    const start = index * characterSlotSize;
    const charStop = start + characterBGSize;
    const spacerStop = charStop + spacerBGSize;
    const isToken = char === token;
    const isActive = index === activeCharIndex;

    underlineArr.push(
      getGradientStep(
        start,
        charStop,
        spacerStop,
        getUnderlineColor(isToken, readOnly, isActive)
      )
    );

    if (!readOnly) {
      backgroundArr.push(
        getGradientStep(
          start,
          charStop,
          spacerStop,
          getBackgroundColor(isToken, isActive)
        )
      );
    }
  });
  underlineArr.push(")");
  if (readOnly) {
    // By keeping the background we don't have to worry about shifting of all the other css properties that act on it.
    backgroundArr.push(`${transparent}, ${transparent}`);
  }
  backgroundArr.push(")");

  return readOnly
    ? underlineArr.join("")
    : underlineArr.concat(backgroundArr).join("");
}

function getGradientStep(start, charStop, spacerStop, color) {
  return `, ${color} ${start}px, ${color} ${charStop}px, ${transparent} ${charStop}px, ${transparent} ${spacerStop}px`;
}

function getUnderlineColor(isToken, readOnly, isActive) {
  if (isToken) {
    if (readOnly) {
      return gray200;
    }

    if (isActive) {
      return blue500;
    }

    return gray400;
  }

  return transparent;
}

// We don't build the background if it's read only so we can assume readonly is false for getBackgroundColor
function getBackgroundColor(isToken, isActive) {
  if (isToken) {
    return isActive ? transparent : gray025;
  }
  return transparent;
}
