import * as React from "react";

/**
 * TODO: Change this icon for the proper 14px icon
 */
function ToggleUnheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FFF"
        d="M9.43119709,8 L12.1853443,5.24585283 C12.5805584,4.85063867 12.5805584,4.20986991 12.1853443,3.81465575 C11.7901301,3.41944158 11.1493613,3.41944158 10.7541472,3.81465575 L8,6.56880291 L5.24585283,3.81465575 C4.29172144,2.86052435 2.86052435,4.29172144 3.81465575,5.24585283 L6.56880291,8 L3.81465575,10.7541472 C3.41944158,11.1493613 3.41944158,11.7901301 3.81465575,12.1853443 C4.20986991,12.5805584 4.85063867,12.5805584 5.24585283,12.1853443 L8,9.43119709 L10.7541472,12.1853443 C11.7082786,13.1394756 13.1394756,11.7082786 12.1853443,10.7541472 L9.43119709,8 Z"
        opacity=".8"
      />
    </svg>
  );
}

export default ToggleUnheck;
