import React from "react";
import { Box } from "hw/ui/blocks";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import Select from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import type { Workflow } from "../types";
import { useActiveWorkflowIfExists, useLazyWorkflowNames } from "../queries";

type Props = {
  workflows: Array<Workflow>;
  onChange: (selectedGuid: string) => void;
  selectedWorkflow?: Workflow;
};

function WorkflowSelector(props: Props) {
  const { workflows, onChange, selectedWorkflow } = props;

  const options = workflows.map((workflow) => ({
    label: workflow.name,
    value: workflow.guid,
  }));

  return (
    <Box data-testid="workflow-selector" mr="xl">
      <Box mb="ms">
        <label>Select a form to send</label>
      </Box>
      <Box width="320px">
        <Select
          fillContainer
          // @ts-expect-error refactor
          onChange={(item) => {
            if (item) onChange(item.value);
          }}
          placeholder="Select Workflow..."
          {...getTestAttributes("launch-workflow-selector")}
          itemToString={(workflow) => (workflow ? workflow.name : null)}
          // @ts-expect-error refactor
          selectedItem={selectedWorkflow || null}
          render={({ getItemProps, highlightedIndex }) => (
            <MenuList>
              {options.map((option, index) => (
                <MenuItem
                  key={option.value}
                  active={index === highlightedIndex}
                  dataTrackId="launch-workflow_menu"
                  passthroughProps={getItemProps({
                    item: option,
                  })}
                >
                  <MenuItemText>{option.label}</MenuItemText>
                </MenuItem>
              ))}
            </MenuList>
          )}
        />
      </Box>
    </Box>
  );
}

export default WorkflowSelector;

type LazyWorkflowSelectorProps = {
  onChange: (selectedGuid: string) => void;
  value?: string;
};

export function LazyWorkflowSelector(props: LazyWorkflowSelectorProps) {
  const { onChange, value } = props;
  const [loadWorkflowNames, { loading, error, data }] = useLazyWorkflowNames();
  const activeWorkflow = useActiveWorkflowIfExists(value);

  const options = (data?.workflows ?? []).map((workflow) => ({
    label: workflow.name,
    value: workflow.guid,
  }));

  // The selected item comes first from the list of workflows. Since workflows
  // are loaded lazily, we may not have the selected item in the list yet. In
  // that case, we'll use the active workflow if it exists.
  let selectedItem = options.find((option) => option.value === value) ?? null;

  if (!selectedItem && activeWorkflow) {
    selectedItem = {
      label: activeWorkflow.name,
      value: activeWorkflow.guid,
    };
  }

  return (
    <Box data-testid="workflow-selector" mr="xl">
      <Box mb="ms">
        <label>Select a form to send</label>
      </Box>
      <Box
        width="320px"
        // @ts-expect-error types on this are a disaster
        onMouseOver={() => {
          loadWorkflowNames();
        }}
      >
        <Select
          fillContainer
          // @ts-expect-error refactor
          onChange={(item) => {
            if (item) onChange(item.value);
          }}
          onStateChange={(change: { isOpen: boolean }) => {
            if (change.isOpen) {
              loadWorkflowNames();
            }
          }}
          placeholder="Select Workflow..."
          {...getTestAttributes("launch-workflow-selector")}
          // @ts-expect-error refactor
          selectedItem={selectedItem}
          render={({ getItemProps, highlightedIndex }) => {
            if (loading) {
              return (
                <MenuItem disabled>
                  <MenuItemText>Loading workflows...</MenuItemText>
                </MenuItem>
              );
            }

            if (error) {
              return (
                <MenuItem disabled>
                  <MenuItemText>
                    There was an error loading the workflows
                  </MenuItemText>
                </MenuItem>
              );
            }

            if (options.length === 0) {
              return (
                <MenuItem disabled>
                  <MenuItemText>No published workflows yet</MenuItemText>
                </MenuItem>
              );
            }

            return (
              <MenuList>
                {options.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    active={index === highlightedIndex}
                    dataTrackId="launch-workflow_menu"
                    passthroughProps={getItemProps({
                      item: option,
                    })}
                  >
                    <MenuItemText>{option.label}</MenuItemText>
                  </MenuItem>
                ))}
              </MenuList>
            );
          }}
        />
      </Box>
    </Box>
  );
}
