import * as React from "react";
import { Tooltip } from "hw/ui/tooltip";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

export const BADGE_DIM = "24px";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'hasAbbr' implicitly has an 'any' ... Remove this comment to see the full error message
function Badge({ hasAbbr, hasBorder, ...rest }) {
  const cn = useStyle("badge", {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: BADGE_DIM,
    minWidth: BADGE_DIM,
    borderRadius: theme.corner.sm,
    borderWidth: hasBorder ? "2px" : "0",
    borderStyle: hasBorder ? "solid" : "initial",
    borderColor: hasBorder ? theme.color.gray100 : "initial",
    fontSize: "14px",
    fontWeight: theme.fontWeight.semibold,
    lineHeight: 1,
    color: theme.color.gray700,
    textDecoration: "none",
    paddingLeft: theme.space.xs,
    paddingRight: theme.space.xs,
    visibility: hasAbbr ? "visible" : "hidden", // If no children exist then allocate the space but don't show it.
  });
  return <abbr className={cn} {...rest} />;
}

type Props = {
  children: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  title?: string;
  hasBorder?: boolean;
  "data-testid"?: string;
};

function RoleBadge(props: Props) {
  const { title, children, position = "top", hasBorder = false } = props;
  const badge = (
    <Badge
      title={title}
      hasAbbr={!!children}
      hasBorder={hasBorder}
      data-testid={props["data-testid"]}
    >
      {children}
    </Badge>
  );
  return title ? (
    <Tooltip position={position} tip={title}>
      {badge}
    </Tooltip>
  ) : (
    badge
  );
}

RoleBadge.defaultProps = {
  "data-testid": "badge-icon",
};
export default React.memo(RoleBadge);
