import * as React from "react";

export default function PrefillIcon() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon 16 / pre-fill </title>
      <defs>
        <path
          d="M8.44847786,5 L9,5 C9.82404532,5 10.2944272,5.94076375 9.8,6.6 L8.3,8.6 C7.9,9.13333333 7.1,9.13333333 6.7,8.6 L5.2,6.6 C4.70557281,5.94076375 5.17595468,5 6,5 L6.43912319,5 C6.23401872,3.57190958 5.46751442,3 3.5,3 C2.94771525,3 2.5,2.55228475 2.5,2 C2.5,1.44771525 2.94771525,1 3.5,1 C6.72453632,1 8.16874046,2.45064104 8.44847786,5 Z M4,8 C4,8.55228475 3.55228475,9 3,9 L3,12 L13,12 L13,9 L12,9 C11.4477153,9 11,8.55228475 11,8 C11,7.44771525 11.4477153,7 12,7 L14,7 C14.5522847,7 15,7.44771525 15,8 L15,13 C15,13.5522847 14.5522847,14 14,14 L2,14 C1.44771525,14 1,13.5522847 1,13 L1,8 C1,7.44771525 1.44771525,7 2,7 L3,7 C3.55228475,7 4,7.44771525 4,8 Z"
          id="path-1"
        />
      </defs>
      <g
        id="icon-16-/-pre-fill-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" fill="#727A83" xlinkHref="#path-1" />
        <g
          id="icon-/-var-/-colors-/-dark-gray"
          mask="url(#mask-2)"
          fill="#555D65"
        >
          <g id="color-/-gray-/-700">
            <rect
              id="swatch"
              x="0"
              y="0"
              width="16.1616162"
              height="16.1616162"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
