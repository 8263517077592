/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { getIn } from "timm";
import cx from "classnames";
import IconWrapper, {
  DeleteIcon,
  CheckmarkIcon,
  DangerIcon,
} from "hw/ui/icons";
import metadata from "hw/sf/runtime/metadata";
import {
  validFileFormats,
  maxImportSize,
  bitesPerSecond,
  fileStatus,
} from "hw/sf/runtime/utils/file-attachment";
import { Tooltip } from "hw/ui/tooltip";
import { VisuallyHidden } from "hw/ui/text";
import ErrorMessage from "hw/common/components/smartforms/common/error-message";
import Message from "hw/sf/components/common/message";
import Label from "./common/label";
import styles from "./file-attachment.module.css";

type Props = {
  clearRequest?: (...args: Array<any>) => any;
  dataRef?: string;
  errorMessage?: string; // Any validation errors
  form: {
    guid: string;
  };
  handleVisited: (...args: Array<any>) => any; // Used to track the field for validation
  hidden: boolean;
  instructions?: string;
  isValid?: boolean;
  label?: string; // Label attached to the input
  onChange: (...args: Array<any>) => any; // Stores the data
  processFile?: (...args: Array<any>) => any;
  readOnly: boolean;
  required: boolean; // Is the input required
  value: {
    guid?: string;
    name?: string;
    size?: number;
  };
  validateAttachment: (...args: Array<any>) => any;
};

type State = {
  dropZoneActive: boolean;
};

/**
 * Allows the uploading of a file via the browsers native file manager or drag and drop.
 */
export default class FileAttachment extends React.Component<Props, State> {
  static defaultProps = {
    form: {
      guid: "",
    },
    handleVisited: () => {},
    onChange: () => {},
    hidden: false,
    readOnly: false,
    required: false,
    value: {},
    validateAttachment: () => {}, // Send the file to the backend for validation
  };

  inputRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      dropZoneActive: false,
    };

    /**
     * The hidden inputRef that serves as the button for opening the upload file dialog. The ref is used to fire the
     * click event on the hidden input. We do this so we don't have to hack around the file input ui the browser forces
     * us to use.
     */
    this.inputRef = React.createRef();
  }

  /**
   * Handle any asynchronous data that may be in a weird state since the user closed the form.
   */
  componentDidMount() {
    const { value, validateAttachment, form, dataRef } = this.props;
    const currentFileStatus = this.getCurrentFileStatus();

    // If we were in a pending state then see if we can resume, if not then wipe out the old data.
    if (currentFileStatus === fileStatus.pending) {
      const formInstanceId = form.guid;
      const { guid } = value;

      if (!guid) {
        this.deleteFile();
      } else {
        validateAttachment({
          formInstanceId,
          field: dataRef,
          attachment: guid,
        });
      }
    } else if (
      metadata.getDataTypeIsPresentValidator(metadata.dataTypeKeys.file)(
        value
      ) &&
      currentFileStatus !== fileStatus.success
    ) {
      this.deleteFile();
    }
  }

  onDragEnter = () => this.setState({ dropZoneActive: true });

  onDragLeave = () => this.setState({ dropZoneActive: false });

  /**
   * Required to make on drop work.
   * See https://developer.mozilla.org/en-US/docs/Web/Events/drop
   *
   * Also used to reapply the style since drag leave triggers on children.
   * Works because the order events are fired in is part of the specification.
   * See https://html.spec.whatwg.org/multipage/dnd.html#drag-and-drop-processing-model
   */
  onDragOver = () =>
    !this.state.dropZoneActive && this.setState({ dropZoneActive: true });

  onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const { processFile } = this.props;
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dropZoneActive: false,
    });

    const { dataTransfer } = event;
    const fileFromList = getIn(dataTransfer, ["items", 0]);
    const file =
      fileFromList && fileFromList.kind === "file"
        ? fileFromList.getAsFile()
        : dataTransfer.files[0];

    processFile(file);
  };

  /**
   * Handle the file object returned by the browsers file menu.
   */
  fileOnChange = (event: React.SyntheticEvent<HTMLInputElement>) =>
    this.props.processFile(getIn(event.target, ["files", 0]));

  /**
   * Cheat that allows us to hide the file input (which has styling issues) and instead launch it from a custom button.
   */
  triggerFileInput = () => this.inputRef.current.click();

  /**
   * Clear the file data for the attachment component.
   */
  deleteFile = () => {
    const { handleVisited, onChange, clearRequest } = this.props;
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.value = null;
    }

    // $FlowIgnore Unreachable if clearRequest doesn't exist, so ignore the flow null error
    clearRequest();
    handleVisited();
    onChange({}); // Results in a change to the states value so we don't manually have to set it.
  };

  canUploadFiles = () => this.props.processFile && !this.props.readOnly;

  isUploading = () => this.getCurrentFileStatus() === fileStatus.pending;

  getCurrentFileStatus = () => getIn(this.props.value, ["metadata", "status"]);

  /**
   * Is their a file in a state where it is being uploaded or has successfully uploaded. Returns false if the status is
   * not "valid", ie rejected.
   */
  isAcceptedUpload = () =>
    [fileStatus.success, fileStatus.pending].some(
      (status) => status === this.getCurrentFileStatus()
    );

  /**
   * Is their a file that has failed to be uploaded
   */
  isRejectedUpload = () =>
    this.getCurrentFileStatus() && !this.isAcceptedUpload();

  /**
   * Has a file been uploaded, true even if the file failed validation
   */
  isUploaded = () => !!this.getCurrentFileStatus();

  renderError = () => {
    const { isValid, errorMessage } = this.props;

    return (
      !isValid &&
      errorMessage && (
        <ErrorMessage className={styles.errorMessage} key="errorMessage">
          {typeof errorMessage === "object" ? (
            <Message {...errorMessage} />
          ) : (
            errorMessage
          )}
        </ErrorMessage>
      )
    );
  };

  renderLabel = () =>
    this.props.label && (
      <Label required={this.props.required} extend={{ margin: 0 }}>
        {this.props.label}
      </Label>
    );

  renderInstructions = () =>
    this.props.instructions && (
      <div className={styles.instructions} key="instructions">
        {this.props.instructions}
      </div>
    );

  renderFileSelect = () => {
    const { value } = this.props;
    const selectFileProps = {
      className: styles.selectFile,
      onClick: this.canUploadFiles() ? this.triggerFileInput : null,
      onKeyPress: this.canUploadFiles()
        ? (evt) => evt.key === "Enter" && this.triggerFileInput()
        : null,
    };

    return (
      <div {...selectFileProps} role="button" tabIndex="0">
        {this.isUploaded() ? (
          <span>
            <IconWrapper className={styles.statusIcon}>
              {this.isAcceptedUpload() ? <CheckmarkIcon /> : <DangerIcon />}
            </IconWrapper>
            <span>{value.name}</span>
          </span>
        ) : (
          <span>
            +{" "}
            <Message stringId="sf.components.smart-view.smart-form.smart-components.file-attachment.fileSelect" />
          </span>
        )}
      </div>
    );
  };

  renderContent = () => {
    const { processFile, value } = this.props;
    const canUpload = !processFile;

    const fileInput = this.canUploadFiles() && (
      <input
        type="file"
        className={styles.hidden}
        accept={validFileFormats}
        onChange={this.fileOnChange}
        ref={this.inputRef}
        data-qa-ref="file-attachment-file-input"
      />
    );

    const dropZoneContents = (
      <div className={styles.dropZoneContent}>
        {this.isUploading() ? (
          <div className={styles.loadingContainer}>
            <Message stringId="sf.components.smart-view.smart-form.smart-components.file-attachment.uploading" />
            <div className={styles.loadingBar}>
              <div
                className={styles.loadingBarInner}
                style={{
                  width: !value.guid ? "2%" : "100%",
                  transition: `width ${
                    (value.size || 0) / bitesPerSecond
                  }s ease-out`,
                }}
              />
            </div>
          </div>
        ) : (
          this.renderFileSelect()
        )}
        {this.isUploaded() && (
          <IconWrapper onClick={this.deleteFile} className={styles.delete}>
            <VisuallyHidden>Delete File</VisuallyHidden>
            <DeleteIcon />
          </IconWrapper>
        )}
      </div>
    );

    const dropZone = (
      <div className={styles.dropZone}>
        {canUpload ? (
          <Tooltip
            tip="Not available while workflow is being edited"
            active
            type="tooltip"
            position="top"
            justify="center"
          >
            {dropZoneContents}
          </Tooltip>
        ) : (
          dropZoneContents
        )}
      </div>
    );

    return (
      <div key="content">
        {fileInput}
        {dropZone}
      </div>
    );
  };

  renderFileHint = () => {
    const fileFormatString = (
      <Message
        stringId="sf.components.smart-view.smart-form.smart-components.file-attachment.fileList"
        values={{
          notLastItem: validFileFormats.slice(0, -1).join(", "),
          lastItem: validFileFormats.slice(-1),
        }}
      />
    );

    return (
      <div className={styles.fileHint} key="fileHint">
        <Message
          stringId="sf.components.smart-view.smart-form.smart-components.file-attachment.acceptedTypes"
          values={{ fileFormatString }}
        />
        <span> &#8226; </span>
        <Message
          stringId="sf.components.smart-view.smart-form.smart-components.file-attachment.maxSize"
          values={{ maxSize: maxImportSize / 1000000 }}
        />
      </div>
    );
  };

  render() {
    const { hidden, readOnly, value } = this.props;
    const { dropZoneActive } = this.state;
    const ddEnabled = !this.isUploading() && this.canUploadFiles();

    const attributes = {
      onDrop: ddEnabled ? this.onDrop : null,
      onDragOver: ddEnabled ? this.onDragOver : null,
      onDragEnter: ddEnabled ? this.onDragEnter : null,
      onDragLeave: ddEnabled ? this.onDragLeave : null,
      className: cx(
        styles.container,
        dropZoneActive && styles.dropZoneActive,
        this.isAcceptedUpload() && styles.valid,
        this.isRejectedUpload() && styles.rejected,
        this.isUploading() && styles.uploading
      ),
      hidden,
    };

    return (
      <div {...attributes}>
        {this.renderLabel()}
        {readOnly ? (
          <div className={styles.readOnlyContent}>{value && value.name}</div>
        ) : (
          [
            this.renderInstructions(),
            this.renderContent(),
            this.renderError(),
            this.renderFileHint(),
          ]
        )}
      </div>
    );
  }
}
