import * as React from "react";
import { getIn } from "timm";
import DraggableItem from "./components/draggable-item";
import AddButton from "./components/add-button";

export type Props = {
  /**
   * The copy for the add button. Defaults to "Add Item"
   */
  addText?: string;

  /**
   * Since this component deals with adding, deleting, and reordering items
   * in a list, the `index` is not a stable key value.  This function
   * takes in your item that you passed and should return the stable `key`
   * value for that item.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemKey: (item: any) => React.Key;

  /**
   * The items to render.  This should be a plain data value representation
   * of your items, like an array of objects.  Items can be rendered with the
   * `renderItem` prop
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>;

  /**
   * Meta should be an array of meta data to render alongside an item.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;

  /**
   * Called when adding a new element to the list
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAdd: (...args: Array<any>) => any;

  /**
   * Called when removing an item at a specific index
   */
  onRemove: (index: number) => void;

  /**
   * Called when moving an item from one index to another
   */
  onMove: (arg0: { fromIndex: number; toIndex: number }) => void;

  /**
   * Renders the item to a React component.  Use this to actually render your
   * data item.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderItem: (item: any, localIndex: number) => React.ReactNode;

  /**
   * Renders some additional content below the draggable item.  This is meant
   * to be used for basic display items like error messaging
   */
  renderItemMeta?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: any,
    localIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: any
  ) => React.ReactNode;

  /**
   * Array of strings defining actions not allowed for this list, e.g. "add", "delete"
   */
  disallow?: Array<string>;
  isModal?: boolean;
};
/**
 * This is a component for handling a list of items where items in the list can be
 * added or removed. Currently it's been developed with `input` elements as the
 * list but it could be refactored to handle other elements.
 *
 * Example:
 *
 * ```jsx
 * const items = [
 *   { key: "a", value: "A" },
 *   { key: "b", value: "B" },
 * ];
 *
 * <OrderableList
 *   items={items}
 *   getItemKey={(item) => item.key}
 *   onMove={({ fromIndex, toIndex }) => this.handleMove(fromIndex, toIndex)}
 *   onAdd={this.handleAdd}
 *   onRemove={(localIndex) => this.handleRemove(localIndex)}
 *   renderItem={(item) => item.value}
 * />;
 * ```
 *
 * If you're reordering a flat list, a helper is provided for reordering:
 *
 * ```jsx
 * import { reorder } from 'hw/ui/orderableList';
 *
 * handlerReorder({ fromIndex, toIndex }) {
 *   const reorderedList = reorder(myList, fromIndex, toIndex)
 * }
 * ```
 *
 * If you need to render additional information below the draggable item, you can
 * use the `renderItemMeta` prop:
 *
 * <OrderableList items={items} getItemKey={item => item.key} onMove={({ fromIndex,
 * toIndex }) => this.handleMove(fromIndex, toIndex)} onAdd={this.handleAdd}
 * onRemove={localIndex => this.handleRemove(localIndex)} renderItem={item =>
 * item.value} renderItemMeta={(item, index) => hasError(item) ? <div>error!</div>
 * : null} />;
 *
 * Just remember to reference stable key values when determining when to render the
 * meta information. See below.
 *
 * ## Stable Key Values
 *
 * Since this component deals in adding, removing, and reordering values in the
 * list, the `index` is not a stable `key` value for rendering the options. That
 * means that the `items` value that you provided should have some mechanism for
 * generating a stable key for that item. React needs to know which item is which
 * regardless of what the local index is in the array.
 *
 * ## Drag and Drop Interactions
 *
 * This component uses React DnD for handling the drag interactions, so you must
 * have a few things set up prior to using:
 *
 * - The `DragDropContext` from `react-dnd`. This is generally set up at the root
 *   of your app:
 *
 * ```jsx
 * import { DragDropContext } from "react-dnd";
 * import HTML5Backend from "react-dnd-html5-backend";
 *
 * function RootApp() {
 *   // ...
 * }
 *
 * export default DragDropContext(HTML5Backend)(RootApp);
 * ```
 *
 * - The custom `DragPreviewLayer` component
 *
 * The HTML5 drag and drop API requires that an element be rendered into the DOM in
 * order for a custom preview rendering to be used. This is generally done once for
 * the whole app, and each item type is rendered to a different component according
 * to its `type`.
 *
 * ```jsx
 * import { DragDropContext } from "react-dnd";
 * import HTML5Backend from "react-dnd-html5-backend";
 * import DragPreviewLayer from "./DragPreviewLayer";
 *
 * function RootApp() {
 *   <div>
 *     <DragPreviewLayer />
 *   </div>;
 * }
 *
 * export default DragDropContext(HTML5Backend)(RootApp);
 * ```
 *
 */

export default function OrderableList(props: Props) {
  const {
    addText,
    items,
    isModal,
    onMove,
    onRemove,
    onAdd,
    getItemKey,
    renderItem,
    renderItemMeta,
    meta,
    disallow,
  } = props;
  const allowAdd = disallow ? !disallow.includes("add") : true;

  return (
    <div>
      {items.map((item, index) => (
        /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
         * found when upgrading Flow to v0.132.0. To view the error, delete
         * this comment and run Flow. */
        <DraggableItem
          onMove={onMove}
          onRemove={onRemove}
          key={getItemKey(item)}
          item={item}
          index={index}
          renderItem={renderItem}
          renderItemMeta={renderItemMeta}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          meta={getIn(meta, [index])}
          isLast={index === items.length - 1}
          isModal={isModal}
          disallow={disallow}
        />
      ))}
      {allowAdd && <AddButton onClick={onAdd}>{addText || null}</AddButton>}
    </div>
  );
}
OrderableList.defaultProps = {
  addText: "Add Item",
};
