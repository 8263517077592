/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { useMutation } from "@apollo/client";
import { createLogger } from "hw/common/utils/logger";
import {
  useSession,
  useDispatch as useSessionDispatch,
  ActionTypes,
} from "hw/portal/modules/session";
import type { Session } from "hw/portal/modules/session";
import UpdateSettings from "hw/portal/modules/team-settings/graphql/save-settings.graphql";
import Modal from "./modal";

const logger = createLogger("signup-completion");

type Props = {
  children: (modal: React.ReactNode | null | undefined) => React.ReactNode;
};

type State = {
  submitting: boolean;
};

/**
 * Handles checking if the user has completed their signup process and, if not,
 * displays a modal to prompt them for the remaining information.  This is done
 * at the root of the application as a render-prop pattern so that the modal
 * can be overlayed onto whatever route is currently rendering
 *
 * @example
 * <WithCompletedSignup>
 *   {modalOrNull => (
 *      <React.Fragment>
 *        {modalOrNull}
 *        <div>The app</div>
 *      </React.Fragment>
 *   )}
 * </WithCompletedSignup>
 */
class WithCompletedSignup extends React.Component<
  Props & {
    session: Session;
    sessionDispatch: Function;
    saveTeamName: Function;
  },
  State
> {
  state = {
    submitting: false,
  };

  handleCompletedSignup = () => {
    const { sessionDispatch } = this.props;

    sessionDispatch({ type: ActionTypes.UpdateTeamName });

    this.setState({ submitting: false });
  };

  handleSubmit = (name: string) => {
    this.setState({ submitting: true });
    const { saveTeamName, session } = this.props;
    const { guid } = session.user.team;

    // @ts-expect-error refactor
    return saveTeamName(guid, name).then(this.handleCompletedSignup, (err) => {
      this.setState({ submitting: false });
      logger.error(err);
    });
  };

  render() {
    const { submitting } = this.state;
    const { isSignupComplete } = this.props.session.user;

    // If the signup has been completed, render without any modal
    // Otherwise, render the modal and pass it to the calling component to
    // place within the layout
    return isSignupComplete
      ? this.props.children(null)
      : this.props.children(
          <Modal onSubmit={this.handleSubmit} submitting={submitting} />
        );
  }
}

export default function WithSession(props: Props) {
  const session = useSession();
  const sessionDispatch = useSessionDispatch();

  const [updateSettings] = useMutation(UpdateSettings);
  // @ts-expect-error refactor
  const saveTeamName = (guid, name) => {
    return updateSettings({ variables: { input: { guid, name } } });
  };

  return (
    <WithCompletedSignup
      {...props}
      session={session}
      sessionDispatch={sessionDispatch}
      saveTeamName={saveTeamName}
    />
  );
}
