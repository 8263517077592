import { createComponent } from "hw/ui/style";
import { testAttributeNames } from "hw/common/utils/get-test-attributes";
import constant from "lodash/constant";
import matchesProperty from "lodash/matchesProperty";
import cond from "lodash/cond";
import { TYPES } from "hw/ui/status-message";

const success = matchesProperty("type", TYPES.SUCCESS);
const error = matchesProperty("type", TYPES.DANGER);
const warning = matchesProperty("type", TYPES.WARNING);
const info = matchesProperty("type", TYPES.INFO);
const fallback = constant(true);

const themeColor =
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.


    (color) =>
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'theme' implicitly has an 'any' ty... Remove this comment to see the full error message
    ({ theme }) =>
      theme.color[color];

const backgroundColor = cond([
  [success, themeColor("green050")],
  [warning, themeColor("yellow050")],
  [error, themeColor("red050")],
  [info, themeColor("blue050")],
  [fallback, themeColor("green050")],
]);

const borderColor = cond([
  [success, themeColor("green100")],
  [warning, themeColor("yellow100")],
  [error, themeColor("red100")],
  [info, themeColor("blue100")],
  [fallback, themeColor("green100")],
]);

export const Wrapper = createComponent(
  function WrapperBanner({ theme, isOpen, type }) {
    return {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: backgroundColor({
        theme,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ theme: any; type: any; }' is n... Remove this comment to see the full error message
        type,
      }),
      borderColor:
        isOpen &&
        borderColor({
          theme,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ theme: any; type: any; }' is n... Remove this comment to see the full error message
          type,
        }),
      borderStyle: isOpen && "solid",
      borderWidth: theme.corner.xs,
      borderRadius: theme.corner.sm,
      width: "100%",
      padding: theme.space.sm,
      justifyContent: "space-between",
      maxHeight: isOpen ? "100px" : 0,
      overflow: "auto",
    };
  },
  "div",
  ["data-test-ref", "data-testid"].concat(testAttributeNames)
);
