import * as React from "react";
import { Input } from "hw/ui/input";
import { Flex, Box } from "hw/ui/blocks";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { InfoIcon } from "hw/ui/icons/svg-icons/info-icon";
import { Wrapper } from "./basics";
import { Actions } from "../../../state";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: (...args: Array<any>) => any;
  path: Array<string | number>;
  placeholder?: string;
  value?: number;
  ceiling: number;
};
export default function CharacterLimit(props: Props) {
  const { editorDispatch, path, placeholder, value = "", ceiling } = props;
  const [hitCeiling, setHitCeiling] = React.useState(false);
  const handleChange = React.useCallback(
    (evt: React.SyntheticEvent<HTMLInputElement>) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      const { value } = evt.target;
      const CHAR_FLOOR = 1;

      const adjustValue = (val: number) => {
        if (val > ceiling) {
          setHitCeiling(true);
          return ceiling;
        }

        if (hitCeiling) setHitCeiling(false);
        if (val < CHAR_FLOOR) return CHAR_FLOOR;
        return val;
      };

      if (value || value === 0) {
        const adjustedValue = adjustValue(Number(value));
        editorDispatch(
          Actions.ChangeFieldSetting({
            path,
            // $FlowIgnore We verify it's an int above
            updater: () => Math.floor(adjustedValue),
          })
        );
      }
    },
    [ceiling, editorDispatch, hitCeiling, path]
  );
  const description = React.useMemo(() => {
    if (Number.isNaN(value)) return "Invalid value";
    const integerValue = Number(value);
    if (integerValue < 100) return "1 short sentence";
    if (integerValue >= 100 && integerValue < 200) return "1-2 sentences";
    if (integerValue >= 200 && integerValue < 500) return "A few sentences";
    if (integerValue >= 500) return "1-2 paragraphs";
  }, [value]);
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type '{ children: (fal... Remove this comment to see the full error message
    <Wrapper label="Character limit">
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; justifySelf: string; ... Remove this comment to see the full error message */}
      <Flex justifySelf="end" width={1} position="relative">
        <Box width="80px">
          <Input
            value={value}
            onChange={handleChange}
            data-testid={`field-settings-character-limit`}
            data-guide-id={`field-settings-character-limit`}
            type="number"
            placeholder={placeholder}
          />
        </Box>
        <Flex
          color="textDim"
          fontSize="sm"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string | undefined; color: strin... Remove this comment to see the full error message
          paddingLeft="ms"
          alignItems="center"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {description}
        </Flex>
      </Flex>
      {hitCeiling && <MaxCharMessage ceiling={ceiling} />}
    </Wrapper>
  );
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'ceiling' implicitly has an 'any' ... Remove this comment to see the full error message
function MaxCharMessage({ ceiling }) {
  const cn = useStyle("max-chars-reached", {
    display: "flex",
    gridColumn: "1 / span 2",
    fontSize: theme.fontSize.sm,
    paddingTop: theme.space.sm,
  });
  const numberCn = useStyle("max-chars-number", {
    fontWeight: theme.fontWeight.semibold,
    display: "contents",
  });
  return (
    <div className={cn}>
      <Flex mr="xs">
        <InfoIcon />
      </Flex>
      <span>
        The output field on this PDF will currently fit approximately{" "}
        <div className={numberCn}>{ceiling}</div> characters.
      </span>
    </div>
  );
}
