import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  label: string | React.ReactNode;
  disabled?: boolean;
  extend?: Extend;
};

function MenuItemGroupLabel(props: Props) {
  const { label, extend = {} } = props;

  const cn = useStyle("ui-menu-item-group-label", style(props), extend);

  return <p className={cn}>{label}</p>;
}

const style = ({ disabled }: Props) => ({
  padding: theme.space.sm,
  // avoid margin shorthand here as there is an extend that overwrites the left value.
  // will not be overwritten if declared in shorthand, in a non-dev environment
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
  textTransform: "uppercase",
  color: disabled ? theme.color.gray500 : theme.color.gray700,
  fontSize: theme.fontSize.sm,
  letterSpacing: "0.05em",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: theme.fontWeight.semibold,
  cursor: "default",
});

export default MenuItemGroupLabel;
