import * as React from "react";
import { testAttributeNames } from "hw/common/utils/get-test-attributes";
import { createComponent, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { BADGE_DIM } from "./preview/role-badge";
// TODO: Refactor the styles in this folder
// This is a hacky but quick way to add hover state styles on the drag handle
// from the parent wrapper.
const DragHandleStyleHook = "__drag-handle-hook__";
export function Wrapper() {
  return {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
}
export const PreviewPane = createComponent(() => ({
  display: "flex",
  flexDirection: "row",
  height: "100%",
}));
export const ScrollContainer = createComponent(
  function ScrollContainer() {
    return {
      overflowY: "auto",
      height: "100%",
      flex: 1,
    };
  },
  "div",
  ["onClick", "data-testid"]
);
export const Canvas = createComponent(function Canvas() {
  return {
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: "704px",
    minWidth: "400px",
  };
});
export const PreviewContainer = createComponent(
  function PreviewContainer({ theme }) {
    return {
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
      borderRadius: theme.corner.sm,
      backgroundColor: theme.color.white,
      padding: theme.space.md,
      flex: 1,
      minHeight: "60vh",
    };
  },
  "div",
  testAttributeNames
);
export const FixedPanel = createComponent(
  function SettingsPanel({ theme }) {
    return {
      display: "flex",
      flexDirection: "column",
      borderLeftColor: theme.color.dividerDim,
      borderLeftWidth: "1px",
      borderLeftStyle: "solid",
      flexGrow: 0,
      flexShrink: 0,
      top: "0px",
      width: "360px",
      minWidth: 0,
    };
  },
  "div",
  testAttributeNames
);
export function SettingsContainer() {
  return {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  };
}
export const RoleBadgeContainer = React.memo(
  createComponent(
    function RoleBadgeContainer({ theme }) {
      return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: theme.fontWeight.bold,
        paddingTop: theme.space.xs,
        paddingLeft: theme.space.xs,
        paddingRight: theme.space.xs,
        color: theme.color.gray500,
        textAlign: "center",
        userSelect: "none",
        flexShrink: 0,
        flexGrow: 0,
        minWidth: `calc(${BADGE_DIM} + ${theme.space.xs} * 2)`,
      };
    },
    "div",
    ["data-testid"]
  )
);
export const Brackets = createComponent(
  function Brackets({ theme, bracketTop }) {
    return {
      marginLeft: theme.space.xs,
      borderColor: theme.color.gray100,
      borderWidth: "2px",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderTopStyle: bracketTop && "solid",
      position: "absolute",
      width: "8px",
      top: "5px",
      height: "calc(100% - 10px)",
      borderRadius: theme.corner.sm,
    };
  },
  "div",
  ["data-testid"]
);
export function DragHandle({
  isDragging,
  connectDragSource,
  isSelected,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const cn = useStyle("drag-handle", {
    transitionProperty: "border-color, background",
    transitionDuration: theme.transitionSpeed.fast,
    cursor: isDragging ? "grabbing" : ["move", "grab"],
    position: "relative",
    borderTopLeftRadius: theme.corner.sm,
    borderBottomLeftRadius: theme.corner.sm,
    ":hover": {
      backgroundColor: theme.color.gray200,
    },
    ":after": {
      transitionProperty: "border-color, background",
      transitionDuration: theme.transitionSpeed.fast,
      content: '""',
      top: 0,
      display: "block",
      width: "2px",
      borderRadius: "2px",
      height: "100%",
      backgroundColor: theme.color.gray200,
      position: "absolute",
      right: 0,
    },
  });
  return connectDragSource(
    <div {...rest} className={`${cn} ${DragHandleStyleHook}`} />
  );
}
export const RoleBadge = createComponent(function RoleBadge({ theme }) {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    width: "32px",
    borderRadius: theme.corner.sm,
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.color.dividerDim,
    fontSize: "14px",
    fontWeight: theme.fontWeight.semibold,
    lineHeight: 1,
    color: theme.color.gray700,
  };
}, "abbr");
export function FieldPreviewWrapper({
  isSelected,
  isDragging,
  isDragLayer,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const { gray100, gray200, gray300, gray500, white } = theme.color;
  const showBorder = isSelected || isDragging || isDragLayer;

  /* eslint-disable no-nested-ternary */
  const cn = useStyle("field-wrapper", {
    transitionProperty: "border-color, background",
    transitionDuration: theme.transitionSpeed.fast,
    opacity: isDragLayer ? 0.9 : 1,
    background: isDragLayer
      ? gray200
      : isSelected || isDragging
      ? gray100
      : white,
    display: "flex",
    borderColor: showBorder ? gray500 : "transparent",
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: theme.corner.md,
    marginBottom: theme.space.ms,
    flexDirection: "row",
    width: "100%",
    [`> .${DragHandleStyleHook}:after`]: {
      backgroundColor: isSelected && "transparent",
    },
    ":hover": {
      borderColor: !isSelected && !isDragging && gray300,
      backgroundColor: gray100,
      [`> .${DragHandleStyleHook}:after`]: {
        backgroundColor: "transparent",
      },
    },
  });

  /* eslint-enable no-nested-ternary */
  return <div {...rest} className={cn} />;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FieldPreviewContentWrapper({ type, isSelected, ...rest }: any) {
  const cn = useStyle("field-preview-content", {
    paddingTop: type !== "Paragraph" && type !== "Group" && theme.space.md,
    paddingLeft: type !== "Paragraph" && type !== "Group" && theme.space.ms,
    paddingRight: type !== "Paragraph" && type !== "Group" && theme.space.ms,
    paddingBottom: type !== "Paragraph" && theme.space.ms,
    width: "100%",
    backgroundColor: theme.color.white,
    borderTopRightRadius: theme.corner.sm,
    borderBottomRightRadius: theme.corner.sm,
    minWidth: 0,
  });
  return <div {...rest} className={cn} />;
}
