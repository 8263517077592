import * as React from "react";

export default function Menu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        id="menu-24-a"
        d="M3,21 C2.17157288,21 1.5,20.3284271 1.5,19.5 C1.5,18.6715729 2.17157288,18 3,18 L21,18 C21.8284271,18 22.5,18.6715729 22.5,19.5 C22.5,20.3284271 21.8284271,21 21,21 L3,21 Z M3,6 C2.17157288,6 1.5,5.32842712 1.5,4.5 C1.5,3.67157288 2.17157288,3 3,3 L21,3 C21.8284271,3 22.5,3.67157288 22.5,4.5 C22.5,5.32842712 21.8284271,6 21,6 L3,6 Z M3,13.5 C2.17157288,13.5 1.5,12.8284271 1.5,12 C1.5,11.1715729 2.17157288,10.5 3,10.5 L21,10.5 C21.8284271,10.5 22.5,11.1715729 22.5,12 C22.5,12.8284271 21.8284271,13.5 21,13.5 L3,13.5 Z"
      />
    </svg>
  );
}
