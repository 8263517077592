import { createComponent } from "hw/ui/style";
import * as React from "react";

const HiddenContent = createComponent(
  ({ hidden }) => ({
    opacity: hidden ? 0 : 1,
    transition: "opacity 150ms",
    ":hover": {
      opacity: 1,
    },
  }),
  "div",
  ["onFocus", "onBlur"]
);
type Props = {
  children: React.ReactNode;
};
type State = {
  hidden: boolean;
};
/**
 * A component that visually hides its child content but is still accessible via
 * tab navigation and hover.
 *
 * NOTE: This could potentially be abstracted but it's quite specific to the
 * form builder functionality, so leaving it here for now.
 *
 * @example
 * <VisuallyHidden>
 *      <MyComponent />
 * </VisuallyHidden>
 */

export default class VisuallyHidden extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);

    this.state = {
      hidden: true,
    };
  }

  hide() {
    this.setState({
      hidden: true,
    });
  }

  show() {
    this.setState({
      hidden: false,
    });
  }

  render() {
    const { hidden } = this.state;
    const { children } = this.props;
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: ReactNode; hidden: boolean; onFo... Remove this comment to see the full error message
      <HiddenContent hidden={hidden} onFocus={this.show} onBlur={this.hide}>
        {children}
      </HiddenContent>
    );
  }
}
