import * as React from "react";

export default function Sparkle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0143 2.38472C13.1366 1.87306 13.8645 1.87306 13.9869 2.38472L14.9458 6.396C15.4435 8.47792 17.0127 10.137 19.0637 10.7498L21.6442 11.5208C22.12 11.663 22.12 12.3368 21.6442 12.4789L19.0636 13.25C17.0126 13.8628 15.4434 15.5219 14.9457 17.6038L13.9869 21.6146C13.8645 22.1263 13.1366 22.1263 13.0143 21.6146L12.0554 17.6038C11.5577 15.5219 9.98853 13.8628 7.93753 13.25L5.35673 12.4789C4.88092 12.3368 4.88092 11.663 5.35673 11.5208L7.93742 10.7497C9.98842 10.1369 11.5576 8.47786 12.0553 6.39593L13.0143 2.38472ZM5.15385 2.3018C5.21355 1.90449 5.78637 1.90448 5.84607 2.3018L6.05694 3.70498C6.17536 4.49302 6.7503 5.13555 7.52039 5.34049L8.74182 5.66554C9.08847 5.7578 9.08847 6.24975 8.74182 6.342L7.52029 6.66708C6.75021 6.87201 6.17527 7.51455 6.05685 8.30259L5.84607 9.70515C5.78637 10.1025 5.21355 10.1025 5.15385 9.70516L4.94307 8.30258C4.82465 7.51454 4.24971 6.872 3.47962 6.66706L2.25816 6.342C1.91151 6.24975 1.91151 5.7578 2.25816 5.66554L3.47953 5.34051C4.24962 5.13557 4.82455 4.49303 4.94298 3.70499L5.15385 2.3018Z"
        fill="#9844CD"
      />
    </svg>
  );
}
