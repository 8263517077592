/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import type { ApolloError } from "@apollo/client";
import { getIn } from "timm";
import ShareableLink from "hw/portal/modules/common/components/shareable-link";
import * as Sentry from "@sentry/browser";
import {
  retrieveErrorCode,
  msgForCodeShareableLink,
} from "hw/portal/modules/common/components/errors";
import withGraphQL from "../graphql/container";
import type {
  ShareableLinkInterfaceResponse,
  ShareableLinkInput,
  ShareableLink as ShareableLinkType,
} from "../types";

type SuccessResult = { response: any; error: null };
type ErrorResult = { response: null; error: ApolloError };
type Result = SuccessResult | ErrorResult;

type Props = React.ComponentProps<typeof ShareableLink> & {
  /**
   * Mutation for enabling the link
   */
  enableShareableLink: (input: ShareableLinkInput) => Promise<Result>;

  /**
   * Mutation for disabling the link
   */
  disableShareableLink: (input: ShareableLinkInput) => Promise<Result>;

  workflow: {
    guid: string;
    name: string;
    mergeFields: Array<any>;
    roles: Array<{ id: string; label: string }>;
  };
  addSuccess: Function;
  addDanger: Function;
  refetch?: Function;
};

type State = {
  saving: boolean;
};

/**
 * This is a GraphQL-connected version of the shareable links interface.  It takes
 * the same props as the unconnected version, but also handles the GraphQL mutations
 * internally. At time of writing, we are currently only using this version.
 */
class ConnectedShareableLink extends React.Component<Props, State> {
  _isMounted = false;

  state = {
    saving: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Takes the confirmation output from the shareable link interface and enables
   * or disables the link for the workflow
   */
  updateShareableLink = async (config: ShareableLinkInterfaceResponse) => {
    const { enableShareableLink, disableShareableLink, refetch } = this.props;
    const { linkIsEnabled, workflowGuid, linkGuid, onError } = config;

    const input = {
      guid: workflowGuid,
    };

    this.setState({ saving: true });

    if (linkIsEnabled) {
      const result = await enableShareableLink(input);

      if (result.error) {
        shareableLinkGraphQLError(input.guid, linkGuid, result.error);
        this.setState({ saving: false });
        onError();
        return this.onUpdateShareableLinkError(result.error);
      }

      const createdLink = result.response.data.enableShareableLink;
      const successMessage = <LinkEnabled />;

      const updatedConfig = {
        ...config,
        successMessage,
      };

      this.onUpdateShareableLink(createdLink, updatedConfig);

      // refetch the draft after generating the link if this is the first time enabling it
      if (refetch && !this.getLinkUrl()) refetch();
    } else {
      const result = await disableShareableLink(input);

      if (result.error) {
        shareableLinkGraphQLError(input.guid, linkGuid, result.error);
        this.setState({ saving: false });
        onError();
        // @ts-expect-error refactor
        return this.onUpdateShareableLinkError();
      }

      const updatedLink = result.response.data.disableShareableLink;
      const updatedConfig = {
        ...config,
        successMessage: <LinkDisabled />,
      };

      this.onUpdateShareableLink(updatedLink, updatedConfig);
    }

    if (this._isMounted) {
      this.setState({ saving: false });
    }
  };

  onUpdateShareableLink = async (
    link: ShareableLinkType,
    config: ShareableLinkInterfaceResponse
  ) => {
    if (!this._isMounted) {
      return;
    }

    if (config.successMessage) {
      this.props.addSuccess(config.successMessage);
    }
  };

  /* $FlowFixMe[value-as-type] $FlowFixMe This comment suppresses an error found
   * when upgrading Flow to v0.132.0. To view the error, delete this comment and
   * run Flow. */
  onUpdateShareableLinkError = (error: ApolloError) => {
    const { addDanger } = this.props;

    if (addDanger) addDanger(shareableLinkError(error));
  };

  getLinkUrl = () => {
    return getIn(this.props.workflow, ["shareableLink", "url"]) || "";
  };

  getWorkflowName = () => {
    return this.props.workflow?.name;
  };

  linkIsEnabled = () => {
    return (
      getIn(this.props.workflow, ["shareableLink", "isDisabled"]) === false
    );
  };

  getLinkGuid = () => {
    return getIn(this.props.workflow, ["shareableLink", "guid"]);
  };

  getWorkflowGuid = () => {
    return this.props.workflow?.guid;
  };

  getWorkflowParticipantMode = () => {
    const roleCount = this.props.workflow.roles.length;

    return roleCount > 1 ? "multi" : "single";
  };

  render() {
    return (
      <ShareableLink
        {...this.props}
        onConfirm={this.updateShareableLink}
        saving={this.state.saving}
        // @ts-expect-error refactor
        linkUrl={this.getLinkUrl()}
        linkIsEnabled={this.linkIsEnabled()}
        // @ts-expect-error refactor
        linkGuid={this.getLinkGuid()}
        workflowGuid={this.getWorkflowGuid()}
        workflowName={this.getWorkflowName()}
        workflowParticipantMode={this.getWorkflowParticipantMode()}
      />
    );
  }
}

function shareableLinkGraphQLError(
  wfGuid: string,
  linkGuid: string | null | undefined,
  err: ApolloError
) {
  // @ts-expect-error Can we type this better?
  const errorCode = err?.graphQLErrors?.[0]?.code ?? null;

  if (errorCode === 402) {
    return;
  }

  Sentry.captureException(err, {
    contexts: {
      link: { guid: linkGuid },
    },
  });
}

function LinkEnabled() {
  return (
    <FormattedMessage
      id="WorkflowLibrary.linkEnabledSuccess"
      defaultMessage="Shareable link enabled!"
    />
  );
}

function LinkDisabled() {
  return (
    <FormattedMessage
      id="WorkflowLibrary.linkDisableSuccess"
      defaultMessage="Shareable link disabled"
    />
  );
}

/* $FlowFixMe[value-as-type] $FlowFixMe This comment suppresses an error found
 * when upgrading Flow to v0.132.0. To view the error, delete this comment and
 * run Flow. */
function shareableLinkError(error?: ApolloError) {
  const defaultError =
    "There was an error updating the shareable link.  This has been reported to our engineering team.";
  // @ts-expect-error refactor
  const errorCode = error ? retrieveErrorCode(error) : null;

  if (!errorCode) return defaultError;

  // @ts-expect-error refactor
  return msgForCodeShareableLink[errorCode] ?? defaultError;
}

// @ts-expect-error refactor
export default withGraphQL(ConnectedShareableLink);
