import React from "react";

export default function CheckmarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#24B34A"
        fillRule="nonzero"
        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm0-1a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm2.485 4.718a.5.5 0 0 1 .846.533l-3.734 5.923a.5.5 0 0 1-.784.08l-2.19-2.292a.5.5 0 1 1 .723-.691l1.747 1.828 3.392-5.381z"
      />
    </svg>
  );
}
