import * as React from "react";
import { createComponent } from "hw/ui/style";
import type { Guid } from "hw/portal/modules/common/types";
import type { Form } from "hw/portal/modules/common/draft";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import Sidebar from "./sidebar";
import SidebarLink from "./sidebar-link";
import * as Validation from "../validation";

type Props = {
  activeId?: string;
  forms: Array<Form>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreate: (...args: Array<any>) => any;

  /* $FlowFixMe[value-as-type] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  onSelect: (guid: Guid) => void;
  onToggle: (isOpen: boolean) => void;
  open: boolean;
  title: React.ReactNode;
  errors: Array<DraftFeedback>;
};
const Wrapper = createComponent(function SidebarLinkWrapper() {
  return {
    display: "inline-flex",
  };
});
const ErrorMark = createComponent(function ErrorMark({ theme }) {
  return {
    backgroundColor: theme.color.red600,
    width: "3px",
  };
});
export function FormEditorSidebar(props: Props) {
  const { activeId, forms, onCreate, onSelect, onToggle, open, title, errors } =
    props;
  const sideBarItems = forms.map((form, idx) => (
    <Wrapper key={form.id}>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{}' but requi... Remove this comment to see the full error message */}
      {!Validation.isFormValid(form, idx, errors) && <ErrorMark />}
      <SidebarLink
        onClick={(evt) => {
          evt.preventDefault();
          // @ts-expect-error refactor
          onSelect(form);
        }}
        active={form.id === activeId}
        data-test-ref="sidebar-link"
        data-testid="sidebar-link"
      >
        {form.name}
      </SidebarLink>
    </Wrapper>
  ));
  return (
    <Sidebar title={title} open={open} onToggle={onToggle}>
      {sideBarItems}
      <SidebarLink
        onClick={onCreate}
        data-test-ref="new-form-cta"
        data-track-id="editor-new_form"
      >
        + Add Form
      </SidebarLink>
    </Sidebar>
  );
}
export default FormEditorSidebar;
