import * as React from "react";

export default function BlankDocumentIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 31.7782V11C9 8.79086 10.7909 7 13 7H27.4808M15.1058 42H35C37.2091 42 39 40.2091 39 38V18.9335"
        stroke="#959CA4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 4"
      />
      <path
        d="M31.5413 9.09381C30.8196 9.02689 30.8196 7.97311 31.5413 7.90619L33.9858 7.67954C35.415 7.54702 36.547 6.41502 36.6795 4.98577L36.9062 2.5413C36.9731 1.81957 38.0269 1.81957 38.0938 2.5413L38.3205 4.98577C38.453 6.41502 39.585 7.54702 41.0142 7.67954L43.4587 7.90619C44.1804 7.97311 44.1804 9.02689 43.4587 9.09381L41.0142 9.32046C39.585 9.45298 38.453 10.585 38.3205 12.0142L38.0938 14.4587C38.0269 15.1804 36.9731 15.1804 36.9062 14.4587L36.6795 12.0142C36.547 10.585 35.415 9.45298 33.9858 9.32046L31.5413 9.09381Z"
        fill="#959CA4"
      />
      <path
        d="M3.64791 40.7036L2.61853 40.6169C1.79382 40.5473 1.79382 39.4527 2.61853 39.3831L3.64791 39.2964C5.28108 39.1587 6.57459 37.9828 6.72602 36.4981L6.82146 35.5623C6.89793 34.8126 8.10207 34.8126 8.17854 35.5623L8.27398 36.4981C8.42541 37.9828 9.71892 39.1587 11.3521 39.2964L12.3815 39.3831C13.2062 39.4527 13.2062 40.5473 12.3815 40.6169L11.3521 40.7036C9.71892 40.8413 8.42541 42.0172 8.27398 43.5019L8.17854 44.4377C8.10207 45.1874 6.89793 45.1874 6.82146 44.4377L6.72602 43.5019C6.57459 42.0172 5.28108 40.8413 3.64791 40.7036Z"
        fill="#959CA4"
      />
      <path
        d="M40.5194 14.4302C41.5332 14.3362 42.3362 13.5332 42.4302 12.5194C42.4944 11.8269 43.5056 11.8269 43.5698 12.5194C43.6638 13.5332 44.4668 14.3362 45.4806 14.4302C46.1731 14.4944 46.1731 15.5056 45.4806 15.5698C44.4668 15.6638 43.6638 16.4668 43.5698 17.4806C43.5056 18.1731 42.4944 18.1731 42.4302 17.4806C42.3362 16.4668 41.5332 15.6638 40.5194 15.5698C39.8269 15.5056 39.8269 14.4944 40.5194 14.4302Z"
        fill="#959CA4"
      />
    </svg>
  );
}
