/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import CaretDownIcon from "hw/ui/icons/svg-icons/caret-down-icon";
import CloseIcon from "hw/ui/icons/svg-icons/close-icon-12";
import MagnifyingGlass from "hw/portal/modules/common/images/magnifying-glass.svg";
import { Input } from "hw/ui/input";
import theme from "hw/ui/theme";
import { useCss } from "hw/ui/style";
import type { TriggerProps } from "../dropdown/types";

type Props = TriggerProps & {
  /**
   * The placeholder to display when nothing is selected.
   */
  placeholder: string;

  /**
   * Display magnifying glass icon on left part of input field
   */
  showSearchIcon?: boolean;

  /**
   * Function that determines if the selected item should appear
   * with a special style.
   * NOTE: If not defined the trigger will work normally, this is
   * a special hacky case while we refactor the select styles
   */
  displaySelectedItem?: (...args: Array<any>) => any;
};

function InputTrigger(props: Props) {
  const {
    disabled,
    placeholder,
    triggerPassthroughProps,
    openMenu,
    selectedItem,
    clearSelection,
    id,
    showSearchIcon,
    displaySelectedItem,
  } = props;
  const { getInputProps, getButtonProps } = triggerPassthroughProps;
  const css = useCss(props);
  const inputRef = React.useRef(null);
  const [inputFocused, setFocused] = React.useState(false);
  const [inputHovered, setHovered] = React.useState(false);
  // Show the clear button if:
  //   - there's a selected item
  //   - the input focused OR the input is hovered
  const showClearBtn = Boolean(selectedItem && (inputFocused || inputHovered));
  const onClear = React.useCallback(
    (...args) => {
      // @ts-expect-error refactor
      inputRef.current.focus();
      clearSelection(...args);
    },
    [inputRef, clearSelection]
  );
  const onInputClick = React.useCallback(
    (...args) => {
      // @ts-expect-error refactor
      inputRef.current.select();
      openMenu(...args);
    },
    [inputRef, openMenu]
  );
  // @ts-expect-error refactor
  const rootCn = css("ui-select-input-trigger-root", rootStyle);
  const iconCn = css("ui-select-input-trigger-icon", iconStyle);
  const clearIconCn = css(
    "ui-select-input-clear-icon",
    iconStyle,
    clearIconStyle(showClearBtn)
  );
  const selectedCn = css(
    "ui-select-input-selected",
    selectedStyle(showClearBtn)
  );
  const inputProps = getInputProps();
  const extendStyles = showSearchIcon
    ? { ...inputStyle(showClearBtn), ...searchIconStyle }
    : inputStyle(showClearBtn);

  return (
    <div
      className={rootCn}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {/* It displays a special style for the selected item if displaySelectedItem
      is defined and true */}
      {displaySelectedItem &&
        inputProps?.value === selectedItem?.label &&
        displaySelectedItem(selectedItem) && (
          <div className={selectedCn}>{selectedItem?.label}</div>
        )}
      <Input
        placeholder={placeholder}
        disabled={disabled}
        type="text"
        {...inputProps}
        data-test-ref="ui-select-input-trigger"
        data-testid="ui-select-input-trigger"
        {...getTestAttributes(props["data-guide-id"])}
        ref={inputRef}
        id={id}
        onClick={onInputClick}
        extend={extendStyles}
      />
      <button
        className={clearIconCn}
        onClick={onClear}
        data-testid="ui-select-clear-input"
        aria-label="clear-selection"
      >
        <CloseIcon />
      </button>
      <button className={iconCn} {...getButtonProps()}>
        <CaretDownIcon />
      </button>
    </div>
  );
}

// @ts-expect-error migration
const rootStyle = ({ disabled, minWidth }: Props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  minWidth: minWidth || "200px",
  cursor: disabled ? "not-allowed" : "pointer",
  minHeight: "40px",
  position: "relative",
});

const ICON_WIDTH = "20px";
const searchIconStyle = {
  width: "248px",
  height: "32px",
  paddingLeft: "30px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "3px 50%",
  backgroundImage: `url(${MagnifyingGlass})`,
};
const iconStyle = {
  backgroundColor: "transparent",
  border: "none",
  padding: 0,
  width: ICON_WIDTH,
  height: ICON_WIDTH,
  position: "absolute",
  right: theme.space.ms,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fill: theme.color.textStandard,
};

const clearIconStyle = (showClearBtn: boolean) => ({
  right: theme.space.xl,
  color: theme.color.textLight,
  transitionProperty: "opacity",
  transitionDuration: theme.transitionSpeed.fast,
  opacity: showClearBtn ? 1 : 0,
});

// Need to make room for the clear button
const inputStyle = (showClearBtn: boolean) => ({
  paddingRight: `calc(${ICON_WIDTH} * ${showClearBtn ? 2 : 1} + ${
    theme.space.ms
  })`,
});

const selectedStyle = (showClearBtn: boolean) => ({
  position: "absolute",
  marginLeft: theme.space.xs,
  color: theme.color.textDarker,
  padding: theme.space.xs,
  backgroundColor: theme.color.gray025,
  fontSize: theme.fontSize.ms,
  borderWidth: "1px",
  borderColor: theme.color.gray400,
  borderStyle: "solid",
  borderRadius: theme.corner.sm,
  // This values are so it gets truncated and doesn't go outside the div
  letterSpacing: "0.05em",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "default",
  maxWidth: `calc(100% - ${ICON_WIDTH} * ${showClearBtn ? 2 : 1} - ${
    theme.space.md
  })`,
});

export default InputTrigger;
