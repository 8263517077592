import * as React from "react";

export default function MergeTag({ size = 24 }: { size: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="merge-tag-icon-24-a"
          d="M9 18.5v-.839a.5.5 0 0 0-.5-.5h-.51c-.556 0-1.01-.451-1.01-1.145v-2.548c0-.678-.22-1.242-.775-1.468.556-.226.775-.79.775-1.468V7.968c0-.71.454-1.13 1.01-1.13h.51a.5.5 0 0 0 .5-.5V5.5a.5.5 0 0 0-.5-.5h-.51C6.357 5 4.875 6.113 4.875 7.984v2.226c0 .34-.212.64-.637.9a.5.5 0 0 0-.238.427v.926a.5.5 0 0 0 .238.426c.425.262.637.562.637.901v2.242C4.875 17.903 6.357 19 7.99 19h.51a.5.5 0 0 0 .5-.5zm6.5.5a.5.5 0 0 1-.5-.5v-.839a.5.5 0 0 1 .5-.5h.51c.556 0 1.01-.451 1.01-1.145v-2.548c0-.678.22-1.242.775-1.468-.556-.226-.775-.79-.775-1.468V7.968c0-.71-.454-1.13-1.01-1.13h-.51a.5.5 0 0 1-.5-.5V5.5a.5.5 0 0 1 .5-.5h.51c1.633 0 3.115 1.113 3.115 2.984v2.226c0 .34.212.64.637.9a.5.5 0 0 1 .238.427v.926a.5.5 0 0 1-.238.426c-.425.262-.637.562-.637.901v2.242c0 1.871-1.482 2.968-3.115 2.968h-.51zm-2.797-4.738a.704.704 0 0 1-.73.737.71.71 0 0 1-.683-.737l.044-1.148-.989.62a.702.702 0 0 1-.997-.274.69.69 0 0 1 .298-.93l1.032-.53-1.032-.53a.69.69 0 0 1-.269-.982.702.702 0 0 1 .968-.222l.99.62-.045-1.148a.71.71 0 0 1 .744-.737.704.704 0 0 1 .669.737l-.056 1.148.985-.617a.715.715 0 0 1 1.016.28.685.685 0 0 1-.3.92L13.304 12l1.044.53a.685.685 0 0 1 .27.975.715.715 0 0 1-.986.226l-.985-.617.056 1.148z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#merge-tag-icon-24-a" />
      </g>
    </svg>
  );
}
