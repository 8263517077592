import React from "react";
import { Input } from "hw/ui/input";
import type { SettingsProps } from "../../../types";
import { TextToggle } from "./basics";
import { Actions } from "../../../state";

type Props = SettingsProps & {
  placeholder?: string;
};
export default class Placeholder extends React.PureComponent<Props> {
  handleChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { path, editorDispatch } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
    const { value } = evt.target;
    editorDispatch(
      Actions.ChangeFieldSetting({
        path: [...path, "placeholder"],
        updater: () => value,
      })
    );
  };

  render() {
    /* $FlowFixMe[incompatible-use] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    const { placeholder, ...rest } = this.props;
    return (
      <TextToggle
        schemaPropName="placeholder"
        text={placeholder}
        name="Placeholder"
        InputComponent={Input}
        data-testid="field-settings-placeholder-input"
        {...rest}
      />
    );
  }
}
