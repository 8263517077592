import * as React from "react";
import { Extend, useStyle } from "hw/ui/style";
import theme, { variantOn } from "hw/ui/theme";

export type Props = {
  children: React.ReactNode;
  required?: boolean;
  appearance?: "compact" | "default";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extend?: Extend;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const fontSize = variantOn("appearance", {
  default: theme.fontSize.ms,
  compact: theme.fontSize.sm,
});

const paddingLeft = variantOn("appearance", {
  default: null,
  compact: theme.space.xs,
});

const color = variantOn("appearance", {
  default: null,
  compact: theme.color.textStandard,
});

export default function Label(props: Props) {
  const { required, appearance = "default", extend = {}, ...rest } = props;
  const cn = useStyle(
    "label",
    {
      fontSize: fontSize({ appearance }),
      marginLeft: paddingLeft({ appearance }),
      color: color({ appearance }),
      fontWeight: theme.fontWeight.semibold,
      ":after": required && {
        color: theme.color.red500,
        content: '"*"',
        marginLeft: theme.space.xs,
      },
    },
    extend
  );

  return <label {...rest} className={cn} />;
}
