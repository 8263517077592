import React from "react";
import Dropdown from "hw/ui/dropdown";
import type { Props } from "./select";
import "./select";
import InputTrigger from "./input-trigger";

/**
 * Light wrapper around dropdown with an input trigger
 * By default it's a select, but accepts a prop type to change
 * that to a "dropdown" if needed
 */
export function SelectInput(props: Props) {
  const {
    placeholder,
    disabled,
    triggerProps,
    type = "select",
    ...rest
  } = props;

  return (
    <Dropdown
      {...rest}
      type={type}
      Trigger={InputTrigger}
      triggerProps={{
        placeholder,
        disabled,
        ...triggerProps,
      }}
    />
  );
}

export default SelectInput;
