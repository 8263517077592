/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import * as Sentry from "@sentry/browser";
import { invariant } from "./assert";

/**
 * This is a hook for checking if a certain timeout has been reached.  It's
 * useful when lazy loading via React.Suspense because you can avoid rendering
 * a fallback component until a certain amount of time has passed.  This avoids
 * a flash of rendering in cases where the load time is quick.  Avoid this
 * flash has been shown to be perceived faster by users.  This is the equivalent
 * of something like the `pastDelay` prop from `react-loadable`
 *
 * @example
 * const LazyLoadedComponent = React.lazy(() => import('./my-component'))
 *
 * function LazyComponent(props) {
 *   return <React.Suspense fallback={<LoadingComponent />}>
 *     <LazyLoadedComponent {...props} />
 *   </React.Suspense>
 * }
 *
 * function LoadingComponent() {
 *   const isPastDelay = useLazyTimeout()
 *
 *   if (!isPastDelay) return null;
 *
 *   return <div>Loading...</div>
 * }
 */
export function useLazyTimeout(ms = 200) {
  const [isPastDelay, setIsPastDelay] = React.useState(ms === 0);
  React.useEffect(() => {
    const timeout = setTimeout(() => setIsPastDelay(true), ms);

    return () => clearTimeout(timeout);
  }, [ms]);

  return isPastDelay;
}

type Options = {
  retryTimes?: number;
  retryInterval?: number;
  Fallback: React.ComponentType<{
    retry?: (...args: Array<any>) => any;
  }>;
  Loading: React.ComponentType<{
    isPastDelay: boolean;
  }>;
};

/**
 * Lazy-loaded component
 *
 * This is a wrapper around `React.lazy` that handles some common cases like
 * retrying chunk loading, error components, and loading components. Due to
 * the way `React.lazy` works, some trickery is needed in order to properly
 * handle retrying and also to avoid 'unhandldedrejection' errors.
 *
 * @example
 * const LazyComponent = createLoadable(() => import('./my-component'), {
 *   Fallback: ({ retry }) => <div>
 *     There was an error loading. <button onClick={retry}>Retry</button>
 *   </div>,
 *   Loading: ({ isPastDelay }) => isPastDelay ? <div>Loading...</div> : null
 * });
 */
export function createLoadable(load: () => Promise<any>, options: Options) {
  invariant(load, `You must provide a 'load' option when creating a loadable`);

  const { Fallback, Loading, retryTimes, retryInterval } = options;

  invariant(
    Fallback,
    `You must provide a 'Fallback' option when creating a loadable`
  );

  invariant(
    Loading,
    `You must provide a 'Loading' option when creating a loadable`
  );

  // Converts the `load` function into one that will retry automatically
  // up to a certain amount of times
  const retryableLoad = () => retry(load, retryTimes, retryInterval);

  function handleError(error: Error) {
    Sentry.withScope((scope) => {
      // Fingerprint all these errors so they always get grouped together in
      // Sentry
      scope.setFingerprint(["lazy_component_error"]);
      Sentry.captureException(error);
    });
  }

  function Loadable(props: any) {
    // Due to the way `React.lazy` works internally, we have to create a new
    // instance for each retry attempt. Otherwise, React will cache the failed
    // promise and won't re-render on the next attempt
    const [LazyComponent, setLazyComponent] = React.useState(
      // @ts-expect-error refactor
      createLazyComponent()
    );

    function retry() {
      setLazyComponent(createLazyComponent());
    }

    function createLazyComponent() {
      return React.lazy(() =>
        /**
         * Ideally we could use an `ErrorBoundary` instead of manually
         * catching the promise, but that still triggers an `unhandldedrejection`
         * error from Webpack. Catching here and returning a fake module as
         * the error handler seems to work better
         */
        retryableLoad().catch((err) => {
          handleError(err);

          // React expects this to return a "module" so we have to fake it with
          // an object with a `default` key
          return {
            default: function FallbackError() {
              return <Fallback {...props} retry={retry} />;
            },
          };
        })
      );
    }

    return (
      <React.Suspense fallback={<BaseLoading {...props} Loading={Loading} />}>
        {/* @ts-expect-error refactor */}
        <LazyComponent {...props} />
      </React.Suspense>
    );
  }

  return Loadable;
}

function BaseLoading({ Loading, ...rest }: any) {
  const isPastDelay = useLazyTimeout();

  return <Loading {...rest} isPastDelay={isPastDelay} />;
}

/**
 * Retries a promise a set number of times over an interval with exponential
 * backoff
 */
const maxRetryInterval = 5000;

function retry(fn: () => Promise<any>, remaining = 5, interval = 500) {
  return new Promise<any>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (remaining === 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          if (remaining === 1) {
            reject(error);
            return;
          }
          const nextInterval = Math.min(maxRetryInterval, interval * 2);
          retry(fn, remaining - 1, nextInterval).then(resolve, reject);
        }, interval);
      });
  });
}
