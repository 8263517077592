import * as React from "react";
import { Text, Flex } from "hw/ui/blocks";

export function NoComponent() {
  return (
    <Flex flexDirection="column">
      <Text px="ms" py="xl" color="textDim" textAlign="center">
        Form component settings
      </Text>
    </Flex>
  );
}
export default NoComponent;
