import * as React from "react";

export default function Back() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      <path
        id="back-16-a"
        d="M10.7071068,12.2928932 C11.0976311,12.6834175 11.0976311,13.3165825 10.7071068,13.7071068 C10.3165825,14.0976311 9.68341751,14.0976311 9.29289322,13.7071068 L4.29289322,8.70710678 C3.90236893,8.31658249 3.90236893,7.68341751 4.29289322,7.29289322 L9.29289322,2.29289322 C9.68341751,1.90236893 10.3165825,1.90236893 10.7071068,2.29289322 C11.0976311,2.68341751 11.0976311,3.31658249 10.7071068,3.70710678 L6.41421356,8 L10.7071068,12.2928932 Z"
      />
    </svg>
  );
}
