// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { EditorState } from "prosemirror-state";

import type { MergeField } from "hw/portal/modules/common/draft";
import * as utils from "./utils";
import type { Dispatch } from "../../types";

export function insertQuery(state: EditorState, dispatch: Dispatch) {
  const { tr, schema } = state;
  const mark = schema.mark("mergeFieldQuery");
  dispatch(tr.replaceSelectionWith(schema.text("{", [mark]), false));
}

/**
 * Inserts a new merge field as a data node
 */
export function insert(
  state: EditorState,
  dispatch: Dispatch,
  mergeField: MergeField,
  pos?: { start: number; end: number }
) {
  const { selection } = state;
  const activeQueryMark = utils.findActiveQueryMark(
    state,
    selection.from - 1,
    selection.to
  );

  const { start, end } = pos || activeQueryMark;
  const { data: dataNode } = state.schema.nodes;
  const nodes = [
    dataNode.create({
      // For legacy cases, some merge fields may not have a label assigned, so
      // we need to fallback to the data ref
      label: mergeField.label || mergeField.dataRef,
      ref: mergeField.dataRef,
    }),
  ];

  if (!utils.isNextCharacterSpace(end, state.tr.doc)) {
    nodes.push(state.schema.text(" "));
  }

  let transaction = state.tr;
  if (activeQueryMark.end !== end) {
    const mergeFieldQueryMark = state.schema.mark("mergeFieldQuery", {
      active: true,
    });
    transaction = transaction.removeMark(
      end,
      activeQueryMark.end,
      mergeFieldQueryMark
    );
  }

  transaction = transaction.replaceWith(start, end, nodes);
  if (utils.isChromeWithSelectionBug) {
    const selection = document.getSelection();
    if (selection) {
      selection.empty();
    }
  }

  dispatch(transaction);

  return true;
}

/**
 * Dismisses the merge field suggestions query
 */
export function dismiss(state: EditorState, dispatch: Dispatch) {
  const transaction = createDismissTr(state);
  dispatch(transaction);

  return true;
}

/**
 * If a space is pressed when there are no actionable merge fields, we should
 * dismiss the query and insert a space to mimic the default behavior
 */
export function dismissWithSpace(state: EditorState, dispatch: Dispatch) {
  const transaction = createDismissTr(state).replaceWith(
    state.selection.from,
    state.selection.to,
    state.schema.text(" ")
  );

  dispatch(transaction);

  return true;
}

function createDismissTr(state: EditorState) {
  const { schema, tr } = state;
  const { selection } = state;
  const markType = schema.mark("mergeFieldQuery");
  const { start, end } = utils.findActiveQueryMark(
    state,
    selection.from - 1,
    selection.to
  );

  return tr.removeMark(start, end, markType).removeStoredMark(markType);
}
