/**
 * Display Rules
 *
 * This module is meant to hold logic that determines how we render UI in the
 * editor. They are not strict validation rules (e.g. the draft will still
 * publish)
 */
import type { Field, Role } from "hw/portal/modules/common/draft/types";
import type { Path } from "hw/common/types";

/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
type FieldType = Field["type"];
type DocumentType = "mapped" | "generated" | "pending";

/**
 * The minimum number of output fields for a particular component type
 */
export function minRequiredOutputFields(componentType: FieldType) {
  switch (componentType) {
    case "Signature":
      return 1;
    default:
      return 0;
  }
}

/**
 * The maximum number of output fields for a particular component type
 */
export function maxAllowedOutputFields(componentType: FieldType) {
  switch (componentType) {
    case "Signature":
    case "Multiline":
      return 1;

    // We technically allow mapping of `FileAttachment` components, but they
    // are rarely useful.
    case "FileAttachment":
      return 0;
    default:
      return Infinity;
  }
}

/**
 * Returns `true` if this component can be mapped to a document
 */
export function canBeMapped(
  componentType: FieldType,
  documentType: DocumentType
) {
  switch (componentType) {
    case "Paragraph":
    case "Group":
      return false;
    default:
      return documentType === "mapped";
  }
}

/**
 * Returns `true` if we should create an output field by default when creating
 * a component of this type
 */
export function shouldCreateDefaultOutputField(componentType: FieldType) {
  switch (componentType) {
    case "FileAttachment":
      return false;
    default:
      return true;
  }
}

/**
 * Returns `true` if the component can be reassigned to a different role
 */
export function canReassign(
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  roles: Array<Role>,
  componentType: FieldType,
  path: Path
) {
  switch (componentType) {
    case "Paragraph":
      return false;
    default:
      return roles.length > 1 && !isChildComponent(path);
  }
}

function isChildComponent(path: Path) {
  return path.includes("children");
}

/**
 * Returns `true` if the component can change its input type
 */
export function canChangeInputType(componentType: FieldType) {
  switch (componentType) {
    case "TextInput":
    case "PhoneNumber":
    case "Signature":
    case "SSN":
    case "DateInput":
      return true;

    default:
      return false;
  }
}
