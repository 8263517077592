import * as React from "react";
import { createComponent, Extend } from "hw/ui/style";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: Array<any>) => any;
  extend?: Extend;
};

/**
 * The `Shade` component obscures the entire window content. It's primarily used
 * with modals.
 */
export const Shade = createComponent(
  function Shade({ theme }) {
    return {
      backgroundColor: theme.color.gray800,
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      opacity: 0.7,
      position: "fixed",
      zIndex: theme.layer.shade,
    };
  },
  "div",
  ["onClick"]
);

export default Shade as React.ComponentType<Props>;
