import * as React from "react";

export default function Delete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="delete-24-a"
          d="M6,7 L18,7 L17.0663395,20.071247 C17.0289605,20.5945525 16.5935195,21 16.0688808,21 L7.9311192,21 C7.40648054,21 6.97103946,20.5945525 6.9336605,20.071247 L6,7 Z M18,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L6,4 C5.44771525,4 5,4.44771525 5,5 L5,5 C5,5.55228475 5.44771525,6 6,6 L18,6 C18.5522847,6 19,5.55228475 19,5 L19,5 C19,4.44771525 18.5522847,4 18,4 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#delete-24-a" />
      </g>
    </svg>
  );
}
