import React from "react";

export function WarningIcon16() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7" fill="#FCB212" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00652 10.462C7.4919 10.462 7.08985 10.076 7.05769 9.5614L6.81646 4.2383C6.7843 3.56287 7.33108 3 7.99044 3C8.66588 3 9.21266 3.54678 9.1805 4.2383L8.95535 9.5614C8.92319 10.076 8.50506 10.462 8.00652 10.462ZM6.71997 12.7135C6.71997 12.0058 7.29892 11.4108 8.00652 11.4108C8.71412 11.4108 9.29307 12.0058 9.29307 12.7135C9.29307 13.405 8.71412 14 8.00652 14C7.29892 14 6.71997 13.405 6.71997 12.7135Z"
        fill="white"
      />
    </svg>
  );
}
