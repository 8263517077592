import * as React from "react";
import { Input } from "hw/ui/input";
import { filterNonUTF83BCharacters } from "hw/portal/modules/draft-editor/utils";
import * as heap from "hw/portal/modules/analytics/heap";
import type { SettingsProps } from "../../../types";
import { Actions } from "../../../state";
import Wrapper from "./basics/wrapper";
import { genId } from "../utils";

type Props = SettingsProps & {
  label?: string;
  placeholder?: string;

  /**
   * Text used to describe this label, a.k.a. The label label
   */
  labelText?: string;
};
export default function Label(props: Props) {
  const {
    label = "",
    labelText = "Label",
    editorDispatch,
    path,
    fieldType,
    placeholder,
  } = props;
  const [modified, setModified] = React.useState(false);
  const trackLabelChange = React.useCallback(() => {
    if (!modified) {
      heap.track(heap.EVENTS.editor.componentLabelFieldModified, {
        "Component Type": fieldType,
      });
      setModified(true);
    }
  }, [fieldType, modified]);
  const handleChange = React.useCallback(
    (evt) => {
      const { value } = evt.target;
      const filteredValue = filterNonUTF83BCharacters(value);
      trackLabelChange();
      editorDispatch(
        Actions.ChangeFieldSetting({
          path: [...path, "label"],
          updater: () => filteredValue,
        })
      );
    },
    [editorDispatch, path, trackLabelChange]
  );
  const trackLabelClicks = React.useCallback(() => {
    heap.track(heap.EVENTS.editor.componentLabelFieldClicked, {
      "Component Type": fieldType,
    });
  }, [fieldType]);
  const { current: id } = React.useRef(genId());
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; label: string; py: stri... Remove this comment to see the full error message
    <Wrapper label={labelText} py="sm" id={id}>
      <Input
        type="text"
        id={id}
        value={label}
        onChange={handleChange}
        data-testid="field-settings-label-input"
        data-guide-id="field-settings-label-input"
        onClick={trackLabelClicks}
        placeholder={placeholder}
        onFocus={(e) => e.currentTarget.select()}
        autoFocus
      />
    </Wrapper>
  );
}
