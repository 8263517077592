import * as React from "react";
import ReactDOM from "react-dom";
import Providers from "./providers";

/**
 * Mounts a shareable link page to the root element with the correct providers
 *
 * @example
 * function render() {
 *   return <ShareableLink />
 * }
 *
 * init(render)
 */
export default function init(render: () => React.ReactNode) {
  const root = document.getElementById("root");

  if (root) {
    ReactDOM.render(<Providers>{render()}</Providers>, root);
  }
}
