import * as React from "react";
import { useHistory } from "react-router-dom";
import { WarningIcon } from "hw/ui/icons";
import BulkTable, { DataRowValues } from "./table";
import styles from "./step1.module.css";

type Props = {
  rows: Array<DataRowValues>;
  headers: Array<string>;
  idMismatch: boolean;
  dataError: boolean;
  setName: (rowIndex: number, participantIdx: number, value: string) => void;
  setEmail: (rowIndex: number, participantIdx: number, value: string) => void;
  setMergeField: (
    rowIndex: number,
    participantIdx: number,
    value: string
  ) => void;
  sending: boolean;
};

export default function Step1(props: Props) {
  const {
    rows,
    headers,
    idMismatch,
    dataError,
    setName,
    setEmail,
    setMergeField,
    sending,
  } = props;

  const isInitialMount = React.useRef(true);
  const history = useHistory();

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      // Redirects AFTER the state updates, which includes updating the toast context.
    } else if (!rows.length) {
      history.push("/workflow/transactions");
    }
  }, [rows, history]);

  let bannerOffset = 0;
  if (dataError) {
    bannerOffset++;
  }

  if (idMismatch) {
    bannerOffset++;
  }

  return (
    <div className={styles.step}>
      {idMismatch && (
        <div className={styles.persistentError}>
          <span className={styles.persistentErrorIcon}>
            <WarningIcon />
          </span>
          <span className={styles.persistentErrorText}>
            The workflow ID from this file did not match. Try downloading the
            template again to make sure you are using the correct structure.
          </span>
        </div>
      )}
      {dataError && (
        <div className={styles.persistentError}>
          <span className={styles.persistentErrorIcon}>
            <WarningIcon />
          </span>
          <span className={styles.persistentErrorText}>
            There is a possible formatting error due to an unexpected number of
            commas in your file. Please review carefully before sending.
          </span>
        </div>
      )}
      <BulkTable
        headers={headers}
        entries={[...rows]}
        onNameChange={setName}
        onEmailChange={setEmail}
        onMergeFieldChange={setMergeField}
        blocked={sending}
        bannerOffset={bannerOffset}
      />
    </div>
  );
}
