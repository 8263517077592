import * as React from "react";
import * as heap from "hw/portal/modules/analytics/heap";
import BillingInfoQuery from "hw/portal/modules/common/graphql/team/billing.graphql";
import { useQuery } from "@apollo/client";
import type { User } from "hw/portal/modules/user";
import type { Billing } from "hw/portal/modules/common/graphql/schema";
import Button from "hw/ui/button";
import Modal from "./modal";
import styles from "./index.module.css";
import type * as Types from "../types";

type Props = {
  workflow: {
    mergeFields: Array<{
      label: string;
      dataRef: string;
    }>;
    roles: Array<{
      id: string;
      label: string;
    }>;
    guid: string;
    name: string;
  };
  bulkSend: Types.BulkSendOperation;
  user: User;
  documentDeliveryType: string;
  notifyWhenComplete: boolean;
};

export default function Index(props: Props) {
  const { workflow, bulkSend, user, documentDeliveryType, notifyWhenComplete } =
    props;
  const { id: userId, team } = user;
  const { mergeFields, roles, guid, name } = workflow;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // @ts-expect-error refactor
  const {
    // @ts-expect-error refactor
    data: { billing } = { billing: {} },
  }: {
    // $FlowExpectedError
    data: { billing: Billing };
    loading: boolean;
    billingRefetch: () => void;
  } = useQuery(BillingInfoQuery, {
    fetchPolicy: "network-only",
  });

  const openBulkModal = () => {
    heap.track(heap.EVENTS.launchPage.bulkSendClicked, {
      "team ID": userId,
      "User ID": team.guid,
      "Workflow ID": guid,
    });

    setIsModalOpen(true);
  };

  /**
   * Close the bulk send wizard
   */
  const closeBulk = () => {
    heap.track(heap.EVENTS.launchPage.bulkSendCloseClicked);

    setIsModalOpen(false);
  };

  /**
   * Gets an array of the headers for the workflow
   */
  const getHeaders = () =>
    roles
      .reduce((acc, role) => {
        acc.push(`${role.label} full name`);
        acc.push(`${role.label} email`);

        return acc;
      }, [] as string[])
      .concat(mergeFields.map((mf) => `Merge field ${mf.label}`));

  return (
    <div className={styles.trigger}>
      {isModalOpen && (
        <Modal
          workflowGuid={guid}
          workflowName={name}
          bulkSend={bulkSend}
          closeBulk={closeBulk}
          headers={
            // $FlowIgnore
            getHeaders()
          }
          participants={roles}
          mergeFields={mergeFields}
          billing={billing}
          user={user}
          documentDeliveryType={documentDeliveryType}
          notifyWhenComplete={notifyWhenComplete}
        />
      )}
      <Button
        type="button"
        presentation="link"
        onClick={openBulkModal}
        data-track-id="bulk-send-button"
      >
        Bulk Send
      </Button>
    </div>
  );
}
