import * as React from "react";

type Props = {
  size?: number;
  color?: string;
};
export default function ConditionIcon(props: Props) {
  const { size = 16, color = "#555D65" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <defs>
        <path
          id="condition-icon-16-a"
          d="M9.334 4.667V3.333a.667.667 0 0 1 1.01-.571l3.332 2a.667.667 0 0 1 0 1.143l-3.333 2a.667.667 0 0 1-1.01-.572V6H4.668a.667.667 0 0 1-.666-.667V2.667a.667.667 0 1 1 1.333 0v2h4zM4 9.333v-2a.667.667 0 1 1 1.333 0v2h1.333c.518 0 .838.566.572 1.01l-2 3.333a.667.667 0 0 1-1.143 0l-2-3.333a.667.667 0 0 1 .572-1.01H4z"
        />
      </defs>
      <use fill={color} xlinkHref="#condition-icon-16-a" />
    </svg>
  );
}
