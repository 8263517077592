import { createStore } from "redux";
import { createStructuredSelector, createSelector } from "reselect";
import * as Types from "./types";

const initialState: Types.State = {
  value: "move.idle",
  scale: 1,
  fields: {},
  pages: {},
  selected: [],
  hovered: null,
};

export function init() {
  return createStore(
    reducer,
    initialState,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}

/**
 * -- Guards --
 *
 * Boolean checks on the state
 */
export const isDragging = (state: Types.State) =>
  state.value === "move.dragging";

/**
 * -- Selectors --
 */

export const pageSelector = createStructuredSelector<
  Types.State,
  { scale: number }
>({
  scale: (state) => state.scale,
});

const emptyArr: Types.Field[] = [];

type CollisionSelector = (state: Types.State) => {
  idleFields: Array<Types.Field>;
};

/**
 * Returns the fields when the state is in the `idle` state. Used to lazily
 * compute collisions between fields on a page
 */
export const createCollisionsSelector = (
  pageNumber: number
): CollisionSelector =>
  createSelector(
    (state) => state.fields,
    (state) => state.value.includes("idle"),
    (fields, idle) => {
      if (!idle) return { idleFields: emptyArr };

      const idlePageFields = [];

      for (const id of Object.keys(fields)) {
        const field = fields[id];
        if (field?.page === pageNumber) {
          idlePageFields.push(field);
        }
      }

      return { idleFields: idlePageFields };
    }
  );

export const externalSelector = createStructuredSelector<
  Types.State,
  {
    scale: Types.State["scale"];
    selected: Types.State["selected"];
    active: boolean;
  }
>({
  scale: (state) => state.scale,
  selected: (state) => state.selected,
  active: (state) => Object.keys(state.pages).length > 0,
});

type FieldState = "selected" | "hovered" | "idle" | "dragging" | "resizing";

export const createFieldSelector = (id: string) =>
  createStructuredSelector<Types.State, { value: FieldState }>({
    value: (state) => {
      switch (state.value) {
        case "move.idle": {
          const isSelected = state.selected.includes(id);

          if (isSelected) {
            return "selected";
          }

          return state.hovered === id ? "hovered" : "idle";
        }
        case "move.dragging":
          return state.dragging.includes(id) ? "dragging" : "idle";
        case "move.resizing":
          return state.resizing.includes(id) ? "resizing" : "idle";
        default:
          return "idle";
      }
    },
  });

export const createSelectReducedHintState = (ids: string[]) =>
  createSelector(
    (state: Types.State) => state.hovered,
    (state: Types.State) => state.selected,
    (hovered, selected) => {
      const anySpecifiedFieldHovered = hovered ? ids.includes(hovered) : false;
      const anotherFieldHovered = hovered && !anySpecifiedFieldHovered;
      const anySpecifiedFieldSelected = selected.some((id) => ids.includes(id));
      if (anySpecifiedFieldSelected) {
        // If this field is selected, mark it as "highlighted" only if there is not
        // another field on the page being hovered. We want the hovered fields to
        // always be highlighted
        return anotherFieldHovered ? "idle" : "highlighted";
      }

      return anySpecifiedFieldHovered ? "hinted" : "idle";
    }
  );

export const createResizingFieldSelector = (_id: string) =>
  createStructuredSelector<
    Types.MoveResizingState,
    {
      sx: number;
      sy: number;
      dx: number;
      dy: number;
      origin: Types.MoveResizingState["resizeOrigin"];
      scale: Types.State["scale"];
    }
  >({
    sx: (state) => state.sx,
    sy: (state) => state.sy,
    dx: (state) => state.dx,
    dy: (state) => state.dy,
    origin: (state) => state.resizeOrigin,
    scale: (state) => state.scale,
  });

export const createDraggingFieldSelector = (_id: string) =>
  createStructuredSelector<
    Types.MoveDraggingState,
    {
      sx: number;
      sy: number;
      dx: number;
      dy: number;
      scale: Types.State["scale"];
    }
  >({
    sx: (state) => state.sx,
    sy: (state) => state.sy,
    dx: (state) => state.dx,
    dy: (state) => state.dy,
    scale: (state) => state.scale,
  });

function reducer(state: Types.State | void, evt: Types.Evt): Types.State {
  // Flow wants this
  if (!state) return initialState;

  // Top-level events could happen in any state node
  if (evt.type === "field.register") return registerField(state, evt);
  if (evt.type === "field.unregister") return unregisterField(state, evt);
  if (evt.type === "page.register") return registerPage(state, evt);
  if (evt.type === "page.unregister") return unregisterPage(state, evt);

  switch (state.value) {
    case "move.idle":
    case "move.dragging":
    case "move.resizing":
      return moveReducer(state, evt);
    default:
      return exhaustiveCheck(state);
  }
}

function exhaustiveCheck(val: never): never {
  return val;
}

function moveReducer(state: Types.State, evt: Types.Evt): Types.MoveState {
  switch (state.value) {
    case "move.idle":
      switch (evt.type) {
        case "scale.changed":
          return setScale(state, evt);
        case "selection.cleared":
          return clearSelection(state);
        case "drag.init":
          return initializeDrag(state, evt);
        case "resize.init":
          return initializeResize(state, evt);
        case "field.selected":
          return selectField(state, evt);
        case "hover":
          return hoverField(state, evt);
        case "unhover":
          return unHoverField(state);
        default:
          return state;
      }
    case "move.resizing":
      switch (evt.type) {
        case "cancel":
          return finishResize(state);
        case "resize.step":
          return resize(state, evt);
        case "resize.done":
          return finishResize(state);
        default:
          return state;
      }
    case "move.dragging":
      switch (evt.type) {
        case "cancel":
          return drop(state);
        case "drag.step":
          return drag(state, evt);
        case "drag.drop":
          return drop(state);
        default:
          return state;
      }
    default:
      return exhaustiveCheck(state);
  }
}

/**
 * Assignments
 */

function drag(state: Types.MoveDraggingState, evt: Types.DragStepEvt) {
  return {
    ...state,
    dx: evt.x - state.sx,
    dy: evt.y - state.sy,
  };
}

function drop(state: Types.MoveDraggingState): Types.MoveIdleState {
  return {
    value: "move.idle",
    fields: state.fields,
    pages: state.pages,
    scale: state.scale,
    selected: state.selected,
    hovered: null,
  };
}

function finishResize(state: Types.MoveResizingState): Types.MoveIdleState {
  return {
    value: "move.idle",
    fields: state.fields,
    pages: state.pages,
    scale: state.scale,
    selected: state.selected,
    hovered: null,
  };
}

function resize(state: Types.MoveResizingState, evt: Types.ResizeStepEvt) {
  return {
    ...state,
    dx: evt.x - state.sx,
    dy: evt.y - state.sy,
  };
}

function selectField(state: Types.MoveIdleState, evt: Types.FieldSelectedEvt) {
  return {
    ...state,

    // In the future, we can add multiple ids to the selected state based on
    // properties in the event
    selected: [evt.id],
  };
}

function hoverField(state: Types.MoveIdleState, evt: Types.HoverEvt) {
  return {
    ...state,
    hovered: evt.id,
  };
}

function unHoverField(state: Types.MoveIdleState) {
  return {
    ...state,
    hovered: null,
  };
}

function initializeDrag(
  state: Types.MoveIdleState,
  evt: Types.DragInitEvt
): Types.MoveDraggingState {
  return {
    ...state,
    value: "move.dragging",

    // In the future, we could resize all selected based on
    // properties in the event
    dragging: [evt.id],
    sx: evt.x,
    sy: evt.y,
    dx: 0,
    dy: 0,
  };
}

function initializeResize(
  state: Types.MoveIdleState,
  evt: Types.ResizeInitEvt
): Types.MoveResizingState {
  return {
    ...state,
    value: "move.resizing",
    // In the future, we could resize all selected based on
    // properties in the event
    resizing: [evt.id],
    resizeOrigin: evt.origin,
    sx: evt.x,
    sy: evt.y,
    dx: 0,
    dy: 0,
  };
}

function clearSelection(state: Types.MoveIdleState) {
  return {
    ...state,
    selected: [],
  };
}

function setScale(state: Types.MoveIdleState, evt: Types.ScaleChangedEvt) {
  return {
    ...state,
    scale: evt.scale,
  };
}

function registerField(state: Types.State, evt: Types.RegisterFieldEvt) {
  // Have to write it this way for flow...
  const field: Types.Field = {
    id: evt.id,
    x: evt.x,
    y: evt.y,
    top: evt.y,
    left: evt.x,
    width: evt.width,
    height: evt.height,
    right: evt.x + evt.width,
    bottom: evt.y + evt.height,
    page: evt.page,
    lockAspectRatio: evt.lockAspectRatio ?? false,
  };

  return {
    ...state,
    fields: {
      ...state.fields,
      [evt.id]: field,
    },
  };
}

function unregisterField(state: Types.State, evt: Types.UnregisterFieldEvt) {
  const nextFields = { ...state.fields };
  delete nextFields[evt.id];
  return {
    ...state,
    fields: nextFields,
  };
}

function registerPage(state: Types.State, evt: Types.RegisterPageEvt) {
  // Have to write it this way for flow...
  const page: Types.Page = {
    number: evt.number,
    width: evt.width,
    height: evt.height,
  };
  return {
    ...state,
    pages: {
      ...state.pages,
      [evt.number]: page,
    },
  };
}

function unregisterPage(state: Types.State, evt: Types.UnregisterPageEvt) {
  const nextPages = { ...state.pages };
  delete nextPages[String(evt.number)];
  return {
    ...state,
    pages: nextPages,
  };
}
