/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import styles from "./file-upload.module.css";

type Props = {
  children: React.ReactNode;
  processFile: (...args: Array<any>) => any;
  className?: string;
  accept: string;
  dataTrackId?: string;
};

export default function FileDropZone(props: Props) {
  const { children, processFile, className = "", accept, dataTrackId } = props;
  const [dropZoneActive, setDropZoneActive] = React.useState(false);
  const inputRef = React.createRef<any>();

  const onDragEnter = () => setDropZoneActive(true);

  const onDragLeave = () => setDropZoneActive(false);

  const onDragOver = (event: any) => {
    event.preventDefault();
    if (!dropZoneActive) {
      setDropZoneActive(true);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDropZoneActive(true);

    const { dataTransfer } = event;
    const fileFromList = dataTransfer?.items?.[0];
    const file =
      fileFromList && fileFromList.kind === "file"
        ? fileFromList.getAsFile()
        : dataTransfer.files[0];

    processFile(file);
  };

  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
      inputRef.current.click();
    }
  };

  const fileInputOnChange = (event: any) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const classes = [
    className,
    styles.dropZone,
    dropZoneActive ? styles.dropZoneActive : "",
  ].join(" ");

  return (
    <>
      <input
        type="file"
        ref={inputRef}
        onChange={fileInputOnChange}
        className={styles.hidden}
        accept={accept}
      />
      <div
        className={classes}
        onClick={onClick}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        data-track-id={dataTrackId}
      >
        {children}
      </div>
    </>
  );
}
