/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/*
 * Draft Editor state
 *
 * This file is where the primary state updates occur in the editor.  State is
 * updated by dispatching actions and running them through the reducer.  Special
 * actions can be marked as "UndoableChangeEvents" where they will be handled by the
 * undo/redo logic.
 */
import { createSelector } from "reselect";
import pipe from "lodash/flow";
import { getIn, updateIn, addLast } from "timm";
import { createLogger } from "hw/common/utils/logger";
import { invariant } from "hw/common/utils/assert";
import * as constants from "hw/portal/modules/draft-editor/constants";
import type { ParsedSchema } from "hw/portal/modules/common/draft";
import { FieldLabels } from "hw/portal/modules/common/draft/constants";
import * as Factories from "hw/portal/modules/common/draft/factories";
import type { DraftResponse } from "hw/portal/modules/draft-editor/types";
import {
  undoable,
  UNDO,
  REDO,
  COMMIT,
  lastUndone,
  lastCommitted,
} from "hw/portal/modules/draft-editor/undoable";
import type { History } from "hw/portal/modules/draft-editor/undoable";
import {
  defaultWorkflowName,
  getReferringFields,
  getReferringFormLabels,
  getReferringCalculationLabels,
  removeCalculationReferences,
} from "hw/portal/modules/draft-editor/utils";
import {
  deleteFieldMappings,
  removeFieldAtPath,
} from "hw/portal/modules/draft-editor/form-builder/form-builder";
import {
  OutputField,
  Schema,
  Form,
  Component,
} from "../common/draft/schema-ops";
import type { Tab } from "./types";
import { NEW_ROLE_NAME } from "./constants";
import {
  getFormRoleIds,
  hasExplicitRole,
  isFieldAssignedToRole,
} from "./utils";
import { createForField, withRawValue } from "./build/mapping";
import migrate from "./migrate";
import { autoPlaceOutputField } from "./build/pdf-mapper";
import type { Modal } from "./modal-manager";

const logger = createLogger("draft-editor-state");
const UNDO_LIMIT = 100;

export const initialState = {
  app: {
    status: "default",
    mergeFieldsModalOpen: false,
    roleModalOpen: false,
    prePublishModalOpen: false,
    deleteFormModalOpen: false,
    reorderFormsModalOpen: false,
    lastSetRoles: {
      workflow: undefined,
      form: {},
    },
    saving: false,
    sendOnPublish: true,
    currentModal: null,
    modals: [],
  },
};

export type DraftState = {
  name: DraftResponse["name"];
  schema: ParsedSchema | string;
  local: {
    temporaryRoleIdsByForm: {};
  };
};

export type State = {
  app: {
    status: any;
    tab: Tab;
    mergeFieldsModalOpen: boolean;
    roleModalOpen: boolean;
    prePublishModalOpen: boolean;
    deleteFormModalOpen: boolean;
    reorderFormsModalOpen: boolean;
    lastSetRoles: {
      workflow: string | null | undefined;
      form: {
        [id: string]: string | null | undefined;
      };
    };
    saving: boolean;
    sendOnPublish: boolean;
    modals: Array<Modal>;
  };
  // $FlowIgnore
  draft: History<DraftState>;
};

/**
 * Action Types
 */
export const ActionTypes = {
  CHANGE_TAB: "CHANGE_TAB",
  CLOSE_MERGE_FIELDS_MODAL: "CLOSE_MERGE_FIELDS_MODAL",
  CLOSE_ROLE_MODAL: "CLOSE_ROLE_MODAL",
  CLOSE_PRE_PUBLISH_MODAL: "CLOSE_PRE_PUBLISH_MODAL",
  CLOSE_DELETE_FORM_MODAL: "CLOSE_DELETE_FORM_MODAL",
  CLOSE_REORDER_FORMS_MODAL: "CLOSE_REORDER_FORMS_MODAL",
  INIT: "INIT",
  LAUNCH: "LAUNCH",
  LAUNCH_ERROR: "LAUNCH_ERROR",
  LAUNCH_SUCCESS: "LAUNCH_SUCCESS",
  OPEN_MERGE_FIELDS_MODAL: "OPEN_MERGE_FIELDS_MODAL",
  OPEN_PRE_PUBLISH_MODAL: "OPEN_PRE_PUBLISH_MODAL",
  OPEN_ROLE_MODAL: "OPEN_ROLE_MODAL",
  OPEN_DELETE_FORM_MODAL: "OPEN_DELETE_FORM_MODAL",
  OPEN_REORDER_FORMS_MODAL: "OPEN_REORDER_FORMS_MODAL",
  PUBLISH: "PUBLISH",
  PUBLISH_ERROR: "PUBLISH_ERROR",
  SAVE: "SAVE",
  SAVE_ERROR: "SAVE_ERROR",
  SAVE_SUCCESS: "SAVE_SUCCESS",
  SAVE_AS_TEMPLATE: "SAVE_AS_TEMPLATE",
  SAVE_AS_TEMPLATE_ERROR: "SAVE_AS_TEMPLATE_ERROR",
  SAVE_AS_TEMPLATE_SUCCESS: "SAVE_AS_TEMPLATE_SUCCESS",
  SET_NAME: "SET_NAME",
  UPDATE_LAST_SET_ROLES: "UPDATE_LAST_SET_ROLES",
  UPDATE_PARSED_SCHEMA: "UPDATE_PARSED_SCHEMA",
  SET_DEFAULT_WORKFLOW_NAME: "SET_DEFAULT_WORKFLOW_NAME",
  SEND_ON_PUBLISH: "SEND_ON_PUBLISH",
  UPDATE_LOCAL_STATE: "UPDATE_LOCAL_STATE",
  ADD_NEW_ROLE_TO_FORM: "ADD_NEW_ROLE_TO_FORM",
  DELETE_FORM_ROLE: "DELETE_FORM_ROLE",
  UNDO,
  REDO,
  COMMIT,
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  CHANGE_COMPONENT_TYPE: "CHANGE_COMPONENT_TYPE",
  UPDATE_DRAFT: "UPDATE_DRAFT",
  FIELD_REASSIGNMENT_REQUESTED: "FIELD_REASSIGNMENT_REQUESTED",
  FIELD_REASSIGNED: "FIELD_REASSIGNED",
  ADD_COMPONENT_OUTPUT_INSTANCE: "ADD_COMPONENT_OUTPUT_INSTANCE",
  REMOVE_COMPONENT_OUTPUT_INSTANCE: "REMOVE_COMPONENT_OUTPUT_INSTANCE",
  COMPONENT_DELETION_REQUESTED: "COMPONENT_DELETION_REQUESTED",
  COMPONENT_DELETED: "COMPONENT_DELETED",
  RESIZE_OUTPUT_FIELD: "RESIZE_OUTPUT_FIELD",
  CHANGE_FONT_SIZE: "CHANGE_FONT_SIZE",
  SET_RAW_SCHEMA: "SET_RAW_SCHEMA",
};

const AT = ActionTypes;

/**
 * Action Creators
 */
type ChangePayload = {
  changeType: string;
  description?: string;
  undoable?: boolean;
};

export type UpdateParsedPayload = ChangePayload & {
  path: Array<string | number>;
  updater: Function;
};

type UndoableChangeEventT = {
  type: string;
  payload: any;
  meta: {
    change: {
      type: string;
      description: string;
      undoable: boolean;
    };
  };
};

type ActionType = keyof typeof AT;

type Action = any;

export const Actions = {
  Init() {
    return {
      type: AT.INIT,
    };
  },

  SetName(payload: any) {
    return UndoableChangeEvent({
      type: AT.SET_NAME,
      payload: {
        ...payload,
        changeType: AT.SET_NAME,
      },
    });
  },

  SetDefaultWfName(payload: any) {
    return UndoableChangeEvent({
      type: AT.SET_DEFAULT_WORKFLOW_NAME,
      payload,
    });
  },

  /**
   * This is a generic action creator used to make updates to the parsed draft
   * schema.  99% percent of updates will go through this action.  It returns
   * a `UndoableChangeEvent` action type, which expects that the action payloads have
   * certain properties in their payload that describes the change being made.
   * This will be used to determine the undo/redo behavior.
   *
   * Many times you'll have an additional action creator in front of this one that
   * prefills with some specific information.
   *
   * @example
   * const Actions = {
   *   SetFormName(payload) {
   *     return EditorActions.UpdateParsedSchema({
   *       ...payload,
   *       changeType: "SET_FORM_NAME"
   *     })
   *   }
   * }
   *
   * function MyComponent(props) {
   *   const { editorDispatch } = props;
   *
   *   editorDispatch(Actions.SetFormName({
   *     description: "Change form name",
   *     path: [...props.path, 'name'],
   *     updater: () => 'some value'
   *   }))
   * }
   */
  UpdateParsedSchema(payload: UpdateParsedPayload) {
    return UndoableChangeEvent({
      type: AT.UPDATE_PARSED_SCHEMA,
      payload,
    });
  },

  SetRawSchema(payload: any) {
    return UndoableChangeEvent({
      type: AT.SET_RAW_SCHEMA,
      payload: {
        changeType: changeTypes.SET_RAW_SCHEMA,
        ...payload,
      },
    });
  },

  UpdateLocalState(payload: any) {
    return UndoableChangeEvent({
      type: AT.UPDATE_LOCAL_STATE,
      payload,
    });
  },

  AddNewRoleToForm(payload: any) {
    return UndoableChangeEvent({
      type: AT.ADD_NEW_ROLE_TO_FORM,
      payload,
    });
  },

  DeleteFormRole(payload: any) {
    return UndoableChangeEvent({
      type: AT.DELETE_FORM_ROLE,
      payload,
    });
  },

  ChangeComponentType(payload: any) {
    return UndoableChangeEvent({
      type: AT.CHANGE_COMPONENT_TYPE,
      payload,
    });
  },

  UpdateDraft(payload: any) {
    return UndoableChangeEvent({
      type: AT.UPDATE_DRAFT,
      payload,
    });
  },

  ReassignField(payload: any) {
    return UndoableChangeEvent({
      type: AT.FIELD_REASSIGNED,
      payload: {
        changeType: changeTypes.SET_FIELD_ROLE,
        ...payload,
      },
    });
  },

  AddComponentOutputInstance(payload: any) {
    return UndoableChangeEvent({
      type: AT.ADD_COMPONENT_OUTPUT_INSTANCE,
      payload: {
        changeType: changeTypes.ADD_COMPONENT_OUTPUT_INSTANCE,
        ...payload,
      },
    });
  },

  RemoveComponentOutputInstance(payload: any) {
    return UndoableChangeEvent({
      type: AT.REMOVE_COMPONENT_OUTPUT_INSTANCE,
      payload: {
        changeType: changeTypes.REMOVE_COMPONENT_OUTPUT_INSTANCE,
        ...payload,
      },
    });
  },

  OpenModal(payload: Modal) {
    return {
      type: AT.OPEN_MODAL,
      payload,
    };
  },

  CloseModal() {
    return {
      type: AT.CLOSE_MODAL,
    };
  },

  DeleteComponent(payload: any) {
    return UndoableChangeEvent({
      type: AT.COMPONENT_DELETED,
      payload: {
        changeType: changeTypes.COMPONENT_DELETED,
        ...payload,
      },
    });
  },

  ResizeOutputField(payload: any) {
    return UndoableChangeEvent({
      type: AT.RESIZE_OUTPUT_FIELD,
      payload: {
        changeType: changeTypes.RESIZE_OUTPUT_FIELD,
        ...payload,
      },
    });
  },

  ChangeFontSize(payload: any) {
    return UndoableChangeEvent({
      type: AT.CHANGE_FONT_SIZE,
      payload: {
        changeType: changeTypes.CHANGE_FONT_SIZE,
        ...payload,
      },
    });
  },
};

export const changeTypes = {
  CHANGE_FIELD_SETTING: "CHANGE_FIELD_SETTING",
  CREATE_FIELD: "CREATE_FIELD",
  CREATE_FORM: "CREATE_FORM",
  CREATE_FORM_FROM_TEMPLATE: "CREATE_FORM_FROM_TEMPLATE",
  DELETE_FIELD: "DELETE_FIELD",
  DELETE_FORM: "DELETE_FORM",
  DELETE_MAPPING: "DELETE_MAPPING",
  DELETE_PDF_TEMPLATE: "DELETE_PDF_TEMPLATE",
  DELETE_FORM_ROLE: "DELETE_FORM_ROLE",
  DUPLICATE_FIELD: "DUPLICATE_FIELD",
  MOVE_FIELD: "MOVE_FIELD",
  SAVE_MERGE_FIELDS: "SAVE_MERGE_FIELDS",
  SAVE_ROLES: "SAVE_ROLES",
  SAVE_FORMS: "SAVE_FORMS",
  SAVE_PDF_MAP: "SAVE_PDF_MAP",
  SET_FIELD_ROLE: "SET_FIELD_ROLE",
  SET_MAPPING: "SET_MAPPING",
  SET_FORM_NAME: "SET_FORM_NAME",
  SET_NAME: "SET_NAME",
  UPLOAD_PDF_TEMPLATE: "UPLOAD_PDF_TEMPLATE",
  UPDATE_FORM_ROLES: "UPDATE_FORM_ROLES",
  UPDATE_ROLE_TITLE: "UPDATE_ROLE_TITLE",
  UPDATE_MAPPINGS: "UPDATE_MAPPINGS",
  ADD_NEW_ROLE_TO_FORM: "ADD_NEW_ROLE_TO_FORM",
  CHANGE_COMPONENT_TYPE: "CHANGE_COMPONENT_TYPE",
  FORM_ANALYZED: "FORM_ANALYZED",
  ADD_COMPONENT_OUTPUT_INSTANCE: "ADD_COMPONENT_OUTPUT_INSTANCE",
  REMOVE_COMPONENT_OUTPUT_INSTANCE: "REMOVE_COMPONENT_OUTPUT_INSTANCE",
  COMPONENT_DELETED: "COMPONENT_DELETED",
  RESIZE_OUTPUT_FIELD: "RESIZE_OUTPUT_FIELD",
  CHANGE_FONT_SIZE: "CHANGE_FONT_SIZE",
  SET_RAW_SCHEMA: "SET_RAW_SCHEMA",
};

/**
 * These are the messages that get displayed to the user when describing an undo
 * or redo action.  They will be rendered as:
 *
 *   "Undo/Redo ${message.toLowerCase()}"
 */
const changeMessages = {
  [changeTypes.CHANGE_FIELD_SETTING]: "Change component setting",
  [changeTypes.CREATE_FIELD]: "Create component",
  [changeTypes.CREATE_FORM]: "Create form",
  [changeTypes.CREATE_FORM_FROM_TEMPLATE]: "Create form from template",
  [changeTypes.DELETE_FIELD]: "Delete component",
  [changeTypes.DELETE_FORM]: "Delete form",
  [changeTypes.DELETE_MAPPING]: "Delete pre-fill",
  [changeTypes.DELETE_PDF_TEMPLATE]: "Delete PDF",
  [changeTypes.DELETE_FORM_ROLE]: "Delete role from form",
  [changeTypes.DUPLICATE_FIELD]: "Duplicate component",
  [changeTypes.MOVE_FIELD]: "Move component",
  [changeTypes.SAVE_MERGE_FIELDS]: "Change merge fields",
  [changeTypes.SAVE_PDF_MAP]: "Change PDF map",
  [changeTypes.SAVE_ROLES]: "Change roles",
  [changeTypes.SAVE_FORMS]: "Change form order and/or names",
  [changeTypes.SET_FIELD_ROLE]: "Assign component",
  [changeTypes.SET_FORM_NAME]: "Change form name",
  [changeTypes.SET_NAME]: "Change name",
  [changeTypes.SET_MAPPING]: "Pre-fill component",
  [changeTypes.UPLOAD_PDF_TEMPLATE]: "Upload PDF",
  [changeTypes.UPDATE_FORM_ROLES]: "Add a role to a form",
  [changeTypes.UPDATE_ROLE_TITLE]: "Change role name",
  [changeTypes.UPDATE_MAPPINGS]: "Update mappings",
  [changeTypes.ADD_NEW_ROLE_TO_FORM]: "Create new role",
  [changeTypes.CHANGE_COMPONENT_TYPE]: "Change component type",
  [changeTypes.FORM_ANALYZED]: "Form Analyzed",
  [changeTypes.ADD_COMPONENT_OUTPUT_INSTANCE]: "Add output field",
  [changeTypes.REMOVE_COMPONENT_OUTPUT_INSTANCE]: "Remove output field",
  [changeTypes.COMPONENT_DELETED]: "Delete component",
  [changeTypes.RESIZE_OUTPUT_FIELD]: "Resize output field",
  [changeTypes.CHANGE_FONT_SIZE]: "Change output field font size",
  [changeTypes.SET_RAW_SCHEMA]: "Setting schema using code view",
};

/**
 * Takes an action and marks it as a "UndoableChangeEvent" which will be used for
 * undo/redo behavior.
 *
 * NOTE: This will assume the action is undoable unless explicitly marked with
 * `undoable=false`.  If marked as such, the history will be cleared when
 * that action is dispatched.
 */
function UndoableChangeEvent(action: Action): UndoableChangeEventT {
  const { changeType, undoable = true } = action.payload;

  invariant(
    changeType,
    "Change events should have a 'changeType' property in their payload"
  );

  const description = action.payload.description || changeMessages[changeType];

  invariant(
    description,
    "Change events should have a 'description' property in their payload"
  );

  const meta = action.meta || {};

  return {
    ...action,
    meta: {
      ...meta,
      change: {
        type: changeType,
        description,
        undoable,
      },
    },
  };
}

/**
 * As a way to reduce boilerplate, we can allow actions to be dispatched with
 * only a type string and assume that coerce that into an action object with a
 * type
 *
 * @example
 * dispatch('Launch');
 * //=> Same as...
 * //=> dispatch({ type: "Launch" })
 */
export function ensureActionFormat(action: ActionType | Action): Action {
  if (typeof action === "string") {
    return {
      type: action,
    };
  } else {
    return action;
  }
}

/**
 * Handles state for everything _except_ the draft
 */
// @ts-expect-error refactor
function appReducer(state, action) {
  switch (action.type) {
    case AT.PUBLISH:
    case AT.PUBLISH_ERROR:
    case AT.SAVE:
    case AT.SAVE_ERROR:
    case AT.SAVE_SUCCESS:
    case AT.SAVE_AS_TEMPLATE:
    case AT.SAVE_AS_TEMPLATE_ERROR:
    case AT.SAVE_AS_TEMPLATE_SUCCESS:
    case AT.LAUNCH:
    case AT.LAUNCH_SUCCESS:
    case AT.LAUNCH_ERROR: {
      return transitionStatus(state, action);
    }
    case AT.OPEN_ROLE_MODAL:
      return {
        ...state,
        roleModalOpen: true,
      };
    case AT.CLOSE_ROLE_MODAL:
      return {
        ...state,
        roleModalOpen: false,
      };
    case AT.OPEN_MERGE_FIELDS_MODAL:
      return {
        ...state,
        mergeFieldsModalOpen: true,
      };
    case AT.CLOSE_MERGE_FIELDS_MODAL:
      return {
        ...state,
        mergeFieldsModalOpen: false,
      };
    case AT.OPEN_DELETE_FORM_MODAL:
      return {
        ...state,
        deleteFormModalOpen: true,
      };
    case AT.CLOSE_DELETE_FORM_MODAL:
      return {
        ...state,
        deleteFormModalOpen: false,
      };
    case AT.OPEN_REORDER_FORMS_MODAL:
      return {
        ...state,
        reorderFormsModalOpen: true,
      };
    case AT.CLOSE_REORDER_FORMS_MODAL:
      return {
        ...state,
        reorderFormsModalOpen: false,
      };
    case AT.UPDATE_LAST_SET_ROLES: {
      invariant(
        action.payload,
        "'UPDATE_LAST_SET_ROLES' action should have a payload with `formId` and `role` properties"
      );

      const { formId, role } = action.payload;

      return {
        ...state,
        lastSetRoles: {
          ...state.lastSetRoles,
          workflow: role,
          [formId]: role,
        },
      };
    }
    case AT.CHANGE_TAB: {
      const nextTab = action.payload;

      return {
        ...state,
        tab: nextTab,
      };
    }
    case AT.CLOSE_PRE_PUBLISH_MODAL: {
      return {
        ...state,
        prePublishModalOpen: false,
      };
    }
    case AT.OPEN_PRE_PUBLISH_MODAL: {
      return {
        ...state,
        prePublishModalOpen: true,
      };
    }
    case AT.SEND_ON_PUBLISH: {
      return {
        ...state,
        sendOnPublish: action.payload,
      };
    }
    case AT.OPEN_MODAL: {
      return {
        ...state,
        modals: [...state.modals, action.payload],
      };
    }
    case AT.CLOSE_MODAL: {
      const nextModals: Array<Modal> = state.modals.slice(0, -1);

      return {
        ...state,
        modals: nextModals,
      };
    }

    default:
      return state;
  }
}

/**
 * Handles all state related to drafts
 */
const draftReducer = undoable(
  function draftReducer(draft: DraftState & { schema: ParsedSchema }, action) {
    switch (action.type) {
      case AT.SET_NAME: {
        invariant(
          action.payload,
          "'SET_NAME' actions should have a payload with a 'value' property"
        );

        const { value } = action.payload;

        return {
          ...draft,
          name: value,
        };
      }
      case AT.SET_DEFAULT_WORKFLOW_NAME: {
        invariant(
          action.payload,
          "'SET_DEFAULT_WORKFLOW_NAME' actions must define a payload"
        );

        const { updater, path } = action.payload;

        const updatedDraft = updateIn(draft, ["schema"], (schemaState) =>
          updateIn(schemaState, ["value", ...path], updater)
        );

        const workflowName = defaultWorkflowName(
          // @ts-expect-error refactor
          getIn(updatedDraft, ["schema", "value", "forms"])
        );

        return {
          // @ts-expect-error refactor
          ...updatedDraft,
          name: workflowName,
        };
      }
      case AT.UPDATE_PARSED_SCHEMA: {
        invariant(
          action.payload,
          "'UPDATE_PARSED_SCHEMA' actions must define a payload"
        );

        const { updater, path } = action.payload;

        return updateIn(draft, ["schema"], (schemaState) =>
          updateIn(schemaState, path, updater)
        );
      }

      case AT.SET_RAW_SCHEMA: {
        invariant(
          action.payload,
          "'SET_RAW_SCHEMA' actions must define a payload"
        );

        const { updater } = action.payload;

        return updateIn(draft, ["schema"], updater);
      }

      /**
       * This is an action that allows for local state to be part of the undo/redo
       * stack. This state is _not_ saved to the server, but it is part of the
       * local browser state. It's a plain object, so each individual use case
       * must properly handle empty values
       */
      case AT.UPDATE_LOCAL_STATE: {
        const { path, updater } = action.payload;

        return updateIn(draft, ["local"], (state) => {
          return updateIn(state, path, updater);
        });
      }
      case AT.ADD_NEW_ROLE_TO_FORM: {
        const { formId } = action.payload;
        const newRole = Factories.Role({ title: NEW_ROLE_NAME });
        const withNewRole = updateIn(draft, ["schema", "roles"], (roles) =>
          addLast(roles, newRole)
        );

        // @ts-expect-error refactor
        return updateIn(withNewRole, ["local"], (localState) => {
          return updateIn(
            localState,
            ["temporaryRoleIdsByForm", formId],
            (list) => addLast(list || [], newRole.id)
          );
        });
      }
      case AT.DELETE_FORM_ROLE: {
        const { formPath, roleId } = action.payload;
        const form = getIn(draft, ["schema", ...formPath]);
        const temporaryFormRoleIds =
          // @ts-expect-error refactor
          draft?.local?.temporaryRoleIdsByForm?.[form.id];

        return pipe(
          (draft) =>
            updateIn(draft, ["schema", ...formPath], (form) =>
              removeFieldsWithRole(form, roleId, temporaryFormRoleIds)
            ),
          (draft) =>
            updateIn(
              // @ts-expect-error refactor
              draft,
              // @ts-expect-error refactor
              ["local", "temporaryRoleIdsByForm", form.id],
              // @ts-expect-error refactor
              (roleIds = []) => roleIds.filter((id) => id !== roleId)
            )
        )(draft);
      }

      case AT.CHANGE_COMPONENT_TYPE: {
        invariant(
          action.payload,
          "'CHANGE_COMPONENT_TYPE' actions must define a payload"
        );
        const { fieldPath, type } = action.payload;
        const field = getIn(draft, ["schema", ...fieldPath]);

        invariant(field, "'field' needs to exist in the schema");

        const formPath = fieldPath.slice(0, 2);
        const formId = getIn(draft, ["schema", ...formPath, "id"]);

        return pipe(
          (draft) =>
            updateIn(draft, ["schema", ...fieldPath], (field) => {
              // The labels is passed only if
              // it was changed from the default one
              let label;
              // @ts-expect-error refactor
              if (field.label !== FieldLabels[field.type]) {
                label = field.label;
              }

              return Factories.Field({
                type,
                id: field.id,
                dataRef: field.dataRef,
                roles: field.roles,
                ...(label && { label }),
              });
            }),
          (draft) =>
            // @ts-expect-error refactor
            updateIn(draft, ["schema", ...formPath, "pdfMap"], (mapping) => {
              if (mapping) {
                // @ts-expect-error refactor
                const updatedField = getIn(draft, ["schema", ...fieldPath]);
                // @ts-expect-error refactor
                return mapping.map((map) => {
                  // @ts-expect-error refactor
                  if (map.source === field.id) {
                    return createForField(updatedField, map);
                  }
                  return map;
                });
              }
            }),
          (draft) =>
            // @ts-expect-error refactor
            updateIn(draft, ["schema", "mapping"], (mapping) =>
              // @ts-expect-error refactor
              deleteFieldMappings(field, formId, mapping)
            )
        )(draft);
      }

      case AT.UPDATE_DRAFT: {
        invariant(
          action.payload,
          "'UPDATE_DRAFT' actions must define a payload"
        );

        const { schema, name } = action.payload;

        return {
          ...draft,
          name,
          schema,
        };
      }

      case AT.FIELD_REASSIGNED: {
        const { formId, field, roleId } = action.payload;
        const { schema } = draft;

        return {
          ...draft,
          schema: Schema.reassignComponent(schema, field, formId, roleId),
        };
      }

      case AT.ADD_COMPONENT_OUTPUT_INSTANCE: {
        const { formId, component, pageRect } = action.payload;

        return {
          ...draft,
          schema: {
            // @ts-expect-error refactor
            ...draft.schema,
            forms: draft.schema.forms.map((form) => {
              if (form.id !== formId) return form;

              const placedMapping = autoPlaceOutputField(
                component,
                form.pdfMap,
                pageRect
              );

              return {
                ...form,
                pdfMap: addLast(form.pdfMap ?? [], placedMapping),
              };
            }),
          },
        };
      }

      case AT.REMOVE_COMPONENT_OUTPUT_INSTANCE: {
        const { id, formId } = action.payload;

        return {
          ...draft,
          schema: {
            // @ts-expect-error refactor
            ...draft.schema,
            forms: draft.schema.forms.map((form) => {
              if (form.id !== formId) return form;
              const outputField = (form.pdfMap ?? []).find(
                (field) => field.id === id
              );

              return {
                ...form,
                pdfMap: (form.pdfMap ?? []).filter((field) => {
                  if (field.id === id) return false;
                  // @ts-expect-error refactor
                  if (OutputField.inSameSet(field, outputField)) {
                    return false;
                  }
                  return true;
                }),
              };
            }),
          },
        };
      }

      // TODO: Move this logic to `schema-ops`
      case AT.COMPONENT_DELETED: {
        const { path, formId } = action.payload;
        const field = getIn(draft.schema, path);
        const { schema } = draft;
        const formIdx = schema.forms.findIndex((form) => form.id === formId);

        invariant(formIdx !== -1, `Could not find form with id '${formId}'`);

        const pdfPath = ["forms", formIdx, "pdfMap"];
        // @ts-expect-error refactor
        const fieldIds = [field.id];

        if (
          // @ts-expect-error refactor
          field.type === "Group" &&
          // @ts-expect-error refactor
          Array.isArray(field.children) &&
          // @ts-expect-error refactor
          field.children.length > 0
        ) {
          // @ts-expect-error refactor
          fieldIds.push(...field.children.map((child) => child.id));
        }

        const nextSchema = pipe(
          (schema) =>
            updateIn(schema, pdfPath, (pdfMap) =>
              // @ts-expect-error refactor
              (pdfMap || []).filter((map) => !fieldIds.includes(map.source))
            ),
          (schema) =>
            // @ts-expect-error refactor
            updateIn(schema, ["mapping"], (mapping) =>
              // @ts-expect-error refactor
              deleteFieldMappings(field, formId, mapping)
            ),
          (schema) =>
            // @ts-expect-error refactor
            updateIn(schema, ["forms", formIdx, "fields"], (fields) =>
              // @ts-expect-error refactor
              removeCalculationReferences(fields, field)
            ),
          (schema) => removeFieldAtPath(schema, path)
        )(schema);

        return {
          ...draft,
          schema: nextSchema,
        };
      }

      case AT.INIT: {
        return {
          ...draft,
          local: {
            /**
             * In the V2 editor it's possible to add a role to a form without
             * actually assigning any fields yet. This is meant to allow the
             * user to first select a role and then a field. This technically
             * is not a valid draft state because roles only exist on _fields_,
             * not forms, so if a form does not have any fields with a particular
             * role then that role is not participating in that form. To get
             * around this, we allow for temporary roles for each form to be
             * part of the local state. Temporary roles allow the role group to
             * be rendered with the form, but isn't persisted.
             */
            temporaryRoleIdsByForm: {},
          },
        };
      }

      case AT.RESIZE_OUTPUT_FIELD: {
        invariant(
          action.payload,
          "'RESIZE_OUTPUT_FIELD' actions must define a payload"
        );

        const { path, resizedFields } = action.payload;

        const nextSchema = pipe(
          (schema) => {
            const sources = {};

            return {
              schema: updateIn(schema, [...path, "pdfMap"], (pdfMap) =>
                // @ts-expect-error refactor
                pdfMap.map((outputField) => {
                  if (resizedFields[outputField.id]) {
                    // @ts-expect-error refactor
                    sources[outputField.source] = (component) =>
                      Component.updateMaxLength(component, {
                        width: resized.width,
                        height: resized.height,
                        fontSize: outputField.fontSize,
                      });
                    const resized = resizedFields[outputField.id];
                    return withRawValue({
                      ...outputField,
                      coords: {
                        ...outputField.coords,
                        x: resized.x,
                        y: resized.y,
                        page: resized.page,
                      },
                      dimensions: {
                        ...outputField.dimensions,
                        width: resized.width,
                        height: resized.height,
                      },
                    });
                  } else {
                    return outputField;
                  }
                })
              ),
              sources,
            };
          },

          ({ schema, sources }) =>
            // @ts-expect-error refactor
            updateIn(schema, path, (form) =>
              Form.updateComponents(form, sources)
            )
        )(draft.schema);

        return {
          ...draft,
          schema: nextSchema,
        };
      }

      case AT.CHANGE_FONT_SIZE: {
        invariant(
          action.payload,
          "'CHANGE_FONT_SIZE' actions must define a payload"
        );

        const { updater, path, mapping } = action.payload;
        const basePath = path.slice(0, 2);

        const nextSchema = pipe(
          (schema) => updateIn(schema, path, updater),
          (schema) =>
            // @ts-expect-error refactor
            updateIn(schema, basePath, (form) =>
              Form.updateComponent(form, mapping.source, (component) =>
                Component.updateMaxLength(component, {
                  width: mapping.dimensions.width,
                  height: mapping.dimensions.height,
                  fontSize: mapping.fontSize,
                })
              )
            )
        )(draft.schema);

        return {
          ...draft,
          schema: nextSchema,
        };
      }
      default:
        return draft;
    }
  },
  {
    limit: UNDO_LIMIT,
  }
);

// @ts-expect-error refactor
function requestFieldReassignmentReducer(state, action) {
  const { path, roleId, field } = action.payload;
  const currentDraft = Selectors.draft(state);
  const { schema } = currentDraft;
  // @ts-expect-error refactor
  const fieldsIndex = path.findIndex((val) => val === "fields");
  const formPath = path.slice(0, fieldsIndex);
  // @ts-expect-error refactor
  const form = getIn(schema, formPath);
  // @ts-expect-error refactor
  const formId = form.id;

  const assignmentProps = {
    formId,
    roleId,
    field,
    schema,
    layer: "hoverCard",
  };

  // @ts-expect-error refactor
  if (hasComponentReferences(schema, field, form.id)) {
    return {
      ...state,
      app: {
        ...state.app,
        modals: [
          ...state.app.modals,
          {
            type: "confirm-reassignment",
            props: assignmentProps,
            confirmAction: Actions.ReassignField(assignmentProps),
          },
        ],
      },
    };
  } else {
    return reducer(state, Actions.ReassignField(assignmentProps));
  }
}

// @ts-expect-error refactor
function requestComponentDeletionReducer(state, action) {
  const { path, formId } = action.payload;
  const currentDraft = Selectors.draft(state);
  const { schema } = currentDraft;
  // @ts-expect-error refactor
  const field = getIn(schema, path);
  // @ts-expect-error refactor
  const fieldsIndex = path.findIndex((val) => val === "fields");
  const formPath = path.slice(0, fieldsIndex);
  // @ts-expect-error refactor
  const form = getIn(schema, formPath);

  const modalProps = {
    formId,
    field,
    schema,
  };
  const confirmAction = Actions.DeleteComponent({
    formId,
    path,
  });

  // @ts-expect-error refactor
  if (hasComponentReferences(schema, field, form.id)) {
    return {
      ...state,
      app: {
        ...state.app,
        modals: [
          ...state.app.modals,
          {
            type: "confirm-component-deletion",
            props: modalProps,
            confirmAction,
          },
        ],
      },
    };
  } else {
    return reducer(state, confirmAction);
  }
}

export function init({ draft, currentTab }: any) {
  return {
    app: appReducer(
      {
        ...initialState.app,
        tab: currentTab,
      },
      Actions.Init()
    ),
    draft: draftReducer(
      {
        name: draft.name,
        schema: parseSchema(draft.schema),
      },
      Actions.Init()
    ),
  };
}

// @ts-expect-error refactor
function parseSchema(schema) {
  try {
    return migrate(JSON.parse(schema));
  } catch {
    return schema;
  }
}

/**
 * Reducer
 */
// @ts-expect-error refactor
export function reducer(state: any, action: Action) {
  logger.debug(action);

  switch (action.type) {
    case AT.COMPONENT_DELETION_REQUESTED:
      return requestComponentDeletionReducer(state, action);
    case AT.FIELD_REASSIGNMENT_REQUESTED:
      return requestFieldReassignmentReducer(state, action);
    default:
      return {
        app: appReducer(state.app, action),
        draft: draftReducer(state.draft, action),
      };
  }
}

export const Selectors = {
  draft: (state: State): DraftState => state.draft.present,
  lastCommitted: (state: State) => lastCommitted(state.draft),
  lastUndone: (state: State) => lastUndone(state.draft),

  /**
   * Due to the way we trigger saves, we only want to select the values that
   * should be saved from the state here, otherwise we'll trigger saves for
   * items in the state that aren't actually saved. This is not necessarily
   * harmful, but causes some unnecessary re-renders and API calls that make
   * tests harder. Using a `reselect` selector here will memoize the result
   * so we only return a new value here if it's changed
   */
  savedDraft: createSelector<State, any, any, any, any>(
    (state) => state.draft.present.name,
    (state) => state.draft.present.schema,
    (name, schema) => ({ name, schema })
  ),
};

// @ts-expect-error refactor
function transitionStatus(state, action) {
  const currentStatus = state.status;
  const nextStatus = getIn(constants.STATUS_MACHINE, [
    currentStatus,
    action.type,
  ]);

  if (nextStatus) {
    return {
      ...state,
      status: nextStatus,
    };
  } else {
    logger.error(
      `Invalid status in the workflow editor trying to ${action.type} from ${currentStatus}`
    );
    return state;
  }
}

/**
 * Filters out any fields in a form that have the given roleId with the following
 * caveat:
 *
 *   - If a field has no role (`Paragraph`) and there are other roles in the
 *     form, do not remove it. `Paragraph` fields should exist in the form as
 *     long as there are roles in the form
 *
 * TODO: Consolidate this logic with the logic for deleting a single
 * field.
 */
// @ts-expect-error refactor
function removeFieldsWithRole(form, roleId, temporaryFormRoleIds) {
  const formRoles = getFormRoleIds(form).concat(temporaryFormRoleIds);
  // @ts-expect-error refactor
  const componentIdsToRemove = [];

  Form.traverseComponents(form, (component) => {
    const componentHasExplicitRole = hasExplicitRole(component);
    const componentHasImplicitRole = !componentHasExplicitRole;

    if (componentHasImplicitRole && formRoles.length <= 1) {
      // If this field has an implicit role (i.e. a `Paragraph` component),
      // only delete this component if there's only a single role in the form.
      // If there is more than one role, we want to keep this component because
      // it's implicitly assigned to _all_ roles
      componentIdsToRemove.push(component.id);
    } else if (
      componentHasExplicitRole &&
      isFieldAssignedToRole(component, roleId)
    ) {
      // Otherwise, delete this compoent if it's explicitly assigned to the
      // given `roleId`
      componentIdsToRemove.push(component.id);
    }
  });

  // @ts-expect-error refactor
  function removeMappings(form) {
    return updateIn(form, ["pdfMap"], (pdfMap = []) =>
      // @ts-expect-error refactor
      pdfMap.filter((mapping) => {
        // Ensure we don't remove output fields that don't have an explicit
        // component source (i.e. custom output fields)
        const hasExplicitSource = mapping.source !== undefined;

        return hasExplicitSource
          ? // @ts-expect-error refactor
            !componentIdsToRemove.includes(mapping.source)
          : true;
      })
    );
  }

  // @ts-expect-error refactor
  function removeFields(form) {
    return updateIn(form, ["fields"], (fields) =>
      // @ts-expect-error refactor
      fields.filter((field) => !componentIdsToRemove.includes(field.id))
    );
  }

  return pipe(removeMappings, removeFields)(form);
}

/**
 * Returns true if the given component has references to other components
 *
 * TODO: This probably belongs in `schema-ops` or somewhere closer to other
 * schema-specific logic
 */
// @ts-expect-error refactor
function hasComponentReferences(schema, component, formId) {
  if (typeof schema === "string") return false;
  const { forms, mapping: mappings } = schema;
  // @ts-expect-error refactor
  const form = forms.find((form) => form.id === formId);

  invariant(form, `Could not find form with id '${formId}'`);

  const hasConditionalLogicReferences =
    getReferringFields(form.fields, component).length !== 0;

  const hasMappingReferences =
    getReferringFormLabels(component, formId, forms, mappings).length !== 0;

  const hasCalculationReferences =
    getReferringCalculationLabels(form.fields, component).length !== 0;

  return (
    hasConditionalLogicReferences ||
    hasMappingReferences ||
    hasCalculationReferences
  );
}
