import DOMPurify from "dompurify";

const defaultOptions = {
  USE_PROFILES: {
    html: true,
  },
};

export type SanitizeOptions = DOMPurify.Config;

/**
 * Sanitizes a string of HTML for rendering via `dangerouslySetInnerHTML`
 *
 * @example
 * const sanitizedHtml = sanitize(htmlString)
 *
 * <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
 */
export default function sanitize(
  htmlString: string,
  options: SanitizeOptions
): ReturnType<typeof DOMPurify["sanitize"]> {
  const newOptions = { ...defaultOptions, ...options };

  return DOMPurify.sanitize(htmlString, newOptions);
}
