/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import mapTree from "./map-tree";
import nodeHandlers from "./node-handlers";
import markHandlers from "./mark-handlers";
import RendererContainer from "../../common/container";

type Props = {
  /**
   * A custom container component to use to render the contents.  By default, the
   * rich text container is used which adds common typographic and spacing
   * styles.  If you want to render without styles applied, you can pass a plain
   * `div`.
   *
   * @example
   * const plain = <JSONReactRenderer doc={doc} data={data} Container='div' />
   */
  Container: React.ComponentType<any>;

  /**
   * The JSON document describing the rich text
   *
   * TODO: Flow Types
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  doc: {};

  /**
   * A map of data ref id to value that represents the current data in an
   * individual form
   */
  data: Record<string, string>;

  /**
   * A custom map of node handlers that can be used to render certain nodes
   * in a custom way.
   */
  nodeHandlers: Record<string, string | React.ComponentType<any>>;

  /**
   * A custom map of mark handlers that can be used to render certain marks
   * in a custom way.
   */
  markHandlers: Record<string, string | React.ComponentType<any>>;
};

/**
 * Takes a rich text JSON document and renders it to a react tree.  A `data`
 * object can be provided for data ref looks up when `dataRef` nodes are in place.
 */
class JSONToReactRenderer extends React.Component<Props> {
  static defaultProps = {
    data: {},
    Container: RendererContainer,
    nodeHandlers,
    markHandlers,
  };

  static defaultNodeHandlers = nodeHandlers;

  static defaultMarkHandlers = markHandlers;

  render() {
    const { Container, doc, data, nodeHandlers, markHandlers } = this.props;

    const tree = mapTree(doc, { data, nodeHandlers, markHandlers });

    return <Container>{tree}</Container>;
  }
}

export default JSONToReactRenderer;
