import * as React from "react";

export default function TriangleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 373.008 373.008"
    >
      <path
        d="M61.792 2.588A19.258 19.258 0 0 1 71.444 0c3.33 0 6.663.864 9.655 2.588l230.116 167.2a19.327 19.327 0 0 1 9.656 16.719 19.293 19.293 0 0 1-9.656 16.713L81.099 370.427a19.336 19.336 0 0 1-19.302 0 19.333 19.333 0 0 1-9.66-16.724V19.305a19.308 19.308 0 0 1 9.655-16.717z"
        fill="currentColor"
      />
    </svg>
  );
}
