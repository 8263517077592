import React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import type { TriggerProps } from "hw/ui/dropdown/types";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { AddIcon } from "hw/ui/icons";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function AddComponentCopy(props) {
  return (
    <div
      {...props}
      className={useStyle("add-component-copy", {
        fontSize: theme.fontSize.md,
        color: theme.color.gray600,
        lineHeight: "24px",
        marginLeft: theme.space.sm,
      })}
    />
  );
}
/**
 * @one-off Button this is a button with a dashed line around. For attachments
 * we will use a similar one, but I prefered to keep it as a one off for now
 * @provisional
 */

export default function NewComponentButton( // $FlowIgnore
  props: TriggerProps & {
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (...args: Array<any>) => any;
  }
) {
  const { triggerPassthroughProps, label, onClick } = props;
  const { getButtonProps } = triggerPassthroughProps;
  const cn = useStyle("new-component-button", {
    display: "inline-flex",
    alignItems: "center",
    borderColor: theme.color.gray200,
    color: theme.color.gray500,
    borderStyle: "dashed",
    borderWidth: "1px",
    padding: theme.space.sm,
    borderRadius: theme.corner.sm,
    transitionProperty: "all",
    transitionDuration: theme.transitionSpeed.fast,
    backgroundColor: theme.color.transparent,
    outline: "none",
    cursor: "pointer",
    boxShadow: "none",
    width: "100%",
    ":hover": {
      backgroundColor: theme.color.gray050,
    },
    ":active": {
      backgroundColor: theme.color.gray100,
    },
    ":focus": {
      borderColor: theme.color.uiFocus,
      outline: "none",
    },
  });
  return (
    <button
      className={cn}
      {...getTestAttributes("editor-build-new-field-btn")}
      data-testid="editor-build-new-field-btn"
      data-track-id="editor-add_component_menu_trigger"
      {...getButtonProps({
        onClick,
      })}
    >
      <AddIcon data-test-ref="new-field-menu-icon" />
      <AddComponentCopy>{label}</AddComponentCopy>
    </button>
  );
}
NewComponentButton.defaultProps = {
  label: "Add form component",
};
