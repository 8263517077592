import * as React from "react";
import { Flex } from "hw/ui/blocks";
import { Heading4 } from "hw/ui/text/heading";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";
import { Step1, Step2, Step3, Step4, Step5 } from "./assets";

const PROGRESS_TIME = 60000; // Value in ms
const PERCENTAGE_INCREASE = 1;

type Props = {
  /**
   * It specifies if the analysis has been completed or not
   */
  completed: boolean;
};

/**
 * The loading screen displays different images and messages
 * based on how much time has passed by.
 * It accepts a completed prop, to accelerate the loading bar
 * when the analysis has been completed
 */
export default function LoadingScreen(props: Props) {
  const { completed } = props;
  const delay = PROGRESS_TIME / 100 / PERCENTAGE_INCREASE;

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const updateProgress = () => {
      if (progress >= 90) {
        clearInterval(timer);
      } else {
        setProgress((prevProgress) => prevProgress + PERCENTAGE_INCREASE);
      }
    };

    if (completed) {
      setProgress(100);
    }

    const timer = setInterval(updateProgress, delay);
    return () => clearInterval(timer);
  }, [delay, progress, completed]);

  // NOTE: Instead of using the <progress /> element, we use a custom one
  // since transitions seem not to work in the progress element bar.
  const wrapperProgressBarCn = useStyle("progress-bar", {
    width: "100%",
    height: "4px",
    backgroundColor: theme.color.gray100,
    borderRadius: theme.corner.md,
  });

  const progressBarCn = useStyle("progress-bar-inner", {
    height: "100%",
    transition: `${
      completed
        ? "width 200ms ease-in"
        : `width ${delay}ms cubic-bezier(.4,.6,.6,1)`
    }`,
    width: `${progress}%`,
    backgroundColor: theme.color.uiActionBase,
    borderRadius: "5px",
  });

  return (
    <Flex
      // @ts-expect-error refactor
      padding={theme.space.lg}
      width={1}
      flexDirection="column"
      alignItems="center"
    >
      {/* @ts-expect-error refactor */}
      <Heading4
        extend={{ color: theme.color.black, marginBottom: theme.space.md }}
      >
        Building your form
      </Heading4>
      <GraphicStep progress={progress} />
      <div className={wrapperProgressBarCn}>
        <div className={progressBarCn} />
      </div>
    </Flex>
  );
}

type GraphicsProps = {
  progress: number;
};

function GraphicStep(props: GraphicsProps) {
  const { progress } = props;
  const currentStep = getCurrentStep(progress);

  return (
    <>
      <img
        className={useStyle("step-progress-illustration", {
          width: "120px",
          marginBottom: theme.space.lg,
        })}
        // @ts-expect-error refactor
        src={currentStep.image}
        // @ts-expect-error refactor
        alt={currentStep.message}
      />
      <Flex
        alignItems="center"
        fontSize="ms"
        // @ts-expect-error refactor
        marginBottom="ms"
        extend={{ lineHeight: "16px", height: "48px", textAlign: "center" }}
      >
        {/* @ts-expect-error refactor */}
        {currentStep.message}
      </Flex>
    </>
  );
}

const steps = [
  { image: Step1, message: "Locating fields..." },
  { image: Step2, message: "Extracting labels..." },
  { image: Step3, message: "Identifying input types..." },
  { image: Step4, message: "Constructing mobile-friendly form..." },
  { image: Step5, message: "Finalizing details..." },
];

const completedStep = { image: Step5, message: "Finalizing details..." };
const overTimeStep = {
  image: Step5,
  message:
    "This is taking longer than expected due to higher demand. Your form should be ready shortly.",
};

function getCurrentStep(progress: number) {
  const stepValue = Math.floor(progress / 10);
  switch (stepValue) {
    case 9:
      return overTimeStep;
    case 10:
      return completedStep;
    default:
      return steps[Math.floor(stepValue / 2)];
  }
}
