import * as React from "react";
import cx from "classnames";
import styles from "./error-message.module.css";

type Props = {
  children: React.ReactNode | null | undefined;

  // NOTE:  Pass a class name if you need to extend the styles.
  // This should be used sparingly.
  className?: string;
};

/**
 * A generic error message component for smartforms components
 *
 * @example
 * function MyComponent(props) {
 *   <div>
 *     {hasError && <ErrorMessage>You must select at least one option</ErrorMessage>}
 *   </div>
 * }
 */
export default function ErrorMessage(props: Props) {
  const { children, className } = props;

  // NOTE: The static class name is here for legacy purposes.  Avoid using it
  // for contextual styles
  const cn = cx(styles.errorMsg, className, "m-sf--error-message");

  return (
    <div className={cn} data-automation-id="sf-error-message">
      {children}
    </div>
  );
}
