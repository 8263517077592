/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import memoizeOne from "memoize-one";
import cond from "lodash/cond";
import constant from "lodash/constant";
import { getIn } from "timm";
import matchesProperty from "lodash/matchesProperty";
import * as resizeListener from "hw/common/utils/dom/resize-listener";
import { Transition } from "react-transition-group";
import {
  HoverCardContent,
  HoverCardWrapper,
  TRANSITION_DURATION,
} from "./components/styled";
import { shouldFitToContainer } from "./utils";
import * as Layout from "./layout";
import Portal from "./components/portal";
import type {
  Coords,
  Position,
  Align,
  Justify,
  Type,
  RenderDest,
} from "./types";

export type Props = {
  /**
   * If true, the content will be rendered
   */
  active: boolean;

  /**
   * Aligns the content vertically.  This only takes effect when the position
   * is 'left' or 'right'.
   */
  align: Align;

  /**
   * The content of the hover card.  This can be any React element, for example, a list of `MenuItem` components for creating a dropdown.
   */
  children: React.ReactNode;

  /**
   * If this is `true` the parent wrapper on the hover card will be set to
   * `100%` width.
   *
   * TODO: I think this should always be the case, but we need to make sure
   * we're not breaking anything that's relying on the existing behavior
   */
  fillContainer?: boolean;

  /**
   * If `true` the content of the hover card will be sized to fit the width of
   * the trigger.
   */
  fitToContainer?: boolean;

  /**
   * Aligns the content horizontally.  This only takes effect when the
   * position is 'top' or 'bottom'.
   *
   */
  justify: Justify;

  /**
   * The element to position the content around.
   *
   * Typically this will be a React element, but you can also pass an arbitrary
   * DOM element if you need to anchor around an element that's outside the
   * React tree.
   */
  anchor: React.ReactNode | HTMLElement;

  /**
   * This is called when the position of the hover card changes.  This can
   * happen if the content would appear offscreen in the originally specified
   * position.  This can be used to adjust styling of a specific trigger.
   */
  onPositionChange: (newPosition: Position) => void;

  /**
   * The position of the content around the anchor.
   */
  position: Position;

  /**
   * If this value is set to `portal` then the hover card will be rendered within a
   * portal that's a sibling to the document body.  This is useful if you need the
   * hovercard to break out of a containing parent that's hiding its overflow,
   * but it requires more UI intensive tracking to properly position it.  When
   * rendering in a portal, the hover card becomes detached from its target so
   * scrolling and resize events must be handled manually in order to keep the card
   * positioned to the target correctly.
   *
   * By default all hover cards are rendered inline.
   */
  renderDest: RenderDest;

  /**
   * Indicates the style `type` of hover card to render.  Different types have
   * different styles.  For example, a `dropdown` type will have a white background
   * whereas a `tooltip` type will have a black background with an arrow
   * pointing towards the anchor.
   */
  type: Type;

  /**
   * If `true`, then the hover card will always use the layout preferences that
   * are specified in props.  This can be useful if the automatic layout
   * calculations are causing unexpected issues
   */
  usePreferredLayout: boolean;

  /**
   * Extra style to pass to the wrapper. Use sparingly as this could break
   * the positioning
   */
  style?: Record<string, unknown>;

  /**
   * Extra style to be applied to the content of the hover card
   */
  extendContentStyle?: Record<string, unknown>;

  /**
   * Called when the component finishes the transition exit transition
   */
  onExited?: (node: HTMLElement) => void;

  /**
   * Called when the content has entered after the transition
   */
  onEnter?: (node: HTMLElement) => void;

  /**
   * This value sets the space between the hover card and its anchor. It's
   * generally not meant to be configurable, but we need it configurable
   * while we're updating some component patterns
   */
  UNSAFE_gutter?: number;

  /**
   * If `true`, the component will animate open or closed. Defaults to `true`
   */
  shouldAnimate?: boolean;
};

type DefaultProps = {
  align: "top";
  justify: "left";
  position: "bottom";
  type: "dropdown";
  onPositionChange: () => void;
  renderDest: "inline";
  usePreferredLayout: false;
  shouldAnimate: true;
};

/**
 * The component can be in one of four states:
 *
 *    1. Mounting - This means that the hover card has been switched to `active`
 *    and needs to be rendered to the DOM.  At this point we need to measure the
 *    dimensions of the target and the card content to know where to render on
 *    the page.
 *    2. Mounted - This means that all of the necessary elements have been
 *    measured and we're ready to render into the DOM.
 *    3. Unmounting - The hover card has been switch to inactive, so we need to
 *    remove the card from the DOM
 *    4. Unmounted - The hover card is inactive and should not render its contents
 *
 * There's some additional state defined for this component to handle in-between
 * states where we need to measure all of the dimensions and also transition
 * the content in.
 */
type Mounting = {
  status: "mounting";
};

type Mounted = {
  status: "mounted";
  data: {
    coords: Coords;
    position: Position;
    justify: Justify;
    align: Align;
  };
};

type Unmounting = {
  status: "unmounting";
  data: {
    coords: Coords;
    position: Position;
    justify: Justify;
    align: Align;
  };
};

type Unmounted = {
  status: "unmounted";
};

type State = {
  card: Mounting | Mounted | Unmounting | Unmounted;
};

const isMounting = matchesProperty(["card", "status"], "mounting");
const isUnmounting = matchesProperty(["card", "status"], "unmounting");
const isMounted = matchesProperty(["card", "status"], "mounted");
const fallback = constant(true);
const cardPosition = (state: State): Position =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getIn(state, ["card", "data", "position"]);

/**
 * The `HoverCard` component is a low-level component for building other components
 * that need to position themselves (or "hover") around a particular element on the
 * screen. The `HoverCard` component takes an `anchor` element and positions the
 * given `children` around that element when the `active` prop is true.
 *
 * The `HoverCard` has different "flavors" or styles that indicate how to render
 * the floating content. By default, it will be a `dropdown` style that has a white
 * background and some box shadows. There's also a `tooltip` that has a black
 * background an and arrow pointing towards the anchor.
 *
 * In advanced cases, you may need to "hover" around an arbitrary DOM element instead of
 * a React element that's passed into the component. You may pass in an `anchor`
 * prop that's a DOM element and the component will _only_ render content at the
 * position of the given DOM element. If passing a DOM element as an anchor, it's
 * required that the `renderDest` is set to `portal`.
 * _Inspired by_:
 * https://engineering.siftscience.com/best-practices-for-building-large-react-applications/
 */
export class HoverCard extends React.Component<Props, State, DefaultProps> {
  target: HTMLElement | null = null;

  content: HTMLElement | null = null;

  static defaultProps = {
    align: "top",
    justify: "left",
    position: "bottom",
    type: "dropdown",
    onPositionChange: () => {},
    renderDest: "inline",
    usePreferredLayout: false,
    shouldAnimate: true,
  };

  state: State = {
    card: {
      status: "unmounted",
    },
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    // If we've internally changed the position from what it originally
    // started from, we notify the parent.  This can be useful if you have
    // specific styles for a dropdown trigger depending on the position
    if (cardPosition(this.state) !== cardPosition(prevState)) {
      this.props.onPositionChange(cardPosition(this.state));
    }

    if (!prevProps.active && this.props.active) {
      // Moving from inactive to active
      this.mount();
    } else if (prevProps.active && !this.props.active) {
      // Moving from active to inactive
      this.unmount();
    }

    // Update the instance `target` if the anchor changes
    // TODO: Can this be applied globally? Leaving it only to the HTMLElement
    // case for now to avoid breakage.
    if (
      this.props.anchor instanceof HTMLElement &&
      prevProps.anchor !== this.props.anchor
    ) {
      this.target = this.props.anchor;
    }
  }

  /**
   * If we're active when mounting, immediately mount
   */
  componentDidMount() {
    if (this.props.anchor instanceof HTMLElement) {
      this.target = this.props.anchor;
    }

    if (this.props.active) {
      this.mount();
    }
  }

  /**
   * Remove the resize listener when unmounting if there is one
   */
  componentWillUnmount() {
    this.unmount();
    this.isWithinHoverCardContent.clear();
  }

  /**
   * Returns the width of the target element if applicable.
   */
  getTargetWidth(target: HTMLElement | null) {
    if (!target) {
      return;
    }

    return (
      shouldFitToContainer(this.props) && target.getBoundingClientRect().width
    );
  }

  /**
   * This is exposed primarily as an interface to external users that need
   * custom control of the instance.
   */
  getIsActive() {
    return this.props.active;
  }

  /**
   * Callback handler for the content ref.  This will be called when the
   * content is rendering into the DOM after being activated.  In the case
   * where we're mounting, we measure after its mounted into the Portal.
   */
  handleContentRef = (node: HTMLElement | null, measure: boolean) => {
    this.content = node;

    if (measure) {
      this.measure();
    }
  };

  handleTargetRef = (node: HTMLElement | null) => {
    if (!this.target) {
      this.target = node;
    }
  };

  isWithinHoverCardContent = memoizeOne((element: HTMLElement): boolean => {
    const isHoverCardContent = element?.dataset?.hwuiHovercard === "content";

    if (!isHoverCardContent) {
      if (element.parentElement instanceof HTMLElement) {
        return this.isWithinHoverCardContent(element.parentElement);
      }
    }

    return isHoverCardContent;
  });

  /**
   * This method computes the correct coordinates and position that the
   * hover card should be in based on the location of the target and the
   * width of the content.
   */
  measure = () => {
    const targetRect = this.target && this.target.getBoundingClientRect();
    const hoverCardRect = this.content && this.content.getBoundingClientRect();

    if (!(hoverCardRect && targetRect)) {
      return;
    }

    const {
      position,
      type,
      justify,
      align,
      renderDest,
      usePreferredLayout,
      UNSAFE_gutter: gutter,
    } = this.props;

    const layout = Layout.resolve(targetRect, hoverCardRect, {
      position,
      type,
      justify,
      align,
      renderDest,
      usePreferredLayout,
      gutter,
    });

    this.setState({
      card: {
        status: "mounted",
        data: {
          coords: layout.coordinates,
          position: layout.position,
          align: layout.align,
          justify: layout.justify,
        },
      },
    });
  };

  /**
   * Mounts the hover card content
   */
  mount() {
    this.track();

    // Hack!  In certain edge cases we might miss the measurement due to the
    // scroll bar.  Doing a final measurement in the next tick seems to
    // catch those cases...
    setTimeout(this.measure, 0);

    this.setState({
      card: {
        status: "mounting",
      },
    });
  }

  getCommonHoverCardProps() {
    const {
      type,
      position,
      justify,
      align,
      active,
      children,
      extendContentStyle,
      shouldAnimate,
    } = this.props;
    const targetWidth = this.getTargetWidth(this.target);

    return {
      type,
      position,
      justify,
      align,
      active,
      style: {
        width: targetWidth,
      },
      children,
      extendContentStyle,
      shouldAnimate,
    };
  }

  /**
   * Renders the hover card content into the portal for measuring.  When it's
   * mounted, we'll measure the contents and re-render.
   */
  measureCard() {
    const commonProps = this.getCommonHoverCardProps();

    return (
      <Portal>
        <HoverCardContent
          {...commonProps}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{
            ...commonProps.style,
            left: 0,
            top: 0,
            position: "absolute",
            visibility: "hidden",
          }}
          innerRef={(node: HTMLElement | null) =>
            this.handleContentRef(node, true)
          }
          extend={commonProps.extendContentStyle}
        />
      </Portal>
    );
  }

  /**
   * Schedules an update to meausre and reposition the hover card if necessary.
   *
   * This has not been painstakingly optimized, but is only used when rendering
   * within a portal which should be the exception.
   */
  onFrameScroll = (evt: Event) => {
    // If we're scrolling _within_ a hover card content area, we shouldn't need
    // to reposition the hover card because its position hasn't changed on
    // the screen.
    const isScrollingHoverCardContent =
      evt.target instanceof HTMLElement
        ? this.isWithinHoverCardContent(evt.target)
        : false;

    if (isScrollingHoverCardContent) return;

    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(this.measure);
    } else {
      setTimeout(this.measure, 66);
    }
  };

  /**
   * If we're rendering within a portal, we need extra event listeners in order
   * to track resize and scroll events because the hover card is detached from
   * the target in the DOM.
   */
  track = () => {
    if (this.props.renderDest === "portal") {
      resizeListener.add(this.measure);

      // This isn't throttled right now because in most cases throttling
      // actively breaks
      document.addEventListener("scroll", this.onFrameScroll, {
        passive: true,
        capture: true,
      });
    }
  };

  untrack = () => {
    document.removeEventListener("scroll", this.onFrameScroll, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      passive: true,
      capture: true,
    });

    resizeListener.remove(this.measure);
  };

  /**
   * Actually renders the card into the tree. At this point all measurements
   * have been made, so we know where to place the content on the screen.
   */
  renderCard(state: Mounted | Unmounting) {
    const { onExited, onEnter } = this.props;
    const data = state.data;
    const commonProps = this.getCommonHoverCardProps();
    const Dest = this.props.renderDest === "portal" ? Portal : React.Fragment;

    return (
      <Dest>
        <Transition
          appear
          in={commonProps.active}
          mountOnEnter
          onExited={callAll(onExited, this.resetState)}
          onEnter={onEnter}
          timeout={{
            enter: 0,
            exit: TRANSITION_DURATION,
          }}
          unmountOnExit
        >
          {(state) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <HoverCardContent
              {...commonProps}
              style={{
                ...commonProps.style,
                ...data.coords,
              }}
              data-hwui-hovercard="content"
              data-testid="hover-card-content"
              transitionState={state}
              position={data.position}
              align={data.align}
              justify={data.justify}
              innerRef={this.handleContentRef}
              extend={commonProps.extendContentStyle}
            />
          )}
        </Transition>
      </Dest>
    );
  }

  /**
   * There are different rendering cases for the hover card content that
   * are outlined above in the state.  Here we determine which state we're in
   * and render accordingly.
   */
  maybeRenderCard() {
    return cond([
      // We're mounting the card, so we measure it in the DOM to determine
      // the correct coordinates.
      [isMounting, () => this.measureCard()],

      // The card is now
      [isMounted, (state) => this.renderCard(state.card)],
      [isUnmounting, (state) => this.renderCard(state.card)],
      [fallback, () => null],
    ])(this.state);
  }

  /**
   * Resets to the unmounted state.  This will be called after transitioning
   * the element out of the DOM.
   */
  resetState = () => {
    this.setState({
      card: {
        status: "unmounted",
      },
    });
  };

  /**
   * Unmounts the hover card content
   */
  unmount() {
    this.untrack();

    if (this.state.card.status === "mounted") {
      this.setState({
        card: {
          status: "unmounting",
          data: {
            coords: this.state.card.data.coords,
            position: this.state.card.data.position,
            align: this.state.card.data.align,
            justify: this.state.card.data.justify,
          },
        },
      });
    } else {
      this.setState({
        card: {
          status: "unmounted",
        },
      });
    }
  }

  renderWithAnchor() {
    const { anchor, fillContainer, style } = this.props;

    return (
      <HoverCardWrapper
        innerRef={this.handleTargetRef}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fillContainer={fillContainer}
        style={style}
      >
        {anchor}
        {this.maybeRenderCard()}
      </HoverCardWrapper>
    );
  }

  renderWithTarget() {
    if (this.props.renderDest !== "portal") {
      throw new Error(
        'Rendering a DOM element target requires the render destination to be "portal"'
      );
    }

    return this.maybeRenderCard();
  }

  render() {
    const { anchor } = this.props;

    if (anchor instanceof HTMLElement) {
      return this.renderWithTarget();
    } else {
      return this.renderWithAnchor();
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFn = (...args: any) => any;

/**
 * Calls all of the given functions, if defined
 */
function callAll(...fns: Array<AnyFn | void>) {
  return function callAll(...args: unknown[]) {
    for (const fn of fns) {
      if (typeof fn === "function") {
        fn(...args);
      }
    }
  };
}

export default HoverCard;
