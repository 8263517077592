import * as React from "react";

export default function UnorderedList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="list-unordered-icon-24-a"
          d="M5 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM9 6h10a1 1 0 0 1 0 2H9a1 1 0 1 1 0-2zm0 5h10a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2zm0 5h10a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#list-unordered-icon-24-a" />
      </g>
    </svg>
  );
}
