import * as React from "react";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'n' implicitly has an 'any' type.
const num = (n) => typeof n === "number" && !Number.isNaN(n);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'n' implicitly has an 'any' type.
const px = (n) => (num(n) ? n + "px" : n);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'n' implicitly has an 'any' type.
const isPercent = (n) => num(n) && n >= 0 && n <= 1;

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'offset' implicitly has an 'any' t... Remove this comment to see the full error message
export function Circle({ offset, direction, circleDiameter, ...rest }) {
  const coercedOffset = getOffset(offset, circleDiameter);
  const top = direction === "vertical" && coercedOffset;
  const left = direction === "horizontal" && coercedOffset;
  const style = {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: `${circleDiameter}px`,
    width: `${circleDiameter}px`,
    borderRadius: theme.corner.round,
    backgroundColor: theme.color.white,
    borderColor: theme.color.dividerDim,
    color: theme.color.textDim,
    fontWeight: theme.fontWeight.semibold,
    fontSize: theme.fontSize.sm,
    borderWidth: "1px",
    borderStyle: "solid",
    top,
    left,
  };
  const cn = useStyle("ui-or-circle", style);

  return <div {...rest} className={cn} />;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export function Wrapper(props) {
  const { direction, spacing, circleDiameter, ...rest } = props;

  const style = {
    // This is a bit of a hack to handle making sure the component fills 100%
    // of the height of the container.
    alignSelf: direction === "vertical" && "normal",
    display: "flex",
    position: "relative",
    flexDirection: direction === "horizontal" ? "row" : "column",
    height: direction === "vertical" && "auto",
    width: getWrapperWidth({ direction, spacing, circleDiameter }),
    justifyContent: "flex-start",
    alignItems: "center",
  };
  const cn = useStyle("ui-or-wrapper", style);

  return <div {...rest} className={cn} />;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export function Line(props) {
  const { spacing, direction, ...rest } = props;
  const style = {
    ...getLineDimensions({ spacing, direction }),
    backgroundColor: theme.color.gray300,
    flex: 1,
  };
  const cn = useStyle("ui-or-line", style);

  return <div className={cn} {...rest} />;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
function getOffset(val, circleDiameter) {
  // Percentage, assume that the circle should be aligned relative to center
  // of circle
  if (isPercent(val)) {
    return `calc(${val * 100}% - ${circleDiameter / 2}px)`;
  }

  // Otherwise offset by the exact given value, converting to pixels if needed
  return px(val);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function getWrapperWidth(props) {
  const { spacing, direction, circleDiameter } = props;

  if (spacing === "none") {
    return "auto";
  }

  if (direction === "horizontal") {
    return "100%";
  } else if (direction === "vertical") {
    return `${circleDiameter}px`;
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function getLineDimensions(props) {
  const { spacing, direction } = props;

  if (spacing === "none") {
    return {
      height: 0,
      width: 0,
    };
  }

  if (direction === "horizontal") {
    return {
      height: "1px",
      width: "100%",
    };
  } else if (direction === "vertical") {
    return {
      height: "100%",
      width: "1px",
    };
  }
}
