import * as React from "react";
import ReactDOM from "react-dom";

const hoverCardRoot = document.body;

type Props = {
  children: React.ReactNode;
};

type State = {
  mounted: boolean;
};

/**
 * This is a custom portal layer for rendering hover cards outside of its parent.
 * This is useful to break the hover cards out of their original stacking
 * context, so we can do things like show tooltips within a div that has its own
 * scroll container.
 *
 */
export class HoverCardPortal extends React.Component<Props, State> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.el = document.createElement("div");
    this.el.dataset.portal = "hover-card";
    this.el.setAttribute("id", "hover-card-portal");

    // This is a special class that the `react-onclickoutside` package that
    // ignores this element from trigger an "outside" click. I _think_ this is
    // safe to apply to all hover card portals...
    this.el.classList.add("ignore-react-onclickoutside");

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    if (hoverCardRoot) {
      hoverCardRoot.appendChild(this.el);

      this.setState({ mounted: true });
    }
  }

  componentWillUnmount() {
    if (hoverCardRoot) {
      hoverCardRoot.removeChild(this.el);
    }
  }

  render() {
    // It's important that we don't actually render the component until we've
    // mounted into the DOM because we're attaching a `ref` to the
    // `children` and using it to measure the dimensions.  If we render the
    // children before we're in the DOM, we won't get the proper dimensions.
    const { mounted } = this.state;
    return ReactDOM.createPortal(mounted && this.props.children, this.el);
  }
}

export default HoverCardPortal;
