import React from "react";
import { getIn } from "timm";
import SelectUI from "hw/ui/select";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import type { Option } from "./types";

type Props = {
  selectedItem?: string | Option;
  options: Array<Option>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (item: any) => void;
  disabled?: boolean;
  id: string;
  invalid?: boolean;
  fitToContainer?: boolean;
};
type State = {
  isOpen: boolean;
};
export default class Select extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleStateChange = (changes: any) => {
    const { isOpen = this.state.isOpen } = changes;
    this.setState({
      isOpen,
    });
  };

  /**
   * This particular select component is potentially rendered a lot, so it is
   * heavily optimized.  If the dropdown is closed, it only needs to update if:
   *   - The disabled or invalid prop has changed
   *   - The selectedItem's display value has changed.
   *
   * In all other cases, there shouldn't be any need for this to re-render.
   * Note that this is not an ideal solution, but a temporary optimization
   * because our Dropdown/Select components are very hard to optimize in their
   * current form.
   */
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // If the dropdown is open, update normally
    if (this.state.isOpen || nextState.isOpen) {
      return true;
    }

    // Check the props that applicable when the dropdown is closed.
    // If any have changed, update normally
    const shallowChecks = ["disabled", "invalid"];

    for (let i = 0; i < shallowChecks.length; i++) {
      const prop = shallowChecks[i];

      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      if (this.props[prop] !== nextProps[prop]) {
        return true;
      }
    }

    // If the selected item is shallowly equal, nothing has changed that is
    // relevant to the closed dropdown
    if (nextProps.selectedItem === this.props.selectedItem) {
      return false;
    }

    // Finally, if the selected item is an object, compare the labels
    // If they are the same, don't update.
    const currentLabel = getIn(this.props, ["selectedItem", "label"]);
    const nextLabel = getIn(nextProps, ["selectedItem", "label"]);

    if (currentLabel === nextLabel) {
      return false;
    }

    return true;
  }

  isActive(id: string) {
    const { selectedItem } = this.props;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'string | Opt... Remove this comment to see the full error message
    if (selectedItem?.id) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'string | Opt... Remove this comment to see the full error message
      return id === selectedItem?.id;
    }

    return id === selectedItem;
  }

  render() {
    const {
      options,
      onChange,
      selectedItem,
      disabled,
      id,
      invalid,
      fitToContainer = true,
    } = this.props;
    const { isOpen } = this.state;
    return (
      /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
       * found when upgrading Flow to v0.132.0. To view the error, delete this
       * comment and run Flow. */
      <SelectUI
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ selectedItem: string | Option; onChange: (... Remove this comment to see the full error message
        selectedItem={selectedItem || ""}
        onChange={onChange}
        placeholder="..."
        fillContainer
        fitToContainer={fitToContainer}
        triggerProps={{
          small: true,
          compacted: true,
          id,
          invalid,
        }}
        disabled={disabled}
        onStateChange={this.handleStateChange}
        isOpen={isOpen}
        renderDest="portal"
        render={({ getItemProps }) => (
          <MenuList>
            {options.map((option) => (
              <MenuItem
                data-testid="select-conditional-logic"
                small={true}
                key={option.id}
                disabled={disabled}
                active={this.isActive(option.id)}
                passthroughProps={getItemProps({
                  item: option,
                })}
              >
                <MenuItemText>{option.label}</MenuItemText>
              </MenuItem>
            ))}
          </MenuList>
        )}
      />
    );
  }
}
