import type { PageMetadata, OutputField } from "hw/portal/modules/common/draft";
import { expandRect } from "hw/ui/document-mapper";
import type { FieldType } from "hw/ui/document-mapper";
import { invariant } from "hw/common/utils/assert";
import { withRawValue } from "../mapping";

export function getPageSize(pageMetadata: PageMetadata, pageNumber: number) {
  const pageIdx = pageNumber - 1;
  const size = pageMetadata.pageSizes[pageIdx];

  invariant(
    size,
    `Could not find page size information for page number ${pageNumber}`
  );

  return size;
}

export function toFieldRect(outputField: OutputField) {
  const { id, coords, dimensions } = outputField;
  return expandRect({
    id,
    x: coords.x,
    y: coords.y,
    page: coords.page,
    width: dimensions.width,
    height: dimensions.height,
  });
}

export function fromFieldRect(rect: FieldType, outputField: OutputField) {
  return withRawValue({
    ...outputField,
    coords: {
      x: rect.x,
      y: rect.y,
      page: rect.page,
      rawValue: `coords %{x: ${rect.x}, y: ${rect.y}, page: ${rect.page}}`,
    },
    dimensions: {
      width: rect.width,
      height: rect.height,
    },
  });
}
