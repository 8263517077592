import * as React from "react";

export default function Expand() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13.5V18.5M6 18.5H11M6 18.5L10.5 14M18.5 11V6M18.5 6H13.5M18.5 6L14 10.5"
        stroke="#959CA4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
