export const DragTypes = {
  Mapping: "Mapping",
  Field: "field",
};

export const JobStatus = {
  Running: "running",
  CompletedWithErrors: "completed with errors", // One true error case
  FailedToStart: "failed to start",
  Cancelled: "cancelled",
  Completed: "completed",
  /** This status doesn't exist in the backend
   * but we use it internally for errors when fetching the status
   */
  Error: "error",
  /** This status doesn't exist in the backend
   * but we use it internally when a job has been merged
   * to stop displaying the loading UI
   */
  Merged: "merged",
  /**
   * This status doesn't exist in the backend
   * but we use it internally for errors when sending a job
   * to get analyzed
   */
  ErrorStarting: "error starting job",

  /**
   * Frontend only state. It indicates that a job has been started
   */
  Starting: "starting",
};
