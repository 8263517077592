import * as React from "react";
import HoverCard from "hw/ui/hover-card";
import { Box } from "hw/ui/blocks";
import type { Justify, Position, RenderDest } from "hw/ui/hover-card";
import { invariant } from "hw/common/utils/assert";
import { useDebouncedFn } from "hw/common/hooks";
import logger from "hw/common/utils/logger";

export type Props = {
  /**
   * The position of the tooltip around the trigger. One of `top`, `right`, `left`,
   * or `bottom`. Defaults to `right`.
   */
  position?: Position;

  /**
   * The positioning of the tooltip on the x-axis. One of `center`, `left`, or
   * `right`. Defaults to `center`.  **NOTE**: This is only
   * applicable when the `position` of the tooltip is `top` or `bottom`
   */
  justify?: Justify;

  /**
   * (From `HoverCard`) The render destination for the tooltip. One of `portal` or `inline`.
   * Defaults to `portal`. A value of `portal` will render the tooltip within a
   * React portal, allowing it to breakout of a container that may be constrained
   * by CSS or layout.
   */
  renderDest?: RenderDest;

  /**
   * The content for the tooltip.
   *
   * NOTE: This content should be small and non-essential.
   */
  tip: React.ReactNode;

  /**
   * The children of the tooltip component will be the `trigger` element that
   * the tooltip anchors to.
   */
  children: React.ReactElement;

  /**
   * Pass this if you want the tooltip to work on a `disabled` trigger element
   */
  triggerDisabled?: boolean;

  /**
   * Hide the tooltip, but keep the elements. Used to conditionally disable a tooltip without rerendering its children.
   */
  hidden?: boolean;

  /**
   * If you _really_ need to, you can pass style to the wrapping `HoverCard`
   * element. This is potentially unsafe as it might mess with the placement of
   * the hovering element, but is sometimes required to avoid breaking styles
   * of the trigger element.
   */
  unsafe_hoverCardStyle?: Record<string, unknown>;
};

/**
 * A component for displaying helpful, **non-essential** information to the user.
 *
 * The tooltip positions itself around a **trigger** element, which is generally
 * a button or another interactive element. This component is a wrapper around
 * the `HoverCard` component and uses many of the same props.
 *
 * ## Focus-able elements
 *
 * If you're creating a tooltip around an element that's not focusable by default
 * (e.g. a `div`), you'll need to manually add a `tabIndex` value of `0` to your
 * trigger element.
 *
 * ## Tooltips on Disabled Elements
 *
 * Disabled elements are not interactive, so they will not fire mouse events which
 * will prevent the tooltip from displaying. If you need your tooltip to display
 * around a disabled element, pass the `triggerDisabled` property to the `Tooltip` component.
 *
 */
export function Tooltip(props: Props) {
  const {
    tip,
    children,
    triggerDisabled,
    position,
    justify,
    renderDest,
    hidden,
    // eslint-disable-next-line camelcase
    unsafe_hoverCardStyle,
  } = props;

  const { triggerProps, contentProps, active } = useTooltip(props);

  // Cloning the element avoids the need to wrap the element with another
  // element, which avoids some over-nesting of elements and also avoids
  // handling with the tab and focus order.
  const toClone = triggerDisabled ? (
    <Box
      tabIndex={0}
      extend={{
        "> *": { pointerEvents: "none" },
      }}
    >
      {children}
    </Box>
  ) : (
    children
  );
  const trigger = React.cloneElement(
    React.Children.only(toClone),
    triggerProps
  );

  return (
    <HoverCard
      anchor={trigger}
      position={position}
      justify={justify}
      type="tooltip"
      align="center"
      active={active && !hidden}
      renderDest={renderDest}
      // eslint-disable-next-line camelcase
      style={unsafe_hoverCardStyle}
    >
      <div {...contentProps}>{tip}</div>
    </HoverCard>
  );
}

Tooltip.defaultProps = {
  position: "right",
  justify: "center",
  renderDest: "portal",
};

type TooltipContext = {
  activeId: string | null;
  setActive: (id: string) => void;
  setInactive: (id: string) => void;
};

const Context = React.createContext<TooltipContext>({
  activeId: null,
  setActive: () => {
    //
  },
  setInactive: () => {
    //
  },
});

// Avoid the debounce altogether in tests so we don't have to await the
// debouncing.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const TOOLTIP_DELAY: number = process.env.NODE_ENV === "test" ? "none" : 150;

export function TooltipProvider(props: { children: React.ReactNode }) {
  const [activeId, setActiveId] = React.useState(null);
  const setActive = useDebouncedFn(
    React.useCallback((id) => setActiveId(id), []),
    TOOLTIP_DELAY
  );
  const setInactive = React.useCallback((_id) => setActive(null), [setActive]);
  const value = React.useMemo(
    () => ({
      activeId,
      setActive,
      setInactive,
    }),
    [activeId, setActive, setInactive]
  );

  return <Context.Provider value={value} {...props} />;
}

/**
 * Hook!  Not exporting this for now until we need it, but it's extracted out
 * in case it comes in handy later.
 */
function useTooltip(props: Props) {
  const { activeId, setActive, setInactive } = React.useContext(Context);

  if (typeof setActive !== "function") {
    throw Error("Tooltips must be wrapped with the tooltip provider");
  }

  const { children } = props;
  const trigger = children;
  const id = React.useRef(makeId());
  const active = activeId === id.current;
  const open = React.useCallback(() => setActive(id.current), [setActive]);
  const close = React.useCallback(() => setInactive(id.current), [setInactive]);
  const handleKeyDown = React.useCallback(
    (evt) => {
      if (evt.key === "Esc") {
        close();
      }
    },
    [close]
  );

  invariant(
    React.isValidElement(trigger),
    `Tooltip<${id.current}> : The provided 'trigger' prop is not a valid react element`
  );

  const triggerProps = {
    "aria-describedby": id.current,
    // @ts-expect-error refactor
    onFocus: wrapEvent(open, trigger.props.onFocus, id.current),
    // @ts-expect-error refactor
    onBlur: wrapEvent(close, trigger.props.onBlur, id.current),
    // @ts-expect-error refactor
    onMouseOver: wrapEvent(open, trigger.props.onMouseOver, id.current),
    // @ts-expect-error refactor
    onMouseLeave: wrapEvent(close, trigger.props.onMouseLeave, id.current),
    // @ts-expect-error refactor
    onKeyDown: wrapEvent(handleKeyDown, trigger.props.onKeyDown, id.current),
  };

  const contentProps = {
    role: "tooltip",
    id: id.current,
  };

  return { triggerProps, contentProps, active };
}

let count = 0;

function makeId() {
  return `tooltip:${count++}`;
}

/**
 * Calls an event handler on a cloned element and then calls our own handler
 * as long as it hasn't been `defaultPrevented`.
 */
function wrapEvent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ourHandler: (evt: any) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theirHandler: (evt: any) => void,
  id: string
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function wrappedEvent(evt: any) {
    if (theirHandler) theirHandler(evt);
    if (!evt.defaultPrevented) {
      ourHandler(evt);
    } else {
      logger.warnOnce(
        `Tooltip with id '${id}' not displaying because the focus event was prevent with 'event.preventDefault'`
      );
    }
  };
}
