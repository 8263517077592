import * as React from "react";
import MultipleChoice from "hw/common/components/smartforms/multiple-choice";
import type { MultipleChoiceField } from "hw/portal/modules/common/draft";
import type { Option } from "hw/common/components/smartforms/multiple-choice/types";

type Props = {
  field: MultipleChoiceField;
};
type State = {
  // $FlowIgnore
  value?: Option;
};
export class MultipleChoicePreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: undefined,
    };
  }

  // $FlowIgnore
  onChange = (option: Option) => {
    this.setState({
      value: option,
    });
  };

  render() {
    const { value } = this.state;
    const { field } = this.props;
    const {
      readOnly,
      allowMultiple,
      options,
      presentation,
      label,
      required,
      helpText,
    } = field;
    // The `required` field could be a macro in which case the form builder won't
    // know if the field is required or not because macros are not being
    // evaluated.  For V1, we'll coerce string values to `true` and treat them
    // as required.
    const isRequired = Boolean(required);
    return (
      <MultipleChoice
        allowMultiple={Boolean(allowMultiple)}
        disabled={readOnly}
        label={label}
        onChange={this.onChange}
        options={options}
        presentation={presentation}
        required={isRequired}
        value={value}
        helpText={helpText}
      />
    );
  }
}
export default MultipleChoicePreview;
