// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Plugin, PluginKey } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import type { Schema } from "prosemirror-model";

import * as commands from "./commands";
import { NamedKeymap } from "../../plugins";
import type { ActiveLink } from "./types";
import { markExtend } from "./utils";

export const key = new PluginKey("link");

export const kmap = NamedKeymap({
  name: "insertLink",
  key: "Mod-k",
  description: "Insert Link",
  cmd: commands.toggleLink,
});

export function createPlugin(_schema: Schema) {
  return [
    keymap({
      [kmap.key]: kmap.cmd,
    }),
    new Plugin({
      key,
      state: {
        init(_config, _state) {
          return {
            activeLink: null,
          };
        },
        apply(tr, prevPluginState, prevState, state) {
          const activeLink = getActiveLink(tr, state.schema.marks.link);

          return { activeLink };
        },
      },
    }),
  ];
}

/**
 * Returns an object representing the active link in the current selection if
 * there is one.
 *
 * NOTE: The `range` returned here is only stable for this current selection.
 * Make sure to compute the extended range again on the current `$selection`
 * if making doc edits.
 */
function getActiveLink(tr, link): ActiveLink | null | undefined {
  if (markActive(tr, link)) {
    const { $from } = tr.selection;
    const { node } = $from.parent.childAfter($from.parentOffset);
    if (node && link.isInSet(node.marks)) {
      const linkMark = node.marks.find((mark) => mark.type === link);
      if (linkMark) {
        const range = markExtend($from, link);
        return {
          href: linkMark.attrs.href,
          range,
        };
      }
    }
  }

  return null;
}

function markActive(state, type) {
  const { from, $from, to, empty } = state.selection;
  if (empty) return type.isInSet(state.storedMarks || $from.marks());
  else return state.doc.rangeHasMark(from, to, type);
}
