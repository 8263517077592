import * as React from "react";
import { WordBreakText } from "hw/ui/text";
import * as Styled from "./styled";
import Icon from "./icon";

/**
 * Public props - These are props that are available for consumers to use
 */
export type Props = {
  /**
   * Sets wether the radio is disabled or not
   */
  disabled?: boolean;

  /**
   * Sets wether the radio is checked or unchecked
   */
  checked?: boolean;

  /**
   * Text that is displayed next to the checkbox
   */
  label: string;

  /**
   * Function triggered when there is a change
   */
  onChange?: (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => void | null | undefined;

  /**
   * Sets the radio as invalid, changing the style
   */
  invalid?: boolean;

  /**
   * Name of the field of the radio
   */
  name: string;

  /**
   * Value of the radio
   */
  value: number | string | boolean;
};
type State = {
  hovered: boolean;
  focused: boolean;
  active: boolean;
};

/**
 *The `Radio` component is a wrapper around a basic radio button, keeping all the
 *functionality and accessibility but using a custom icon and style. It is a
 *controlled component, meant to be used with a light wrapper who handles the
 *logic when changing the value.
 *
 *Basic Example:
 *
 *```jsx
 *<Radio label="This is a label" name="radio" value="radio" />
 *```
 */
export class Radio extends React.Component<Props, State> {
  static defaultProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.state = {
      hovered: false,
      focused: false,
      active: false,
    };
  }

  handleBlur() {
    this.setState({
      focused: false,
    });
  }

  handleFocus() {
    this.setState({
      focused: true,
    });
  }

  handleMouseDown() {
    this.setState({
      active: true,
    });
  }

  handleMouseUp() {
    this.setState({
      active: false,
    });
  }

  handleMouseEnter() {
    this.setState({
      hovered: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      hovered: false,
      active: false,
    });
  }

  handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key && e.key === " ") {
      this.setState({
        active: true,
      });
    }
  }

  handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key && e.key === " ") {
      this.setState({
        active: false,
      });
    }
  }

  render() {
    const { disabled, checked, label, onChange, name, value, invalid } =
      this.props;
    const { active, focused, hovered } = this.state;
    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
      <Styled.Label
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        disabled={disabled}
      >
        <Styled.Input
          type="radio"
          checked={checked}
          name={name}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          value={value}
          onFocus={this.handleFocus}
          onChange={onChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          disabled={disabled}
        />
        <Styled.Wrapper>
          <Styled.Icon>
            <Icon
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ checked: boolean | undefined; active: bool... Remove this comment to see the full error message
              checked={checked}
              active={active}
              focused={focused}
              hovered={hovered}
              invalid={invalid}
              disabled={disabled}
            />
          </Styled.Icon>
          <WordBreakText>{label}</WordBreakText>
        </Styled.Wrapper>
      </Styled.Label>
    );
  }
}
export default Radio;
