/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import pick from "lodash/pick";
import Downshift from "downshift";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import HoverCard from "../hover-card";
import type { HoverCardProps } from "../hover-card";
// NOTE: Along with the below props, this component accepts all props
// that `Downshift` accepts.  See the repo for docs:
// https://github.com/paypal/downshift
export type Props = {
  "data-testid"?: string;

  /**
   * A function to render the item to a string.  This is use to display the
   * selected value, so if you've provide complex value for the item, you
   * can pick out a specific string to display when selected.
   */
  itemToString?: (item: any | null | undefined) => string;

  /**
   * The function called to render the contents of the dropdown.  The return
   * value here will be rendered inside of a `HoverCard` component.  It's required
   * to pass certain props along to the actual menu items using `Downshift`'s
   * prop collector functions.  See README for instructions.
   *
   * TODO: Better typing of downshift props
   */
  render: (downshiftProps: any) => React.ReactNode;

  /**
   * An option props object to be provided to the `Trigger` component
   * along with the default trigger props.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  triggerProps?: {};

  /**
   * A special React component for triggering the dropdown to active and close.
   * This component is provided a handful of useful props and also a special
   * `triggerPassthroughProps` props that _must_ be passed on to the underlying
   * `button` component and used in a particular way.
   */
  Trigger: React.ComponentType<any>;

  /**
   * The type of `HoverCard` to display
   */
  type?: "dropdown" | "select";

  /**
   * An option that sets wether the dropbox is disabled or not
   */
  disabled?: boolean;
  "data-guide-id"?: string;

  renderDest?: HoverCardProps["renderDest"];
  fillContainer?: HoverCardProps["fillContainer"];
  fitToContainer?: HoverCardProps["fitToContainer"];
};

/* eslint-disable react/display-name */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const renderDropdown = (props) => (downshiftProps) => {
  const {
    type,
    Trigger,
    itemToString,
    triggerProps: providedTriggerProps,
    render,
    disabled,
  } = props;
  const hoverCardProps = pick(props, [
    "position",
    "align",
    "justify",
    "fitToContainer",
    "onPositionChange",
    "fillContainer",
    "renderDest",
    "usePreferredLayout",
  ]);
  const {
    getToggleButtonProps,
    getInputProps,
    isOpen,
    selectedItem,
    inputValue,
    toggleMenu,
    openMenu,
    clearSelection,
  } = downshiftProps;

  /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  const triggerProps = {
    active: isOpen,
    selectedItem,
    itemToString,
    disabled,
    triggerPassthroughProps: {
      getButtonProps: getToggleButtonProps,
      getInputProps,
    },
    inputValue,
    toggleMenu,
    openMenu,
    clearSelection,
    ...providedTriggerProps,
  };
  const trigger = (
    <Trigger {...triggerProps} {...getTestAttributes(props["data-guide-id"])} />
  );
  return (
    <div data-testid={props["data-testid"]} role="presentation">
      <HoverCard
        {...hoverCardProps}
        active={isOpen}
        anchor={trigger}
        type={type}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        disabled={disabled}
      >
        {render(downshiftProps)}
      </HoverCard>
    </div>
  );
};

/**
 * Our `Dropdown` component in the most basic sense is a component that displays a
 * list of items when some other element (the "trigger") is clicked. The most basic
 * example would be a navigation menu where you have some text that displays and
 * when that text is clicked, a list of navigation options is displayed. The user
 * can click on one of those options and the page can react accordingly. Dropdowns
 * can also get more complicated with things like custom triggers (icons or
 * inputs), custom item rendering (with an icon), or filtering/asynchrounous
 * rendering (e.g. an autocomplete).
 *
 * In order to accomodate lots of different use cases, our `Dropdown` component is
 * a light wrapper around the [Downshift](https://github.com/paypal/downshift)
 * library, which uses the
 * ["render prop"](https://cdb.reacttraining.com/use-a-render-prop-50de598f11ce) to
 * actually render the dropdown contents.
 */
export default function Dropdown(props: Props) {
  return <Downshift {...props}>{renderDropdown(props)}</Downshift>;
}

Dropdown.defaultProps = {
  type: "dropdown",
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  itemToString: (item) => {
    if (item === null || item === undefined) {
      return "";
    }

    if (typeof item.label === "string") {
      return item.label;
    }

    return String(item);
  },
  render: () => null,
  triggerProps: {},
  "data-guide-id": "ui-dropdown",
};
