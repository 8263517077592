import * as React from "react";
import { Stack } from "hw/ui/layout";
import Label from "./input-label";
import Input from "./input";

type InputProps = JSX.LibraryManagedAttributes<
  typeof Input,
  React.ComponentProps<typeof Input>
>;

type Props = InputProps & {
  id: string;
  required?: boolean;
  label: React.ReactNode;
  appearance?: "compact" | "default";
};

/**
 * A pre-composed label + input component for text fields
 */
export function TextField(props: Props) {
  const { id, required, label, appearance, ...rest } = props;
  return (
    <Stack space="xs">
      <Label htmlFor={id} required={required} appearance={appearance}>
        {label}
      </Label>
      <Input type="text" id={id} required={required} {...rest} />
    </Stack>
  );
}
