import compose from "lodash/flowRight";
import { graphql } from "@apollo/client/react/hoc";
import { QueryName as LibraryItems } from "hw/portal/modules/workflow-library/constants";
import {
  QueryName as Workflows,
  WfQueryName,
} from "hw/portal/modules/launch-workflow/constants";
import ENABLE from "./enable.graphql";
import DISABLE from "./disable.graphql";

const EnableShareableLink = {
  // @ts-expect-error refactor
  props: ({ mutate }) => ({
    // @ts-expect-error refactor
    enableShareableLink: (input) => {
      return (
        mutate({
          variables: { input },
          refetchQueries: [LibraryItems, Workflows, WfQueryName],
        })
          // @ts-expect-error refactor
          .then((response) => ({ response, error: null }))
          // @ts-expect-error refactor
          .catch((error) => ({ error, response: null }))
      );
    },
  }),
};

const DisableShareableLink = {
  // @ts-expect-error refactor
  props: ({ mutate }) => ({
    // @ts-expect-error refactor
    disableShareableLink: (input) => {
      return (
        mutate({
          variables: { input },
          refetchQueries: [LibraryItems, Workflows, WfQueryName],
        })
          // @ts-expect-error refactor
          .then((response) => ({ response, error: null }))
          // @ts-expect-error refactor
          .catch((error) => ({ error, response: null }))
      );
    },
  }),
};

export default compose(
  // @ts-expect-error refactor
  graphql(ENABLE, EnableShareableLink),
  // @ts-expect-error refactor
  graphql(DISABLE, DisableShareableLink)
);
