import * as React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { Box } from "hw/ui/blocks";
import Loader from "hw/ui/loader";
import WorkflowNames from "./graphql/workflow-names.graphql";
import WorkflowToLaunch from "./graphql/workflow-to-launch.graphql";
import CreateWorkflowInstance from "./graphql/create-workflow-instance.graphql";
import type * as Types from "./types";

type WorkflowNamesResponse = {
  workflows: Array<Pick<Types.Workflow, "guid" | "name">>;
};

export function useLazyWorkflowNames() {
  return useLazyQuery<WorkflowNamesResponse>(WorkflowNames);
}

type WorkflowToLaunchResponse = {
  workflow: Types.Workflow;
};

export function useWorkflow(guid: string) {
  return useQuery<WorkflowToLaunchResponse>(WorkflowToLaunch, {
    variables: { guid },
  });
}

/**
 * In the workflow selector we may or may not have a `guid` to load. This
 * query will return the active workflow if we have a `guid`, otherwise we'll
 * skip it. In most cases, this query will either already exist in the cache
 * or will be batched with the `useWorkflow` query above.
 */
export function useActiveWorkflowIfExists(guid?: string) {
  const query = useQuery<WorkflowToLaunchResponse>(WorkflowToLaunch, {
    variables: { guid },
    skip: !guid,
  });

  return query.data?.workflow;
}

/**
 * The return value of this hook is meant to match the `CreateWorkflowInstance`
 * mutation `portal/modules/common/graphql/container.ts` file so it can be
 * swapped in as an alternative implementation. When we remove the feature flag,
 * we can adjust the API as needed
 */
export function useCreateWorkflowInstance() {
  const [mutate] = useMutation(CreateWorkflowInstance);

  async function launchWorkflow(input: Types.CreateWorkflowInstanceInput) {
    return mutate({
      variables: { input },
      refetchQueries: [
        { query: WorkflowToLaunch, variables: { guid: input.guid } },
      ],
    })
      .then((response) => ({ response, error: null }))
      .catch((err) => ({ response: null, error: err }));
  }

  return { launchWorkflow };
}

export function WorkflowReady(props: {
  guid: string;
  children: (wf: Types.Workflow) => JSX.Element;
}) {
  const { guid, children } = props;
  const query = useWorkflow(guid);

  if (query.error) {
    return <p>There was an error fetching the data.</p>;
  }

  if (query.loading) {
    return (
      <Box p="xl">
        <Loader />
      </Box>
    );
  }

  const workflow = query.data?.workflow;

  if (!workflow) {
    return (
      <Box as="p" color="textDim">
        <FormattedMessage
          id="LaunchWorkflow.empty"
          defaultMessage="Workflow not found"
        />
      </Box>
    );
  }

  return children(workflow);
}

export function AwaitWorkflow(props: { guid: string; children: JSX.Element }) {
  const { guid, children } = props;
  const query = useWorkflow(guid);

  return query.data?.workflow ? children : null;
}
