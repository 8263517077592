import * as React from "react";

export default function File() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13 14.5H3C2.72386 14.5 2.5 14.2761 2.5 14V2C2.5 1.72386 2.72386 1.5 3 1.5H13C13.2761 1.5 13.5 1.72386 13.5 2V14C13.5 14.2761 13.2761 14.5 13 14.5Z"
          stroke="#727A83"
        />
        <rect x="5" y="5" width="6" height="1" rx="0.5" fill="#727A83" />
        <rect x="5" y="7" width="6" height="1" rx="0.5" fill="#727A83" />
        <rect x="5" y="9" width="5" height="1" rx="0.5" fill="#727A83" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
