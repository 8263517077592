import { combineRules } from "hw/ui/style";
import Theme from "hw/ui/theme";
import type { Props } from "./checkbox";

type FelaProps = { theme: typeof Theme };

const label = ({ theme, disabled, labelFontSize }: Props & FelaProps) => ({
  display: "flex",
  alignItems: "center",
  lineHeight: "20px",
  color: theme.color.textStandard,
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  fontSize: theme.fontSize[labelFontSize || "md"],
  cursor: disabled ? "not-allowed" : "",
  position: "relative",
});

const wrapper = () => ({
  display: "inline-flex",
  alignItems: "flex-start",
});

const inputRule = () => ({
  position: "absolute",
  opacity: 0,
  zIndex: -1,
  margin: 0,
});

const inputState = (props: Props & FelaProps) => {
  const { disabled, checked, invalid, theme } = props;
  const iconProps = styleProps(theme, { checked, disabled, invalid });

  const states = ["focus", "active", "hover"].reduce((acc, state) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    acc[`:${state} ~ div`] = iconProps.props(state);

    return acc;
  }, {});

  return disabled ? {} : states;
};

const iconStyle = (props: Props & FelaProps) => {
  const { checked, disabled, invalid, theme } = props;
  const iconProps = styleProps(theme, { checked, disabled, invalid });
  return iconProps.props();
};

const iconRule = () => {
  return {
    marginRight: "8px",
    "& svg": {
      borderRadius: "2px",
    },
  };
};

export default {
  label,
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  input: combineRules(inputRule, inputState),
  wrapper,
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  icon: combineRules(iconRule, iconStyle),
};

/**
 * All the values for the styles are obtained from here.
 * It uses the state passed from JS (disabled, checked, invalid)
 * to get the information for the interactive States as focus, active and hover
 */

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
function styleProps(theme, states) {
  let reactState = "default";
  if (states.disabled)
    reactState = states.checked ? "disabledChecked" : "disabled";
  else if (states.invalid) reactState = "invalid";
  else if (states.checked) reactState = "checked";

  const color = theme.color.borderSelectedColor;

  const symbolProps = {
    hover: {
      fillOpacity: !states.checked ? 0 : 1,
    },
    disabledChecked: {
      fill: theme.color.gray500,
    },
    disabled: {
      fillOpacity: 0,
    },
  };

  const borderStrokeColor = {
    disabled: theme.color.gray100,
    disabledChecked: theme.color.gray100,
    active: color,
    invalid: theme.color.red500,
    focus: states.invalid ? "" : color,
    default: theme.color.gray500,
  };

  const borderFillColor = {
    disabled: theme.color.gray100,
    disabledChecked: theme.color.gray100,
    checked: color,
    active: color,
    hover: !states.checked ? theme.color.gray100 : "",
    default: theme.color.white,
  };

  const borderExtraProps = {
    focus: {
      strokeWidth: "2px",
      strokeOpacity: 0.7,
    },
    active: {
      strokeWidth: "2px",
      fillOpacity: 0.2,
      strokeOpacity: 0.7,
    },
    invalid: {
      strokeWidth: "2px",
    },
    default: {
      strokeWidth: "1px",
    },
  };

  const border = (state: string) => {
    const borderProps = {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      stroke: borderStrokeColor[state],
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      fill: borderFillColor[state],
    };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const extraProps = borderExtraProps[state];

    return JSON.parse(JSON.stringify(Object.assign(borderProps, extraProps)));
  };

  const symbol = (state: string) => {
    // @ts-expect-error ts-migrate(2550) FIXME: Property 'assign' does not exist on type 'ObjectCo... Remove this comment to see the full error message
    return JSON.parse(JSON.stringify(Object.assign({}, symbolProps[state])));
  };

  const props = (state = reactState) => {
    return {
      "& rect": {
        ...border(state),
      },
      "& path": {
        ...symbol(state),
      },
    };
  };

  return { props };
}
