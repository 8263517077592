import React from "react";

export default function Duplicate() {
  return (
    <svg width={24} height={24} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 16V5h8v11H7zM5 4a1 1 0 011-1h10a1 1 0 011 1v13a1 1 0 01-1 1H6a1 1 0 01-1-1V4zm4 16v-1h9V7h1a1 1 0 011 1v12a1 1 0 01-1 1h-9a1 1 0 01-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}
