/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addLast,
  getIn,
  setIn,
  updateIn,
  insert,
  removeAt,
  omit,
  merge,
} from "timm";
import mapValues from "lodash/mapValues";
import flow from "lodash/flow";
import groupBy from "lodash/groupBy";
import union from "lodash/union";
import difference from "lodash/difference";
import uniqBy from "lodash/uniqBy";
import isPlainObject from "lodash/isPlainObject";
import flatMap from "lodash/flatMap";
import { createSelector } from "reselect";
import { Factories } from "hw/portal/modules/common/draft";
import type {
  DataRef,
  MergeField,
  Form,
  ParsedSchema,
  Role,
  Field,
  FormMapping,
  MappedField,
  PdfMapping,
  Expression,
} from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import { createLogger } from "hw/common/utils/logger";
import { invariant, warning } from "hw/common/utils/assert";
import parseExpression from "./form-builder/expression-builder/parser";
import type { MergeFieldsByDataRef } from "./types";
import { ROUTE } from "./constants";

const logger = createLogger("draft-editor:utils");
type PathTuple = [Path, number];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fromDataRef' implicitly has an 'any' ty... Remove this comment to see the full error message
const sourcedFromStartStep = (fromDataRef) => {
  return (
    directMapping(fromDataRef) && // Mapping strings without dots are "global" data refs which are used for
    // populating merge fields
    !fromDataRef.args[0].includes(".")
  );
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fromDataRef' implicitly has an 'any' ty... Remove this comment to see the full error message
const sourcedFromOtherForm = (fromDataRef) => {
  return directMapping(fromDataRef) && fromDataRef.args[0].includes(".");
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fromDataRef' implicitly has an 'any' ty... Remove this comment to see the full error message
const directMapping = (fromDataRef) => {
  return fromDataRef.methodName === "val" && fromDataRef.args.length === 1;
};

/**
 * TODO: This is a copy of a function in smartforms. I imagine this function as
 * part of a DraftValidator in the common folder
 * Check whether a value is an Expression.
 * @param v value to check
 */
export const isExpression = (v: any) => {
  return (
    isPlainObject(v) &&
    Array.isArray(v.args) &&
    (v.methodName !== undefined || v.macroName !== undefined)
  );
};

/**
 * Returns a valid route for the draft editor given a draftId
 */
export const route = (draftId: string): string =>
  ROUTE.replace(":workflowId", draftId);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
const insertAtPath = (path, index, value) => (data) =>
  updateIn(data, path, (arr) => insert(arr, index, value));

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
const removeAtPath = (path, index) => (data) =>
  updateIn(data, path, (arr) => removeAt(arr, index));

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
const reorderAtPath = (path, startIndex, endIndex) => (data) =>
  updateIn(data, path, (arr) =>
    insert(removeAt(arr, startIndex), endIndex, arr[startIndex])
  );

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
const appendField = (field, form) =>
  updateIn(form, ["fields"], (fields) => addLast(fields, field));

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
const mergeAttrsAtPath = (path, index, attrs) => (data) =>
  updateIn(data, [...path, index], (oldAttrs) => merge(oldAttrs, attrs));

/**
 * This splits the field path into a tuple of parent path to index.  Since a
 * field path will always be some sort of path that ends with an index, we can
 * split it to find the parent group that the field is in.
 *
 * @param path - The full path to the field
 * @return tuple of parent path and index
 */
export function splitPath(path: Path): PathTuple {
  const parentPath = path.slice(0, -1);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
  const index = parseInt(path[path.length - 1], 10);

  // This is a little over defensive but it makes flow happy
  if (Number.isNaN(index)) {
    throw new Error(
      "A field path should always end in an integer representing the index of the field in its local group"
    );
  }

  return [parentPath, index];
}

/**
 * Moves a field within a schema from one path to another path.
 *
 * Some very important assumptions here:
 *   - The `targetPath` is the path to be moved to within the _existing_ schema
 *     structure.  This is important to note because if you're moving from a
 *     parent group to a child group and you remove something from the parent
 *     group first, then the original path might not point to the correct item
 *     because the indexes of the parent have changed.
 *   - All updates made to the schema are immutable, but anthing that hasn't
 *     changed will still point to the same reference.  Meaning, if you reorder
 *     two fields, the schema will have have changed but the two fields will
 *     still be referentially the same.  This is an intentional strategy
 *     for performance reasons.
 *
 * @param schema - A form schema
 * @param sourcePath - The path of the field to move
 * @param targetPath - The path that the source needs to move to based on the
 * existing schema structure before any updates have been made.
 * @param attrs - Optional extra attrs to merge into the field to move
 * @return A new form schema
 */
export function movePath(
  schema: ParsedSchema,
  sourcePath: Path,
  targetPath: Path,
  attrs?: {}
): ParsedSchema {
  const source = getIn(schema, sourcePath);
  const [sourceItemsPath, sourceIndex] = splitPath(sourcePath);
  const [targetItemsPath, targetIndex] = splitPath(targetPath);
  const enhancedTargetIndex = getEnhancedTargetIndex(
    schema,
    targetItemsPath,
    targetIndex
  );

  // If there's no source or target, just return the schema as is
  if (!source) {
    return schema;
  }

  // These are the various operations that might need to be performed.
  // Don't allow recursive movements, i.e. Moving a parent node to one of its
  // own child nodes
  if (isRecursiveMove(sourcePath, targetPath)) {
    return schema;
  }

  // Depending on the direction the field is moving, the order that these
  // happen might diff.  The functions are curried and are awaiting the
  // schema data.
  const insertTarget = insertAtPath(
    targetItemsPath,
    enhancedTargetIndex,
    source
  );
  const removeSource = removeAtPath(sourceItemsPath, sourceIndex);
  const mergeAttrs = mergeAttrsAtPath(
    targetItemsPath,
    enhancedTargetIndex,
    attrs
  );
  let ops = [];

  if (isSameBasePath(sourcePath, targetPath)) {
    // If paths are the same removing the last element, they are within
    // the same group and this is just a reordering around a specific index
    // Flow wants this typed...
    // @ts-expect-error refactor
    const fn: (data: ParsedSchema) => ParsedSchema = reorderAtPath(
      sourceItemsPath,
      sourceIndex,
      targetIndex
    );
    ops = [fn];
  } else if (targetPath.length > sourcePath.length) {
    // Moving from parent to child
    // We need to insert the target into the child first so that the
    // parent structure doesn't change when we go to remove the source
    ops = [insertTarget, mergeAttrs, removeSource];
  } else if (sourcePath.length > targetPath.length) {
    // Moving from child to parent
    // We need to remove the source from the child first so that the
    // parent structure doesn't change when we go to insert the target
    ops = [removeSource, insertTarget, mergeAttrs];
  } else {
    // Moving between child components of different groups
    ops = [insertTarget, mergeAttrs, removeSource];
  }

  return flow(...ops)(schema);
}

/**
 * This function returns a "hint" for where the new path will be relative to the
 * given target path. This is mainly used for showing the drag and drop hint.
 * It lives here because this hint has a relationship to how fields are re-ordered,
 * so the hint should be a true indication of where the new field will be once
 * reordering occurs.
 */
export function movePathHint(
  sourcePath: Path,
  targetPath: Path
): "before" | "after" | void {
  if (isSameBasePath(sourcePath, targetPath)) {
    // Same group
    // Direction changes depending on if coming from above or below
    const [, sourceIndex] = splitPath(sourcePath);
    const [, targetIndex] = splitPath(targetPath);
    const topToBottom = sourceIndex < targetIndex;
    const bottomToTop = sourceIndex > targetIndex;
    if (topToBottom) return "after";
    if (bottomToTop) return "before";
  } else {
    return "after";
  }
}

function getEnhancedTargetIndex(
  schema: ParsedSchema,
  targetItemsPath: Path,
  targetIndex: number
) {
  // If the target has siblings, we need to add 1 to the target index.
  // If not, we need to move it to the position 0.
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const targetHasSiblings = getIn(schema, targetItemsPath).length > 0;
  return targetHasSiblings ? targetIndex + 1 : 0;
}

/**
 * When moving fields around, the final target path will depend on if the target
 * has children or not. We take that into consideration to give a better index
 *
 * @param schema: A form schema
 * @param targetPath: The full path of the
 * @return Tuple of parent path and enhanced index
 */
export function enhancedTargetPath(
  schema: ParsedSchema,
  sourcePath: Path,
  targetPath: Path
): Path {
  // We don't make any difference if they have the same base path
  if (isSameBasePath(sourcePath, targetPath)) return targetPath;
  const [targetItemsPath, targetIndex] = splitPath(targetPath);
  return [
    ...targetItemsPath,
    getEnhancedTargetIndex(schema, targetItemsPath, targetIndex),
  ];
}

function isRecursiveMove(sourcePath: Path, targetPath: Path): boolean {
  if (sourcePath.length === targetPath.length) {
    return false;
  }

  const sharedParent = targetPath.slice(0, sourcePath.length);
  return isSamePath(sourcePath, sharedParent);
}

export function isSameBasePath(sourcePath: Path, targetPath: Path): boolean {
  if (sourcePath.length !== targetPath.length) {
    return false;
  }

  const baseSourcePath = sourcePath.slice(0, -1);
  const targetSourcePath = targetPath.slice(0, -1);
  // The difference is not enough, we need to check that they have exactly the
  // same order. We perform a shallow one level comparison.
  // TODO: We should be able to do a regular comparison, but apparently we are modifying
  // the path making them different. We should fix that
  return isSamePath(baseSourcePath, targetSourcePath);
}

function isSamePath(sourcePath: Path, targetPath: Path): boolean {
  return sourcePath.every((path, index) => path === targetPath[index]);
}

/**
 * Ensures that a merge field mapping existing for the given merge field and
 * data ref.  If a merge field mapping already exists for that dataref, it will
 * be replaced.  If one does not exist, one will be added.
 */
export function ensureMergeFieldMapping(
  mappings: {},
  mergeField: MergeField,
  targetDataRef: DataRef
) {
  const sourceDataRef = mergeField.dataRef;
  const newMapping = Factories.Mapping(sourceDataRef, targetDataRef);

  /* $FlowFixMe[cannot-spread-indexer] $FlowFixMe This comment suppresses an
   * error found when upgrading Flow to v0.132.0. To view the error, delete
   * this comment and run Flow. */
  return { ...mappings, ...newMapping };
}

/**
 * Removes a merge field mapping for the given data ref
 * It removes references from toDataRef and fromDataRef
 */
export function removeMergeFieldMapping(mappings: {}, stepDataRef: DataRef) {
  const existingDataRefs = Object.keys(mappings).filter((toDataRef) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const fromDataRefExp = mappings[toDataRef];
    const fromDataRef = fromDataRefExp.args[0];
    return (
      (sourcedFromStartStep(fromDataRefExp) ||
        sourcedFromOtherForm(fromDataRefExp)) &&
      (toDataRef === stepDataRef || fromDataRef === stepDataRef)
    );
  });
  return existingDataRefs ? omit(mappings, existingDataRefs) : mappings;
}

/**
 * Takes the merge fields and mappings and returns a nested map of data refs
 * to merge field.  We use this to look up a merge field that's attached to
 * a particular field so we can display the label.
 * TODO: we are not using this function anymore, but I'll keep it around in case
 * we need it soon.
 *
 * @example
 * const mergeFields = [{
 *   dataRef: 'firstName',
 *   label: 'First Name'
 * }]
 * const mappings = [{
 *   fromDataRef: 'start_step.firstName',
 *   toDataRef: 'signer_step.w9.firstName'
 * }];
 *
 * mergeFieldByDataRef(mergeFields, mappings)
 * //=> {
 *   w9: {
 *     firstName: {
 *       dataRef: 'firstName',
 *       label: 'First Name'
 *     }
 *   }
 * }
 */
export function mergeFieldsByDataRef(
  mergeFields: Array<MergeField> = [],
  mappings: {} = {}
): MergeFieldsByDataRef {
  return Object.keys(mappings).reduce((result, toDataRef) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const fromDataRef = mappings[toDataRef];

    // First check if the mappign is from the start step
    if (sourcedFromStartStep(fromDataRef)) {
      const nestedDataRef = fromDataRef.args[0];
      // Grab the data ref from the mapping source
      const mappingSourceDataRef = extractNestedDataRef(nestedDataRef);
      // Find the merge field that matches that source data ref
      const mergeField = mergeFields.find(
        (mf) => mf.dataRef === mappingSourceDataRef
      );

      // If there is a merge field for that mapping source, recursively
      // split that target data ref path into a nested object
      if (mergeField) {
        return assocNested(
          splitNestedDataRef(toDataRef),
          mergeField,
          [],
          result
        );
      } else {
        return result;
      }
    } else {
      return result;
    }
  }, {});
}

// @ts-expect-error ts-migrate(7023) FIXME: 'assocNested' implicitly has return type 'any' bec... Remove this comment to see the full error message
function assocNested(
  dataRefs: Array<any>,
  mergeField: MergeField,
  currentPath: Array<any>,
  result: {}
) {
  const [dataRef, ...rest] = dataRefs;
  const path = [...currentPath, dataRef];

  if (rest.length === 0) {
    return setIn(result, path, mergeField);
  }

  return assocNested(rest, mergeField, path, result);
}

export function createNestedDataRef(...dataRefs: Array<DataRef>): DataRef {
  return dataRefs.join(".");
}
export function splitNestedDataRef(nestedDataRef: DataRef): Array<string> {
  return nestedDataRef.split(".");
}
export function extractNestedDataRef(nestedDataRef: DataRef): DataRef {
  const split = splitNestedDataRef(nestedDataRef);
  // @ts-expect-error refactor
  return split[split.length - 1];
}
export function ensureFieldInForm(field: Field, schema: Form) {
  return fieldWithDataRefExists(schema.fields, field.dataRef)
    ? schema
    : appendField(field, schema);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
function fieldWithDataRefExists(fields, dataRef) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const existingFieldWithDataRef = fields.find((field) => {
    if (field.dataRef === dataRef) {
      return true;
    }

    if (Array.isArray(field.children)) {
      return fieldWithDataRefExists(field.children, dataRef);
    }

    return false;
  });
  return Boolean(existingFieldWithDataRef);
}

/**
 * Returns a map of role id -> { roleBadge, roleTitle }
 *
 * It's useful for the UI to have a quick way to access both the role badge
 * and the full role title in a map format.  The end
 *
 * The badge text for each role is computed based on the entire set of roles, so
 * that there are no duplicate badges.  Priority of badge text to use is:
 * - First letter
 * - First letter + first letter of second word
 * - First letter + second letter of first word
 * - First letter + number
 *
 * TODO: This is probably inefficient and not ideal, so let's improve this later...
 * Using `reselect` instead of lodash to memoize this because we only really
 * need to memoize the last call and not all calls.
 */
type RoleDescById = Record<
  string,
  {
    badge: string;
    title: string;
  }
>;
type AbbrGroup = Record<string, Array<Role>>;
export const roleDescriptionById = createSelector<any, any, any, any>(
  (roles) => roles,
  function _roleDescriptionById(roles: Array<Role>): RoleDescById {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
    const badges = runStrategies(roles);
    return byRoleId(badges);
  }
);

/**
 * Takes the nested map of badgeId -> badgeSet and prepares is as a map of
 * roleID -> { roleBadge, roleTitle }
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'byRoleId' implicitly has return type 'any' becaus... Remove this comment to see the full error message
function byRoleId(result, map = {}) {
  return Object.keys(result).reduce((res, abbr) => {
    const set = result[abbr];

    if (Array.isArray(set)) {
      const role = set[0];
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      map[role.id] = {
        badge: abbr,
        title: role.title,
      };
    } else {
      return byRoleId(set, map);
    }

    return res;
  }, map);
}

const strategies = [badgeByLetter, badgeByWord];

/**
 * Returns a map of badgeText -> role by recursively evaluating the different
 * strategies.  A strategy produces a grouping of the roles by an abbreviation
 * strategy.  Each key in the group is an abbreviation and the value of each key
 * is the number of items in the set of roles that can take that abbrievation.
 * If there's only one item in the set of abbreviations, then
 * it's unique and we can use that abbreivation.  Otherwise, we try the
 * next strategy. As a fallback, we use the last known abbreviation to
 * create an abbreviation based on the count.
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'runStrategies' implicitly has return type 'any' b... Remove this comment to see the full error message
function runStrategies(roles, attempt = 0, lastAttemptedAbbr) {
  const strategy = strategies[attempt];

  if (strategy) {
    const group = strategy(roles);
    // Map each set of roles in an abbreviation and see if it's unique
    return mapValues(group, (set, abbr) => {
      if (set.length === 1) {
        // Only one role with this abbreviation, so it's ok to use
        return set;
      } else {
        // More than one role has this same abbreviation, so we need to try
        // another strategy
        return runStrategies(set, attempt + 1, abbr);
      }
    });
  } else {
    // No strategies left to try, so we create badges based on the count for
    // the remaining roles without badges
    return badgeByCount(roles, lastAttemptedAbbr);
  }
}

/**
 * Groups the roles by the first letter of each role title
 */
function badgeByLetter(roles: Array<Role>): AbbrGroup {
  return groupBy(roles, (role) => role.title.charAt(0).toUpperCase());
}

/**
 * Groups the roles by either the first letter of each word in the role title
 * or by first two letters of the first word
 */
function badgeByWord(roles: Array<Role>): AbbrGroup {
  return groupBy(roles, (role) => {
    const words = role.title.split(" ");
    const hasMultipleWords = words.length > 1;
    // @ts-expect-error refactor
    const firstLetter = words[0].charAt(0).toUpperCase();

    if (hasMultipleWords) {
      // @ts-expect-error refactor
      return firstLetter + words[1].charAt(0).toUpperCase();
    } else {
      const firstWord = words[0];

      // @ts-expect-error refactor
      if (firstWord.length >= 2) {
        // @ts-expect-error refactor
        return firstLetter + words[0].charAt(1).toLowerCase();
      } else {
        return firstLetter;
      }
    }
  });
}

/**
 * Groups the roles by the first letter and index of the role
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'roles' implicitly has an 'any' type.
function badgeByCount(roles, seed = ""): AbbrGroup {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'map' implicitly has an 'any' type.
  const res = roles.reduce((map, role, index) => {
    const badge = `${seed}${index + 1}`;

    if (!map[badge]) {
      map[badge] = [];
    }

    map[badge].push(role);
    return map;
  }, {});
  return res;
}

export function rolesByExistence(roles: Array<Role>, forms: Array<Form>) {
  // @ts-expect-error refactor
  const fields = forms.reduce((acc, form) => union(acc, form.fields), []);
  return roles.reduce((acc, role) => {
    // @ts-expect-error refactor
    if (!acc[role.id]) {
      // @ts-expect-error refactor
      acc[role.id] = fieldsIncludeRoleAssignment(fields, role);
    }

    return acc;
  }, {});
}
// @ts-expect-error ts-migrate(7023) FIXME: 'fieldsIncludeRoleAssignment' implicitly has retur... Remove this comment to see the full error message
export function fieldsIncludeRoleAssignment(
  fields: Array<Field> = [],
  role: Role
) {
  return fields.some((field) => {
    const assignedFieldRoles = field.roles || [];

    if (assignedFieldRoles.includes(role.id)) {
      return true;
    }

    if (field.children) {
      return fieldsIncludeRoleAssignment(field.children, role);
    }

    return false;
  });
}
export function fieldCanBeReferenced(field: Field): boolean {
  if (field) {
    const { type } = field;
    if (
      type === "Paragraph" ||
      // @ts-expect-error refactor
      type === "Hidden" ||
      type === "Group" ||
      type === "AddressGroup"
    )
      return false;
    return true;
  }

  return false;
}

function canUseChildrenReferences(field: Field): boolean {
  return field.type === "AddressGroup" || field.type === "Group";
}

/**
 * This function looks for references of a certain dataRef in the visible property
 * of group component fields.
 * If it finds it, it returns an Array with a map of the id and label.
 * If it is referenced more than once, it only returns one of it.
 */
export function getReferringFields(
  fields: Array<Field> = [],
  field?: Field | null | undefined
): Array<{
  id: string;
  label?: string;
}> {
  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'referencedFields' implicitly has type 'a... Remove this comment to see the full error message
  const referencedFields = [];

  if (
    field &&
    (fieldCanBeReferenced(field) || canUseChildrenReferences(field))
  ) {
    const dataRefs = getAllDataRefs(field);
    const groupFields = fields.filter((field) => field.type === "Group");

    if (groupFields.length > 0) {
      const dataRefExpressions = dataRefs.map((dataRef) =>
        Factories.Expression("val", [dataRef])
      );
      // We go through the group fields and check if the visible property includes
      // the dataRef expression
      groupFields.forEach((field) => {
        if (field.visible) {
          try {
            const visibleExpression = JSON.stringify(field.visible);
            const exprExists = dataRefExpressions.some((expression) =>
              visibleExpression.includes(JSON.stringify(expression))
            );

            if (exprExists) {
              referencedFields.push({
                id: field.id,
                label: field.label,
              });
            }
          } catch (_e) {
            // Do nothing
          }
        }
      });
    }
  }

  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'referencedFields' implicitly has an 'any... Remove this comment to see the full error message
  return referencedFields;
}
export function getReferringFieldLabels(
  fields: Array<Field>,
  field?: Field | null | undefined
): Array<string> {
  const referringFields = getReferringFields(fields, field);
  return fieldLabels(referringFields, "Condition");
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
function fieldLabels(fields, defaultLabel): Array<string> {
  const uniqueFields = uniqBy(fields, "id");
  const labels = uniqueFields.reduce((acc, field) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type '{ toStrin... Remove this comment to see the full error message
    acc.add(getUniqueLabel(field.label || defaultLabel, acc));
    return acc;
  }, new Set());
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown[]' is not assignable to type 'string... Remove this comment to see the full error message
  return Array.from(labels);
}

export function getReferringFormLabels(
  field: Field,
  formId: string,
  forms: Array<Form>,
  mappings: {} = {}
): Array<string> {
  if (field) {
    const referringMappings = getReferringMappings(mappings, field, formId);

    if (referringMappings.length > 0) {
      const mappedFormsById = referringMappings.reduce(
        (formsById, nestedDataRef) => {
          if (!nestedDataRef.includes(".")) {
            // This is a merge field reference
          } else {
            // we look for the label in the forms
            const [mappedFormId, dataRef] = splitNestedDataRef(nestedDataRef);
            const mappedForm = forms.find((form) => form.id === mappedFormId);

            if (mappedForm) {
              const mappedField = (mappedForm.fields || []).find((field) => {
                const dataRefs = getAllDataRefs(field);
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                return dataRefs.includes(dataRef);
              });

              if (mappedField) {
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                formsById[mappedForm.id] = mappedForm;
              } else {
                logger.warn("Could not find a field referenced by a mapping");
              }
            } else {
              logger.warn("Could not find a form referenced by a mapping");
            }

            return formsById;
          }

          return nestedDataRef;
        },
        {}
      );
      const formNames = Object.values(mappedFormsById).reduce(
        (uniqueLabels, form) => {
          const uniqueLabel = getUniqueLabel(
            // $FlowFixMe
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            form.name || "Untitled Form",
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            uniqueLabels
          );
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          uniqueLabels.add(uniqueLabel);
          return uniqueLabels;
        },
        new Set()
      );
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      return Array.from(formNames);
    }
  }

  return [];
}
export function getReferenceConditionalCopy(
  fields: Array<Field>,
  field: Field
): string | null | undefined {
  const labels = getReferringFieldLabels(fields, field);

  if (labels.length > 0) {
    let baseCopy = "This component is referenced in the conditional rule";

    if (labels.length > 1) {
      baseCopy += "s ";
    } else {
      baseCopy += " ";
    }

    const fieldLabels = sentenceJoin(labels);
    return baseCopy + fieldLabels;
  }
}
export function getReferencePrefillCopy(
  field: Field,
  formId: string,
  forms: Array<Form>,
  mappings: {} = {}
): string | null | undefined {
  const formLabels = getReferringFormLabels(field, formId, forms, mappings);

  if (formLabels.length > 0) {
    const formLabelsCopy = sentenceJoin(formLabels);
    return "This component is pre-filling data in " + formLabelsCopy;
  }
}

function getReferringMappings(mappings: {} = {}, field: Field, formId: string) {
  const dataRefs = getAllDataRefs(field);
  const nestedDataRefs = dataRefs.map((dataRef) =>
    createNestedDataRef(formId, dataRef)
  );
  return Object.keys(mappings).filter((toDataRef) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const fromDataRefExp = mappings[toDataRef];
    return nestedDataRefs.includes(fromDataRefExp.args[0]);
  });
}

// This function returns if the base roles are contained in the field roles
export function shareSameRole(
  baseRoles?: Array<string>,
  fieldRoles?: Array<string>
) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[] | undefined' is not ass... Remove this comment to see the full error message
  return difference(baseRoles, fieldRoles).length === 0;
}

function fieldCanMapChildren(field: Field) {
  // We don't map children of AddressGroup
  return field.children && field.type !== "AddressGroup";
}

/**
 * It returns a list of fields that have the same field type and share roles with
 * the field provided
 */
function filterFieldsByTypeAndRole(
  prevFields: Array<Field> = [],
  field: Field,
  filteredFields: Array<Field> = []
): Array<Field> {
  prevFields.forEach((prevField) => {
    if (
      prevField.dataRef &&
      prevField.type === field.type &&
      shareSameRole(prevField.roles, field.roles)
    ) {
      filteredFields.push(prevField);
    } else if (fieldCanMapChildren(prevField)) {
      filterFieldsByTypeAndRole(prevField.children, field, filteredFields);
    }
  });
  return filteredFields;
}

/**
 * Returns a list of all the possible fields that could be mapped to a certain field
 */
function getPossibleMappedFields(form: Form, field: Field): Array<MappedField> {
  const filteredFields = filterFieldsByTypeAndRole(form.fields, field);
  const uniqueLabels = new Set();
  // @ts-expect-error refactor
  return filteredFields.map((prevField) => {
    const uniqueLabel = getUniqueLabel(
      prevField.label,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Set<unknown>' is not assignable ... Remove this comment to see the full error message
      uniqueLabels,
      prevField.type
    );
    uniqueLabels.add(uniqueLabel);
    return {
      label: uniqueLabel,
      // @ts-expect-error refactor
      dataRef: createNestedDataRef(form.id, prevField.dataRef),
      children:
        prevField.children &&
        prevField.children.map((child) => ({
          // @ts-expect-error refactor
          dataRef: createNestedDataRef(form.id, child.dataRef),
        })),
    };
  });
}

/**
 * Returns the prefill (it it has one) of a certain field.
 */
export function getPrefill(
  mergeFields: Array<MergeField> = [],
  formMappings: Array<FormMapping> | null | undefined,
  mapping: {} = {},
  formId: string,
  field: Field
) {
  const { dataRef } = field;

  // We can't get a prefill of a field without a dataRef, unless the children have
  // a dataRef
  if (dataRef) {
    // We look for the key formId.dataRef in the mapping
    const mappingExpression = getIn(mapping, [`${formId}.${dataRef}`]);

    if (mappingExpression && isExpression(mappingExpression)) {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const nestedDataRef = mappingExpression.args[0];

      if (sourcedFromStartStep(mappingExpression)) {
        // Check that is part of the mergeFields
        const value = mergeFields.find((mf) => mf.dataRef === nestedDataRef);
        warning(
          Boolean(value),
          `The merge field selected '${nestedDataRef}' is not part
          of the list of mergeFields`
        );

        if (value) {
          return {
            value,
            message: getMergeFieldMessage(value.label),
          };
        }
      } else if (sourcedFromOtherForm(mappingExpression)) {
        // It means it is a form to form mapping. For now, we will only accept direct
        // references
        formMappings = formMappings || [];

        for (let i = 0; i < formMappings.length; i++) {
          const formMapping = formMappings[i];
          // @ts-expect-error refactor
          const value = formMapping.options.find(
            (opt) => opt.dataRef === nestedDataRef
          );
          if (value)
            return {
              value,
              // @ts-expect-error refactor
              message: getPrefillMessage(value.label, formMapping.label),
            };
        } // TODO: check if the backend is doing this validation or not (when a form
        // mapping don't exist, add a warning in the frontend)
      } else {
        // This is a custom mapping, we will not show a UI for it for now.
        logger.warn("Skipping prefill message for custom mapping");
      }
    }
  }
}

function getPrefillMessage(dataRefLabel: string, formLabel: string): string {
  return `Pre-filled from ${dataRefLabel} in ${formLabel}`;
}

function getMergeFieldMessage(dataRefLabel: string) {
  return `{${dataRefLabel}}`;
}

/**
 * Returns a structured list of form mappings for a certain field.
 * This would contain all the forms and the possible fields that could you map
 * to the field.
 * For previous forms it returns the list of fields, but for the same form or
 * next ones, it passes the flag disabled and an empty list
 *
 */
export function getFormMappings(
  field: Field,
  formId: string,
  forms: Array<Form>
): Array<FormMapping> {
  if (field) {
    // We only look at the forms that are before the current form
    const currentFormIndex = forms.findIndex((form) => form.id === formId);

    if (currentFormIndex !== -1) {
      return forms.map((form, idx) => {
        if (idx < currentFormIndex) {
          const options = getPossibleMappedFields(form, field);
          return {
            label: form.name,
            id: form.id,
            options,
          };
        } else {
          return {
            label: form.name,
            id: form.id,
            options: [],
            disabled: true,
          };
        }
      });
    } else {
      logger.error("Could not a form from is ID in the schema");
    }
  }

  // If we couldn't find any formMapping, we return an empty array
  return [];
}
// Returns if there is a mapping associated to a dataRef in a specific form
export function fieldHasMapping(
  dataRef: DataRef,
  formId: string,
  mapping: {} = {}
) {
  return Boolean(getIn(mapping, [`${formId}.${dataRef}`]));
}

/**
 * This is a SUPER basic implementation of name for a label. We'll keep it
 * like this for now, but it will probably change in the future
 * It generates a unique label using the type as fallback in case there is no label
 * We use this function for both form to form mapping and conditional logic
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'getUniqueLabel' implicitly has return type 'any' ... Remove this comment to see the full error message
export function getUniqueLabel(
  label?: string,
  // @ts-expect-error ts-migrate(1016) FIXME: A required parameter cannot follow an optional par... Remove this comment to see the full error message
  labels: Set<string>,
  type = "Field",
  attempt = 0
) {
  if (!label) return getUniqueLabel(type, labels);
  const uniqueLabel = attempt ? `${label} ${attempt}` : label;

  if (labels.has(uniqueLabel)) {
    return getUniqueLabel(label, labels, type, attempt + 1);
  }

  return uniqueLabel;
}

// For now, a field can only be referenced in a group, which will always
// be a parent. This could change in the future and we would need to look
// at group children in that case
function fieldHasReference(
  field: Field,
  referringFields: Array<{
    id: string;
    label?: string;
  }>
): boolean {
  return referringFields.some((refField) => refField.id === field.id);
}

/**
 * It returns an array of all the dataRefs associated with a single field.
 * That means, all children dataRefs and its own one.
 */
function getAllDataRefs(field: Field, dataRefs = []): Array<string> {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
  if (field.dataRef) dataRefs.push(field.dataRef);

  if (field.children && canUseChildrenReferences(field)) {
    // @ts-expect-error refactor
    return field.children.reduce(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
      (acc, child) => getAllDataRefs(child, acc),
      dataRefs
    );
  } else {
    return dataRefs;
  }
}

/**
 * It returns for a specific field what indexes are associated in the visible
 * expression with a certain dataRef.
 */
export function getReferencedIndexes(
  referencedField: Field,
  field: Field,
  referringFields: Array<{
    id: string;
    label?: string;
  }>
): Array<number> | null | undefined {
  if (fieldHasReference(field, referringFields)) {
    const { rules } = parseExpression(field.visible);

    if (rules) {
      const dataRefs = getAllDataRefs(referencedField);
      const indexes = rules.reduce((acc, rule, idx) => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        if (dataRefs.includes(rule[0])) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          acc.push(idx);
        }

        return acc;
      }, []);
      // we need to reverse the indexes to remove them in the right order
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number[... Remove this comment to see the full error message
      return indexes.reverse();
    } else {
      logger.warn(
        "A field is being referenced but we can't find the rules associated with it"
      );
    }
  }
}

function wrapWord(word: string, wrapper = "'") {
  return wrapper + word + wrapper;
}

/**
 * Joins the list of strings together in a sentence-like string
 * If you want the strings to be wrapped by a character different than '', you
 * can specify it in the second argument.
 *
 * @example
 * const str = `The result is: ${sentenceJoin(['a', 'b', 'c'])}`
 * //=> "The result is: 'a', 'b', and 'c'"
 */
export function sentenceJoin(list: Array<string>, wrapper = "'"): string {
  // Removes empty values
  // $FlowFixMe
  const strs: Array<string> = list.filter((w) => w);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  if (strs.length === 1) return wrapWord(strs[0], wrapper);
  const needsSerialComma = strs.length >= 3;
  const firstItems = strs.slice(0, -1);
  const lastItem = strs[strs.length - 1];
  let firstString = firstItems.map((str) => wrapWord(str, wrapper)).join(", ");

  if (needsSerialComma) {
    firstString += ",";
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  return `${firstString} and ${wrapWord(lastItem, wrapper)}`;
}
export function filterNonUTF83BCharacters(string: string) {
  /* eslint-disable no-control-regex */
  return string.replace(/[^\u0001-\ua69F]/g, "");
}
export function filterNonAtomCharacters(string: string) {
  return filterNonUTF83BCharacters(string).replace(/#/g, "");
}

/**
 * This is a function that gets the relationship between mappings and fields.
 * A more complete solution should include a better way to map fields at the time of
 * creating them, with probably a dictionary provided by the backend. For now,
 * we do this "patch solution" to make sure we have a basic relationship
 * between fields and mappings
 */
export function parseSchemaFieldMappings(form: Form) {
  const { fields = [], pdfMap = [] } = form;
  const { mapsPerDataRef } = pdfMap.reduce(
    ({ mapsPerDataRef }, map) => {
      const parsedDataRefs = parseDataRefFromMap(map);
      parsedDataRefs.forEach((dataRef) =>
        addToDictionaryList(mapsPerDataRef, dataRef, map.label)
      );
      return {
        mapsPerDataRef,
      };
    },
    {
      mapsPerDataRef: {},
    }
  );
  const mapsPerField = fields.reduce((mapsPerField, field) => {
    // @ts-expect-error refactor
    mapsPerField[field.id] =
      // @ts-expect-error refactor
      (field.dataRef && mapsPerDataRef[field.dataRef]) || [];
    return mapsPerField;
  }, {});
  return {
    mapsPerField,
  };
}

/**
 * It takes a pdf map and returns an array with all the dataRefs associated with it.
 * For now, we only look for `@` and a subsequent word to determine if it's associated
 * with a field. Check the `parseSchemaFieldMappings` comments for more info
 */
function parseDataRefFromMap(map: PdfMapping): Array<string> {
  const { value } = map;

  if (value) {
    const regex = /@\w+/g;
    const matches = value.match(regex);

    if (matches && matches.length > 0) {
      return matches.map((match) => match.substring(1));
    }
  }

  return [];
}

function addToDictionaryList(
  dictionary: Record<string, any>,
  key: string,
  element: any
) {
  if (!dictionary[key]) {
    dictionary[key] = [];
  }

  dictionary[key].push(element);
}

/**
 * It generates a default workflow name based on the form names.
 * The limit is 90 characters as the specs
 */
export function defaultWorkflowName(forms: Array<Form>) {
  const formNames = forms.map((form) => form.name);
  const formNamesString = formNames.join(" & ");

  if (formNamesString.length > 87) {
    return formNamesString.substring(0, 87) + "...";
  } else {
    return formNamesString;
  }
}
export function parseCalculationExpression(expr: Expression) {
  if (!expr || !isExpression(expr)) {
    return null;
  }

  invariant(
    typeof expr === "object" && typeof expr.methodName === "string",
    "Calculation value must be an expression"
  );
  invariant(
    expr.args.length === 2,
    "Calculation value accepts only 2 arguments"
  );
  const { args, methodName } = expr;
  // $FlowFixMe I think flow got dizzy here
  const values = args.map(parseCalculationArg);
  return {
    operation: methodName,
    values,
  };
}

/**
 * Argument can be either a "val" expression or a constant (number o string)
 * It returns {type: "DATAREF", value: string} for a val expression and
 * {type: "CONSTANT", value: number (as string)} for a constant value
 */
function parseCalculationArg(value: Expression | string) {
  invariant(
    (typeof value === "object" &&
      value.methodName === "val" &&
      value.args.length === 1) ||
      typeof value === "string",
    "Calculation argument values need to be a 'val' expression or a string"
  );

  if (typeof value === "object") {
    return {
      type: "DATAREF",
      value: value.args[0],
    };
  } else if (typeof value === "string") {
    return {
      type: "CONSTANT",
      value,
    };
  }
}

/**
 * It gets the fields that can be source values in calculations for
 * a specific field
 */
export function calculationSources(
  field: Field,
  path: Path,
  fields: Array<Field>
): Array<Field> {
  // First we need to look for the index of the field in the fields list.
  // Only values with the same role and a smaller index can be a source
  const isChild = isChildPath(path);
  const baseIndex = isChild
    ? Number(path[path.length - 3])
    : Number(path[path.length - 1]);
  return fields // All the top-level fields before this one
    .slice(0, baseIndex + 1) // Children have the same role as their parent
    .filter((f) => shareSameRole(field.roles, f.roles)) // concat on this field's prior siblings if it's within a group
    .concat(
      isChild
        ? // @ts-expect-error refactor
          (fields[baseIndex].children || []).slice(
            0,
            // @ts-expect-error refactor
            (fields[baseIndex].children || []).indexOf(field)
          )
        : []
    ) // Include only calculation sources
    .filter((f) => canBeCalculationSource(f) && f.id !== field.id);
}
export function getReferringCalculations(
  fields: Array<Field>,
  field: Field
): Array<Field> {
  return flatMap(fields, (f) =>
    Array.isArray(f.children) ? f.children : f
  ).filter((f) => f.type === "Calculation" && inExpression(f.value, field));
}

function inExpression(expression: Expression, field: Field) {
  if (!expression) return false;

  return expression.args.some((e) => {
    // @ts-expect-error refactor
    if (!e.args) return false;
    // @ts-expect-error refactor
    return getIn(e, ["args", 0]) === field.dataRef;
  });
}

export function getReferringCalculationLabels(
  fields: Array<Field>,
  field: Field
) {
  const references = getReferringCalculations(fields, field);
  return fieldLabels(references, "Calculation");
}
export function getReferenceCalculationCopy(
  fields: Array<Field>,
  field: Field
) {
  const labels = getReferringCalculationLabels(fields, field);

  if (labels.length > 0) {
    let baseCopy = "This component is referenced in the calculation";

    if (labels.length > 1) {
      baseCopy += "s ";
    } else {
      baseCopy += " ";
    }

    const fieldreferences = sentenceJoin(labels);
    return baseCopy + fieldreferences;
  }
}

/**
 * Removes the formula of calculation components if they are referenced by the field to remove
 */
export function removeCalculationReferences(
  fields: Array<Field>,
  fieldToRemove: Field
): Array<Field> {
  const referencesIds = getReferringCalculations(fields, fieldToRemove).map(
    (f) => f.id
  );
  // @ts-expect-error refactor
  return fields.map((field) => {
    if (referencesIds.includes(field.id)) {
      return omit(field, ["value"]);
    }

    if (
      Array.isArray(field.children) &&
      field.children.some((child) => referencesIds.includes(child.id))
    ) {
      return updateIn(field, ["children"], (children) =>
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'child' implicitly has an 'any' type.
        children.map((child) => {
          if (referencesIds.includes(child.id)) {
            return omit(child, ["value"]);
          } else {
            return child;
          }
        })
      );
    }

    return field;
  });
}

/**
 * Extremely basic and unreliable function that determines if a path
 * contains the word "children" in it
 */
function isChildPath(path: Path) {
  return path.includes("children");
}

function canBeCalculationSource(field: Field) {
  return field.type === "Calculation" || field.type === "TextInput";
}
