import * as React from "react";
import { Flex } from "hw/ui/blocks";
import type {
  TextInputField,
  Field,
  MergeField,
  Mapping,
  FormMapping,
  MappedField,
} from "hw/portal/modules/common/draft";
import {
  HelpText,
  Label,
  Required,
  Placeholder,
  InputValidation,
  Prefill,
} from "./common";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: TextInputField;
  formId: string;
  mapping: Mapping;
  mergeFields: Array<MergeField>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal: (...args: Array<any>) => any;
  deleteMapping: (field: Field) => void;
  setMapping: (mergeField: MappedField, field: Field) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
  formMappings: Array<FormMapping>;
  activePrefill?: MappedField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreateMergeField: (...args: Array<any>) => any;
  referenceCalculationCopy?: string | null | undefined;
};
export function TextInputSettings(props: Props) {
  const {
    defaultSettings,
    field,
    openMergeFieldsModal,
    deleteMapping,
    path,
    mergeFields,
    formMappings,
    setMapping,
    referenceSetting,
    activePrefill,
    editorDispatch,
    onCreateMergeField,
    referenceCalculationCopy,
  } = props;
  const { label, required, helpText, placeholder, validators, type } = field;
  const settingsProps = {
    path,
    editorDispatch,
  };
  const validationDisabled = Boolean(referenceCalculationCopy);
  return (
    <Flex flexDirection="column">
      {defaultSettings}
      <Label
        {...settingsProps}
        label={label}
        fieldType={type}
        placeholder="First Name, Last Name, Email, ..."
      />
      <Required {...settingsProps} required={required} fieldType={type} />
      <Prefill
        field={field}
        onChange={setMapping}
        onRemove={deleteMapping}
        mergeFields={mergeFields}
        activePrefill={activePrefill}
        openMergeFieldsModal={openMergeFieldsModal}
        formMappings={formMappings}
        onCreateMergeField={onCreateMergeField}
      />
      <Placeholder {...settingsProps} placeholder={placeholder} />
      <HelpText {...settingsProps} helpText={helpText} />
      <InputValidation
        {...settingsProps}
        // @ts-expect-error refactor
        validators={validators}
        // @ts-expect-error refactor
        dataRef={field.dataRef}
        disabled={validationDisabled}
      />
      {referenceSetting}
    </Flex>
  );
}
export default React.memo(TextInputSettings);
