/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex } from "hw/ui/blocks";
import Checkbox from "hw/ui/checkbox";
import Or from "hw/ui/or";
import { Stack } from "hw/ui/layout";
import {
  IfAuthorizedTo,
  permissions,
  useIsAuthorizedTo,
} from "hw/portal/modules/authn";
import { ToastContext } from "hw/ui/toast";
import DocumentDeliverySelector from "./components/document-delivery-selector";
import Form from "./form";
import { Divider } from "./components/form";
import ShareableLink from "./components/shareable-link";
import type * as Types from "./types";

type LaunchWorkflowV2Props = {
  workflow: Types.Workflow;
};

export function LaunchWorkflowV2(props: LaunchWorkflowV2Props) {
  const { workflow } = props;
  const { addSuccess, addDanger } = React.useContext(ToastContext);

  const allowedDocumentDeliveryTypes =
    workflow.allowedDocumentDeliveryTypes ?? ["attachment", "link"];

  const [documentDeliveryType, setDocumentDeliveryType] = React.useState(() => {
    const type = workflow.documentDeliveryType;

    return (
      allowedDocumentDeliveryTypes.find((t) => t === type) ??
      allowedDocumentDeliveryTypes[0] ??
      "attachment"
    );
  });

  const [notifyWhenComplete, setNotifyWhenComplete] = React.useState(
    workflow.notifyWhenComplete ?? true
  );

  return (
    <Stack space="lg">
      <div>
        {/**
         * These controls are under the 'update:workflow' permission because
         * they silenty update the workflow configuration settings, so we
         * disallow sending them altogether from the UI
         */}
        <IfAuthorizedTo permission={permissions.UpdateWorkflow}>
          <Box mt="md" mb="sm">
            <DocumentDeliverySelector
              onChange={setDocumentDeliveryType}
              selectedOption={documentDeliveryType}
              allowedDocumentDeliveryTypes={allowedDocumentDeliveryTypes}
            />
          </Box>
          <Box>
            <Checkbox
              onChange={(evt) => setNotifyWhenComplete(evt.target.checked)}
              checked={notifyWhenComplete}
              name="document-notification"
              value="document-notification"
              label="Notify the sender or team members when complete"
              labelFontSize="ms"
              dataTestId="delivery-notification-checkbox"
            />
          </Box>
        </IfAuthorizedTo>
      </div>

      <Stack space="lg">
        <Divider />
        <Flex>
          <Box flex={1} extend={{ minWidth: "400px" }}>
            <FormSection
              key={workflow.guid}
              workflow={workflow}
              documentDeliveryType={documentDeliveryType}
              notifyWhenComplete={notifyWhenComplete}
              addSuccess={addSuccess}
              addDanger={addDanger}
            />
          </Box>
          {canShare(workflow) && (
            <ShareableLinkSection
              workflow={workflow}
              addSuccess={addSuccess}
              addDanger={addDanger}
            />
          )}
        </Flex>
      </Stack>
    </Stack>
  );
}

function FormSection(props: {
  workflow: Types.Workflow;
  documentDeliveryType: string;
  notifyWhenComplete: boolean;
  addSuccess: Function;
  addDanger: Function;
}) {
  const {
    workflow,
    documentDeliveryType,
    notifyWhenComplete,
    addSuccess,
    addDanger,
  } = props;
  const hasMultipleRoles = workflow.roles.length > 1;

  const headerCopy = hasMultipleRoles ? (
    <FormattedMessage
      id="launchWorkflow.multiParticipantHeader"
      defaultMessage="Send to specific participants"
    />
  ) : (
    <FormattedMessage
      id="launchWorkflow.singleParticipantHeader"
      defaultMessage="Send to a specific participant"
    />
  );

  return (
    <Form
      key={workflow.guid}
      workflow={workflow}
      headerCopy={headerCopy}
      documentDeliveryType={documentDeliveryType}
      notifyWhenComplete={notifyWhenComplete}
      addDanger={addDanger}
      addSuccess={addSuccess}
    />
  );
}

function ShareableLinkSection(props: {
  workflow: Types.Workflow;
  addDanger: Function;
  addSuccess: Function;
}) {
  const { workflow, addSuccess, addDanger } = props;

  const hasActivatedLink = workflow.shareableLink?.isDisabled === false;
  const isAuthorizedToUpdate = useIsAuthorizedTo(permissions.UpdateWorkflow);
  const noActivatedLinkExists = !hasActivatedLink;

  // If there is not an active link for this workflow and this user is not
  // authorized to update the workflow, then hide the entire section
  if (noActivatedLinkExists && !isAuthorizedToUpdate) return null;

  return (
    <>
      <Flex mx="xl">
        <Or direction="vertical" offset={50} />
      </Flex>
      <Box flex={1}>
        <div>
          <ShareableLink
            {...workflow.shareableLink}
            workflow={workflow}
            addSuccess={addSuccess}
            addDanger={addDanger}
          />
        </div>
      </Box>
    </>
  );
}

function canShare(workflow: Types.Workflow) {
  return workflow.shareableLinkEligible;
}
