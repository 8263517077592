import { insert, removeAt } from "timm";
import { INDICATOR_TOP, INDICATOR_BOTTOM } from "./constants";

/**
 * Reorders a list around a start and end index
 */
export const reorder = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr: Array<any>,
  startIndex: number,
  endIndex: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<any> => insert(removeAt(arr, startIndex), endIndex, arr[startIndex]);

/**
 * Returns the correct position description for the drop indicator given the
 * source and target index
 */
export function getIndicatorPosition(sourceIndex: number, targetIndex: number) {
  if (sourceIndex < targetIndex) {
    return INDICATOR_BOTTOM;
  } else if (sourceIndex > targetIndex) {
    return INDICATOR_TOP;
  }
}
