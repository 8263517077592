import * as React from "react";
import { createComponent } from "hw/ui/style";
import { Flex } from "hw/ui/blocks";
import { SuccessIcon, DangerIcon, WarningIcon, InfoIcon } from "hw/ui/icons";
import theme from "hw/ui/theme";
import { TYPES } from "./constants";

const Wrapper = createComponent(function Wrapper() {
  return {
    alignItems: "flex-start",
    display: "flex",
    width: "100%",
  };
});

const Content = createComponent(function Content({
  theme,
  type,
  shouldColorText,
  fontSize = "sm",
  lineHeight = "lg",
}) {
  return {
    color:
      type === TYPES.DANGER && shouldColorText
        ? theme.color.textError
        : theme.color.textStandard,
    fontSize: theme.fontSize[fontSize],
    // TODO: This is a little hacky because it relies on the specific dimensions
    // of the success/danger/warning icons so that the text and icon line up
    // properly
    lineHeight: theme.space[lineHeight],
  };
});

type Props = {
  /**
   * The content of the message
   */
  children: React.ReactNode;

  fontSize?: keyof typeof theme["fontSize"];
  lineHeight?: keyof typeof theme["space"];

  /**
   * If `true`, text color may be altered based on the status type.  If `false`,
   * text color will always default to the base grey.
   */
  shouldColorText: boolean;

  /**
   * The type of status message
   */
  type: typeof TYPES[keyof typeof TYPES];
};

/**
 * The `StatusMessage` is a feedback message to convey short pieces of feedback after user
 * actions. The types are `Success`, `Warning` and `Danger`.
 */
export default function StatusMessage(props: Props) {
  const {
    children,
    shouldColorText = true,
    type,
    fontSize = "ms",
    lineHeight,
  } = props;

  return (
    <Wrapper type={type}>
      <Flex mr="xs">{renderIcon(type)}</Flex>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: a... Remove this comment to see the full error message */}
      <Content
        type={type}
        shouldColorText={shouldColorText}
        fontSize={fontSize}
        lineHeight={lineHeight}
      >
        {children}
      </Content>
    </Wrapper>
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
function renderIcon(type) {
  switch (type) {
    case TYPES.SUCCESS:
      return <SuccessIcon />;
    case TYPES.DANGER:
      return <DangerIcon />;
    case TYPES.WARNING:
      return <WarningIcon />;
    case TYPES.INFO:
      return <InfoIcon />;
    default:
      return <SuccessIcon />;
  }
}
