import { createComponent } from "hw/ui/style";

/**
 * TODO: this should be part of a text component with different values for
 * different types of headers and styles. I'm adding it here for now.
 */

export const TableCellTextTitle = createComponent(function TableCellTextTitle({
  theme,
}) {
  return {
    fontSize: theme.fontSize.ms,
    fontWeight: theme.fontWeight.semibold,
    color: theme.color.textStandard,
  };
});

export const TableCellTextSubtitle = createComponent(
  function TableCellTextSubtitle({ theme }) {
    return {
      fontSize: theme.fontSize.sm,
      fontWeight: theme.fontWeight.normal,
      color: theme.color.textDim,
    };
  }
);
