import * as React from "react";
import { Flex } from "hw/ui/blocks";
import type {
  PhoneNumberField,
  FormMapping,
  MappedField,
  Field,
} from "hw/portal/modules/common/draft";
import { HelpText, Label, Required, Prefill } from "./common";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: PhoneNumberField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
  deleteMapping: (field: Field) => void;
  setMapping: (mappedField: MappedField, field: Field) => void;
  formMappings: Array<FormMapping>;
  activePrefill?: MappedField;
};

/**
 * The settings panel on the side
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function PhoneSettings(props: Props) {
  const {
    field,
    path,
    editorDispatch,
    defaultSettings,
    referenceSetting,
    setMapping,
    deleteMapping,
    activePrefill,
    formMappings,
  } = props;
  const { label, required, helpText, type } = field;
  return (
    <Flex flexDirection="column">
      {defaultSettings}
      <Label
        path={path}
        editorDispatch={editorDispatch}
        label={label}
        fieldType={type}
        placeholder="Phone Number, Mobile Number, ..."
      />
      <Required
        path={path}
        editorDispatch={editorDispatch}
        required={required}
        fieldType={type}
      />
      <Prefill
        field={field}
        onChange={setMapping}
        onRemove={deleteMapping}
        activePrefill={activePrefill}
        formMappings={formMappings}
      />
      <HelpText
        path={path}
        editorDispatch={editorDispatch}
        helpText={helpText}
      />
      {referenceSetting}
    </Flex>
  );
}
export default PhoneSettings;
