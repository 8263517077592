import { combineRules } from "fela";
import {
  connect,
  createComponent,
  withTheme,
  createComponentWithProxy,
} from "react-fela";
import Provider from "./provider";

export {
  combineRules,
  createComponent,
  withTheme,
  createComponentWithProxy,
  Provider,
  connect as withStyle,
};
export { default as createRenderer } from "./create-renderer";
export { useStyle, useCss } from "./hooks";
export type { StyleRule as Extend } from "./hooks";

export default Provider;
