/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Actions as EditorActions,
  ActionTypes as AT,
  changeTypes,
} from "../state";

export function createField(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.CREATE_FIELD,
  });
}

export function deleteField(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.DELETE_FIELD,
  });
}

export function moveField(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.MOVE_FIELD,
  });
}

export function updateFormRoles(payload: any) {
  return EditorActions.UpdateLocalState({
    ...payload,
    changeType: changeTypes.UPDATE_FORM_ROLES,
  });
}

export function updateRoleTitle(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.UPDATE_ROLE_TITLE,
  });
}

export function addNewRoleToForm(payload: any) {
  return EditorActions.AddNewRoleToForm({
    ...payload,
    changeType: changeTypes.ADD_NEW_ROLE_TO_FORM,
  });
}

/**
 * Triggers an action to request field reassignment. If this field has
 * active references, the user will be asked for confirmation before removal
 */
export function requestFieldReassignment(props: any) {
  const { field, path, roleId } = props;

  return {
    type: AT.FIELD_REASSIGNMENT_REQUESTED,
    payload: {
      field,
      path,
      roleId,
    },
  };
}

export function requestComponentDeletion(props: any) {
  const { path, formId } = props;

  return {
    type: AT.COMPONENT_DELETION_REQUESTED,
    payload: {
      path,
      formId,
    },
  };
}

export function deleteFormRole(payload: any) {
  return EditorActions.DeleteFormRole({
    ...payload,
    changeType: changeTypes.DELETE_FORM_ROLE,
  });
}

export function setMapping(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.SET_MAPPING,
  });
}

export function deleteMapping(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.DELETE_MAPPING,
  });
}

export function changeFieldSetting(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.CHANGE_FIELD_SETTING,
  });
}

/**
 * NOTE: Uploading PDFs is currently undoable because we do not delete PDFs
 * unles the user specifically asks via the 'Delete this PDF' action. If this
 * behavior changes we will not be able to undo this action
 */
export function uploadPdfTemplate(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.UPLOAD_PDF_TEMPLATE,
  });
}

// TODO: Revist the undoable false after we decide how to handle deleting PDFs and sync
export function deletePdfTemplate(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.DELETE_PDF_TEMPLATE,
    undoable: false,
  });
}

// TODO: Revist the undoable false after we decide how to handle deleting PDFs and sync
export function savePdfMap(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.SAVE_PDF_MAP,
    undoable: false,
  });
}

export function duplicateField(payload: any) {
  return EditorActions.UpdateParsedSchema({
    ...payload,
    changeType: changeTypes.DUPLICATE_FIELD,
  });
}

export function changeComponentType(payload: any) {
  return EditorActions.ChangeComponentType({
    ...payload,
    changeType: changeTypes.CHANGE_COMPONENT_TYPE,
  });
}

export function updateDraft(payload: any) {
  return EditorActions.UpdateDraft({
    ...payload,
    changeType: changeTypes.FORM_ANALYZED,
  });
}
