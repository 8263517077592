import * as React from "react";

export default function OperatorDivide() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.99998 4C8.99998 4.55228 8.55227 5 7.99998 5C7.4477 5 6.99998 4.55228 6.99998 4C6.99998 3.44772 7.4477 3 7.99998 3C8.55227 3 8.99998 3.44772 8.99998 4ZM3 7.99993C3 7.44765 3.44772 6.99993 4.00001 6.99994L12 7C12.5523 7 13 7.44772 13 8.00001C13 8.55229 12.5523 9 12 9L3.99999 8.99994C3.44771 8.99993 3 8.55222 3 7.99993ZM7.99998 13C8.55227 13 8.99998 12.5523 8.99998 12C8.99998 11.4477 8.55227 11 7.99998 11C7.4477 11 6.99998 11.4477 6.99998 12C6.99998 12.5523 7.4477 13 7.99998 13Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99998 4C8.99998 4.55228 8.55227 5 7.99998 5C7.4477 5 6.99998 4.55228 6.99998 4C6.99998 3.44772 7.4477 3 7.99998 3C8.55227 3 8.99998 3.44772 8.99998 4ZM3 7.99993C3 7.44765 3.44772 6.99993 4.00001 6.99994L12 7C12.5523 7 13 7.44772 13 8.00001C13 8.55229 12.5523 9 12 9L3.99999 8.99994C3.44771 8.99993 3 8.55222 3 7.99993ZM7.99998 13C8.55227 13 8.99998 12.5523 8.99998 12C8.99998 11.4477 8.55227 11 7.99998 11C7.4477 11 6.99998 11.4477 6.99998 12C6.99998 12.5523 7.4477 13 7.99998 13Z"
        fill="white"
      />
      <path
        d="M4.00001 6.99994L4 7.99994L4 7.99994L4.00001 6.99994ZM12 7L12 6L12 6L12 7ZM12 9L12 8L12 8L12 9ZM3.99999 8.99994L3.99998 9.99994L3.99998 9.99994L3.99999 8.99994ZM7.99998 6C9.10455 6 9.99998 5.10457 9.99998 4L7.99998 4V6ZM5.99998 4C5.99998 5.10457 6.89542 6 7.99998 6V4H5.99998ZM7.99998 2C6.89542 2 5.99998 2.89543 5.99998 4H7.99998V2ZM9.99998 4C9.99998 2.89543 9.10455 2 7.99998 2V4L9.99998 4ZM4.00002 5.99994C2.89545 5.99993 2.00001 6.89535 2 7.99992L4 7.99994L4 7.99994L4.00002 5.99994ZM12 6L4.00002 5.99994L4 7.99994L12 8L12 6ZM14 8.00001C14 6.89545 13.1046 6.00001 12 6L12 8L12 8L14 8.00001ZM12 10C13.1045 10 14 9.10458 14 8.00001L12 8L12 8L12 10ZM3.99998 9.99994L12 10L12 8L4 7.99994L3.99998 9.99994ZM2 7.99992C1.99999 9.10449 2.89542 9.99993 3.99998 9.99994L4 7.99994L4 7.99994L2 7.99992ZM7.99998 12V14C9.10455 14 9.99998 13.1046 9.99998 12H7.99998ZM7.99998 12H9.99998C9.99998 10.8954 9.10455 10 7.99998 10L7.99998 12ZM7.99998 12L7.99998 10C6.89542 10 5.99998 10.8954 5.99998 12H7.99998ZM7.99998 12H5.99998C5.99998 13.1046 6.89542 14 7.99998 14V12Z"
        fill="#727A83"
        mask="url(#path-1-inside-1)"
      />
    </svg>
  );
}
