import * as React from "react";
import { createComponent } from "hw/ui/style";

type Props = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/ban-types
  extend?: {};
};

/**
 * Generic page header component for the default portal layout.
 */
const PageHeader = createComponent(function PageHeader({ theme }) {
  return {
    color: theme.color.textStandard,
    fontWeight: "normal",
    fontSize: theme.fontSize.xl,
    marginTop: 0,
    marginBottom: theme.space.xl,
  };
}, "h1");

export default PageHeader as React.ComponentType<Props>;
