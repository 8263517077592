const guideId = "data-guide-id";
// TODO: QA ref should be deprecated
const qaRef = "data-qa-ref";
const automationId = "data-automation-id";

export interface TestAttributes {
  [guideId]: string | undefined;
  [qaRef]: string | undefined;
  [automationId]: string | undefined;
}

/**
 * Generates an object that can be spread to assign data test attributes to an element.
 * If qaValue is falsy then it defaults the qaValue to guideValue.
 *
 * @param guideValue
 * @param qaValue
 * @returns {[{guideId: string, qaRef: string}]}
 */
const getTestAttributes = (
  guideValue?: string,
  qaValue?: string
): TestAttributes => ({
  [guideId]: guideValue,
  [qaRef]: qaValue || guideValue,
  [automationId]: qaValue || guideValue,
});

export const getTestAttributesFromProps = ({
  guideValue,
  qaValue,
}: {
  guideValue: string;
  qaValue: string;
}) => getTestAttributes(guideValue, qaValue);

export const testAttributeNames = [guideId, qaRef, automationId];

export default getTestAttributes;
