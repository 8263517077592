/* eslint-disable @typescript-eslint/no-explicit-any */
import isObjectEqual from "lodash/isEqual";
import type { PdfMapping as PdfMappingType } from "hw/portal/modules/common/draft";
import {
  AddressGroup,
  MultipleChoice,
  TextInput,
  SignatureField,
  DateInput,
  MultilineMapping as Multiline,
  Text as TextOutputField,
  Checkmark as CheckmarkOutputField,
} from "./factories";
import * as ValueExpr from "./value-expr";
import { isLinkedTo } from "./utils";
import { MappingTypes } from "./constants";

export { withRawValue, duplicate, id, isLinkedTo } from "./utils";
export {
  FontSize,
  AddressFormats,
  MultipleChoiceFormats,
  MappingTypes as Types,
} from "./constants";
export { ValueExpr, MultipleChoice, AddressGroup };

/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function isEqual(a: PdfMappingType, b: PdfMappingType) {
  return a.value === b.value && isObjectEqual(a.meta, b.meta);
}

/**
 * Creates a PDF mapping type linked to a field
 */
export function createForField(
  field: any,
  mappingAttrs: Partial<PdfMappingType>
) {
  switch (field.type) {
    case "TextInput":
      return TextInput(field, mappingAttrs);
    case "AddressGroup":
      return AddressGroup(field, mappingAttrs);
    case "MultipleChoice":
      return MultipleChoice(field, mappingAttrs);
    case "Signature":
      // @ts-expect-error refactor
      return SignatureField(field, mappingAttrs);
    case "DateInput":
      return DateInput(field, mappingAttrs);
    case "Multiline":
      return Multiline(field, mappingAttrs);
    default:
      return TextInput(field, mappingAttrs);
  }
}

/**
 * Returns the linked field of the mapping.
 */
export function getLinkedField(
  mapping: PdfMappingType,
  fieldPathsById: Map<string, any>
) {
  const field = mapping.source ? fieldPathsById.get(mapping.source) : null;
  if (!field) return null;

  if (isLinkedTo(mapping, field)) {
    return field;
  }

  return null;
}

/**
 * Links a mapping to a field
 */
export function linkTo(mapping: PdfMappingType, field: any) {
  return createForField(field, mapping);
}

/**
 * Sets the font size of an output field
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function setFontSize(mapping: PdfMappingType, newFontSize: number) {
  if (mapping.type === MappingTypes.Text) {
    return {
      ...mapping,
      fontSize: newFontSize,
      dimensions: {
        ...mapping.dimensions,
        height: newFontSize,
      },
    };
  } else if (mapping.type === MappingTypes.Multiline) {
    return {
      ...mapping,
      fontSize: newFontSize,
    };
  } else {
    return mapping;
  }
}

/**
 * Changes the output field type
 * We leave out Signature and Multiline
 */
/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
export function setOutputFieldType(outputField: PdfMappingType, type: string) {
  if (type === MappingTypes.Text) {
    const newField = TextOutputField(outputField);
    return {
      ...newField,
      label: outputField.label,
      dimensions: {
        ...newField.dimensions,
        width: 125,
      },
    };
  } else if (type === MappingTypes.Checkmark) {
    const { splitBy, ...outputFieldProps } = outputField;
    const newField = CheckmarkOutputField(outputFieldProps);

    return {
      ...newField,
      label: outputField.label,
    };
  } else {
    return outputField;
  }
}
