/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { getIn } from "timm";
import { TruncatedText, VisuallyHidden } from "hw/ui/text";
import type { PdfMapping, Component } from "hw/portal/modules/common/draft";
import { Flex } from "hw/ui/blocks";
import theme from "hw/ui/theme";
import { useStyle } from "hw/ui/style";
import { Signature as SignatureIcon } from "hw/portal/modules/draft-editor/form-builder/components/preview/component-menu/icons";
import { Tooltip } from "hw/ui/tooltip";
import {
  ValueExpr as Value,
  Types as MappingTypes,
  AddressFormats,
} from "../mapping";
import { useBuilderContext } from "../context";

type Props = {
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  mapping: PdfMapping;
  roleTheme: {
    base: string;
    light: string;
  };
};

export default function MappingLabel(props: Props) {
  const { mapping, roleTheme } = props;
  const { type } = mapping;
  const { mappableFieldsById } = useBuilderContext();
  const linkedField = mapping.source
    ? mappableFieldsById.get(mapping.source)
    : null;

  // @ts-expect-error refactor
  const labelText = getLabel(mapping, linkedField);

  if (type === MappingTypes.Checkmark) {
    return (
      <Tooltip tip={labelText} position="top">
        {/**
         * NOTE: This tooltip won't be keyboard-reachable because this element
         * is not in the tab order. This is tricky with the `ui/document-mapper`
         * component because it has its own focus handlers.
         */}
        <div>
          <CheckmarkIcon fill={theme.color.textStandard} />
          {/**
           * For a11y/testing, ensure there's a text value for this label even
           * if we don't display one visually
           */}
          <VisuallyHidden>{labelText}</VisuallyHidden>
        </div>
      </Tooltip>
    );
  }

  const label = <DisplayText>{labelText}</DisplayText>;

  if (type === MappingTypes.Signature) {
    return (
      <>
        <Flex mr="xs">
          <SignatureIcon fill={roleTheme.base} size={12} />
        </Flex>
        {label}
      </>
    );
  } else if (type === MappingTypes.Multiline) {
    return (
      <MultilinePattern fontSize={mapping.fontSize}>
        {labelText}
      </MultilinePattern>
    );
  }

  return label;
}

// TODO: This should live in the `common/draft/schema-ops` file as a
// `OutputField.describe` method
export function getLabel(
  mapping: PdfMapping,
  linkedField: Component | null | undefined,
  extraLabel?: string
): string {
  if (!linkedField) return defaultMappingLabel(mapping.label, extraLabel);

  // Extra defensive to handle legacy mappings that may not be in the format
  // we expect
  if (linkedField.type === "AddressGroup" && knownAddressFormat(mapping)) {
    const format = getIn(mapping, ["options", "format"]);
    if (format === AddressFormats.SeparateFields) {
      const dataRefSource = Value.extractDataRef(mapping.value);

      if (dataRefSource) {
        const foundChild = linkedField?.children?.find(
          (child) => child.dataRef === dataRefSource
        );

        return getLabel(mapping, foundChild, linkedField.label);
      }
    } else if (format === AddressFormats.TwoLines) {
      const isStreet =
        mapping.value === Value.AddressGroup.FullStreet(linkedField);
      const isCityStateZip =
        mapping.value === Value.AddressGroup.CityStateZip(linkedField);
      if (isStreet)
        return defaultMappingLabel("Street Address, Line 2", linkedField.label);
      if (isCityStateZip)
        return defaultMappingLabel("City, State, Zip", linkedField.label);
    } else if (format === AddressFormats.ThreeLines) {
      const isStreet =
        mapping.value === Value.AddressGroup.StreetAddress(linkedField);
      const isStreetLine2 =
        mapping.value === Value.AddressGroup.StreetLine2(linkedField);
      const isCityStateZip =
        mapping.value === Value.AddressGroup.CityStateZip(linkedField);
      if (isStreet)
        return defaultMappingLabel("Street Address", linkedField.label);
      if (isStreetLine2)
        return defaultMappingLabel("Line 2", linkedField.label);
      if (isCityStateZip)
        return defaultMappingLabel("City, State, Zip", linkedField.label);
    } else if (format === AddressFormats.FourLines) {
      const isStreet =
        mapping.value === Value.AddressGroup.FullStreet(linkedField);
      const isCity = mapping.value === Value.AddressGroup.City(linkedField);
      const isState = mapping.value === Value.AddressGroup.State(linkedField);
      const isZip = mapping.value === Value.AddressGroup.Zip(linkedField);
      if (isStreet)
        return defaultMappingLabel("Street Address, Line 2", linkedField.label);
      if (isCity) return defaultMappingLabel("City", linkedField.label);
      if (isState) return defaultMappingLabel("State", linkedField.label);
      if (isZip) return defaultMappingLabel("Zip", linkedField.label);
    } else if (format === AddressFormats.SingleLine) {
      return defaultMappingLabel(
        "Street Address, Line 2, City, State, Zip",
        linkedField.label
      );
    }
  } else if (linkedField.type === "Signature") {
    // @ts-expect-error refactor
    return defaultMappingLabel("Signature");
  } else if (
    linkedField.type === "MultipleChoice" &&
    mapping.type === MappingTypes.Checkmark
  ) {
    const optionIndex = mapping?.meta?.optionIndex;

    if (optionIndex !== undefined) {
      const optionLabel = linkedField.options[optionIndex];

      if (optionLabel) {
        return optionLabel;
      }
    }
  }

  return defaultMappingLabel(linkedField.label || mapping.label, extraLabel);
}

// @ts-expect-error refactor
function defaultMappingLabel(label, extra) {
  let str = label || "Untitled Mapping";

  if (extra) {
    str += ` (${extra})`;
  }

  return str;
}

// @ts-expect-error refactor
function knownAddressFormat(mapping) {
  const options = mapping.options || {};
  const { format } = options;

  if (!format) return false;

  return Object.values(AddressFormats).includes(format);
}

function DisplayText(props: any) {
  const { children } = props;
  return <TruncatedText>{children}</TruncatedText>;
}

// @ts-expect-error refactor
function CheckmarkIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
    >
      <path
        fill={fill}
        d="M3.7229411,7.21435647 C3.34137946,6.81508074 2.70838569,6.80072063 2.30910996,7.18228227 C1.90983422,7.56384392 1.89547411,8.19683768 2.27703576,8.59611342 L5.23451089,11.69089 C5.68417395,12.1614288 6.45637619,12.0838305 6.80342535,11.5332304 L11.8458447,3.53333585 C12.1403333,3.06612405 12.0003135,2.44864387 11.5331017,2.15415527 C11.0658899,1.85966666 10.4484098,1.99968646 10.1539212,2.46689826 L5.79492351,9.38253116 L3.7229411,7.21435647 Z"
      />
    </svg>
  );
}

type MultilineProps = {
  children: React.ReactNode;
  fontSize?: number;
};

function MultilinePattern(props: MultilineProps) {
  const { children, fontSize = 14 } = props;

  // This adds a ruled line pattern to the area
  const patternCn = useStyle("multi-line-pattern", {
    height: "100%",
    width: "100%",
    backgroundImage: `repeating-linear-gradient(transparent 0px, transparent ${
      fontSize - 1
    }px, ${theme.color.blue600} ${fontSize}px)`,
  });

  const textCn = useStyle("multi-line-text", {
    wordBreak: "break-word",
    textAlign: "left",
    overflow: "hidden",
  });
  return (
    <Flex className={patternCn}>
      <span className={textCn}>{children}</span>
    </Flex>
  );
}
