import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import type {
  Field as FieldT,
  MergeField,
  MappedField,
  Mapping,
  Role,
  Form,
} from "hw/portal/modules/common/draft";
import type { DraftFeedback } from "hw/portal/modules/common/graphql/schema";
import type { Path } from "hw/common/types";
import PrefillIcon from "hw/ui/icons/svg-icons/prefill-icon";
import ConditionIcon from "hw/ui/icons/svg-icons/condition-icon-16";
import MultipleChoice from "./multiple-choice";
import TextInput from "./text-input";
import Signature from "./signature";
import PhoneNumber from "./phone-number";
import AddressGroup from "./address-group";
import FileAttachment from "./file-attachment";
import DateInput from "./date-input";
import Ssn from "./ssn";
import Sin from "./sin";
import Ein from "./ein";
import Fallback from "./fallback";
import Paragraph from "./paragraph";
import Multiline from "./multi-line";
import Calculation from "./calculation";
import Group from "./group-component";
import RoleBadge from "./role-badge";
import * as Styled from "../styled";
import { DRAG_TYPE_FIELD } from "../../constants";
import { findUnrenderableProperty } from "../../utils";

type Props = {
  connectDragSource?: (el: React.ReactNode) => React.ReactNode;

  /**
   * A string representing the type of the item being dragged
   */
  draggingType: string | null | undefined;
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'field'.
  field: FieldT;
  formId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpToDef: (...args: Array<any>) => any;
  mergeFields: Array<MergeField>;
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'mapping'.
  mapping: Mapping;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openMergeFieldsModal: (...args: Array<any>) => any;
  isSelected: boolean;
  setMapping: (mappedField: MappedField | MergeField, field: FieldT) => void;
  selectField: (path: Path) => void;
  roleBadge: string;
  showRoleBadge?: boolean;
  roleTitle: string;
  isDragLayer: boolean;

  /**
   * True if the field is actively being dragged
   */
  isDragging: boolean;

  /**
   * The field to render
   */
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'field'.
  field: FieldT;
  fields: Array<FieldT>;
  path: Path;
  roles: Array<Role>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCreate: (path: Path, type: string, attrs?: {}) => void;
  warnings: Array<DraftFeedback>;
  errors: Array<DraftFeedback>;
  forms: Array<Form>;
  prefillMessage: string | null | undefined;
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'mapping'.
  mapping: Mapping;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: any;
  onCreateMergeField?: (mergeField: MergeField) => void;
};
/**
 * Wrapper for all field preview renderings
 */

class Field extends React.PureComponent<Props> {
  render() {
    const {
      field,
      isDragging,
      connectDragSource = (i) => i,
      path,
      selectField,
      mergeFields,
      mapping,
      formId,
      roleBadge,
      roleTitle,
      draggingType,
      showRoleBadge,
      forms,
      prefillMessage,
      isDragLayer,
      isSelected,
      ...rest
    } = this.props;
    // The fields in props are the scoped ones not the total ones, because of it
    // we need to calculate it from the list of forms
    const formFields = forms?.find((form) => form.id === formId)?.fields || [];
    const fieldPreview = renderComponent(field, {
      ...rest,
      prefillMessage,
      formId,
      path,
      isSelected,
      mergeFields,
      selectField,
      isAnyFieldDragging: draggingType === DRAG_TYPE_FIELD || isDragging,
      mapping,
      forms,
      formFields,
    });
    return (
      <Styled.FieldPreviewWrapper
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
        onClick={(e) => {
          // TODO: Is this the right place for this?
          // Since this item is recursive, the clicks might propagate
          // to the parent and select the parent element instead of the
          // child.
          // We also need this properly deselect fields when the surrounding
          // canvas is clicked
          e.stopPropagation();

          if (!isSelected) {
            selectField(path);
          }
        }}
        isDragging={isDragging}
        isSelected={isSelected}
        isDragLayer={isDragLayer}
        data-testid="field-preview"
        id={field.id}
        {...getTestAttributes("editor-build-field-preview")}
      >
        <Styled.DragHandle
          connectDragSource={connectDragSource}
          isDragging={isDragging}
        >
          <Styled.RoleBadgeContainer
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
            isSelected={isSelected}
            data-testid="field-short-label"
          >
            {showRoleBadge && (
              <RoleBadge title={roleTitle}>{roleBadge}</RoleBadge>
            )}
            {prefillMessage && (
              <RoleBadge
                title="Receiving pre-fill data"
                data-testid="prefill-badge-icon"
              >
                <PrefillIcon />
              </RoleBadge>
            )}

            {field.type === "Group" && (
              <RoleBadge>
                <ConditionIcon />
              </RoleBadge>
            )}
          </Styled.RoleBadgeContainer>
        </Styled.DragHandle>

        <Styled.FieldPreviewContentWrapper
          type={field.type}
          isSelected={isSelected}
        >
          {fieldPreview}
        </Styled.FieldPreviewContentWrapper>
      </Styled.FieldPreviewWrapper>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function renderComponent(field: FieldT, props: any) {
  const unrenderableProperty = findUnrenderableProperty(field);

  // If a field has a property that cannot be rendered, the fallback is displayed
  // so that the rest of the builder is still usable.
  if (unrenderableProperty) {
    return (
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      <Fallback
        field={field}
        {...props}
        jumpPath={[...props.path, unrenderableProperty]}
      >
        The '{unrenderableProperty}' property in this component is currently not
        configurable in the form builder.
      </Fallback>
    );
  }

  switch (field.type) {
    case "TextInput":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <TextInput field={field} {...props} />;

    case "Paragraph":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Paragraph field={field} {...props} />;

    case "MultipleChoice":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <MultipleChoice field={field} {...props} />;

    case "Signature":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Signature field={field} {...props} />;

    case "SSN":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Ssn field={field} {...props} />;

    case "PhoneNumber":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <PhoneNumber field={field} {...props} />;

    case "SIN":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Sin field={field} {...props} />;

    case "EIN":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Ein field={field} {...props} />;

    case "AddressGroup":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <AddressGroup field={field} {...props} />;

    case "FileAttachment":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <FileAttachment field={field} {...props} />;

    case "Group":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Group field={field} {...props} />;

    case "DateInput":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <DateInput field={field} {...props} />;

    case "Multiline":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Multiline field={field} {...props} />;

    case "Calculation":
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Calculation field={field} {...props} />;

    default:
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe This comment suppresses
       * an error found when upgrading Flow to v0.132.0. To view the error,
       * delete this comment and run Flow. */
      return <Fallback field={field} {...props} />;
  }
}
export default Field;
