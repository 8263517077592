/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import { VisuallyHidden } from "hw/ui/text";

import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  value: string;
  onChange: any;
  placeholder?: string;
  extendReadView?: () => void | {};
  extendEditView?: () => void | {};

  /*
   * Minimum width in edit mode.
   */
  minWidth?: number;
  initialIsEditing?: boolean;
  onClick?: (evt: any) => void;
  onMouseOver?: (evt: any) => void;
  onBlur?: (evt: any) => void;
  onKeyDown?: (evt: any) => void;
};

/*
 * This function has 2 modes, editing and reading. When reading the content
 * it's a normal div, but then when editing it, it's an input.
 */
export default function InlineEdit(props: Props) {
  const editView = React.useRef(null);
  const readView = React.useRef(null);

  const {
    value,
    onChange,
    placeholder = "Click to enter value",
    extendReadView = {},
    extendEditView = {},
    minWidth = 150,
    initialIsEditing = false,
    ...rest
  } = props;

  const initialEditingSet = React.useRef(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const [width, setWidth] = React.useState(0);

  const toEditMode = React.useCallback(() => {
    if (readView.current) {
      // @ts-expect-error refactor
      setWidth(Math.max(minWidth, readView.current.offsetWidth));
    }

    setIsEditing(true);
  }, [minWidth]);

  React.useEffect(() => {
    if (initialIsEditing && !initialEditingSet.current) {
      toEditMode();
      initialEditingSet.current = true;
    }
  }, [toEditMode, initialIsEditing]);

  /**
   * Style for read mode
   */
  const readViewStyle = useStyle(
    "read-view-inline-edit",
    {
      wordBreak: "break-word",
      paddingLeft: theme.space.xs,
      paddingRight: theme.space.xs,
      paddingTop: theme.space.sm,
      paddingBottom: theme.space.sm,
      color: isEmpty(value)
        ? theme.color.textPlaceholder
        : theme.color.textStandard,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.color.transparent,
      backgroundColor: theme.color.transparent,
      minHeight: "34px", // This is needed for when a user enters " "
      textAlign: "start",
      cursor: "text",
      whiteSpace: "pre",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.color.dividerDefault,
      },
    },
    extendReadView
  );

  /**
   * Style for edit mode
   */
  const editViewStyle = useStyle(
    "edit-view-inline-edit",
    {
      paddingLeft: theme.space.xs,
      paddingRight: theme.space.xs,
      paddingTop: theme.space.sm,
      paddingBottom: theme.space.sm,
      width: `${width}px`,
      whiteSpace: "pre",
      wordBreak: "break-word",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.color.transparent,
      "&:focus": {
        outlineOffset: 0,
      },
    },
    extendEditView
  );

  if (isEditing) {
    return (
      <>
        <VisuallyHidden>
          <label htmlFor="form-name-input">Form Name</label>
        </VisuallyHidden>
        <input
          {...rest}
          id="form-name-input"
          ref={editView}
          className={editViewStyle}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={callAllHandlers(props.onBlur, (_evt: any) =>
            setIsEditing(false)
          )}
          autoFocus
          onKeyDown={callAllHandlers(props.onKeyDown, (e: any) => {
            // This is a not exhaustive list of keys that we would want to capture
            if (e.key === "Enter" || e.key === "Esc" || e.key === "Escape") {
              setIsEditing(false);
            }
          })}
        />
      </>
    );
  } else {
    return (
      <button
        {...rest}
        ref={readView}
        className={readViewStyle}
        onClick={callAllHandlers(props.onClick, toEditMode)}
        aria-label="edit"
      >
        {value || placeholder}
      </button>
    );
  }
}

function callAllHandlers(...fns: any) {
  return (evt: any, ...args: any) => {
    return fns.some((fn: any) => {
      if (fn) {
        fn(evt, ...args);
      }

      return evt.defaultPrevented;
    });
  };
}
