import * as React from "react";
import theme from "hw/ui/theme";
import { useBuilderContext } from "../context";
import { displayLoadingScreen } from "../form-builder/pdf-analyzer/pdf-analyzer";
import styles from "./pdf-overlay.module.css";

export function PdfOverlay() {
  // NOTE: I'm storing the status instead of checking if it's running
  // or not, basically because I want to leave open the option to accelerate
  // the "scanning" UI when the job is ready. My CSS knowledge though is sparse
  // and this is something that needs to be worked on
  // Also, the scan duration is not really lasting what it should.
  // part of the CSS mysteries that I want to dig on deeper and second part
  // of why I'm passing --easing and --scan-duration as variables
  const { job } = useBuilderContext();

  if (displayLoadingScreen(job?.status)) {
    return (
      <div
        style={{
          // @ts-expect-error refactor
          "--easing": "cubic-bezier(.4,.6,.6,1)",
          "--scan-duration": "85000ms",
        }}
        className={styles.scanning}
      />
    );
  } else {
    return (
      <div
        data-testid="pdf-overlay"
        style={{
          backgroundColor: theme.color.white,
          opacity: 0.8,
          position: "absolute",
          zIndex: theme.layer.shade,
          width: "100%",
          height: "100%",
        }}
      />
    );
  }
}
