import type { NodeType, Node, MarkType } from "prosemirror-model";
import type { EditorState, Selection } from "prosemirror-state";
import schema from "./schema";

/**
 * Returns true if the given nodetype and node are the same
 */
export const equalNodeType = (nodeType: NodeType, node: Node) => {
  return (
    (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
    node.type === nodeType
  );
};

/**
 * Finds the parent node from the given selection that passes the given predicate
 */
export const findParentNode =
  (predicate: (node: Node) => boolean) => (selection: Selection) => {
    const { $from } = selection;
    for (let i = $from.depth; i > 0; i--) {
      const node = $from.node(i);
      if (predicate(node)) {
        return {
          pos: i > 0 ? $from.before(i) : 0,
          start: $from.start(i),
          node,
        };
      }
    }
  };

/**
 * Finds a parent node of a specific type
 */
export const findParentNodeOfType =
  (nodeType: NodeType) => (selection: Selection) => {
    return findParentNode((node) => equalNodeType(nodeType, node))(selection);
  };

export function rangeHasNodeMatchingQuery(
  doc: Node,
  from: number,
  to: number,
  query: (node: Node) => boolean
) {
  let found = false;
  doc.nodesBetween(from, to, (node) => {
    if (query(node)) {
      found = true;
    }
  });

  return found;
}

/**
 * Helper functions
 *
 */

export function isMarkActive(state: EditorState, type: MarkType) {
  const { from, $from, to, empty } = state.selection;
  if (empty) {
    return type.isInSet(state.storedMarks || $from.marks());
  } else {
    return state.doc.rangeHasMark(from, to, type);
  }
}

export function isListActive(
  state: EditorState<typeof schema>,
  type: NodeType
) {
  const { tr } = state;
  const { bulletList, orderedList } = state.schema.nodes;

  // @ts-expect-error refactor
  const listParent = findParentNodeOfType([bulletList, orderedList])(
    tr.selection
  );

  return !!listParent && listParent.node.type === type;
}

export function isBlockActive(
  state: EditorState<typeof schema>,
  nodeType: NodeType,
  attrs: Record<string, unknown>
) {
  if (nodeType.name === "bulletList" || nodeType.name === "orderedList") {
    const res = isListActive(state, nodeType);

    return res;
  }

  // @ts-expect-error refactor
  const { $from, to, node } = state.selection;
  if (node) return node.hasMarkup(nodeType, attrs);
  return to <= $from.end() && $from.parent.hasMarkup(nodeType, attrs);
}

/**
 * Returns the text value of the given Node. Can be used to determine if the
 * node is empty
 */
export function docEmpty(value: typeof Node) {
  const doc = schema.nodeFromJSON(value);

  if (doc.textContent !== "") return false;

  let isEmpty = true;

  // Merge field nodes don't register as text content, so we need to explicitly
  // look to see if any are present.
  doc.descendants((node) => {
    if (node.type === schema.nodes.data) {
      isEmpty = false;
      return false;
    }
  });

  return isEmpty;
}
