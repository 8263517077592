import { createComponent } from "hw/ui/style";
import { WordBreakTextParent } from "hw/ui/text";

export const Label = createComponent(
  function Label({ theme, disabled }) {
    return {
      display: "flex",
      alignItems: "center",
      lineHeight: "20px",
      color: theme.color.textStandard,
      cursor: disabled && "not-allowed",
      marginBottom: theme.space.xs,
    };
  },
  "label",
  ["onMouseDown", "onMouseUp", "onMouseEnter", "onMouseLeave"]
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Wrapper = createComponent(function RadioWrapper() {
  return {
    display: "inline-flex",
    alignItems: "flex-start",
  };
}, WordBreakTextParent);

export const Input = createComponent(
  function Input() {
    return {
      position: "absolute",
      opacity: 0,
      zIndex: -1,
      margin: 0,
    };
  },
  "input",
  Object.keys
);

export const Icon = createComponent(function Icon() {
  return {
    display: "flex",
    marginRight: "8px",
    "& svg": {
      borderRadius: "1px",
    },
  };
});
