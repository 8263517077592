import * as React from "react";
import min from "lodash/min";
import { Flex } from "hw/ui/blocks";
import type {
  MultilineField,
  Mapping,
  OutputField as OutputFieldType,
} from "hw/portal/modules/common/draft";
import { maxCharsInArea } from "hw/portal/modules/common/draft/utils";
import {
  HelpText,
  Label,
  Required,
  Placeholder,
  CharacterLimit,
} from "./common";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: MultilineField;
  formId: string;
  mapping: Mapping;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
  linkedOutputFields?: Array<OutputFieldType>;
  documentType?: "mapped" | "generated" | "pending";
};
export function MultiLineSettings(props: Props) {
  /**
   * Default values for linkedOutputFields and document type are used for
   * editor v1
   */
  const {
    defaultSettings,
    field,
    path,
    referenceSetting,
    editorDispatch,
    linkedOutputFields = [],
    documentType = "generated",
  } = props;
  const { label, required, helpText, placeholder, type, maxLength } = field;
  const settingsProps = {
    path,
    editorDispatch,
  };
  const ceiling = React.useMemo(() => {
    if (documentType === "generated") {
      return 500;
    }

    const maxChars = linkedOutputFields.map((of) =>
      maxCharsInArea({ ...of.dimensions, fontSize: of.fontSize })
    );
    const minSize = min(maxChars) || 500;
    return minSize > 500 ? 500 : minSize;
  }, [documentType, linkedOutputFields]);
  return (
    <Flex flexDirection="column">
      {defaultSettings}
      <Label
        {...settingsProps}
        label={label}
        fieldType={type}
        placeholder="Reason for leaving, Explain, ..."
      />
      <CharacterLimit
        {...settingsProps}
        value={maxLength}
        path={[...path, "maxLength"]}
        ceiling={ceiling}
      />
      <Required {...settingsProps} required={required} fieldType={type} />
      <Placeholder {...settingsProps} placeholder={placeholder} />
      <HelpText {...settingsProps} helpText={helpText} />
      {referenceSetting}
    </Flex>
  );
}
export default React.memo(MultiLineSettings);
