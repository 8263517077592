import type { UpdateParsedPayload } from "hw/portal/modules/draft-editor/state";
import {
  Actions as EditorActions,
  changeTypes,
} from "hw/portal/modules/draft-editor/state";

export const Actions = {
  ChangeFieldSetting: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.CHANGE_FIELD_SETTING,
    }),
  CreateField: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.CREATE_FIELD,
    }),
  DeleteField: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.DELETE_FIELD,
    }),
  DeleteMapping: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.DELETE_MAPPING,
    }),
  DuplicateField: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.DUPLICATE_FIELD,
    }),
  MoveField: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.MOVE_FIELD,
    }),
  SetMapping: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.SET_MAPPING,
    }),
  SetFormName: (payload: Partial<UpdateParsedPayload>) =>
    // @ts-expect-error refactor
    EditorActions.UpdateParsedSchema({
      ...payload,
      changeType: changeTypes.SET_FORM_NAME,
    }),
};
