import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function CaretLeftIcon(props: Props) {
  const { fill, ...rest } = props;
  return (
    <svg
      {...rest}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6925 3.80752C11.1025 3.39403 11.1025 2.72362 10.6925 2.31012C10.2824 1.89663 9.61759 1.89663 9.20754 2.31012L4.30754 7.2513C3.89749 7.6648 3.89749 8.3352 4.30754 8.7487L9.20754 13.6899C9.61759 14.1034 10.2824 14.1034 10.6925 13.6899C11.1025 13.2764 11.1025 12.606 10.6925 12.1925L6.53492 8L10.6925 3.80752Z"
        fill={fill}
      />
    </svg>
  );
}
