import React from "react";
import AddIcon from "hw/ui/icons/svg-icons/add-icon-16";
import SubstractIcon from "hw/ui/icons/svg-icons/substract-icon";
import IconWrapper from "hw/ui/icons";
import { ControlRuleContainer } from "./styled";

type Props = {
  visible: boolean;
  onFocus: () => void;
  onBlur: () => void;
  showRemoveBtn: boolean;
  addRule: () => void;
  removeRule: () => void;
};
export default function ControlRule(props: Props) {
  const { showRemoveBtn, addRule, removeRule, visible, onFocus, onBlur } =
    props;
  return (
    <ControlRuleContainer visible={visible} onFocus={onFocus} onBlur={onBlur}>
      {showRemoveBtn && (
        <IconWrapper onClick={removeRule} data-testid="remove-rule">
          <SubstractIcon />
        </IconWrapper>
      )}
      <IconWrapper onClick={addRule} data-testid="add-rule">
        <AddIcon />
      </IconWrapper>
    </ControlRuleContainer>
  );
}
