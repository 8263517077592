export const ROUTE = "/workflow/:workflowId/edit/";
export const DEFAULT_START_STEP_ID = "start_step";
export const DEFAULT_SIGNER_STEP_ID = "signer_step";
export const NEW_GUID = "new";
export const NEW_WORKFLOW_NAME = "Untitled Form";
export const NEW_FORM_NAME = "Untitled Form";
export const Tabs = {
  Build: {
    name: "build",
    schemaStateType: "parsed",
  },
  Code: {
    name: "code",
    schemaStateType: "raw",
  },
  Map: {
    name: "map",
    schemaStateType: "parsed",
  },
  LivePreview: {
    name: "live-preview",
    schemaStateType: "parsed",
  },
} as const;
export const DEFAULT_TAB = Tabs.Build;
export const NEW_FORM = "new";

/**
 * TODO: This state machine represents the current implementation, but not the
 * ideal implementation.  There are some invalid states that can be reached.
 *
 *   For example:
 *    We are not blocking saving when launching or publishing so we can get a
 *    save_success while publishing, for example.
 */
export const STATUS_MACHINE = {
  default: {
    SAVE: "saving",
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
  },
  saveSuccess: {
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  publishError: {
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  saveError: {
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  publishing: {
    PUBLISH_ERROR: "publishError",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  savingAsTemplate: {
    SAVE_AS_TEMPLATE_ERROR: "saveAsTemplateError",
    SAVE_AS_TEMPLATE_SUCCESS: "saveAsTemplateSuccess",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  saveAsTemplateError: {
    SAVE_AS_TEMPLATE: "savingAsTemplate",
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  saveAsTemplateSuccess: {
    SAVE_AS_TEMPLATE: "savingAsTemplate",
    SAVE: "saving",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    PUBLISH: "publishing",
    LAUNCH: "launching",
  },
  saving: {
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  launching: {
    LAUNCH_SUCCESS: "launchSuccess",
    LAUNCH_ERROR: "launchError",
    LAUNCH_ERROR_RATE_LIMITED: "launchErrorRateLimited",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    SAVE: "saving",
  },
  launchSuccess: {
    SAVE: "saving",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
  },
  launchError: {
    SAVE: "saving",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
  },
  launchErrorRateLimited: {
    SAVE: "saving",
    SAVE_ERROR: "saveError",
    SAVE_SUCCESS: "saveSuccess",
    PUBLISH: "publishing",
    LAUNCH: "launching",
    SAVE_AS_TEMPLATE: "savingAsTemplate",
  },
};
export const FieldTypes = {
  TextInput: "TextInput",
  MultipleChoice: "MultipleChoice",
  Paragraph: "Paragraph",
  PhoneNumber: "PhoneNumber",
  Signature: "Signature",
  SSN: "SSN",
  Address: "AddressGroup",
  FileAttachment: "FileAttachment",
  Group: "Group",
  DateInput: "DateInput",
  Multiline: "Multiline",
  Calculation: "Calculation",
} as const;
export const PrebuiltFieldTypes = {
  EmailAddress: "EmailAddress",
};
