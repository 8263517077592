/* eslint-disable no-console */

/**
 * Browser Logger
 *
 * This is a browser-specific handler that's implemented on top of our shared
 * logger.  It provides some additional functionality like namespaced debugging
 * and reporting errors to Sentry.
 *
 * There is a simple namespacing functionality for formatting error messages
 * for specific parts of the app.  This is helpful for reducing noise in the
 * console if you'd like to filter out certain messages.
 *
 * @example
 * // Use the default logger to log an error
 * import logger from 'path/to/logger';
 *
 * // In QA/Staging/Prod environments, this is sent to Sentry
 * logger.error(new Error('boom'));
 *
 * @example
 * // Use a namespaced logger to debug a complex function
 * import logger from 'path/to/logger';
 *
 * const logger = logger.create('transpiler');
 *
 * class Transpiler {
 *      constructor() {
 *          logger.debug('Initializing...');
 *      }
 *      //...
 * }
 */
// @ts-expect-error will be refactored
import { logger as sharedLogger, Levels } from "./logger";
import createSentryCapturer from "./create-sentry-capturer";

export { Levels, sharedLogger as logger };

const noop = () => {};
let namespaceCount = 0;

/**
 * Colors used when namespacing the logging output.
 */
const COLORS = [
  "lightseagreen",
  "forestgreen",
  "goldenrod",
  "dodgerblue",
  "darkorchid",
  "crimson",
];

/**
 * Set up the global logger with the browser logger
 */
export const init = () => {
  sharedLogger.setLevel(getLogLevel());
  sharedLogger.init(createBrowserHandler);
};

init();

export default sharedLogger;

/**
 * This is here to keep parity with the existing api
 */
export function createLogger(namespace?: string) {
  return sharedLogger.create(namespace);
}

/**
 * Creates a logger under a namespace.  Unless using the default namespace, the
 * namespace will be prepended to the given console function msg.
 */
export function createBrowserHandler(options = {}) {
  return {
    log: wrapConsoleFn(Levels.log, options),
    warn: wrapConsoleFn(Levels.warn, options),
    debug: wrapConsoleFn(Levels.debug, options),
    error: wrapConsoleFn(Levels.error, options),
    info: wrapConsoleFn(Levels.info, options),
  };
}

/**
 * Returns true if we should capture messages and send to Sentry for the given
 * level.
 */
function shouldCaptureForLevel(level: string) {
  const { error, info, warn } = Levels;

  return level === error || level === info || level === warn;
}

/**
 * For now, this just returns the default level of the logger which is `debug`
 * for dev and `error` for prod.  At some point we could add a mechanism for
 * reading the log level from local storage.
 */
function getLogLevel() {
  return sharedLogger.level;
}

/**
 * Wraps the given console level function with the following additional
 * functionality:
 *
 * - Adding a prepended format string for namespaced logs
 * - Capturing the given message and sending to sentry
 *
 * @param {String} namespace The namespace for the logging messages
 * @param {Enum} level The level of the logging message.
 * @return {Function} The logging function.
 */

function wrapConsoleFn(level: string, options: { namespace?: string }) {
  const { namespace } = options;

  // @ts-expect-error refactor
  if (typeof console !== "undefined" && typeof console[level] === "function") {
    const loggerFn = sharedLogger.shouldLogForLevel(level)
      ? getConsoleFn(console, level, namespace)
      : noop;

    if (shouldCaptureForLevel(level)) {
      return createSentryCapturer(loggerFn, level);
    } else {
      return loggerFn;
    }
  } else {
    return noop;
  }
}

/**
 * Returns the console function for the given level.  If using a namespace other
 * than the global namespace, it will be prepended in front of the message.
 *
 * NOTE:  It's important to return the bound console function directly to
 * preserve line numbers.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getConsoleFn(console: any, level: any, namespace: any) {
  if (!namespace) {
    return console[level].bind(console);
  } else {
    const nextColor = cycleColors();

    return console[level].bind(
      console,
      `%c${namespace}: `,
      `color: ${nextColor}`
    );
  }
}

/**
 * Cycles the color value based on the namespace count.
 */
function cycleColors() {
  const nextColor = COLORS[namespaceCount % COLORS.length];

  namespaceCount += 1;

  return nextColor;
}
