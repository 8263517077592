import * as React from "react";
import { MoreVerticalIcon } from "hw/ui/icons";
import { FormattedMessage } from "react-intl";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import { Dropdown, IconTrigger } from "hw/ui/dropdown";
import Modal, { ACTION_TYPE } from "hw/ui/modal";
import { TextBody1 } from "hw/ui/text";

type Props = {
  menuItems: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (...args: Array<any>) => any;
    "data-guide-id"?: string;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (...args: Array<any>) => any;
  canDelete: boolean;
};
type State = {
  modalOpen: boolean;
};
export default class FormHeaderMenu extends React.PureComponent<Props, State> {
  static defaultProps = {
    menuItems: [],
  };

  state = {
    modalOpen: false,
  };

  handleModalOpen = () =>
    this.setState({
      modalOpen: true,
    });

  handleModalClose = () =>
    this.setState({
      modalOpen: false,
    });

  handleDeleteForm = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
      this.setState({
        modalOpen: false,
      });
    }
  };

  render() {
    const { menuItems, canDelete } = this.props;
    const { modalOpen } = this.state;
    const options = canDelete
      ? [
          {
            text: "Delete this form",
            ...getTestAttributes("editor-form-delete"),
            onClick: this.handleModalOpen,
          },
          ...menuItems,
        ]
      : menuItems;
    return (
      <React.Fragment>
        {options && Boolean(options.length) && (
          <Dropdown
            data-testid="form-editor-menu"
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ render: ({ getItemProps, highlightedIndex ... Remove this comment to see the full error message
            onChange={(item) => item.onClick && item.onClick()}
            Trigger={IconTrigger}
            triggerProps={{
              Icon: MoreVerticalIcon,
            }}
            justify="right"
            {...getTestAttributes("editor-form-settings-dropdown")}
            render={({ getItemProps, highlightedIndex }) => (
              <MenuList>
                {options.map((item, index) => (
                  <MenuItem
                    key={index}
                    active={index === highlightedIndex}
                    passthroughProps={getItemProps({
                      item,
                    })}
                    {...getTestAttributes(item["data-guide-id"])}
                  >
                    <MenuItemText>{item.text}</MenuItemText>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          />
        )}
        {modalOpen && (
          <Modal
            heading="Are you sure you want to delete this form?"
            onClose={this.handleModalClose}
            primaryAction={{
              text: "Delete",
              onClick: this.handleDeleteForm,
              type: ACTION_TYPE.DANGER,
            }}
            secondaryAction={{
              text: "Cancel",
              onClick: this.handleModalClose,
            }}
          >
            <TextBody1>
              <FormattedMessage
                id="formEditor.menu.deleteConfirmationCopy"
                defaultMessage="This will delete all components
                                within the form as well as any PDF mapped to this form."
              />
            </TextBody1>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
