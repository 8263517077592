export const FieldLabels = {
  TextInput: "Text Input",
  MultipleChoice: "Multiple Choice",
  PhoneNumber: "Telephone Number",
  SSN: "Social Security Number",
  FileAttachment: "Attachment",
  Address: "Address",
  Group: "Condition",
  DateInput: "Date",
  EmailAddress: "Email Address",
  Multiline: "Multi-line Input",
  Calculation: "Total",
} as const;
