import React from "react";

export default function DangerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#DD1D1D"
          d="M8.864 2.497l5.245 8.997a1 1 0 0 1-.864 1.504H2.755a1 1 0 0 1-.864-1.504l5.245-8.997a1 1 0 0 1 1.728 0z"
        />
        <path
          fill="#FFF"
          d="M8 9.13a.73.73 0 0 1-.737-.7l-.188-3.137a.921.921 0 0 1 .912-.962c.525 0 .95.425.925.962l-.175 3.138a.74.74 0 0 1-.737.7zm-1 1.75c0-.55.45-1.012 1-1.012s1 .463 1 1.013c0 .537-.45 1-1 1s-1-.463-1-1z"
        />
      </g>
    </svg>
  );
}
