import debounce from "lodash/debounce";
import * as React from "react";
import Task from "hw/common/utils/task";
import {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  useMemo,
} from "react";
import type { Dispatch } from "redux";
import { Provider, useSelector, useDispatch } from "react-redux";
import { VisuallyHidden } from "hw/ui/text";
// eslint-disable-next-line
import Portal from "hw/ui/portal";
import theme from "hw/ui/theme";
import { useLatest } from "hw/common/hooks";
import { scrollIntoView } from "hw/common/dom/scroll-into-view";
import {
  init,
  isDragging,
  pageSelector,
  externalSelector,
  createCollisionsSelector,
  createFieldSelector,
  createResizingFieldSelector,
  createDraggingFieldSelector,
  createSelectReducedHintState,
} from "./state";
import {
  attrs,
  bindStateEvents,
  queryField,
  setCursorForState,
  ns,
  getMostVisiblePageRect,
} from "./dom";
import { AutoScroller } from "./auto-scroller";
import { getCollisions, resize, Intersection } from "./utils";
import type {
  Evt,
  ResizeShorthand,
  ResizeOrigin,
  Result,
  State,
} from "./types";
import * as Types from "./types";
import { useHover } from "./use-hover";

/**
 * Some API operations depend on the DOM of the scroll container
 */
const ScrollContainerContext = React.createContext<
  React.MutableRefObject<HTMLElement | null>
>({
  current: null,
});

/**
 * -- External components --
 */

/**
 * Top-level provider. Provides store and context for children
 */
export function DocumentMapperProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const store = useMemo(() => init(), []);
  const ref = useRef(null);
  return (
    <Provider store={store}>
      <ScrollContainerContext.Provider value={ref}>
        {children}
      </ScrollContainerContext.Provider>
    </Provider>
  );
}

type DocumentMapperProps = {
  autoScroll: boolean;
  autoScrollClassName?: string;
  children: React.ReactNode;
  "data-testid": string;
  initialScale?: number;
  onMove: (result: Result) => void;
  onResize: (result: Result) => void;
  onDelete: (id: string) => void;
};

/**
 * Top-level React interface. This component binds the dom events, handles
 * callbacks, and renders any additional UI needed like collisions and
 * scroll containers
 */
export function DocumentMapper(props: DocumentMapperProps) {
  const scrollContainerRef = React.useContext(ScrollContainerContext);
  const {
    autoScroll,
    autoScrollClassName,
    children,
    "data-testid": dataTestId,
    initialScale,
  } = props;

  // TODO: Should this be a `suscribe` instead of selector?
  const state = useSelector<State, State>((state) => state);
  const dispatch = useDispatch();

  const onMove = useLatest(props.onMove);
  const onResize = useLatest(props.onResize);
  const onDelete = useLatest(props.onDelete);

  // Overload the `dispatch` function to both hit our internal reducer and
  // call out to the consumer callbacks for external events
  const send = useCallback(
    (evt: Evt) => {
      if (evt.type === "external.move") {
        onMove.current(evt.result);
      } else if (evt.type === "external.resize") {
        onResize.current(evt.result);
      } else if (evt.type === "external.delete") {
        onDelete.current(evt.result);
      }
      return dispatch(evt);
    },
    [dispatch, onDelete, onMove, onResize]
  );

  useLayoutEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(evt: Evt) => Evt' is not assign... Remove this comment to see the full error message
    return bindStateEvents(state, send);
  }, [state, send]);

  useLayoutEffect(() => {
    setCursorForState(state);
  }, [state]);

  useEffect(() => {
    if (initialScale) {
      send({
        type: "scale.changed",
        scale: initialScale,
      });
    }
  }, [initialScale, send]);

  return (
    <ScrollContainer
      data-testid={dataTestId}
      ref={scrollContainerRef}
      autoScroll={autoScroll}
      isAnyFieldDragging={isDragging(state)}
      className={autoScrollClassName}
    >
      {children}
    </ScrollContainer>
  );
}

type PageProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  height: number;
  number: number;
  style?: Record<string, unknown>;
  width: number;
  className?: string;
};

/**
 * Renders a single page in the document. This component assumes:
 *
 *  - Dimensions of the page are statically known and can be provided separately
 *  - The content of the document does not change the position of the fields
 *
 * Pages are rendered at the dimensions given. The _content_ of the page is
 * rendered as a sibling and the fields rendered absolutely on top, relative to
 * the page. When scaled, only the element containing the fields is scaled
 * which allows the content to be scaled by the consumer.
 */

export function Page(props: PageProps) {
  const {
    width,
    height,
    style: customStyle,
    children,
    content,
    number,
    ...rest
  } = props;
  const send: Dispatch<Evt> = useDispatch();
  const { scale } = useSelector(pageSelector);

  // Register the page when mounted
  useLayoutEffect(() => {
    send({
      type: "page.register",
      number,
      width,
      height,
    });

    return () => {
      send({ type: "page.unregister", number });
    };
  }, [number, width, height, send]);

  const style = useMemo(
    () => ({
      ...customStyle,
      position: "relative" as const,

      // Apply the width and height to the outer container
      width: width * scale,
      height: height * scale,
    }),
    [scale, width, height, customStyle]
  );

  return (
    <div {...rest} style={style} data-testid="page">
      {content}

      {/**
       * Create an inner element where the scale is applied. This allows the
       * fields to scale independenly of the `content` which may have its
       * own scaling mechanism (like `react-pdf`)
       */}
      <div
        {...attrs.forPage(number)}
        style={{
          width,
          height,
          position: "absolute",
          top: 0,
          left: 0,
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        }}
      >
        {children}
        <Collisions pageNumber={number} />
      </div>
    </div>
  );
}

type FieldProps = {
  /**
   * The height of the field
   */
  height: number;
  id: string;
  page: number;
  resizableOn: ResizeShorthand;
  width: number;
  x: number;
  y: number;
  children: React.ReactNode;
  resizable?: boolean;
  style?: Record<string, unknown>;
  handleClass?: string;
  lockAspectRatio?: boolean;
  onMouseEnter?: (evt: MouseEvent) => void;
  onMouseLeave?: (evt: MouseEvent) => void;
  className?: string;
};

/**
 * Renders and invididual field. When this component mounts, it will register
 * itself with the store for use in later dragging or resizing calculations.
 */
export function Field(props: FieldProps) {
  const {
    height,
    id,
    page,
    resizableOn,
    width,
    x,
    y,
    lockAspectRatio,
    resizable,
    ...rest
  } = props;
  const { value } = useFieldState(id);
  const send: Dispatch<Evt> = useDispatch();

  // Register / unregister the field when mounting so we can keep track of its
  // original unscaled coordinates and dimensions
  useLayoutEffect(() => {
    send({
      type: "field.register",
      id,
      x,
      y,
      width,
      height,
      page,
      lockAspectRatio,
    });

    return () => {
      send({ type: "field.unregister", id });
    };
  }, [id, x, y, width, height, page, lockAspectRatio, send]);

  const { onMouseEnter, onMouseLeave } = useHover({
    onHover: () => {
      send({
        type: "hover",
        id,
      });
    },
    onUnhover: () => {
      send({
        type: "unhover",
        id,
      });
    },
  });

  const baseFieldProps = {
    id,
    resizableOn,
    x,
    y,
    width,
    height,
    "data-state": value,
    onMouseEnter: callAll(onMouseEnter, props.onMouseEnter),
    onMouseLeave: callAll(onMouseLeave, props.onMouseLeave),
  };

  if (!resizable) {
    return <IdleField {...rest} {...baseFieldProps} />;
  }

  // Render a different component based on which state the field is currently
  // in
  switch (value) {
    case "dragging":
      return <DraggingField {...rest} {...baseFieldProps} />;
    case "resizing":
      return (
        <ResizingField
          {...rest}
          {...baseFieldProps}
          lockAspectRatio={lockAspectRatio ?? false}
        />
      );
    default:
      return <IdleField {...rest} {...baseFieldProps} />;
  }
}

/**
 * -- External hooks --
 */

/**
 * This is exported, but not currently exposed. It could be used in the
 * component list to subscribe to the state of the output field
 */
export function useFieldState(id: string) {
  const selector = useMemo(() => createFieldSelector(id), [id]);
  return useSelector(selector);
}

/**
 * This is a convenience hook for returning a single "hint" state for a set of
 * ids. It returns a finite set of states and has baked-in logic for determining
 * the correct state
 */
export function useReducedHintState(ids: string[]) {
  const selector = useMemo(() => createSelectReducedHintState(ids), [ids]);
  return useSelector(selector);
}

/**
 * The primary external interface to the document mapper
 */
type UseDocumentMapperState = {
  scale: Types.State["scale"];
  selected: Types.State["selected"];
  active: boolean;
};

type UseDocumentMapperApi = {
  deselect: () => void;
  select: (id: string) => void;
  scrollTo: (id: string) => Task<unknown, unknown>;
  getMostVisiblePageRect: () => Types.PageRect | null;
};
export function useDocumentMapper(): [
  UseDocumentMapperState,
  UseDocumentMapperApi
] {
  const scrollContainerRef = React.useContext(ScrollContainerContext);
  const send = useDispatch();

  // The external state we want to expose for the component
  const state = useSelector(externalSelector);
  const { scale } = state;

  const api = useMemo(
    () => ({
      deselect() {
        send({
          type: "selection.cleared",
        });
      },
      select(id: string) {
        send({
          type: "field.selected",
          id,
        });
      },
      scrollTo(id: string) {
        const scrollContainer = scrollContainerRef.current;
        const el = queryField(id);

        if (scrollContainer && el) {
          return scrollIntoView(scrollContainer, el);
        } else {
          return Task.reject();
        }
      },

      getMostVisiblePageRect() {
        const scrollContainer = scrollContainerRef.current;

        if (!scrollContainer) {
          return null;
        }

        return getMostVisiblePageRect(scrollContainer, scale);
      },
    }),
    [scrollContainerRef, send, scale]
  );

  return [state, api];
}

/**
 * -- Internal components --
 */

type BaseFieldProps = {
  children: React.ReactNode;
  handleClass?: string;
  height: number;
  id: string;
  resizable?: boolean;
  resizableOn: ResizeShorthand;
  style?: Record<string, unknown>;
  positionStyle?: Record<string, unknown>;
  width: number;
  x: number;
  y: number;
};

const BaseField = React.forwardRef<HTMLElement, BaseFieldProps>(
  function BaseField(props, ref) {
    const {
      x,
      y,
      width,
      height,
      children,
      id,
      style: customStyle,
      resizableOn,
      resizable = true,
      handleClass,
      positionStyle,
      ...rest
    } = props;

    const computedProperties = pickBy(customStyle, (key: string) =>
      key.startsWith("--")
    );

    const style = {
      // Applying computed properties at the top element allows consumers to
      // pass them in the `style` prop of the `Field` and have them available
      // in the resize handles, like `<Field style={{ --base: 'red' }} />`
      // The handles are siblings of the primary button, so custom properties
      // are not inherited if we only apply them there.
      // This avoids needing to have the consumer pass additional
      // `resizeHandleProps` or create a wrapping element to apply the style to.
      ...computedProperties,
      ...positionStyle,
      top: y,
      left: x,
      width,
      height,
      position: "absolute",
    };

    const btnStyle = {
      width: "100%",
      height: "100%",

      // Set the cursor based on the global custom property if it's been set,
      // otherwise fallback to `move`. Reference the custom property to handle
      // cases where the pointer slides off the button
      cursor: `var(--${ns("cursor")}, move)`,
      font: "inherit",
      ...customStyle,
    };

    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardedRef<HTMLElement>' is not assignable... Remove this comment to see the full error message
      <div ref={ref} style={style} {...attrs.forField(id)}>
        <button {...rest} style={btnStyle}>
          {children}
        </button>
        {resizable && (
          <Resizer id={id} resizableOn={resizableOn} className={handleClass} />
        )}
      </div>
    );
  }
);

function pickBy(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  obj: T = {},
  picker: (key: string) => boolean
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {};

  for (const key of Object.keys(obj)) {
    if (picker(key)) {
      result[key] = obj[key];
    }
  }

  return result;
}

type ResizerProps = {
  id: string;
  resizableOn: ResizeShorthand;
  className?: string;
};

function Resizer(props: ResizerProps) {
  const { resizableOn, id, ...rest } = props;
  const handles = toHandles(resizableOn).map((origin) => (
    <button
      {...rest}
      {...attrs.forHandle(id, origin)}
      key={origin}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; width: number; height: n... Remove this comment to see the full error message
      style={originStyle(origin)}
    >
      <VisuallyHidden>
        Resize field {id} from {origin}{" "}
      </VisuallyHidden>
    </button>
  ));

  return <>{handles}</>;
}

export function originStyle(origin: ResizeOrigin) {
  const baseStyle = {
    position: "absolute",
    width: 6,
    height: 6,
    borderRadius: "100%",
    padding: 0,
    background: "white",

    // The custom prop is not the greatest API, but should work for now.
    // Consumers can provide a `--base: blue` on a parent component to
    // adjust the border style
    border: `1px solid var(--base)`,
  };

  const merge = (s: Record<string, unknown>) => ({ ...baseStyle, ...s });

  switch (origin) {
    case "w":
      return merge({
        top: "50%",
        left: 0,
        transform: `translate(-50%, -50%)`,
        cursor: "col-resize",
      });
    case "e":
      return merge({
        top: "50%",
        right: 0,
        transform: `translate(50%, -50%)`,
        cursor: "col-resize",
      });
    case "n":
      return merge({
        top: 0,
        left: "50%",
        transform: `translate(-50%, -50%)`,
        cursor: "row-resize",
      });
    case "s":
      return merge({
        bottom: 0,
        left: "50%",
        transform: `translate(-50%, 50%)`,
        cursor: "row-resize",
      });
    case "nw":
      return merge({
        top: 0,
        left: 0,
        transform: `translate(-50%, -50%)`,
        cursor: "nw-resize",
      });
    case "sw":
      return merge({
        bottom: 0,
        left: 0,
        transform: `translate(-50%, 50%)`,
        cursor: "sw-resize",
      });
    case "ne":
      return merge({
        top: 0,
        right: 0,
        transform: `translate(50%, -50%)`,
        cursor: "ne-resize",
      });
    case "se":
      return merge({
        bottom: 0,
        right: 0,
        transform: `translate(50%, 50%)`,
        cursor: "se-resize",
      });
    default:
      return merge({});
  }
}

// These are ordered for tab index
function toHandles(shorthand: "x" | "y" | "xy" | "all"): ResizeOrigin[] {
  if (shorthand === "x") return ["w", "e"];
  if (shorthand === "y") return ["n", "s"];
  if (shorthand === "xy") return ["nw", "ne", "sw", "se"];
  if (shorthand === "all") return ["nw", "n", "ne", "w", "e", "sw", "s", "se"];
  return [];
}

type ResizingFieldProps = BaseFieldProps & {
  lockAspectRatio: boolean;
};

/**
 * The field as its actively resizing. Like the `DraggingField`, this one is
 * rendered in a `Portal` to avoid being clipped while resizing. When we
 * get the `boundingRect`, we have to translate to coordinates that our
 * absolute to the viewport
 */
function ResizingField(props: ResizingFieldProps) {
  const { id, x, y, width, height, lockAspectRatio, ...rest } = props;
  const [ref, rect] = useRectOnMount();
  const selector = useMemo(() => createResizingFieldSelector(id), [id]);
  const { dx, dy, sx, sy, origin, scale } = useSelector(selector);

  // We need to do one render pass to get the rect of the element initially
  if (!rect) {
    return (
      <BaseField
        {...rest}
        x={x}
        y={y}
        width={width}
        height={height}
        id={id}
        ref={ref}
      />
    );
  }

  // Translate the starting point of the mouse so the left and top edge of the
  // field so that it's a fluid transition into dragging. Without this, the
  // field would shift it's top left corner directly to where the pointer is.
  const absoluteX = sx - (sx - rect.left);
  const absoluteY = sy - (sy - rect.top);
  const startX = absoluteX + window.scrollX;
  const startY = absoluteY + window.scrollY;

  const resized = resize(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    {
      x: startX,
      y: startY,
      width,
      height,
      lockAspectRatio,
    },
    origin,
    dx / scale,
    dy / scale
  );

  return (
    <Portal>
      <BaseField
        {...rest}
        x={resized.x}
        y={resized.y}
        width={resized.width}
        height={resized.height}
        id={id}
        ref={ref}
        positionStyle={{
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        }}
      />
    </Portal>
  );
}

/**
 * The field as its actively dragging. This element renders in a `Portal`
 * to avoid being cut off by the scroll container. Because it's in a portal,
 * its coordinates need to be relative to the entire viewport instead of just
 * the page that contains it.
 */
function DraggingField(props: BaseFieldProps) {
  const { id, x, y, ...rest } = props;
  const [ref, rect] = useRectOnMount();
  const selector = useMemo(() => createDraggingFieldSelector(id), [id]);
  const { sx, sy, dx, dy, scale } = useSelector(selector);

  // We need to do one render pass to get the rect of the element initially
  if (!rect) {
    return (
      <BaseField {...rest} x={x} y={y} id={id} ref={ref} resizable={false} />
    );
  }

  // Translate the starting point of the mouse so the left and top edge of the
  // field so that it's a fluid transition into dragging. Without this, the
  // field would shift it's top left corner directly to where the pointer is.
  const absoluteX = sx - (sx - rect.left);
  const absoluteY = sy - (sy - rect.top);
  const startX = absoluteX + window.scrollX;
  const startY = absoluteY + window.scrollY;
  const transform = `translate(${dx}px, ${dy}px)  scale(${scale})`;

  return (
    <Portal>
      <BaseField
        {...rest}
        x={startX}
        y={startY}
        positionStyle={{
          transform,
          transformOrigin: "top left",
        }}
        id={id}
        ref={ref}
        resizable={false}
        // @ts-expect-error I think we can remove this?
        scale={scale}
      />
    </Portal>
  );
}

function IdleField(props: BaseFieldProps) {
  return <BaseField {...props} />;
}

function useRectOnMount(): [
  React.MutableRefObject<HTMLElement | null>,
  DOMRect | null
] {
  const ref = useRef<HTMLElement>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    setRect(rect);
  }, []);

  return [ref, rect];
}

/**
 * Visualizes the collisions for an individual page by drawing a red box around
 * overlaps between fields
 */
function Collisions(props: { pageNumber: number }) {
  const { pageNumber } = props;
  const selector = useMemo(
    () => createCollisionsSelector(pageNumber),
    [pageNumber]
  );
  const { idleFields } = useSelector(selector);
  const [collisions, setCollisions] = useState<Intersection[]>([]);

  useEffect(() => {
    if (idleFields.length === 0) {
      setCollisions([]);
    } else {
      const cancel = getCollisionsLazily(idleFields, setCollisions);

      return () => cancel();
    }
  }, [idleFields]);

  const result = collisions.map((coll, idx) => {
    return (
      <span
        key={idx}
        data-testid="collision"
        style={{
          position: "absolute",
          top: coll.y,
          left: coll.x,
          width: coll.width,
          height: coll.height,
          backgroundColor: theme.color.red100,
          border: `1px solid ${theme.color.red200}`,
          opacity: 0.5,
          pointerEvents: "none",
        }}
      />
    );
  });

  return <>{result}</>;
}

const isTest = process.env.NODE_ENV === "test";

/**
 * The collisions are computed lazily in the real, but when testing it ends
 * up throwing a lot of `act` warnings. Since we don't really need to be
 * concerned with the debounce when testing, this function will call the
 * given `cb` syncronously when testing
 */
function getCollisionsLazily(
  idleFields: Types.Field[],
  cb: (intersections: Intersection[]) => void
): () => void {
  if (isTest) {
    cb(getCollisions(idleFields));
    return () => {
      //
    };
  }

  const getCollisionsDebounced = debounce(() => {
    cb(getCollisions(idleFields));
  }, 350);

  const frame = requestAnimationFrame(getCollisionsDebounced);

  return () => {
    cancelAnimationFrame(frame);
    getCollisionsDebounced.cancel();
  };
}

type ScrollContainerProps = {
  isAnyFieldDragging: boolean;
  autoScroll: boolean;
  children: React.ReactNode;
  className?: string;
};

const ScrollContainer = React.forwardRef<HTMLElement, ScrollContainerProps>(
  function ScrollContainer(props, ref) {
    const { isAnyFieldDragging, autoScroll = true, ...rest } = props;

    return (
      <AutoScroller
        {...rest}
        enabled={autoScroll && isAnyFieldDragging}
        style={{ overflow: "auto", height: "100%" }}
        ref={ref}
      />
    );
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFn = (...args: any) => any;

function callAll(...fns: Array<AnyFn | void>) {
  return function callAll(...args: unknown[]) {
    for (const fn of fns) {
      if (typeof fn === "function") {
        fn(...args);
      }
    }
  };
}
