/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { Box, Flex, Text } from "hw/ui/blocks";
import Or from "hw/ui/or";
import Button from "hw/ui/button";
import { A } from "hw/ui/text";
import theme from "hw/ui/theme";
import Tooltip from "hw/ui/tooltip";
import Loader from "hw/ui/loader";
import { IDS } from "hw/portal/modules/analytics/heap";
import { brandName } from "hw/brand";
import { useBuilderContext } from "../../context";
import { JobStatus } from "../../constants";
import LoadingScreen from "./loading-screen";
import { useGetJob, useAnalyzer, useTeamUsage } from "./hooks";

type Props = {
  pagesLeft: number;
  numPages: number;
  startAnalysis: Function;
};

/**
 * Component that renders the PDF Analyzer prompt.
 * It calculates if it can be analyzed based on the team usage and number of pages
 * in the PDF, and renders the appropriate information depending on this result.
 */
function PdfAnalyzer(props: Props) {
  const { pagesLeft, numPages, startAnalysis } = props;
  const { job } = useBuilderContext();

  const status = getUsageStatus(pagesLeft, numPages);
  const pagesLeftCopy = pagesLeft === -1 ? "Unlimited" : pagesLeft;

  switch (status) {
    case UsageStatus.Available:
      return (
        <>
          <Flex alignItems="center" flexDirection="column" mb="lg">
            <Button
              // @ts-expect-error refactor
              onClick={startAnalysis}
              data-track-id={IDS.autoBuild.beginScan}
              extend={{ width: "100%" }}
            >
              Scan now
            </Button>
            <TinyText mt="md">
              {`Use ${numPages} of ${pagesLeftCopy} free page scans`}
            </TinyText>
          </Flex>
          {(job?.status === JobStatus.ErrorStarting ||
            job?.status === JobStatus.Error ||
            job?.status === JobStatus.FailedToStart) && (
            <Text
              color="red400"
              fontSize="sm"
              mb="ms"
              data-testid="job-status--error"
            >
              We're experiencing high demand right now. Please, try again in a
              little bit.
            </Text>
          )}
          {job?.status === JobStatus.CompletedWithErrors && (
            <Text
              color="red400"
              fontSize="sm"
              mb="ms"
              data-testid="job-status--completed-with-errors"
            >
              We experienced an error trying to analyze your document. Please,
              contact support@hellosign.com.
            </Text>
          )}
          {job?.status === JobStatus.Cancelled && (
            <Text
              color="red400"
              fontSize="sm"
              mb="ms"
              data-testid="job-status--cancelled"
            >
              Your analysis was cancelled. Please, try again or contact
              support@hellosign.com.
            </Text>
          )}
          <TinyText extend={{ lineHeight: "20px" }} mb="sm">
            Auto-build scans your PDF and identifies form fields and labels to
            generate your form.
          </TinyText>
          <TinyText extend={{ lineHeight: "20px" }} mb="ms">
            By opting-in, you agree that {brandName("hs")} and its third party
            vendors may store and use document and image inputs to provide the
            service and to improve and develop their services as further
            described in our{" "}
            <Link href="http://www.hellosign.com/privacy">Privacy Policy</Link>
            {" and "}
            <Link href="https://aws.amazon.com/textract/faqs/">
              Textract FAQ
            </Link>
            .
          </TinyText>
        </>
      );
    case UsageStatus.NotEnoughPages:
      return (
        <>
          <Flex alignItems="center" flexDirection="column" mb="lg">
            <Button disabled extend={{ width: "100%" }}>
              Scan now
            </Button>
            <TinyText mt="md">{`${pagesLeft} page scans available`}</TinyText>
          </Flex>
          <Text
            color="textStandard"
            fontSize="ms"
            extend={{ lineHeight: "20px" }}
          >
            There aren’t enough page scans remaining in this account to scan
            this document.{" "}
            <Link
              href="https://www.hellosign.com/info/hs-pricing"
              extend={{ fontSize: theme.fontSize.ms }}
            >
              Upgrade for additional page scans
            </Link>
            .
          </Text>
        </>
      );
    case UsageStatus.NoAvailablePages:
    default:
      return (
        <Text
          color="textStandard"
          fontSize="ms"
          extend={{ lineHeight: "20px" }}
        >
          There are no remaining page scans in this account.{" "}
          <Link
            href="https://www.hellosign.com/info/hs-pricing"
            extend={{ fontSize: theme.fontSize.ms }}
          >
            Upgrade for additional page scans
          </Link>
        </Text>
      );
  }
}

/**
 * Usage status
 * Available -> Analysis can be done (enough or unlimited available pages)
 * NotEnoughPages -> Analysis can't be done (not enough but > 0 available pages)
 * NoAvailablePages -> Analysis can't be done (no pages left)
 */
const UsageStatus = {
  Available: "Available",
  NotEnoughPages: "Not enough pages",
  NoAvailablePages: "No available pages",
};

// @ts-expect-error refactor
function getUsageStatus(pagesLeft, numPages) {
  if (pagesLeft && numPages) {
    if (pagesLeft === -1 || pagesLeft >= numPages) return UsageStatus.Available;
    else if (pagesLeft < numPages && pagesLeft > 0)
      return UsageStatus.NotEnoughPages;
  }
  return UsageStatus.NoAvailablePages;
}

export function displayLoadingScreen(
  status?: typeof JobStatus[keyof typeof JobStatus]
) {
  return (
    status === JobStatus.Running ||
    status === JobStatus.Completed ||
    status === JobStatus.Starting
  );
}

/**
 * Convenience wrapper function around text component
 */
// @ts-expect-error refactor
function TinyText(props) {
  return <Text color="textStandard" fontSize="sm" {...props} />;
}

/**
 * Wrapper function for links
 */
// @ts-expect-error refactor
function Link(props) {
  return (
    <A
      extend={{ fontSize: theme.fontSize.sm }}
      target="_blank"
      rel="noopener"
      {...props}
    />
  );
}

/**
 * Simple beta flag with a tooltip
 */
function Beta() {
  return (
    <Tooltip
      tip="This feature is still actively being tested and improved."
      position="right"
    >
      <TinyText
        borderWidth="1px"
        borderColor="gray400"
        borderStyle="solid"
        fontWeight="semibold"
        px="xs"
        extend={{ cursor: "pointer", lineHeight: "14px" }}
      >
        Beta
      </TinyText>
    </Tooltip>
  );
}

type PdfAnalyzerPendingProps = {
  formId: string;
  workflowGuid: string;
  participants: string;
  dispatch: Function;
  children: React.ReactNode;
  numPages: number;
};

/**
 * This is the function that adds the queries that need to be loaded
 * before going to the analyzer page.
 * This queries could potentially be moved upper in the directory so we
 * have this information when the draft is loaded without needing an extra
 * loading time
 */
function WithPendingGate(props: PdfAnalyzerPendingProps) {
  const { workflowGuid, formId, participants, dispatch, children, numPages } =
    props;
  const { setJob, job } = useBuilderContext();

  const [jobId, loadingJob] = useGetJob({
    formId: props.formId,
    workflowGuid: props.workflowGuid,
    onCompleted: setJob,
  });

  const [analyzeForm] = useAnalyzer({
    workflowGuid,
    formId,
    jobId,
    setJob,
    participants,
    dispatch,
  });

  const startAnalysis = React.useCallback(() => {
    // @ts-expect-error refactor
    analyzeForm();
    /* $FlowFixMe[prop-missing] $FlowFixMe This comment suppresses an error
     * found when upgrading Flow to v0.132.0. To view the error, delete this
     * comment and run Flow. */
    setJob((job) => ({
      // @ts-expect-error refactor
      ...job,
      status: JobStatus.Starting,
    }));
  }, [analyzeForm, setJob]);

  if (loadingJob) {
    return <Loader />;
  }

  if (job && displayLoadingScreen(job.status)) {
    return <LoadingScreen completed={job.status === JobStatus.Completed} />;
  }

  return (
    <WithUsageGate startAnalysis={startAnalysis} numPages={numPages}>
      {children}
    </WithUsageGate>
  );
}

type PdfAnalyzerUsageProps = {
  children: React.ReactNode;
  numPages: number;
  startAnalysis: Function;
};

function WithUsageGate(props: PdfAnalyzerUsageProps) {
  const { children, numPages, startAnalysis } = props;
  // @ts-expect-error refactor
  const [pagesLeft, { loading, error, authorized }] = useTeamUsage();
  if (loading) {
    return <Loader />;
  }

  const status = getUsageStatus(pagesLeft, numPages);

  if (status === UsageStatus.Available) {
    return (
      <Box mt="md">
        {authorized && !error && (
          <AutomaticallyBuildForm
            // @ts-expect-error refactor
            pagesLeft={pagesLeft}
            numPages={numPages}
            startAnalysis={startAnalysis}
          />
        )}
        <Box mb="xl">
          <Or offset={0.1} circleDiameter={32} />
        </Box>
        <ManuallyBuildForm authorized={authorized} error={error}>
          {children}
        </ManuallyBuildForm>
      </Box>
    );
  }

  return (
    <Box mt="md">
      <ManuallyBuildForm authorized={authorized} error={error}>
        {children}
      </ManuallyBuildForm>
      {authorized && !error && (
        <>
          <Box mb="xl">
            <Or offset={0.1} circleDiameter={32} />
          </Box>
          <AutomaticallyBuildForm
            // @ts-expect-error refactor
            pagesLeft={pagesLeft}
            numPages={numPages}
            startAnalysis={startAnalysis}
          />
        </>
      )}
    </Box>
  );
}

type ManuallyBuildFormProps = {
  authorized: boolean;
  error: boolean;
  children: React.ReactNode;
};

function ManuallyBuildForm(props: ManuallyBuildFormProps) {
  const { authorized, error, children } = props;
  return (
    <Box mx="md" mb="xxl">
      {authorized && !error && (
        <Text
          fontWeight="semibold"
          fontSize="md"
          color="textStandard"
          mb="ms"
          as="h2"
        >
          Manually build form
        </Text>
      )}
      {children}
    </Box>
  );
}

function AutomaticallyBuildForm(props: Props) {
  const { pagesLeft, numPages, startAnalysis } = props;
  return (
    <Box mx="md" mb="xl">
      <Flex alignItems="baseline">
        <Text
          fontWeight="semibold"
          fontSize="md"
          color="textStandard"
          mb="ms"
          mr="ms"
          as="h2"
        >
          Automatically build form
        </Text>
        <Beta />
      </Flex>
      <PdfAnalyzer
        pagesLeft={pagesLeft}
        numPages={numPages}
        startAnalysis={startAnalysis}
      />
    </Box>
  );
}

export default WithPendingGate;
