import { ARROW_HEIGHT } from "./components/styled";
import type {
  Position,
  Type,
  Justify,
  Align,
  RenderDest,
  Coords,
} from "./types";

type Config = {
  position: Position;
  type: Type;
  justify: Justify;
  align: Align;
  renderDest: RenderDest;
  usePreferredLayout: boolean;
  gutter?: number;
};

/**
 * Returns the coordinates and position that the hover card content should use
 * to correctly position itself around the target.  If the attempted layout is
 * offscreen, we'll try a number of alternative layouts.  If none of the alternative
 * layouts are on screen, we'll default to the given layout.
 */
export function resolve(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  config: Config
) {
  const { coords, config: adjustedConfig } = bestChoiceLayout(
    targetRect,
    hoverCardRect,
    config
  );

  const { left, top } = translateForRenderDest(targetRect, hoverCardRect, {
    config: adjustedConfig,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    coords,
  });

  return {
    coordinates: { left, top },
    position: adjustedConfig.position,
    justify: adjustedConfig.justify,
    align: adjustedConfig.align,
  };
}

/**
 * Iterates through the potential "flips" to determine which final configuration
 * should be used to place the hover card.  If there's no visible configuration,
 * we just return the original config.
 */
function bestChoiceLayout(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  originalConfig: Config
) {
  const preferredLayout = {
    coords: coordsByPosition(targetRect, hoverCardRect, originalConfig),
    config: originalConfig,
  };

  if (originalConfig.usePreferredLayout) {
    return preferredLayout;
  }

  const alternativeLayouts = [
    flip(originalConfig, "position"),
    flip(originalConfig, "align"),
    flip(originalConfig, "justify"),
  ];

  function nextBestLayout(
    targetRect: DOMRect,
    hoverCardRect: DOMRect,
    attempts: Config[]
  ): { config: Config; coords: Coords } | undefined {
    if (attempts.length) {
      const [config, ...rest] = attempts;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const coords = coordsByPosition(targetRect, hoverCardRect, config);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const attemptedPosition = coords[config.position];
      const viewportPosition = relativeToViewport(
        targetRect,
        hoverCardRect,
        attemptedPosition
      );

      if (isInViewport(viewportPosition)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { coords, config };
      } else {
        return nextBestLayout(targetRect, hoverCardRect, rest);
      }
    }
  }

  const attemptedPosition =
    preferredLayout.coords[preferredLayout.config.position];
  const viewportPosition = relativeToViewport(
    targetRect,
    hoverCardRect,
    attemptedPosition
  );

  if (isInViewport(viewportPosition)) {
    return preferredLayout;
  } else {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nextBestLayout(targetRect, hoverCardRect, alternativeLayouts) ||
      preferredLayout
    );
  }
}

function isInViewport({
  top,
  right,
  bottom,
  left,
}: {
  top: number;
  right: number;
  bottom: number;
  left: number;
}) {
  const docEl = document.documentElement;

  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || (docEl && docEl.clientHeight) || 0) &&
    right <= (window.innerWidth || (docEl && docEl.clientWidth) || 0)
  );
}

/**
 * This returns a map of coords for every position.  We'll use this to determine
 * which of these configurations is on screen use the `top` and `left`
 * properties to set the final coordinates.
 *
 * These coords are computed _relative_ to the target element.
 */
function coordsByPosition(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  { type, justify, align, gutter: gutterFromConfig }: Config
) {
  const gutter = gutterFromConfig ?? gutterForType(type);

  return {
    top: {
      top: -(hoverCardRect.height + gutter),
      left: leftJustification(targetRect, hoverCardRect, { justify }),
    },
    right: {
      top: topAlignment(targetRect, hoverCardRect, { align }),
      left: targetRect.width + gutter,
    },
    bottom: {
      top: targetRect.height + gutter,
      left: leftJustification(targetRect, hoverCardRect, { justify }),
    },
    left: {
      top: topAlignment(targetRect, hoverCardRect, { align }),
      left: -(hoverCardRect.width + gutter),
    },
  };
}

/**
 * Returns the `left` position value for the given `justify` setting
 */
function leftJustification(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  config: Pick<Config, "justify">
) {
  switch (config.justify) {
    case "right":
      // Target
      //    xxx
      //    xxx
      return -(hoverCardRect.width - targetRect.width);
    case "center":
      // Target
      //  xxx
      //  xxx
      return (targetRect.width - hoverCardRect.width) / 2;
    default:
      // Target
      // xxx
      // xxx
      return 0;
  }
}

function topAlignment(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  config: Pick<Config, "align">
) {
  switch (config.align) {
    case "center":
      //         xxx
      // Target  xxx
      //         xxx
      return (targetRect.height - hoverCardRect.height) / 2;
    case "bottom":
      //         xxx
      //         xxx
      // Target  xxx
      return targetRect.height - hoverCardRect.height;
    default:
      // Target  xxx
      //         xxx
      //         xxx
      return 0;
  }
}

function gutterForType(type: Type) {
  switch (type) {
    case "tooltip":
      return ARROW_HEIGHT;
    case "select":
      return 2;
    default:
      // NOTE: This should eventually be set to `0` but we need to be `2`
      // while we're refactoring the `Menu` component. Our menu buttons
      // currently maintain a 2px focus ring when clicked, so menus need to be
      // placed outside of that focus ring. Long-term, the proper behavior is
      // for the menu _itself_ to have focus when clicked, so there wouldn't
      // be a need to adjust the positioning around the menu button's focus
      // state. We can remove this after we've refactored all of our `Dropdown`
      // instances to `Menu`.
      return 2;
  }
}

const FLIPS = {
  align: {
    top: "bottom",
    center: "center",
    bottom: "top",
  },
  position: {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  },
  justify: {
    left: "right",
    center: "center",
    right: "left",
  },
};

function flip(config: Config, prop: keyof typeof FLIPS) {
  // Flow really likes switch statements...
  switch (prop) {
    case "position": {
      return {
        ...config,
        position: FLIPS.position[config.position],
      };
    }
    case "align": {
      return {
        ...config,
        align: FLIPS.align[config.align],
      };
    }
    case "justify": {
      return {
        ...config,
        justify: FLIPS.justify[config.justify],
      };
    }
    default:
      return config;
  }
}

/**
 * Takes a set of relative `top` and `left` coordinates and returns a full set of
 * coordinates that are absolute to the viewport.  These are used to determine
 * if an element will be visiable in the viewport.
 */
function relativeToViewport(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  coords: Coords
) {
  return {
    top: targetRect.top + coords.top,
    left: targetRect.left + coords.left,
    bottom: targetRect.top + coords.top + hoverCardRect.height,
    right: targetRect.left + coords.left + hoverCardRect.width,
  };
}

/**
 * If we're rendering within a portal, we need to first convert the relative
 * coordinates to absolute within the viewport and then we need to add in the
 * scroll values of the body.
 */
function translateForRenderDest(
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  layout: { config: Config; coords: Coords }
) {
  if (layout.config.renderDest === "portal") {
    const rect = relativeToViewport(
      targetRect,
      hoverCardRect,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      layout.coords[layout.config.position]
    );
    const { left, top } = ensureInBounds(
      rect,
      targetRect,
      hoverCardRect,
      layout
    );

    return {
      left: left + (window.scrollX || 0),
      top: top + (window.scrollY || 0),
    };
  } else {
    const { left, top } = ensureInBounds(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      layout.coords[layout.config.position],
      targetRect,
      hoverCardRect,
      layout
    );

    return {
      left,
      top,
    };
  }
}

/**
 * Attempts to nudge the final coordinates so that they are visible within the
 * viewport. This is somewhat simplisitic and does not handle every case. It
 * will only nudge vertically for `left` or `right` positions and horizontally
 * for `top` and `bottom` positions.
 */
function ensureInBounds(
  { left, top }: { left: number; top: number },
  targetRect: DOMRect,
  hoverCardRect: DOMRect,
  layout: { config: Config; coords: Coords }
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const coords = layout.coords[layout.config.position];
  const position = layout.config.position;
  const buffer = 8;

  const rect = {
    top: targetRect.top + coords.top,
    left: targetRect.left + coords.left,
    bottom: targetRect.top + coords.top + hoverCardRect.height,
    right: targetRect.left + coords.left + hoverCardRect.width,
  };

  if (position === "right" || position === "left") {
    const windowHeight = window.innerHeight;
    const fitsVertically = rect.bottom - rect.top <= windowHeight;
    const outOfBounds = rect.bottom > windowHeight || rect.top < 0;

    if (outOfBounds && fitsVertically) {
      const bottomOffset = Math.min(0, windowHeight - rect.bottom - buffer);
      const topOffset = Math.min(0, rect.top - buffer);
      top += bottomOffset - topOffset;
    }
  } else if (position === "bottom" || position === "top") {
    const windowWidth = window.innerWidth;
    const fitsHorizontally = rect.right - rect.left <= windowWidth;
    const outOfBounds = rect.right > windowWidth || rect.left < 0;

    if (outOfBounds && fitsHorizontally) {
      const rightOffset = Math.min(0, windowWidth - rect.right - buffer);
      const leftOffset = Math.min(0, rect.left - buffer);
      left += rightOffset - leftOffset;
    }
  }

  return { left, top };
}
