import React from "react";
import * as heap from "hw/portal/modules/analytics/heap";
import type { SettingsProps } from "../../../types";
import { Toggled } from "./basics";
import { Actions } from "../../../state";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer: (...args: Array<any>) => any;
  value: boolean;
  name: string;
  heapEventType?: heap.HeapEvent;
};
export default class Toggle extends React.PureComponent<Props> {
  static defaultProps = {
    fieldType: "unknown",
  };

  handleChange = (newValue: boolean) => {
    const { path, editorDispatch, fieldType, heapEventType, transformer } =
      this.props;

    if (heapEventType) {
      heap.track(heapEventType, {
        "Component Type": fieldType,
      });
    }

    editorDispatch(
      Actions.ChangeFieldSetting({
        path,
        updater: (field) => transformer(field, newValue),
      })
    );
  };

  render() {
    const { value, name } = this.props;
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'disabled' is missing in type '{ name: st... Remove this comment to see the full error message
    return <Toggled name={name} checked={value} onChange={this.handleChange} />;
  }
}
