/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import cx from "classnames";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import ErrorMessage from "hw/common/components/smartforms/common/error-message";
import Message from "hw/sf/components/common/message";
import Label from "./common/label";
import styles from "./date-input.module.css";

type Props = {
  errorMessage?: string; // Any validation errors
  handleVisited?: (...args: Array<any>) => any; // Used to track the field for validation
  helpText?: string; // The help bubble next to the label, if null there is no bubble
  hidden: boolean;
  id?: string; // Passed through to the masked input
  isValid?: boolean;
  label?: string; // Label attached to the input
  onChange?: (...args: Array<any>) => any;
  readOnly: boolean;
  required: boolean; // Is the input required
  value?: string;
  defaultValue?: string; // Used by inplace of value in Draft 2
};

export default class DateInput extends React.Component<Props> {
  static defaultProps = {
    hidden: false,
    readOnly: false,
    required: false,
  };

  /**
   * This function gets triggered in OnBlur, and handles the internal validation
   * of the input
   */
  handleBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { handleVisited, onChange, value } = this.props;

    const isValid = e.currentTarget.checkValidity();

    if (!isValid && onChange) {
      // The invalid internal state will be null. This will allow us to not
      // modify the input, and show an error message at the same time
      onChange(null);
    } else if (isValid && onChange) {
      const currentValue = e.currentTarget.value;
      if (value !== currentValue) {
        // The "" is just in case currentValue is null. It shouldn't happen,
        // but just in case since our validation relies on the value not being
        // null, and this could depend on the browser implementation
        onChange(currentValue || "");
      }
    }
    return handleVisited && handleVisited(e);
  };

  render() {
    const {
      label,
      helpText,
      required,
      errorMessage,
      hidden,
      id,
      value,
      defaultValue,
      readOnly,
      onChange,
      isValid,
    } = this.props;

    const labelComp = label && (
      <Label required={required} helpText={helpText} htmlFor={id}>
        {label}
      </Label>
    );

    const error = !isValid ? (
      <ErrorMessage>
        {typeof errorMessage === "object" ? (
          <Message {...errorMessage} />
        ) : (
          errorMessage
        )}
      </ErrorMessage>
    ) : null;

    const dateInputProps = {
      type: "date",
      id,
      autoComplete: "off",
      value,
      defaultValue,
      placeholder: readOnly ? undefined : "yyyy-mm-dd",
      onChange: onChange ? (e) => onChange(e.target.value) : null,
      onBlur: this.handleBlur,
      disabled: readOnly,
    };

    return (
      <div
        hidden={hidden}
        aria-hidden={hidden}
        className={cx(styles.container)}
      >
        {labelComp}
        <input
          {...dateInputProps}
          {...getTestAttributes("date-input")}
          className={cx(styles.input, !isValid && styles.invalid)}
        />
        {error}
      </div>
    );
  }
}
