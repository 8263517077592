export default {
  // Tracks the internal state for each row, reducing the complexity of things like tracking the server email errors.
  STATUS: {
    PENDING: "Pending",
    ERROR: "Error",
    ERROR_EMAIL: "Invalid email", // Happens only when the server throughs an error, not the same thing as the ui email validation.
    ERROR_RATE_LIMIT_MINUTE: "Rate limit for minute",
    TRANSACTIONS_BLOCKED: "Transactions have been blocked for this team",
    EXCEEDED_MAX_BULK_SEND_SIZE:
      "You can only send up to 100 transactions at once",
    ERROR_RATE_LIMIT_MONTH: "Rate limit for month",
    SUCCESS: "Success",
  },
  CSV_COMMENTS:
    "Note: Commas in the data are not supported and will result in incorrect mappings.",
  MAX_SEND: 100,
};
