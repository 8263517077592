export const MappingTypes = {
  Text: "Text",
  Checkmark: "Checkmark",
  Signature: "Signature",
  Multiline: "Multiline",
};

export const AddressFormats = {
  SingleLine: "single_line",
  TwoLines: "two_lines",
  ThreeLines: "three_lines",
  FourLines: "four_lines",
  SeparateFields: "separate_fields",
};

export const MultipleChoiceFormats = {
  Text: "text",
  Checkboxes: "checkboxes",
};

export const FontSize = 14;
