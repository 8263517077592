import getTestAttributes from "hw/common/utils/get-test-attributes";
import * as constants from "../../../../constants";
import * as Icons from "./icons";

/**
 * The add a component menu in portal
 *
 * {
 *   label: The text that shows in the menu
 *   value: What to add
 * }
 */
export const TextInput = {
  label: "Text Input",
  value: constants.FieldTypes.TextInput,
  Icon: Icons.TextInput,
  ...getTestAttributes("editor-build-new-input"),
};
export const MultipleChoice = {
  label: "Multiple Choice",
  value: constants.FieldTypes.MultipleChoice,
  Icon: Icons.MultipleChoice,
  ...getTestAttributes("editor-build-new-multiple"),
};
export const Paragraph = {
  label: "Display Text",
  value: constants.FieldTypes.Paragraph,
  Icon: Icons.Paragraph,
  ...getTestAttributes("editor-build-new-paragraph"),
};
export const PhoneNumber = {
  label: "Phone Number",
  value: constants.FieldTypes.PhoneNumber,
  Icon: Icons.PhoneNumber,
  ...getTestAttributes("editor-build-new-phone-number"),
};
export const Signature = {
  label: "Signature",
  value: constants.FieldTypes.Signature,
  Icon: Icons.Signature,
  ...getTestAttributes("editor-build-new-signature"),
};
export const SSN = {
  label: "Social Security Number",
  value: constants.FieldTypes.SSN,
  Icon: Icons.SSN,
  ...getTestAttributes("editor-build-new-ssn"),
};
export const Group = {
  label: "Conditional Rule",
  value: constants.FieldTypes.Group,
  Icon: Icons.ConditionalLogic,
  premium: true,
  ...getTestAttributes("editor-build-new-group"),
};
export const Address = {
  label: "Street Address",
  value: constants.FieldTypes.Address,
  Icon: Icons.Address,
  ...getTestAttributes("editor-build-new-address"),
};
export const FileAttachment = {
  label: "File Attachment",
  value: constants.FieldTypes.FileAttachment,
  Icon: Icons.Attachment,
  premium: true,
  ...getTestAttributes("editor-build-new-attachment"),
};
export const DateInput = {
  label: "Date",
  value: constants.FieldTypes.DateInput,
  Icon: Icons.DateComponent,
  ...getTestAttributes("editor-build-new-date-input"),
};
export const Email = {
  label: "Email Address",
  value: constants.PrebuiltFieldTypes.EmailAddress,
  Icon: Icons.Email,
  ...getTestAttributes("editor-build-new-email-address"),
};
export const Multiline = {
  label: "Multi-Line Text Input",
  value: constants.FieldTypes.Multiline,
  Icon: Icons.Multiline,
  ...getTestAttributes("editor-build-new-multi-line"),
};
export const Calculation = {
  label: "Calculation",
  value: constants.FieldTypes.Calculation,
  Icon: Icons.Calculation,
  premium: true,
  ...getTestAttributes("editor-build-new-calculation"),
};
