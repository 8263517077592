import * as React from "react";

export default function NewForm() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9C17 8.44772 16.5523 8 16 8C15.4477 8 15 8.44772 15 9V11H13C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13H15V15C15 15.5523 15.4477 16 16 16C16.5523 16 17 15.5523 17 15V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H17V9Z"
        fill="#959CA4"
      />
      <path
        d="M16 18V19H6V5H16V6"
        stroke="#959CA4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
