import isPlainObject from "lodash/isPlainObject";
import type { Expression as ExpressionT } from "hw/portal/modules/common/draft/types";
import { invariant } from "hw/common/utils/assert";
import * as constants from "./constants";
import type { Rule } from "./types";
/**
 * Takes an expression and parses into the necessary state for the component
 *
 * NOTE: If there is any abnormality, we will not support it
 * do not support macro or null method names (or any one not supported)
 */

export default function parseExpression(
  expression?: ExpressionT | string | boolean
): {
  rules: Array<Rule>;
  globalSelector: "All" | "Any";
} {
  // Assume that no value or an empty object means we should populate an
  // empty state for the expression builder
  if (!expression || isEmptyObject(expression)) {
    return {
      rules: [[""]],
      globalSelector: constants.DEFAULT_GLOBAL_SELECTOR,
    };
  }

  invariant(
    isExpr(expression) || typeof expression === "string",
    `Must be an expression. Received ${JSON.stringify(
      expression
    )} (${typeof expression})`
  );

  // Handle the recursive case of args being plain strings
  if (typeof expression === "string") {
    return {
      rules: [translateRule(expression)],
      globalSelector: constants.DEFAULT_GLOBAL_SELECTOR,
    };
  }

  // @ts-expect-error refactor
  const { args, methodName } = expression;
  invariant(
    methodName === constants.METHOD_ALL || methodName === constants.METHOD_ANY,
    `Method name must be "all" or "any". Received ${JSON.stringify(methodName)}`
  );
  const globalSelector =
    methodName === constants.METHOD_ANY
      ? constants.GLOBAL_SELECTOR_ANY
      : constants.GLOBAL_SELECTOR_ALL;
  if (args.length === 0)
    return {
      rules: [[""]],
      globalSelector,
    };
  const rules = args.map(translateRule);
  return {
    rules,
    globalSelector,
  };
}

function translateRule(expression: ExpressionT | string): Rule {
  if (typeof expression === "string") {
    return constants.EMPTY_RULE;
  }

  const { methodName, args } = expression;

  if (methodName === "val") {
    // This is an incomplete expression
    const subject = getSubjectFromExpression(expression);
    return subject ? [subject] : constants.EMPTY_RULE;
  } else if (methodName === "if") {
    // Each supported rule starts with an "if".
    const [innerExp, ifTrue, ifFalse] = args;
    invariant(
      isExpr(innerExp),
      `The first argument to a "if" expression should be a expression: Got "${JSON.stringify(
        innerExp
      )}"`
    );
    invariant(
      typeof ifTrue === "boolean",
      `The second argument to a "if" expression should be a boolean. Got: "${JSON.stringify(
        ifTrue
      )}"`
    );
    invariant(
      typeof ifFalse === "boolean",
      `The third argument to a "if" expression should be a boolean. Got: "${JSON.stringify(
        ifFalse
      )}"`
    );

    // @ts-expect-error refactor
    if (innerExp.methodName === "isEmpty") {
      const condition = ifTrue ? constants.IS_BLANK : constants.IS_FILLED;
      return getBasicRule({
        // @ts-expect-error refactor
        expression: innerExp,
        condition,
      });
      // @ts-expect-error refactor
    } else if (innerExp.methodName === "isOrIncludes") {
      const condition = ifTrue ? constants.IS : constants.IS_NOT;
      return getMultipleChoiceRule({
        // @ts-expect-error refactor
        expression: innerExp,
        condition,
      });
    }

    return constants.EMPTY_RULE;
  } else {
    throw new Error(
      `Unsupported expression configuration with method name "${methodName}".`
    );
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'expression' implicitly has an 'any' typ... Remove this comment to see the full error message
function getSubjectFromExpression(expression): string | null | undefined {
  const { args, methodName } = expression;

  if (methodName === "val") {
    const dataRef = args[0];
    invariant(
      typeof dataRef === "string",
      `The first argument to a "val" expression should be a data ref string.  Got "${JSON.stringify(
        dataRef
      )}"`
    );
    return dataRef;
  } else if (methodName === "isEmpty") {
    const valExpression = args[0];
    invariant(
      isExpr(valExpression) && valExpression.methodName === "val",
      `The first argument to an "isEmpty" expression should be a val expression: Got "${JSON.stringify(
        valExpression
      )}"`
    );
    return getSubjectFromExpression(valExpression);
  }
}

function getMultipleChoiceRule({
  expression,
  condition,
}: {
  expression: ExpressionT;
  condition: "is" | "is not";
}): Rule {
  const { args, methodName } = expression;
  invariant(
    methodName === "isOrIncludes",
    `The method name to a multiple choice condition needs to be "isOrIncludes". Got "${JSON.stringify(
      methodName
    )}"`
  );
  const [valExpression, option] = args;
  invariant(
    isExpr(valExpression),
    `The first argument to an "isOrIncludes" expression should be an expression.  Got "${JSON.stringify(
      valExpression
    )}"`
  );
  invariant(
    typeof option === "string" || option === null,
    `The second argument in an "isOrIncludes" expression should be a string.  Got "${JSON.stringify(
      option
    )}"`
  );
  const subject = getSubjectFromExpression(valExpression);
  invariant(
    typeof subject === "string",
    `The subject should be a string. Got "${JSON.stringify(subject || "")}"`
  );
  return [subject, condition, option];
}

function getBasicRule({
  expression,
  condition,
}: {
  expression: ExpressionT;
  condition: "is filled" | "is blank";
}): Rule {
  const { args, methodName } = expression;
  invariant(
    methodName === "isEmpty",
    `The method name to a basic condition needs to be "isEmpty". Got "${JSON.stringify(
      methodName
    )}"`
  );
  const [valExpression] = args;
  invariant(
    isExpr(valExpression),
    `The first argument to an "isEmpty" expression should be an expression.  Got "${JSON.stringify(
      valExpression
    )}"`
  );
  const subject = getSubjectFromExpression(valExpression);
  invariant(
    typeof subject === "string",
    `The subject should be a string. Got "${JSON.stringify(subject || "")}"`
  );
  return [subject, condition];
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
function isEmptyObject(obj) {
  /* $FlowFixMe[not-an-object] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  return isPlainObject(obj) && Object.keys(obj).length === 0;
}

/*
 * Returns true if the given object is an expression
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExpr(obj: any): boolean {
  return typeof obj === "object" && typeof obj.methodName === "string";
}
