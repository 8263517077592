import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { Box } from "hw/ui/blocks";
import IconWrapper, { CloseIcon } from "hw/ui/icons";
import type { Type } from "hw/ui/status-message";
import StatusMessage, { TYPES } from "hw/ui/status-message";
import theme from "hw/ui/theme";
import { Wrapper } from "./styled";
import { Extend } from "../style";

export type Props = {
  children: React.ReactNode;

  /**
   * This prop can be used as an escape hatch to extend certain styles for
   * one-off designs.  It should be used only in acknowledge one-off cases.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  extend?: Extend;

  /**
   * Type for the workflow. It can be success, warning or danger
   */
  type: Type;

  /**
   * Function triggered when clicking on the close icon.
   */

  onRemove?: () => void;

  /**
   * Defines whether the banner is shown or not. The onRemove function
   * should modify this prop if it want's to not show the banner
   */
  isOpen?: boolean;

  /**
   * @ignore
   * Idenitifier for testing
   */
  dataTestRef?: string;

  /**
   * @ignore
   * Idenitifier for testing
   */
  "data-testid"?: string;

  fontSize?: keyof typeof theme["fontSize"];
};

/**
 *
 * The `Banner` is a feedback message to convey short pieces of feedback after user actions.
 * It's composed of the [StatusMessage](/story/foundation-status-message--main) component, so it accepts the same `type` prop.
 *
 *
 */
function Banner(props: Props) {
  const { isOpen, onRemove, type, extend, dataTestRef, fontSize } = props;

  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: (... Remove this comment to see the full error message
    <Wrapper
      isOpen={isOpen}
      type={type}
      extend={extend}
      data-test-ref={dataTestRef}
      data-testid={props["data-testid"]}
      {...getTestAttributes("banner")}
    >
      <StatusMessage type={type} shouldColorText={false} fontSize={fontSize}>
        {props.children}
      </StatusMessage>

      {onRemove && (
        <Box mr="sm">
          <IconWrapper onClick={onRemove}>
            <CloseIcon />
          </IconWrapper>
        </Box>
      )}
    </Wrapper>
  );
}

Banner.defaultProps = {
  type: TYPES.SUCCESS,
  isOpen: true,
  "data-testid": "ui-banner",
};

export default Banner;
