import * as React from "react";

export default function Italic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="italic-icon-24-a"
          d="M10 6v1a1 1 0 0 0 1 1h1.215l-3.43 8H7a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1.215l3.43-8H17a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentColor" xlinkHref="#italic-icon-24-a" />
      </g>
    </svg>
  );
}
