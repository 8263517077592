import * as React from "react";
import { Transition } from "react-transition-group";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type CallbackProps = {
  captureRef: (node: HTMLElement | null | undefined) => void;
};

type Props = {
  /**
   * Indicates if the content should be expanded or not
   */
  active?: boolean;

  /**
   * If `true`, the transition will trigger on the initial render
   */
  appear?: boolean;

  /**
   * The children as a function expected to return the content to be animated
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (callbackProps: CallbackProps) => React.ReactElement<any>;
};

/**
 * This component transitions the height of arbitrary content by measuring the
 * height of the content and then transitioning the height to and from 0.
 * Overflow `hidden` is applied while transitioning to avoid overflowing content,
 * but is unset after the transition is done.
 *
 * ## Usage
 *
 * The `AnimateHeight` component is a small wrapper around the `Transition` component
 * from `react-transition-group` library.  It takes an `active` prop and expects the
 * children to be a function.  When rendering your content, you must passed the
 * provided `captureRef` function to the root content element.  This is needed so
 * that the height of the element can be computed.
 *
 * ```jsx
 * <AnimateHeight active={true}>
 *   {({ captureRef}) => (
 *     <div ref={captureRef>
 *       My content
 *     </div>
 *   )}
 * </AnimateHeight>
 * ```
 */
export default class AnimateHeight extends React.Component<Props> {
  content: HTMLElement | null | undefined;

  expander: HTMLElement | null | undefined;

  /**
   * Fired before the transition starts
   */
  handleEnter = (node: HTMLElement) => {
    node.style.overflow = "hidden";
  };

  /**
   * Fired when the content is entering the DOM. We need to get the height
   * of the content so we can apply to the expander for the animation
   */
  handleEntering = (node: HTMLElement) => {
    const contentHeight = this.content?.clientHeight ?? 0;
    node.style.height = `${contentHeight}px`;
  };

  /**
   * Fired after the content has animated in. Reset the styles so that the
   * content can flow naturally in the DOM
   */
  handleEntered = (node: HTMLElement) => {
    node.style.height = "auto";
    node.style.overflow = "unset";
  };

  /**
   * Fired before we start animating out. Grab the height of the content and
   * set it on the expander ref in preparation for animation out
   */
  handleExit = (node: HTMLElement) => {
    const contentHeight = this.content?.clientHeight ?? 0;
    node.style.height = `${contentHeight}px`;
    node.style.overflow = "hidden";
  };

  /**
   * Set the expander height to 0 to trigger the animation.
   *
   * NOTE: Needed to perform this in the next tick to get the transition to
   * trigger
   */
  handleExiting = (node: HTMLElement) => {
    setTimeout(() => {
      node.style.height = "0px";
    });
  };

  /**
   * Redundant styles since these should already be applied after `handleExit`
   * and `handleExiting`, but defining them here to have them in one place.
   * We also call this at the initial render if not active so that the
   * transition is rendered properly
   */
  handleExited = (node: HTMLElement) => {
    node.style.height = "0px";
    node.style.overflow = "hidden";
  };

  /**
   * Defined so that we can get fine-grained transitions rather than timeout-based
   * transitions
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEndListener = (node: HTMLElement, done: (...args: Array<any>) => any) => {
    node.addEventListener("transitionend", done, false);
  };

  captureRef = (node: HTMLElement | null | undefined) => {
    this.content = node;
  };

  expanderRef = (node: HTMLElement | null | undefined) => {
    this.expander = node;

    if (!node) return;

    // If not active when, apply the resting styles so
    if (!this.props.active) {
      this.handleExited(node);
    } else if (this.props.appear) {
      this.handleExited(node);
    }
  };

  render() {
    const { active, appear, ...rest } = this.props;

    return (
      <Transition
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        in={active}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        timeout={null}
        addEndListener={this.addEndListener}
        appear={appear}
      >
        {() => (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; ref: (node: HTMLElement | n... Remove this comment to see the full error message
          <Expander {...rest} ref={this.expanderRef}>
            {this.props.children({ captureRef: this.captureRef })}
          </Expander>
        )}
      </Transition>
    );
  }
}

const transitionStyles = {
  transitionProperty: "height",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: theme.transitionSpeed.fast,
};

const Expander = React.forwardRef(function Expander(props, ref) {
  const cn = useStyle(transitionStyles);

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <div {...props} className={cn} ref={ref} />;
});
