import type { ParsedSchema } from "hw/portal/modules/common/draft";
import { id } from "./build/mapping";

/* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
 * TS migration. */
type Migration = (schema: ParsedSchema) => ParsedSchema;

// NOTE: Order is important here. Migrations are assumed to be run cumulatively
// in order of how they are defined in this list, with each migration accepting
// the previously migrated schema.
const schemaMigrations = [ensurePdfMappingId];

/**
 * This is a migration function that takes the parsed schema and
 * ensures it's in the correct shape for the editor. Migrations defined here
 * are assumed to be _minor_ or _patch_ upgrades. They should be backwards
 * compatible and non-breaking
 *
 * TODO: Proper versioning of schemas. Because we don't have an explicit way
 * to check which 'version' the schema is in, we have to use property checks or
 * other heuristics to determine that information.
 */
export default function migrate(
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
  schema: ParsedSchema,
  migrations: Array<Migration> = schemaMigrations
  /* $FlowFixMe[value-as-type] $FlowIgnore - will be removed when we finish the
   * TS migration. */
): ParsedSchema {
  let migratedSchema = schema;

  for (const migration of migrations) {
    migratedSchema = migration(migratedSchema);
  }

  return migratedSchema;
}

/**
 * PDF mappings historically only have `label` properties that we've used
 * as an ID. The new editor specifically expects an `id` property, so in the
 * case that a schema is pulled up with mappings without ID's, we create and
 * add it here
 */
function ensurePdfMappingId(schema: ParsedSchema): ParsedSchema {
  return {
    ...schema,
    forms: schema.forms.map((form) => {
      if (!Array.isArray(form.pdfMap)) return form;

      return {
        ...form,
        pdfMap: form.pdfMap.map((mapping) => ({
          ...mapping,
          id: mapping.id || id(),
        })),
      };
    }),
  };
}
