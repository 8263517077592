import * as React from "react";
import { createComponent, useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

export const SubtleLink = createComponent(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'theme' does not exist on type '{}'.
  function SubtleLink({ theme }) {
    return {
      textDecoration: "underline",
      color: theme.color.textStandard,
      ":hover": {
        textDecoration: "underline",
        color: theme.color.textStandard,
      },
      ":active": {
        textDecoration: "underline",
        color: theme.color.textStandard,
      },
    };
  },
  "a",
  Object.keys
);

/**
 * The base text link style
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function A(props: any) {
  const { extend, ...rest } = props;
  const cn = useStyle(
    "text-link",
    {
      textDecoration: "none",
      color: theme.color.blue600,
      fontSize: theme.fontSize.ms,
      cursor: "pointer",
      ":active": {
        color: theme.color.blue400,
        fontWeight: theme.fontWeight.semibold,
      },
      ":hover": {
        color: theme.color.blue400,
        textDecoration: "underline",
      },
    },
    extend
  );

  return <a className={cn} {...rest} />;
}
