import * as React from "react";

export default function Upload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          id="upload-icon-48-a"
          d="M25.5,9.62132034 L25.5,32 C25.5,32.8284271 24.8284271,33.5 24,33.5 C23.1715729,33.5 22.5,32.8284271 22.5,32 L22.5,9.62132034 L17.0606602,15.0606602 C16.4748737,15.6464466 15.5251263,15.6464466 14.9393398,15.0606602 C14.3535534,14.4748737 14.3535534,13.5251263 14.9393398,12.9393398 L22.9393398,4.93933983 C23.5251263,4.35355339 24.4748737,4.35355339 25.0606602,4.93933983 L33.0606602,12.9393398 C33.6464466,13.5251263 33.6464466,14.4748737 33.0606602,15.0606602 C32.4748737,15.6464466 31.5251263,15.6464466 30.9393398,15.0606602 L25.5,9.62132034 Z M6.5,22 C6.5,21.1715729 7.17157288,20.5 8,20.5 C8.82842712,20.5 9.5,21.1715729 9.5,22 L9.5,38 C9.5,39.3807119 10.6192881,40.5 12,40.5 L36,40.5 C37.3807119,40.5 38.5,39.3807119 38.5,38 L38.5,22 C38.5,21.1715729 39.1715729,20.5 40,20.5 C40.8284271,20.5 41.5,21.1715729 41.5,22 L41.5,38 C41.5,41.0375661 39.0375661,43.5 36,43.5 L12,43.5 C8.96243388,43.5 6.5,41.0375661 6.5,38 L6.5,22 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#959CA4" fillRule="nonzero" xlinkHref="#upload-icon-48-a" />
      </g>
    </svg>
  );
}
