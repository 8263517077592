import type { Tab } from "./types";

export const ROUTE = "/workflow/:workflowId/edit/";

type TabMap = {
  Build: Tab;
  LivePreview: Tab;
  ReviewPublish: Tab;
  CodeView: Tab;
};

export const Tabs: TabMap = {
  Build: {
    name: "build",
    displayMode: "form",
  },
  LivePreview: {
    name: "live-preview",
    displayMode: "form",
  },
  ReviewPublish: {
    name: "review-publish",
    displayMode: "workflow",
  },
  CodeView: {
    name: "code",
    displayMode: "workflow",
  },
};

export const DEFAULT_TAB = Tabs.Build;
export const NEW_GUID = "new";
export const NEW_FORM = "new";
export const NEW_WORKFLOW_NAME = "Untitled Workflow";
export const NEW_FORM_NAME = "Untitled Form";
export const NEW_ROLE_NAME = "Unnamed Role";

// For all intents and purposes, INITIAL_FORM_NAME
// will be the initialized, substantive form name, as if we've
// named the form for the user, until they decide to rename it.
// This is in contrast to NEW_FORM_NAME which is
// the uninitialized, unnamed version of a form name.
// If for some reason INITIAL_FORM_NAME cannot be applied,
// form name can fallback to NEW_FORM_NAME
export const INITIAL_FORM_NAME = "Form 1";
