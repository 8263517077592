import * as React from "react";

/**
 * TODO: Change this icon for the proper 14px icon
 */
function ToggleCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FFF"
        d="M4.7229622,8.21429499 C4.34139553,7.81501399 3.70839342,7.80065369 3.30911242,8.18222036 C2.90983142,8.56378704 2.89547112,9.19678915 3.2770378,9.59607015 L6.23455192,12.6908876 C6.68422091,13.1614326 7.45643333,13.0838332 7.80348707,12.5332258 L12.8459729,4.53322583 C13.1404654,4.06600787 13.0004438,3.44851955 12.5332258,3.15402706 C12.0660079,2.85953457 11.4485196,2.99955622 11.1540271,3.46677417 L6.79497193,10.3824983 L4.7229622,8.21429499 Z"
      />
    </svg>
  );
}

export default ToggleCheck;
