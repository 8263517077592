export const styleBySize = {
  small: {
    height: "24px",
    padding: "sm",
    fontSize: "ms",
  },
  base: {
    height: "32px",
    padding: "ms",
    fontSize: "ms",
  },
  large: {
    height: "40px",
    padding: "md",
    fontSize: "md",
  },
};

export const styleByState = {
  default: {
    backgroundColor: "gray100",
    color: "textStandard",
    borderWidth: "1px",
    corner: "sm",
  },
  primary: {
    backgroundColor: {
      default: "__buttonPrimaryBg",
      hover: "__buttonPrimaryBgHover",
      active: "__buttonPrimaryBgPressed",
    },
    color: {
      default: "white",
    },
    corner: {
      default: "__buttonPrimary",
    },
    boxShadow: {
      hover: "none",
    },
  },
  primaryOutline: {
    backgroundColor: {
      default: "__buttonPrimaryOutlineBg",
      hover: "__buttonPrimaryOutlineBgHover",
      active: "__buttonPrimaryOutlineBgPressed",
    },
    borderStyle: {
      default: "solid",
    },
    borderColor: {
      default: "__buttonPrimaryOutlineBorderColor",
      hover: "__buttonPrimaryOutlineBorderHover",
      active: "__buttonPrimaryOutlineBorderPressed",
    },
    color: {
      default: "__buttonPrimaryOutlineColor",
      active: "__buttonPrimaryOutlineColorPressed",
    },
    corner: {
      default: "__buttonPrimary",
    },
    boxShadow: {
      hover: "none",
    },
  },
  standard: {
    backgroundColor: {
      default: "buttonBgStandard",
      hover: "buttonBgStandardHover",
      active: "buttonBgSubtlePressed",
    },
    color: {
      default: "textDark",
      active: "blue700",
    },
  },
  subtle: {
    backgroundColor: {
      default: "transparent",
      hover: "buttonBgSubtleHover",
      active: "buttonBgSubtlePressed",
    },
    color: {
      default: "textDim",
      active: "blue700",
    },
  },
  danger: {
    backgroundColor: {
      default: "red500",
      hover: "red400",
      active: "red600",
    },
    color: {
      default: "textInverse",
    },
  },

  link: {
    backgroundColor: {
      default: "transparent",
    },
    boxShadow: {
      hover: "none",
    },
    textDecoration: {
      hover: "underline",
      active: "none",
    },
    color: {
      default: "textAction",
      hover: "blue500",
      active: "blue700",
    },
  },
};
