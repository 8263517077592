import * as React from "react";
import { useStyle } from "hw/ui/style";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function VisuallyHidden(props: any) {
  const cn = useStyle("visually-hidden", {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: "1px",
  });

  return <span {...props} className={cn} />;
}
