import { invariant } from "./utils/assert";

const DOM_ID = "hw-env";

// This is the base environment that all apps should implement
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppEnv {
  brandName: string;
  logoLockupUrl: string;
  logoGlyph32Url: string;
  hsBrandName: string;
  hsAppFqdn: string;
}

let env: AppEnv;

/**
 * This function is used as a general interface for the runtime variables
 * that a client application will receive from the server.
 *
 *   1. The server is rendering the application variables within an
 *      `application/json` script tag:
 *      <script type="application/json">
 *        <%= raw Jason.encode!(%{})) %>
 *      </script>
 *   2. The server is providing a known set of base environment variables to
 *      the app, e.g. `brandName`
 *
 * @example
 * import { Env } from 'hw/common/env';
 *
 * // At the application boot
 * const env = Env.initFromDOM();
 *
 * // Can read directly from the return value in the application boot
 * env.other;
 * //=> "value"
 *
 * // In the application, read values from the getter
 * Env.get('brandName');
 * //=> "the brand name"
 */
export const Env = {
  /**
   * Initialize the environment from the DOM. This assumes that the server is
   * embedded the values as follows:
   *
   *   1. A `script` tag with an `application/json` type
   *   2. An `id` on the `script` tag of `hw-env`
   *
   * Should be called as early as possible within the client app.
   */
  initFromDOM(): AppEnv {
    return Env.init(readFromDOM());
  },

  /**
   * Initializes the environment with a set of values. This is mostly useful
   * for testing. Most apps should use the `initFromDOM` method above
   */
  init(data: unknown): AppEnv {
    env = parse(data);
    return env;
  },

  /**
   * Retrieve a value from the environment. This is the interface that the
   * client app should use to retrieve values from the environment.
   */
  get<Key extends keyof AppEnv>(key: Key): AppEnv[Key] {
    invariant(
      env,
      `Environment has not been initialized. Make sure to call 'Env.init()' or 'Env.initFromDOM()' before running the application.`
    );

    return env[key];
  },
};

function parse(data: unknown): AppEnv {
  invariant(data, `No environment data present`);
  invariant(
    typeof data === "object",
    "Expected environment data to be an object"
  );

  hasStringProp(data, "brandName");
  hasStringProp(data, "logoLockupUrl");
  hasStringProp(data, "logoGlyph32Url");
  hasStringProp(data, "hsBrandName");
  hasStringProp(data, "hsAppFqdn");

  return data;
}

function hasStringProp<K extends PropertyKey>(
  data: object,
  prop: K
): asserts data is Record<K, string> {
  hasProp(data, prop);
  isString(data, prop);
}

function hasProp<K extends PropertyKey>(
  data: object,
  prop: K
): asserts data is Record<K, unknown> {
  invariant(
    prop in data,
    `Expected '${String(prop)}' to be present but it was not`
  );
}

function isString<K extends PropertyKey>(
  data: Record<K, unknown>,
  prop: K
): asserts data is Record<K, string> {
  invariant(
    typeof data[prop] === "string",
    `Expected '${String(prop)}' to be a string`
  );
}

function readFromDOM() {
  const el = document.getElementById(DOM_ID);

  if (el) {
    return JSON.parse(el.innerHTML);
  } else {
    throw new Error("Could not find the server state element on the page");
  }
}
