/**
 * Authorization rules
 *
 * This is logic for determining which pieces of UI to show or hide in the portal
 * based on the current user's role. This logic is based on the
 * 'authorization matrix' that the backend uses, but does not cover every
 * layer of granularity. For example, because all users that can edit drafts
 * can also publish drafts, and users can only publish drafts from the editor,
 * there's no need to create a separate `draft:publish` permission here because
 * it would never be applied. We assume the backend has this level of granularity
 * applied, and that any frontend permission logic is cosmetic _only_.
 */
import React from "react";
import logger from "hw/common/utils/logger";
import { useUser } from "hw/portal/modules/user";

export const permissions = {
  CreateDraft: "create:draft",
  EditDraft: "edit:draft",
  ReadDraft: "read:draft",
  ReadKey: "read:key",
  ReadTeam: "read:team",
  CreateTemplate: "create:template",
  ReadTemplate: "read:template",
  CancelTransaction: "cancel:transaction",
  DeleteCompletedTransaction: "delete-completed:transaction",
  DeleteCanceledTransaction: "delete-canceled:transaction",
  DownloadSingleTransaction: "download-single:transaction",
  DownloadBulkTransaction: "download-bulk:transaction",
  ReadTransaction: "read:transaction",
  SendReminderForTransaction: "send-reminder-for:transaction",
  DeleteWorkflow: "delete:workflow",
  ReadWorkflow: "read:workflow",
  UpdateWorkflow: "update:workflow",
  ReadTeamBilling: "read:team_billing",
} as const;

export type Permission = typeof permissions[keyof typeof permissions];

/**
 * Default policies for the portal that define which permissions are associated
 * with a role
 */
const defaultPolicies = {
  admin: new Set(Object.values(permissions)),
  member: new Set([
    permissions.EditDraft,
    permissions.CreateDraft,
    permissions.ReadDraft,
    permissions.CreateTemplate,
    permissions.ReadTemplate,
    permissions.ReadTransaction,
    permissions.SendReminderForTransaction,
    permissions.DeleteCanceledTransaction,
    permissions.CancelTransaction,
    permissions.ReadWorkflow,
    permissions.UpdateWorkflow,
    permissions.DeleteWorkflow,
  ]),
  sender: new Set([
    permissions.ReadDraft,
    permissions.ReadTransaction,
    permissions.SendReminderForTransaction,
    permissions.DeleteCanceledTransaction,
    permissions.CancelTransaction,
    permissions.DownloadSingleTransaction,
    permissions.ReadWorkflow,
  ]),
};

type PolicyMap = Record<string, Set<Permission>>;

/**
 * Function that returns true if the current user has permissions for a
 * particular action.
 */
export function isAuthorizedTo(
  permission: Permission,
  userRole: string,

  /**
   * Passing this in for testing purposes
   */
  policies: PolicyMap = defaultPolicies
) {
  const policy = policies[userRole];

  if (!policy) {
    logger.debug(`Policy not found for role '${userRole}'`);
    return false;
  }

  return policy.has(permission);
}

/**
 * A hook for determining if a user is authorized for a particular action
 *
 * @example
 * function MyComponent() {
 *   const isAuthorized = useIsAuthorizedTo('read:draft')
 *   //...
 * }
 */
export function useIsAuthorizedTo(perm: Permission, policies?: PolicyMap) {
  const user = useUser();

  return isAuthorizedTo(perm, user.role, policies);
}

/**
 * Component version of the `isAuthorizedTo` function. Children will only render
 * if the current user is authorized.
 *
 * @example
 * function MyComponent() {
 *   return <IfAuthorizedTo perm='read:draft'>
 *    Protected content
 *  </IfAuthorizedTo>
 * }
 */
export function IfAuthorizedTo(props: {
  permission: Permission;
  children: React.ReactNode;
  policies?: PolicyMap;
}) {
  const { permission, children, policies } = props;
  const isAuthorized = useIsAuthorizedTo(permission, policies);

  if (isAuthorized) return <>{children}</>;

  return null;
}
