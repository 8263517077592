import * as React from "react";

type Props = {
  size?: number;
};
export function InfoIcon(props: Props) {
  const { size = 24 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <circle cx={size / 2} cy={size / 2} r={size / 3} fill="#019BCE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.956 7c.66 0 1.116.468 1.116 1.068 0 .612-.456 1.068-1.116 1.068-.66 0-1.128-.456-1.128-1.068 0-.6.468-1.068 1.128-1.068zm.14 3.336a1 1 0 011 1v3.26c0 .45.365.816.816.816h.168a.648.648 0 110 1.296h-4.032a.648.648 0 110-1.296h.252a.9.9 0 00.9-.9v-2.466a.414.414 0 00-.414-.414.414.414 0 01-.413-.414v-.234c0-.358.29-.648.648-.648h1.075z"
        fill="#fff"
      />
    </svg>
  );
}
