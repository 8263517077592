/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import type { MultipleChoiceField } from "hw/portal/modules/common/draft";
import MultipleChoiceButtons from "./components/multiple-choice-buttons";
import MultipleChoiceDropdown from "./components/multiple-choice-dropdown";
import MultipleChoiceList from "./components/multiple-choice-list";
import ErrorMessage from "../common/error-message";
import Label from "../common/label";
import type { Option, SelectedValue } from "./types";

type Props = {
  /**
   * If true, the selected values will be provided as an array of values.  This
   * option also enables selection of 0 values, otherwise at least one must
   * be chosen from the list.
   */
  allowMultiple: boolean;

  /**
   * Disables the control
   */
  disabled?: boolean;

  /**
   * A validation error message.  If provided, it will be displayed so it
   * should only be provided if there's an error.
   */
  errorMessage?: string;

  /**
   * Label for the control
   */
  label?: string;

  /**
   * Config for the label component
   */
  labelProps?: {};

  /**
   * Called when the selection changes
   */
  onChange: (...args: Array<any>) => any;

  /**
   * Updates a component to reflect that it has been visited.
   */
  handleVisited: (...args: Array<any>) => any;

  /**
   * The min width value for the control. Not applicable to all presentation
   * types
   */
  minWidth?: string;

  /**
   * The options available for selection.  Note that currently this is just a
   * list of strings.  At a later point this may expand to be an array of
   * objects with some more structure.
   */
  options: Array<string>;

  /**
   * The presentation mode for the options
   */
  presentation: MultipleChoiceField["presentation"];

  /**
   * Marks the field as required
   */
  required?: boolean;

  /**
   * The current value of the component.  If `allowMultiple` is set to `true`,
   * this will be treated as an array.
   */
  value?: SelectedValue;

  /**
   * Informative text to complete the field. It is represented as a tooltip
   */
  helpText?: string;
  id?: string;

  /**
   * Used to style the div that wraps the component and label
   */
  className?: string;

  /**
   * Passed to the component selected for the multiple choice
   */
  placeholder?: string;
};

const ensureArray = (val) => (Array.isArray(val) ? val : [val]);

const supportsMultipleSelection = (presentationType) =>
  ["buttons", "list"].includes(presentationType);

/**
 * Renders the sub component based on the presentation type
 */
function renderComponent(presentationType, props) {
  switch (presentationType) {
    case "list":
      return <MultipleChoiceList {...props} />;
    case "dropdown":
      return <MultipleChoiceDropdown {...props} />;
    case "buttons":
      return <MultipleChoiceButtons {...props} />;
    default:
      throw new Error(
        "No component defined for presentation type: " + presentationType
      );
  }
}

/**
 * Multiple Choice component
 *
 * This component presents a list of options for selection to the user.  The
 * options can be presented in different ways, but otherwise all functionality
 * is the same between presentation modes.
 *
 * A few things to note:
 * - We currently do not all multiple selection for "dropdown" presentation mode.
 *   If this prop is provided when this mode is selectd, it's ignored.
 * - If you `allowMultiple` but only provide a single value, this component will
 *   coerce it into an array.
 * - When choosing `allowMultiple`, the component will allow for 0 or all of the
 *   options to be selected.  When `allowMultiple` is `false`, at least one
 *   option must be chosen (like a group of radio buttons)
 */
export class MultipleChoice extends React.Component<Props> {
  static defaultProps = {
    allowMultiple: false,
    handleVisited: () => {},
    presentation: "buttons",
    options: [],
  };

  constructor(props: Props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.isOptionSelected = this.isOptionSelected.bind(this);
  }

  /**
   * Returns true if the component should allow multiple options to be chosen.
   * This is currently only support for "buttons" and "list" types.  Dropdown
   * support will be added at a later point.
   */
  allowMultiple(): boolean {
    const { presentation, allowMultiple } = this.props;

    return allowMultiple && supportsMultipleSelection(presentation);
  }

  /**
   * Handles selection for multiple options
   *
   * When multiple selection is enabled, the user can select 0 or all of the
   * available options.  If an option is already selected, it will be deselected.
   * If it's not already selected, it will be appened to the current list of values.
   *
   * @param option - The option being selected
   * @param value - The current list of values that is selected
   */
  handleMultiSelect(option: Option, value?: SelectedValue = []) {
    const { onChange } = this.props;
    const values = ensureArray(value);

    return this.isOptionSelectedMulti(option, value)
      ? onChange(values.filter((val) => val !== option))
      : onChange([...values, option]);
  }

  /**
   * Handles selction for a single option
   *
   * For single-selection, at least one option must be chosen at any time.
   *
   * @param option - The option being selected
   */
  handleSelect(option: Option) {
    const { onChange, handleVisited, value } = this.props;
    handleVisited();

    return this.allowMultiple()
      ? this.handleMultiSelect(option, value)
      : onChange(option);
  }

  /**
   * Returns true if the option is currently selected
   *
   * This allows for abstracting the "isSelected" logic away from the components.
   * They can call this function without knowing whether single or multiple
   * selection is enabled
   *
   * @param option - An option from the list of options that may or may not
   * be current selected
   */
  isOptionSelected(option: Option) {
    const { value } = this.props;

    return this.allowMultiple()
      ? this.isOptionSelectedMulti(option, value)
      : value === option;
  }

  /**
   * Returns true if an option is currently selected for multi-selection
   *
   * @param option - The option in question
   * @param values - The current list of selected values
   */
  isOptionSelectedMulti(option: Option, values: SelectedValue = []) {
    return ensureArray(values).includes(option);
  }

  render() {
    const {
      allowMultiple,
      label,
      labelProps,
      options,
      presentation,
      value,
      disabled,
      required,
      errorMessage,
      helpText,
      className,
      placeholder,
      id,
      minWidth,
    } = this.props;

    const componentProps = {
      allowMultiple,
      isSelected: this.isOptionSelected,
      options,
      onSelect: this.handleSelect,
      disabled,
      required,
      qaRef: "multiple-choice",
      value,
      placeholder,
      id,
      minWidth,
    };

    return (
      <>
        <div className={className} role="presentation" htmlFor={id}>
          {/* $FlowIgnore */}
          <Label required={required} helpText={helpText} {...labelProps}>
            {label}
          </Label>
          {renderComponent(presentation, componentProps)}
        </div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  }
}

export default MultipleChoice;
