import * as React from "react";
import { useSession, Session } from "../session";

export type User = Session["user"];

// TODO: We're reading from the 'session' right now, but eventually we should
// move this to reading from the Apollo cache
export function useUser() {
  const session = useSession();

  return session.user;
}

/**
 * Higher-order component for injecting the current user
 */
export function withUser<Props>(
  Component: React.ComponentType<Props>
): React.ComponentType<Props & { user: User }> {
  return function WithUser(props) {
    const user = useUser();

    return <Component {...props} user={user} />;
  };
}
