import React from "react";
import getTestAttributes, {
  testAttributeNames,
} from "hw/common/utils/get-test-attributes";
import { createComponent } from "hw/ui/style";
import { AddIcon } from "hw/ui/icons";
import { VisuallyHidden } from "hw/ui/text";
import type { TriggerProps } from "hw/ui/dropdown/types";

/**
 * @one-off Button this is a rounded button. As it is a one off (we don't have
 * any other rounded buttons) I prefered to keep it as this for now.
 * @provisional
 */
const StyledButton = createComponent(
  function StyledButton({ theme }) {
    return {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: theme.corner.round,
      textDecoration: "none",
      transitionProperty: "all",
      transitionDuration: theme.transitionSpeed.fast,
      color: theme.color.gray500,
      backgroundColor: theme.color.transparent,
      outline: "none",
      border: "none",
      cursor: "pointer",
      boxShadow: "none",
      padding: theme.space.xs,
      ":hover": {
        color: theme.color.gray700,
        backgroundColor: theme.color.gray100,
      },
      ":active": {
        color: theme.color.gray700,
        backgroundColor: theme.color.gray300,
      },
      ":focus": {
        boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
        outline: "none",
      },
    };
  },
  "button",
  ["onClick", "data-testid", "data-track-id"].concat(testAttributeNames)
);

/**
 * This component is a one-off of the AddIcon. I decided to go for a full new
 * component to remove unncessary properties to handle this specific case.
 */
const SmallNewComponentButton = (props: TriggerProps) => {
  const { active, triggerPassthroughProps } = props;
  const { getButtonProps } = triggerPassthroughProps;
  return (
    <StyledButton
      {...getTestAttributes("editor-build-new-field-btn")}
      data-testid="editor-build-new-field-btn"
      data-track-id="editor-add_component_menu_trigger"
      {...getButtonProps()}
    >
      <AddIcon data-test-ref="new-field-menu-icon" active={active} />
      <VisuallyHidden>Add New Field</VisuallyHidden>
    </StyledButton>
  );
};

export default SmallNewComponentButton;
