/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import mapValues from "lodash/mapValues";
import { matchSorter } from "match-sorter";
import type { EditorView } from "prosemirror-view";
import type { EditorState, Transaction } from "prosemirror-state";
import "prosemirror-state";
import type { Schema } from "prosemirror-model";

import type { MergeField } from "hw/portal/modules/common/draft";
import { Factories } from "hw/portal/modules/common/draft";
import { createPlugin, key } from "./plugin";
import * as commands from "./commands";

type State = {
  highlightedIndex: number;
  resetHighlightedIndex: (...args: Array<any>) => any;
};

type Item =
  | {
      type: "data";
      data: MergeField;
    }
  | {
      type: "edit-merge-fields-sentinel";
      label: string;
    }
  | {
      type: "new-merge-field";
      data: MergeField;
    };

export const Context = React.createContext<State>({
  resetHighlightedIndex: () => {},
  highlightedIndex: 0,
  onSelect: () => {},
  getFilteredItems: () => [],
});

/**
 * The merge fields plugin organization is a bit strange.  There's ProseMirror
 * plugin state and also React state, and those two bits of state need to work
 * together to determine the overall merge field plugin state.  The PM state
 * manages whether there is an active "query" for a merge field.  The React
 * component manages which merge fields should be shown based on the query input.
 *
 * There are also keyboard events that need to be handled.  In order to have the
 * React component handle those and manage the state in a decent way, there needs
 * to be a parent component that passes event handlers down to the PM editor
 * so keyboard events can be registered.  Separately, there's the actual "suggestions"
 * component that renders the merge field suggestions when there is an active
 * query.  We're using context to avoid some prop passing between the two
 * components.
 *
 * @example
 * class Editor extends React.Component {
 *
 *   createEditor = () => {
 *     // Create the merge field plugin from the prop function provided
 *     const plugins = [
 *       //...other plugins
 *       this.props.createMergeFieldPlugin(schema)
 *     ]
 *   }
 *
 *   render() {
 *     return <div>
 *       <div ref={this.createEditor} key='ProseMirror' />
 *       {this.view && <MergeFieldSuggestions view={this.view} />}
 *     </div>
 *   }
 * }
 *
 * export default withSuggestions(Editor)
 */
export function withSuggestionsState<P extends {}>(
  Component: React.ComponentType<P>
) {
  return class WithMergeFieldSuggestions extends React.Component<any, State> {
    static defaultProps = {
      mergeFields: [],
    };

    state = {
      highlightedIndex: 0,
      resetHighlightedIndex: this.resetHighlightedIndex.bind(this),
      onSelect: this.handleInsert.bind(this),
      getFilteredItems: this.getFilteredItems.bind(this),
    };

    /**
     * Moves the highlighted index up or down based on the current set of
     * filtered items
     */
    changeHighlightedIndex(moveAmount: number, query: string) {
      const itemsLastIndex = this.getFilteredItems(query).length - 1;

      if (itemsLastIndex < 0) {
        return;
      }

      const { highlightedIndex } = this.state;
      let newIndex = highlightedIndex + moveAmount;

      if (newIndex < 0) {
        newIndex = itemsLastIndex;
      } else if (newIndex > itemsLastIndex) {
        newIndex = 0;
      }

      this.setState({ highlightedIndex: newIndex });
    }

    /**
     * Returns the set of filtered items based on the given query
     */
    getFilteredItems(query: string) {
      const { mergeFields } = this.props;

      const matchedMergeFields = matchSorter(mergeFields, query, {
        keys: ["label", "dataRef"],
      }).map((mergeField) => ({
        data: mergeField,
        type: "data",
      }));

      if (query && !mergeFields.find((mf) => mf.label === query)) {
        return matchedMergeFields.concat([
          {
            type: "new-merge-field",
            data: {
              label: `+ Create "${query}"`,
              dataRef: query,
            },
          },
          {
            type: "edit-merge-fields-sentinel",
            data: {
              label: "Edit merge fields",
              dataRef: "edit-merge-fields-sentinel",
            },
          },
        ]);
      } else {
        return matchedMergeFields.concat({
          type: "edit-merge-fields-sentinel",
          data: {
            label: "Edit merge fields",
            dataRef: "edit-merge-fields-sentinel",
          },
        });
      }
    }

    /**
     * Handles the insertion of a new merge field
     */

    handleInsert(state: EditorState, dispatch: Transaction, item: Item) {
      if (item.type === "edit-merge-fields-sentinel") {
        this.props.onEditMergeFields();
        return commands.dismiss(state, dispatch);
      } else if (item.type === "data") {
        // Inserting a merge field comes with the side effect of creating a
        // `Hidden` field in the schema
        this.props.onInsertMergeField(item.data);

        return commands.insert(state, dispatch, item.data);
      } else if (item.type === "new-merge-field") {
        const newItem = {
          label: item.data.dataRef,
          dataRef: Factories.DataRef(item.data.dataRef),
        };

        this.props.onCreateMergeField(newItem);

        this.props.onInsertMergeField(newItem);
        return commands.insert(state, dispatch, newItem);
      }
    }

    handleEditMergeFields(state: EditorState, dispatch: Transaction) {
      this.props.onEditMergeFields();
      return commands.dismiss(state, dispatch);
    }

    /**
     * Resets the highlighted index state when the suggestions are unmounted
     */
    resetHighlightedIndex() {
      this.setState({ highlightedIndex: 0 });
    }

    /**
     * Decrements the highlighted index
     */
    onArrowUp = (state: EditorState) => {
      const pluginState = key.getState(state);

      this.changeHighlightedIndex(-1, pluginState.query);

      return true;
    };

    /**
     * Increments the highlighted index
     */
    onArrowDown = (state: EditorState) => {
      const pluginState = key.getState(state);
      this.changeHighlightedIndex(1, pluginState.query);
      return true;
    };

    /**
     * When a space is entered and the plugin state is active, we either want to
     *
     * - Select an item if there is only one item currently being filtered
     * - Remove the query so typing can continue as normal
     */
    onSpace = (state: EditorState, dispatch: Transaction, view: EditorView) => {
      const pluginState = key.getState(view.state);

      // Get the filtered items and remove the "Edit merge fields action"
      const filteredItems = this.getFilteredItems(pluginState.query).filter(
        (item) => item.type !== "edit-merge-fields-sentinel"
      );

      // If there's only one item in the filtered list, select it
      // Otherwise, dismiss with a space to allow users to continue typing as
      // normal
      const currentSelection = filteredItems.length === 1 && filteredItems[0];

      if (currentSelection) {
        return this.handleInsert(state, dispatch, currentSelection);
      } else {
        return commands.dismissWithSpace(state, dispatch);
      }
    };

    /**
     * Dismisses the current data ref query
     */
    onEscape = (state: EditorState, dispatch: Transaction) => {
      return commands.dismiss(state, dispatch);
    };

    /**
     * When enter is pressed we either select the currently filtered item if there
     * is one, or dismiss the query
     */
    onEnter = (state: EditorState, dispatch: Transaction) => {
      const pluginState = key.getState(state);
      const filteredItems = this.getFilteredItems(pluginState.query);
      const selection = filteredItems[this.state.highlightedIndex];

      if (selection) {
        return this.handleInsert(state, dispatch, selection);
      } else {
        return commands.dismiss(state, dispatch);
      }
    };

    keymap = runIfActive({
      Space: this.onSpace,
      ArrowDown: this.onArrowDown,
      ArrowUp: this.onArrowUp,
      Escape: this.onEscape,
      Enter: this.onEnter,
    });

    createPlugin = (schema: Schema) =>
      createPlugin({ keymap: this.keymap, schema });

    render() {
      return (
        <Context.Provider value={this.state}>
          <Component
            {...this.props}
            createMergeFieldPlugin={this.createPlugin}
          />
        </Context.Provider>
      );
    }
  };
}

/**
 * Helper to only run keymap commands when the plugin state is active
 */
function runIfActive(keymap) {
  return mapValues(keymap, (fn) => {
    return (state, ...rest) => {
      const pluginState = key.getState(state);

      if (!pluginState.active) {
        return false;
      }

      return fn(state, ...rest);
    };
  });
}
