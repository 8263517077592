import * as React from "react";
import flatMap from "lodash/flatMap";
import { Calculation } from "hw/common/components/smartforms/calculation";
import { PremiumPreview as Premium } from "hw/portal/modules/common/components/premium";
import type { CalculationField, Field } from "hw/portal/modules/common/draft";
import { parseCalculationExpression } from "../../../utils";
import styles from "./calculation.module.css";

type Props = {
  field: CalculationField;
  formFields: Array<Field>;
};
export default function CalculationPreview(props: Props) {
  const exp = React.useMemo(
    () => tryParseExpression(props.field?.value),
    [props.field]
  );
  const flatFields = React.useMemo(
    () =>
      flatMap(props.formFields, (f) =>
        Array.isArray(f.children) ? f.children : f
      ),
    [props.formFields]
  );
  return (
    <>
      <Premium feature="Calculation" />
      <Calculation
        {...props.field}
        value={
          exp.supported &&
          exp.value && <ParsedFormula exp={exp.value} fields={flatFields} />
        }
      />
    </>
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'expression' implicitly has an 'any' typ... Remove this comment to see the full error message
function tryParseExpression(expression) {
  try {
    return {
      supported: true,
      value: parseCalculationExpression(expression),
    };
  } catch (err) {
    return {
      supported: false,
      value: {
        values: [],
        operation: "add",
      },
      reason: err,
    };
  }
}

/**
 * This component parses the expression and gives it a special style for the form builder preview
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function ParsedFormula(props) {
  const {
    exp: { values, operation },
    fields = [],
  } = props;
  const parsedOperation = parseOperator(operation);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'arg' implicitly has an 'any' type.
  const parsedArgs = values.map((arg) => {
    if (arg?.type === "CONSTANT") {
      return arg;
    } else if (arg?.type === "DATAREF") {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
      const field = fields.find((field) => field.dataRef === arg.value);

      if (field) {
        return {
          value: field?.label || `Unnamed ${field.type}`,
          type: "DATAREF",
        };
      } else {
        // We would hopefully only run into this case if messing up in code view
        // ideally we could give a special type and style for it, but I'm keeping it
        // the way it is for now
        return {
          value: "Invalid reference",
          type: "DATAREF",
        };
      }
    } else {
      return arg;
    }
  });
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'arg' implicitly has an 'any' type.
  const argsCn = parsedArgs.map((arg) =>
    arg.type === "DATAREF" ? styles.calculationArgument : undefined
  );
  return (
    <div className={styles.formula}>
      <div className={argsCn[0]}>{parsedArgs[0].value}</div>
      <div>{parsedOperation}</div>
      <div className={argsCn[1]}>{parsedArgs[1].value}</div>
    </div>
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'operation' implicitly has an 'any' type... Remove this comment to see the full error message
function parseOperator(operation) {
  switch (operation) {
    case "add":
      return "+";

    case "sub":
      return "-";

    case "multiply":
      return "×";

    case "divide":
      return "÷";

    default:
      return "";
  }
}
