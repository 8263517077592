/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import getTestAttributes, {
  testAttributeNames,
} from "hw/common/utils/get-test-attributes";
import { createComponent } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  /**
   * Disables the input
   */
  disabled?: boolean;

  /**
   * The text to display when hovering over the input.
   *
   * TODO: This should be a tooltip but I think we'll need to
   * refactor that component to accept arbitrary children as
   * the target of the tip
   */
  helpText?: string;

  /**
   * Id of the element
   */
  id?: string;

  /**
   * The initial value for the input
   */
  value: string;

  /**
   * The size of the input font size.  This should be a key from the
   * `fontSize` property in the theme.
   */
  size?: keyof typeof theme["space"];

  /**
   * Called on the blur event of the input with the new input value
   */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * The placeholer for the input
   */
  placeholder?: string | null;

  /**
   * A `ref` function to be applied to the input element. Use this to get
   * DOM access to the underlying input element
   */
  innerRef?: (node: HTMLInputElement | null | undefined) => unknown;
  "data-guide-id"?: string;

  /**
   * Tracking id for analytics
   */
  dataTrackId?: string;

  /**
   * A boolean to determine if it should autofocus
   */
  autoFocus?: boolean;

  /**
   * Autocomplete option. To disable, pass "off"
   */

  autoComplete?: string;

  onClick?: (...args: Array<any>) => any;

  onFocus?: (...args: Array<any>) => any;

  onBlur?: (...args: Array<any>) => any;

  // eslint-disable-next-line @typescript-eslint/ban-types
  extend?: {};
};

type State = {
  value: string;
};

const Input = createComponent(
  function InlineEditInput({ theme, size }) {
    return {
      fontFamily: "inherit",
      fontSize: theme.fontSize[size] || theme.fontSize.md,
      color: theme.color.textStandard,
      width: "100%",
      borderStyle: "solid",
      borderColor: "transparent",
      borderWidth: "1px",
      padding: theme.space.sm,
      transitionProperty: "border-color",
      transitionDuration: "150ms",
      borderRadius: theme.corner.sm,
      outline: "none",
      ":hover": {
        borderColor: theme.color.gray100,
      },
      ":focus": {
        borderColor: theme.color.borderSelectedColor,
      },
      ":disabled": {
        color: theme.color.textFieldDisabled,
        cursor: "not-allowed",
      },

      // TODO: Remove these
      // These are overrides that are set by the "base.css" class.  That
      // file is setting these for all values, but I don't think we
      // always want them set.  This keeps the placeholder value visible
      // even when focused.
      ":focus::-webkit-input-placeholder": {
        opacity: 1,
      },
      ":focus::-moz-input-placeholder": {
        opacity: 1,
      },
      ":focus::-ms-input-placeholder": {
        opacity: 1,
      },
    };
  },
  "input",
  [
    "onChange",
    "value",
    "title",
    "placeholder",
    "innerRef",
    "disabled",
    "id",
    "autoFocus",
    "autoComplete",
    "data-track-id",
    "onFocus",
    "onClick",
    "onBlur",
  ].concat(testAttributeNames)
);

/**
 * This component currently isn't much besides a wrapper with some predefined
 * styles around a regular input component. It's questionable whether this should
 * just be a variation of our standard input or stand on its own. I made it a new
 * component for now so that it can be tracked. There may be other functionality
 * that we want to add to this like edited only on `blur`.
 *
 * Basic example:
 *
 * ```jsx
 * <InlineEdit onChange={changeHandler} value="the value" />
 * ```
 *
 * You can adjust the size for larger inputs by passing a key corresponding to
 * something from the `fontSize` property in the theme:
 *
 * ```jsx
 * <InlineEdit onChange={changeHandler} value="the value" size="md" />
 * ```
 *
 * As an escape hatch, you can also provide an object of valid input attributes:
 *
 * ```jsx
 * <InlineEdit
 *   onChange={changeHandler}
 *   value="the value"
 *   size="md"
 *   inputAttrs={{ placeholder: "A placeholder" }}
 * />
 * ```
 */
export class InlineEdit extends React.PureComponent<Props, State> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'handleBlur' has no initializer and is no... Remove this comment to see the full error message
  handleBlur: (e: React.SyntheticEvent<HTMLInputElement>) => void;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'handleChange' has no initializer and is ... Remove this comment to see the full error message
  handleChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;

  static defaultProps = {
    helpText: "Edit",
    "data-guide-id": "ui-inline-editor",
  };

  render() {
    const {
      value,
      helpText,
      onChange,
      size,
      innerRef,
      placeholder,
      disabled,
      id,
      autoFocus,
      autoComplete,
      dataTrackId,
      onFocus,
      onClick,
      onBlur,
      extend,
    } = this.props;

    return (
      <Input
        id={id}
        onChange={onChange}
        value={value}
        title={helpText}
        size={size}
        innerRef={innerRef}
        // @ts-expect-error refactor
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        data-track-id={dataTrackId}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={onBlur}
        extend={extend}
        {...getTestAttributes(this.props["data-guide-id"])}
      />
    );
  }
}

export default InlineEdit;
