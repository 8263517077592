import { updateIn, setIn, getIn } from "timm";
import type { Path } from "hw/common/types";
import type { ParsedSchema } from "hw/portal/modules/common/draft";

type ParseSuccessType = {
  result: "success";
  value: ParsedSchema;
};
type ParseFailType = {
  result: "fail";
  error: Error;
  originalValue: string;
};
type RawState = {
  type: "raw";
  value: string;
};
type ParseResult = ParseSuccessType | ParseFailType;
type ParsedState = {
  type: "parsed";
  value: ParseResult;
};
export type SchemaState = RawState | ParsedState;
export type StateType = "raw" | "parsed";

/**
 * Converts the given schema state to the new state type
 *
 * If in "parsed" mode, the state will be transitioned to "raw" mode by stringifying
 * the state.  If in "raw" mode, the state will be transition to "raw" mode
 * by attempting to parse the current stringified state.  In "parsed" mode,
 * the state value can either be a successful parse or a failed parsed.  A
 * failed parsed will store the error and the original string.
 *
 */
export function convertTo(stateType: StateType, state: SchemaState) {
  switch (stateType) {
    case "parsed":
      return toParsed(state);

    case "raw":
      return toRaw(state);

    default:
      exhaustiveCheck(stateType);
      throw new Error("Cannot compute schema state for type: " + stateType);
  }
}

/**
 * Sets the raw value _only_ if the given state is in "raw" mode
 */
export function setRawOrThrow(newSchema: string, state: SchemaState) {
  if (isRaw(state)) {
    return setIn(state, ["value"], newSchema);
  } else {
    throw new Error("Trying to update a raw schema in parsed mode");
  }
}

/**
 * Updates the parsed state _only_ if the given state is in "parsed" mode
 */
export function updateParsedOrThrow(
  state: SchemaState,
  path: Path,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updater: (existingState: any) => any
) {
  if (isSuccessfullyParsed(state)) {
    return updateIn(state, [...path], updater);
  } else {
    throw new Error("Trying to update parsed state in raw mode.");
  }
}

/**
 * Returns the parsed state _only_ if the state is in "parsed" mode
 */
export function getParsedOrThrow(state: SchemaState) {
  if (isSuccessfullyParsed(state)) {
    return getIn(state, ["value", "value"]);
  } else {
    throw new Error(
      "Can't get the parsed schema. The schema may be in raw mode or unparseable due to syntax errors"
    );
  }
}

/**
 * Like the `getParsedOrThrow` but will try to convert to parsed mode if in
 * raw mode
 */
export function forceGetParsed(state: SchemaState) {
  if (isSuccessfullyParsed(state)) {
    return getIn(state, ["value", "value"]);
  }

  const converted = convertTo("parsed", state);
  return getParsedOrThrow(converted);
}

function toParsed(state: SchemaState): SchemaState {
  switch (state.type) {
    case "parsed":
      return state;

    case "raw":
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; value: ParseResult; }' is no... Remove this comment to see the full error message
      return Parsed(tryParseSchema(state.value));

    default:
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      exhaustiveCheck(state.type);
      throw new Error(
        `Cannot convert schema state type of ${
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'never'.
          state.type
        } to parsed`
      );
  }
}

function toRaw(state: SchemaState): RawState {
  switch (state.type) {
    case "parsed": {
      // The previous parse result may have failed
      const parseResult = state.value;

      switch (parseResult.result) {
        // The last parse result succeeded so we can safely stringify
        case "success":
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; value: string; }' is not ass... Remove this comment to see the full error message
          return Raw(JSON.stringify(parseResult.value, null, 2));

        // The last parse failed, so we restore the raw state from the last
        // attempted string
        case "fail": {
          const failedState = parseResult;
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; value: string; }' is not ass... Remove this comment to see the full error message
          return Raw(failedState.originalValue);
        }

        default:
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          exhaustiveCheck(parseResult.result);
          throw new Error(
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'result' does not exist on type 'never'.
            "Cannot handle parse result type of " + parseResult.result
          );
      }
    }

    case "raw":
      return state;

    default:
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      exhaustiveCheck(state.type);
      throw new Error(
        `Cannot convert schema state type of ${
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'never'.
          state.type
        } to raw`
      );
  }
}

function Parsed(value: ParseResult) {
  return {
    type: "parsed",
    value,
  };
}

export function Raw(value: string) {
  return {
    type: "raw",
    value,
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'json' implicitly has an 'any' type.
function ParseSuccess(json): ParseSuccessType {
  return {
    result: "success",
    value: json,
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
function ParseFail(error, originalValue): ParseFailType {
  return {
    result: "fail",
    error,
    originalValue,
  };
}

export function isParsed(state: SchemaState) {
  return state.type === "parsed";
}

function isRaw(state: SchemaState) {
  return state.type === "raw";
}

export function isSuccessfullyParsed(state: SchemaState): boolean {
  return state.type === "parsed" && state.value.result === "success";
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'string' implicitly has an 'any' type.
function tryParseSchema(string): ParseResult {
  try {
    const schema = JSON.parse(string);
    return ParseSuccess(schema);
  } catch (e) {
    return ParseFail(e, string);
  }
}

function exhaustiveCheck(_impossible: never) {}
