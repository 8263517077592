import * as React from "react";
import { createComponent } from "hw/ui/style";
import { DangerIcon } from "hw/ui/icons";
import { Heading3, TextBody1 } from "hw/ui/text";

const Wrapper = createComponent(function Wrapper({ theme }) {
  return {
    width: "100%",
    maxWidth: "600px",
    margin: theme.space.xl,
    padding: theme.space.md,
    textAlign: "left",
    borderColor: theme.color.red100,
    backgroundColor: theme.color.red050,
    borderStyle: "solid",
    borderWidth: theme.corner.xs,
    borderRadius: theme.corner.sm,
  };
});
const Header = createComponent(function Header({ theme }) {
  return {
    marginBottom: theme.space.xs,
    display: "flex",
    alignItems: "center",
  };
});
const Title = createComponent(function Title({ theme }) {
  return {
    marginLeft: theme.space.xs,
  };
});
const Copy = createComponent(function Copy({ theme }) {
  return {
    paddingLeft: theme.space.xl,
    paddingRight: theme.space.xl,
  };
});
export function SchemaError() {
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message
    <Wrapper>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message */}
      <Header>
        <DangerIcon />
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message */}
        <Title>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message */}
          <Heading3 type={3}>Schema Error</Heading3>
        </Title>
      </Header>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'theme' is missing in type '{ children: E... Remove this comment to see the full error message */}
      <Copy>
        <TextBody1>
          There are syntax errors that have been introduced in the code editor.
          You need to fix them before you can continue editing your workflow.
        </TextBody1>
      </Copy>
    </Wrapper>
  );
}
export default SchemaError;
