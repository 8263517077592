import { TextBody1, TextBody2 } from "./body";

export { TextBody1, TextBody2 };
export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from "./heading";
export { default as VisuallyHidden } from "./visually-hidden";
export { SubtleLink, A } from "./link";
export {
  TruncatedText,
  TruncatedTextParent,
  WordBreakText,
  WordBreakTextParent,
  Text,
  Heading,
} from "./text";

export type { TextProps } from "./text";

export default TextBody1;
