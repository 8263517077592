import * as React from "react";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import { useStyle } from "hw/ui/style";
import { CaretDownIcon } from "hw/ui/icons";
import theme from "hw/ui/theme";
import type { TriggerProps } from "./types";

type Props = TriggerProps & {
  children: React.ReactNode;
  "data-testid"?: string;
};

/**
 * This is the most basic trigger that displays some text with a downward facing
 * caret
 */
export default function TextTrigger(props: Props) {
  const {
    children,
    triggerPassthroughProps,
    disabled,
    "data-testid": dataTestId,
  } = props;
  const { getButtonProps } = triggerPassthroughProps;
  const cn = useStyle("dropdown-text-trigger", style(props));

  /**
   * @one-off Button The button style here should be one of our standard UI button
   variations.  We're inlining it here but eventually we should delegate
   to that component
   * @provisional
   */
  return (
    <button
      className={cn}
      {...getButtonProps()}
      disabled={disabled}
      data-test-ref="ui-text-trigger"
      data-testid={dataTestId}
      {...getTestAttributes(props["data-guide-id"])}
    >
      {children}
      <i>
        <CaretDownIcon />
      </i>
    </button>
  );
}

const style = ({ active, disabled }: Props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: theme.fontSize.ms,
  fontWeight: theme.fontWeight.semibold,
  paddingTop: theme.space.sm,
  paddingBottom: theme.space.sm,
  paddingLeft: theme.space.sm,
  paddingRight: theme.space.sm,
  cursor: disabled ? "not-allowed" : "pointer",
  borderRadius: theme.corner.sm,
  color: theme.color.textStandard,
  backgroundColor: active
    ? theme.color.uiDropdownTriggerActive
    : theme.color.transparent,
  border: "none",
  transition: "background-color 50ms, color 50ms",
  ":active": {
    outline: "none",
  },
  ":focus": {
    outline: active && "none",
  },
  ":hover": {
    backgroundColor: theme.color.uiDropdownTriggerActive,
  },
  "> i": {
    display: "flex",
    marginLeft: theme.space.xs,
    fill: active ? theme.color.white : theme.color.textStandard,
  },
});
