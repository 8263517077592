import * as React from "react";
import { Box } from "hw/ui/blocks";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import IconWrapper, { MenuIcon } from "hw/ui/icons";
import InlineEdit from "hw/ui/inline-edit";
import { createComponent } from "hw/ui/style";
import { VisuallyHidden } from "hw/ui/text";
import { Tooltip } from "hw/ui/tooltip";
import * as heap from "hw/portal/modules/analytics/heap";
import FormMenu from "./form-menu";
import type { MenuItem } from "../types";
import { NEW_FORM_NAME } from "../constants";

type Props = {
  canDelete: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  name: string;
  menuItems: Array<MenuItem> | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (...args: Array<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSidebarToggle: (...args: Array<any>) => any;
  sidebarOpen: boolean;
  isNewForm?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeNewFormParam?: (...args: Array<any>) => any;
};
const Styled = styled();
const SVG_WIDTH = 16;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
const isDefaultName = (name) => NEW_FORM_NAME === name;

/**
 * This function compares the form name with the default one, and if it is the same
 * it adds '(Click to rename)'
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
const getFormName = (name) => {
  if (isDefaultName(name)) {
    return `${name} (Click to rename)`;
  } else {
    return name;
  }
};

function FormHeader(props: Props) {
  const [modified, setModified] = React.useState(false);
  const {
    canDelete,
    menuItems,
    children,
    disabled,
    name,
    onDelete,
    sidebarOpen,
    onSidebarToggle,
    isNewForm,
    removeNewFormParam,
  } = props;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onChange = (e) => {
    if (!modified) {
      heap.track(heap.EVENTS.editor.formNameModified);
      setModified(true);
    }

    props.onChange(e);
  };

  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Styled.Container sidebarOpen={sidebarOpen}>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Styled.MenuIconWrapper active={!sidebarOpen}>
        <Tooltip tip="Show form list">
          <IconWrapper
            onClick={() => onSidebarToggle(true)}
            className="m-workflow-editor__sidebar__open-cta"
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; onClick: () => any; cla... Remove this comment to see the full error message
            alt="openSidebar"
          >
            <MenuIcon />
          </IconWrapper>
        </Tooltip>
      </Styled.MenuIconWrapper>
      <Box mr="ms" width={1}>
        <VisuallyHidden>
          <label htmlFor="form-editor-name-input">Form Name</label>
        </VisuallyHidden>
        <InlineEdit
          size="lg"
          onChange={onChange}
          value={getFormName(name)}
          disabled={disabled}
          id="form-editor-name-input"
          dataTrackId="editor-form_name"
          onFocus={(e) => e.currentTarget.select()}
          autoFocus={isDefaultName(name) && isNewForm}
          onClick={() => heap.track(heap.EVENTS.editor.formNameClicked)}
          onBlur={isNewForm ? undefined : removeNewFormParam}
          {...getTestAttributes("editor-form-name")}
        />
      </Box>
      {children}
      <FormMenu
        onDelete={onDelete}
        menuItems={menuItems || []}
        canDelete={canDelete}
      />
    </Styled.Container>
  );
}

FormHeader.defaultProps = {
  menuItems: [],
};
export default React.memo(FormHeader);

function styled() {
  return {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    Container: createComponent(function FormEditorNameContainer({
      theme,
      sidebarOpen,
    }) {
      return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: theme.space.xs,
        paddingBottom: theme.space.xs,
        paddingRight: theme.space.ms,
        paddingLeft: sidebarOpen && theme.space.ms,
        borderBottomStyle: "solid",
        borderColor: theme.color.dividerDim,
        borderWidth: "1px",
        width: "100%",
        flexShrink: "0",
      };
    },
    "header"),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ theme, active, }: { theme: an... Remove this comment to see the full error message
    MenuIconWrapper: createComponent(function MenuIconWrapper({
      theme,
      active,
    }) {
      return {
        cursor: "pointer",
        top: theme.space.sm,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
        visibility: active ? "visible" : "hidden",
        opacity: active ? 1 : 0,
        // Some questionalble math going on here...
        // This is the width of the icon + the padding on the icon + the padding
        // on the overall wrapper
        marginLeft:
          !active &&
          `calc(-${SVG_WIDTH}px - (${theme.space.sm} * 2) - (${theme.space.xs} * 2))`,
        transition: "visibility 0s, opacity 0.5s linear, margin-left 0.3s",
        "& svg": {
          width: `${SVG_WIDTH}px`,
          height: `${SVG_WIDTH}px`,
        },
      };
    }),
  };
}
