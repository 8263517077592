import * as React from "react";
import { useStyle } from "hw/ui/style";
import theme from "hw/ui/theme";

type Props = {
  /**
   * The icon content, i.e. `<svg><path /></svg>`
   */
  children: React.ReactNode;
};

/**
 * A wrapper for rendering icons within a `MenuItem` component.  Ideally, the
 * icon should be an inline `svg` icon.
 */
export function MenuItemIcon(props: Props) {
  const { children } = props;

  return (
    <div className={useStyle("ui-menu-item-icon", menuItemIconStyle)}>
      {children}
    </div>
  );
}

const menuItemIconStyle = {
  paddingLeft: theme.space.xs,
  paddingRight: theme.space.xs,
};

export default MenuItemIcon;
