import * as React from "react";
import { Flex } from "hw/ui/blocks";
import type { GroupField } from "hw/portal/modules/common/draft";
import { PremiumBox1 as Premium } from "hw/portal/modules/common/components/premium";
import { Label } from "./common";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: GroupField;

  /**
   * Children are provided only in the V2 editor
   *
   * NOTE: Only used in V2
   */
  children?: React.ReactNode;

  /**
   * Text used to describe this label, a.k.a. The label label
   *
   * NOTE: Only used in V2
   */
  labelText?: string;
};
export function GroupSettings(props: Props) {
  const { field, path, editorDispatch, defaultSettings, children, labelText } =
    props;
  const { label, type } = field;
  return (
    <Flex flexDirection="column">
      <Premium feature="Group" />
      {defaultSettings}
      <Label
        path={path}
        editorDispatch={editorDispatch}
        label={label}
        fieldType={type}
        placeholder="Condition"
        labelText={labelText}
      />
      {children}
    </Flex>
  );
}
export default GroupSettings;
