/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import ToggleButton from "hw/ui/toggle-button";
import { createComponent } from "hw/ui/style";
import { testAttributeNames } from "hw/common/utils/get-test-attributes";
import type { PresentationProps } from "../types";

type Props = PresentationProps;

const noop: (...args: Array<any>) => any = () => {};

const ToggleWrapper = createComponent(function MultipleChoiceToggleWrapper() {
  return {
    flexBasis: "calc(50% - 5px)",
    minWidth: 0, // necessary to make it work in firefox
    marginRight: "10px",
    marginBottom: "10px",
    ":nth-child(even)": {
      marginRight: 0,
    },
  };
});

const ButtonsWrapper = createComponent(
  function MultipleChoiceButtonsWrapper() {
    return {
      display: "flex",
      flexWrap: "wrap",
    };
  },
  "div",
  testAttributeNames
);

type OptionProps = {
  active?: boolean;
  option: string;
  onSelect: (option: string) => void;
  disabled?: boolean;
};

class Option extends React.PureComponent<OptionProps> {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { option, onSelect } = this.props;

    onSelect(option);
  }

  render() {
    const { active, option, disabled } = this.props;

    return (
      <ToggleWrapper>
        <ToggleButton
          onClick={this.handleClick}
          active={active}
          disabled={disabled}
        >
          {option}
        </ToggleButton>
      </ToggleWrapper>
    );
  }
}

export function MultipleChoiceButtons(props: Props) {
  const {
    options = [],
    isSelected = noop,
    onSelect = noop,
    disabled,
    qaRef,
    id,
  } = props;

  return (
    <ButtonsWrapper data-qa-ref={qaRef} id={id} role="menu">
      {options.map((option, index) => (
        <Option
          key={`${option}-${index}`}
          option={option}
          active={isSelected(option)}
          disabled={disabled}
          onSelect={onSelect}
        />
      ))}
    </ButtonsWrapper>
  );
}

export default React.memo(MultipleChoiceButtons);
