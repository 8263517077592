import * as React from "react";
import Box from "./box";

/**
 * Helper component for Flex elements
 *
 * @example
 * <Flex flexDirection='column'>
 *      content
 * </Flex>
 */
export function Flex(props: React.ComponentProps<typeof Box>) {
  return <Box {...props} display="flex" />;
}

export default Flex;
