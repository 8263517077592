import * as React from "react";
import Signature from "hw/common/components/smartforms/signature";

export function SignaturePreview() {
  // TODO: Refactor this, we shouldn't be passing strings that should never change like this.
  //  Because of it we will have a translated version in SF but this version is hardcoded to english
  return (
    <div>
      <Signature message="Click to sign" isValid />
    </div>
  );
}
export default SignaturePreview;
