import * as React from "react";

type Props = {
  size?: number;
};
export function NoLinkedFieldsIcon(props: Props) {
  const { size = 24 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78 17.625a1 1 0 11-1.56-1.25l8-10a1 1 0 011.56 1.25l-8 10zM5 8a2 2 0 00-2 2v4a2 2 0 002 2h.5a1 1 0 100-2H5v-4h5a1 1 0 100-2H5zm13.5 0a1 1 0 100 2h.5v4h-5a1 1 0 100 2h5a2 2 0 002-2v-4a2 2 0 00-2-2h-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
