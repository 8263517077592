import * as React from "react";
import { useIntl } from "react-intl";
import type { Position } from "hw/ui/hover-card";
import ButtonTrigger from "./button-trigger";
import type { Props as DropdownProps } from "../dropdown";
import { Dropdown } from "../dropdown";
import SelectNative from "./select-native";

export type Props = Omit<DropdownProps, "Trigger"> & {
  /**
   * The placeholder to display when nothing is selected.
   */
  placeholder?: string;

  /**
   * If `true`, a native select component will be rendered
   */
  native?: boolean;

  /**
   * If `true`, the select control will be disabled
   */
  disabled?: boolean;
  "data-guide-id"?: string;
  "data-qa-ref"?: string;

  id?: string;
};

type State = {
  position: Position;
};

/**
 * A `Select` component is a convenience component that's a specialized kind of `Dropdown` component.
 *
 * ```jsx
 * const validationOptions = [
 *   { label: "Letters Only", value: "L" },
 *   { label: "Numbers Only", value: "N" },
 *   { label: "Letters and Numbers Only", value: "LN" },
 * ];
 *
 * <Select
 *   onChange={}
 *   placeholder="Select Validation..."
 *   render={({ getItemProps, index, highlightedIndex }) => (
 *     <MenuList>
 *       {validationOptions.map((option, index) => (
 *         <MenuItem
 *           key={option.value}
 *           active={index === highlightedIndex}
 *           passthroughProps={getItemProps({ item: option })}
 *         >
 *           <MenuItemText>{option.label}</MenuItemText>
 *         </MenuItem>
 *       ))}
 *     </MenuList>
 *   )}
 * />;
 * ```
 *
 * For certain cases, like mobile devices, a native `select` control is desired.
 * With a few prop changes you can render a native `select` element:
 *
 * ```jsx
 * <Select
 *   native
 *   onChange={(e) => this.setSelected(e.currentTarget.value)}
 *   placeholder="Select Validation..."
 *   selectedItem={selectedItem}
 * >
 *   {this.items.map((opt) => (
 *     <option key={opt} value={opt}>
 *       {opt}
 *     </option>
 *   ))}
 * </Select>
 * ```
 *
 * Note that the `children` are now specified as regular `option` elements instead
 * of using a `render` function. This is to avoid needing to wrap them in a
 * surrounding element (React 16 will fix this). Also note that the `onChange`
 * handler gets the native `onChange` event.
 */

class Select extends React.Component<Props, State> {
  static defaultProps = {
    "data-guide-id": "ui-select",
    triggerProps: {},
  };

  constructor(props: Props) {
    super(props);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.state = {
      position: "bottom",
    };
  }

  handlePositionChange(position: Position) {
    this.setState({
      position,
    });
  }

  render() {
    const { position } = this.state;
    const { placeholder, disabled, native, triggerProps, ...rest } = this.props;

    if (native) {
      return (
        // @ts-expect-error refactor
        <SelectNative disabled={disabled} placeholder={placeholder} {...rest} />
      );
    }

    return (
      <Dropdown
        Trigger={ButtonTrigger}
        {...rest}
        triggerProps={{
          placeholder,
          disabled,
          position,
          ...triggerProps,
        }}
        type="select"
        // @ts-expect-error migration
        position={position}
        onPositionChange={this.handlePositionChange}
        justify="left"
      />
    );
  }
}

export default function SelectLocaleWrapper(props: Props) {
  const intl = useIntl();
  // Because this eventually leads to the placeholder being rendered in an option it MUST be a string.
  // So we can not use the normal message component.
  const {
    placeholder = intl.formatMessage({
      id: "sf.components.smart-view.smart-form.smart-components.select-list.placeHolder",
    }),
    ...rest
  } = props;
  // $FlowIgnore
  return <Select placeholder={placeholder} {...rest} />;
}
