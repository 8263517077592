import { createLogger } from "hw/common/utils/logger";

const logger = createLogger("rich-text-html-renderer");

type Data = Record<string, string>;

/**
 * Replace string variables by the matching values
 * @param s         String to interpolate
 * @param data      Map of values to use
 * @return          Interpolated string
 */
export default function interpolateString(s: string, data?: Data) {
  if (s && data) {
    // Extract variable names from the string
    const vars = {};
    let start;
    let end;
    for (let i = 0; i < s.length; i++) {
      if (s[i] === "{") {
        start = i;
        end = s.indexOf("}", start + 1);
        if (end > start) {
          // @ts-expect-error refactor
          vars[s.slice(start + 1, end)] = true;
        }
      }
    }

    const varNames = Object.keys(vars);
    if (varNames.length > 0) {
      // Replace variables by values
      for (let i = 0; i < varNames.length; i++) {
        const name = varNames[i];
        // @ts-expect-error refactor
        let v = data[name];
        if (v === undefined) {
          // Show a placeholder if the value isn't available yet
          logger.warnOnce("No value for text variable");
          v = "____";
        }

        // NOTE: The function replacement form ensures that characters in the
        // replacement value don't get interpreted as regex replacement
        // characters (e.g. "$6")
        // eslint-disable-next-line security-node/non-literal-reg-expr
        s = s.replace(new RegExp("{" + name + "}", "g"), () => v);
      }
    }
  }

  return s;
}
