import * as React from "react";

export default function Preview() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path
        id="preview-icon-24-a"
        d="M12,6 C7.90909091,6 4.41545455,8.488 3,12 C4.41545455,15.512 7.90909091,18 12,18 C16.095,18 19.5845455,15.512 21,12 C19.5845455,8.488 16.095,6 12,6 Z M12,16 C9.792,16 8,14.208 8,12 C8,9.792 9.792,8 12,8 C14.208,8 16,9.792 16,12 C16,14.208 14.208,16 12,16 Z M12,9 C10.345,9 9,10.345 9,12 C9,13.655 10.345,15 12,15 C13.655,15 15,13.655 15,12 C15,10.345 13.655,9 12,9 Z"
      />
    </svg>
  );
}
