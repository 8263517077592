export function trackEvent(properties = {}) {
  // @ts-expect-error refactor
  const dataLayer = window.dataLayer;

  if (!dataLayer) {
    throw new Error("The `dataLayer` object must be defined globally.");
  }

  // @ts-expect-error refactor
  const { category, action, label } = properties;

  dataLayer.push({
    event: "track_event",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
}
