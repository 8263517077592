import * as React from "react";
import cx from "classnames";
import ErrorMessage from "hw/common/components/smartforms/common/error-message";
import "./select-list.css";
import Label from "./common/label";

type Props = {
  classNames?: string;
  children: React.ReactNode;
  errorMessage?: string;
  grouped: boolean;
  handleChange: (value: string) => void;
  isValid: boolean;
  isVisited: boolean;
  label?: string;
  readOnly: boolean;
  required: boolean;
  theme: {
    classNames: {
      inputBorderFocus: string;
    };
  };
  value: unknown;
  // eslint-disable-next-line @typescript-eslint/ban-types
  labelProps?: {};
  id: string;
};

export default function SelectList(props: Props) {
  const {
    classNames,
    value,
    grouped,
    required,
    isValid,
    errorMessage,
    isVisited,
    readOnly,
    handleChange,
    children,
    theme,
    id,
  } = props;
  const rootClass = cx(
    classNames,
    { "m-sf-select-list--grouped": grouped },
    { "m-sf-select-list--required": required }
  );

  const labelComponent = getLabelComponent(props);

  const error = !isValid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

  const classes = {
    "m-sf-select-list": true,
    visited: isVisited,
    invalid: !isValid,
    readonly: readOnly,
    [theme.classNames.inputBorderFocus]: true,
  };

  const selectProps = {
    className: cx(classes),
    value,
    // @ts-expect-error refactor
    onChange: readOnly ? null : (e) => handleChange(e.target.value),
    disabled: readOnly,
    id,
  };

  return (
    <div className={rootClass}>
      {labelComponent}
      {/* @ts-expect-error refactor */}
      <select {...selectProps}>{children}</select>
      {error}
    </div>
  );
}

// @ts-expect-error refactor
function getLabelComponent(props) {
  const { label, required, id, labelProps = {}, grouped } = props;

  if (!label) {
    return null;
  }

  // TODO: Can we remove this?
  // It's unclear why we need a special style for grouped select lists.
  // I'm leaving this here until we know that we can remove it
  if (grouped) {
    return <div className="m-sf-select-list-title">{label}</div>;
  }

  return (
    <Label required={required} htmlFor={id} {...labelProps}>
      {label}
    </Label>
  );
}
