/**
 * This is a hook that can be used to observe a hover card target and automatically
 * reposition when the target changes. This is likely not a complete solution,
 * so please use with discretion.
 *
 *
 * We can be somewhat aggressive here in the mutation observer callback because
 * we only setup the mutation observer if there is an active target. It's
 * unlikely that the target of the hover card will be mutated often when the
 * hover card is currently active.
 *
 * @example
 * function MyComponent() {
 *   // Create a ref to hold the hovercard instance
 *   const ref = React.useRef();
 *
 *   // Call the hook with the current value
 *   useTargetObserver(ref.current);
 *
 *   return <HoverCard ref={ref} />
 * }
 */
import * as React from "react";
import { useEffect } from "react";
import throttle from "lodash/throttle";
import HoverCard from "./hover-card";

export function useTargetObserver(ref: React.ElementRef<typeof HoverCard>) {
  useEffect(() => {
    const hoverCard = ref;
    if (!hoverCard) return;

    let observer: MutationObserver;
    let frame: number;
    let mutationCb: MutationCallback;

    const isActive = hoverCard.getIsActive();
    const target = hoverCard.target;

    function cleanup() {
      if (observer) {
        observer.disconnect();
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof mutationCb.cancel === "function") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mutationCb.cancel();
      }

      cancelAnimationFrame(frame);
    }

    if (isActive && target) {
      const config = { attributes: true };

      mutationCb = throttle(function reposition() {
        if (!hoverCard) return;

        frame = requestAnimationFrame(hoverCard.measure);
      }, 300);

      observer = new MutationObserver(mutationCb);
      observer.observe(target, config);
    } else {
      cleanup();
    }

    return cleanup;
  }, [ref]);
}
