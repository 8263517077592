/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { VisuallyHidden } from "hw/ui/text";
import IconWrapper, { LinkBreak, ExternalLink } from "hw/ui/icons";
import * as Styled from "./styled";

type Props = {
  onChange: (href: string) => void;
  onUnlink: (...args: Array<any>) => any;
  onExit: (...args: Array<any>) => any;
  value: string;
};

/**
 * Renders an input and controls for editing the `href` of an anchor element.
 */
class LinkEditor extends React.Component<Props> {
  externalLinkForwardedProps = ["href", "target", "ref"];

  linkBreakForwardedProps = ["type"];

  inputRef: { current: HTMLInputElement | null } = React.createRef();

  /**
   * If there is no value, assume we're wrapping some plain text in a new link
   * and auto focus the input
   */
  componentDidMount() {
    if (this.props.value === "" && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  handleChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const target = evt.currentTarget as HTMLInputElement;

    this.props.onChange(target.value);
  };

  /* before continuing, protect against missing http/https protocol */
  handleBlur = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const target = evt.currentTarget as HTMLInputElement;

    if (!target.value) {
      return;
    }

    const validURL = this.getValidURL(target.value);

    this.props.onChange(validURL);
  };

  handleSubmit = (evt: React.SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault();
    this.props.onExit();
  };

  /**
   * When escaping on the wrapping container, treat that as a "leave" event
   */
  handleContainerKeyDown = (evt: React.KeyboardEvent) => {
    const key = evt.key;

    if (key === "Escape") {
      evt.preventDefault();
      this.props.onExit();
    }
  };

  /**
   * Since the input is the first element, if a `Shift+Tab` keydown is encounted,
   * force the focus back to the editor at its current selection.  Without this,
   * the editor would still be focused, but the cursor would be reset to the
   * begining instead of where it was before opening the edit window.
   */
  handleInputKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === "Tab" && evt.shiftKey) {
      evt.preventDefault();
      this.props.onExit();
    }
  };

  /**
   * Protect against missing http/https protocol.
   * Technically, not the most stringent validator; does not account for TLD.
   */
  getValidURL = (input: string) => {
    const isValid = /^http:\/\/|^https:\/\//.test(input);

    return isValid ? input : `http://${input}`;
  };

  openLink = () => {
    const { value } = this.props;
    const url = this.getValidURL(value);

    if (url) {
      let link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.click();
      link = null;
    }
  };

  render() {
    const { onUnlink, value } = this.props;

    return (
      <Styled.EditContainer
        onSubmit={this.handleSubmit}
        onKeyDown={this.handleContainerKeyDown}
      >
        <VisuallyHidden>
          <label htmlFor="rich-text-link-edit">Edit link location</label>
        </VisuallyHidden>
        <Styled.EditInput
          autoComplete="off"
          id="rich-text-link-edit"
          innerRef={this.inputRef}
          onChange={this.handleChange}
          onKeyDown={this.handleInputKeyDown}
          onBlur={this.handleBlur}
          placeholder="Link URL"
          type="text"
          value={value}
        />
        <Styled.EditControls>
          <IconWrapper onClick={this.openLink} type="button" title="Open Link">
            <ExternalLink />
          </IconWrapper>
          <IconWrapper onClick={onUnlink} type="button" title="Delete link">
            <VisuallyHidden>Delete link</VisuallyHidden>
            <LinkBreak />
          </IconWrapper>
        </Styled.EditControls>
      </Styled.EditContainer>
    );
  }
}

export default LinkEditor;
