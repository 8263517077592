import React from "react";
import { EVENTS as heapEvents } from "hw/portal/modules/analytics/heap";
import type { Expression } from "hw/portal/modules/common/draft";
import Toggle from "./toggle";
import type { SettingsProps } from "../../../types";

type Props = SettingsProps & {
  path: Array<string | number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: (...args: Array<any>) => any;
  required: boolean | Expression;
  fieldType?: string;
};
export default class Required extends React.PureComponent<Props> {
  static defaultProps = {
    required: false,
  };

  transformer = (
    field: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      errors?: {};
    },
    newValue: boolean
  ) => ({
    ...field,
    required: newValue,
    errors: { ...field.errors, required: "This field is required" },
  });

  render() {
    const { required, path, editorDispatch, fieldType } = this.props;
    // Coerce to a boolean if the `required` value is a macro
    const isRequired = Boolean(required);
    return (
      <Toggle
        path={path}
        editorDispatch={editorDispatch}
        transformer={this.transformer}
        value={isRequired}
        name="Required"
        fieldType={fieldType}
        heapEventType={heapEvents.editor.requiredToggleClicked}
      />
    );
  }
}
