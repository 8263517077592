import * as React from "react";
import memoize from "lodash/memoize";
import isEqual from "lodash/isEqual";
import { getIn } from "timm";
import { Box } from "hw/ui/blocks";
import type {
  Field as FieldType,
  Role,
  Mapping,
  Form,
  MergeField,
} from "hw/portal/modules/common/draft";
import type { Path } from "hw/common/types";
import VisuallyHidden from "./visually-hidden";
import { Small as NewComponentDropdown } from "./component-menu/dropdown";
import DraggableField from "./draggable-field";
import * as utils from "../../utils";
import { getPrefill, fieldHasMapping, getFormMappings } from "../../../utils";

type Props = {
  fields: Array<FieldType>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCreate: (path: Path, type: string, attrs?: {}) => void;
  order: string;
  path: Path;
  isNested?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  nestedAttrs?: {};
  roleDescriptions: Record<
    Role["id"],
    {
      title: string;
      badge: string;
    }
  >;
  formId: string;
  mapping: Mapping;
  forms: Array<Form>;
  mergeFields: Array<MergeField>;
  selectedFieldPath: Path | null | undefined;
  onCreateMergeField?: (mergeField: MergeField) => void;
  isPaid: boolean;
};
export default class FieldCollection extends React.PureComponent<Props> {
  cachedPaths = {};

  /* $FlowFixMe[missing-annot] $FlowFixMe This comment suppresses an error
   * found when upgrading Flow to v0.132.0. To view the error, delete this
   * comment and run Flow. */
  getPath = memoize(
    (path: Path) => path,
    (args) => args.join(".")
  );

  isFieldSelected = (path: Path) => {
    const { selectedFieldPath } = this.props;
    return Boolean(selectedFieldPath && isEqual(selectedFieldPath, path));
  };

  render() {
    const {
      fields,
      onCreate,
      roleDescriptions,
      isNested,
      nestedAttrs,
      formId,
      mapping,
      forms,
      mergeFields,
      selectedFieldPath,
      ...rest
    } = this.props;
    return (
      <React.Fragment>
        {fields.map((field, index) => {
          const path = this.getPath([...this.props.path, index]);
          const fieldRoleId = getIn(field, ["roles", 0]);
          // @ts-expect-error ts-migrate(2538) FIXME: Type 'null' cannot be used as an index type.
          const roleDesc = roleDescriptions[fieldRoleId] || {};
          const { title, badge } = roleDesc;
          const isSelected = this.isFieldSelected(path);
          let formMappings;

          // We only look for the form mappings if the field has mappings.
          // @ts-expect-error refactor
          if (fieldHasMapping(field.dataRef, formId, mapping)) {
            formMappings = getFormMappings(field, formId, forms);
          }

          const prefill = getPrefill(
            mergeFields,
            formMappings,
            mapping,
            formId,
            field
          );
          return utils.isFieldVisible(field) ? (
            <Field
              {...rest}
              key={field.id}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; path: Path; onCreate: (path: Pat... Remove this comment to see the full error message
              path={path}
              onCreate={onCreate}
              isNested={isNested}
              nestedAttrs={nestedAttrs}
              isSelected={isSelected}
              roleDescriptions={roleDescriptions}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              prefillMessage={getIn(prefill, ["message"])}
              field={field}
              roleTitle={title}
              roleBadge={badge}
              mapping={mapping}
              formId={formId}
              forms={forms}
              mergeFields={mergeFields}
            />
          ) : null;
        })}
      </React.Fragment>
    );
  }
}
const Field = React.memo(function Field(props) {
  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'path' does not exist on type '{ children... Remove this comment to see the full error message
    path,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onCreate' does not exist on type '{ chil... Remove this comment to see the full error message
    onCreate,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'nestedAttrs' does not exist on type '{ c... Remove this comment to see the full error message
    nestedAttrs,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isNested' does not exist on type '{ chil... Remove this comment to see the full error message
    isNested,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSelected' does not exist on type '{ ch... Remove this comment to see the full error message
    isSelected,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'roleDescriptions' does not exist on type... Remove this comment to see the full error message
    roleDescriptions,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'prefillMessage' does not exist on type '... Remove this comment to see the full error message
    prefillMessage,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'field' does not exist on type '{ childre... Remove this comment to see the full error message
    field,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'roleTitle' does not exist on type '{ chi... Remove this comment to see the full error message
    roleTitle,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'roleBadge' does not exist on type '{ chi... Remove this comment to see the full error message
    roleBadge,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isPaid' does not exist on type '{ childr... Remove this comment to see the full error message
    isPaid,
    ...rest
  } = props;
  return (
    <>
      <VisuallyHiddenNewComponentDropdown
        path={path}
        onCreate={onCreate}
        isNested={isNested}
        nestedAttrs={nestedAttrs}
        isPaid={isPaid}
      />
      <DraggableField
        {...rest}
        onCreate={onCreate}
        isSelected={isSelected}
        roleDescriptions={roleDescriptions}
        prefillMessage={prefillMessage}
        field={field}
        path={path}
        roleTitle={roleTitle}
        roleBadge={roleBadge}
        isPaid={isPaid}
      />
    </>
  );
});
/**
 * Extracting this is a separate component for perf.
 */

class VisuallyHiddenNewComponentDropdown extends React.PureComponent<{
  path: Path;
  isNested?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  nestedAttrs?: {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCreate: (path: Path, type: string, attrs?: {}) => void;
  isPaid: boolean;
}> {
  render() {
    const { path, onCreate, isNested, nestedAttrs, isPaid } = this.props;
    return (
      <Box mb="ms">
        <VisuallyHidden>
          <NewComponentDropdown
            path={path}
            onSelect={onCreate}
            isNested={isNested}
            nestedAttrs={nestedAttrs}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ path: Path; onSelect: (path: Path, type: s... Remove this comment to see the full error message
            isPaid={isPaid}
          />
        </VisuallyHidden>
      </Box>
    );
  }
}
