import * as React from "react";

export default function Link() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="link-icon-24-a"
          d="M13.715 6.853A1 1 0 0 1 12.32 5.42l.132-.128a4.472 4.472 0 0 1 6.197-.041 4.438 4.438 0 0 1 .255 6.177l-2.933 3.282a3.738 3.738 0 0 1-5.77-.24 1 1 0 1 1 1.596-1.205 1.738 1.738 0 0 0 2.683.112l2.933-3.282a2.438 2.438 0 0 0-.14-3.393 2.472 2.472 0 0 0-3.426.023l-.132.128zM9.737 16.868a1 1 0 1 1 1.555 1.257 4.044 4.044 0 0 1-5.875.443l-.045-.041a4.276 4.276 0 0 1-.269-6.042l3.045-3.286a3.738 3.738 0 0 1 5.622.157 1 1 0 0 1-1.54 1.275 1.738 1.738 0 0 0-2.615-.073l-3.026 3.266a2.28 2.28 0 0 0 .133 3.227l.045.04a2.044 2.044 0 0 0 2.97-.223z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use
          fill="currentColor"
          fillRule="nonzero"
          xlinkHref="#link-icon-24-a"
        />
      </g>
    </svg>
  );
}
