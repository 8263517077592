import type { Node } from "prosemirror-model";
import type { EditorState } from "prosemirror-state";

export const isChromeWithSelectionBug = () =>
  // @ts-expect-error refactor
  parseInt((navigator.userAgent.match(/Chrome\/(\d{2})/) || [])[1], 10) >= 58;

export function findActiveQueryMark(
  state: EditorState,
  start: number,
  end: number
) {
  const { doc, schema } = state;
  const { mergeFieldQuery } = schema.marks;

  let foundMark = { start: -1, end: -1 };

  doc.nodesBetween(start, end || doc.nodeSize - 2, (node, pos) => {
    const mark = mergeFieldQuery.isInSet(node.marks);

    if (mark) {
      const query = node.textContent.substr(1).trim();
      if (mark.attrs.active) {
        foundMark = {
          start: pos,
          end: pos + node.textContent.length,
          // @ts-expect-error refactor
          query,
        };
      }

      return false;
    }

    return true;
  });

  return foundMark;
}

export function isNextCharacterSpace(position: number, doc: Node) {
  try {
    const resolvedPosition = doc.resolve(position);
    return (
      resolvedPosition.nodeAfter &&
      resolvedPosition.nodeAfter.textContent.indexOf(" ") === 0
    );
  } catch (e) {
    return false;
  }
}
