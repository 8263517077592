import * as React from "react";
import Downshift from "downshift";
import type { EditorView } from "prosemirror-view";

import HoverCard from "hw/ui/hover-card";
import { MenuList, MenuItem, MenuItemText } from "hw/ui/menu";
import theme from "hw/ui/theme";
import type { MergeField } from "hw/portal/modules/common/draft";
import { key } from "./plugin";
import * as utils from "./utils";
import { Context } from "./suggestions-container";

type Props = {
  editorActive?: boolean;
  view: EditorView;
  mergeFields: Array<MergeField>;
};

/**
 * Suggestions portal
 *
 * Here is where the merge field suggestions are rendered.  If there's an active
 * query, the merge field are rendered and filtered based on the current query input
 */
export default function Suggestions(props: Props) {
  const { view, mergeFields, editorActive } = props;
  const pluginState = key.getState(view.state);

  return pluginState.active && editorActive ? (
    <Context.Consumer>
      {(props) => (
        <ActiveSuggestions
          view={view}
          query={pluginState.query}
          mergeFields={mergeFields}
          {...props}
        />
      )}
    </Context.Consumer>
  ) : null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ActiveSuggestions extends React.Component<any> {
  componentWillUnmount() {
    this.props.resetHighlightedIndex();
  }

  // If the query changes, the highlighted index will be off so we need to
  // reset it
  // @ts-expect-error refactor
  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.props.resetHighlightedIndex();
    }
  }

  // @ts-expect-error refactor
  itemToString = (item) => {
    if (item === null || item === undefined) {
      return "";
    }

    return item.label;
  };

  // @ts-expect-error refactor
  handleSelect = (item) => {
    const { view, onSelect } = this.props;

    onSelect(view.state, view.dispatch, item);
  };

  render() {
    const { view, highlightedIndex, query, getFilteredItems } = this.props;
    const { selection } = view.state;
    const mark = utils.findActiveQueryMark(
      view.state,
      selection.from - 1,
      selection.to
    );
    const domRef = mark ? view.domAtPos(mark.start) : undefined;
    const anchor = domRef ? domRef.node.childNodes[domRef.offset] : null;
    const filteredItems = getFilteredItems(query);

    return (
      <Downshift
        highlightedIndex={highlightedIndex}
        onSelect={this.handleSelect}
        itemToString={this.itemToString}
      >
        {({ getItemProps, highlightedIndex }) => {
          return (
            <div>
              <HoverCard
                renderDest="portal"
                active={true}
                type="dropdown"
                anchor={anchor}
              >
                <MenuList
                  // @ts-expect-error refactor
                  extend={ConstrainedMenuList}
                  data-testid="suggestions-menu"
                >
                  {/* @ts-expect-error refactor */}
                  {filteredItems.map((item, index) => (
                    <MenuItem
                      key={generateKey(item)}
                      active={index === highlightedIndex}
                      passthroughProps={getItemProps({ item })}
                      extend={LastMenuItem(index === filteredItems.length - 1)}
                    >
                      <MenuItemText>
                        {item.data.label || item.data.dataRef}
                      </MenuItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </HoverCard>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

/**
 * We can't use our usual height/width restrictions for this hover card because
 * it has some special circumstances.  Its trigger is really just a single character,
 * so we can't tell the hover card to fit to its container because it would be
 * too small.
 */
function ConstrainedMenuList() {
  return {
    maxWidth: "300px",
    maxHeight: "300px",
    overflowY: "auto",
  };
}

/**
 * The last menu item (Edit merge fields) need a specific style
 */
// @ts-expect-error refactor
function LastMenuItem(last) {
  if (!last) return {};
  return {
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: theme.color.gray300,
  };
}

/**
 * The data ref may not always be unique, so we need to include the label when
 * generating the key.
 */
// @ts-expect-error refactor
function generateKey(item) {
  return `${item.data.dataRef}.${item.data.label}`;
}
