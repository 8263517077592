import * as React from "react";
import { Input } from "hw/ui/input";
import { Wrapper } from "./basics";
import { Actions } from "../../../state";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorDispatch: (...args: Array<any>) => any;
  path: Array<string | number>;
  value?: number;
  label: string;
  placeholder?: string;
  inputType?: string;
};
export default class InputSetting extends React.Component<Props> {
  handleChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { editorDispatch, path } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
    const { value } = evt.target;

    if (value || value === 0) {
      editorDispatch(
        Actions.ChangeFieldSetting({
          path,
          // $FlowIgnore We verify it's an int above
          updater: () => value * 1,
        })
      );
    } else {
      const pathForDelete = [...path];
      const fieldToDelete = pathForDelete.pop();
      editorDispatch(
        Actions.ChangeFieldSetting({
          path: pathForDelete,
          updater: (field) => {
            const update = { ...field };
            // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
            delete update[fieldToDelete];
            return update;
          },
        })
      );
    }
  };

  render() {
    const { label, placeholder, value = "", inputType } = this.props;
    return (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type '{ children: Elem... Remove this comment to see the full error message
      <Wrapper label={label}>
        <Input
          value={value}
          onChange={this.handleChange}
          data-testid={`field-settings-${label}`}
          data-guide-id={`field-settings-${label}`}
          type={inputType}
          placeholder={placeholder}
        />
      </Wrapper>
    );
  }
}
