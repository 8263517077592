import * as React from "react";
import { createComponent } from "hw/ui/style";
import IconWrapper, { DeleteIcon } from "hw/ui/icons";
import { Tooltip } from "hw/ui/tooltip";
import { VisuallyHidden } from "hw/ui/text";

const ButtonWrapper = createComponent(
  function RemoveButtonWrapper({ visible }) {
    return {
      opacity: visible ? 1 : 0,
      pointerEvents: visible ? "all" : "none",
      position: "absolute",
      right: 0,
    };
  },
  "div",
  ["onFocus", "onBlur", "data-testid", "data-automation-id"]
);
type Props = {
  /**
   * Called when the wrapping element is blurred
   */
  onBlur: (e: React.SyntheticEvent<HTMLDivElement>) => void;

  /**
   * Called when clicking on the button
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...args: Array<any>) => any;

  /**
   * Called when the wrapping element is focused.  This will make sure the
   * button is visible when focused
   */
  onFocus: (e: React.SyntheticEvent<HTMLDivElement>) => void;

  /**
   * True if the button should be visible or not
   */
  visible: boolean;

  /**
   * True if the button should be disabled
   */
  disabled?: boolean;

  /**
   * Message to display when the button is disabled
   */
  disabledMessage?: string;
};

/**
 * Handles removal of an item from the list.
 *
 * There's a bit of trickery going on here because this button is "hidden" behind
 * the item until it's hovered or focused.  It has a wrapper element to handle
 * that custom logic.
 */
export function RemoveButton(props: Props) {
  const { onFocus, visible, onBlur, onClick, disabled, disabledMessage } =
    props;
  const Icon = (
    <IconWrapper onClick={onClick} disabled={disabled}>
      <VisuallyHidden>Remove</VisuallyHidden>
      <DeleteIcon />
    </IconWrapper>
  );
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; visible: boolean; onFoc... Remove this comment to see the full error message
    <ButtonWrapper
      visible={visible}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid="orderable-list-remove-btn"
      data-automation-id="removeButton"
    >
      {disabled ? (
        <Tooltip tip={disabledMessage} position="top">
          {Icon}
        </Tooltip>
      ) : (
        Icon
      )}
    </ButtonWrapper>
  );
}
export default RemoveButton;
