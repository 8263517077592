import * as React from "react";

export default function Reorder() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16L12 20M12 20L16 16M12 20L12 15"
        stroke="#727A83"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8L12 4M12 4L8 8M12 4L12 9"
        stroke="#727A83"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="11" y="11" width="2" height="2" rx="1" fill="#727A83" />
    </svg>
  );
}
