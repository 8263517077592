/* eslint-disable @typescript-eslint/no-explicit-any */
import "hw/common/css/base.css";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { setIn } from "timm";
import { LogoLockup } from "hw/brand";
import { Box } from "hw/ui/blocks";
import * as Sentry from "@sentry/browser";
import * as Styled from "./components/styled";
import Form from "./components/form";
import Submitted from "./components/submitted";
import type { ApiInput } from "./types";

type Props = {
  guid: string;
  name: string;
  roles: Array<{ id: string; label: string }>;
  onSubmit: (guid: string, data: ApiInput) => Promise<any>;
  onResend: (guid: string) => Promise<any>;
  privacyPolicyUrl: string;
  teamName: string;
  mergeFields: Array<{ data_ref: string; label: string }>;
};

type State = {
  screen: "init" | "sent";
  error: React.ReactNode | null | undefined;
  submitting: boolean;
  resending: boolean;
  workflowInstanceGuid: string | null | undefined;
  data: {
    roles: Record<
      string,
      {
        fullName: string;
        value: string;
        type: "EMAIL";
      }
    >;
    mergeFields: {
      [dataRef: string]: string;
    };
  };
};

const baseInitState = {
  screen: "init",
  error: null,
  submitting: false,
  resending: false,
  workflowInstanceGuid: null,
};

export default class ShareableLinks extends React.Component<Props, State> {
  // @ts-expect-error refactor
  state: State = {
    ...baseInitState,
    data: formatInitialState(this.props.roles, this.props.mergeFields),
  };

  // edge case: user leaves their send window open and tries to resend after
  // wf has been completed, cancelled, or deleted already.
  // take user back to form so they can create a new instance,
  // instead of being stuck in resend limbo
  retry = (errMessage: React.ReactNode) => {
    // @ts-expect-error refactor
    this.setState({ ...baseInitState, error: errMessage });
  };

  resend = () => {
    this.setState({ resending: true });
    const { workflowInstanceGuid } = this.state;
    const { teamName } = this.props;

    if (!workflowInstanceGuid) {
      throw new Error(
        "Trying to resend an email without a workflow instance guid"
      );
    }

    this.props
      .onResend(workflowInstanceGuid)
      .catch((err) => {
        captureError(err, "Error resending shareable link");

        this.retry(messageFromErr(err, "resending the workflow", teamName));
      })
      .finally(() =>
        this.setState({
          resending: false,
        })
      );
  };

  submit = () => {
    const { guid, teamName } = this.props;

    this.setState({ submitting: true, error: null });

    this.props
      .onSubmit(guid, formatDataForApi(this.state.data))
      .then(
        (resp) =>
          this.setState({
            screen: "sent",
            workflowInstanceGuid: resp.wfi_guid,
          }),
        (err) => {
          captureError(err, "Error creating shareable link workflow instance");

          this.setState({
            error: messageFromErr(err, "starting the workflow", teamName),
          });
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  handleChange = (roleId: string, name: string, value: string) => {
    // @ts-expect-error refactor
    this.setState(setIn(this.state, ["data", "roles", roleId, name], value));
  };

  handleChangeMergeField = (dataRef: string, value: string) => {
    // @ts-expect-error refactor
    this.setState(setIn(this.state, ["data", "mergeFields", dataRef], value));
  };

  handleSubmit = (evt: React.SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault();

    this.submit();
  };

  render() {
    const { data, screen, submitting, error, workflowInstanceGuid, resending } =
      this.state;
    const { name, privacyPolicyUrl, roles, mergeFields } = this.props;
    const formDisabled = submitting;

    return (
      <Styled.Page>
        <Styled.Content>
          <Box mb="lg">
            <LogoLockup />
          </Box>
          {screen === "init" && (
            <Form
              data={data}
              disabled={formDisabled}
              onChange={this.handleChange}
              onChangeMergeField={this.handleChangeMergeField}
              onSubmit={this.handleSubmit}
              privacyPolicyUrl={privacyPolicyUrl}
              errorMsg={error}
              roles={roles}
              mergeFields={mergeFields}
              name={name}
            />
          )}
          {screen === "sent" && (
            <Submitted
              onResend={this.resend}
              resending={resending}
              // @ts-expect-error refactor
              workflowInstanceGuid={workflowInstanceGuid}
              multipleParticipants={roles.length > 1}
            />
          )}
        </Styled.Content>
      </Styled.Page>
    );
  }
}

// @ts-expect-error refactor
function formatInitialState(roles, mergeFields) {
  // @ts-expect-error refactor
  roles = roles.reduce((obj, role) => {
    obj[role.id] = {
      fullName: "",
      type: "EMAIL",
      value: "",
    };
    return obj;
  }, {});

  // @ts-expect-error refactor
  mergeFields = mergeFields.reduce((obj, mf) => {
    obj[mf.data_ref] = "";
    return obj;
  }, {});

  return {
    roles,
    mergeFields,
  };
}

// @ts-expect-error refactor
function formatDataForApi(data) {
  return {
    ...data,

    // Shareable links share components with the 'launch' page in the portal,
    // but the API contracts are different. We store the data in local state
    // in the format the component needs, but need to reformat before sending
    // to the backend.
    roles: Object.keys(data.roles).reduce((roles, roleId) => {
      const participant = data.roles[roleId];
      return {
        ...roles,
        [roleId]: {
          contact: participant.value,
          type: "EMAIL",
          name: participant.fullName,
        },
      };
    }, {}),
  };
}

// @ts-expect-error refactor
function messageFromErr(apiErr, action, teamName) {
  if (apiErr.status === 429) {
    return (
      <FormattedMessage
        id="errors.rateLimit"
        defaultMessage="{teamName} has exceeded the maximum number of forms they can send in an hour. Please contact them or try again later."
        values={{ teamName }}
      />
    );
  }

  if (apiErr.status === 402) {
    return (
      <FormattedMessage
        id="errors.rateLimit"
        defaultMessage="{teamName} has exceeded the maximum number of transactions they can send in an month. Please contact them or try again later."
        values={{ teamName }}
      />
    );
  }

  // A 403 in our system typically means that the session has expired. While we
  // don't have persisted sessions for shareable links, we do use sessions to
  // handle CSRF tokens. But because we're on the same domain as the portal
  // application, we do share a session token. So if a user already has a session
  // because they are logged into the portal, and that session expires while
  // attempting to launch a shareable link, they will see a 403.
  if (apiErr.status === 403) {
    if (apiErr.response?.text?.length > 0) {
      return (
        <FormattedMessage
          id="errors.sessionExpired"
          defaultMessage="Unable to send transaction. Please contact them or try again later."
        />
      );
    } else {
      return (
        <FormattedMessage
          id="errors.sessionExpired"
          defaultMessage="Please reload the page and try again."
        />
      );
    }
  }

  return (
    <FormattedMessage
      id="shareableLinks.requestError"
      defaultMessage="An error occurred when {action}.  This has been reported to our engineering team."
      values={{ action }}
    />
  );
}

const ignoreCodes = [403, 429, 500];

// @ts-expect-error refactor
function captureError(apiErr, msg) {
  if (ignoreCodes.includes(apiErr.status)) return;

  Sentry.withScope((scope) => {
    scope.setLevel("error");
    scope.setExtra("status", apiErr.status);
    Sentry.captureMessage(msg);
  });
}
