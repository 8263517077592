import * as React from "react";
import cx from "classnames";
import "./signature.css";
import getTestAttributes from "hw/common/utils/get-test-attributes";
import Message from "hw/sf/components/common/message";
import ErrorMessage from "hw/common/components/smartforms/common/error-message";

type Props = {
  clearSignature?: (e: React.SyntheticEvent) => void;
  errorMessage?: string;
  handleClick?: (e: React.SyntheticEvent) => void;
  isSigned?: boolean;
  isValid?: boolean;
  message?: string;
  prerenderedFont?: React.ReactNode;
  readOnly?: boolean;
  signature?: React.ReactNode;
  signatureModal?: React.ReactNode;
  signerTitle?: string;
};

export default class Signature extends React.Component<Props> {
  render() {
    const {
      isSigned,
      readOnly,
      signatureModal,
      signature,
      isValid,
      message,
      errorMessage,
      handleClick,
      clearSignature,
      prerenderedFont,
      signerTitle,
    } = this.props;

    const rootClasses = cx({
      "m-sf-signature": true,
      "is-signed": isSigned,
      readOnly,
    });

    const clearClasses = cx({
      "m-button": true,
      "m-sf-signature--clear": true,
      "is-signed": isSigned,
    });

    const clear = !readOnly ? (
      <div className="m-sf-signature--clear-wrapper">
        <span className={clearClasses} onClick={clearSignature}>
          <Message stringId="sf.components.smart-view.smart-form.smart-components.signature.clear" />
        </span>
      </div>
    ) : null;

    const error = !isValid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

    const canvasProps = {
      ref: "canvas",
      className: cx({
        "m-sf-signature--canvas": true,
        invalid: !isValid,
        readonly: readOnly,
      }),
      onClick: !readOnly ? handleClick : null,
    };

    return (
      // @ts-expect-error refactor
      <div className={rootClasses} tabIndex="0" data-custom-focus>
        {clear}
        {signatureModal}
        {prerenderedFont}

        {/* @ts-expect-error refactor */}
        <div
          {...canvasProps}
          data-test-ref="signature-canvas"
          data-testid="signature-canvas"
          {...getTestAttributes("signature-canvas")}
        >
          {signature}
          <div className="m-sf-signature--canvas--placeholder">{message}</div>
        </div>
        {signerTitle ? (
          <div
            className="m-sf-signature--signer-title"
            data-testid="signer-title"
          >
            {signerTitle}
          </div>
        ) : null}
        {error}
      </div>
    );
  }
}
