/* eslint-disable @typescript-eslint/no-explicit-any */
import superagent from "superagent";
import Task from "hw/common/utils/task";
import type { ApiInput } from "./types";

/**
 * API for shareable links
 *
 * Each method should return a `Task` object that can be cancelled if needed.
 */
export default function Api(csrfToken: string) {
  return {
    /**
     * Starts the workflow for the given guid and set of roles
     */
    startWorkflow(guid: string, data: ApiInput) {
      return Task.from((reject, resolve) => {
        const req = superagent
          .post(`/link/${guid}`)
          .withCredentials()
          .set("x-csrf-token", csrfToken)
          .set("Accept", "application/json")
          .send(data);

        req.then((response: any) => resolve(response.body), reject);

        return () => req.abort();
      });
    },

    /**
     * Resends the email for a given workflow instance without creating a new
     * instance.
     */
    resend(workflowinstanceGuid: string) {
      return Task.from((reject, resolve) => {
        const req = superagent
          .post(`/link/resend/${workflowinstanceGuid}`)
          .withCredentials()
          .set("x-csrf-token", csrfToken)
          .set("Accept", "application/json");

        req.then((response: any) => resolve(response.body), reject);

        return () => req.abort();
      });
    },
  };
}
