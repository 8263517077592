import isPlainObject from "lodash/isPlainObject";

const validKeys = ["type", "payload", "error", "meta"];

function isValidKey(key: string) {
  return validKeys.indexOf(key) > -1;
}

export function isFSA(action: { type?: string }) {
  return (
    isPlainObject(action) &&
    typeof action.type !== "undefined" &&
    Object.keys(action).every(isValidKey)
  );
}

export function isError(action: { error?: boolean }) {
  return action.error === true;
}
