/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

type Options = {
  /**
   * A map of dataRefs to values
   */
  data: Record<string, string>;

  /**
   * A map of mark types to either strings (`em`, `strong`, etc.) or React components
   */
  markHandlers: Record<string, string | React.ComponentType<any>>;

  /**
   * A map of node types to either string (`p`, `ul`, etc.) or React components
   */
  nodeHandlers: Record<string, string | React.ComponentType<any>>;
};

/**
 * Recursively renders a rich text json document to a React tree.  Items are
 * rendered based on the type and mark maps provided.
 */
export default function mapTree(leaf: any, options: Options) {
  if (leaf.type === "text" && (!leaf.marks || leaf.marks.length === 0)) {
    return leaf.text;
  }

  const typeHandler = options.nodeHandlers[leaf.type];

  if (!typeHandler) {
    throw new Error(`No handler for node type "${leaf.type}"`);
  }

  const props =
    typeof typeHandler === "string" ? leaf.attrs : { ...options, node: leaf };
  const children = (leaf.content || []).map((child: any) =>
    mapTree(child, options)
  );

  return React.createElement(typeHandler, props, ...children);
}
