import * as React from "react";
import { Flex } from "hw/ui/blocks";
import type {
  AddressGroupField,
  FormMapping,
  MappedField,
  Field,
} from "hw/portal/modules/common/draft";
import { isLegacy } from "hw/common/components/smartforms/address-group";
import { HelpText, Label, Required, Prefill } from "./common";
import type { SettingsProps } from "../../types";

type Props = SettingsProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSettings: Array<React.ReactElement<any>>;
  field: AddressGroupField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referenceSetting?: React.ReactElement<any>;
  deleteMapping: (field: Field) => void;
  setMapping: (mappedField: MappedField, field: Field) => void;
  formMappings: Array<FormMapping>;
  activePrefill?: MappedField;
};

/**
 * Address Group settings panel
 * NOTE: It does not use default settings currently since the only default is role and we have to modify role.
 *
 * param props
 * returns {*}
 * constructor
 */
export function AddressSettings(props: Props) {
  const {
    field,
    path,
    defaultSettings,
    referenceSetting,
    setMapping,
    deleteMapping,
    activePrefill,
    formMappings,
    editorDispatch,
  } = props;
  const { children = [], label, helpText, type } = field;
  const isRequired = children.some(({ required }) => required);

  // Normaly required passes a generic function as the second parameter to the editorDispatch function.
  // In this case though we override the default function by ignoring the callback function and passing our own
  // custom function instead.
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  const customEditorDispatch = (action) => {
    editorDispatch({
      ...action,
      payload: {
        ...action.payload,
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
        updater: (field) => {
          const { children } = field;
          // @ts-expect-error ts-migrate(7034) FIXME: Variable 'updatedChildren' implicitly has type 'an... Remove this comment to see the full error message
          const updatedChildren = [];
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'child' implicitly has an 'any' type.
          children.forEach((child, index) => {
            if (isLegacy(children)) {
              if (index < 4) {
                updatedChildren.push({ ...child, required: !isRequired });
                return;
              }
            } else if (index !== 1 && index < 5) {
              updatedChildren.push({ ...child, required: !isRequired });
              return;
            }

            updatedChildren.push({ ...child });
          });
          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'updatedChildren' implicitly has an 'any[... Remove this comment to see the full error message
          return { ...field, children: updatedChildren };
        },
      },
    });
  };

  return (
    <Flex flexDirection="column">
      {defaultSettings}
      <Label
        path={path}
        editorDispatch={editorDispatch}
        label={label}
        fieldType={type}
        placeholder="Home Address, Work Address, ..."
      />
      <Required
        path={path}
        editorDispatch={customEditorDispatch}
        required={isRequired}
        fieldType={type}
      />
      <Prefill
        field={field}
        onChange={setMapping}
        onRemove={deleteMapping}
        activePrefill={activePrefill}
        formMappings={formMappings}
      />
      <HelpText
        path={path}
        editorDispatch={editorDispatch}
        helpText={helpText}
      />
      {referenceSetting}
    </Flex>
  );
}
export default AddressSettings;
