import * as React from "react";

export default function DownloadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44771 11 5L13 5ZM16.7071 11.7071C17.0976 11.3166 17.0976 10.6834 16.7071 10.2929C16.3166 9.90237 15.6834 9.90237 15.2929 10.2929L16.7071 11.7071ZM8.70711 10.2929C8.31658 9.90237 7.68342 9.90237 7.29289 10.2929C6.90237 10.6834 6.90237 11.3166 7.29289 11.7071L8.70711 10.2929ZM13 15L13 5L11 5L11 15L13 15ZM15.2929 10.2929L11.2929 14.2929L12.7071 15.7071L16.7071 11.7071L15.2929 10.2929ZM12.7071 14.2929L8.70711 10.2929L7.29289 11.7071L11.2929 15.7071L12.7071 14.2929Z"
        fill="#727A83"
      />
      <line
        x1="4"
        y1="19"
        x2="20"
        y2="19"
        stroke="#727A83"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
