import * as React from "react";
import getTestAttributes, {
  testAttributeNames,
} from "hw/common/utils/get-test-attributes";
import { createComponent } from "hw/ui/style";
import type { TriggerProps } from "./types";

type Props = TriggerProps & {
  /**
   * A react style icon
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: React.ComponentType<any>;
};

/**
 * @one-off Button special button to style an icon when used in a dropdown.
 * This could be one variation of the presentation prop in the button
 */
const StyledButton = createComponent(
  function StyledButton({ theme, disabled }) {
    return {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: theme.corner.sm,
      textDecoration: "none",
      transitionProperty: "all",
      transitionDuration: theme.transitionSpeed.fast,
      color: theme.color.iconDefault,
      backgroundColor: theme.color.transparent,
      outline: "none",
      border: "none",
      cursor: disabled ? "not-allow" : "pointer",
      boxShadow: "none",
      padding: theme.space.xs,
      ":hover": {
        color: theme.color.iconHover,
        backgroundColor: theme.color.buttonBgSubtleHover,
      },
      ":active": {
        color: theme.color.iconPressed,
        backgroundColor: theme.color.uiActionPressed,
      },
      ":focus": {
        boxShadow: `0 0 0 2px ${theme.color.uiFocus}`,
        outline: "none",
      },
    };
  },
  "button",
  ["onClick", "data-testid", "disabled"].concat(testAttributeNames)
);

function IconTrigger(props: Props) {
  const { Icon, triggerPassthroughProps, disabled } = props;
  const { getButtonProps } = triggerPassthroughProps;

  return (
    <StyledButton
      {...getTestAttributes(props["data-guide-id"])}
      {...getButtonProps()}
      disabled={disabled}
      data-testid={props["data-testid"]}
    >
      <Icon />
    </StyledButton>
  );
}

IconTrigger.defaultProps = {
  triggerPassthroughProps: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getButtonProps: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getInputProps: () => {},
  },
  "data-guide-id": "ui-icon-button",
  "data-testid": "ui-icon-trigger-btn",
};

export default IconTrigger;
