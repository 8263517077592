import type Theme from "hw/ui/theme";

const iconWidth = "20px";

/**
 * Shared style between the SelectTrigger and the native select styling.
 *
 * This was my attempt at sharing styles because there's a lot of shared styles, but they need to be applied to different elements.
 *
 */

export default {
  root: ({
    disabled,
    compacted,
    small,
    minWidth,
    minHeight,
  }: {
    disabled?: boolean;
    compacted?: boolean;
    small?: boolean;
    minWidth?: string;
    minHeight?: string;
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    minWidth: getMinWidth(compacted, small, minWidth),
    cursor: disabled ? "not-allowed" : "pointer",
    minHeight: getMinHeight(compacted, minHeight),
    position: "relative",
  }),
  content: ({
    theme,
    disabled,
    compacted,
  }: {
    theme: typeof Theme;
    disabled?: boolean;
    compacted?: boolean;
  }) => ({
    backgroundColor: disabled ? theme.color.inputBgDisabled : theme.color.white,
    transition: "background-color 150ms",
    borderRadius: theme.corner.sm,
    borderColor: disabled
      ? theme.color.inputBorderDisabled
      : theme.color.gray400,
    borderWidth: "1px",
    borderStyle: "solid",
    fontSize: theme.fontSize.md,
    paddingTop: getPadding("Y", theme, compacted),
    paddingBottom: getPadding("Y", theme, compacted),
    paddingLeft: getPadding("X", theme, compacted),
    paddingRight: `calc(${getPadding("X", theme, compacted)} + ${iconWidth})`,
    ":active": {
      outline: "none",
    },
    ":hover": {
      backgroundColor: !disabled && theme.color.gray100,
    },
  }),
  text: ({
    theme,
    selectedItem,
  }: {
    theme: typeof Theme;
    selectedItem: boolean;
  }) => ({
    fontSize: theme.fontSize.ms,
    fontFamily: "inherit",
    transition: "color 50ms",
    color: selectedItem
      ? theme.color.textStandard
      : theme.color.textSelectPrompt,
  }),
  icon: ({
    theme,
    compacted,
  }: {
    theme: typeof Theme;
    compacted?: boolean;
  }) => ({
    width: iconWidth,
    position: "absolute",
    right: compacted ? theme.space.xs : theme.space.ms,
    display: "flex",
    alignItems: "center",
    fill: theme.color.textStandard,
    pointerEvents: "none",
  }),
};

export function getPadding(
  axis: "X" | "Y",
  theme: typeof Theme,
  compacted?: boolean
) {
  if (axis === "X") {
    return compacted ? theme.space.sm : theme.space.ms;
  } else if (axis === "Y") {
    return compacted ? theme.space.xs : theme.space.sm;
  }
}

function getMinHeight(compacted?: boolean, minHeight?: string) {
  if (minHeight) return minHeight;
  if (compacted) return "24px";
  return "40px";
}

function getMinWidth(compacted?: boolean, small?: boolean, minWidth?: string) {
  if (minWidth) return minWidth;

  if (compacted) {
    if (small) return "60px";
    return "100px";
  }

  return "200px";
}
