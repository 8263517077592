/* eslint-disable @typescript-eslint/no-explicit-any */
import { createComponent } from "hw/ui/style";

export const Page = createComponent(function Wrapper({ theme }: any) {
  return {
    backgroundColor: theme.color.white,
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: theme.space.md,
    paddingRight: theme.space.md,
    height: "auto",
    minHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
});

export const Content = createComponent(function Content() {
  return {
    paddingTop: "40px",
    maxWidth: "400px",
    width: "100%",
  };
});
