import * as React from "react";

export default function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      <path
        id="close-16-a"
        d="M9.40997691,8 L13.6900096,12.2800327 C14.079364,12.6693871 14.079364,13.3006552 13.6900096,13.6900096 C13.3006552,14.079364 12.6693871,14.079364 12.2800327,13.6900096 L8,9.40997691 L3.71996729,13.6900096 C2.77998269,14.6299942 1.37000578,13.2200173 2.30999038,12.2800327 L6.59002309,8 L2.30999038,3.71996729 C1.92063601,3.33061292 1.92063601,2.69934476 2.30999038,2.30999038 C2.69934476,1.92063601 3.33061292,1.92063601 3.71996729,2.30999038 L8,6.59002309 L12.2800327,2.30999038 C13.2200173,1.37000578 14.6299942,2.77998269 13.6900096,3.71996729 L9.40997691,8 Z"
      />
    </svg>
  );
}
