import * as React from "react";
import DateInput from "hw/common/components/smartforms/date-input";
import type { DateInputField } from "hw/portal/modules/common/draft";

type Props = {
  field: DateInputField;
};
type State = {
  value: string;
};

/**
 * Draft editor wrapper for the phone number component.
 */
export class DateInputPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  onChange = (newValue: string) =>
    this.setState({
      value: newValue,
    });

  render() {
    const { value } = this.state;
    const { field } = this.props;
    const { label, required, helpText } = field;
    // The `required` field could be a macro in which case the form builder won't
    // know if the field is required or not because macros are not being
    // evaluated.  For V1, we'll coerce string values to `true` and treat them
    // as required.
    const isRequired = Boolean(required);
    return (
      <DateInput
        helpText={helpText}
        isValid={true}
        label={label}
        onChange={this.onChange}
        required={isRequired}
        value={value}
      />
    );
  }
}
export default DateInputPreview;
