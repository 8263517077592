import * as React from "react";

/** \
 * TODO: The border is square by default. I'm styling it rounded through CSS.
 * The rectangle should be added rounded at the beggining but for that, we need
 * to change it (if we want it to be rounded by default)
 */
export function Check(props: {
  size?: number;
  color?: string;
  rectStyle?: Record<string, string>;
}) {
  const { size = 20, color = "#FFF", rectStyle } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="-2 -2 20 20"
      role="presentation"
    >
      <rect
        x="0"
        y="0"
        width="16"
        height="16"
        fill="transparent"
        rx="2px"
        style={rectStyle}
      />
      <path
        fill={color}
        d="M11.1540271,3.46677417 C11.4485196,2.99955622 12.0660079,2.85953457 12.5332258,3.15402706 C13.0004438,3.44851955 13.1404654,4.06600787 12.8459729,4.53322583 L7.80348707,12.5332258 C7.45643333,13.0838332 6.68422091,13.1614326 6.23455192,12.6908876 L3.2770378,9.59607015 C2.89547112,9.19678915 2.90983142,8.56378704 3.30911242,8.18222036 C3.70839342,7.80065369 4.34139553,7.81501399 4.7229622,8.21429499 L6.79497193,10.3824983 L11.1540271,3.46677417 Z"
      />
    </svg>
  );
}

export default Check;
