import React from "react";
import SIN from "hw/common/components/smartforms/sin";
import type { SinField } from "hw/portal/modules/common/draft";

type Props = {
  field: SinField;
};
type State = {
  value: string;
  visited: boolean;
};

class SinPreview extends React.Component<Props, State> {
  state = {
    value: "",
    visited: false,
  };

  handleChange = (newValue: string) =>
    this.setState({
      value: newValue,
    });

  render() {
    const { value } = this.state;
    const { field } = this.props;
    const { label, required, helpText } = field;
    // The `required` field could be a macro in which case the form builder won't
    // know if the field is required or not because macros are not being
    // evaluated.  For V1, we'll coerce string values to `true` and treat them
    // as required.
    const isRequired = Boolean(required);
    return (
      <SIN
        helpText={helpText}
        isValid={true}
        label={label}
        onChange={this.handleChange}
        readOnly={false}
        required={isRequired}
        value={value}
      />
    );
  }
}

export default SinPreview;
